/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { Button, Checkbox } from '@mui/material';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import * as wjChart from '@grapecity/wijmo.react.chart';
import * as wjChartAnimate from '@grapecity/wijmo.react.chart.animation';
import { AllInputWrap, CustomInputWrap, CustomInputText, st } from 'components/inputs/CustomInput';
import { ContentGrid } from 'components/layouts/ContentGrid';
import { SubTitleLayout, SubTitleGroup } from 'components/layouts/ContentLayout';
import {
  SearchBox,
  SearchBoxRow,
  InputBox,
  SearchRows,
  SearchCols,
  SearchButtonWrap,
} from 'components/layouts/SearchBox';
import { SelectBox, Selected, list } from 'components/selects/CustomSelect';
import { IconButton } from 'components/buttons/IconSVG';
import CustomDialog from 'components/modals/common/CustomDialog';
import { t } from 'i18next';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { ComboBox } from 'components/selects/ComboBox';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { Code } from 'models/common/CommonCode';
import CustomGrid from 'components/grids/CustomGrid';
import { useMessageBar } from 'hooks/useMessageBar';
import { useTranslation } from 'react-i18next';
import { getUtMatrixUsageData } from 'apis/ut/UtMatringGraph';

type Props = {
  open: boolean;
  close: () => void;
  condition: any;
};

interface SearchParam {
  utmId: string;
  utmNm: string;
  utmNo: string;
  verNo: string;
  utmItemCd: string;
  prdnProcCd: string;
}

const UtMatrixGraphPopup = ({ open, close, condition }: Props) => {
  const [flexRef, setFlexRef] = useState<any>();
  const { openMessageBar } = useMessageBar();
  const { t } = useTranslation();

  const [prdnProcCode, setPrdnProcCode] = useState<Code[]>([]);
  const [utmItemCode, setUtmItemCode] = useState<Code[]>([]);
  const [checked, setChecked] = useState<boolean>(false);
  const [rowData, setRowData] = useState<any>([]);
  const [pivotData, setPivotData] = useState<any>([]);
  const [chartData, setChartData] = useState<any>([]);
  const [bindingX, setBindingX] = useState<string>('');
  const [chartHeader, setChartHeader] = useState<string>('');

  const [searchParam, setSearchParam] = useState<SearchParam>({
    utmId: condition.utmId,
    utmNm: condition.utmNm,
    utmNo: condition.utmNo,
    verNo: condition.verNo,
    // askPurpCdNm: condition.askPurpCdNm,
    utmItemCd: 'ELECTRICITY',
    prdnProcCd: '',
  });

  useEffect(() => {
    initCondition();
    btnSearch();
  }, []);

  const initCondition = async () => {
    const prdnProcCd = await getCommonCodeNames('PRDN_PROC_CD');
    const utmItemCd = await getCommonCodeNames('UTM_ITEM_CD');
    setPrdnProcCode(prdnProcCd);
    setUtmItemCode(utmItemCd);
  };

  const flexLayoutDefinition = useMemo(() => {
    const flexLayout = [] as any[];

    if (pivotData.length > 0) {
      pivotData.forEach((item: any, index) => {
        flexLayout.push({ header: item.KEY, align: 'right', binding: item.KEY });
      });
    }

    return [
      {
        binding: 'VAL_TP',
        header: String(t('com.label.구분', '구분')),
        align: 'center',
      },
      ...flexLayout,
    ];
  }, [rowData]);

  const palette = [
    'rgb(180, 181, 243, 0.99)',
    'rgb(218, 240, 136, 0.99)',
    'rgb(252, 169, 191, 0.99)',
    'rgb(173, 206, 255, 0.99)',
  ];

  const onInitialized = (grid) => {
    setFlexRef(grid);
  };

  const btnSearch = () => {
    getUtMatrixUsageData(searchParam).then((result) => {
      if (result !== null) {
        setRowData(result.list);
        setPivotData(result.pivotData);
        setChartData(
          'ELECTRICITY' === searchParam.utmItemCd
            ? (result.chart || []).reduce(
                (acc, cur) => [
                  ...acc,
                  {
                    EQCL_ID: cur['EQCL_ID'],
                    EQCL_ID_NM: cur['EQCL_ID_NM'],
                    PRDN_PROC_CD: cur['PRDN_PROC_CD'],
                    PRDN_PROC_NM: cur['PRDN_PROC_NM'],
                    SM: cur['SM'],
                  },
                ],
                []
              )
            : result.chart
        );
        setBindingX(result.type);
        setChartHeader(searchParam.utmItemCd);
      } else {
        openMessageBar({
          type: 'error',
          content: t(
            'com.msg.요청 정보 조회 중 오류가 발생했습니다.',
            '요청 정보 조회 중 오류가 발생했습니다.'
          ),
        });
      }
    });
  };

  return (
    <CustomDialog
      title={t('com.label.UT Matrix 도표분석', 'UT Matrix 도표분석')}
      size="lg"
      open={open}
      onClose={close}
    >
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows>
              <SearchCols>
                <label>
                  <span>{t('com.label.UT Matrix 명', 'UT Matrix 명')}</span>
                </label>
                <CustomInputWithSearch
                  type="text"
                  name="utmNm"
                  value={searchParam.utmNm}
                  readOnly
                />
              </SearchCols>
              <SearchCols>
                <label>
                  <span>{t('com.label.UT Matrix 번호', 'UT Matrix 번호')}</span>
                </label>
                <CustomInputWithSearch
                  type="text"
                  name="utmNo"
                  value={searchParam.utmNo}
                  readOnly
                />
              </SearchCols>
              <SearchCols>
                <label>{t('com.label.버전', '버전')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="verNo"
                  value={searchParam.verNo}
                  readOnly
                />
              </SearchCols>
            </SearchRows>
            <SearchRows>
              <SearchCols>
                <label>{t('gtng.label.UT 항목', 'UT 항목')}</label>
                <ComboBox
                  options={utmItemCode}
                  defaultValue={searchParam.utmItemCd}
                  onChange={(value) =>
                    setSearchParam({
                      ...searchParam,
                      utmItemCd: value,
                    })
                  }
                />
              </SearchCols>
              <SearchCols>
                <div style={{ display: 'inline-flex', width: '200px', paddingTop: '5px' }}>
                  <label style={{ paddingTop: '0px' }}>{t('com.label.상세비교', '상세비교')}</label>
                  <Checkbox
                    id="showDtalProcCd"
                    name="showDtalProcCd"
                    css={st.checkbox}
                    className="custom"
                    checked={checked}
                    onClick={(e) => {
                      setChecked(checked ? false : true);
                      setSearchParam({
                        ...searchParam,
                        prdnProcCd: !checked ? 'E' : '',
                      });
                    }}
                    disableRipple
                  />
                </div>
                <ComboBox
                  options={prdnProcCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam.prdnProcCd}
                  onChange={(value) =>
                    setSearchParam({
                      ...searchParam,
                      prdnProcCd: value,
                    })
                  }
                  readOnly={!checked}
                />
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchButtonWrap>
            <Button css={IconButton.button} onClick={btnSearch} className="find" disableRipple>
              {String(t('com.button.조회', '조회'))}
            </Button>
          </SearchButtonWrap>
        </SearchBoxRow>
      </SearchBox>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('com.label.UT별 사용량', 'UT별 사용량')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{rowData?.length ?? 0}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={flexLayoutDefinition}
        rowData={rowData}
        height={146}
        initialized={onInitialized}
        isSelector={false}
        isReadOnly={true}
        isFilter={false}
        allowPinning={false}
      />
      <wjChart.FlexChart
        header={chartHeader}
        bindingX={bindingX === 'UTM_ITEM' ? 'PRDN_PROC_NM' : 'DTAL_PROC_NM'}
        selectionMode="Point"
        itemsSource={chartData}
        palette={palette}
      >
        <wjChart.FlexChartLegend position="Bottom" />
        <wjChart.FlexChartSeries name="Min" binding="MN" />
        <wjChart.FlexChartSeries name="Max" binding="MX" />
        <wjChart.FlexChartSeries name="Sum" binding="SM" />
        <wjChart.FlexChartSeries name="Avg" binding="AV" />
        <wjChartAnimate.FlexChartAnimation />
      </wjChart.FlexChart>
    </CustomDialog>
  );
};

export default UtMatrixGraphPopup;
