import { PaginationRequest } from '../common/Pagination';
import { Crud } from '../common/Edit';

export enum AsstTpCd {
  EQUIPMENT = 'EQP',
}

export enum RvwStatCd {
  REVIEW = 'RVW',
  COMPLETE = 'CPLT',
}

export interface IdleAssetCondition extends PaginationRequest {
  copCds?: string[]; // 법인코드목록 (멀티콤보용 화면에서만 사용)
  copCd?: string; // 대상법인코드
  eqpNm?: string; // 설비명
  asstNm?: string; // 고정자산명
  rvwStatCd?: string; // 검토상태코드
  rvwRltDivsCd?: string; // 검토결과구분코드
  rvwReqTitNm?: string; // 요청제목
  dataInsUserId?: string; // 요청자
  startDate?: string; // 요청일 검색시작일
  endDate?: string; // 요청일 검색종료일
  rvwReqId?: string; //요청번호
  wrtUserId?: string; //검토자
}

/**
 * 불용자산기본
 */
export interface IdleAssetMaster extends Crud {
  rvwReqId?: string; // 검토요청ID
  rvwReqTitNm?: string; // 검토요청제목명
  rvwReqCtn?: string; // 검토요청내용
  asstRvwReqCopCd?: string; // 법인코드
  rvwCpltDt?: string; // 검토완료일자
  rvwStatCd?: string; // 검토상태코드
  rvwStatCdNm?: string; // 검토상태코드명
  rmk?: string; // 비고
  atchFileGrId?: string; // 첨부파일그룹ID
  useYn?: string; // 사용여부
  optValCtn1?: string; // 옵션값내용1
  optValCtn2?: string; // 옵션값내용2
  optValCtn3?: string; // 옵션값내용3
  optValCtn4?: string; // 옵션값내용4
  optValCtn5?: string; // 옵션값내용5
  dataInsUserId?: string; // 데이터입력사용자ID
  dataInsUserNm?: string; // 데이터입력사용자명
  dataInsUserIp?: string;
  dataInsDtm?: string; // 데이터입력일시
  dataUpdUserId?: string; // 데이터수정사용자ID
  dataUpdUserNm?: string; // 데이터수정사용자명
  dataUpdUserIp?: string;
  dataUpdDtm?: string; // 데이터수정일시
  eqpCount?: number; // 설비대수
  progressRate?: string; // 검토율
  deptRvwRoleYn?: string;
}

/**
 *불용자산상세
 */
export interface IdleAssetDetail extends Crud {
  rvwReqId?: string; // 검토요청ID
  asstTpCd?: string; // 자산유형코드
  asstTpCdNm?: string; // 자산유형코드명
  eqpIdExstYn?: string; // 설비ID존재여부
  eqpOwnCopCd?: string; // 설비의 법인코드
  prdnPldoCd?: string; // 생산프랜트동코드
  eqpId?: string; // 설비ID
  eqpNm?: string; // 설비명
  rvwStatCd?: string; // 검토상태코드
  rvwStatCdNm?: string; // 검토상태코드명
  asstRvwTpCd?: string; // 검토유형코드
  rvwRltDivsCd?: string; // 검토결과코드
  rvwRltDivsCdNm?: string; // 검토결과코드명
  rvwRltCtn?: string; // 검토결과내용
  tgtCopCd?: string; // 대상법인코드
  crgeDeptCd?: string; // 담당부서코드
  cctrCd?: string; // 코스트센터코드
  cctrNm?: string; // 코스트센터명
  asstNo?: string; // 자산번호
  asstNm?: string; // 자산명
  prdnProcCd?: string; // 공정코드
  prdnProcCdNm?: string; // 공정코드명
  procNm?: string; // 공정명
  acqrDt?: string; // 취득일자
  mdlNm?: string; // 모델명
  asstAcqrAmt?: string; // 자산취득금액
  deprAmt?: string; // 감가상각금액
  asstQty?: string; // 자산수량
  asstQtyUnitNm?: string; // 자산수량단위명
  rmk?: string; // 비고
  atchFileGrId?: string; // 첨부파일그룹ID
  useYn?: string; // 사용여부
  optValCtn1?: string; // 옵션값내용1
  optValCtn2?: string; // 옵션값내용2
  optValCtn3?: string; // 옵션값내용3
  optValCtn4?: string; // 옵션값내용4
  optValCtn5?: string; // 옵션값내용5
  dataInsUserId?: string; // 데이터입력사용자ID
  dataInsUserNm?: string; // 데이터입력사용자명
  dataInsUserIp?: string;
  dataInsDtm?: string; // 데이터입력일시
  dataUpdUserId?: string; // 데이터수정사용자ID
  dataUpdUserNm?: string; // 데이터수정사용자명
  dataUpdUserIp?: string;
  dataUpdDtm?: string; // 데이터수정일시
  progressRate?: string; // 검토율
  atchFileCnt?: number; // 첨부파일수

  asstRvwReqCopCd?: string;
  reqDeptCd?: string;
  rvwReqTitNm?: string;
  reqInsUserNm?: string;
  rvwCpltDt?: string;
  reqInsDeptNm?: string;
  deptRvwRoleYn?: string;
}

export interface IdleAssetRequestMaster extends IdleAssetMaster {
  castType?: string; // 서버측 결재요청상세vo > bizReqData cast 타입
  aprReqId?: string;
  requestDetailList?: IdleAssetRequestDetail[];
}

export interface IdleAssetRequestDetail extends IdleAssetDetail {
  aprReqId?: string;
}

export interface IdleAssetSummary {
  copCd?: string; // 법인코드
  eqpCount?: number; // 설비대수
  asstAcqrAmt?: number; // 자산취득금액
  deprAmt?: number; // 잔존가액
  puseCount?: number; // 활용
  dpslCount?: number; // 매각
  dcdCount?: number; // 폐기
}
