/** @jsxImportSource @emotion/react */
import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { addClass } from '@grapecity/wijmo';
import { CellType } from '@grapecity/wijmo.grid';
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { IconButton } from 'components/buttons/IconSVG';
import { tb } from 'components/layouts/Table';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'hooks/useMessageBar';
import { useLoading } from 'components/process/Loading';
import { ControlBtnGroup, SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import CustomDialog from 'components/modals/common/CustomDialog';
import { ComboBox } from 'components/selects/ComboBox';
import { Code } from 'models/common/CommonCode';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import CustomGrid from 'components/grids/CustomGrid';
import { getExcelFileName } from 'utils/ExcelUtil';
import FacilityInspectionHistoryPopup from './FacilityInspectionHistoryPopup';
import FacilityScheduleRegistPopup from './FacilityScheduleRegistPopup';
import CustomDatepicker from 'components/inputs/CustomDatepicker';
import { hasRole } from 'utils/SessionUtil';
import {
  SetupMaster,
  SetupTgtEqp,
  SetupTgtEqpSchedule,
} from 'models/pjm/SetupScheduleRegistDetail';
import {
  getElmPrjId,
  getSetupEqpTgtScheduleList,
  getSetupScheduleRegistList,
  saveScheduleRegistData,
  saveScheduleRegistTempData,
  updateAtchFileGrId,
  confirmPlan,
} from 'apis/pjm/SetupScheduleRegist';
import _ from 'lodash';
import UserMultiModal from 'components/modals/common/UserMultiModal';
import { GatingContListPopUp } from 'pages/gtng/popup/GatingContListPopUp';
import { CrudCode } from 'models/common/Edit';
import useEvent from 'react-use-event-hook';
import useSessionStore from 'stores/useSessionStore';
import { DateType } from 'react-tailwindcss-datepicker';
import { GridStatusCellTemplate } from 'components/grids/GridStatusCellRenderer';
import FileUploadPopUp from 'pages/common/components/FileUploadPopUp';
import FacilityScheduleChangePopup from './FacilityScheduleChangePopup';

type Props = {
  open: boolean;
  close: () => void;
  condition: any;
  onCallback: (result) => void;
};

const SetupScheduleRegistPopup = ({ open, close, condition, onCallback }: Props) => {
  const gridRef = useRef<any>();
  const [flexEqpRef, setEqpFlexRef] = useState<any>();
  const [flexTgtScheduleRef, setTgtScheduleFlexRef] = useState<any>();
  const [flexItem, setFlexItem] = useState<any>();

  const { t } = useTranslation();
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();
  const { openLoading } = useLoading();
  const userSession = useSessionStore();
  const [errors, setErrors] = useState<any>({});

  const [isOpenFacilityInspectionHistoryModal, setOpenFacilityInspectionHistoryModal] =
    useState<boolean>(false);
  const [isOpenFacilityScheduleRegistModal, setOpenFacilityScheduleRegistModal] =
    useState<boolean>(false);

  const [copCode, setCopCode] = useState<Code[]>([]);
  const [prdnPldoCode, setPrdnPldoCode] = useState<Code[]>([]);
  const [emlPrjTpCode, setElmPrjTpCode] = useState<Code[]>([]);
  const [perdWctCode, setPerdWctCode] = useState<Code[]>([]);

  const [elmPrjIdCode, setMakeElmPrjIdCode] = useState<string>('');
  const [atchFileEqpId, setAtchFileTgtEqpId] = useState<string>('');
  const [mode, setMode] = useState<string>('');
  const [mgrMode, setMgrMode] = useState<string>('');

  const [isOpenUserMultiModal, setOpenUserMultiModal] = useState<boolean>(false);
  const [isOpenSetupMgrMultiModal, setOpenSetupMgrMultiModal] = useState<boolean>(false);
  const [isOpenUserReadModal, setOpenUserReadModal] = useState<boolean>(false);
  const [fileUploadModalCondition, setFileUploadModalCondition] = useState<any>({});
  const [isOpenFileUploadModal, setOpenFileUploadModal] = useState<boolean>(false);
  const [isOpenScheduleChangeModal, setOpenScheduleChangeModal] = useState<boolean>(false);

  const [setupMasterData, setSetupMasterData] = useState<SetupMaster>({
    elmPrjTpCd: 'SETUP',
  });
  const [setupEquipRowData, setSetupEquipRowData] = useState<SetupTgtEqp[]>([]);
  const [setupEqpTgtScheduleRowData, setSetupEqpTgtScheduleRowData] = useState<
    SetupTgtEqpSchedule[]
  >([]);

  const [userIds, setUserIds] = useState<string>('');
  const [historyCondition, setHistoryCondition] = useState<any>({});
  const [scheduleChangeCondition, setScheduleChangeCondition] = useState<any>({});
  const [eqpPlanProcId, setEqpPlanProcId] = useState<string>('');
  const [hasAuth, setHasAuth] = useState<boolean>(false);

  const [searchParam, setSearchParam] = useState<any>();
  const [initParam, setInitParam] = useState<any>();

  const plannigStat = useMemo(() => {
    return setupMasterData.elmPrjStatCd === 'PLANNING' ? true : false;
  }, [setupMasterData]);

  const onGoingStat = useMemo(() => {
    return setupMasterData.elmPrjStatCd === 'ONGOING' ? true : false;
  }, [setupMasterData]);

  const completedStat = useMemo(() => {
    return setupMasterData.elmPrjStatCd === 'COMPLETE' ? true : false;
  }, [setupMasterData]);

  const setupEqpTgtScheduleSubtitle = useMemo(() => {
    const eqp = [searchParam?.eqpLnId, searchParam?.eqpId].filter((o) => o).join('-');
    return [searchParam?.prdnProcCdNm, searchParam?.eqclId, searchParam?.eltrTpCdNm, eqp]
      .filter((o) => o)
      .join(' ');
  }, [searchParam]);
  useEffect(() => {
    initCondition();
    if (hasRole('ADM') || hasRole('UT_MANAGER')) {
      setHasAuth(true);
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(elmPrjIdCode)) {
      handleSearchElmPrjIdCode(elmPrjIdCode);
    }
  }, [elmPrjIdCode]);

  const handleSearchElmPrjIdCode = async (elmPrjIdCode) => {
    const filteredCode = copCode.filter((item) => item.cmnCd === elmPrjIdCode);
    const condition = {
      copCd: elmPrjIdCode,
      optValCtn1: filteredCode[0].optValCtn1,
    };
    const elmPrjId = await getElmPrjId(condition);

    setSetupMasterData({
      ...setupMasterData,
      elmPrjId: elmPrjId || '',
    });
  };

  const initCondition = async () => {
    const copCode: Code[] = await getCommonCodeNames('ELM_COP_CD'); //법인
    const elmPrjTpCode: Code[] = await getCommonCodeNames('ELM_PRJ_TP_CD'); // 구분
    const factoryCode: Code[] = await getCommonCodeNames('FACTORY_CODE'); // 대상설비 공장
    const perdWctCodes: Code[] = await getCommonCodeNames('PERD_WCT_CD'); // 일정주차

    const today = dayjs().format('YYYYMMDD');

    const filteredPerdWct = perdWctCodes.filter(
      (item) =>
        (dayjs(today).isAfter(dayjs(item.optValCtn3)) ||
          dayjs(today).isSame(dayjs(item.optValCtn3))) &&
        (dayjs(today).isBefore(dayjs(item.optValCtn4)) ||
          dayjs(today).isSame(dayjs(item.optValCtn4)))
    );
    setCopCode(copCode);
    setPrdnPldoCode(factoryCode);
    setElmPrjTpCode(elmPrjTpCode);
    setPerdWctCode(filteredPerdWct);
  };

  useEffect(() => {
    if (searchParam?.elmPrjId !== undefined) {
      const condition = {
        ...searchParam,
        crtnYm: perdWctCode[0]?.optValCtn1 || '', // 기준연월
        perdWct: perdWctCode[0]?.optValCtn2 || '', // 주차수
      };
      searchEqpTgtScheduleList(condition);
    }
  }, [searchParam]);

  useEffect(() => {
    if (condition?.mode === 'WRITE') {
      searchList(condition.elmPrjId);
    } else if (condition?.mode === 'NEW') {
      setSetupMasterData({
        elmPrjStatCd: 'PLANNING',
        elmPrjStatCdNm: '일정수립중',
        elmPrjTpCd: 'SETUP', // 구분 기본값 설정 (증설)
      });
    }
    setMode(condition?.mode);
  }, [condition]);

  const setupEquipment = useMemo(() => {
    return [
      {
        binding: 'elmPrjId',
        visible: false,
      },
      {
        binding: 'prdnPldoCdNm',
        header: String(t('pjm.label.Factory', 'Factory')),
        isReadOnly: true,
        align: 'center',
        width: 200,
      },
      {
        binding: 'prdnProcCdNm',
        header: String(t('pjm.label.공정', '공정')),
        isReadOnly: true,
        align: 'center',
        width: 100,
      },
      {
        binding: 'eqclId',
        header: String(t('pjm.label.설비군', '설비군')),
        isReadOnly: true,
        align: 'center',
        width: 90,
      },
      {
        header: String(t('pjm.label.Line', 'Line')),
        align: 'center',
        columns: [
          {
            binding: 'eltrTpCdNm',
            header: String(t('pjm.label.A/C', 'A/C')),
            width: 80,
            isReadOnly: true,
            align: 'center',
          },
          {
            binding: 'eqpLnId',
            header: String(t('pjm.label.호기', '호기')),
            width: 80,
            isReadOnly: true,
            align: 'center',
          },
        ],
      },
      {
        binding: 'eqpId',
        header: String(t('pjm.label.Machine', 'Machine')),
        isReadOnly: true,
        align: 'center',
        width: 120,
      },
      {
        binding: 'schedule',
        header: String(t('pjm.label.일정', '일정')),
        isReadOnly: true,
        align: 'center',
        width: '*',
      },
      {
        binding: 'procStat',
        header: String(t('pjm.label.진행현황', '진행현황')),
        isReadOnly: true,
        align: 'center',
        width: 120,
      },
      {
        binding: 'dlyYn',
        header: String(t('pjm.label.지연', '지연')),
        isReadOnly: true,
        align: 'center',
        width: 100,
      },
      {
        header: String(t('pjm.label.상세보기', '상세보기')),
        isReadOnly: true,
        width: 80,
        align: 'center',
        cssClass: 'WijmoPlay',
        cellTemplate: '<button id="btnPlay"></button>',
      },
      {
        binding: 'dataUpdUserNm',
        header: String(t('pjm.label.최종수정자', '최종수정자')),
        isReadOnly: true,
        width: 120,
        align: 'center',
      },
      {
        binding: 'dataUpdDtm',
        header: String(t('pjm.label.최종수정일', '최종수정일')),
        isReadOnly: true,
        width: 150,
        align: 'center',
      },
    ];
  }, []);

  const eqpOnInitialized = (grid) => {
    setEqpFlexRef(grid);

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;
      const ht = grid.hitTest(e);
      grid.startEditing(true);

      if (e.target instanceof HTMLButtonElement) {
        const col = grid.columns[ht.col];
        const item = grid.rows[ht.row].dataItem;

        if (e.target.id === 'btnPlay') {
          const binding = grid.columns[ht.col].binding;
          const item = grid.rows[ht.row].dataItem;
          setSearchParam({
            ...searchParam,
            elmPrjId: item?.elmPrjId,
            tgtEqpId: item?.tgtEqpId,
            // 설비별 Setup 일정관리 그리드 상단 타이틀 노출용
            prdnProcCdNm: item?.prdnProcCdNm,
            eqclId: item?.eqclId,
            eltrTpCdNm: item?.eltrTpCdNm,
            eqpLnId: item?.eqpLnId,
            eqpId: item?.eqpId,
          });
          setEqpPlanProcId(item?.planProcId);
        }
      }
    });
  };

  const equipSetupScheduleMangement = [
    {
      binding: 'crudKey',
      header: String(t('com.label.상태', '상태')),
      isReadOnly: true,
      width: 50,
      align: 'center',
      cellTemplate: GridStatusCellTemplate,
    },
    {
      binding: 'tgtEqpId',
      visible: false,
    },
    {
      binding: 'prjSpCdNm',
      header: String(t('pjm.label.일정단계', '일정단계')),
      isReadOnly: true,
      align: 'center',
      width: 150,
    },
    {
      binding: 'prjSpCd',
      visible: false,
    },
    {
      binding: 'spMgrIds',
      header: String(t('pjm.label.담당자', '담당자')),
      align: 'center',
      isReadOnly: !hasAuth,
      cssClass: 'WijmoFind',
      cellTemplate: (params) =>
        `<span>${params.item.spMgrNms === null ? '' : params.item.spMgrNms}</span><button id="${
          params.item.curSpYn !== 'Y' ||
          (params.item.crudKey !== CrudCode.UPDATE &&
            params.item.prjSpCd === 'MSPD' &&
            params.item.cpltYn === 'Y')
            ? 'readOpenModal'
            : 'btnOpenMgr'
        }" />`,
      width: 200,
    },
    {
      binding: 'spMgrNms',
      visible: false,
    },
    {
      header: String(t('pjm.label.관리항목', '관리항목')),
      align: 'center',
      columns: [
        {
          binding: 'planEndDt',
          header: String(t('pjm.label.계획일정', '계획일정')),
          width: 120,
          isReadOnly: true,
          align: 'center',
          cellTemplate: CellMaker.makeLink({
            click: (e, ctx) => {
              const condition = {
                elmPrjId: searchParam.elmPrjId,
                tgtEqpId: ctx.item.tgtEqpId,
                prjSpCd: ctx.item.prjSpCd,
              };
              setScheduleChangeCondition(condition);
              setOpenScheduleChangeModal(true);
            },
          }),
        },
        {
          binding: 'isptDt',
          header: String(t('pjm.label.점검일정', '점검일정')),
          width: 110,
          isReadOnly: true,
          align: 'center',
          cellTemplate: CellMaker.makeLink({
            click: (e, ctx) => {
              const condition = {
                elmPrjId: searchParam.elmPrjId,
                tgtEqpId: ctx.item.tgtEqpId,
                prjSpCd: ctx.item.prjSpCd,
                prjSpCdNm: ctx.item.prjSpCdNm,
                planEndDt: ctx.item.planEndDt,
                crtnYm: perdWctCode[0].optValCtn1,
                perdWct: perdWctCode[0].optValCtn2,
                isptDt: ctx.item.isptDt,
                isptYn: ctx.item.isptYn,
                isptUserId: ctx.item.isptUserId,
                isptUserNm: ctx.item.isptUserNm,
                isptCtn: ctx.item.isptCtn,
              };
              setHistoryCondition(condition);
              setOpenFacilityInspectionHistoryModal(true);
            },
          }),
        },
        {
          binding: 'isptYn',
          header: String(t('pjm.label.점검여부', '점검여부')),
          width: 110,
          isReadOnly: true,
          align: 'center',
          cssClass: 'WijmoCheck',
          cellTemplate: (params) => checkYn(params, 'isptYn'),
        },
        {
          binding: 'isptUserNm',
          visible: false,
        },
        {
          binding: 'dlyDdn',
          header: String(t('pjm.label.지연일수', '지연일수')),
          width: 110,
          isReadOnly: true,
          align: 'center',
        },
        {
          binding: 'cpltYn',
          header: String(t('pjm.label.완료여부', '완료여부')),
          width: 110,
          isReadOnly: true,
          align: 'center',
          cssClass: 'WijmoCheck',
          cellTemplate: (params) => checkYn(params, 'cpltYn'),
        },
        {
          binding: 'shipNo',
          header: String(t('pjm.label.선적번호', '선적번호')),
          width: 110,
          align: 'center',
        },
      ],
    },
    {
      binding: 'curSpYn',
      visible: false,
    },
    {
      binding: 'curWeekYn',
      visible: false,
    },
    {
      binding: 'isptCtn',
      header: String(t('pjm.label.점검내용', '점검내용')),
      align: 'left',
      width: 200,
    },
    {
      binding: 'isuTitNm',
      header: String(t('pjm.label.Issue', 'Issue')),
      align: 'left',
      width: 200,
    },
    {
      binding: 'atchFileGrId',
      visible: false,
    },
    {
      binding: 'atchFileCnt',
      header: String(t('pjm.label.첨부파일', '첨부파일')),
      isReadOnly: true,
      align: 'center',
      width: 110,
      cellTemplate: CellMaker.makeLink({
        click: (e, ctx) => {
          let downloadOnly;
          const spMgrIds = ctx.item.spMgrIds;
          const curSpYnY = ctx.item.curSpYn === 'Y' ? true : false;
          if (spMgrIds !== null) {
            const spMgrId = spMgrIds.split(',');
            const mgrAuth = spMgrId.includes(userSession.userId);
            downloadOnly = (hasAuth || mgrAuth) && curSpYnY ? false : true;
          }
          setFileUploadModalCondition({
            atchFileGrId: ctx.item?.atchFileGrId,
            atchFileTpCd: 'SETUP',
            tableName: 'tb_eelmb_prj_tgt_sp_ispt_m',
            bizName: 'prj',
            downloadOnly: downloadOnly,
          });
          setAtchFileTgtEqpId(ctx.item.tgtEqpId);
          setOpenFileUploadModal(true);
        },
      }),
    },
    {
      binding: 'dataUpdUserNm',
      header: String(t('pjm.label.최종수정자', '최종수정자')),
      isReadOnly: true,
      align: 'center',
      width: 120,
    },
    {
      binding: 'dataUpdDtm',
      header: String(t('pjm.label.최종수정일', '최종수정일')),
      isReadOnly: true,
      align: 'center',
      width: 150,
    },
    {
      binding: 'elmPrjId',
      visible: false,
    },
    {
      binding: 'planProcId',
      visible: false,
    },
  ];

  const checkYn = (params, binding) => {
    const checked = params.value === 'Y' ? 'checked' : '';
    const spMgrIds = params.item.spMgrIds;
    const curSpYnY = params.item.curSpYn === 'Y' ? true : false;
    const mspd =
      params.item.crudKey !== CrudCode.UPDATE &&
      params.item.prjSpCd === 'MSPD' &&
      params.item.cpltYn === 'Y'
        ? false
        : true;
    let disabled;
    if (spMgrIds !== null) {
      const spMgrId = spMgrIds.split(',');
      const mgrAuth = spMgrId.includes(userSession.userId);
      disabled = (hasAuth || mgrAuth) && curSpYnY && mspd ? '' : 'disabled';
    } else {
      disabled = curSpYnY ? '' : 'disabled';
    }
    return `<div class="checkbox">
            <input type="checkbox" id=${binding} ${checked} ${disabled}/>  
            <label />
          </div> 
    `;
  };

  const eqpTgtScheduleOnInitialized = (grid) => {
    gridRef.current = grid;
    setTgtScheduleFlexRef(grid);

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;
      const ht = grid.hitTest(e);
      const item = grid.rows[ht.row].dataItem;
      setFlexItem(item);
      grid.startEditing(true);

      if (e.target instanceof HTMLButtonElement) {
        switch (e.target.id) {
          case 'btnOpenMgr':
            setOpenSetupMgrMultiModal(true);
            setUserIds(item.spMgrIds);
            setMgrMode('spMgr');
            // setMode('gridWrtUserId');
            break;
          case 'readOpenModal':
            setOpenUserReadModal(true);
            setUserIds(item.spMgrIds);
            break;
        }
      }
      if (e.target instanceof HTMLInputElement) {
        switch (e.target.id) {
          case 'cpltYn':
            item.cpltYn = e.target.checked ? 'Y' : 'N';
            item.crudKey = CrudCode.UPDATE;
            gridRef.current?.refresh();
            break;
          case 'isptYn':
            item.isptYn = e.target.checked ? 'Y' : 'N';
            item.crudKey = CrudCode.UPDATE;
            gridRef.current?.refresh();
            break;
        }
      }
    });
  };

  const onTgtEqpSchdItemFormatter = useEvent((panel, row, col, cell) => {
    if (CellType.Cell === panel.cellType) {
      const binding = panel.columns[col].binding;
      const item = panel.rows[row].dataItem;
      if (item.curSpYn === 'N' && ['spMgrIds', 'isptCtn', 'isuTitNm'].includes(binding)) {
        cell.ariaReadOnly = true;
      }
      if (
        item.crudKey !== CrudCode.UPDATE &&
        item.prjSpCd === 'MSPD' &&
        item.cpltYn === 'Y' &&
        ['spMgrIds', 'isptCtn', 'isuTitNm'].includes(binding)
      ) {
        cell.ariaReadOnly = true;
      }
      if ((binding === 'shipNo' && item.prjSpCd !== 'FOB') || item.curSpYn === 'N') {
        cell.ariaReadOnly = true;
      }
    }

    if (CellType.ColumnHeader === panel.cellType) {
      const binding = panel.columns[col].binding;
      // 필수항목
      if (['isptCtn'].includes(binding)) {
        addClass(cell, 'dot');
      }
    }
  });

  const beginningEdit = useEvent((grid, e) => {
    const col = grid.columns[e.col];
    const binding = col.binding;
    const item = grid.rows[e.row].dataItem;

    // readonly 설정
    if (item.curSpYn === 'N' && ['spMgrIds', 'isptCtn', 'isuTitNm'].includes(binding)) {
      e.cancel = true;
    }
    if (['spMgrIds'].includes(binding)) {
      e.cancel = true;
    }
    if (
      item.crudKey !== CrudCode.UPDATE &&
      item.prjSpCd === 'MSPD' &&
      item.cpltYn === 'Y' &&
      ['spMgrIds', 'isptCtn', 'isuTitNm'].includes(binding)
    ) {
      e.cancel = true;
    }
    if ((binding === 'shipNo' && item.prjSpCd !== 'FOB') || item.curSpYn === 'N') {
      e.cancel = true;
    }
  });

  const searchList = (emlPrjId) => {
    getSetupScheduleRegistList(emlPrjId).then((result: SetupMaster) => {
      if (result !== null) {
        setSetupMasterData({
          ...result,
          elmPrjTpCd: result?.elmPrjTpCd || 'SETUP', // 구분 기본값 설정 (증설)
        });
        setSetupEquipRowData(result?.setupTgtEqp || []);
      } else {
        openMessageBar({
          type: 'error',
          content: t(
            'com.msg.요청 정보 조회 중 오류가 발생했습니다.',
            '요청 정보 조회 중 오류가 발생했습니다.'
          ),
        });
      }
    });
    gridRef.current?.refresh();
  };

  const searchEqpTgtScheduleList = (condition) => {
    getSetupEqpTgtScheduleList(condition).then((result: SetupTgtEqpSchedule[]) => {
      if (result !== null) {
        setSetupEqpTgtScheduleRowData(result);
      } else {
        openMessageBar({
          type: 'error',
          content: t(
            'com.msg.요청 정보 조회 중 오류가 발생했습니다.',
            '요청 정보 조회 중 오류가 발생했습니다.'
          ),
        });
      }
    });
  };

  const validate = () => {
    if (!setupMasterData.copCd) {
      openMessageBar({
        type: 'error',
        content: `${t('pjm.label.법인을 선택해 주세요.', '법인을 선택해 주세요.')}`,
      });
      return false;
    }
    if (!setupMasterData.elmPrjTpCd) {
      openMessageBar({
        type: 'error',
        content: `${t('pjm.label.구분을 선택해 주세요.', '구분을 선택해 주세요.')}`,
      });
      return false;
    }
    if (!setupMasterData.elmPrjId) {
      openMessageBar({
        type: 'error',
        content: `${t('pjm.label.프로젝트 ID가 없습니다.', '프로젝트 ID가 없습니다.')}`,
      });
      return false;
    }
    if (!setupMasterData.elmPrjNm) {
      openMessageBar({
        type: 'error',
        content: `${t('pjm.label.프로젝트 명을 입력해 주세요.', '프로젝트 명을 입력해 주세요.')}`,
      });
      return false;
    }
    return true;
  };

  /**
   * 계획수립완료 버튼 이벤트
   */
  const handleConfirmPlan = useEvent(() => {
    // master 정보 필수항목 값 확인
    if (!validate()) {
      return;
    }
    if (!setupMasterData.ivesEndDt) {
      openMessageBar({
        type: 'error',
        content: `${t('pjm.label.투자품의 일정을 선택해주세요.', '투자품의 일정을 선택해주세요.')}`,
      });
      return;
    }
    if (!setupMasterData.prjMgrIds) {
      openMessageBar({
        type: 'error',
        content: `${t(
          'pjm.label.투자품의 담당자를 선택해주세요.',
          '투자품의 담당자를 선택해주세요.'
        )}`,
      });
      return;
    }
    if (plannigStat) {
      setupMasterData.crudKey = CrudCode.CREATE;
    }
    setupMasterData.planProcId = eqpPlanProcId;
    setupMasterData.crtnYm = perdWctCode[0].optValCtn1;
    setupMasterData.perdWct = perdWctCode[0].optValCtn2;
    setupMasterData.setupTgtEqp = setupEquipRowData;
    setupMasterData.setupTgtEqpSchedule = setupEqpTgtScheduleRowData;

    openCommonModal({
      modalType: 'confirm',
      content: t(
        'pjm.label.일정등록 후 설비를 추가할 수 없습니다.',
        '일정등록 후 설비를 추가할 수 없습니다.'
      ),
      yesCallback: () => {
        openLoading(true);
        confirmPlan(setupMasterData)
          .then((res) => {
            if (res?.successOrNot !== 'Y') {
              openMessageBar({
                type: 'error',
                content:
                  res.data && typeof res.data === 'string'
                    ? res.data
                    : t('com.label.등록 중 오류가 발생했습니다.', '등록 중 오류가 발생했습니다.'),
              });
              return;
            }
            openMessageBar({
              type: 'confirm',
              content: t('com.label.등록되었습니다.', '등록되었습니다.'),
            });
            searchList(setupMasterData.elmPrjId);
          })
          .finally(() => openLoading(false));
      },
    });
  });

  const handleSave = useEvent(() => {
    // 권한자 수정 : 일정 등록, 일정 변경, 이슈 입력
    // 담당자 수정 : 점검 여부, 완료 여부, 점검 내용, 이슈 입력
    // 투자품의 일정 : 일정 등록 후 투자품의 일정은 Machine의 각 단계의 계획 종료일자보다 클수는 없음
    if (!validate()) {
      return;
    }
    if (!setupMasterData.ivesEndDt) {
      openMessageBar({
        type: 'error',
        content: `${t('pjm.label.투자품의 일정을 선택해주세요.', '투자품의 일정을 선택해주세요.')}`,
      });
      return;
    }
    if (!setupMasterData.prjMgrIds) {
      openMessageBar({
        type: 'error',
        content: `${t(
          'pjm.label.투자품의 담당자를 선택해주세요.',
          '투자품의 담당자를 선택해주세요.'
        )}`,
      });
      return;
    }
    // if (setupEquipRowData.length < 1)
    //   return openMessageBar({
    //     type: 'error',
    //     content: `${t('pjm.label.Setup 대상설비가 없습니다.', 'Setup 대상설비가 없습니다.')}`,
    //   });
    // if (setupEqpTgtScheduleRowData.length < 1)
    //   return openMessageBar({
    //     type: 'error',
    //     content: `${t(
    //       'pjm.label.설비별 Setup 일정관리가 없습니다.',
    //       '설비별 Setup 일정관리가 없습니다.'
    //     )}`,
    //   });

    const validStatusCorU = setupEqpTgtScheduleRowData.filter(
      (element) => element.crudKey == CrudCode.UPDATE
    );
    const valid = validStatusCorU
      .map((rowNode, index) => {
        if (rowNode.isptYn === 'Y' && (rowNode.isptCtn === null || rowNode.isptCtn === ''))
          return `${t(
            'pjm.label.점검을 완료하려면 점검내용을 입력해 주세요.',
            '점검을 완료하려면 점검내용을 입력해 주세요.'
          )}`;
        if (
          (rowNode.isptYn === 'N' || rowNode.isptYn === null || rowNode.isptYn === '') &&
          rowNode.cpltYn === 'Y'
        )
          return `${t(
            'pjm.label.일정단계를 완료하려면 점검완료를 먼저 진행해 주세요.',
            '일정단계를 완료하려면 점검완료를 먼저 진행해 주세요.'
          )}`;
        if (
          (rowNode.isptYn === 'N' || rowNode.isptYn === null) &&
          (rowNode.isptCtn !== null || rowNode.isptCtn !== '')
        )
          return `${t('pjm.label.점검여부를 확인해 주세요', '점검여부를 확인해 주세요')}`;
      })
      .filter((element) => element !== undefined);
    if (valid.length) {
      const content = valid[0]?.toString();
      openMessageBar({ type: 'error', content: content || '' });
      return false;
    }

    if (validStatusCorU.length < 1)
      return openMessageBar({
        type: 'error',
        content: `${t('pjm.label.수정내역이 없습니다.', '수정내역이 없습니다.')}`,
      });

    if (plannigStat) {
      setupMasterData.crudKey = CrudCode.CREATE;
    }

    if (onGoingStat) {
      setupMasterData.crudKey = CrudCode.UPDATE;
    }

    setupMasterData.planProcId = eqpPlanProcId;
    setupMasterData.crtnYm = perdWctCode[0].optValCtn1;
    setupMasterData.perdWct = perdWctCode[0].optValCtn2;
    setupMasterData.setupTgtEqp = setupEquipRowData;
    setupMasterData.setupTgtEqpSchedule = setupEqpTgtScheduleRowData;

    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.저장하시겠습니까?', '저장하시겠습니까?'),
      yesCallback: () => {
        saveScheduleRegistData(setupMasterData).then((result) => {
          if (!result) {
            openMessageBar({
              type: 'error',
              content: t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
            });
            return false;
          } else {
            openMessageBar({
              type: 'confirm',
              content: t('com.label.저장되었습니다.', '저장되었습니다.'),
            });
          }
          // 대상 설비 목록 조회
          btnRefresh();
          // 설비별 Setup 일정관리 목록 조회
          btnEqpTgtScheduleRefresh();
        });
      },
      noCallback: () => {
        return false;
      },
    });
  });

  const handleTempSave = useEvent(() => {
    if (!validate()) {
      return;
    }

    if (plannigStat) {
      setupMasterData.crudKey = CrudCode.CREATE;
    }

    if (onGoingStat) {
      setupMasterData.crudKey = CrudCode.UPDATE;
    }

    setupMasterData.planProcId = eqpPlanProcId;
    setupMasterData.crtnYm = perdWctCode[0].optValCtn1;
    setupMasterData.perdWct = perdWctCode[0].optValCtn2;
    setupMasterData.setupTgtEqp = setupEquipRowData;
    setupMasterData.setupTgtEqpSchedule = setupEqpTgtScheduleRowData;

    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.저장하시겠습니까?', '저장하시겠습니까?'),
      yesCallback: () => {
        saveScheduleRegistTempData(setupMasterData).then((result) => {
          if (!result) {
            openMessageBar({
              type: 'error',
              content: t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
            });
            return false;
          } else {
            openMessageBar({
              type: 'confirm',
              content: t('com.label.저장되었습니다.', '저장되었습니다.'),
            });
          }
        });
      },
      noCallback: () => {
        return false;
      },
    });
  });

  const handleOnChange = (name, value) => {
    setSetupMasterData({
      ...setupMasterData,
      [name]: value,
    });
  };

  const btnEqpExcelExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexEqpRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = 'setup';
    book.saveAsync(getExcelFileName(t('pjm.label.Setup대상설비', 'Setup대상설비')));
  };

  const btnTgtScheduleExcelExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexTgtScheduleRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = 'setup';
    book.saveAsync(getExcelFileName(t('pjm.label.Setup설비별일정관리', 'Setup설비별일정관리')));
  };

  const btnfacilityScheduleRegist = () => {
    // 프로젝트 신규 생성경우 설비별일정등록 클릭시 임시저장 후 팝업오픈
    setInitParam({ elmPrjId: setupMasterData.elmPrjId });
    if (plannigStat) {
      if (setupMasterData.copCd == null || setupMasterData.copCd == '')
        return openMessageBar({
          type: 'error',
          content: `${t('pjm.label.법인을 선택해 주세요.', '법인을 선택해 주세요.')}`,
        });
      if (setupMasterData.elmPrjTpCd == null || setupMasterData.elmPrjTpCd == '')
        return openMessageBar({
          type: 'error',
          content: `${t('pjm.label.구분을 선택해 주세요.', '구분을 선택해 주세요.')}`,
        });
      if (setupMasterData.elmPrjId == null || setupMasterData.elmPrjId == '')
        return openMessageBar({
          type: 'error',
          content: `${t('pjm.label.프로젝트 ID가 없습니다.', '프로젝트 ID가 없습니다.')}`,
        });
      if (setupMasterData.elmPrjNm == null || setupMasterData.elmPrjNm == '')
        return openMessageBar({
          type: 'error',
          content: `${t('pjm.label.프로젝트 명을 입력해 주세요.', '프로젝트 명을 입력해 주세요.')}`,
        });

      setupMasterData.setupTgtEqp = setupEquipRowData;
      setupMasterData.setupTgtEqpSchedule = setupEqpTgtScheduleRowData;

      saveScheduleRegistTempData(setupMasterData).then((result) => {
        if (!result) {
          openMessageBar({
            type: 'error',
            content: t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
          });
          return false;
        } else {
          openMessageBar({
            type: 'confirm',
            content: t('com.label.저장되었습니다.', '저장되었습니다.'),
          });
          setOpenFacilityScheduleRegistModal(true);
        }
      });
    } else {
      setOpenFacilityScheduleRegistModal(true);
    }
  };

  const btnRefresh = () => {
    searchList(setupMasterData.elmPrjId);
  };

  const btnEqpTgtScheduleRefresh = () => {
    if (searchParam?.elmPrjId !== undefined) {
      const condition = {
        elmPrjId: searchParam?.elmPrjId,
        tgtEqpId: searchParam?.tgtEqpId,
        crtnYm: perdWctCode[0]?.optValCtn1 || '', // 기준연월
        perdWct: perdWctCode[0]?.optValCtn2 || '', // 주차수
      };
      searchEqpTgtScheduleList(condition);
    }
  };

  const callbackPrjMgrData = (callbackData) => {
    if (mgrMode === 'spMgr') {
      flexItem['spMgrIds'] = callbackData.userId;
      flexItem['spMgrNms'] = callbackData.empNm;
      flexItem['crudKey'] = CrudCode.UPDATE;
    }

    if (mgrMode === 'prjMgr') {
      setSetupMasterData({
        ...setupMasterData,
        prjMgrIds: callbackData.userId,
        prjMgrNms: callbackData.empNm,
      });
    }

    if (mgrMode === 'prjDevMgr') {
      setSetupMasterData({
        ...setupMasterData,
        prjDevMgrIds: callbackData.userId,
        prjDevMgrNms: callbackData.empNm,
      });
    }
  };

  const callbackFileUpload = (atchFileGrId, cnt) => {
    if (cnt === 1) {
      updateSetupAtchFileGrId(atchFileGrId);
    } else {
      flexItem['atchFileCnt'] = cnt;
      gridRef.current.refresh();
    }
    setOpenFileUploadModal(false);
  };

  // 첨부파일 -> 점검여부나 완료여부를 체크하고 저장 후 첨부파일을 등록하는경우가 아니면 ISTP_M 테이블에 데이터가 없는상태라
  // atchFileGrId 가 업데이트 되지 않음. 첨부파일 업로드전에 미리 데이터 저장을 시키고 첨부파일 저장을 해야할지..?
  // 그런경우 점검여부나 완료여부 체크 없이 저장해도 될지...?
  const updateSetupAtchFileGrId = async (atchFileGrId) => {
    const params = {
      tgtEqpId: searchParam?.tgtEqpId,
      prjSpCd: flexItem['prjSpCd'],
      crtnYm: perdWctCode[0]?.optValCtn1 || '', // 기준연월
      perdWct: perdWctCode[0]?.optValCtn2 || '', // 주차수
      atchFileGrId: atchFileGrId,
    };
    await updateAtchFileGrId(params).then((result) => {
      const condition = {
        elmPrjId: searchParam?.elmPrjId,
        tgtEqpId: searchParam?.tgtEqpId,
        crtnYm: perdWctCode[0]?.optValCtn1 || '', // 기준연월
        perdWct: perdWctCode[0]?.optValCtn2 || '', // 주차수
      };
      searchEqpTgtScheduleList(condition);
    });
  };

  const dialogButtons = useMemo(() => {
    const buttons = [] as ReactElement[];
    // 일정수립중(PLANNING)과 권한(setupManager, ADM)
    if (plannigStat && hasAuth) {
      buttons.push(
        <Button
          key={'draft'}
          css={IconButton.button}
          className="draft"
          onClick={handleTempSave}
          disableRipple
        >
          {t('com.button.임시저장', '임시저장')}
        </Button>
      );
      buttons.push(
        <Button
          key={'confirm'}
          css={IconButton.button}
          className="confirm"
          onClick={handleConfirmPlan}
          disableRipple
        >
          {t('pjm.label.계획수립완료', '계획수립완료')}
        </Button>
      );
    }
    // 일정수립중(PLANNING)과 권한(setupManager, ADM)이 있고 완료(COMPLETE)가 아닐때
    // 담당자(!hasAuth)이고 일정 등록일때(ONGOING)
    else if ((hasAuth && !completedStat) || (!hasAuth && onGoingStat)) {
      buttons.push(
        <Button
          key={'confirm'}
          css={IconButton.button}
          className="confirm"
          onClick={handleSave}
          disableRipple
        >
          {t('com.button.저장', '저장')}
        </Button>
      );
    }
    return buttons;
  }, [plannigStat, completedStat, onGoingStat, hasAuth]);

  return (
    <CustomDialog
      title={t('pjm.label.Setup 일정등록(상세)', 'Setup 일정등록(상세)')}
      size="xl"
      open={open}
      onClose={close}
      onCancel={(dialogButtons || []).length ? close : undefined}
      buttons={dialogButtons || []}
    >
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('pjm.label.Setup Master', 'Setup Master')}</h3>
        </SubTitleGroup>
      </SubTitleLayout>
      <TableContainer css={tb.table} className="colFix9">
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <span className="dot">{t('pjm.label.법인', '법인')}</span>
              </TableCell>
              <TableCell>
                {plannigStat ? (
                  <ComboBox
                    options={copCode}
                    placeholder={String(t('com.label.선택', '선택'))}
                    defaultValue={setupMasterData?.copCd}
                    onChange={(value) => {
                      setSetupMasterData({
                        ...setupMasterData,
                        copCd: value,
                      });
                      setMakeElmPrjIdCode(value);
                    }}
                    readOnly={!plannigStat || !hasAuth}
                  />
                ) : (
                  setupMasterData?.copCd
                )}
              </TableCell>
              <TableCell>
                <span className="dot">{t('pjm.label.구분', '구분')}</span>
              </TableCell>
              <TableCell>
                {plannigStat ? (
                  <ComboBox
                    options={emlPrjTpCode}
                    placeholder={String(t('com.label.선택', '선택'))}
                    defaultValue={setupMasterData?.elmPrjTpCd}
                    onChange={(value) => {
                      setSetupMasterData({
                        ...setupMasterData,
                        crudKey: mode === 'NEW' ? CrudCode.CREATE : CrudCode.UPDATE,
                        elmPrjTpCd: value,
                      });
                    }}
                    readOnly={!plannigStat || !hasAuth}
                  />
                ) : (
                  setupMasterData?.elmPrjTpCdNm
                )}
              </TableCell>
              <TableCell>
                <span className="dot">{t('pjm.label.프로젝트 ID', '프로젝트 ID')}</span>
              </TableCell>
              <TableCell>{setupMasterData?.elmPrjId}</TableCell>
              <TableCell>
                <span>{t('pjm.label.전체일정', '전체일정')}</span>
              </TableCell>
              <TableCell>{setupMasterData?.schedule}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span className="dot">{t('pjm.label.프로젝트 명', '프로젝트 명')}</span>
              </TableCell>
              <TableCell colSpan={3}>
                {plannigStat ? (
                  <CustomInputWithSearch
                    name="elmPrjNm"
                    placeholder={String(
                      t(
                        'pjm.label.Item 프로젝트 명을 입력해 주세요.',
                        '프로젝트 명을 입력해 주세요.'
                      )
                    )}
                    value={setupMasterData?.elmPrjNm}
                    onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                    isError={errors?.elmPrjNm}
                    msgError={String(
                      t('pjm.label.프로젝트 명을 입력해 주세요.', '프로젝트 명을 입력해 주세요.')
                    )}
                    readOnly={!hasAuth}
                  />
                ) : (
                  setupMasterData?.elmPrjNm
                )}
              </TableCell>
              <TableCell>{t('pjm.label.상태', '상태')}</TableCell>
              <TableCell>{setupMasterData?.elmPrjStatCdNm}</TableCell>
              <TableCell>{t('pjm.label.설비대수', '설비대수')}</TableCell>
              <TableCell>{setupMasterData?.eqpQty}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('pjm.label.투자품의 일정', '투자품의 일정')}</TableCell>
              <TableCell>
                <CustomDatepicker
                  isRange={false} // 기간선택인 경우 생략가능
                  value={setupMasterData?.ivesEndDt} // 기본값 (date)
                  onChange={(newValue) => {
                    setSetupMasterData((prev) => ({
                      ...prev,
                      crudKey: mode === 'NEW' ? CrudCode.CREATE : CrudCode.UPDATE,
                      ivesEndDt: newValue as DateType as string,
                    }));
                  }}
                  readOnly={!hasAuth || !plannigStat}
                  disabled={!hasAuth || !plannigStat}
                />
              </TableCell>
              <TableCell>{t('pjm.label.투자품의 담당자', '투자품의 담당자')}</TableCell>
              <TableCell>
                <CustomInputWithSearch
                  name="prjMgr"
                  className="find"
                  placeholder={String(
                    t(
                      'pjm.label.투자품의 담당자를 입력해 주세요.',
                      '투자품의 담당자를 입력해 주세요.'
                    )
                  )}
                  value={setupMasterData.prjMgrNms || ''}
                  defaultValue={setupMasterData.prjMgrNms || ''}
                  onSearchClick={(e) => {
                    if (hasAuth && plannigStat) {
                      setOpenSetupMgrMultiModal(true);
                      setMgrMode('prjMgr');
                      setUserIds(setupMasterData.prjMgrIds || '');
                    } else {
                      setOpenUserReadModal(true);
                      setUserIds(setupMasterData.prjMgrIds || '');
                    }
                    // setSetupMasterData({
                    //   ...setupMasterData,
                    //   prjMgrNms: setupMasterData.prjMgrNms,
                    //   prjMgrIds: setupMasterData.prjMgrIds,
                    // });
                  }}
                  readOnly={true}
                  onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                  isError={errors?.prjMgr}
                  msgError={String(
                    t(
                      'pjm.label.투자품의 담당자를 입력해 주세요.',
                      '투자품의 담당자를 입력해 주세요.'
                    )
                  )}
                />
              </TableCell>
              <TableCell>{t('pjm.label.개발PM 담당자', '개발PM 담당자')}</TableCell>
              <TableCell>
                <CustomInputWithSearch
                  name="prjDevMgr"
                  className="find"
                  placeholder={String(
                    t('pjm.label.개발PM 담당자를 입력해 주세요.', '개발PM 담당자를 입력해 주세요.')
                  )}
                  value={setupMasterData.prjDevMgrNms || ''}
                  defaultValue={setupMasterData.prjDevMgrNms || ''}
                  onSearchClick={(e) => {
                    if (hasAuth && plannigStat) {
                      setOpenSetupMgrMultiModal(true);
                      setMgrMode('prjDevMgr');
                      setUserIds(setupMasterData.prjDevMgrIds || '');
                    } else {
                      setOpenUserReadModal(true);
                      setUserIds(setupMasterData.prjDevMgrIds || '');
                    }
                    // setSetupMasterData({
                    //   ...setupMasterData,
                    //   prjMgrNms: setupMasterData.prjMgrNms,
                    //   prjMgrIds: setupMasterData.prjMgrIds,
                    // });
                  }}
                  readOnly={true}
                  onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                  isError={errors?.prjDevMgr}
                  msgError={String(
                    t('pjm.label.개발PM 담당자를 입력해 주세요.', '개발PM 담당자를 입력해 주세요.')
                  )}
                />
              </TableCell>
              <TableCell>{t('pjm.label.최종수정일', '최종수정일')}</TableCell>
              <TableCell>{setupMasterData?.dataUpdDtm}</TableCell>
            </TableRow>
            {!plannigStat && (
              <>
                <TableRow>
                  <TableCell>
                    <span>{t('pjm.label.준수', '준수')}</span>
                  </TableCell>
                  <TableCell>{setupMasterData?.obsrCnt}</TableCell>
                  <TableCell>
                    <span>{t('pjm.label.지연', '지연')}</span>
                  </TableCell>
                  <TableCell>{setupMasterData?.dlyCnt}</TableCell>
                  <TableCell>
                    <span>{t('pjm.label.적기완료', '적기완료')}</span>
                  </TableCell>
                  <TableCell>{setupMasterData?.cpltCnt}</TableCell>
                  <TableCell>
                    <span>{t('pjm.label.지연완료', '지연완료')}</span>
                  </TableCell>
                  <TableCell>{setupMasterData?.dlyCpltCnt}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <span>{t('pjm.label.지연사유/이슈', '지연사유/이슈')}</span>
                  </TableCell>
                  <TableCell colSpan={7}>
                    <CustomInputWithSearch
                      name="isuTitNm"
                      placeholder={String(
                        t(
                          'pjm.msg.지연사유/이슈를 입력해 주세요.',
                          '지연사유/이슈를 입력해 주세요.'
                        )
                      )}
                      value={setupMasterData?.isuTitNm}
                      onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                      isError={errors?.isuTitNm}
                      msgError={String(
                        t(
                          'pjm.msg.지연사유/이슈를 입력해 주세요.',
                          '지연사유/이슈를 입력해 주세요.'
                        )
                      )}
                      readOnly={!hasAuth || condition?.mode === 'NEW'}
                    />
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('pjm.label.Setup 대상설비', 'Setup 대상설비')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{setupEquipRowData?.length ?? 0}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          {plannigStat && hasAuth && (
            <Button
              css={IconButton.button}
              className="regist"
              onClick={btnfacilityScheduleRegist}
              disableRipple
            >
              {t('com.button.설비별일정등록', '설비별일정등록')}
            </Button>
          )}
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={btnEqpExcelExport}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
          <Button css={IconButton.button} className="refresh" onClick={btnRefresh} disableRipple>
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={setupEquipment}
        rowData={setupEquipRowData}
        isReadOnly={completedStat}
        initialized={eqpOnInitialized}
        height={190}
        isSelector={false}
        isFilter={false}
        allowPinning={false}
      />
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('pjm.label.설비별 Setup 일정관리', '설비별 Setup 일정관리')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{setupEqpTgtScheduleRowData?.length ?? 0}</span>
            {t('com.label.건', '건')}
          </span>
          {setupEqpTgtScheduleSubtitle && (
            <div className="info warning">{setupEqpTgtScheduleSubtitle}</div>
          )}
        </SubTitleGroup>
        <ControlBtnGroup>
          {perdWctCode[0]?.cmnCdNm && (
            <CustomInputWithSearch
              name="perdWctCode"
              value={perdWctCode[0]?.cmnCdNm || ''}
              style={{
                width: '145px',
              }}
              readOnly={true}
            />
          )}
          <SubTitleGroup>
            {perdWctCode[0]?.optValCtn3 && perdWctCode[0]?.optValCtn4 && (
              <span className="info primary">
                {`${dayjs(perdWctCode[0]?.optValCtn3 || '').format('MM월DD일')} ~ ${dayjs(
                  perdWctCode[0]?.optValCtn4 || ''
                ).format('MM월DD일')}`}
              </span>
            )}
          </SubTitleGroup>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={btnTgtScheduleExcelExport}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
          <Button
            css={IconButton.button}
            className="refresh"
            onClick={btnEqpTgtScheduleRefresh}
            disableRipple
          >
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={equipSetupScheduleMangement}
        rowData={setupEqpTgtScheduleRowData}
        initialized={eqpTgtScheduleOnInitialized}
        isReadOnly={completedStat}
        beginningEdit={beginningEdit}
        itemFormatter={onTgtEqpSchdItemFormatter}
        height={200}
        isSelector={false}
        excludePin={['atchFileGrId']}
      />
      {isOpenFacilityInspectionHistoryModal && (
        <FacilityInspectionHistoryPopup
          open={isOpenFacilityInspectionHistoryModal}
          close={() => setOpenFacilityInspectionHistoryModal(false)}
          onCallback={() => console.log('')}
          condition={''}
        />
      )}
      {isOpenFacilityScheduleRegistModal && (
        <FacilityScheduleRegistPopup
          open={isOpenFacilityScheduleRegistModal}
          close={() => setOpenFacilityScheduleRegistModal(false)}
          condition={initParam}
          onRefresh={() => searchList(setupMasterData.elmPrjId)}
        />
      )}
      {isOpenSetupMgrMultiModal && (
        <UserMultiModal
          open={isOpenSetupMgrMultiModal}
          close={() => setOpenSetupMgrMultiModal(false)}
          title={t('com.label.담당자 조회', '담당자 조회')}
          mode={mode}
          defaultUserId={userIds || ''}
          singleSelect={false}
          onCallback={(callbackData: any) => {
            if (callbackData.userId === '') {
              openMessageBar({
                type: 'warning',
                content: t('com.msg.선택된 사용자가 없습니다.', '선택된 사용자가 없습니다.'),
              });
              return false;
            }
            callbackPrjMgrData(callbackData);
          }}
        />
      )}
      {isOpenUserReadModal && (
        <GatingContListPopUp
          open={isOpenUserReadModal}
          close={() => setOpenUserReadModal(false)}
          title={t('com.label.담당자', '담당자')}
          initParam={userIds}
        />
      )}
      {isOpenFacilityInspectionHistoryModal && (
        <FacilityInspectionHistoryPopup
          open={isOpenFacilityInspectionHistoryModal}
          close={() => setOpenFacilityInspectionHistoryModal(false)}
          condition={historyCondition}
          onCallback={() => console.log('')}
        />
      )}
      {isOpenFileUploadModal && (
        <FileUploadPopUp
          open={isOpenFileUploadModal}
          close={() => setOpenFileUploadModal(false)}
          singleSelect={false}
          downloadOnly={fileUploadModalCondition.downloadOnly}
          initParam={{
            atchFileGrId: fileUploadModalCondition.atchFileGrId,
            atchFileTpCd: fileUploadModalCondition.atchFileTpCd,
            optValCtn1: fileUploadModalCondition.tableName,
            bizName: fileUploadModalCondition.bizName,
          }}
          onCallback={(atchFileGrId, fileCount) => {
            callbackFileUpload(atchFileGrId, fileCount);
            setOpenFileUploadModal(false);
          }}
        />
      )}
      {isOpenScheduleChangeModal && (
        <FacilityScheduleChangePopup
          open={isOpenScheduleChangeModal}
          close={() => {
            setOpenScheduleChangeModal(false);
          }}
          condition={scheduleChangeCondition}
          onRefresh={() => btnEqpTgtScheduleRefresh()}
        />
      )}
    </CustomDialog>
  );
};

export default SetupScheduleRegistPopup;
