/** @jsxImportSource @emotion/react */
import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useEvent from 'react-use-event-hook';
import dayjs from 'dayjs';
import { v4 as uuid } from 'uuid';
import { Button } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { InputDate } from '@grapecity/wijmo.input';
import { DataMap, CellType } from '@grapecity/wijmo.grid';
import { ControlBtnGroup, SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import { IconButton } from 'components/buttons/IconSVG';
import { tb } from 'components/layouts/Table';
import CustomGrid from 'components/grids/CustomGrid';
import CustomDatepicker from 'components/inputs/CustomDatepicker';
import { getExcelFileName } from 'utils/ExcelUtil';
import { CrudCode } from 'models/common/Edit';
import { CommonYN } from 'models/common/Common';
import { Code } from 'models/common/CommonCode';
import { useMessageBar } from 'hooks/useMessageBar';
import useSessionStore from 'stores/useSessionStore';
import { getCommonCodeNames, getCommonCodeNamesCondition } from 'apis/admin/CommonCode';
import {
  AsstTpCd,
  IdleAssetDetail,
  IdleAssetRequestDetail,
  IdleAssetRequestMaster,
} from 'models/asst/IdleAsset';
import FileUploadPopUp from 'pages/common/components/FileUploadPopUp';
import WJCellTextarea from 'components/inputs/WJCellTextarea';
import { ComboBox } from 'components/selects/ComboBox';
import EquipmentPopUp from '../../common/popup/EquipmentPopUp';
import { CustomEquipment } from 'models/common/popup/EquipmentMaster';
import { GridStatusCellTemplate } from 'components/grids/GridStatusCellRenderer';
import { findIdleAssetRequestEquipments } from 'apis/asst/IdleAsset';
import _, { toNumber } from 'lodash';
import { addClass, DataType, toggleClass } from '@grapecity/wijmo';

interface Props {
  aprReqId?: string;
  rvwReqId: string;
  isReadOnly?: boolean;
}

const IdleAssetRequest = (props: Props, ref) => {
  const { rvwReqId, isReadOnly = false } = props;
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();
  const userSession = useSessionStore();
  const gridRef = useRef<any>();
  const dateEditor = useRef(
    new InputDate(document.createElement('div'), {
      format: 'yyyy.MM.dd',
      isRequired: false,
      max: new Date(),
    })
  );
  const [masterData, setMasterData] = useState<IdleAssetRequestMaster>({
    asstRvwReqCopCd: userSession?.elmCopCd,
    rvwCpltDt: dayjs().add(14, 'day').format('YYYY.MM.DD'),
  });
  const [rowData, setRowData] = useState<IdleAssetRequestDetail[]>([]);
  const [code, setCode] = useState<any>();
  const [hitTest, setHitTest] = useState<any>();
  const [isOpenCellTextarea, setOpenCellTextarea] = useState<boolean>(false);
  const [fileUploadModalCondition, setFileUploadModalCondition] = useState<any>({});
  const [isOpenFileUploadModal, setOpenFileUploadModal] = useState<boolean>(false);
  const [equipmentModalCondition, setEquipmentModalCondition] = useState<any>({});
  const [isOpenEquipmentModal, setOpenEquipmentModal] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    validate: () => {
      const saveData = rowData.filter((o) => o.crudKey && o.crudKey !== CrudCode.READ);

      if (_.isEmpty(masterData?.asstRvwReqCopCd)) {
        return openMessageBar({
          type: 'error',
          content: `${t('asst.msg.요청법인을 선택해주세요.', '요청법인을 선택해주세요.')}`,
        });
      }
      if (_.isEmpty(masterData?.rvwCpltDt)) {
        return openMessageBar({
          type: 'error',
          content: `${t('asst.msg.검토완료일을 입력해주세요.', '검토완료일을 입력해주세요.')}`,
        });
      }

      if (saveData.length == 0) {
        return openMessageBar({
          type: 'error',
          content: `${t('asst.msg.검토설비를 등록해주세요.', '검토설비를 등록해주세요.')}`,
        });
      }

      const valid = saveData
        .map((o, index) => {
          if (_.isEmpty(o.cctrCd)) {
            return `${index + 1} : ${t(
              'asst.msg.코스트센터를 입력해주세요.',
              '코스트센터를 입력해주세요.'
            )}\n`;
          }
          if (_.isEmpty(o.cctrNm)) {
            return `${index + 1} : ${t(
              'asst.msg.코스트센터명을 입력해주세요.',
              '코스트센터명을 입력해주세요.'
            )}\n`;
          }

          if (_.isEmpty(o.asstNo)) {
            return `${index + 1} : ${t(
              'asst.msg.자산번호를 입력해주세요.',
              '자산번호를 입력해주세요.'
            )}\n`;
          }
          if (_.isEmpty(o.asstNm)) {
            return `${index + 1} : ${t(
              'asst.msg.고정자산명을 입력해주세요.',
              '고정자산명을 입력해주세요.'
            )}\n`;
          }

          if (_.isEmpty(o.prdnProcCd)) {
            return `${index + 1} : ${t(
              'asst.msg.설비 공정을 입력해주세요.',
              '설비 공정을 입력해주세요.'
            )}\n`;
          }

          if (!_.isDate(o.acqrDt)) {
            return `${index + 1} : ${t(
              'asst.msg.자산취득일자를 선택해주세요.',
              '자산취득일자를 선택해주세요.'
            )}\n`;
          }
          if (_.isEmpty(o.mdlNm)) {
            return `${index + 1} : ${t(
              'asst.msg.대응모델을 입력해주세요.',
              '대응모델을 입력해주세요.'
            )}\n`;
          }
          if (!_.isNumber(o.asstAcqrAmt)) {
            return `${index + 1} : ${t(
              'asst.msg.취득가액을 입력해주세요.',
              '취득가액을 입력해주세요.'
            )}\n`;
          }
          if (!_.isNumber(o.deprAmt)) {
            return `${index + 1} : ${t(
              'asst.msg.잔존가액을 입력해주세요.',
              '잔존가액을 입력해주세요.'
            )}\n`;
          }
          //잔존가액 deprAmt , 취득가액 asstAcqrAmt 잔존가액이 취득가액 보다 많으면 안됨
          if (toNumber(o.deprAmt) > toNumber(o.asstAcqrAmt)) {
            return `${index + 1} : ${t(
              'asst.msg.잔존가액이 취득가액보다 많습니다.',
              '잔존가액이 취득가액보다 많습니다.'
            )}\n`;
          }
        })
        .filter((element) => element !== undefined);

      if (valid.length) {
        const content = valid[0]?.toString();
        openMessageBar({ type: 'warning', content: content || '' });
        return;
      }

      return true;
    },
    getBizReqData: (aprReqId: string, reqRsn: string) => {
      const master = {
        ...masterData,
        castType: 'IdleAssetRequest',
        aprReqId: aprReqId,
        rvwReqId: rvwReqId,
        requestDetailList: rowData,
      };
      return master;
    },
  }));

  useEffect(() => {
    getCommonCodes();
  }, []);

  const getCommonCodes = async () => {
    const elmCopCd: Code[] = await getCommonCodeNames('ELM_COP_CD'); // 법인코드
    const asstTpCd: Code[] = await getCommonCodeNames('ASST_TP_CD'); // 자산유형코드
    const factoryCode: Code[] = await getCommonCodeNames('FACTORY_CODE');
    const prdnProcCd = await getCommonCodeNamesCondition({
      optValCtn5: 'Y',
      cmnGrCd: 'PRDN_PROC_CD',
    }); // 공정

    setCode({
      elmCopCd: elmCopCd,
      asstTpCd: asstTpCd,
      factoryCode: factoryCode,
      prdnProcCd: prdnProcCd,
    });
  };

  const handleExportExcel = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(gridRef.current, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = t('asst.label.유휴자산 등록', '불용자산 등록');
    book.saveAsync(getExcelFileName(t('asst.label.유휴자산 등록', '불용자산 등록')));
  };

  const handleDelRow = () => {
    const selectedRows = gridRef.current.rows.filter((r) => r.isSelected);
    if (!selectedRows) return;

    const selectedIds = selectedRows
      .map((row) => {
        return parseInt(row.index!);
      })
      .reverse();

    selectedIds.forEach((item) => {
      if (rowData[item].crudKey === CrudCode.CREATE) {
        delete rowData[item];
      } else {
        rowData[item].crudKey = CrudCode.DELETE;
      }
    });

    const filteredData = rowData.filter((element) => element !== undefined);
    setRowData(filteredData);
  };

  useEffect(() => {
    handleSearch();
  }, [rvwReqId]);

  const handleSearch = () => {
    if (!rvwReqId || !props?.aprReqId) {
      return;
    }
    findIdleAssetRequestEquipments(rvwReqId, props?.aprReqId).then((result) => setRowData(result));
  };

  const handleFileUpload = async (item, row, col) => {
    setFileUploadModalCondition({
      atchFileGrId: item.atchFileGrId,
      tableName: 'tb_eelmb_idl_asst_d',
      bizName: 'asst',
      target: {
        item: item,
        row: row,
        col: col,
      },
    });
    setOpenFileUploadModal(true);
  };

  const onItemFormatter = useEvent((panel, row, col, cell) => {
    if (CellType.ColumnHeader === panel.cellType) {
      const binding = panel.columns[col].binding;
      // 필수항목
      if (
        [
          'asstNo',
          'asstNm',
          'cctrCd',
          'cctrNm',
          'prdnProcCd',
          'acqrDt',
          'mdlNm',
          'asstAcqrAmt',
          'deprAmt',
        ].includes(binding)
      ) {
        addClass(cell, 'dot');
      }
    }

    if (CellType.Cell === panel.cellType) {
      const binding = panel.columns[col].binding;
      const item = panel.rows[row].dataItem;
      // 비고 editable css 변경
      if (['rmk'].includes(binding)) {
        cell.ariaReadOnly = isReadOnly;
      }
    }
  });

  const onInitialized = (grid) => {
    gridRef.current = grid;
    grid.itemFormatter = onItemFormatter;

    grid.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);

      if (ht.row < 0 || ht.col < 0) return;

      if (ht.panel === grid.cells) {
        const item = grid.rows[ht.row].dataItem;
        const binding = grid.columns[ht.col].binding;

        // 첨부파일 클릭
        if ('atchFileCnt' === binding) {
          handleFileUpload(item, ht.row, ht.col);
        }
        // 비고 클릭
        else if ('rmk' === binding) {
          setHitTest(ht);
          setOpenCellTextarea(true);
        }
      }
    });
  };

  const layoutDefinition = [
    {
      binding: 'no',
      header: String(t('com.label.NO', 'NO')),
      align: 'center',
      width: 40,
      isReadOnly: true,
      cellTemplate: (grid) => grid.row._idx + 1,
    },
    {
      binding: 'crudKey',
      header: String(t('com.label.상태', '상태')),
      width: 50,
      isReadOnly: true,
      cellTemplate: GridStatusCellTemplate,
    },
    {
      binding: 'eqpIdExstYn',
      width: 80,
      header: String(t('asst.label.구분', '구분')),
      isReadOnly: true,
      align: 'center',
      cssClass: 'WijmoCheck',
      cellTemplate: (params) => {
        const checked = params.value === 'Y' ? 'checked' : '';
        return `<div class="checkbox">
                  <input type="checkbox" id="eqpIdExstYn_${uuid()}" ${checked} disabled /> 
                  <label />
                </div>
          `;
      },
    },
    {
      binding: 'eqpId',
      header: String(t('asst.label.설비ID', '설비ID')),
      align: 'left',
      width: 100,
      isReadOnly: true,
    },
    {
      binding: 'eqpNm',
      header: String(t('asst.label.설비명', '설비명')),
      align: 'left',
      width: 100,
      isReadOnly: true,
    },
    {
      binding: 'cctrCd',
      header: String(t('asst.label.코스트센터', '코스트센터')),
      align: 'left',
      width: 100,
      maxLength: 10,
    },
    {
      binding: 'cctrNm',
      header: String(t('asst.label.코스트센터명', '코스트센터명')),
      align: 'left',
      width: 100,
      maxLength: 200,
    },
    {
      binding: 'prdnPldoCd',
      header: String(t('asst.label.플랜트코드', '플랜트코드')),
      align: 'center',
      dataMap: new DataMap(code?.factoryCode || [], 'cmnCd', 'cmnCdNm'),
      isReadOnly: true,
    },
    {
      binding: 'asstNo',
      header: String(t('asst.label.자산번호', '자산번호')),
      align: 'left',
      width: 100,
      maxLength: 50,
    },
    {
      binding: 'asstNm',
      header: String(t('asst.label.고정자산명', '고정자산명')),
      align: 'left',
      width: 100,
      maxLength: 300,
    },
    {
      binding: 'prdnProcCd',
      header: String(t('asst.label.공정', '공정')),
      align: 'left',
      width: 100,
      dataMap: new DataMap(code?.prdnProcCd || [], 'cmnCd', 'cmnCdNm'),
      isReadOnly: true,
    },
    {
      binding: 'acqrDt',
      header: String(t('asst.label.자산취득일자', '자산취득일자')),
      align: 'left',
      width: 100,
      cssClass: 'WijmoDate',
      isRequired: false,
      editor: dateEditor.current,
      format: 'yyyy.MM.dd',
      cellTemplate: (params) => (params.value ? dayjs(params.value).format('YYYY.MM.DD') : ''),
      // isReadOnly: false, // 컬럼 속성에서 isReadOnly 설정을 하는 경우 달력 아이콘 미노출되어 별도로 이벤트 제어
    },
    {
      binding: 'mdlNm',
      header: String(t('asst.label.대응모델', '대응모델')),
      align: 'left',
      width: 100,
      maxLength: 200,
    },
    {
      binding: 'asstAcqrAmt',
      header: String(t('asst.label.취득가액(USD)', '취득가액(USD)')),
      align: 'right',
      width: 100,
      dataType: DataType.Number,
    },
    {
      binding: 'deprAmt',
      header: String(t('asst.label.잔존가액(USD)', '잔존가액(USD)')),
      align: 'right',
      width: 100,
      dataType: DataType.Number,
    },
    {
      binding: 'asstQty',
      header: String(t('asst.label.수량', '수량')),
      align: 'center',
      width: 100,
      dataType: DataType.Number,
    },
    {
      binding: 'asstQtyUnitNm',
      header: String(t('asst.label.단위', '단위')),
      align: 'center',
      width: 100,
      maxLength: 100,
    },
    {
      binding: 'eqpOwnCopCd',
      header: String(t('asst.label.법인', '법인')),
      align: 'center',
      width: 100,
      dataMap: new DataMap(code?.elmCopCd || [], 'cmnCd', 'cmnCdNm'),
      isReadOnly: true,
    },
    {
      binding: 'atchFileCnt',
      header: String(t('asst.label.참고자료', '참고자료')),
      align: 'center',
      width: 100,
      isReadOnly: true,
      cellTemplate: (params) =>
        `<div class="fileDiv"><span><em>${(params.value || 0).toLocaleString()}</em></span></div>`,
    },
    {
      binding: 'rmk',
      header: String(t('asst.label.비고', '비고')),
      align: 'left',
      width: 100,
      isReadOnly: true,
    },
    {
      binding: 'atchFileGrId',
      visible: false,
    },
    {
      binding: 'asstTpCd',
      visible: false,
    },
  ];

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('asst.label.유휴자산 활용 요청 정보', '불용자산 활용 요청 정보')}</h3>
        </SubTitleGroup>
      </SubTitleLayout>
      <TableContainer css={tb.table} className="colFix3" style={{ marginBottom: '24px' }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <span>{t('asst.label.요청번호', '요청번호')}</span>
              </TableCell>
              <TableCell>{rvwReqId}</TableCell>
              <TableCell>
                <span className="dot">{t('asst.label.요청법인', '요청법인')}</span>
              </TableCell>
              <TableCell>
                <ComboBox
                  placeholder={String(
                    t('asst.label.요청법인을 선택해주세요.', '요청법인을 선택해주세요.')
                  )}
                  options={code?.elmCopCd}
                  defaultValue={masterData?.asstRvwReqCopCd}
                  onChange={(value: string) => {
                    setMasterData((prev) => ({
                      ...prev,
                      asstRvwReqCopCd: value,
                    }));
                  }}
                />
              </TableCell>
              <TableCell>
                <span className="dot">{t('asst.label.검토완료일자', '검토완료일자')}</span>
              </TableCell>
              <TableCell>
                <CustomDatepicker
                  isRange={false}
                  value={masterData?.rvwCpltDt}
                  minDate={dayjs().toDate()}
                  onChange={(newValue) => {
                    setMasterData((prev) => ({
                      ...prev,
                      rvwCpltDt: newValue as string,
                    }));
                  }}
                />
              </TableCell>
              <TableCell>
                <span>{t('asst.label.요청자', '요청자')}</span>
              </TableCell>
              <TableCell>
                {userSession.empNm} {userSession.deptNm && `(${userSession.deptNm})`}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('asst.label.유휴자산 등록', '불용자산 등록')}</h3>
          <span className="total">
            {t('com.label.총', '총')} <span>{(rowData || []).length.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
          <div className="info warning">
            {t(
              'asst.msg.취득가 및 잔존가는 USD($)로 입력하세요.',
              '취득가 및 잔존가는 USD($)로 입력하세요.'
            )}
          </div>
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="addRow"
            onClick={() => {
              setEquipmentModalCondition({});
              setOpenEquipmentModal(true);
            }}
            disableRipple
          >
            {t('asst.label.설비추가', '설비추가')}
          </Button>
          <Button
            css={IconButton.button}
            className="delRow"
            onClick={() => handleDelRow()}
            disableRipple
          >
            {t('com.button.행삭제', '행삭제')}
          </Button>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={handleExportExcel}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
          <Button
            css={IconButton.button}
            className="refresh"
            onClick={() => handleSearch()}
            disableRipple
          >
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        height={150}
        // excludeFilter={['eqpIdExstYn', 'atchFileCnt']} 1
        isFilter={false}
        allowPinning={false}
        initialized={onInitialized}
      />

      {isOpenCellTextarea && (
        <WJCellTextarea
          grid={gridRef.current}
          hitTest={hitTest}
          close={() => setOpenCellTextarea(false)}
        />
      )}

      {isOpenFileUploadModal && (
        <FileUploadPopUp
          open={isOpenFileUploadModal}
          close={() => setOpenFileUploadModal(false)}
          initParam={{
            atchFileGrId: fileUploadModalCondition.atchFileGrId,
            atchFileTpCd: fileUploadModalCondition.atchFileTpCd,
            optValCtn1: fileUploadModalCondition.tableName,
            bizName: fileUploadModalCondition.bizName,
          }}
          onCallback={(atchFileGrId, fileCount) => {
            const target = fileUploadModalCondition.target;
            if (target) {
              const item = gridRef.current?.rows[target.row].dataItem;
              item.atchFileGrId = atchFileGrId;
              gridRef.current?.setCellData(target.row, target.col, fileCount);
              gridRef.current?.startEditing(false, target.row, target.col);
              gridRef.current?.finishEditing();
            }
            setOpenFileUploadModal(false);
          }}
        />
      )}

      {isOpenEquipmentModal && (
        <EquipmentPopUp
          open={isOpenEquipmentModal}
          close={() => setOpenEquipmentModal(false)}
          title={t('asst.label.설비추가', '설비추가')}
          condition={{
            copCds: [masterData?.asstRvwReqCopCd || ''],
          }}
          isCopCdFix={true}
          isFixedMainLevel={true}
          editable={true}
          singleSelect={false}
          defaultEquipment={(rowData || []).reduce(
            (acc, cur) => [
              ...acc,
              {
                ...cur,
                equipmentId: cur.eqpId,
                equipmentName: cur.eqpNm,
                factoryCode: cur.prdnPldoCd,
                processCode: cur.prdnProcCd,
                copCd: cur.eqpOwnCopCd,
              },
            ],
            [] as CustomEquipment[]
          )}
          onCallback={(result) => {
            console.log(result);
            const equipments = (result || []) as CustomEquipment[];
            setRowData(
              equipments.reduce(
                (acc, cur) => [
                  ...acc,
                  {
                    ...cur,
                    eqpId: cur.equipmentId,
                    eqpNm: cur.equipmentName,
                    asstTpCd: AsstTpCd.EQUIPMENT, // [25.01.14] 현재 자산유형이 1건밖에 없어서 초기값 고정
                    prdnPldoCd: cur.factoryCode,
                    prdnProcCd: cur.processCode,
                    eqpIdExstYn: cur.eqpIdExstYn,
                    useYn: CommonYN.Y,
                    eqpOwnCopCd: cur.copCd,
                  },
                ],
                [] as IdleAssetDetail[]
              )
            );
          }}
        />
      )}
    </>
  );
};

export default forwardRef(IdleAssetRequest);
