/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react';
import { DialogContent, Button, Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { di, TitleWrap, PopupBtnGroup } from 'components/layouts/Dialog';
import { ContentGrid } from 'components/layouts/ContentGrid';
import { useTranslation } from 'react-i18next';
import CustomDatepicker from '../../inputs/CustomDatepicker';
import { CloseBtn } from '../../buttons/CustomButton';
import styled from '@emotion/styled';

const Container = styled.div`
  display: none !important;
`;

type Props = {
  open: boolean;
  close: () => void;
  condition?: {
    date?: string;
  };
  onCallback: (result: string) => void;
};
const DatepickerModal = ({ open, close, condition, onCallback }: Props) => {
  const { t } = useTranslation();
  const selectedDateRef = useRef<string>('');

  const handleClose = () => {
    close();
  };

  return (
    <Container>
      <Dialog open={true} onClose={handleClose} css={di.dialog} maxWidth={'sm'}>
        <TitleWrap>
          <h2>{t('com.label.날짜 선택', '날짜 선택')}</h2>
          <CloseBtn onClick={handleClose} />
        </TitleWrap>
        <div style={{ width: '293px', height: '316px' }}>
          <CustomDatepicker
            isAlwaysOepn={true}
            isRange={false}
            value={condition?.date}
            readOnly={true}
            startFrom={new Date(condition?.date || '')}
            onChange={(newValue) => {
              selectedDateRef.current = (newValue as string).replaceAll('.', '-');
              onCallback(selectedDateRef.current);
              close();
            }}
          />
        </div>
      </Dialog>
    </Container>
  );
};
export default DatepickerModal;
