import {
  CommonRequest,
  Method,
  ServiceName,
  CommonResponse,
  DmlResponse,
} from 'models/common/RestApi';
import { SearchParam, UtMatrixStandardCode } from 'models/ut/UtMatrixStandardCode';
import { callApi } from 'utils/ApiUtil';

export const getUtMatrixStandardCodeList = async (searchParam: SearchParam) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ut/getUtMatrixStandardCodeList`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      ...searchParam,
    }),
  };
  const response: CommonResponse<UtMatrixStandardCode[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as UtMatrixStandardCode[];
};

export const saveUtMatrixStandardCodeList = async (saveData: UtMatrixStandardCode[]) => {
  console.log('saveData', saveData);
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/ut/saveUtMatrixStandardCodeList`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: saveData,
  };
  const response: CommonResponse = await callApi(request);
  return response;
};

export const checkDuplicationStandardCode = async (saveData: UtMatrixStandardCode[]) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/ut/checkDuplicationStandardCode`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: saveData,
  };
  const response = await callApi(request);
  console.log('response', response);
  return response.data;
};

export const deleteUtMatrixStandardCodeList = async (deleteData: UtMatrixStandardCode[]) => {
  console.log('deleteData', deleteData);
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/ut/deleteUtMatrixStandardCodeList`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: deleteData,
  };
  const response = await callApi(request);

  return response.data;
};
