/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useMemo, useRef } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Button } from '@mui/material';
import {
  SubTitleLayout,
  SubTitleGroup,
  ControlBtnGroup,
  StatusDot,
} from 'components/layouts/ContentLayout';
import {
  SearchBox,
  SearchBoxRow,
  InputBox,
  SearchRows,
  SearchCols,
} from 'components/layouts/SearchBox';
import { IconButton } from 'components/buttons/IconSVG';
import { useTranslation } from 'react-i18next';
import { getCommonCodeNames, getCommonCodeNamesCondition } from 'apis/admin/CommonCode';
import { ComboBox, MultiComboBox } from 'components/selects/ComboBox';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'hooks/useMessageBar';
import CustomGrid from 'components/grids/CustomGrid';
import { getExcelFileName } from 'utils/ExcelUtil';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';
import { Code } from 'models/common/CommonCode';
import { addClass } from '@grapecity/wijmo';
import { CellType } from '@grapecity/wijmo.grid';
import { getEquGroupParameter } from '../../apis/pjm/SetupManager';
import SearchBoxButtons from '../../components/buttons/SearchBoxButtons';
import { SetupDashboardLegendPopup } from './popup/SetupDashboardLegendPopup';
import { hasRole } from '../../utils/SessionUtil';
import { FacilitySetup, FacilitySetupCondition } from 'models/pjm/FacilitySetupList';
import { getFacilitySetupList } from 'apis/pjm/FacilitySetupList';
import SetupScheduleRegistPopup from './popup/SetupScheduleRegistPopup';
import useEvent from 'react-use-event-hook';
import _ from 'lodash';
import { ResultRegistCondition } from 'models/pjm/SetupResultRegist';
import SetupResultRegistPopup from './popup/SetupResultRegistPopup';

interface PageCondition {
  elmPrjId?: string;
  mode?: string;
}

const FacilitySetupListPage = () => {
  const [flexRef, setFlexRef] = useState<any>();
  const gridRef = useRef<any>();
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();
  const { openCommonModal } = useCommonModal();

  const [code, setCode] = useState<any>({});
  const [rowData, setRowData] = useState<FacilitySetup[]>([]);

  const initParam = {
    copCds: [],
    copCd: '',
    prdnPldoCds: [],
    prdnPldoCd: '',
    prdnProcCds: [],
    prdnProcCd: '',
    eqclIds: [],
    eqclId: '',
    eltrTpCds: [],
    eltrTpCd: '',
    elmPrjTpCds: ['SETUP'],
    elmPrjTpCd: '',
    elmPrjId: '',
    elmPrjNm: '',
    elmPrjStatCd: '',
    elmPrjStatCds: [],
  };
  const [condition, setCondition] = useState<FacilitySetupCondition>(initParam);
  const [scheduleRegtistCondition, setScheduleRegtistCondition] =
    useState<PageCondition>(condition);
  const [resultRegistCondition, setResultRegistCondition] = useState<ResultRegistCondition>({});

  const [isOpenlegendPopUp, setOpenlegendPopUp] = useState<boolean>(false);
  const [isOpenScheduleRegistModal, setOpenScheduleRegistModal] = useState<boolean>(false);
  const [isOpenResultRegistModal, setOpenResultRegistModal] = useState<boolean>(false);

  useEffect(() => {
    getCommonCodes();
  }, []);

  useEffect(() => {
    const init = async () => {
      await getCommonCodes();
      await handleSearch();
    };
    init();
  }, []);

  const getCommonCodes = async () => {
    const elmCopCd: Code[] = await getCommonCodeNames('ELM_COP_CD'); // 법인코드
    const elmPrjTpCd: Code[] = await getCommonCodeNames('ELM_PRJ_TP_CD'); // 구분
    const elmPrjStatCode: Code[] = await getCommonCodeNames('ELM_PRJ_STAT_CD'); // 상태
    const eqclId = await getEquGroupParameter(); // 설비군
    const factoryCode = await getCommonCodeNames('FACTORY_CODE'); // 공장
    const prjSpCd: Code[] = await getCommonCodeNamesCondition({
      optValCtn1: 'Y',
      cmnGrCd: 'PRJ_SP_CD',
    }); // 프로젝트 단계
    const eltrTpCd: Code[] = await getCommonCodeNames('ELTR_TP_CD');
    const prdnProcCd = await getCommonCodeNamesCondition({
      cmnGrCd: 'PRDN_PROC_CD',
    }); // 공정

    setCode({
      elmCopCd: elmCopCd,
      elmPrjTpCd: elmPrjTpCd,
      eqclId: eqclId,
      factoryCode: factoryCode,
      prjSpCd: prjSpCd,
      eltrTpCd: eltrTpCd,
      prdnProcCd: prdnProcCd,
      elmPrjStatCd: elmPrjStatCode,
    });
  };

  const handleConditionChange = (name, value) => {
    setCondition({ ...condition, [name]: value });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSearch = () => {
    getFacilitySetupList(condition).then((result: FacilitySetup[]) => {
      if (result !== null) {
        setRowData(result);
      } else {
        openMessageBar({
          type: 'error',
          content: t(
            'com.msg.요청 정보 조회 중 오류가 발생했습니다.',
            '요청 정보 조회 중 오류가 발생했습니다.'
          ),
        });
      }
    });
  };

  const handleRefresh = () => {
    handleSearch();
  };

  const handleExportExcel = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(gridRef.current, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = t('pjm.label.Setup List', 'Setup List');
    book.saveAsync(getExcelFileName(t('pjm.label.Setup List', 'Setup List')));
  };

  const layoutDefinition = [
    {
      binding: 'no',
      header: String(t('com.label.NO', 'NO')),
      align: 'center',
      width: 40,
      isReadOnly: true,
      cellTemplate: (grid) => grid.row._idx + 1,
    },
    {
      binding: 'copCd',
      header: String(t('pjm.label.법인', '법인')),
      align: 'center',
      width: 120,
    },
    // {
    //   binding: 'elmPrjTpCdNm',
    //   header: String(t('pjm.label.구분', '구분')),
    //   align: 'center',
    //   width: 50,
    // },
    {
      binding: 'elmPrjId',
      header: String(t('pjm.label.프로젝트 ID', '프로젝트 ID')),
      align: 'center',
      width: 120,
      cellTemplate: CellMaker.makeLink({
        text: '<span>${item.elmPrjId}</span>',
        click: (e, ctx) => {
          btnGridScheduleRegist(ctx.item);
        },
      }),
    },
    {
      binding: 'elmPrjNm',
      header: String(t('pjm.label.프로젝트 명', '프로젝트 명')),
      width: 200,
      align: 'left',
    },
    {
      binding: 'elmPrjStatCdNm',
      header: String(t('pjm.label.상태', '상태')),
      align: 'center',
      width: 120,
    },
    {
      binding: 'prdnPldoCdNm',
      header: String(t('pjm.label.Factory', 'Factory')),
      width: 150,
      align: 'left',
    },
    {
      binding: 'prdnProcCdNm',
      header: String(t('pjm.label.공정', '공정')),
      align: 'center',
      width: 100,
    },
    {
      binding: 'eqclId',
      header: String(t('pjm.label.설비군', '설비군')),
      isReadOnly: true,
      width: 90,
      align: 'center',
      // cellTemplate: CellMaker.makeLink({
      //   text: '<span>${item.eqclId}</span>',
      //   click: (e, ctx) => {
      //     btnGridScheduleRegist(ctx.item);
      //   },
      // }),
    },
    {
      header: String(t('pjm.label.Line', 'Line')),
      align: 'center',
      columns: [
        {
          binding: 'eltrTpCdNm',
          header: String(t('pjm.label.A/C', 'A/C')),
          align: 'center',
          width: 80,
        },
        {
          binding: 'eqpLnId',
          header: String(t('pjm.label.호기', '호기')),
          align: 'center',
          width: 80,
        },
      ],
    },
    {
      binding: 'eqpId',
      header: String(t('pjm.label.Machine', 'Machine')),
      width: 120,
      align: 'center',
    },
    {
      header: String(t('pjm.label.PO발행', 'PO발행')),
      align: 'center',
      columns: [
        {
          binding: 'poIssuClas',
          header: String(t('pjm.label.계획일정', '계획일정')),
          align: 'center',
          width: 120,
          cellTemplate: (params) => {
            return `<span>${
              params.item.poIssuDt === null ? '' : btnResultRegist(params, params.item.poIssuDt)
            }</span>`;
          },
        },
        {
          binding: 'poIssuClas',
          header: String(t('pjm.label.현재일정', '현재일정')),
          align: 'center',
          width: 120,
          cellTemplate: (params) => {
            return `<span>${params.item.poIssuStat === null ? '' : params.item.poIssuStat}</span>`;
          },
        },
      ],
    },
    {
      header: String(t('pjm.label.시운전자재', '시운전자재')),
      align: 'center',
      columns: [
        {
          binding: 'atMatClas',
          header: String(t('pjm.label.계획일정', '계획일정')),
          align: 'center',
          width: 120,
          cellTemplate: (params) => {
            return `<span>${
              params.item.atMatDt === null ? '' : btnResultRegist(params, params.item.atMatDt)
            }</span>`;
          },
        },
        {
          binding: 'atMatClas',
          header: String(t('pjm.label.현재일정', '현재일정')),
          align: 'center',
          width: 120,
          cellTemplate: (params) => {
            return `<span>${params.item.atMatStat === null ? '' : params.item.atMatStat}</span>`;
          },
        },
        {
          binding: 'atMatClas',
          header: String(t('pjm.label.지연일수', '지연일수')),
          align: 'center',
          width: 120,
          cellTemplate: (params) => {
            return `<span>${
              params.item.atMatDlyDdn === null ? '' : params.item.atMatDlyDdn
            }</span>`;
          },
        },
        {
          binding: 'atMatClas',
          header: String(t('pjm.label.완료여부', '완료여부')),
          align: 'center',
          width: 80,
          cellTemplate: (params) => {
            return `<span>${
              params.item.atMatCpltYn === null ? '' : params.item.atMatCpltYn
            }</span>`;
          },
        },
      ],
    },
    {
      header: String(t('pjm.label.FAT', 'FAT')),
      align: 'center',
      columns: [
        {
          binding: 'fatClas',
          header: String(t('pjm.label.계획일정', '계획일정')),
          align: 'center',
          width: 120,
          cellTemplate: (params) => {
            return `<span>${
              params.item.fatDt === null ? '' : btnResultRegist(params, params.item.fatDt)
            }</span>`;
          },
        },
        {
          binding: 'fatClas',
          header: String(t('pjm.label.현재일정', '현재일정')),
          align: 'center',
          width: 120,
          cellTemplate: (params) => {
            return `<span>${params.item.fatStat === null ? '' : params.item.fatStat}</span>`;
          },
        },
        {
          binding: 'fatClas',
          header: String(t('pjm.label.지연일수', '지연일수')),
          align: 'center',
          width: 120,
          cellTemplate: (params) => {
            return `<span>${params.item.fatDlyDdn === null ? '' : params.item.fatDlyDdn}</span>`;
          },
        },
        {
          binding: 'fatClas',
          header: String(t('pjm.label.완료여부', '완료여부')),
          align: 'center',
          width: 80,
          cellTemplate: (params) => {
            return `<span>${params.item.fatCpltYn === null ? '' : params.item.fatCpltYn}</span>`;
          },
        },
      ],
    },
    {
      header: String(t('pjm.label.FOB', 'FOB')),
      align: 'center',
      columns: [
        {
          binding: 'fobClas',
          header: String(t('pjm.label.계획일정', '계획일정')),
          align: 'center',
          width: 120,
          cellTemplate: (params) => {
            return `<span>${
              params.item.fobDt === null ? '' : btnResultRegist(params, params.item.fobDt)
            }</span>`;
          },
        },
        {
          binding: 'fobClas',
          header: String(t('pjm.label.현재일정', '현재일정')),
          align: 'center',
          width: 120,
          cellTemplate: (params) => {
            return `<span>${params.item.fobStat === null ? '' : params.item.fobStat}</span>`;
          },
        },
        {
          binding: 'fobClas',
          header: String(t('pjm.label.지연일수', '지연일수')),
          align: 'center',
          width: 120,
          cellTemplate: (params) => {
            return `<span>${params.item.fobDlyDdn === null ? '' : params.item.fobDlyDdn}</span>`;
          },
        },
        {
          binding: 'fobClas',
          header: String(t('pjm.label.완료여부', '완료여부')),
          align: 'center',
          width: 80,
          cellTemplate: (params) => {
            return `<span>${params.item.fobCpltYn === null ? '' : params.item.fobCpltYn}</span>`;
          },
        },
        {
          binding: 'fobClas',
          header: String(t('pjm.label.선적번호', '선적번호')),
          align: 'center',
          width: 120,
          cellTemplate: (params) => {
            return `<span>${params.item.fobShipNo === null ? '' : params.item.fobShipNo}</span>`;
          },
        },
      ],
    },
    {
      header: String(t('pjm.label.설비반입', '설비반입')),
      align: 'center',
      columns: [
        {
          binding: 'eqpCrryClas',
          header: String(t('pjm.label.계획일정', '계획일정')),
          align: 'center',
          width: 120,
          cellTemplate: (params) => {
            return `<span>${
              params.item.eqpCrryDt === null ? '' : btnResultRegist(params, params.item.eqpCrryDt)
            }</span>`;
          },
        },
        {
          binding: 'eqpCrryClas',
          header: String(t('pjm.label.현재일정', '현재일정')),
          align: 'center',
          width: 120,
          cellTemplate: (params) => {
            return `<span>${
              params.item.eqpCrryStat === null ? '' : params.item.eqpCrryStat
            }</span>`;
          },
        },
        {
          binding: 'eqpCrryClas',
          header: String(t('pjm.label.지연일수', '지연일수')),
          align: 'center',
          width: 120,
          cellTemplate: (params) => {
            return `<span>${
              params.item.eqpCrryDlyDdn === null ? '' : params.item.eqpCrryDlyDdn
            }</span>`;
          },
        },
        {
          binding: 'eqpCrryClas',
          header: String(t('pjm.label.완료여부', '완료여부')),
          align: 'center',
          width: 80,
          cellTemplate: (params) => {
            return `<span>${
              params.item.eqpCrryCpltYn === null ? '' : params.item.eqpCrryCpltYn
            }</span>`;
          },
        },
      ],
    },
    {
      header: String(t('pjm.label.설치', '설치')),
      align: 'center',
      columns: [
        {
          binding: 'istlClas',
          header: String(t('pjm.label.계획일정', '계획일정')),
          align: 'center',
          width: 120,
          cellTemplate: (params) => {
            return `<span>${
              params.item.istlDt === null ? '' : btnResultRegist(params, params.item.istlDt)
            }</span>`;
          },
        },
        {
          binding: 'istlClas',
          header: String(t('pjm.label.현재일정', '현재일정')),
          align: 'center',
          width: 120,
          cellTemplate: (params) => {
            return `<span>${params.item.istlStat === null ? '' : params.item.istlStat}</span>`;
          },
        },
        {
          binding: 'istlClas',
          header: String(t('pjm.label.지연일수', '지연일수')),
          align: 'center',
          width: 120,
          cellTemplate: (params) => {
            return `<span>${params.item.istlDlyDdn === null ? '' : params.item.istlDlyDdn}</span>`;
          },
        },
        {
          binding: 'istlClas',
          header: String(t('pjm.label.완료여부', '완료여부')),
          align: 'center',
          width: 80,
          cellTemplate: (params) => {
            return `<span>${params.item.istlCpltYn === null ? '' : params.item.istlCpltYn}</span>`;
          },
        },
      ],
    },
    {
      header: String(t('pjm.label.I/O Check', 'I/O Check')),
      align: 'center',
      columns: [
        {
          binding: 'iocClas',
          header: String(t('pjm.label.계획일정', '계획일정')),
          align: 'center',
          width: 120,
          cellTemplate: (params) => {
            return `<span>${
              params.item.iocDt === null ? '' : btnResultRegist(params, params.item.iocDt)
            }</span>`;
          },
        },
        {
          binding: 'iocClas',
          header: String(t('pjm.label.현재일정', '현재일정')),
          align: 'center',
          width: 120,
          cellTemplate: (params) => {
            return `<span>${params.item.iocStat === null ? '' : params.item.iocStat}</span>`;
          },
        },
        {
          binding: 'iocClas',
          header: String(t('pjm.label.지연일수', '지연일수')),
          align: 'center',
          width: 120,
          cellTemplate: (params) => {
            return `<span>${params.item.iocDlyDdn === null ? '' : params.item.iocDlyDdn}</span>`;
          },
        },
        {
          binding: 'iocClas',
          header: String(t('pjm.label.완료여부', '완료여부')),
          align: 'center',
          width: 80,
          cellTemplate: (params) => {
            return `<span>${params.item.iocCpltYn === null ? '' : params.item.iocCpltYn}</span>`;
          },
        },
      ],
    },
    {
      header: String(t('pjm.label.Wet Run', 'Wet Run')),
      align: 'center',
      columns: [
        {
          binding: 'wetRunClas',
          header: String(t('pjm.label.계획일정', '계획일정')),
          align: 'center',
          width: 120,
          cellTemplate: (params) => {
            return `<span>${
              params.item.wetRunDt === null ? '' : btnResultRegist(params, params.item.wetRunDt)
            }</span>`;
          },
        },
        {
          binding: 'wetRunClas',
          header: String(t('pjm.label.현재일정', '현재일정')),
          align: 'center',
          width: 120,
          cellTemplate: (params) => {
            return `<span>${params.item.wetRunStat === null ? '' : params.item.wetRunStat}</span>`;
          },
        },
        {
          binding: 'wetRunClas',
          header: String(t('pjm.label.지연일수', '지연일수')),
          align: 'center',
          width: 120,
          cellTemplate: (params) => {
            return `<span>${
              params.item.wetRunDlyDdn === null ? '' : params.item.wetRunDlyDdn
            }</span>`;
          },
        },
        {
          binding: 'wetRunClas',
          header: String(t('pjm.label.완료여부', '완료여부')),
          align: 'center',
          width: 80,
          cellTemplate: (params) => {
            return `<span>${
              params.item.wetRunDlyDdn === null ? '' : params.item.wetRunDlyDdn
            }</span>`;
          },
        },
      ],
    },
    {
      header: String(t('pjm.label.Quality check', 'Quality check')),
      align: 'center',
      columns: [
        {
          binding: 'qcClas',
          header: String(t('pjm.label.계획일정', '계획일정')),
          align: 'center',
          width: 120,
          cellTemplate: (params) => {
            return `<span>${
              params.item.qcDt === null ? '' : btnResultRegist(params, params.item.qcDt)
            }</span>`;
          },
        },
        {
          binding: 'qcClas',
          header: String(t('pjm.label.현재일정', '현재일정')),
          align: 'center',
          width: 120,
          cellTemplate: (params) => {
            return `<span>${params.item.qcStat === null ? '' : params.item.qcStat}</span>`;
          },
        },
        {
          binding: 'qcClas',
          header: String(t('pjm.label.지연일수', '지연일수')),
          align: 'center',
          width: 120,
          cellTemplate: (params) => {
            return `<span>${params.item.qcDlyDdn === null ? '' : params.item.qcDlyDdn}</span>`;
          },
        },
        {
          binding: 'qcClas',
          header: String(t('pjm.label.완료여부', '완료여부')),
          align: 'center',
          width: 80,
          cellTemplate: (params) => {
            return `<span>${params.item.qcDlyDdn === null ? '' : params.item.qcDlyDdn}</span>`;
          },
        },
      ],
    },
    {
      header: String(t('pjm.label.SAT', 'SAT')),
      align: 'center',
      columns: [
        {
          binding: 'satClas',
          header: String(t('pjm.label.계획일정', '계획일정')),
          align: 'center',
          width: 120,
          cellTemplate: (params) => {
            return `<span>${
              params.item.satDt === null ? '' : btnResultRegist(params, params.item.satDt)
            }</span>`;
          },
        },
        {
          binding: 'satClas',
          header: String(t('pjm.label.현재일정', '현재일정')),
          align: 'center',
          width: 120,
          cellTemplate: (params) => {
            return `<span>${params.item.satStat === null ? '' : params.item.satStat}</span>`;
          },
        },
        {
          binding: 'satClas',
          header: String(t('pjm.label.지연일수', '지연일수')),
          align: 'center',
          width: 120,
          cellTemplate: (params) => {
            return `<span>${params.item.satDlyDdn === null ? '' : params.item.satDlyDdn}</span>`;
          },
        },
        {
          binding: 'satDlyDdn',
          header: String(t('pjm.label.완료여부', '완료여부')),
          align: 'center',
          width: 80,
          cellTemplate: (params) => {
            return `<span>${params.item.satCpltYn === null ? '' : params.item.satCpltYn}</span>`;
          },
        },
      ],
    },
    {
      header: String(t('pjm.label.샘플생산', '샘플생산')),
      align: 'center',
      columns: [
        {
          binding: 'smplPrdnClas',
          header: String(t('pjm.label.계획일정', '계획일정')),
          align: 'center',
          width: 120,
          cellTemplate: (params) => {
            return `<span>${
              params.item.smplPrdnDt === null ? '' : btnResultRegist(params, params.item.smplPrdnDt)
            }</span>`;
          },
        },
        {
          binding: 'smplPrdnClas',
          header: String(t('pjm.label.현재일정', '현재일정')),
          align: 'center',
          width: 120,
          cellTemplate: (params) => {
            return `<span>${
              params.item.smplPrdnStat === null ? '' : params.item.smplPrdnStat
            }</span>`;
          },
        },
        {
          binding: 'smplPrdnClas',
          header: String(t('pjm.label.지연일수', '지연일수')),
          align: 'center',
          width: 120,
          cellTemplate: (params) => {
            return `<span>${
              params.item.smplPrdnDlyDdn === null ? '' : params.item.smplPrdnDlyDdn
            }</span>`;
          },
        },
        {
          binding: 'smplPrdnClas',
          header: String(t('pjm.label.완료여부', '완료여부')),
          align: 'center',
          width: 80,
          cellTemplate: (params) => {
            return `<span>${
              params.item.smplPrdnCpltYn === null ? '' : params.item.smplPrdnCpltYn
            }</span>`;
          },
        },
      ],
    },
    {
      header: String(t('pjm.label.양산', '양산')),
      align: 'center',
      columns: [
        {
          binding: 'mspdClas',
          header: String(t('pjm.label.계획일정', '계획일정')),
          align: 'center',
          width: 120,
          cellTemplate: (params) => {
            return `<span>${
              params.item.mspdDt === null ? '' : btnResultRegist(params, params.item.mspdDt)
            }</span>`;
          },
        },
        {
          binding: 'mspdClas',
          header: String(t('pjm.label.현재일정', '현재일정')),
          align: 'center',
          width: 120,
          cellTemplate: (params) => {
            return `<span>${params.item.mspdStat === null ? '' : params.item.mspdStat}</span>`;
          },
        },
        {
          binding: 'mspdClas',
          header: String(t('pjm.label.지연일수', '지연일수')),
          align: 'center',
          width: 120,
          cellTemplate: (params) => {
            return `<span>${params.item.mspdDlyDdn === null ? '' : params.item.mspdDlyDdn}</span>`;
          },
        },
        {
          binding: 'mspdClas',
          header: String(t('pjm.label.완료여부', '완료여부')),
          align: 'center',
          width: 80,
          cellTemplate: (params) => {
            return `<span>${params.item.mspdCpltYn === null ? '' : params.item.mspdCpltYn}</span>`;
          },
        },
      ],
    },
  ];

  const btnGridScheduleRegist = (params) => {
    const condition = {
      elmPrjId: params?.elmPrjId,
      mode: 'WRITE',
    };
    setScheduleRegtistCondition(condition);
    setOpenScheduleRegistModal(true);
  };

  const btnResultRegist = (params, binding) => {
    if (['OBSR', 'DLY'].includes(params.value)) {
      return `<a href="#" class="wj-cell-maker" tabindex="-1" id="btnOpen">${
        binding === null ? '' : binding
      }</a>`;
    } else {
      return `<span>${binding === null ? '' : binding}</span>`;
    }
  };

  const btnScheduleRegist = (params) => {
    const selectedRowNodes = flexRef.selectedRows;
    const isSelected = selectedRowNodes.filter((item) => item.isSelected);
    const selectedData = isSelected.map(({ dataItem }) => dataItem);

    if (isSelected.length > 1) {
      openMessageBar({
        type: 'error',
        content: t('pjm.label.Setup을 하나만 선택해 주세요.', 'Setup을 하나만 선택해 주세요.'),
      });
      return false;
    }

    if (isSelected.length === 1) {
      const elmPrjId = selectedData[0].elmPrjId;
      const condition = {
        elmPrjId: elmPrjId,
        mode: 'WRITE',
      };
      setScheduleRegtistCondition(condition);
      setOpenScheduleRegistModal(true);
    } else if (isSelected.length === 0) {
      const condition = {
        elmPrjId: '',
        mode: 'NEW',
      };
      setScheduleRegtistCondition(condition);
      setOpenScheduleRegistModal(true);
    }
  };

  const onInitialized = (grid) => {
    setFlexRef(grid);

    grid.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;
      // grid.startEditing(true);

      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      const binding = grid.columns[ht.col].binding;
      const item = grid.rows[ht.row].dataItem;

      if (e.target instanceof HTMLAnchorElement) {
        if (e.target.id === 'btnOpen') {
          setResultRegistCondition({
            elmPrjId: item.elmPrjId,
            prdnPldoCd: item.prdnPldoCd,
            planProcId: item.planProcId,
            eqpLnId: item.eqpLnId,
            eltrTpCd: item.eltrTpCd,
            prjSpCd: _.toUpper(_.snakeCase(binding.replace('Clas', ''))),
          });
          setOpenResultRegistModal(true);
        }
      }
    });
  };

  const onItemFormatter = useEvent((panel, row, col, cell) => {
    if (CellType.Cell === panel.cellType) {
      const binding = panel.columns[col].binding;
      const item = panel.rows[row].dataItem;
      if (
        binding.includes('Dt') ||
        binding.includes('Stat') ||
        binding.includes('Crs') ||
        binding.includes('Clas') ||
        binding.includes('CpltYn') ||
        binding.includes('DlyDdn')
      ) {
        switch (item[binding]) {
          case 'CPLT':
            addClass(cell, 'cplt');
            break;
          case 'DLY_CPLT':
            addClass(cell, 'dlyCplt');
            break;
          case 'OBSR':
            addClass(cell, 'obsr');
            break;
          case 'DLY':
            addClass(cell, 'dly');
            break;
        }
      }
    }
  });

  return (
    <>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows className="FourCol">
              <SearchCols>
                <label>{String(t('pjm.label.법인', '법인'))}</label>
                <MultiComboBox
                  id="copCd"
                  options={code?.elmCopCd || []}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={condition?.copCds || []}
                  onChange={(value) => {
                    handleConditionChange('copCd', (value || []).join());
                  }}
                />
              </SearchCols>
              {/* 25.02.19 임시저장 확인을 위해 구분 삭제 상태 추가 */}
              {/* <SearchCols>
                <label>{String(t('pjm.label.구분', '구분'))}</label>
                <MultiComboBox
                  id="elmPrjTpCd"
                  options={code?.elmPrjTpCd || []}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={condition?.elmPrjTpCds || []}
                  onChange={(value) => {
                    handleConditionChange('elmPrjTpCd', (value || []).join());
                  }}
                />
              </SearchCols> */}
              <SearchCols>
                <label>{t('pjm.label.프로젝트 ID', '프로젝트 ID')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="elmPrjId"
                  placeholder={String(
                    t('pjm.label.프로젝트 ID를 입력해 주세요.', '프로젝트 ID를 입력해 주세요.')
                  )}
                  value={condition?.elmPrjId}
                  onChange={(e) => handleConditionChange(e.target.name, e.target.value)}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('pjm.label.프로젝트 명', '프로젝트 명')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="elmPrjNm"
                  placeholder={String(
                    t('pjm.label.프로젝트 명을 입력해 주세요.', '프로젝트 명을 입력해 주세요.')
                  )}
                  value={condition?.elmPrjNm}
                  onChange={(e) => handleConditionChange(e.target.name, e.target.value)}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label>{String(t('pjm.label.상태', '상태'))}</label>
                <MultiComboBox
                  id="elmPrjStatCd"
                  options={code.elmPrjStatCd}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={condition?.elmPrjStatCds || []}
                  onChange={(value) => {
                    handleConditionChange('elmPrjStatCd', value.join());
                  }}
                />
              </SearchCols>
            </SearchRows>
            <SearchRows className="FourCol">
              <SearchCols>
                <label>{String(t('pjm.label.Factory', 'Factory'))}</label>
                <MultiComboBox
                  id="factoryCode"
                  options={code?.factoryCode || []}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={condition?.prdnPldoCds || []}
                  onChange={(value) => {
                    handleConditionChange('prdnPldoCd', (value || []).join());
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{String(t('pjm.label.공정', '공정'))}</label>
                <MultiComboBox
                  id="prdnProcCd"
                  options={code?.prdnProcCd || []}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={condition?.prdnProcCds || []}
                  onChange={(value) => {
                    handleConditionChange('prdnProcCd', (value || []).join());
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{String(t('pjm.label.설비군', '설비군'))}</label>
                <MultiComboBox
                  id="eqclId"
                  options={code?.eqclId || []}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={condition?.eqclIds || []}
                  onChange={(value) => {
                    handleConditionChange('eqclId', (value || []).join());
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{String(t('pjm.label.Line', 'Line'))}</label>
                <MultiComboBox
                  id="eltrTpCd"
                  options={code?.eltrTpCd || []}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={condition?.eltrTpCds || []}
                  onChange={(value) => {
                    handleConditionChange('eltrTpCd', (value || []).join());
                  }}
                />
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchBoxButtons
            setReset={setCondition}
            onSearch={handleSearch}
            defaultCondition={initParam}
          />
        </SearchBoxRow>
      </SearchBox>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('pjm.label.Setup List', 'Setup List')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{(rowData || []).length.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="rule"
            onClick={() => setOpenlegendPopUp(true)}
            disableRipple
          >
            {t('pjm.label.범례', '범례', '범례')}
          </Button>
          {(hasRole('SETUP_MANAGER') || hasRole('ADMIN')) && (
            <Button
              css={IconButton.button}
              className="regist"
              onClick={btnScheduleRegist}
              disableRipple
            >
              {t('com.button.일정등록', '일정등록')}
            </Button>
          )}
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={handleExportExcel}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
          <Button css={IconButton.button} className="refresh" onClick={handleRefresh} disableRipple>
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        isReadOnly={true}
        height={400}
        isSelector={false}
        excludePin={['elmPrjTpCdNm']}
        excludeFilter={['elmPrjTpCdNm']}
        initialized={onInitialized}
        itemFormatter={onItemFormatter}
        isHoverCss={false}
      />
      {isOpenlegendPopUp && (
        <SetupDashboardLegendPopup
          open={isOpenlegendPopUp}
          close={() => setOpenlegendPopUp(false)}
        />
      )}
      {isOpenResultRegistModal && (
        <SetupResultRegistPopup
          open={isOpenResultRegistModal}
          close={() => setOpenResultRegistModal(false)}
          onRefresh={() => handleSearch()}
          condition={resultRegistCondition}
        />
      )}
      {isOpenScheduleRegistModal && (
        <SetupScheduleRegistPopup
          open={isOpenScheduleRegistModal}
          close={() => setOpenScheduleRegistModal(false)}
          onCallback={() => console.log('')}
          condition={scheduleRegtistCondition}
        />
      )}
    </>
  );
};

export default FacilitySetupListPage;
