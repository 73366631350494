/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DateRangeType } from 'react-tailwindcss-datepicker';
import dayjs from 'dayjs';
import {
  SearchBox,
  SearchBoxRow,
  InputBox,
  SearchRows,
  SearchCols,
} from 'components/layouts/SearchBox';
import CustomDatepicker from 'components/inputs/CustomDatepicker';
import SearchBoxButtons from 'components/buttons/SearchBoxButtons';
import { ComboBox, MultiComboBox } from 'components/selects/ComboBox';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { getCommonCodeNames, getCommonCodeNamesCondition } from 'apis/admin/CommonCode';
import { Code } from 'models/common/CommonCode';
import { IdleAssetCondition, RvwStatCd } from 'models/asst/IdleAsset';
import IdleAssetRequestGrid from './management/IdleAssetRequestGrid';
import IdleAssetStatusGrid from './management/IdleAssetStatusGrid';
import IdleAssetEquipmentGrid from './management/IdleAssetEquipmentGrid';
import IdleAssetReviewGrid from './management/IdleAssetReviewGrid';
import UserModal from 'components/modals/common/UserModal';
import { Employee } from 'models/admin/Employee';
import _ from 'lodash';
import { useSearchParams } from 'react-router-dom';

interface DisplayCondition {
  dataInsUserNm?: string; // 요청자명
}

const IdlAssetManagementPage = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const defaultCondition = {
    copCds: [] as string[],
    rvwStatCd: RvwStatCd.REVIEW,
    startDate: dayjs().add(-2, 'year').format('YYYY.MM.DD'),
    endDate: dayjs().format('YYYY.MM.DD'),
    rvwReqId: searchParams.get('rvwReqId') || '',
  };
  const requestRef = useRef<any>();
  const equipRef = useRef<any>();
  const statusRef = useRef<any>();
  const reviewRef = useRef<any>();
  const [condition, setCondition] = useState<IdleAssetCondition>(defaultCondition);
  const [displayCondition, setDisplayCondition] = useState<DisplayCondition>({});
  const [code, setCode] = useState<any>();
  const [isOpenUserModal, setOpenUserModal] = useState<boolean>(false);
  const [isAsstMstComplete, setAsstMstComplete] = useState<boolean>(false);

  useEffect(() => {
    getCommonCodes();
    handleSearch();
  }, []);

  useEffect(() => {
    console.log(searchParams.get('rvwReqId'));

    if (!_.isNull(searchParams.get('rvwReqId'))) {
      const newCondition = {
        ...condition,
        rvwReqId: searchParams.get('rvwReqId') || '',
        rvwStatCd: '',
      };
      setCondition(newCondition);
      setTimeout(() => {
        requestRef.current?.search(newCondition);
      }, 0);
    }
  }, [searchParams]); // searchParms가 변경될 때마다 실행

  const getCommonCodes = async () => {
    const elmCopCd: Code[] = await getCommonCodeNames('ELM_COP_CD'); // 법인코드
    const asstRvwRltDivsCd: Code[] = await getCommonCodeNames('ASST_RVW_RLT_DIVS_CD'); // 검토결과
    const rvwStatCd: Code[] = await getCommonCodeNamesCondition({
      optValCtn1: 'Y',
      cmnGrCd: 'RVW_STAT_CD',
    }); // 검토상태

    setCode({
      elmCopCd: elmCopCd,
      asstRvwRltDivsCd: asstRvwRltDivsCd,
      rvwStatCd: rvwStatCd,
    });
  };

  //ddddd반영ㅇㅇㅇㅇ
  const handleSearch = () => {
    requestRef.current?.search(condition);
  };

  const handleDisplayConditionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    setDisplayCondition({
      ...displayCondition,
      [name]: e.target.value,
    });
    setCondition({
      ...condition,
      [name.replace('Nm', 'Id')]: '',
    });
  };

  const handleConditionChange = (name, value) => {
    setCondition({ ...condition, [name]: value });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows>
              <SearchCols className="width25">
                <label>
                  <span>{String(t('asst.label.법인', '법인'))}</span>
                </label>
                <MultiComboBox
                  id="copCd"
                  placeholder={String(t('asst.msg.법인', '법인'))}
                  options={code?.elmCopCd || []}
                  defaultValue={condition?.copCds || []}
                  onChange={(value) => {
                    handleConditionChange('copCd', (value || []).join());
                  }}
                />
              </SearchCols>
              <SearchCols className="width25">
                <label>
                  <span>{String(t('asst.label.설비명', '설비명'))}</span>
                </label>
                <CustomInputWithSearch
                  name="eqpNm"
                  placeholder={String(
                    t('asst.msg.설비명을 입력해 주세요.', '설비명을 입력해 주세요.')
                  )}
                  value={condition?.eqpNm}
                  onChange={(e) => {
                    handleConditionChange(e.target.name, e.target.value);
                  }}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
              <SearchCols className="width25">
                <label>
                  <span>{String(t('asst.label.고정자산명', '고정자산명'))}</span>
                </label>
                <CustomInputWithSearch
                  name="asstNm"
                  placeholder={String(
                    t('asst.msg.고정자산명을 입력해주세요.', '고정자산명을 입력해주세요.')
                  )}
                  value={condition?.asstNm}
                  onChange={(e) => {
                    handleConditionChange(e.target.name, e.target.value);
                  }}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
              <SearchCols className="width25">
                <label>
                  <span>{String(t('asst.label.검토결과', '검토결과'))}</span>
                </label>
                <ComboBox
                  placeholder={String(t('asst.msg.검토결과', '검토결과'))}
                  options={code?.asstRvwRltDivsCd || []}
                  defaultValue={condition?.rvwRltDivsCd}
                  onChange={(value) => handleConditionChange('rvwRltDivsCd', value)}
                />
              </SearchCols>
            </SearchRows>
            <SearchRows>
              <SearchCols className="width25">
                <label>
                  <span>{String(t('asst.label.요청번호', '요청번호'))}</span>
                </label>
                <CustomInputWithSearch
                  name="rvwReqId"
                  placeholder={String(
                    t('asst.msg.요청번호를 입력해 주세요.', '요청번호를 입력해 주세요.')
                  )}
                  value={condition?.rvwReqId}
                  onChange={(e) => {
                    handleConditionChange(e.target.name, e.target.value);
                  }}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
              <SearchCols className="width25">
                <label>
                  <span>{String(t('asst.label.요청제목', '요청제목'))}</span>
                </label>
                <CustomInputWithSearch
                  name="rvwReqTitNm"
                  placeholder={String(
                    t('asst.msg.요청제목을 입력해 주세요.', '요청제목을 입력해 주세요.')
                  )}
                  value={condition?.rvwReqTitNm}
                  onChange={(e) => {
                    handleConditionChange(e.target.name, e.target.value);
                  }}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>

              <SearchCols className="width25">
                <label>
                  <span>{String(t('asst.label.요청일자', '요청일자'))}</span>
                </label>
                <CustomDatepicker
                  isRange={true} // 기간선택인 경우 생략가능
                  value={{
                    startDate: condition?.startDate || '',
                    endDate: condition?.endDate || '',
                  }}
                  onChange={(newValue) => {
                    const value = newValue as DateRangeType;
                    setCondition({
                      ...condition,
                      startDate: (value?.startDate as string) || '',
                      endDate: (value?.endDate as string) || '',
                    });
                  }}
                />
              </SearchCols>
              <SearchCols className="width25">
                <label>
                  <span>{String(t('asst.label.검토상태', '검토상태'))}</span>
                </label>
                <ComboBox
                  placeholder={String(t('asst.msg.검토상태', '검토상태'))}
                  options={code?.rvwStatCd || []}
                  defaultValue={condition?.rvwStatCd}
                  onChange={(value) => handleConditionChange('rvwStatCd', value)}
                />
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchBoxButtons
            defaultCondition={defaultCondition}
            setReset={setCondition}
            onSearch={() => handleSearch()}
          />
        </SearchBoxRow>
      </SearchBox>

      <IdleAssetRequestGrid
        ref={requestRef}
        onClikDetail={(item) => {
          if (_.isNull(item) || item == undefined) return;
          setAsstMstComplete(item.rvwStatCd === 'CPLT');
          equipRef.current?.search(item);
          // 진행형황, 검토현황 초기화
          statusRef.current?.reset();
          reviewRef.current?.reset();
        }}
      />

      <IdleAssetEquipmentGrid
        ref={equipRef}
        resultCfm={false}
        gridHeight={150}
        isAsstMstComplete={isAsstMstComplete}
        onClikDetail={(item) => {
          statusRef.current?.search(item);
          reviewRef.current?.search(item);
        }}
        onRefresh={() => {
          // 요청 목록 재조회
          handleSearch();
          // 법인별 진행 현황 재조회
          statusRef.current?.refresh();
          // 법인별 검토 현황 재조회
          reviewRef.current?.refresh();
        }}
      />

      <IdleAssetStatusGrid ref={statusRef} />

      <IdleAssetReviewGrid
        ref={reviewRef}
        resultCfm={false}
        isAsstMstComplete={isAsstMstComplete}
      />

      {isOpenUserModal && (
        <UserModal
          open={isOpenUserModal}
          close={() => setOpenUserModal(false)}
          title={t('com.label.담당자 조회', '담당자 조회')}
          singleSelect={true}
          defaultUserId={condition?.dataInsUserId}
          onCallback={(result) => {
            const user = result as Employee;
            setCondition({
              ...condition,
              dataInsUserId: user?.userId,
            });
            setDisplayCondition({
              ...displayCondition,
              dataInsUserNm: user?.empNm || '',
            });
          }}
          condition={{ empNm: displayCondition?.dataInsUserNm }}
        />
      )}
    </>
  );
};

export default IdlAssetManagementPage;
