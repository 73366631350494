/** @jsxImportSource @emotion/react */
import React, { useState, useImperativeHandle, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CustomGrid from 'components/grids/CustomGrid';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { getExcelFileName } from 'utils/ExcelUtil';
import UtMatrixHookupTagPopup from '../popup/UtMatrixHookupTagPopup';

interface Props {
  detailRowData?: any;
}

const DetailSummaryContent = ({ detailRowData }: Props, ref) => {
  const [flexRef, setFlexRef] = useState<any>();
  const { t } = useTranslation();
  const [isOpenHookupTagPopup, setOpenHookupTagPopup] = useState<boolean>(false);
  const [hookupTagCondition, setHookupTagCondition] = useState<any>();

  useImperativeHandle(ref, () => ({
    btnDetailExcelExport: () => {
      btnDetailExcelExport();
    },
  }));

  const layoutDefinition = [
    {
      binding: 'no',
      header: String(t('com.label.NO', 'NO')),
      width: 40,
      isReadOnly: true,
      align: 'center',
      cellTemplate: (grid) => grid.row._idx + 1,
    },
    {
      binding: 'utmId',
      header: String(t('ut.label.UTM ID', 'UTM ID')),
      width: 100,
      align: 'center',
    },
    {
      binding: 'prdnProcCdNm',
      header: String(t('ut.label.공정', '공정')),
      width: 100,
      align: 'center',
    },
    {
      binding: 'bizAreaNm',
      header: String(t('ut.label.AREA', 'AREA')),
      width: 100,
      align: 'center',
    },
    {
      binding: 'eqclNm',
      header: String(t('ut.label.설비군', '설비군')),
      width: 100,
    },
    {
      binding: 'stndEqpId',
      header: String(t('ut.label.표준설비코드', '표준설비코드')),
      width: 140,
    },
    {
      binding: 'dtalProcCd',
      header: String(t('ut.label.세부공정', '세부공정')),
      width: 110,
    },
    {
      header: String(t('ut.label.Facility Location', 'Facility Location')),
      collapseTo: 'bldgFloCd',
      isCollapsed: true,
      columns: [
        {
          binding: 'bldgFloCd',
          header: String(t('ut.label.Floor', 'Floor')),
          width: 120,
        },
        {
          binding: 'istlLocNm',
          header: String(t('ut.label.Room', 'Room')),
          width: 100,
        },
      ],
    },
    {
      binding: 'prdnLnNm',
      header: String(t('ut.label.Line', 'Line')),
      width: 100,
    },
    {
      binding: 'prdnPrlLnNm',
      header: String(t('ut.label.Sub Line', 'Sub Line')),
      width: 120,
    },
    {
      binding: 'eqpMainNm',
      header: String(t('ut.label.Main', 'Main')),
      width: 100,
    },
    {
      binding: 'eqpMchNm',
      header: String(t('ut.label.Machine', 'Machine')),
      width: 120,
    },
    {
      binding: 'eqpUntNm',
      header: String(t('ut.label.Unit', 'Unit')),
      width: 100,
    },
    {
      header: String(t('ut.label.Hookup Tagging', 'Hookup Tagging')),
      collapseTo: 'machEqpNm',
      columns: [
        {
          binding: 'machEqpNm',
          header: String(t('ut.label.Mech', 'Mech')),
          width: 120,
          cssClass: 'WijmoFind',
          cellTemplate: (params) => `
                <span>${params.value || ''}</span>
                ${params.value && params.item.tagIssuYn === 'Y' ? '<Button id="machEqpNm"/>' : ''} 
            `,
        },
        {
          binding: 'elpwEqpNm',
          header: String(t('ut.label.Elec', 'Elec')),
          width: 120,
          cssClass: 'WijmoFind',
          cellTemplate: (params) => `
                <span>${params.value || ''}</span>
                ${params.value && params.item.tagIssuYn === 'Y' ? '<Button id="elpwEqpNm" />' : ''}
            `,
        },
      ],
    },
    {
      header: String(t('ut.label.설비 Qty', '설비 Qty')),
      columns: [
        {
          binding: 'machEqpQty',
          header: String(t('ut.label.Mech', 'Mech')),
          width: 100,
          align: 'right',
        },
        {
          binding: 'elpwEqpQty',
          header: String(t('ut.label.Elec', 'Elec')),
          width: 100,
          align: 'right',
        },
      ],
    },
    {
      header: String(t('ut.label.Library', 'Library')),
      columns: [
        {
          binding: 'utmLibId',
          header: String(t('ut.label.ID', 'ID')),
          width: 100,
        },
        {
          binding: 'utmLibVerNo',
          header: String(t('ut.label.Version', 'Version')),
          width: 100,
        },
      ],
    },
    {
      binding: 'insTpCdNm',
      header: String(t('ut.label.작성구분', '작성구분')),
      width: 100,
      align: 'center',
    },
    {
      header: String(t('ut.label.Electricity', 'Electricity')),
      collapseTo: 'vltgNvl',
      columns: [
        {
          header: String(t('ut.label.Voltage', 'Voltage')),
          columns: [
            {
              binding: 'vltgNvl',
              // header: String(t('ut.label.Voltage[V]', 'Voltage[V]')),
              header: '[V]',
              width: 75,
              align: 'center',
            },
          ],
        },
        {
          header: String(t('ut.label.Phase', 'Phase')),
          columns: [
            {
              binding: 'elpwPhasCd',
              // header: String(t('ut.label.Phase[Φ]', 'Phase')),
              header: '[Φ]',
              width: 75,
              align: 'center',
            },
          ],
        },
        {
          header: String(t('ut.label.Wires', 'Wires')),
          columns: [
            {
              binding: 'ctwTpCdNm',
              // header: String(t('ut.label.Wires[EA]', 'Wires')),
              header: '[EA]',
              width: 80,
              align: 'center',
            },
          ],
        },
        {
          binding: 'frqNvl',
          header: String(t('ut.label.Frequency[Hz]', 'Frequency[Hz]')),
          width: 100,
          align: 'center',
          visible: false,
        },
        {
          binding: 'pwftNvl',
          header: String(t('ut.label.Power Factor-', 'Power Factor-')),
          width: 100,
          align: 'right',
          visible: false,
        },
        {
          header: String(t('ut.label.Current', 'Current')),
          columns: [
            {
              binding: 'od2EcNvl',
              // header: String(t('ut.label.Current(입력값)[A]', 'Current(입력값)[A]')),
              header: String(t('ut.label.입력값 [A]', '입력값 [A]')),
              width: 97,
              align: 'right',
            },
            {
              binding: 'od1EcNvl',
              // header: String(t('ut.label.Current(계산값)[A]', 'Current(계산값)[A]')),
              header: String(t('ut.label.계산값 [A]', '계산값 [A]')),
              width: 97,
              align: 'right',
            },
          ],
        },
        {
          header: String(t('ut.label.동시가동율', '동시가동율')),
          columns: [
            {
              binding: 'smtmOprt',
              // header: String(t('ut.label.동시가동율[%]', '동시가동율[%]')),
              header: '[%]',
              width: 75,
              align: 'right',
            },
          ],
        },
        {
          header: String(t('ut.label.Breaker', 'Breaker')),
          columns: [
            {
              binding: 'brkeCapa',
              // header: String(t('ut.label.Breaker(입력값)', 'Breaker(입력값)')),
              header: String(t('ut.label.입력값 [A]', '입력값 [A]')),
              width: 97,
              align: 'right',
            },
            {
              binding: 'optValCtn1',
              // header: String(t('ut.label.Breaker(MCCB)-(계산값)', 'Breaker(MCCB)-(계산값)')),
              header: String(t('ut.label.계산값 [A]', '계산값 [A]')),
              width: 97,
              align: 'right',
            },
          ],
        },
        {
          binding: 'plcClctYn',
          header: String(t('ut.label.PLC접지유무-', 'PLC접지유무-')),
          width: 100,
          align: 'center',
          visible: false,
        },
        {
          header: String(t('ut.label.Connection', 'Connection')),
          columns: [
            {
              binding: 'elpwEqpCnctTpCdNm',
              // header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
              header: '[Type]',
              width: 80,
              align: 'center',
            },
          ],
        },
        {
          header: String(t('ut.label.Capacity', 'Capacity')),
          columns: [
            {
              binding: 'elpwCapa',
              // header: String(t('ut.label.Capacity[KW]', 'Capacity[KW]')),
              header: '[kW]',
              width: 83,
              align: 'right',
            },
            {
              binding: 'elpwSumCapa',
              // header: String(t('ut.label.Capacity Sum', 'Capacity Sum')),
              header: 'Sum',
              width: 83,
              align: 'right',
            },
          ],
        },
        {
          header: String(t('ut.label.표준사용량', '표준사용량')),
          columns: [
            {
              binding: 'elpwStndUseQty',
              // header: String(t('ut.label.표준사용량', '표준사용량')),
              header: '[kW]',
              width: 82,
              align: 'right',
            },
          ],
        },
        {
          header: String(t('ut.label.Demand Factor', 'Demand Factor')),
          columns: [
            {
              binding: 'dmdElpwTarfNvl',
              // header: String(t('ut.label.Demand Factor[%]', 'Demand Factor[%]')),
              header: '[%]',
              width: 96,
              align: 'right',
            },
          ],
        },
        {
          header: String(t('ut.label.Capacity_Sum', 'Capacity_Sum')),
          columns: [
            {
              binding: 'dmdElpwCapa',
              // header: String(
              //   t('ut.label.Capacity_Sum(수용율 적용)[KW]', 'Capacity_Sum(수용율 적용)[KW]')
              // ),
              header: String(t('ut.label.(수용율 적용) [kW]', '(수용율 적용) [kW]')),
              width: 160,
            },
          ],
        },
        {
          header: String(t('ut.label.Heat Capacity', 'Heat Capacity')),
          columns: [
            {
              binding: 'elpwHeatCapa',
              // header: String(t('ut.label.Heat Capacity[KW]', 'Heat Capacity[KW]')),
              header: '[kW]',
              width: 88,
              align: 'right',
            },
          ],
        },
      ],
    },
    {
      header: String(t('ut.label.CDA', 'CDA')),
      collapseTo: 'cdaOtsdSizeValCtn',
      columns: [
        {
          header: String(t('ut.label.Size', 'Size')),
          columns: [
            {
              binding: 'cdaOtsdSizeValCtn',
              // header: String(t('ut.label.Size O.D[Φ]', 'Size O.D[Φ]')),
              header: 'O.D[Φ]',
              width: 75,
            },
            {
              binding: 'cdaInsdSizeValCtn',
              // header: String(t('ut.label.Size I.D[Φ]', 'Size I.D[Φ]')),
              header: 'I.D[Φ]',
              width: 75,
            },
          ],
        },
        {
          header: String(t('ut.label.Connection', 'Connection')),
          columns: [
            {
              binding: 'cdaDevcCnctTpCdNm',
              // header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
              header: '[Type]',
              width: 90,
            },
          ],
        },
        {
          header: String(t('ut.label.Pressure', 'Pressure')),
          columns: [
            {
              binding: 'cdaPresValCtn',
              // header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
              header: '[Bar]',
              width: 80,
            },
          ],
        },
        {
          header: String(t("ut.label.Q'ty", "Q'ty")),
          columns: [
            {
              binding: 'cdaPntCnt',
              // header: String(t("ut.label.Q'ty[Point]", "Q'ty[Point]")),
              header: '[Point]',
              width: 92,
              align: 'right',
            },
          ],
        },
        {
          header: String(t('ut.label.Consumption', 'Consumption')),
          columns: [
            {
              binding: 'cdaCspCapa',
              // header: String(t('ut.label.Consumption[ℓ/min]', 'Consumption[ℓ/min]')),
              header: '[ℓ/min]',
              width: 95,
              align: 'right',
            },
            {
              binding: 'cdaCspSumCapa', // 수식계산 (= Qty * CDA Consumption[ℓ/min])
              // header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
              header: 'Sum',
              width: 95,
              align: 'right',
            },
          ],
        },
        {
          header: String(t('ut.label.표준사용량', '표준사용량')),
          columns: [
            {
              binding: 'cdaStndUseQty',
              // header: String(t('ut.label.표준사용량', '표준사용량')),
              header: '[ℓ/min]',
              width: 95,
              align: 'right',
            },
          ],
        },
      ],
    },
    {
      header: String(t('ut.label.N2', 'N2')),
      collapseTo: 'nitrOtsdDmtrValCtn',
      columns: [
        {
          header: 'Size',
          columns: [
            {
              binding: 'nitrOtsdDmtrValCtn',
              // header: String(t('ut.label.Size O.D[Φ]', 'Size O.D[Φ]')),
              header: 'O.D[Φ]',
              width: 75,
            },
            {
              binding: 'nitrInsdDmtrValCtn',
              // header: String(t('ut.label.Size I.D[Φ]', 'Size I.D[Φ]')),
              header: 'I.D[Φ]',
              width: 75,
            },
          ],
        },
        {
          header: String(t('ut.label.Connection', 'Connection')),
          columns: [
            {
              binding: 'nitrDevcCnctTpCdNm',
              // header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
              header: '[Type]',
              width: 90,
              align: 'center',
            },
          ],
        },
        {
          header: String(t('ut.label.Pressure', 'Pressure')),
          columns: [
            {
              binding: 'nitrPresValCtn',
              // header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
              header: '[Bar]',
              width: 80,
            },
          ],
        },
        {
          header: String(t("ut.label.Q'ty", "Q'ty")),
          columns: [
            {
              binding: 'nitrPntCnt',
              // header: String(t("ut.label.Q'ty[Point]", "Q'ty[Point]")),
              header: '[Point]',
              width: 92,
              align: 'right',
            },
          ],
        },
        {
          header: String(t('ut.label.AVG Consumption', 'AVG Consumption')),
          columns: [
            {
              binding: 'nitrCspCapa',
              // header: String(t('ut.label.AVG Consumption[ℓ/min]', 'AVG Consumption[ℓ/min]')),
              header: '[ℓ/min]',
              width: 95,
              align: 'right',
            },
            {
              binding: 'nitrCspSumCapa',
              // header: String(t('ut.label.AVG Consumption Sum', 'AVG Consumption Sum')),
              header: 'Sum',
              width: 95,
            },
          ],
        },
        {
          header: String(t('ut.label.Peak Consumption', 'Peak Consumption')),
          columns: [
            {
              binding: 'nitrPeakCspCapa',
              // header: String(t('ut.label.Peak Consumption[ℓ/min]', 'Peak Consumption[ℓ/min]')),
              header: '[ℓ/min]',
              width: 95,
              align: 'right',
            },
            {
              binding: 'nitrPeakCspSumCapa', // 수식 (= Qty * N2 Consumption[ℓ/min])
              // header: String(t('ut.label.Peak Consumption Sum', 'Peak Consumption Sum')),
              header: 'Sum',
              width: 95,
              align: 'right',
            },
          ],
        },
        {
          header: String(t('ut.label.표준사용량', '표준사용량')),
          columns: [
            {
              binding: 'nitrStndUseQty',
              // header: String(t('ut.label.표준사용량', '표준사용량')),
              header: '[ℓ/min]',
              width: 95,
              align: 'right',
            },
          ],
        },
      ],
    },
    {
      header: String(t('ut.label.IW', 'IW')),
      collapseTo: 'iwOtsdDmtrValCtn',
      columns: [
        {
          header: String(t('ut.label.Size', 'Size')),
          columns: [
            {
              binding: 'iwOtsdDmtrValCtn',
              // header: String(t('ut.label.Size O.D[Φ]', 'Size O.D[Φ]')),
              header: 'O.D[Φ]',
              width: 75,
            },
            {
              binding: 'iwInsdDmtrValCtn',
              // header: String(t('ut.label.Size I.D[Φ]', 'Size I.D[Φ]')),
              header: 'I.D[Φ]',
              width: 75,
            },
          ],
        },
        {
          header: String(t('ut.label.Connection', 'Connection')),
          columns: [
            {
              binding: 'iwDevcCnctTpCdNm',
              // header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
              header: '[Type]',
              width: 90,
              align: 'center',
            },
          ],
        },
        {
          header: String(t('ut.label.Tempurature', 'Tempurature')),
          columns: [
            {
              binding: 'iwTmprValCtn',
              // header: String(t('ut.label.Tempurature[℃]', 'Tempurature[℃]')),
              header: '[℃]',
              width: 85,
            },
          ],
        },
        {
          header: String(t('ut.label.Pressure', 'Pressure')),
          columns: [
            {
              binding: 'iwPresValCtn', // [24.11.11] 숫자 -> 문자 변경 (데이터 검증 제외)
              // header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
              header: '[Bar]',
              width: 80,
            },
          ],
        },
        {
          header: String(t("ut.label.Q'ty", "Q'ty")),
          columns: [
            {
              binding: 'iwPntCnt',
              // header: String(t("ut.label.Q'ty[Point]", "Q'ty[Point]")),
              header: '[Point]',
              width: 92,
              align: 'right',
            },
          ],
        },
        {
          header: String(t('ut.label.Drain', 'Drain')),
          columns: [
            {
              binding: 'iwDrnCapa',
              // header: String(t('ut.label.Drain[ℓ/min]', 'Drain[ℓ/min]')),
              header: '[ℓ/min]',
              width: 95,
              align: 'right',
            },
            {
              binding: 'iwDrnSumCapa',
              // header: String(t('ut.label.Drain Sum', 'Drain Sum')),
              header: 'Sum',
              width: 95,
              align: 'right',
            },
          ],
        },
        {
          header: String(t('ut.label.Consumption', 'Consumption')),
          columns: [
            {
              binding: 'iwCspCapa',
              // header: String(t('ut.label.Consumption[ℓ/min]', 'Consumption[ℓ/min]')),
              header: '[ℓ/min]',
              width: 95,
              align: 'right',
            },
            {
              binding: 'iwCspSumCapa', // 수식 (= Qty * IW Consumption[ℓ/min])
              // header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
              header: 'Sum',
              width: 95,
              align: 'right',
            },
          ],
        },
        {
          header: String(t('ut.label.표준사용량', '표준사용량')),
          columns: [
            {
              binding: 'iwStndUseQty',
              // header: String(t('ut.label.표준사용량', '표준사용량')),
              header: '[ℓ/min]',
              width: 95,
              align: 'right',
            },
          ],
        },
      ],
    },
    {
      header: String(t('ut.label.IW for Emergency fire', 'FW')),
      collapseTo: 'frwtOtsdDmtrValCtn',
      columns: [
        {
          header: String(t('ut.label.Size', 'Size')),
          columns: [
            {
              binding: 'frwtOtsdDmtrValCtn',
              // header: String(t('ut.label.Size O.D[Φ]', 'Size O.D[Φ]')),
              header: 'O.D[Φ]',
              width: 75,
              align: 'right',
            },
            {
              binding: 'frwtInsdDmtrValCtn',
              // header: String(t('ut.label.Size I.D[Φ]', 'Size I.D[Φ]')),
              header: 'I.D[Φ]',
              width: 75,
              align: 'right',
            },
          ],
        },
        {
          header: String(t('ut.label.Connection', 'Connection')),
          columns: [
            {
              binding: 'frwtDevcCnctTpCdNm',
              // header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
              header: '[Type]',
              width: 90,
              align: 'center',
            },
          ],
        },
        {
          header: String(t('ut.label.Tempurature', 'Tempurature')),
          columns: [
            {
              binding: 'frwtTmprValCtn',
              // header: String(t('ut.label.Tempurature[℃]', 'Tempurature[℃]')),
              header: '[℃]',
              width: 83,
            },
          ],
        },
        {
          header: String(t('ut.label.Pressure', 'Pressure')),
          columns: [
            {
              binding: 'frwtPresValCtn',
              // header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
              header: '[Bar]',
              width: 80,
            },
          ],
        },
        {
          header: String(t("ut.label.Q'ty", "Q'ty")),
          columns: [
            {
              binding: 'frwtPntCnt',
              // header: String(t("ut.label.Q'ty[Point]", "Q'ty[Point]")),
              header: '[Point]',
              width: 92,
              align: 'right',
            },
          ],
        },
        {
          header: String(t('ut.label.Drain', 'Drain')),
          columns: [
            {
              binding: 'frwtDrnCapa',
              // header: String(t('ut.label.Drain[ℓ/min]', 'Drain[ℓ/min]')),
              header: '[ℓ/min]',
              width: 95,
              align: 'right',
            },
            {
              binding: 'frwtDrnSumCapa',
              // header: String(t('ut.label.Drain Sum', 'Drain Sum')),
              header: 'Sum',
              width: 95,
              align: 'right',
            },
          ],
        },
        {
          header: String(t('ut.label.Consumption', 'Consumption')),
          columns: [
            {
              binding: 'frwtCspCapa',
              // header: String(t('ut.label.Consumption[ℓ/min]', 'Consumption[ℓ/min]')),
              header: '[ℓ/min]',
              width: 95,
              align: 'right',
            },
            {
              binding: 'frwtCspSumCapa', // 수식 (= Qty * IW2 Consumption[ℓ/min])
              // header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
              header: 'Sum',
              width: 95,
              align: 'right',
            },
          ],
        },
        {
          header: String(t('ut.label.표준사용량', '표준사용량')),
          columns: [
            {
              binding: 'frwtStndUseQty',
              // header: String(t('ut.label.표준사용량', '표준사용량')),
              header: '[ℓ/min]',
              width: 95,
              align: 'right',
            },
          ],
        },
      ],
    },
    {
      header: String(t('ut.label.PCW', 'PCW')),
      collapseTo: 'coolOtsdSizeValCtn',
      columns: [
        {
          header: String(t('ut.label.Size', 'Size')),
          columns: [
            {
              binding: 'coolOtsdSizeValCtn',
              // header: String(t('ut.label.Size O.D[Φ]', 'Size O.D[Φ]')),
              header: 'O.D[Φ]',
              width: 75,
            },
            {
              binding: 'coolInsdSizeValCtn',
              // header: String(t('ut.label.Size I.D[Φ]', 'Size I.D[Φ]')),
              header: 'I.D[Φ]',
              width: 75,
            },
          ],
        },
        {
          header: String(t('ut.label.Connection', 'Connection')),
          columns: [
            {
              binding: 'coolDevcCnctTpCdNm',
              // header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
              header: '[Type]',
              width: 90,
              align: 'center',
            },
          ],
        },
        {
          header: String(t('ut.label.Tempurature', 'Tempurature')),
          columns: [
            {
              binding: 'coolTmprValCtn',
              // header: String(t('ut.label.Tempurature[℃]', 'Tempurature[℃]')),
              header: '[℃]',
              width: 85,
            },
          ],
        },
        {
          header: String(t('ut.label.Pressure', 'Pressure')),
          columns: [
            {
              binding: 'coolPresValCtn',
              // header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
              header: '[Bar]',
              width: 80,
            },
          ],
        },
        {
          header: String(t("ut.label.Q'ty", "Q'ty")),
          columns: [
            {
              binding: 'coolPntCnt',
              // header: String(t("ut.label.Q'ty[Point]", "Q'ty[Point]")),
              header: '[Point]',
              width: 92,
              align: 'right',
            },
          ],
        },
        {
          header: String(t('ut.label.Consumption', 'Consumption')),
          columns: [
            {
              binding: 'coolCspCapa',
              // header: String(t('ut.label.Consumption[ℓ/min]', 'Consumption[ℓ/min]')),
              header: '[ℓ/min]',
              width: 95,
              align: 'right',
            },
            {
              binding: 'coolCspSumCapa', // 수식 (= Qty * PCW Consumption[ℓ/min])
              // header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
              header: 'Sum',
              width: 95,
              align: 'right',
            },
          ],
        },
        {
          header: String(t('ut.label.표준사용량', '표준사용량')),
          columns: [
            {
              binding: 'coolStndUseQty',
              // header: String(t('ut.label.표준사용량', '표준사용량')),
              header: '[ℓ/min]',
              width: 95,
              align: 'right',
            },
          ],
        },
      ],
    },
    {
      header: String(t('ut.label.WW', 'WW')),
      collapseTo: 'wwOtsdDmtrValCtn',
      columns: [
        {
          header: String(t('ut.label.Size', 'Size')),
          columns: [
            {
              binding: 'wwOtsdDmtrValCtn',
              // header: String(t('ut.label.Size O.D[Φ]', 'Size O.D[Φ]')),
              header: 'O.D[Φ]',
              width: 75,
            },
            {
              binding: 'wwInsdDmtrValCtn',
              // header: String(t('ut.label.Size I.D[Φ]', 'Size I.D[Φ]')),
              header: 'I.D[Φ]',
              width: 75,
            },
          ],
        },
        {
          header: String(t('ut.label.Connection', 'Connection')),
          columns: [
            {
              binding: 'wwDevcCnctTpCdNm',
              // header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
              header: '[Type]',
              width: 90,
              align: 'center',
            },
          ],
        },
        {
          header: String(t('ut.label.Tempurature', 'Tempurature')),
          columns: [
            {
              binding: 'wwTmprValCtn',
              // header: String(t('ut.label.Tempurature[℃]', 'Tempurature[℃]')),
              header: '[℃]',
              width: 85,
            },
          ],
        },
        {
          header: String(t('ut.label.Pressure', 'Pressure')),
          columns: [
            {
              binding: 'wwPresValCtn',
              // header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
              header: '[Bar]',
              width: 80,
            },
          ],
        },
        {
          header: String(t("ut.label.Q'ty", "Q'ty")),
          columns: [
            {
              binding: 'wwPntCnt',
              // header: String(t("ut.label.Q'ty[Point]", "Q'ty[Point]")),
              header: '[Point]',
              width: 92,
              align: 'right',
            },
          ],
        },
        {
          header: String(t('ut.label.Consumption', 'Consumption')),
          columns: [
            {
              binding: 'wwCspCapa',
              // header: String(t('ut.label.Consumption[ℓ/min]', 'Consumption[ℓ/min]')),
              header: '[ℓ/min]',
              width: 95,
              align: 'right',
            },
            {
              binding: 'wwCspSumCapa',
              // header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
              header: 'Sum',
              width: 95,
              align: 'right',
            },
          ],
        },
        {
          header: String(t('ut.label.표준사용량', '표준사용량')),
          columns: [
            {
              binding: 'wwStndUseQty',
              // header: String(t('ut.label.표준사용량', '표준사용량')),
              header: '[ℓ/min]',
              width: 95,
              align: 'right',
            },
          ],
        },
      ],
    },
    {
      header: String(t('ut.label.Steam', 'Steam')),
      collapseTo: 'stemOtsdDmtrValCtn',
      columns: [
        {
          header: String(t('ut.label.Size', 'Size')),
          columns: [
            {
              binding: 'stemOtsdDmtrValCtn',
              // header: String(t('ut.label.Size O.D[Φ]', 'Size O.D[Φ]')),
              header: 'O.D[Φ]',
              width: 75,
            },
            {
              binding: 'stemInsdDmtrValCtn',
              // header: String(t('ut.label.Size I.D[Φ]', 'Size I.D[Φ]')),
              header: 'I.D[Φ]',
              width: 75,
            },
          ],
        },
        {
          header: String(t('ut.label.Connection', 'Connection')),
          columns: [
            {
              binding: 'stemDevcCnctTpCdNm',
              // header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
              header: '[Type]',
              width: 90,
              align: 'center',
            },
          ],
        },
        {
          header: String(t('ut.label.Condensator connection', 'Condensator connection')),
          columns: [
            {
              binding: 'stemCwtSize',
              // header: String(
              //   t('ut.label.Condenstaor connection size[Φ]', 'Condenstaor connection size[Φ]')
              // ),
              header: 'size [Φ]',
              width: 96,
              align: 'right',
            },
            {
              binding: 'stemCwtDevcCnctTpCdNm',
              // header: String(
              //   t('ut.label.Condensator connection[Type]', 'Condensator connection[Type]')
              // ),
              header: '[Type]',
              width: 96,
              align: 'center',
            },
          ],
        },
        {
          header: String(t('ut.label.Pressure', 'Pressure')),
          columns: [
            {
              binding: 'stemPresValCtn',
              // header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
              header: '[Bar]',
              width: 80,
            },
          ],
        },
        {
          header: String(t("ut.label.Q'ty", "Q'ty")),
          columns: [
            {
              binding: 'stemPntCnt',
              // header: String(t("ut.label.Q'ty[Point]", "Q'ty[Point]")),
              header: '[Point]',
              width: 100,
              align: 'right',
            },
          ],
        },
        {
          header: String(t('ut.label.Consumption', 'Consumption')),
          columns: [
            {
              binding: 'stemCspCapa',
              // header: String(t('ut.label.Consumption[t/hr]', 'Consumption[t/hr]')),
              header: '[t/hr]',
              width: 85,
              align: 'right',
            },
            {
              binding: 'stemCspSumCapa', // 수식 (= Qty * Steam Consumption[ℓ/min])
              // header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
              header: 'Sum',
              width: 85,
              align: 'right',
            },
          ],
        },
        {
          header: String(t('ut.label.표준사용량', '표준사용량')),
          columns: [
            {
              binding: 'stemStndUseQty',
              // header: String(t('ut.label.표준사용량', '표준사용량')),
              header: '[t/hr]',
              width: 85,
              align: 'right',
            },
          ],
        },
      ],
    },
    {
      header: String(t('ut.label.NG', 'NG')),
      collapseTo: 'lngOtsdDmtrValCtn',
      columns: [
        {
          header: String(t('ut.label.Size', 'Size')),
          columns: [
            {
              binding: 'lngOtsdDmtrValCtn',
              // header: String(t('ut.label.Size O.D[Φ]', 'Size O.D[Φ]')),
              header: 'O.D[Φ]',
              width: 75,
            },
            {
              binding: 'lngInsdDmtrValCtn',
              // header: String(t('ut.label.Size I.D[Φ]', 'Size I.D[Φ]')),
              header: 'I.D[Φ]',
              width: 75,
            },
          ],
        },
        {
          header: String(t('ut.label.Connection', 'Connection')),
          columns: [
            {
              binding: 'lngDevcCnctTpCdNm',
              // header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
              header: '[Type]',
              width: 90,
              align: 'center',
            },
          ],
        },
        {
          header: String(t('ut.label.Pressure', 'Pressure')),
          columns: [
            {
              binding: 'lngPresValCtn',
              // header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
              header: '[Bar]',
              width: 80,
            },
          ],
        },
        {
          header: String(t("ut.label.Q'ty", "Q'ty")),
          columns: [
            {
              binding: 'lngPntCnt',
              // header: String(t("ut.label.Q'ty[Point]", "Q'ty[Point]")),
              header: '[Point]',
              width: 92,
            },
          ],
        },
        {
          header: String(t('ut.label.Consumption', 'Consumption')),
          columns: [
            {
              binding: 'lngCspCapa',
              // header: String(t('ut.label.Consumption[ℓ/min]', 'Consumption[ℓ/min]')),
              header: '[Nm3/hr]',
              width: 110,
              align: 'right',
            },
            {
              binding: 'lngCspSumCapa', // 수식 (= Qty * NG Consumption[ℓ/min])
              // header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
              header: 'Sum',
              width: 90,
              align: 'right',
            },
          ],
        },
        {
          header: String(t('ut.label.표준사용량', '표준사용량')),
          columns: [
            {
              binding: 'lngStndUseQty',
              // header: String(t('ut.label.표준사용량', '표준사용량')),
              header: '[Nm3/hr]',
              width: 108,
              align: 'right',
            },
          ],
        },
      ],
    },
    {
      header: String(t('ut.label.Exhaust air [Return(to Air Handling Unit)]', 'Return Air')),
      collapseTo: 'exhaOtsdDmtrValCtn',
      columns: [
        {
          header: String(t('ut.label.Size', 'Size')),
          columns: [
            {
              binding: 'exhaOtsdDmtrValCtn',
              // header: String(t('ut.label.Size O.D[Φ]', 'Size O.D[Φ]')),
              header: 'O.D[Φ]',
              width: 75,
            },
            {
              binding: 'insdExhaInsdDmtrValCtn',
              // header: String(t('ut.label.Size I.D[Φ]', 'Size I.D[Φ]')),
              header: 'I.D[Φ]',
              width: 75,
            },
          ],
        },
        {
          header: String(t('ut.label.Connection', 'Connection')),
          columns: [
            {
              binding: 'insdExhaCnctTpCdNm',
              // header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
              header: '[Type]',
              width: 85,
              align: 'center',
            },
          ],
        },
        {
          header: String(t('ut.label.Collector Type', 'Collector Type')),
          columns: [
            {
              binding: 'insdExhaCllrTpCdNm',
              // header: String(t('ut.label.Collector Type[Type]', 'Collector Type[Type]')),
              header: '[Type]',
              width: 93,
              align: 'center',
            },
          ],
        },
        {
          header: String(t('ut.label.Tempurature', 'Tempurature')),
          columns: [
            {
              binding: 'insdExhaTmprValCtn',
              // header: String(t('ut.label.Tempurature[℃]', 'Tempurature[℃]')),
              header: '[℃]',
              width: 80,
            },
          ],
        },
        {
          header: String(t("ut.label.Q'ty", "Q'ty")),
          columns: [
            {
              binding: 'insdExhaPntCnt',
              // header: String(t("ut.label.Q'ty[Point]", "Q'ty[Point]")),
              header: '[Point]',
              width: 92,
              align: 'right',
            },
          ],
        },
        {
          header: String(t('ut.label.Consumption', 'Consumption')),
          columns: [
            {
              binding: 'insdExhaCspCapa', // 기계 조건부 필수 대상값
              // header: String(t('ut.label.Consumption[CHM]', 'Consumption[CHM]')),
              header: '[CHM]',
              width: 95,
              align: ' right',
            },
            {
              binding: 'insdExhaCspSumCapa', // 수식 (= Qty * Exhaust air Return Consumption[ℓ/min])
              // header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
              header: 'Sum',
              width: 95,
              align: 'right',
            },
          ],
        },
        {
          header: String(t('ut.label.표준사용량', '표준사용량')),
          columns: [
            {
              binding: 'insdExhaStndUseQty',
              // header: String(t('ut.label.표준사용량', '표준사용량')),
              header: '[CHM]',
              width: 95,
              align: 'right',
            },
          ],
        },
      ],
    },
    {
      header: String(t('ut.label.Exhaust air [Ventilation(to Outside)]', 'Exhaust air')),
      collapseTo: 'otsdExhaOtsdDmtrValCtn',
      columns: [
        {
          header: String(t('ut.label.Size', 'Size')),
          columns: [
            {
              binding: 'otsdExhaOtsdDmtrValCtn',
              // header: String(t('ut.label.Size O.D[Φ]', 'Size O.D[Φ]')),
              header: 'O.D[Φ]',
              width: 75,
            },
            {
              binding: 'otsdExhaInsdDmtrValCtn',
              // header: String(t('ut.label.Size I.D[Φ]', 'Size I.D[Φ]')),
              header: 'I.D[Φ]',
              width: 75,
            },
          ],
        },
        {
          header: String(t('ut.label.Connection', 'Connection')),
          columns: [
            {
              binding: 'otsdExhaCnctTpCdNm',
              // header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
              header: '[Type]',
              width: 90,
              align: 'center',
            },
          ],
        },
        {
          header: String(t("ut.label.Q'ty", "Q'ty")),
          columns: [
            {
              binding: 'otsdExhaPntCnt',
              // header: String(t("ut.label.Q'ty[Point]", "Q'ty[Point]")),
              header: '[Point]',
              width: 92,
              align: 'right',
            },
          ],
        },
        {
          header: String(t('ut.label.Consumption', 'Consumption')),
          columns: [
            {
              binding: 'otsdExhaCspCapa', // 기계 조건부 필수 대상값
              // header: String(t('ut.label.Consumption[CHM]', 'Consumption[CHM]')),
              header: '[CHM]',
              width: 95,
              align: 'right',
            },
            {
              binding: 'otsdExhaCspSumCapa', // 수식 (= Qty * Exhaust air [Ventilation Consumption[ℓ/min])
              // header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
              header: 'Sum',
              width: 95,
              align: 'right',
            },
          ],
        },
        {
          header: String(t('ut.label.표준사용량', '표준사용량')),
          columns: [
            {
              binding: 'otsdExhaStndUseQty',
              // header: String(t('ut.label.표준사용량', '표준사용량')),
              header: '[CHM]',
              width: 95,
              align: 'right',
            },
          ],
        },
      ],
    },
    {
      header: String(t('ut.label.Supply Air', 'Supply air')),
      collapseTo: 'suarOtsdDmtrValCtn',
      columns: [
        {
          header: String(t('ut.label.Size', 'Size')),
          columns: [
            {
              binding: 'suarOtsdDmtrValCtn',
              // header: String(t('ut.label.Size O.D[Φ]', 'Size O.D[Φ]')),
              header: 'O.D[Φ]',
              width: 75,
            },
            {
              binding: 'suarInsdDmtrValCtn',
              // header: String(t('ut.label.Size I.D[Φ]', 'Size I.D[Φ]')),
              header: 'I.D[Φ]',
              width: 75,
            },
          ],
        },
        {
          header: String(t('ut.label.Connection', 'Connection')),
          columns: [
            {
              binding: 'suarDevcCnctTpCdNm',
              // header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
              header: '[Type]',
              width: 90,
              align: 'center',
            },
          ],
        },
        {
          header: String(t("ut.label.Q'ty", "Q'ty")),
          columns: [
            {
              binding: 'suarPntCnt',
              // header: String(t("ut.label.Q'ty[Point]", "Q'ty[Point]")),
              header: '[Point]',
              width: 92,
              align: 'right',
            },
          ],
        },
        {
          header: String(t('ut.label.Consumption', 'Consumption')),
          columns: [
            {
              binding: 'suarCspCapa', // 기계 조건부 필수 대상값
              // header: String(t('ut.label.Consumption[CHM]', 'Consumption[CHM]')),
              header: '[CHM]',
              width: 95,
              align: 'right',
            },
            {
              binding: 'suarCspSumCapa', // 수식 (= Qty * Supply air Consumption[ℓ/min])
              // header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
              header: 'Sum',
              width: 95,
              align: 'right',
            },
          ],
        },
        {
          header: String(t('ut.label.표준사용량', '표준사용량')),
          columns: [
            {
              binding: 'suarStndUseQty',
              // header: String(t('ut.label.표준사용량', '표준사용량')),
              header: '[CHM]',
              width: 95,
              align: 'right',
            },
          ],
        },
      ],
    },
    {
      // header: String(t('ut.label.EQP Spec(Dimension_mm)', 'Dimension[mm]/Weight[kg]')),
      header: String(t('ut.label.Equipment Specification', 'Equipment Specification')),
      collapseTo: 'eqpDimWthLen',
      isCollapsed: true,
      columns: [
        {
          header: String(t('ut.label.Dimension[mm]', 'Dimension[mm]')),
          columns: [
            {
              binding: 'eqpDimWthLen',
              header: String(t('ut.label.Width', 'Width')),
              width: 190,
              align: 'right',
            },
            {
              binding: 'eqpDimDpthNvl',
              header: String(t('ut.label.Depth', 'Depth')),
              width: 100,
              align: 'right',
            },
            {
              binding: 'eqpDimHigtNvl',
              header: String(t('ut.label.Height', 'Height')),
              width: 100,
              align: 'right',
            },
          ],
        },
        {
          header: String(t('ut.label.Weight[kg]', 'Weight[kg]')),
          columns: [
            {
              binding: 'eqpWgt',
              // header: String(t('ut.label.EQP Spec(Weight_kg)', 'Weight')),
              header: String(t('ut.label.전체중량', '전체중량')),
              width: 110,
              align: 'right',
            },
          ],
        },
      ],
    },
    {
      binding: 'rmk',
      header: String(t('ut.label.Remark', 'Remark')),
      width: 100,
    },
    {
      binding: 'dataInsUserNm',
      header: String(t('com.label.작성자', '작성자')),
      width: 100,
      align: 'center',
    },
    {
      binding: 'dataInsDtm',
      header: String(t('com.label.작성일자', '작성일자')),
      width: 120,
      align: 'center',
    },
    {
      binding: 'dataUpdUserNm',
      header: String(t('com.label.수정자', '수정자')),
      width: 100,
      align: 'center',
    },
    {
      binding: 'dataUpdDtm',
      header: String(t('com.label.수정일자', '수정일자')),
      width: 120,
      align: 'center',
    },
  ];

  const onInitialized = (grid) => {
    setFlexRef(grid);
    grid.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);

      if (ht.panel === grid.cells) {
        const item = grid.rows[ht.row].dataItem;
        if (e.target instanceof HTMLButtonElement) {
          const id = e.target.id;
          if ('machEqpNm' === id) {
            if (item.machEqpNm && item.tagIssuYn === 'Y') {
              if (item.utmId && item.planProcId && item.utmSeq) {
                setHookupTagCondition({
                  utmId: item.utmId,
                  planProcId: item.planProcId,
                  utmSeq: item.utmSeq,
                  tagDivsCd: 'MECH',
                });
                setOpenHookupTagPopup(true);
              }
            }
          }
          if ('elpwEqpNm' === id) {
            if (item.elpwEqpNm && item.tagIssuYn === 'Y') {
              if (item.utmId && item.planProcId && item.utmSeq) {
                setHookupTagCondition({
                  utmId: item.utmId,
                  planProcId: item.planProcId,
                  utmSeq: item.utmSeq,
                  tagDivsCd: 'ELEC',
                });
                setOpenHookupTagPopup(true);
              }
            }
          }
        }
      }
    });
  };

  const btnDetailExcelExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = '상세요약';
    book.saveAsync(getExcelFileName(t('ut.label.상세요약', '상세요약')));
  };

  const excludePin = useMemo(() => {
    const convertFlat = (acc, cur) => {
      if ('columns' in cur && Array.isArray(cur?.columns)) {
        return cur.columns.reduce(convertFlat, acc);
      }
      return ![
        'utmId',
        'prdnProcCdNm',
        'bizAreaNm',
        'eqclNm',
        'stndEqpId',
        'dtalProcCd',
        'bldgFloCd',
        'istlLocNm',
        'prdnLnNm',
        'prdnPrlLnNm',
        'eqpMainNm',
        'eqpMchNm',
        'eqpUntNm',
        'machEqpNm',
        'elpwEqpNm',
        'machEqpQty',
        'elpwEqpQty',
      ].includes(cur?.binding)
        ? [...acc, cur?.binding]
        : acc;
    };

    return (layoutDefinition || []).reduce(convertFlat, []);
  }, [layoutDefinition]);

  return (
    <>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={detailRowData}
        height={388}
        frozenColumns={13}
        excludePin={excludePin}
        excludeFilter={['utmSeq', 'prdnProcCdNm', 'eqclNm']}
        initialized={onInitialized}
        isReadOnly={true}
        isSelector={false}
      />
      {isOpenHookupTagPopup && (
        <UtMatrixHookupTagPopup
          open={isOpenHookupTagPopup}
          close={() => setOpenHookupTagPopup(false)}
          condition={hookupTagCondition}
        />
      )}
    </>
  );
};

export default React.forwardRef(DetailSummaryContent);
