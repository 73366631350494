/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useImperativeHandle, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { DataType } from '@grapecity/wijmo';
import {
  SubTitleLayout,
  SubTitleGroup,
  ControlBtnGroup,
  GlobalBtnGroup,
} from 'components/layouts/ContentLayout';
import { IconButton } from 'components/buttons/IconSVG';
import CustomGrid from 'components/grids/CustomGrid';
import { findMachineSummary } from 'apis/ut/UtMatrixRegist';
import { UtMatrixDetail, UtMatrixRegistSearchCondition } from 'models/ut/UtMatrix';
import { hasRole } from '../../../utils/SessionUtil';

const MachineSummaryContent = (props: any, ref) => {
  const {
    onSubmit,
    condition = {} as UtMatrixRegistSearchCondition,
    processData = [] as UtMatrixDetail[],
  } = props;
  const gridRef = useRef<any>();
  const { t } = useTranslation();
  const [rowData, setRowData] = useState<UtMatrixDetail[]>([]);
  const isUtWriter = useMemo(() => {
    const p = processData.filter(
      (o) => o.prdnProcCd === condition?.prdnProcCd && o.eqclId === condition?.eqclId
    );
    return (p.length > 0 && p[0].utmWriteYn === 'Y') || hasRole('ADM');
  }, [processData, condition?.prdnProcCd, condition?.eqclId]);
  const isWrite = useMemo(() => {
    const p = processData.filter(
      (o) => o.prdnProcCd === condition?.prdnProcCd && o.eqclId === condition?.eqclId
    );
    return p.length > 0 && p[0].utmWrtProcProgStatCd === 'UTP02';
  }, [processData, condition?.prdnProcCd, condition?.eqclId]);
  const isWriteComplete = useMemo(() => {
    const p = processData.filter(
      (o) => o.prdnProcCd === condition?.prdnProcCd && o.eqclId === condition?.eqclId
    );
    return p.length > 0 && p[0].utmWrtProcProgStatCd === 'UTP03';
  }, [processData, condition?.prdnProcCd, condition?.eqclId]);

  useImperativeHandle(ref, () => ({
    searchMachineSummary: (condition) => {
      searchMachineSummary(condition);
    },
  }));

  const searchMachineSummary = (condition) => {
    findMachineSummary(condition).then((result) => {
      setRowData(result);
    });
  };

  const layoutDefinition = [
    {
      binding: 'no',
      header: String(t('com.label.NO', 'NO')),
      width: 40,
      isReadOnly: true,
      align: 'center',
      cellTemplate: (grid) => grid.row._idx + 1,
    },
    {
      binding: 'prdnProcCdNm',
      header: String(t('ut.label.공정', '공정')),
      width: 100,
      align: 'center',
    },
    {
      binding: 'eqclNm',
      header: String(t('ut.label.설비군', '설비군')),
      width: 100,
    },
    {
      binding: 'qty',
      header: String(t('ut.label.라인/설비 수량', '라인/설비 수량')),
      width: 120,
      align: 'center',
      cellTemplate: (params) =>
        `${(params.item.eqpPrdnLnQty || 0).toLocaleString()} / ${(
          params.item.machEqpQty || 0
        ).toLocaleString()}`,
    },
    {
      header: String(t('ut.label.CDA', 'CDA')),
      columns: [
        {
          binding: 'cdaCspSumCapa',
          // header: String(t('ut.label.CDA[l/min]', 'CDA[ℓ/min]')),
          header: '[ℓ/min]',
          minWidth: 100,
          width: '*',
          dataType: DataType.Number,
          cellTemplate: (params) => Number(params.value || 0).toLocaleString(),
        },
      ],
    },
    {
      header: String(t('ut.label.N2', 'N2')),
      columns: [
        {
          binding: 'nitrPeakCspSumCapa',
          // header: String(t('ut.label.N2[l/min]', 'N2[ℓ/min]')),
          header: '[ℓ/min]',
          minWidth: 100,
          width: '*',
          dataType: DataType.Number,
          cellTemplate: (params) => Number(params.value || 0).toLocaleString(),
        },
      ],
    },
    {
      header: String(t('ut.label.IW', 'IW')),
      columns: [
        {
          binding: 'iwCspSumCapa',
          // header: String(t('ut.label.IW[l/min]', 'IW[ℓ/min]')),
          header: '[ℓ/min]',
          minWidth: 100,
          width: '*',
          dataType: DataType.Number,
          cellTemplate: (params) => Number(params.value || 0).toLocaleString(),
        },
      ],
    },
    {
      header: String(t('ut.label.IW2', 'FW')),
      columns: [
        {
          binding: 'frwtCspSumCapa',
          // header: String(t('ut.label.IW2[l/min]', 'FW[ℓ/min]')),
          header: '[ℓ/min]',
          minWidth: 100,
          width: '*',
          dataType: DataType.Number,
          cellTemplate: (params) => Number(params.value || 0).toLocaleString(),
        },
      ],
    },
    {
      header: String(t('ut.label.PCW', 'PCW')),
      columns: [
        {
          binding: 'coolCspSumCapa',
          // header: String(t('ut.label.PCW[l/min]', 'PCW[ℓ/min]')),
          header: '[ℓ/min]',
          minWidth: 100,
          width: '*',
          dataType: DataType.Number,
          cellTemplate: (params) => Number(params.value || 0).toLocaleString(),
        },
      ],
    },
    {
      header: String(t('ut.label.WW', 'WW')),
      columns: [
        {
          binding: 'wwCspSumCapa',
          // header: String(t('ut.label.WW[l/min]', 'WW[ℓ/min]')),
          header: '[ℓ/min]',
          minWidth: 100,
          width: '*',
          dataType: DataType.Number,
          cellTemplate: (params) => Number(params.value || 0).toLocaleString(),
        },
      ],
    },
    {
      header: String(t('ut.label.Steam', 'Steam')),
      columns: [
        {
          binding: 'stemCspSumCapa',
          // header: String(t('ut.label.Steam[Ton/hr]', 'Steam[Ton/hr]')),
          header: '[Ton/hr]',
          minWidth: 100,
          width: '*',
          dataType: DataType.Number,
          cellTemplate: (params) => Number(params.value || 0).toLocaleString(),
        },
      ],
    },
    {
      header: String(t('ut.label.NG', 'NG')),
      columns: [
        {
          binding: 'lngCspSumCapa',
          // header: String(t('ut.label.NG[N㎥/hr]', 'NG[N㎥/hr]')),
          header: '[N㎥/hr]',
          minWidth: 100,
          width: '*',
          dataType: DataType.Number,
          cellTemplate: (params) => Number(params.value || 0).toLocaleString(),
        },
      ],
    },
    {
      header: String(t('ut.label.Return air', 'Return air')),
      columns: [
        {
          binding: 'insdExhaCspSumCapa',
          // header: String(t('ut.label.Return air[CMH]', 'Return air[CMH]')),
          header: '[CMH]',
          minWidth: 100,
          width: '*',
          dataType: DataType.Number,
          cellTemplate: (params) => Number(params.value || 0).toLocaleString(),
        },
      ],
    },
    {
      header: String(t('ut.label.Exhaust air', 'Exhaust air')),
      columns: [
        {
          binding: 'otsdExhaCspSumCapa',
          // header: String(t('ut.label.Exhaust air[CMH]', 'Exhaust air[CMH]')),
          header: '[CMH]',
          minWidth: 100,
          width: '*',
          dataType: DataType.Number,
          cellTemplate: (params) => Number(params.value || 0).toLocaleString(),
        },
      ],
    },
    {
      header: String(t('ut.label.Supply Air', 'Supply air')),
      columns: [
        {
          binding: 'suarCspSumCapa',
          // header: String(t('ut.label.Supply Air', 'Supply air')),
          header: '[CHM]',
          minWidth: 100,
          width: '*',
          dataType: DataType.Number,
          cellTemplate: (params) => Number(params.value || 0).toLocaleString(),
        },
      ],
    },
  ];

  const onInitialized = (grid) => {
    console.log('grid init');
  };

  const handleSubmit = () => {
    props?.onSubmit?.();
  };

  const handleModify = () => {
    props?.onModify?.();
  };

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('ut.label.기계요약', '기계요약')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{(rowData || []).length.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
          {condition?.utmNo && condition?.prdnProcCdNm && condition?.eqclNm && (
            <div className="info warning">
              {condition?.utmNo} / {condition?.prdnProcCdNm} / {condition?.eqclNm}
            </div>
          )}
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="refresh"
            onClick={() => searchMachineSummary(condition)}
          >
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        height={388}
        frozenColumns={3}
        isSelector={false}
        isFilter={false}
        isReadOnly={true}
        allowPinning={false}
        initialized={onInitialized}
      />
      <GlobalBtnGroup>
        {isWrite && isUtWriter && (
          <Button css={IconButton.button} className="save" onClick={handleSubmit}>
            {t('com.button.제출', '제출')}
          </Button>
        )}
        {isWriteComplete && isUtWriter && (
          <Button css={IconButton.button} className="save" onClick={handleModify}>
            {t('com.button.수정', '수정')}
          </Button>
        )}
      </GlobalBtnGroup>
    </>
  );
};

export default React.forwardRef(MachineSummaryContent);
