/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useMemo } from 'react';
import useEvent from 'react-use-event-hook';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import CustomGrid from 'components/grids/CustomGrid';
import { addClass } from '@grapecity/wijmo';
import { CellType } from '@grapecity/wijmo.grid';
import { Button } from '@mui/material';
import { SubTitleLayout, SubTitleGroup, ControlBtnGroup } from 'components/layouts/ContentLayout';
import { IconButton } from 'components/buttons/IconSVG';
import CustomDialog from 'components/modals/common/CustomDialog';
import { SetupDashboardLegendPopup } from './SetupDashboardLegendPopup';
import { getSetupScheduleChartList } from 'apis/pjm/SetupDashboard';

type Props = {
  open: boolean;
  close: () => void;
  condition: any;
};

const SetupScheduleChartPopup = ({ open, close, condition }: Props) => {
  const { t } = useTranslation();
  const [isOpenlegendPopUp, setOpenlegendPopUp] = useState<boolean>(false);
  const [rowData, setRowData] = useState<any[]>([]); // SetupScheduleChart + yyyymm 컬럼 추가

  const dateRange = (startDt, endDt) => {
    const dates = [] as string[];
    if (startDt && endDt) {
      let start = dayjs(startDt);
      const end = dayjs(endDt);

      while (start <= end) {
        start = start.set('date', 1);
        dates.push(start.format('YYYY.MM.DD'));
        start = start.add(1, 'month');
      }
    }
    return dates;
  };

  useEffect(() => {
    const load = async () => {
      await getSetupScheduleChartList(condition).then(async (response) => {
        if (response) {
          setRowData(
            (response || []).reduce((acc, cur) => {
              const range = dateRange(cur.planStartDt, cur.planEndDt);
              const dateCols = (range || []).reduce((racc, rcur) => {
                const day = dayjs(rcur);
                const year = String(day.year());
                const month = String(day.month() + 1);
                return {
                  ...racc,
                  [`${year}${month}`]: cur.state, // `${cur.planStartDt} - ${cur.planEndDt} (${cur.state})`,
                };
              }, {} as any);
              acc.push({
                ...cur,
                ...dateCols,
              });
              return acc;
            }, [] as any[])
          );
        }
      });
    };
    load();
  }, []);

  const layoutDefinition = useMemo(() => {
    const min = (rowData || []).length > 0 ? rowData[0].planEndDt : '';
    const max = (rowData || []).length > 0 ? rowData[rowData.length - 1].planEndDt : '';
    const range = dateRange(min, max);
    const stateCodes = {
      CPLT: { color: 'grey', text: t('pjm.label.적기완료', '적기완료'), format: '' },
      DLY_CPLT: {
        color: 'dark-gray',
        text: t('pjm.label.지연완료', '지연완료'),
        format: '{crs}', // 완료일자(YYYY.MM.DD)
      },
      OBSR: { color: 'green', text: t('pjm.label.준수', '준수'), format: 'D-{crs}' },
      DLY: { color: 'red', text: t('pjm.label.지연', '지연'), format: 'D+{crs}' },
      PLAN: { color: 'purple', text: t('pjm.label.계획중', '계획중'), format: '{crs}' }, // 계획종료일(YYYY.MM.DD)
    };
    const getStateTag = (params) => {
      if (!params.value || !params.item.state) return '';
      let crs = '';
      if ('DLY_CPLT' === params.item.state) {
        crs =
          (params.item.crs || '').length === 8 ? dayjs(params.item.crs).format('YYYY.MM.DD') : '';
      } else if (['DLY', 'OBSR'].includes(params.item.state)) {
        crs = params.item.crs;
      } else if ('PLAN' === params.item.state) {
        crs = params.item.planEndDt ? dayjs(params.item.planEndDt)?.format('YYYY.MM.DD') : '';
      }
      // const text = stateCodes[params.item.state].text;
      const format = stateCodes[params.item.state].format;
      return `<span class="${stateCodes[params.item.state].color}">${
        crs ? format.replace('{crs}', crs) : ''
      }</span>`;
    };

    const columns = (range || []).reduce((acc, cur) => {
      const day = dayjs(cur);
      const year = String(day.year());
      const month = String(day.month() + 1);
      const parent = (acc || []).filter((o) => o.binding === year);
      let yearColumn;
      // 해당 년도의 컬럼이 없는 경우 생성
      if (parent.length < 1) {
        yearColumn = {
          binding: year,
          header: year,
        };
      } else {
        yearColumn = parent[0];
      }
      if (!yearColumn['columns']) {
        yearColumn['columns'] = [];
      }
      yearColumn['columns'].push({
        binding: `${year}${month}`,
        header: month,
        align: 'center',
        cssClass: 'WijmoTag',
        minWidth: 80,
        width: '*',
        cellTemplate: getStateTag,
      });
      // 해당 년도의 컬럼이 없는 경우 추가
      if (parent.length < 1) {
        acc.push(yearColumn);
      }
      return acc;
    }, [] as any[]);

    return [
      {
        binding: 'prjSpCdNm',
        header: String(t('pjm.label.단계', '단계')),
        align: 'center',
        width: 120,
      },
      {
        binding: 'planEndDt',
        header: String(t('pjm.label.계획일정', '계획일정')),
        align: 'center',
        width: 120,
      },
      ...columns,
    ];
  }, [rowData]);

  const onItemFormatter = useEvent((panel, row, col, cell) => {
    if (CellType.Cell === panel.cellType) {
      const binding = panel.columns[col].binding;
      if (['prjSpCdNm', 'planEndDt'].includes(binding)) {
        addClass(cell, 'transposed-header');
      }
    }
  });

  const onInitialized = (grid) => {
    grid.itemFormatter = onItemFormatter;
  };

  return (
    <CustomDialog
      title={`${t('com.label.설비별 일정 Chart', '설비별 일정 Chart')} (${[
        condition?.prdnProcCdNm,
        condition?.eqclId,
        condition?.eqpLnId,
      ]
        .filter((o) => o)
        .join(' ')})`}
      size="xl"
      open={open}
      onClose={close}
    >
      <SubTitleLayout>
        <SubTitleGroup></SubTitleGroup>
        <ControlBtnGroup style={{ justifyContent: 'flex-end' }}>
          <Button
            css={IconButton.button}
            className="rule"
            onClick={() => setOpenlegendPopUp(true)}
            disableRipple
          >
            {t('pjm.label.범례', '범례', '범례')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>

      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        isSelector={false}
        isFilter={false}
        allowPinning={false}
        isReadOnly={true}
        isHoverCss={false}
        isGantt={true}
        frozenColumns={2}
        height={523} // 506
        initialized={onInitialized}
      />

      {isOpenlegendPopUp && (
        <SetupDashboardLegendPopup
          open={isOpenlegendPopUp}
          close={() => setOpenlegendPopUp(false)}
        />
      )}
    </CustomDialog>
  );
};

export default SetupScheduleChartPopup;
