/** @jsxImportSource @emotion/react */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { useState, useEffect, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import { tb } from 'components/layouts/Table';
import { IconButton } from '../../../components/buttons/IconSVG';
import DialogContent from '@mui/material/DialogContent';
import {
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { di, TitleWrap, PopupBtnGroup } from '../../../components/layouts/Dialog';
import { useTranslation } from 'react-i18next';
import { SearchButtonWrap } from '../../../components/layouts/SearchBox';
import { CloseBtn } from '../../../components/buttons/CustomButton';
import CustomTextarea from 'components/inputs/CustomTextarea';
import { useCommonModal } from 'hooks/useCommonModal';
import { useLoading } from 'components/process/Loading';
import { useMessageBar } from 'hooks/useMessageBar';
import { SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import { hasRole } from 'utils/SessionUtil';
import { getExcpSecrPldgDetail, getSecrPldgVer, saveSecrPldgInfo } from 'apis/admin/ExcpPartner';
import { ManagementMode } from 'models/common/Common';
import { ExcpPartnerCondition } from 'models/admin/ExcpPartner';
import { PartnerSecrPldg } from 'models/common/Partner';
import CustomDatepicker from 'components/inputs/CustomDatepicker';
import dayjs from 'dayjs';
import Datepicker, { DateType } from 'react-tailwindcss-datepicker';
import useSessionStore from 'stores/useSessionStore';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { DatePickerWrap } from 'components/inputs/DatePicker';

interface SearchParam {
  userId?: string;
  empNm?: string;
  deptNm?: string;
  useYn?: string;
  isEditableFlag?: boolean;
}

type Props = {
  open: boolean;
  condition: any;
  isReadOnly: boolean;
  close: () => void;
  useCallback: () => void;
};

export const ExcpSecrPldgPopUp = (props: Props) => {
  const { t } = useTranslation();
  const { condition } = props;
  const [secrPldgCondition, setSecrPldgCondition] = useState<PartnerSecrPldg>({});
  const { openMessageBar } = useMessageBar();
  const { openCommonModal } = useCommonModal();
  const { userId, empNm } = useSessionStore();
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    if (condition.mode === 'M') {
      console.log('M');
      init();
    }
    if (condition.mode === 'C') {
      console.log('C');
      getSecrPldgVer().then((result) => {
        if (result !== null) {
          setSecrPldgCondition({
            secrPldgVer: result,
            dataUpdUserNm: empNm,
            finYn: 'N',
          });
        } else {
          openMessageBar({
            type: 'error',
            content: t(
              'com.msg.요청 정보 조회 중 오류가 발생했습니다.',
              '요청 정보 조회 중 오류가 발생했습니다.'
            ),
          });
          return false;
        }
      });
    }
  }, []);

  const init = () => {
    getExcpSecrPldgDetail(props.condition).then((result: PartnerSecrPldg) => {
      console.log('result--------------- ', result);

      if (result !== null && result !== undefined) {
        setSecrPldgCondition(result);
      } else {
        openMessageBar({
          type: 'error',
          content: t(
            'com.msg.요청 정보 조회 중 오류가 발생했습니다.',
            '요청 정보 조회 중 오류가 발생했습니다.'
          ),
        });
        return false;
      }
    });
  };

  const handleSecrPldgCondition = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setSecrPldgCondition({
      ...secrPldgCondition,
      [name]: value,
    });
    setErrors({ ...errors, [e.target.name]: false });
  };

  const validate = () => {
    const err = {};
    const msg = [] as string[];
    // 개정일
    if (!secrPldgCondition?.revDt) {
      Object.assign(err, { revDt: true });
    }
    // 설명
    if (!secrPldgCondition?.secrPldgDesc) {
      Object.assign(err, { secrPldgDesc: true });
    }
    // 내용
    if (!secrPldgCondition?.secrPldgCtn) {
      Object.assign(err, { secrPldgCtn: true });
    }

    if (msg.length) {
      openMessageBar({
        type: 'error',
        content: msg.join('<br/>'),
      });
    }

    setErrors(err);
    if (!Object.keys(err).filter((k) => err[k]).length) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    setErrors({ ...errors, revDt: false });
  }, [secrPldgCondition.revDt]);

  const btnSave = () => {
    if (!validate()) return false;
    secrPldgCondition.finYn = 'N';
    openCommonModal({
      modalType: 'confirm',
      content: t(`com.msg.저장하시겠습니까?`, `저장하시겠습니까?`),
      yesCallback: async () => {
        saveSecrPldgInfo(secrPldgCondition).then((result) => {
          openMessageBar({
            type: result?.successOrNot === 'Y' ? 'confirm' : 'error',
            content:
              result?.successOrNot === 'Y'
                ? t('com.msg.저장되었습니다.', '저장되었습니다.')
                : t('com.msg.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
          });
          props.close();
          props.useCallback();
        });
      },
    });
  };

  const btnConfirm = (req) => {
    if (!validate()) return false;
    if (req === 'TMP') {
      secrPldgCondition.finYn = 'N';
    }
    if (req === 'APR') {
      secrPldgCondition.finYn = 'Y';
    }

    console.log('secrPldgCondition', secrPldgCondition);
    openCommonModal({
      modalType: 'confirm',
      content:
        req === 'TMP'
          ? t('com.msg.저장하시겠습니까?', '저장하시겠습니까?')
          : t(
              `com.msg.반영완료 처리 시 기존 보안서약 완료한 사용자 정보가 초기화 됩니다.\n진행 하시겠습니까?`,
              `반영완료 처리 시 기존 보안서약 완료한 사용자 정보가 초기화 됩니다.\n진행 하시겠습니까?`
            ),
      yesCallback: async () => {
        saveSecrPldgInfo(secrPldgCondition).then((result) => {
          openMessageBar({
            type: result?.successOrNot === 'Y' ? 'confirm' : 'error',
            content:
              result?.successOrNot === 'Y'
                ? t('com.msg.저장되었습니다.', '저장되었습니다.')
                : t('com.msg.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
          });
          props.close();
          props.useCallback();
        });
      },
    });
  };

  return (
    <>
      <Dialog open={true} css={di.dialog} fullWidth className="md" maxWidth={false}>
        <TitleWrap>
          <h2>{t('com.label.보안서약서', '보안서약서')}</h2>
          <CloseBtn
            onClick={() => {
              props.close();
            }}
          ></CloseBtn>
        </TitleWrap>
        <DialogContent>
          <SubTitleLayout>
            <SubTitleGroup>
              <h3>{t('com.label.기본정보', '기본정보')}</h3>
            </SubTitleGroup>
          </SubTitleLayout>
          <TableContainer css={tb.table}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>{t('com.label.버전', '버전')}</TableCell>
                  <TableCell className="w500">{secrPldgCondition?.secrPldgVer}</TableCell>
                  <TableCell>{t('com.label.담당자', '담당자')}</TableCell>
                  <TableCell className="w500">{secrPldgCondition?.dataUpdUserNm}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <span className="dot">{t('com.label.개정일', '개정일')}</span>
                  </TableCell>
                  <TableCell>
                    <CustomDatepicker
                      isRange={false}
                      value={secrPldgCondition?.revDt}
                      onChange={(newValue) => {
                        setSecrPldgCondition((prev) => ({
                          ...prev,
                          revDt: newValue as DateType as string,
                        }));
                      }}
                      disabled={props.isReadOnly}
                      readOnly={props.isReadOnly}
                      isError={errors?.revDt}
                      msgError={String(
                        t('com.msg.개정일을 입력해 주세요.', '개정일을 입력해 주세요.')
                      )}
                    />
                  </TableCell>
                  <TableCell>{t('com.label.반영일', '반영일')}</TableCell>
                  <TableCell className="w500">{secrPldgCondition?.rflcDt}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <span className="dot">{t('com.label.설명', '설명')}</span>
                  </TableCell>
                  <TableCell colSpan={3}>
                    <CustomInputWithSearch
                      id="secrPldgDesc"
                      name="secrPldgDesc"
                      value={secrPldgCondition?.secrPldgDesc}
                      onChange={handleSecrPldgCondition}
                      placeholder={String(
                        t('com.label.설명을 입력해 주세요.', '설명을 입력해 주세요.')
                      )}
                      readOnly={props.isReadOnly}
                      isError={errors?.secrPldgDesc}
                      msgError={String(t('com.msg.설명을 입력해 주세요.', '설명을 입력해 주세요.'))}
                    ></CustomInputWithSearch>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <SubTitleLayout>
            <SubTitleGroup>
              <h3>{t('com.label.보안서약내용', '보안서약내용')}</h3>
            </SubTitleGroup>
          </SubTitleLayout>
          <TableContainer>
            <Table css={tb.table}>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={3}>
                    <CustomTextarea
                      name="secrPldgCtn"
                      id="secrPldgCtn"
                      className="height200"
                      value={secrPldgCondition?.secrPldgCtn
                        ?.replaceAll('&amp;lsquo;', "'")
                        .replaceAll('&amp;rsquo;', "'")
                        .replaceAll('&rsquo;', "'")
                        .replaceAll('&lsquo;', "'")}
                      onChange={handleSecrPldgCondition}
                      placeholder={String(
                        t('com.label.내용을 입력해 주세요.', '내용을 입력해 주세요.')
                      )}
                      readOnly={props.isReadOnly}
                      isError={errors?.secrPldgCtn}
                      // maxLength={2000}
                      // showMaxLength={true}
                      msgError={String(t('com.msg.내용을 입력해 주세요.', '내용을 입력해 주세요.'))}
                      wrapStyle={{ height: '250px' }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <PopupBtnGroup>
          <Button
            css={IconButton.button}
            className="cancel"
            disableRipple
            onClick={() => props.close()}
          >
            {t('com.button.취소', '취소')}
          </Button>
          {!props.isReadOnly && (
            <>
              <Button
                css={IconButton.button}
                className="save"
                disableRipple
                onClick={() => btnConfirm('TMP')}
              >
                {t('com.button.저장', '저장')}
              </Button>
              <Button
                css={IconButton.button}
                className="confirm"
                disableRipple
                onClick={() => btnConfirm('APR')}
              >
                {t('com.button.반영', '반영')}
              </Button>
            </>
          )}
        </PopupBtnGroup>
      </Dialog>
    </>
  );
};
