/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCommonModal } from 'hooks/useCommonModal';
import { IdleAssetDetail, IdleAssetMaster } from 'models/asst/IdleAsset';
import { tb } from 'components/layouts/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import { SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import { useMessageBar } from 'hooks/useMessageBar';
import { Code } from 'models/common/CommonCode';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { findIdleAssetEquipments, findIdleAssetMaster } from '../../../apis/asst/IdleAsset';
import IdleAssetEquipmentGrid from '../management/IdleAssetEquipmentGrid';
import IdleAssetReviewGrid from '../management/IdleAssetReviewGrid';
import CustomDialog from 'components/modals/common/CustomDialog';

type Props = {
  open: boolean;
  close: () => void;
  initParam?: {
    // asstTpCd?: string; //자산유형코드
    rvwReqId?: string; //검토요청ID
    // tgtCopCd?: string; //대상법인코드
    // eqpId?: string; //설비ID
    copCd?: string;
    // crgeDeptCd?: string; //검토부서코드
    asstRvwReqCopCd?: string;
  };
  onRefresh: () => void;
  // onCallback: () => void;sss
};

export const IdlAssetReviewResultCfmPopUp = ({ open, close, initParam, onRefresh }: Props) => {
  const { t } = useTranslation();
  const gridRef = useRef<any>();
  const { openMessageBar } = useMessageBar();
  const [isSave, setSave] = useState<boolean>(false);
  const [rowData, setRowData] = useState<IdleAssetDetail>();
  const [rowDataResult, setRowDataResult] = useState<IdleAssetDetail[]>([]);
  //const [rowDataResultEqp, setRowDataResultEqp] = useState<IdleAssetDetail[]>([]);

  const [code, setCode] = useState<any>();
  const { openCommonModal } = useCommonModal();

  const equipRef = useRef<any>();
  const reviewRef = useRef<any>();

  const handleSearch = () => {
    equipRef.current?.search(initParam?.rvwReqId);
  };

  const getCommonCodes = async () => {
    const rvwRltDivsCd: Code[] = await getCommonCodeNames('RVW_RLT_DIVS_CD'); // 검토결과
    const rvwStatCd: Code[] = await getCommonCodeNames('RVW_STAT_CD'); // 검토상태(부서)
    const asstTpCd: Code[] = await getCommonCodeNames('ASST_TP_CD'); // 분류

    setCode({
      rvwRltDivsCd: rvwRltDivsCd,
      rvwStatCd: rvwStatCd,
      asstTpCd: asstTpCd,
    });
  };

  /**
   * props.initParam 변경 시 실행1
   */
  useEffect(() => {
    console.log(initParam);
    setSave(false);
    getCommonCodes();
    getEquipmentInfo(initParam);
  }, [initParam?.rvwReqId]);

  const getEquipmentInfo = async (initParam) => {
    if (initParam.rvwReqId) {
      findIdleAssetMaster(initParam.rvwReqId).then((result) => {
        setRowData(result[0]);
      });
      findIdleAssetEquipments(initParam.rvwReqId, initParam.asstRvwReqCopCd).then((result) => {
        equipRef.current?.search({
          rvwReqId: result[0]?.rvwReqId,
          asstRvwReqCopCd: result[0]?.asstRvwReqCopCd,
        } as IdleAssetMaster);
        //setRowDataResultEqp(result);
      });
    }
  };

  const handleClose = () => {
    if (isSave) {
      onRefresh();
    }
    close();
  };

  return (
    <CustomDialog
      open={open}
      onClose={handleClose}
      size={'xl'}
      title={t('asst.label.최종결과입력', '최종결과입력')}
      // buttons={dialogButtons}
    >
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('asst.label.유휴자산정보', '불용자산정보')}</h3>
        </SubTitleGroup>
      </SubTitleLayout>
      <TableContainer css={tb.table}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>{t('asst.label.요청번호', '요청번호')}</TableCell>
              <TableCell className="col31">{rowData?.rvwReqId}</TableCell>
              <TableCell>{t('asst.label.요청법인', '요청법인')}</TableCell>
              <TableCell className="col31">{rowData?.asstRvwReqCopCd}</TableCell>
              <TableCell>{t('asst.label.요청자', '요청자')}</TableCell>
              <TableCell className="col31">{rowData?.reqInsUserNm}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('asst.label.요청제목', '요청제목')}</TableCell>
              <TableCell className="col31" colSpan={3}>
                {rowData?.rvwReqTitNm}
              </TableCell>
              <TableCell>{t('asst.label.검토완료일자', '검토완료일자')}</TableCell>
              <TableCell className="col31">{rowData?.rvwCpltDt}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <IdleAssetEquipmentGrid
        isAsstMstComplete={rowData?.rvwStatCd == 'CPLT'}
        resultCfm={true}
        gridHeight={150}
        ref={equipRef}
        onClikDetail={(item) => {
          reviewRef.current?.search(item);
        }}
        onRefresh={() => {
          // 법인별 검토 현황 재조회
          reviewRef.current?.refresh();
          setSave(true);
        }}
      />

      <IdleAssetReviewGrid
        ref={reviewRef}
        resultCfm={true}
        onRefresh={() => {
          // 불용자산 설비 목록 재조회
          getEquipmentInfo(initParam);
          setSave(true);
        }}
      />
    </CustomDialog>
  );
};
