/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Checkbox } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ContainerLayout } from 'components/layouts/ContainerLayout';
import { ControlBtnGroup, SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import {
  InputBox,
  SearchBox,
  SearchBoxRow,
  SearchButtonWrap,
  SearchCols,
  SearchRows,
} from 'components/layouts/SearchBox';
import { IconButton } from 'components/buttons/IconSVG';
import { getCommonCodeNames, getCommonCodeNamesCondition } from 'apis/admin/CommonCode';
import { MultiComboBox } from 'components/selects/ComboBox';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'hooks/useMessageBar';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { Code } from 'models/common/CommonCode';
import useSessionStore from 'stores/useSessionStore';
import { hasRole } from 'utils/SessionUtil';
import { st } from 'components/inputs/CustomInput';
import { UtMatrixManagerList } from 'models/ut/UtMatrixManager';
import { CrudCode } from 'models/common/Edit';
import { ContentSection } from 'components/layouts/ContentSection';
import { ContentFlex } from 'components/layouts/ContentFlex';
import { ApproveRequestPageType } from '../../approves/ApproveRequestDetailCase';
import UtMatrixLibraryMasterGrid from './UtMatrixLibraryMasterGrid';
import UtMatrixLibraryDetailGrid from './UtMatrixLibraryDetailGrid';
import {
  UtMatrixStandardEquipment,
  UtMatrixStandardEquipmentCondition,
} from 'models/ut/UtMatrixStandardEquipment';
import { findUtMatrixStandardEquipmentList, checkAvailableRequest } from 'apis/ut/UtMatrixLibrary';
import { downloadExcelTemplatesPost } from 'apis/common/Excel';
import { CommonUtil } from 'utils/CommonUtil';
import { ManagementMode } from 'models/common/Common';
import ApproveRequestModal from '../../approves/ApproveRequestModal';
import StandardEquipmentPopup from '../popup/StandardEquipmentPopup';
import _ from 'lodash';
import { UtMatrixLibrary } from '../../../models/ut/UtMatrixLibrary';

const UtMatrixLibraryPage = () => {
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();
  const { openCommonModal } = useCommonModal();
  const navigate = useNavigate();
  const userSession = useSessionStore();

  const gridRef = useRef<any>();
  const [hitTest, setHitTest] = useState<any>();
  const [flexRef, setFlexRef] = useState<any>();
  const [detailFlexRef, setDetailFlexRef] = useState<any>();
  const [flexItem, setFlexItem] = useState<any>();

  const [rowData, setRowData] = useState<UtMatrixStandardEquipment[]>([]);

  const location = useLocation();
  const state = { ...location.state };
  const [locationState, setLocationState] = useState<any>(useLocation().state);
  const [searchState, setSearchState] = useState<any>();

  const [gridUseYn, setGridUseYn] = useState<Code[]>([]);

  const [userIds, setUserIds] = useState<string>('');
  const [wrtUserNm, setWrtUserNm] = useState<string>('');
  const [utMatrixStandardEquipmentCondition, setUtMatrixStandardEquipmentCondition] =
    useState<UtMatrixStandardEquipmentCondition>({
      prdnProcCds: [],
      eqclIds: [],
      dtalProcCds: [],
      eqpMchId: '',
      stndEqpId: '',
    });

  const [mode, setMode] = useState<string>('');

  const [isOpenStandardEquipmentModal, setOpenStandardEquipmentModaletOpenModal] =
    useState<boolean>(false);

  const [requestModalCondition, setRequestModalCondition] = useState<any>();
  const [isOpenRequestModal, setOpenRequestModal] = useState<boolean>(false);

  const [rowItem, setRowItem] = useState<any>();
  const [hasAuth, setHasAuth] = useState<boolean>(false);

  const [delData, setDelData] = useState<UtMatrixManagerList[]>([]);

  const masterGridRef = useRef<any>();
  const detailGridRef = useRef<any>();

  const isReadOnly = useMemo(() => {
    return hasAuth;
  }, [hasAuth]);

  useEffect(() => {
    initCondition();
    if (hasRole('ADM') || hasRole('UT_MANAGER')) {
      setHasAuth(true);
    }

    setSearchState(true);
  }, []);

  useEffect(() => {
    if (!_.isEmpty(state)) {
      setUtMatrixStandardEquipmentCondition({
        stndEqpId: state?.stndEqpId,
      });
    }
  }, [state?.stndEqpId]);

  useEffect(() => {
    if (searchState) {
      setTimeout(() => {
        handleSearch();
        setSearchState(false);
      }, 100);
    }
  }, [searchState]);

  // const initCondition = async () => {
  //   const prdnProcCd = await getCommonCodeNames('PRDN_PROC_CD');
  //   const eqpGrCd = await getCommonCodeNames('EQCL_ID');
  //   const dtalProcCd = await getCommonCodeNames('DTAL_PROC_CD');
  //   //const getUseYn: Code[] = await getCommonCodeNames('YN_FLAG');
  //   // setGridUseYn(getUseYn);
  //   setPrdnProcCode(prdnProcCd);
  //   setEqpGrCode(eqpGrCd);
  //   setDtalProcCode(dtalProcCd);
  // };

  const [getCommPrdnProcCode, setCommPrdnProcCode] = useState<Code[]>([]); // 공정 공정코드
  const [getCommEqclIdCodeList, setCommEqclIdCodeList] = useState<Code[]>([]); // 설비군 공통코드
  const [getCommDtalProcCodeList, setCommDtalProcCodeList] = useState<Code[]>([]); // 세부공정 공통코드

  const [eqclIdCode, setEqclIdCode] = useState<Code[]>([]); // 설비군 조회조건
  const [dtalProcCode, setDtalProcCode] = useState<Code[]>([]); // 세부공정 조회조건

  const [dtalProcListCode, setDtalProcListCode] = useState<Code[]>([]); // 공정-설비군-세부공정 공통코드(하이라키)

  const [changeCode, setChangeCode] = useState<string>('');

  const initCondition = async () => {
    const prdnProcCd = await getCommonCodeNamesCondition({
      optValCtn1: 'Y',
      cmnGrCd: 'PRDN_PROC_CD',
    });
    const eqclIdCd = await getCommonCodeNamesCondition({
      optValCtn1: 'Y',
      cmnGrCd: 'EQCL_ID',
    });
    const dtalProcCd = await getCommonCodeNames('DTAL_PROC_CD');

    setCommPrdnProcCode(prdnProcCd);
    setCommEqclIdCodeList(eqclIdCd);
    setCommDtalProcCodeList(dtalProcCd);

    const dtalProclist = await getCommonCodeNames('DTAL_PROC_LIST'); //공정-설비군-세부공정 하이라키 구조 공통코드
    setDtalProcListCode(dtalProclist); //공정-설비군-세부공정 하이라키 구조 공통코드
  };

  useEffect(() => {
    if (changeCode === 'prdnProcCd') {
      utMatrixStandardEquipmentCondition.eqclIds = [];
      utMatrixStandardEquipmentCondition.dtalProcCds = [];
      const selectedPrdnProcCd = utMatrixStandardEquipmentCondition.prdnProcCd?.split(',') || [];

      const findedEqclIdList: Code[] = [];
      if (selectedPrdnProcCd?.length > 0) {
        for (let i = 0; i < selectedPrdnProcCd.length; i++) {
          const selectedCode: Code[] = dtalProcListCode.filter(
            (code) => code.optValCtn1 === selectedPrdnProcCd[i]
          );
          findedEqclIdList.push(...selectedCode);
        }
      }

      const makeEqclIdCode: Code[] = [];
      for (let i = 0; i < findedEqclIdList.length; i++) {
        for (let j = 0; j < getCommEqclIdCodeList.length; j++) {
          if (findedEqclIdList[i].optValCtn2 === getCommEqclIdCodeList[j].cmnCd) {
            makeEqclIdCode.push({
              cmnCd: findedEqclIdList[i].optValCtn2,
              cmnCdNm: getCommEqclIdCodeList[j].cmnCdNm,
            });
          }
        }
      }

      const deleteDuplication = makeEqclIdCode.filter((code, index) => {
        return (
          makeEqclIdCode.findIndex((scdCode) => {
            return code.cmnCd === scdCode.cmnCd;
          }) === index
        );
      });

      setEqclIdCode(deleteDuplication);
    }

    if (changeCode === 'eqclId') {
      utMatrixStandardEquipmentCondition.dtalProcCds = [];
      const selectedEqclId = utMatrixStandardEquipmentCondition.eqclId?.split(',') || [];

      const findedDtalProcCdList: Code[] = [];
      if (selectedEqclId?.length > 0) {
        for (let i = 0; i < selectedEqclId.length; i++) {
          const selectedCode: Code[] = dtalProcListCode.filter(
            (code) => code.optValCtn2 === selectedEqclId[i]
          );
          findedDtalProcCdList.push(...selectedCode);
        }
      }

      const makeDtalProcCode: Code[] = [];
      for (let i = 0; i < findedDtalProcCdList.length; i++) {
        for (let j = 0; j < getCommDtalProcCodeList.length; j++) {
          if (findedDtalProcCdList[i].optValCtn3 === getCommDtalProcCodeList[j].cmnCd) {
            makeDtalProcCode.push({
              cmnCd: findedDtalProcCdList[i].optValCtn3,
              cmnCdNm: getCommDtalProcCodeList[j].cmnCdNm,
            });
          }
        }
      }

      const deleteDuplication = makeDtalProcCode.filter((code, index) => {
        return (
          makeDtalProcCode.findIndex((scdCode) => {
            return code.cmnCd === scdCode.cmnCd;
          }) === index
        );
      });

      setDtalProcCode(deleteDuplication);
    }
  }, [utMatrixStandardEquipmentCondition]);

  const btnReload = () => {
    setUtMatrixStandardEquipmentCondition(
      (prev) =>
        Object.keys(prev).reduce(
          (acc, cur) =>
            Object.assign(acc, {
              [cur]: { prdnProcCds: [], eqclIds: [], dtalProcCds: [], eqpMchId: '' }[cur] || '',
            }),
          {}
        ) as UtMatrixStandardEquipmentCondition
    );
    setEqclIdCode([]);
    setDtalProcCode([]);
  };

  const handleOnChange = (name, value) => {
    setUtMatrixStandardEquipmentCondition({ ...utMatrixStandardEquipmentCondition, [name]: value });
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSearch = () => {
    detailGridRef.current.init();
    masterGridRef.current.search(utMatrixStandardEquipmentCondition);
  };

  const onDetailGridRef = (gridRef) => {
    setDetailFlexRef(gridRef);
  };
  const onGridRef = (gridRef) => {
    setFlexRef(gridRef);
  };

  return (
    <ContainerLayout>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows>
              <SearchCols>
                <label>{String(t('ut.label.공정', '공정'))}</label>
                <MultiComboBox
                  id="prdnProcCd"
                  options={getCommPrdnProcCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={utMatrixStandardEquipmentCondition?.prdnProcCds || []}
                  onChange={(value) => {
                    handleOnChange('prdnProcCd', value.join());
                    setChangeCode('prdnProcCd');
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{String(t('ut.label.설비군', '설비군'))}</label>
                <MultiComboBox
                  id="eqclId"
                  options={eqclIdCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={utMatrixStandardEquipmentCondition?.eqclIds || []}
                  onChange={(value) => {
                    handleOnChange('eqclId', value.join());
                    setChangeCode('eqclId');
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{String(t('ut.label.세부공정', '세부공정'))}</label>
                <MultiComboBox
                  id="dtalProcCd"
                  options={dtalProcCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={utMatrixStandardEquipmentCondition?.dtalProcCds || []}
                  onChange={(value) => {
                    handleOnChange('dtalProcCd', value.join());
                    setChangeCode('dtalProcCd');
                  }}
                />
              </SearchCols>
            </SearchRows>
            <SearchRows>
              <SearchCols>
                <label>{t('com.label.Machine', 'Machine')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="eqpMchNm"
                  value={utMatrixStandardEquipmentCondition.eqpMchNm}
                  placeholder={String(
                    t('ut.label.Machine을 입력해 주세요.', 'Machine을 입력해 주세요.')
                  )}
                  onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                  onKeyDown={onKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('ut.label.표준설비코드', '표준설비코드')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="stndEqpId"
                  value={utMatrixStandardEquipmentCondition.stndEqpId}
                  placeholder={String(
                    t('ut.label.표준설비코드를 입력해 주세요.', '표준설비코드를 입력해 주세요.')
                  )}
                  onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                  onKeyDown={onKeyDown}
                />
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchButtonWrap>
            <Button
              css={IconButton.button}
              className="reload"
              onClick={btnReload}
              disableRipple
            ></Button>
            <Button css={IconButton.button} className="find" onClick={handleSearch} disableRipple>
              {t('com.button.조회', '조회')}
            </Button>
          </SearchButtonWrap>
        </SearchBoxRow>
      </SearchBox>
      <ContentFlex>
        <ContentSection className="section width33p marginT0">
          <UtMatrixLibraryMasterGrid
            ref={masterGridRef}
            condition={utMatrixStandardEquipmentCondition}
            onGridRef={onGridRef}
            onClickDetail={(item) => {
              detailGridRef.current.search(item);
            }}
            onClickApprove={(aprReqId) => {
              if (aprReqId) {
                setRequestModalCondition({
                  pageId: ApproveRequestPageType.UT_LIB_REQ,
                  aprReqId: aprReqId,
                });
                setOpenRequestModal(true);
              }
            }}
          />
        </ContentSection>
        <ContentSection className="section width65p">
          <UtMatrixLibraryDetailGrid
            ref={detailGridRef}
            masterRef={flexRef}
            onDetailGridRef={onDetailGridRef}
            onClickStndConfirmBtn={() => {
              setOpenStandardEquipmentModaletOpenModal(true);
            }}
            onClickRegistBtn={() => {
              setRequestModalCondition({
                pageId: ApproveRequestPageType.UT_LIB_REQ,
                mode: ManagementMode.CREATE,
              });
              setOpenRequestModal(true);
            }}
            onClickEditBtn={() => {
              const selectedMasterRows = masterGridRef.current.getSelectedRow();
              if (selectedMasterRows.length < 1) {
                openMessageBar({
                  type: 'error',
                  content: t('ut.label.수정할 항목을 선택해주세요.', '수정할 항목을 선택해주세요.'),
                });
                return;
              }
              const masterList = selectedMasterRows.map((o) => ({ ...o }));
              checkAvailableRequest(mode, masterList).then((res) => {
                if (res.successOrNot === 'Y' && res.data) {
                  setRequestModalCondition({
                    pageId: ApproveRequestPageType.UT_LIB_UPDATE_REQ,
                    mode: ManagementMode.MODIFY,
                    requestMasterList: masterList,
                  });
                  setOpenRequestModal(true);
                } else {
                  openMessageBar({
                    type: 'error',
                    content:
                      res.data && typeof res.data === 'string'
                        ? res.data
                        : t('ut.label.수정 요청할 수 없습니다.', '수정 요청할 수 없습니다.'),
                  });
                }
              });
            }}
            onClickDeleteBtn={async () => {
              let isSuccess = false;
              const selectedMasterRows = masterGridRef.current.getSelectedRow();
              if (selectedMasterRows.length < 1) {
                openMessageBar({
                  type: 'error',
                  content: t('ut.msg.삭제할 항목을 선택해주세요.', '삭제할 항목을 선택해주세요.'),
                });
                return;
              }

              const masterList = selectedMasterRows.map((o) => ({ ...o }));
              await checkAvailableRequest(ManagementMode.DELETE, masterList).then((res) => {
                if (res.successOrNot === 'Y' && res.data) {
                  isSuccess = true;
                } else {
                  openMessageBar({
                    type: 'error',
                    content:
                      res.data && typeof res.data === 'string'
                        ? res.data
                        : t('ut.msg.삭제 요청할 수 없습니다.', '삭제 요청할 수 없습니다.'),
                  });
                }
              });

              if (isSuccess) {
                setRequestModalCondition({
                  pageId: ApproveRequestPageType.UT_LIB_DELETE_REQ,
                  mode: ManagementMode.DELETE,
                  requestMasterList: masterList,
                });
                setOpenRequestModal(true);
              }
            }}
            onClickLibraryExcelDownloadBtn={() => downloadExcelTemplatesPost('UT_LIB_LIST', '', [])}
            onClickExcelDownloadBtn={() => {
              CommonUtil.exportWijmoExcelGrid(
                detailFlexRef,
                t('ut.label.라이브러리 List', '라이브러리 List'),
                t('ut.label.라이브러리 List', '라이브러리 List')
              );
            }}
          />
        </ContentSection>
      </ContentFlex>
      {isOpenStandardEquipmentModal && (
        <StandardEquipmentPopup
          open={isOpenStandardEquipmentModal}
          close={() => setOpenStandardEquipmentModaletOpenModal(false)}
          onCallback={(callbackData) => console.log('callbackData', callbackData)}
          singleSelect={false}
          showDialogButtons={false}
        />
      )}
      {isOpenRequestModal && (
        <ApproveRequestModal
          open={isOpenRequestModal}
          close={() => setOpenRequestModal(false)}
          pageId={requestModalCondition?.pageId}
          aprReqId={requestModalCondition?.aprReqId}
          condition={{
            mode: requestModalCondition?.mode,
            requestMasterList: requestModalCondition?.requestMasterList,
          }}
        />
      )}
    </ContainerLayout>
  );
};

export default UtMatrixLibraryPage;
