/** @jsxImportSource @emotion/react */
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import useEvent from 'react-use-event-hook';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import _, { camelCase } from 'lodash';
import { InputDate } from '@grapecity/wijmo.input';
import { Button } from '@mui/material';
import { ControlBtnGroup, SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import { addClass } from '@grapecity/wijmo';
import { DataMap, CellType } from '@grapecity/wijmo.grid';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { IconButton } from 'components/buttons/IconSVG';
import CustomGrid from 'components/grids/CustomGrid';
import ExcelValidModal from 'components/modals/common/ExcelValidModal';
import ExcelUploadButton from 'components/buttons/ExcelUploadButton';
import { getExcelFileName } from 'utils/ExcelUtil';
import { ComboBox } from 'components/selects/ComboBox';
import { downloadExcelTemplatesPost, uploadExcelTemplates } from 'apis/common/Excel';
import { CrudCode } from 'models/common/Edit';
import { useMessageBar } from 'hooks/useMessageBar';
import { GridStatusCellTemplate } from 'components/grids/GridStatusCellRenderer';
import { useLoading } from 'components/process/Loading';
import {
  FacilitySchedule,
  FacilityScheduleCondition,
  FacilityScheduleRegistMaster,
} from 'models/pjm/FacilityScheduleRegist';
import { getFacilityScheduleList, getProcessList } from 'apis/pjm/FacilityScheduleRegist';
import { getCommonCodeNames, getCommonCodeNamesCondition } from 'apis/admin/CommonCode';
import { Code } from 'models/common/CommonCode';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import DatepickerModal from 'components/modals/common/DatepickerModal';
import { getEquGroupParameter } from 'apis/pjm/SetupManager';

const ScheduleRegistGrid = (props: any, ref) => {
  const {
    masterData = {} as FacilityScheduleRegistMaster,
    condition = {} as FacilityScheduleCondition,
  } = props;

  const gridRef = useRef<any>();
  const { t } = useTranslation();
  const { openLoading } = useLoading();
  const { openMessageBar } = useMessageBar();
  const [code, setCode] = useState<any>();
  const [date, setDate] = useState<string>();
  const [selectedFactoryCode, setSelectedFactoryCode] = useState<string>('');
  const [selectedFactoryName, setSelectedFactoryName] = useState<string>('');
  const [rowData, setRowData] = useState<FacilitySchedule[]>([]);
  const [process, setProcess] = useState<FacilitySchedule[]>([]);
  const [excelValidCondition, setExcelValidCondition] = useState<string | null>(null);
  const [isExcelValidModalOpen, setExcelValidModalOpen] = useState<boolean>(false);
  const [isDatepickerModalOpen, setDatepickerModalOpen] = useState<boolean>(false);
  const [allApplyType, setAllApplyType] = useState<string>('');
  const [checkedItems, setCheckedItems] = useState<FacilitySchedule[]>([]);
  const dateEditor = useRef(
    new InputDate(document.createElement('div'), {
      format: 'yyyy.MM.dd',
      isRequired: false,
    })
  );

  useImperativeHandle(ref, () => ({
    getScheduleRowData: () => {
      return rowData;
    },
    validate: () => {
      const set = new Set();
      const isDuplicate = (rowData || []).some((item) => {
        return set.size === set.add(`${item.prdnPldoCd}${item.prdnProcCd}${item.eqclId}`).size;
      });
      if (isDuplicate) {
        openMessageBar({
          type: 'error',
          content: t('pjm.label.중복된 행이 존재합니다.', '중복된 행이 존재합니다.'),
        });
        return false;
      }
      return true;
    },
    refreshScheduleRowData: () => {
      handleSearch(condition);
    },
  }));

  const getCommonCodesForGrid = async () => {
    // const eqclId: Code[] = await getCommonCodeNames('EQCL_ID');
    const eqclId = await getEquGroupParameter();
    const prdnProcCd: Code[] = await getCommonCodeNames('PRDN_PROC_CD');
    const eltrTpCd: Code[] = await getCommonCodeNames('ELTR_TP_CD');
    const prjSpCd: Code[] = await getCommonCodeNamesCondition({
      optValCtn1: 'Y',
      cmnGrCd: 'PRJ_SP_CD',
    }); // 프로젝트 단계

    const factoryCode = await getCommonCodeNamesCondition({
      optValCtn1: masterData?.copCd,
      cmnGrCd: 'FACTORY_CODE',
    }); // 공정

    // 공정 기본 정보 목록 조회
    getProcessList(masterData.copCd).then((result) => setProcess(result));

    setCode({
      eqclId: eqclId,
      prdnProcCd: prdnProcCd,
      eltrTpCd: eltrTpCd,
      factoryCode: (factoryCode || []).reduce(
        (acc, cur) => [...acc, { ...cur, cmnCdNm: cur.cmnCd }],
        [] as Code[]
      ),
      prjSpCd: prjSpCd,
    });
  };

  useEffect(() => {
    const init = async () => {
      getCommonCodesForGrid();

      await handleSearch(condition);
    };

    if (masterData?.copCd) {
      init();
    }
  }, [masterData?.copCd]);

  useEffect(() => {
    const item = (code?.factoryCode || []).find((item) => item.cmnCd === selectedFactoryCode);
    if (item) {
      setSelectedFactoryName(item.cmnCdNm || '');
    }
  }, [selectedFactoryCode]);
  const handleSearch = async (condition) => {
    await getFacilityScheduleList(condition).then((result) => {
      setRowData(result);
      setCheckedItems([]);
    });
  };

  const layoutdefinition = useMemo(() => {
    const mapPrdnProcCd = new DataMap(code?.prdnProcCd || [], 'cmnCd', 'cmnCdNm');
    mapPrdnProcCd.getDisplayValues = (item) => {
      // 공정 기본정보에 포함이 된 항목만 노출
      const prdnProcCds = (process || []).filter((o) => !!o.prdnProcCd).map((o) => o.prdnProcCd);
      return (code?.prdnProcCd || [])
        .reduce((acc, cur) => {
          if (acc.findIndex((o) => o.cmnCd === cur.cmnCd) < 0 && prdnProcCds.includes(cur.cmnCd)) {
            acc.push(cur);
          }
          return acc;
        }, [])
        .filter((o) => !!o.cmnCdNm)
        .map((o) => o.cmnCdNm || '');
    };

    const mapEqclId = new DataMap(code?.eqclId || [], 'cmnCd', 'cmnCd');
    mapEqclId.getDisplayValues = (item) => {
      // 선택한 공정값에 따라 설비군 콤보박스 필터링
      const targets = (process || [])
        .filter((o) => o.prdnProcCd === item.prdnProcCd)
        .map((o) => o.eqclId || '');
      return (code?.eqclId || [])
        .filter((o) => !!o.cmnCd && (targets || []).includes(o.cmnCd))
        .reduce((acc, cur) => {
          if (acc.findIndex((o) => o.cmnCd === cur.cmnCd) < 0) {
            acc.push(cur);
          }
          return acc;
        }, [])
        .map((o) => o.cmnCd || '');
    };

    return [
      {
        header: String(t('com.label.상태', '상태')),
        binding: 'crudKey',
        width: 40,
        isReadOnly: true,
        align: 'center',
        cellTemplate: GridStatusCellTemplate,
      },
      {
        binding: 'prdnPldoCd',
        header: String(t('pjm.label.Factory', 'Factory')),
        isReadOnly: true,
        width: 90,
        align: 'center',
        cssClass: 'WijmoSelect',
        dataMap: new DataMap(code?.factoryCode || [], 'cmnCd', 'cmnCdNm'),
      },
      {
        binding: 'prdnProcCd',
        header: String(t('pjm.label.공정', '공정')),
        align: 'center',
        width: 90,
        dataMap: mapPrdnProcCd,
      },
      {
        binding: 'eqclId',
        header: String(t('pjm.label.설비군', '설비군')),
        align: 'center',
        width: 90,
        cssClass: 'WijmoSelect',
        dataMap: mapEqclId,
      },
      {
        header: String(t('pjm.label.Line', 'Line')),
        align: 'center',
        columns: [
          {
            binding: 'eltrTpCd',
            header: String(t('pjm.label.A/C', 'A/C')),
            width: 80,
            isRequired: false,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.eltrTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'eqpLnId',
            header: String(t('pjm.label.호기', '호기')),
            width: 80,
            isRequired: false,
            align: 'center',
          },
        ],
      },
      {
        binding: 'eqpId',
        header: String(t('pjm.label.Machine', 'Machine')),
        width: 90,
        align: 'left',
      },
      {
        binding: 'poIssuDt',
        header: String(t('pjm.label.PO발행', 'PO발행')),
        width: 120,
        align: 'left',
        cssClass: 'WijmoDate',
        editor: dateEditor.current,
        cellTemplate: (params) => (params.value ? dayjs(params.value).format('YYYY.MM.DD') : ''),
      },
      {
        binding: 'atMatDt',
        header: String(t('pjm.label.시운전자재', '시운전자재')),
        width: 120,
        align: 'left',
        cssClass: 'WijmoDate',
        editor: dateEditor.current,
        cellTemplate: (params) => (params.value ? dayjs(params.value).format('YYYY.MM.DD') : ''),
      },
      {
        binding: 'fatDt',
        header: String(t('pjm.label.FAT', 'FAT')),
        width: 120,
        align: 'left',
        cssClass: 'WijmoDate',
        editor: dateEditor.current,
        cellTemplate: (params) => (params.value ? dayjs(params.value).format('YYYY.MM.DD') : ''),
      },
      {
        binding: 'fobDt',
        header: String(t('pjm.label.FOB', 'FOB')),
        width: 120,
        align: 'left',
        cssClass: 'WijmoDate',
        editor: dateEditor.current,
        cellTemplate: (params) => (params.value ? dayjs(params.value).format('YYYY.MM.DD') : ''),
      },
      {
        binding: 'eqpCrryDt',
        header: String(t('pjm.label.설비반입', '설비반입')),
        width: 120,
        align: 'left',
        cssClass: 'WijmoDate',
        editor: dateEditor.current,
        cellTemplate: (params) => (params.value ? dayjs(params.value).format('YYYY.MM.DD') : ''),
      },
      {
        binding: 'istlDt',
        header: String(t('pjm.label.설치', '설치')),
        width: 120,
        align: 'left',
        cssClass: 'WijmoDate',
        editor: dateEditor.current,
        cellTemplate: (params) => (params.value ? dayjs(params.value).format('YYYY.MM.DD') : ''),
      },
      {
        binding: 'iocDt',
        header: String(t('pjm.label.I/O Check', 'I/O Check')),
        width: 120,
        align: 'left',
        cssClass: 'WijmoDate',
        editor: dateEditor.current,
        cellTemplate: (params) => (params.value ? dayjs(params.value).format('YYYY.MM.DD') : ''),
      },
      {
        binding: 'wetRunDt',
        header: String(t('pjm.label.Wet Run', 'Wet Run')),
        width: 120,
        align: 'left',
        cssClass: 'WijmoDate',
        editor: dateEditor.current,
        cellTemplate: (params) => (params.value ? dayjs(params.value).format('YYYY.MM.DD') : ''),
      },
      {
        binding: 'qcDt',
        header: String(t('pjm.label.Quality Check', 'Quality Check')),
        width: 120,
        align: 'left',
        cssClass: 'WijmoDate',
        editor: dateEditor.current,
        cellTemplate: (params) => (params.value ? dayjs(params.value).format('YYYY.MM.DD') : ''),
      },
      {
        binding: 'satDt',
        header: String(t('pjm.label.SAT', 'SAT')),
        width: 120,
        align: 'left',
        cssClass: 'WijmoDate',
        editor: dateEditor.current,
        cellTemplate: (params) => (params.value ? dayjs(params.value).format('YYYY.MM.DD') : ''),
      },
      {
        binding: 'smplPrdnDt',
        header: String(t('pjm.label.샘플생산', '샘플생산')),
        width: 120,
        align: 'left',
        cssClass: 'WijmoDate',
        editor: dateEditor.current,
        cellTemplate: (params) => (params.value ? dayjs(params.value).format('YYYY.MM.DD') : ''),
      },
      {
        binding: 'mspdDt',
        header: String(t('pjm.label.양산', '양산')),
        width: 120,
        align: 'left',
        cssClass: 'WijmoDate',
        editor: dateEditor.current,
        cellTemplate: (params) => (params.value ? dayjs(params.value).format('YYYY.MM.DD') : ''),
      },
      {
        header: String(t('pjm.label.대상설비ID', '대상설비ID')),
        binding: 'tgtEqpId',
        visible: false,
      },
      {
        header: String(t('pjm.label.계획공정ID', '계획공정ID')),
        binding: 'planProcId',
        visible: false,
      },
    ];
  }, [code, process]);

  const onItemFormatter = useEvent((panel, row, col, cell) => {
    if (CellType.ColumnHeader === panel.cellType) {
      const binding = panel.columns[col].binding;
      // 필수항목
      if (!['eltrTpCd'].includes(binding)) {
        addClass(cell, 'dot');
      }
    }
  });

  const onInitialized = (grid) => {
    gridRef.current = grid;
    grid.itemFormatter = onItemFormatter;
  };

  const btnExcelExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(gridRef.current, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = String(t('pjm.label.일정등록', '일정등록'));
    book.saveAsync(getExcelFileName(String(t('pjm.label.일정등록', '일정등록'))));
  };

  const btnAddRow = () => {
    if (_.isEmpty(selectedFactoryCode)) {
      openMessageBar({
        type: 'error',
        content: t('pjm.label.Factory 항목을 선택해주세요.', 'Factory 항목을 선택해주세요.'),
      });
      return;
    }

    const newRow = {
      crudKey: CrudCode.CREATE,
      prdnPldoCd: selectedFactoryCode,
      prdnPldoCdNm: selectedFactoryName,
    } as FacilitySchedule;
    setRowData([newRow, ...rowData]);
  };

  const btnAllRegistProcess = () => {
    if (_.isEmpty(selectedFactoryCode)) {
      openMessageBar({
        type: 'error',
        content: t('pjm.label.Factory 항목을 선택해주세요.', 'Factory 항목을 선택해주세요.'),
      });
      return;
    }

    setRowData((prev) => {
      // [25.02.11] 이미 등록된 공정, 설비군인 경우에만 제외 (공장, 공정, 설비군, 라인까지는 중복 가능하기때문에 중복제거 방식 변경)
      const newRows = (process || []).reduce((acc, cur) => {
        if (
          (prev || []).findIndex(
            ({ prdnPldoCd, prdnProcCd, eqclId }) =>
              prdnPldoCd === selectedFactoryCode &&
              prdnProcCd === cur.prdnProcCd &&
              eqclId === cur.eqclId
          ) === -1
        ) {
          acc.push({
            ...cur,
            crudKey: CrudCode.CREATE,
            prdnPldoCd: selectedFactoryCode,
            prdnPldoCdNm: selectedFactoryName,
          });
        }
        return acc;
      }, [] as FacilitySchedule[]);
      return [...prev, ...newRows];
    });
  };

  const btnAllRegist = () => {
    if ((checkedItems || []).length < 1) {
      openMessageBar({
        type: 'error',
        content: t('pjm.label.적용할 행을 선택해 주세요.', '적용할 행을 선택해 주세요.'),
      });
      return;
    } else if (!allApplyType) {
      openMessageBar({
        type: 'error',
        content: String(t('pjm.label.단계를 선택해 주세요.', '단계를 선택해 주세요.')),
      });
      return;
    } else if (_.isEmpty(date)) {
      openMessageBar({
        type: 'error',
        content: t('pjm.label.날짜를 선택해 주세요.', '날짜를 선택해 주세요.'),
      });
      return;
    }

    let msg = '';
    const curDate = new Date(date || '');
    (checkedItems || []).some((item, idx) => {
      if (allApplyType === 'AT_MAT') {
        const prevDate = new Date(checkedItems[idx].poIssuDt || '');

        if (!checkedItems[idx].poIssuDt) {
          msg = `PO발행 날짜를 입력해주세요.`;
          return true;
        } else if (prevDate >= curDate) {
          msg = `${checkedItems[idx].poIssuDt} 이후의 날짜를 입력해주세요.`;
          return true;
        }
      } else if (allApplyType === 'FAT') {
        const prevDate = new Date(checkedItems[idx].atMatDt || '');

        if (!checkedItems[idx].atMatDt) {
          msg = `시운전자재 날짜를 입력해주세요.`;
          return true;
        } else if (prevDate >= curDate) {
          msg = `${checkedItems[idx].atMatDt} 이후의 날짜를 입력해주세요.`;
          return true;
        }
      } else if (allApplyType === 'FOB') {
        const prevDate = new Date(checkedItems[idx].fatDt || '');

        if (!checkedItems[idx].fatDt) {
          msg = `FAT 날짜를 입력해주세요.`;
          return true;
        } else if (prevDate >= curDate) {
          msg = `${checkedItems[idx].fatDt} 이후의 날짜를 입력해주세요.`;
          return true;
        }
      } else if (allApplyType === 'EQP_CRRY') {
        const prevDate = new Date(checkedItems[idx].fobDt || '');

        if (!checkedItems[idx].fobDt) {
          msg = `FOB 날짜를 입력해주세요.`;
          return true;
        } else if (prevDate >= curDate) {
          msg = `${checkedItems[idx].fobDt} 이후의 날짜를 입력해주세요.`;
          return true;
        }
      } else if (allApplyType === 'ISTL') {
        const prevDate = new Date(checkedItems[idx].eqpCrryDt || '');

        if (!checkedItems[idx].eqpCrryDt) {
          msg = `설비반입 날짜를 입력해주세요.`;
          return true;
        } else if (prevDate >= curDate) {
          msg = `${checkedItems[idx].eqpCrryDt} 이후의 날짜를 입력해주세요.`;
          return true;
        }
      } else if (allApplyType === 'IOC') {
        const prevDate = new Date(checkedItems[idx].istlDt || '');

        if (!checkedItems[idx].istlDt) {
          msg = `설치 날짜를 입력해주세요.`;
          return true;
        } else if (prevDate >= curDate) {
          msg = `${checkedItems[idx].istlDt} 이후의 날짜를 입력해주세요.`;
          return true;
        }
      } else if (allApplyType === 'WET_RUN') {
        const prevDate = new Date(checkedItems[idx].iocDt || '');

        if (!checkedItems[idx].iocDt) {
          msg = `I/O Check 날짜를 입력해주세요.`;
          return true;
        } else if (prevDate >= curDate) {
          msg = `${checkedItems[idx].iocDt} 이후의 날짜를 입력해주세요.`;
          return true;
        }
      } else if (allApplyType === 'QC') {
        const prevDate = new Date(checkedItems[idx].wetRunDt || '');

        if (!checkedItems[idx].wetRunDt) {
          msg = `Wet Run 날짜를 입력해주세요.`;
          return true;
        } else if (prevDate >= curDate) {
          msg = `${checkedItems[idx].wetRunDt} 이후의 날짜를 입력해주세요.`;
          return true;
        }
      } else if (allApplyType === 'SAT') {
        const prevDate = new Date(checkedItems[idx].qcDt || '');

        if (!checkedItems[idx].qcDt) {
          msg = `Quality Check 날짜를 입력해주세요.`;
          return true;
        } else if (prevDate >= curDate) {
          msg = `${checkedItems[idx].qcDt} 이후의 날짜를 입력해주세요.`;
          return true;
        }
      } else if (allApplyType === 'SMPL_PRDN') {
        const prevDate = new Date(checkedItems[idx].satDt || '');

        if (!checkedItems[idx].satDt) {
          msg = `SAT 날짜를 입력해주세요.`;
          return true;
        } else if (prevDate >= curDate) {
          msg = `${checkedItems[idx].satDt} 이후의 날짜를 입력해주세요.`;
          return true;
        }
      } else if (allApplyType === 'MSPD') {
        const prevDate = new Date(checkedItems[idx].smplPrdnDt || '');

        if (!checkedItems[idx].smplPrdnDt) {
          msg = `샘플생산 날짜를 입력해주세요.`;
          return true;
        } else if (prevDate >= curDate) {
          msg = `${checkedItems[idx].smplPrdnDt} 이후의 날짜를 입력해주세요.`;
          return true;
        }
      }
    });

    if (!_.isEmpty(msg)) {
      openMessageBar({
        type: 'error',
        content: msg,
      });
      return;
    }

    const id = `${camelCase(allApplyType)}Dt`;
    (checkedItems || []).forEach((o) => {
      o[id] = date;
      if (o.crudKey !== CrudCode.CREATE) {
        o.crudKey = CrudCode.UPDATE;
      }
    });
    gridRef.current?.collectionView.refresh();
  };

  const btnDelRow = () => {
    const selectedRows = gridRef.current.rows.filter((r) => r.isSelected);

    if ((selectedRows || []).length < 1) {
      openMessageBar({
        type: 'error',
        content: t('com.label.삭제할 데이터를 선택해 주세요.', '삭제할 데이터를 선택해 주세요.'),
      });
      return;
    }

    const selectedIds = selectedRows
      .map((row) => {
        return parseInt(row.index!);
      })
      .reverse();

    selectedIds.forEach((item) => {
      if (rowData[item].crudKey === CrudCode.CREATE) {
        // 각 선택된 아이템에 대해 삭제 로직 수행
        delete rowData[item];
      } else {
        rowData[item].crudKey = CrudCode.DELETE;
      }
    });

    const filteredData = rowData.filter((element) => element !== undefined);
    setRowData(filteredData);
    gridRef.current?.collectionView.refresh();
  };

  const downloadExcel = async (templateType) => {
    openLoading(true);
    downloadExcelTemplatesPost(templateType, condition.elmPrjId, [], condition.copCd).finally(() =>
      openLoading(false)
    );
  };

  const btnCopyRow = useCallback(() => {
    const selectedRows = gridRef.current.rows.filter((r) => r.isSelected);
    if (!selectedRows || selectedRows.length === 0) {
      openMessageBar({
        type: 'error',
        content: t('com.label.복사할 행을 선택해 주세요.', '복사할 행을 선택해 주세요.'),
      });
      return;
    }

    const copyRows = [] as FacilitySchedule[];
    selectedRows.forEach((o) => {
      const item = o.dataItem;
      copyRows.push({
        crudKey: CrudCode.CREATE,
        planProcId: item.planProcId,
        prdnPldoCd: item.prdnPldoCd,
        prdnPldoCdNm: item.prdnPldoCdNm,
        prdnProcCd: item.prdnProcCd,
        prdnProcCdNm: item.prdnProcCdNm,
        eqclId: item.eqclId,
        eltrTpCd: item.eltrTpCd,
        eqpLnId: item.eqpLnId,
      });
    });
    setRowData([...copyRows, ...rowData]);
  }, [rowData]);

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('pjm.label.일정등록', '일정등록')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{(rowData || []).length}</span>
            {t('com.label.건', '건')}
          </span>
          {condition?.utmNo && <div className="info warning">{condition?.utmNo}</div>}

          <ControlBtnGroup>
            <SubTitleGroup>
              <span className="total">{t('pjm.label.Factory', 'Factory')}&nbsp;</span>
            </SubTitleGroup>
            <ComboBox
              options={code?.factoryCode || []}
              placeholder={String(t('com.label.선택', '선택'))}
              defaultValue={selectedFactoryCode}
              style={{
                width: '80px',
              }}
              onChange={(value) => {
                setSelectedFactoryCode(value);
              }}
            />
            <Button
              css={IconButton.button}
              className="setting"
              onClick={btnAllRegistProcess}
              disableRipple
            >
              {t('com.button.설비군 가져오기', '설비군 가져오기')}
            </Button>
          </ControlBtnGroup>
        </SubTitleGroup>
        <ControlBtnGroup>
          <SubTitleGroup>
            <span className="total">{t('pjm.label.단계', '단계')}&nbsp;</span>
          </SubTitleGroup>
          <ComboBox
            placeholder={String(t('com.label.선택', '선택'))}
            options={code?.prjSpCd || []}
            defaultValue={allApplyType}
            style={{
              width: '120px',
            }}
            onChange={(value) => setAllApplyType(value)}
          />
          <CustomInputWithSearch
            name="eqpMchNm"
            value={date || ''}
            placeholder={String(t('pjm.label.날짜를 선택해 주세요.', '날짜를 선택해 주세요.'))}
            style={{
              width: '110px',
            }}
            onClick={(e) => setDatepickerModalOpen(true)}
          />

          <Button css={IconButton.button} className="setting" onClick={btnAllRegist} disableRipple>
            {t('com.button.일괄적용', '일괄적용')}
          </Button>
          <ExcelUploadButton
            msgConfirm={
              t(
                'com.label.저장된 데이터가 있으면 기존 데이터는 삭제됩니다.\n 새로 Upload 하시겠습니까?',
                '저장된 데이터가 있으면 기존 데이터는 삭제됩니다.\n 새로 Upload 하시겠습니까?'
              ) || ''
            }
            onCallback={(file) => {
              openLoading(true);
              uploadExcelTemplates(
                file,
                'TPL_SETUP_SCHD_LIST',
                'NORMAL',
                'TB_EELMB_PRJ_TGT_SP_SCHD_M',
                condition.elmPrjId
              )
                .then((response) => {
                  if (response?.data.x_result == 'OK') {
                    openMessageBar({
                      type: 'confirm',
                      content: t('pjm.label.검증이 완료되었습니다.', '검증이 완료되었습니다.'),
                    });
                    handleSearch(condition);
                  } else {
                    setExcelValidCondition(response?.data.p_xls_upload_id);
                    setExcelValidModalOpen(true);
                  }
                })
                .finally(() => {
                  openLoading(false);
                });
            }}
          />
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={() => downloadExcel('TPL_SETUP_SCHD_LIST')}
            disableRipple
          >
            {t('com.button.템플릿 다운로드', '템플릿 다운로드')}
          </Button>
          <Button css={IconButton.button} className="addRow" onClick={btnAddRow} disableRipple>
            {t('com.button.행추가', '행추가')}
          </Button>
          <Button css={IconButton.button} className="copyRow" onClick={btnCopyRow} disableRipple>
            {t('com.button.행복사', '행복사')}
          </Button>
          <Button css={IconButton.button} className="delRow" onClick={btnDelRow} disableRipple>
            {t('com.button.행삭제', '행삭제')}
          </Button>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={btnExcelExport}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
          <Button
            css={IconButton.button}
            className="refresh"
            onClick={() => handleSearch(condition)}
            disableRipple
          >
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutdefinition}
        rowData={rowData}
        height={400}
        frozenColumns={7}
        isFilter={false}
        allowPinning={false}
        initialized={onInitialized}
        onChangeCheckedItem={(items) => setCheckedItems(items)}
      />
      {isExcelValidModalOpen && (
        <ExcelValidModal
          open={isExcelValidModalOpen}
          close={() => {
            setExcelValidCondition(null);
            setExcelValidModalOpen(false);
          }}
          condition={{
            valReqId: excelValidCondition || '',
          }}
        />
      )}
      {isDatepickerModalOpen && (
        <DatepickerModal
          open={isDatepickerModalOpen}
          close={() => {
            //setDatepickerModalOpen(null);
            setDatepickerModalOpen(false);
          }}
          onCallback={(result) => {
            setDate(result);
          }}
          condition={{
            // valReqId: excelValidCondition || '',
            date: date,
          }}
        />
      )}
    </>
  );
};

export default React.forwardRef(ScheduleRegistGrid);
