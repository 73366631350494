/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useEffect, useMemo } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Button } from '@mui/material';
import { SelectionMode } from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { ContainerLayout } from 'components/layouts/ContainerLayout';
import {
  ContentLayout,
  SubTitleLayout,
  SubTitleGroup,
  ControlBtnGroup,
  GlobalBtnGroup,
} from 'components/layouts/ContentLayout';
import {
  SearchBox,
  SearchBoxRow,
  InputBox,
  SearchRows,
  SearchCols,
  SearchButtonWrap,
} from 'components/layouts/SearchBox';
import { IconButton } from 'components/buttons/IconSVG';
import { useTranslation } from 'react-i18next';
import { getCommonCodeNames, getCommonCodeNamesCondition } from 'apis/admin/CommonCode';
import { ComboBox, MultiComboBox } from 'components/selects/ComboBox';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'hooks/useMessageBar';
import CustomGrid from 'components/grids/CustomGrid';
import { getExcelFileName } from 'utils/ExcelUtil';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { useNavigate } from 'react-router-dom';
import { Code } from 'models/common/CommonCode';
import useSessionStore from 'stores/useSessionStore';
import { hasRole } from 'utils/SessionUtil';
import { CrudCode } from 'models/common/Edit';
import { PopupSearchParam, SearchParam, UtMatrixHookupTag } from 'models/ut/UtMatrixHookupTag';
import { getUtMatrixHookupTagList } from 'apis/ut/UtMatrixHookupTag';
import CustomDialog from 'components/modals/common/CustomDialog';

interface Props {
  open: boolean;
  close: () => void;
  condition: any;
}

const UtMatrixHookupTagPopup = ({ open, close, condition, ...props }: Props) => {
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();

  const gridRef = useRef<any>();
  const [flexRef, setFlexRef] = useState<any>();

  const [rowData, setRowData] = useState<UtMatrixHookupTag[]>([]);

  const [prdnProcCode, setPrdnProcCode] = useState<Code[]>([]);
  const [eqclIdCode, setEqclIdCode] = useState<Code[]>([]);
  const [dtalProcCode, setDtalProcCode] = useState<Code[]>([]);

  // const initSearchParam = {
  //   utmNo: '',
  //   utmNm: '',
  //   prdnProcCds: [],
  //   eqclIds: [],
  //   dtalProcCds: [],
  //   eqpMchId: '',
  // };

  const [searchParam, setSearchParam] = useState<PopupSearchParam>({
    utmId: condition?.utmId || '',
    planProcId: condition?.planProcId || '',
    utmSeq: condition?.utmSeq || '',
    tagDivsCd: condition?.tagDivsCd || '',
  });

  useEffect(() => {
    initCondition();
    btnSearch(searchParam);
  }, []);

  const initCondition = async () => {
    const prdnProcCd = await getCommonCodeNamesCondition({
      optValCtn1: 'Y',
      cmnGrCd: 'PRDN_PROC_CD',
    });
    const eqclIdCd = await getCommonCodeNamesCondition({
      optValCtn1: 'Y',
      cmnGrCd: 'EQCL_ID',
    });
    const dtalProcCd = await getCommonCodeNames('DTAL_PROC_CD');
    setPrdnProcCode(prdnProcCd);
    setEqclIdCode(eqclIdCd);
    setDtalProcCode(dtalProcCd);
  };

  const layoutDefinition = useMemo(() => {
    return [
      {
        binding: 'no',
        header: String(t('com.label.NO', 'NO')),
        width: 40,
        align: 'center',
        cellTemplate: (grid) => grid.row._idx + 1,
      },
      {
        header: String(t('ut.label.Hookup Tagging', 'Hookup Tagging')),
        columns: [
          {
            binding: 'tagDivsCdNm',
            header: String(t('ut.label.기계/전기', '기계/전기')),
            width: 120,
            align: 'center',
          },
          {
            binding: 'tagId',
            header: String(t('ut.label.TAG ID', 'TAG ID')),
            width: 250,
          },
        ],
      },
      {
        binding: 'prdnProcCdNm',
        header: String(t('ut.label.공정', '공정')),
        width: 120,
        align: 'center',
      },
      {
        binding: 'eqclIdNm',
        header: String(t('ut.label.설비군', '설비군')),
        width: 120,
        align: 'center',
      },
      {
        binding: 'dtalProcCdNm',
        header: String(t('ut.label.세부공정', '세부공정')),
        width: 120,
        align: 'center',
      },
      {
        binding: 'eqpMainNm',
        header: String(t('ut.label.Main', 'Main')),
        width: 120,
      },
      {
        binding: 'eqpMchNm',
        header: String(t('ut.label.Machine', 'Machine')),
        width: 150,
      },
      {
        binding: 'stndEqpId',
        header: String(t('ut.label.표준설비코드', '표준설비코드')),
        width: 180,
        align: 'center',
      },
      {
        binding: 'bizAreaNm',
        header: String(t('ut.label.Area', 'Area')),
        width: 80,
        align: 'center',
      },
      {
        binding: 'bldgFloCdNm',
        header: String(t('ut.label.Floor', 'Floor')),
        width: 80,
        align: 'center',
      },
      {
        binding: 'prdnLnNm',
        header: String(t('ut.label.Line', 'Line')),
        width: 80,
        align: 'center',
      },
      {
        binding: 'prdnPrlLnNm',
        header: String(t('ut.label.Sub Line', 'Sub Line')),
        width: 90,
        align: 'center',
      },
      {
        header: String(t('ut.label.수량', '수량')),
        columns: [
          {
            binding: 'machEqpQty',
            header: String(t('ut.label.기계', '기계')),
            width: 80,
            align: 'center',
          },
          {
            binding: 'elpwEqpQty',
            header: String(t('ut.label.전기', '전기')),
            width: 80,
            align: 'center',
          },
        ],
      },
      {
        binding: 'vltgNvl',
        header: String(t('ut.label.Voltage', 'Voltage')),
        width: 100,
        align: 'center',
      },
      {
        binding: 'elpwPhasCd',
        header: String(t('ut.label.Phase', 'Phase')),
        width: 100,
        align: 'center',
      },
      {
        binding: 'ctwTpCd',
        header: String(t('ut.label.Wire', 'Wire')),
        width: 100,
        align: 'center',
      },
      {
        binding: 'od1EcNvl',
        header: String(t('ut.label.Current(입력값)', 'Current(입력값)')),
        width: 140,
        align: 'center',
      },
      {
        binding: 'brkeCapa',
        header: String(t('ut.label.Breaker(MCCB)', 'Breaker(MCCB)')),
        width: 140,
        align: 'center',
      },
      {
        binding: 'elpwCapa',
        header: String(t('ut.label.Electricity Capacity', 'Electricity Capacity')),
        width: 150,
        align: 'center',
      },
      {
        binding: 'cdaDevcCnctTpCd',
        header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
        width: 140,
        align: 'center',
      },
      {
        binding: 'cdaPntCnt',
        header: String(t('ut.label.CDA(Qty)', 'CDA(Qty)')),
        width: 100,
        align: 'center',
      },
      {
        binding: 'nitrPntCtn',
        header: String(t('ut.label.N2(Qty)', 'N2(Qty)')),
        width: 100,
        align: 'center',
      },
      {
        binding: 'iwPntCnt',
        header: String(t('ut.label.IW(Qty)', 'IW(Qty)')),
        width: 100,
        align: 'center',
      },
      {
        binding: 'frwtPntCnt',
        header: String(t('ut.label.FW(Qty)', 'FW(Qty)')),
        width: 100,
        align: 'center',
      },
      {
        binding: 'coolPntCnt',
        header: String(t('ut.label.RCW(Qty)', 'RCW(Qty)')),
        width: 100,
        align: 'center',
      },
      {
        binding: 'wwPntCnt',
        header: String(t('ut.label.WW(Qty)', 'WW(Qty)')),
        width: 100,
        align: 'center',
      },
      {
        binding: 'stemPntCnt',
        header: String(t('ut.label.Steam(Qty)', 'Steam(Qty)')),
        width: 100,
        align: 'center',
      },
      {
        binding: 'lngPntCnt',
        header: String(t('ut.label.NG(Qty)', 'NG(Qty)')),
        width: 100,
        align: 'center',
      },
      {
        binding: 'insdExhaPntCnt',
        header: String(t('ut.label.Retrun Air(Qty)', 'Retrun Air(Qty)')),
        width: 140,
        align: 'center',
      },
      {
        binding: 'otsdExhaPntCnt',
        header: String(t('ut.label.Exhaust Air(Qty)', 'Exhaust Air(Qty)')),
        width: 140,
        align: 'center',
      },
      {
        binding: 'suarPntCnt',
        header: String(t('ut.label.Supply Air(Qty)', 'Supply air(Qty)')),
        width: 140,
        align: 'center',
      },
      {
        binding: 'dataInsUserNm',
        header: String(t('ut.label.등록자', '등록자')),
        width: 100,
        align: 'center',
      },
      {
        binding: 'dataInsDtm',
        header: String(t('ut.label.등록일', '등록일')),
        width: 100,
        align: 'center',
      },
    ];
  }, [rowData]);

  const onInitialized = (grid) => {
    new Selector(grid);
    setFlexRef(grid);
    gridRef.current = grid;
  };

  // const handleOnChange = (name, value) => {
  //   setSearchParam({ ...searchParam, [name]: value });
  // };

  // const onKeyDown = (e) => {
  //   if (e.key === 'Enter') {
  //     btnSearch(searchParam);
  //   }
  // };

  const btnExcelExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = 'Hookup tagging list';
    book.saveAsync(getExcelFileName(t('ut.label.Hookup Tagging List', 'Hookup Tagging List')));
  };

  const btnSearch = (searchParam) => {
    getUtMatrixHookupTagList(searchParam).then((result: UtMatrixHookupTag[]) => {
      if (result !== null) {
        setRowData(result);
      } else {
        openMessageBar({
          type: 'error',
          content: t(
            'com.msg.요청 정보 조회 중 오류가 발생했습니다.',
            '요청 정보 조회 중 오류가 발생했습니다.'
          ),
        });
      }
    });
  };

  // const btnReload = () => {
  //   setSearchParam(
  //     (prev) =>
  //       Object.keys(prev).reduce(
  //         (acc, cur) => Object.assign(acc, { [cur]: initSearchParam[cur] || '' }),
  //         {}
  //       ) as SearchParam
  //   );
  // };

  return (
    <CustomDialog
      title={t('ut.label.Hookup Tagging List', 'Hookup Tagging List')}
      open={open}
      onClose={close}
      size={'xl'}
    >
      {/* <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows className="FourCol">
              <SearchCols>
                <label>{String(t('ut.label.공정', '공정'))}</label>
                <MultiComboBox
                  id="prdnProcCd"
                  options={prdnProcCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.prdnProcCds || []}
                  onChange={(value) => {
                    handleOnChange('prdnProcCd', value.join());
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{String(t('ut.label.설비군', '설비군'))}</label>
                <MultiComboBox
                  id="eqpGrcd"
                  options={eqclIdCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.eqclIds || []}
                  onChange={(value) => {
                    handleOnChange('eqclId', value.join());
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{String(t('ut.label.세부공정', '세부공정'))}</label>
                <MultiComboBox
                  id="dtalProcCode"
                  options={dtalProcCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.dtalProcCds || []}
                  onChange={(value) => {
                    handleOnChange('dtalProcCd', value.join());
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('com.label.Machine', 'Machine')}</label>
                <CustomInputWithSearch
                  name="eqpMchId"
                  value={searchParam.eqpMchId || ''}
                  defaultValue={searchParam.eqpMchId || ''}
                  placeholder={String(
                    t('com.label.Machine을 입력해 주세요.', 'Machine을 입력해 주세요.')
                  )}
                  onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                  onKeyDown={onKeyDown}
                />
              </SearchCols>
            </SearchRows>
            <SearchRows className="FourColThree">
              <SearchCols>
                <label>{t('com.label.UT Matrix 번호', 'UT Matrix 번호')}</label>
                <CustomInputWithSearch
                  name="utmNo"
                  value={searchParam.utmNo || ''}
                  defaultValue={searchParam.utmNo || ''}
                  placeholder={String(
                    t(
                      'com.label.UT Matrix 번호를 입력해 주세요.',
                      'UT Matrix 번호를 입력해 주세요.'
                    )
                  )}
                  onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                  onKeyDown={onKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('com.label.UT Matrinx 명', 'UT Matrinx 명')}</label>
                <CustomInputWithSearch
                  name="utmNm"
                  value={searchParam.utmNm || ''}
                  defaultValue={searchParam.utmNm || ''}
                  placeholder={String(
                    t('com.label.UT Matrinx 명을 입력해 주세요.', 'UT Matrinx 명을 입력해 주세요.')
                  )}
                  onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                  onKeyDown={onKeyDown}
                />
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchButtonWrap>
            <Button
              css={IconButton.button}
              className="reload"
              onClick={btnReload}
              disableRipple
            ></Button>
            <Button css={IconButton.button} className="find" onClick={btnSearch} disableRipple>
              {t('com.button.조회', '조회')}
            </Button>
          </SearchButtonWrap>
        </SearchBoxRow>
      </SearchBox> */}
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('ut.label.Hookup Tagging List', 'Hookup Tagging List')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{rowData?.length ?? 0}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={btnExcelExport}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
          <Button css={IconButton.button} className="refresh" onClick={btnSearch} disableRipple>
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        initialized={onInitialized}
        height={400}
        excludeFilter={[]}
        excludePin={[]}
        autoCheck={true}
        isReadOnly={true}
        selectionMode={SelectionMode.Row}
      />
    </CustomDialog>
  );
};

export default UtMatrixHookupTagPopup;
