import React, { useState, useEffect, useRef, useMemo, InputHTMLAttributes } from 'react';
import { Button } from '@mui/material';
import { AllInputWrap, CustomInputText, CustomInputWrap } from './CustomInput';

export enum MsgType {
  ERROR = 'error',
  WARNING = 'warning',
  CONFIRM = 'comfirm',
}

interface PropsType extends InputHTMLAttributes<HTMLInputElement> {
  isWrap?: boolean; // <AllInputWrap> 추가 여부 (default: true)
  isError?: boolean; // 오류여부 (추후 삭제 예정 - msgType으로 변경)
  msgError?: string; // 오류문구
  msgWarning?: string; // 경고문구
  msgConfirm?: string; // 확인문구
  msgHelp?: string; // 도움말문구
  msgType?: MsgType; // 노출메세지유형
  onSearchClick?: (e) => void; // 검색버튼 클릭 이벤트
  // className에 find가 포함되는 경우 검색버튼 노출
  // 클릭 이벤트가 정의되지 않은 경우 검색버튼이 disabled 처리됨
  style?: {
    width?: string;
  }; // <AllInputWrap>에 추가되는 스타일
  // 그 외 속성은 InputHTMLAttributes 그대로 사용
}
const CustomInputWithSearch = (props: PropsType) => {
  const {
    isWrap = true,
    isError = false,
    msgError,
    msgHelp,
    msgWarning,
    msgConfirm,
    msgType,
    style,
    onSearchClick,
    ...inputProps
  } = props;
  const searchRef = useRef<HTMLDivElement>(null);
  const [isFocus, setFocus] = useState(false);
  const showError = useMemo(() => {
    if (isError) {
      return !isFocus && msgError;
    }
    return false;
  }, [msgError, isError, isFocus]);

  const wrapClass = useMemo(() => {
    if (msgType) {
      if (msgType === MsgType.CONFIRM || !isFocus) {
        return msgType.toString();
      }
    }
    // 도움말이 있는 경우
    if (msgHelp) {
      return 'help';
    }
    return '';
  }, [msgType, msgHelp, isFocus]);

  const msg = useMemo(() => {
    if (msgType && !isFocus) {
      if (msgType === MsgType.ERROR && msgError) {
        return msgError;
      } else if (msgType === MsgType.WARNING && msgWarning) {
        return msgWarning;
      } else if (msgType === MsgType.CONFIRM && msgConfirm) {
        return msgConfirm;
      }
    }
    // 도움말이 있는 경우
    if (msgHelp) {
      return msgHelp;
    }
    return '';
  }, [msgType, msgHelp, isFocus]);

  useEffect(() => {
    document.addEventListener('click', handleFocusCheck);
    return () => {
      document.removeEventListener('click', handleFocusCheck);
    };
  }, []);

  /*
  const handleDelete = () => {
    const setValue = Object.getOwnPropertyDescriptor(
      Object.getPrototypeOf(inputRef.current),
      'value'
    )?.set;
    const event = new Event('input', { bubbles: true });
    setValue?.call(inputRef.current, '');
    inputRef.current?.dispatchEvent(event);
  };
  */

  const handleFocusCheck = (e) => {
    if (document.activeElement !== searchRef.current && !searchRef.current?.contains(e.target)) {
      setFocus(false);
    }
  };

  return isWrap ? (
    <AllInputWrap ref={searchRef} className={showError ? 'error' : wrapClass} style={style}>
      {/* error, warning, comfirm */}
      <CustomInput setFocus={setFocus} {...props} />
      {showError ? <span>{msgError}</span> : msg && <span>{msg}</span>}
    </AllInputWrap>
  ) : (
    <CustomInput setFocus={setFocus} {...props} />
  );
};

const CustomInput = (props: any) => {
  const { setFocus, onSearchClick, ...inputProps } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <CustomInputWrap>
      <CustomInputText
        ref={inputRef}
        {...inputProps}
        defaultValue={inputProps?.value || ''}
        onFocus={() => setFocus(true)}
      />
      {/* inputProps?.value && isFocus && !inputProps.readOnly && (
          <Button className="buttonDel" onClick={handleDelete} disableRipple></Button>
        ) */}
      {(inputProps.className || '').includes('find') && (
        <Button
          className="buttonFind"
          onClick={onSearchClick}
          disabled={!onSearchClick} // 클릭이벤트가 정의되지 않은 경우 disabled 처리
          disableRipple
        ></Button>
      )}
    </CustomInputWrap>
  );
};
export default CustomInputWithSearch;
