/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import useEvent from 'react-use-event-hook';
import { addClass } from '@grapecity/wijmo';
import { CellType } from '@grapecity/wijmo.grid';
import { SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import CustomGrid from 'components/grids/CustomGrid';
import { findAssetReviewStatus } from 'apis/asst/IdleAssetReview';
import { IdleAssetReview } from 'models/asst/IdleAssetReview';
import { IdleAssetDetail } from 'models/asst/IdleAsset';
import _ from 'lodash';

interface Prop {
  condition?: any;
}

const IdleAssetStatusGrid = (props: Prop, ref) => {
  const { t } = useTranslation();
  const gridRef = useRef<any>();
  const [condition, setCondition] = useState<IdleAssetDetail>();
  const [rowData, setRowData] = useState<IdleAssetReview[]>([]);
  const [subTitle, setSubTitle] = useState<string>('');

  useImperativeHandle(ref, () => ({
    reset: () => {
      setCondition(undefined);
      setRowData([]);
    },
    search: (params) => {
      setCondition(params);
      handleSearch(params);
    },
    refresh: () => {
      if (condition) {
        handleSearch(condition);
      }
    },
  }));

  const handleSearch = (params) => {
    if (_.isNull(params) || params.eqpId == undefined) return;
    const data = [params.eqpId, params.eqpNm];
    setSubTitle(`${t('asst.label.설비 정보', '설비 정보')} ${data.filter((o) => !!o).join(', ')}`);
    findAssetReviewStatus(params.rvwReqId, params.asstTpCd, params.eqpId).then((result) => {
      setRowData(result);
    });
  };

  const onItemFormatter = useEvent((panel, row, col, cell) => {
    if (CellType.RowHeader === panel.cellType) {
      addClass(cell, 'transposed-header');
    }
  });

  const getRvwRltDivsCdTag = (rvwRltDivsCd) => {
    if ('PSBL' === rvwRltDivsCd) {
      return `<span class="green">${t('asst.label.활용가능', '활용가능')}</span>`;
    }
    if ('IMPB' === rvwRltDivsCd) {
      return `<span class="red">${t('asst.label.활용불가', '활용불가')}</span>`;
    }
    return `<span class="yellow">${t('asst.label.검토중', '검토중')}</span>`;
  };

  const onInitialized = (grid) => {
    gridRef.current = grid;
    grid.itemFormatter = onItemFormatter;
    grid.formatItem.addHandler((s, e) => {
      if (e.panel == s.cells) {
        const binding = s.rows[e.row].binding;
        if ('rvwRltDivsCd' === binding) {
          const value = s.getCellData(e.row, e.col, false);
          e.cell.innerHTML = getRvwRltDivsCdTag(value);
        }
      }
    });
  };

  const layoutDefinition = [
    {
      binding: 'tgtCopCd',
      header: String(t('asst.label.법인', '법인')),
      align: 'center',
      minWidth: 100,
      width: '*',
    },
    {
      binding: 'rvwRltDivsCd',
      header: String(t('asst.label.검토결과', '검토결과')),
      align: 'center',
      minWidth: 100,
      width: '*',
      cssClass: 'WijmoTag',
    },
    {
      binding: 'progressRate',
      header: String(t('asst.label.진행률', '진행률')),
      align: 'center',
      minWidth: 200,
      width: '*',
    },
  ];

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>
            {t(
              'asst.label.법인별 진행 현황',
              '불용자산 요청 List > 불용자산 설비상세 > 법인별 진행 현황'
            )}
          </h3>
          {subTitle && <div className="info warning">{subTitle}</div>}
        </SubTitleGroup>
      </SubTitleLayout>
      <CustomGrid
        isTransposed={true}
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        height={110}
        isReadOnly={true}
        isSelector={false}
        isFilter={false}
        allowPinning={false}
        initialized={onInitialized}
      />
    </>
  );
};

export default React.forwardRef(IdleAssetStatusGrid);
