/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useImperativeHandle, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';
import { toggleClass } from '@grapecity/wijmo';
import {
  ControlBtnGroup,
  GlobalBtnGroup,
  SubTitleGroup,
  SubTitleLayout,
} from 'components/layouts/ContentLayout';
import { IconButton } from 'components/buttons/IconSVG';
import CustomGrid from 'components/grids/CustomGrid';
import { IdleAssetDetail, IdleAssetMaster } from 'models/asst/IdleAsset';
import { getExcelFileName } from 'utils/ExcelUtil';
import { findIdleAssetEquipments, generateRvwReqId } from 'apis/asst/IdleAsset';
import { useMessageBar } from 'hooks/useMessageBar';
import { IdlAssetReviewResultCfmPopUp } from '../popup/IdlAssetReviewResultCfmPopUp';
import { Code } from 'models/common/CommonCode';
import { getCommonCodeNames, getCommonCodeNamesCondition } from 'apis/admin/CommonCode';
import { CellType, DataMap } from '@grapecity/wijmo.grid';
import { IdlAssetReviewResultPopUp } from '../popup/IdlAssetReviewResultPopUp';
import { saveIdleAssetReviewResultEqp } from 'apis/asst/IdleAssetReviewResult';
import { useCommonModal } from 'hooks/useCommonModal';
import { IdleAssetReviewResult } from 'models/asst/IdleAssetReviewResult';
import { hasRole } from 'utils/SessionUtil';
import useEvent from 'react-use-event-hook';

interface Prop {
  onClikDetail: (item: IdleAssetDetail) => void;
  resultCfm: boolean;
  isAsstMstComplete: boolean;
  gridHeight?: number;
  onRefresh?: () => void;
}

const IdleAssetEquipmentGrid = (
  { onClikDetail, resultCfm, isAsstMstComplete, gridHeight, onRefresh }: Prop,
  ref
) => {
  const { t } = useTranslation();
  const gridRef = useRef<any>();
  const [condition, setCondition] = useState<IdleAssetMaster>();
  const [rowData, setRowData] = useState<IdleAssetReviewResult[]>([]);
  const [subTitle, setSubTitle] = useState<string>('');
  const { openMessageBar } = useMessageBar();
  const [isOpenReviewResult, setOpenReviewResult] = useState<boolean>(false);
  const [isOpenReviewResultDept, setOpenReviewResultDept] = useState<boolean>(false);
  const [reviewResultCondition, setReviewResultCondition] = useState<any>();

  const [code, setCode] = useState<any>();
  const { openCommonModal } = useCommonModal();
  const [isAsstEqpComplete, setAsstEqpComplete] = useState<boolean>(true);
  const [isAsstDeptRole, setAsstDeptRole] = useState<boolean>(false);

  useEffect(() => {
    getCommonCodes();
  }, []);

  const getCommonCodes = async () => {
    const asstRvwRltDivsCd: Code[] = await getCommonCodeNames('ASST_RVW_RLT_DIVS_CD'); // 검토결과
    const asstRvwTpCd: Code[] = await getCommonCodeNames('ASST_RVW_TP_CD'); // 검토유형
    /*const asstRvwRltDivsCd2: Code[] = await getCommonCodeNamesCondition({
      optValCtn1: 'Y',
      cmnGrCd: 'ASST_RVW_RLT_DIVS_CD',
    }); // 검토유형2*/
    const rvwStatCd: Code[] = await getCommonCodeNamesCondition({
      optValCtn1: 'Y',
      cmnGrCd: 'RVW_STAT_CD',
    }); // 검토상태

    setCode({
      asstRvwRltDivsCd: asstRvwRltDivsCd,
      rvwStatCd: rvwStatCd,
      asstRvwTpCd: asstRvwTpCd,
      // asstRvwRltDivsCd2: asstRvwRltDivsCd2,
    });
  };

  useImperativeHandle(ref, () => ({
    search: (params) => {
      setCondition(params);
      handleSearch(params);
    },
  }));

  const handleSearch = (params) => {
    setSubTitle(
      `${t('asst.label.요청정보', '요청정보')} ${params.asstRvwReqCopCd}, ${params.rvwReqId}`
    );
    findIdleAssetEquipments(params.rvwReqId, params.asstRvwReqCopCd).then((result) => {
      //요청별 부서검토권한
      setAsstDeptRole(result[0].deptRvwRoleYn == 'Y');

      //설비검토 완료여부
      setAsstEqpComplete(true);

      if (result.filter((o) => o.rvwStatCd == 'RVW').length > 0) {
        setAsstEqpComplete(false);
      }
      setRowData(result);
      onClikDetail(result[0]);
    });
  };

  const handleSave = () => {
    // if (!validate()) return;

    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.저장하시겠습니까?', '저장하시겠습니까?'),
      yesCallback: () => {
        // 변경된 데이터
        const params = (rowData || []).filter((o) => !!o.crudKey);
        saveIdleAssetReviewResultEqp(params)
          .then((response) => {
            // getEquipmentInfo(initParam);
            openMessageBar({
              type: 'confirm',
              content: t('com.msg.저장되었습니다.', '저장되었습니다.'),
            });

            // 재조회 호출
            onRefresh?.();
          })
          .catch((err) =>
            openMessageBar({
              type: 'error',
              content: t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
            })
          );
      },
    });
  };

  const handleExportExcel = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(gridRef.current, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = t('asst.label.유휴자산 설비 List', '불용자산 설비 List');
    book.saveAsync(getExcelFileName(t('asst.label.유휴자산 설비 List', '불용자산 설비 List')));
  };

  const onInitialized = (grid) => {
    gridRef.current = grid;
    grid.itemFormatter = onItemFormatter;

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;
      const ht = grid.hitTest(e);
      grid.startEditing(true);

      if (ht.row < 0 || ht.col < 0) return;
      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      const item = grid.rows[ht.row].dataItem; // 선택한 row의 정보
    });
  };

  const onItemFormatter = useEvent((panel, row, col, cell) => {
    if (CellType.Cell === panel.cellType) {
      const binding = panel.columns[col].binding;
      const item = panel.rows[row].dataItem;
      const readonly = item.asstRvwTpCd !== 'RLT_INS' || !resultCfm;
      if ('rvwRltDivsCd' === binding) {
        cell.ariaReadOnly = readonly;
        toggleClass(cell, 'WijmoSelect', !readonly);
        if (readonly && cell.firstElementChild instanceof HTMLButtonElement) {
          cell.firstChild.remove();
        }
      }
    }
  });

  const beginningEdit = useEvent((s, e) => {
    const item = s.rows[e.row].dataItem;
    const col = s.columns[e.col];
    const readonly = item.asstRvwTpCd !== 'RLT_INS' || !resultCfm;

    if ('rvwRltDivsCd' === col.binding) {
      e.cancel = readonly;
      /*if (readonly) {
        col.dataMap = new DataMap(code.asstRvwRltDivsCd, 'cmnCd', 'cmnCdNm');
      } else {
        col.dataMap = new DataMap(code.asstRvwRltDivsCd2, 'cmnCd', 'cmnCdNm');
      }*/
      // col.dataMap = asstRvwTpCd;
    }
  });

  const layoutDefinition = useMemo(() => {
    const mapRvwRltDivsCd = new DataMap(code?.asstRvwRltDivsCd || [], 'cmnCd', 'cmnCdNm');
    mapRvwRltDivsCd.getDisplayValues = (item) => {
      if (item.asstRvwTpCd !== 'RLT_INS' || !resultCfm) {
        return (code?.asstRvwRltDivsCd || []).map((o) => o.cmnCdNm || '');
      }
      return (code?.asstRvwRltDivsCd || [])
        .filter((o) => o.optValCtn1 === 'Y')
        .map((o) => o.cmnCdNm || '');
    };

    return [
      {
        binding: 'no',
        header: String(t('com.label.NO', 'NO')),
        align: 'center',
        width: 40,
        isReadOnly: true,
        cellTemplate: (grid) => grid.row._idx + 1,
      },
      {
        binding: 'asstTpCdNm',
        header: String(t('asst.label.분류', '분류')),
        align: 'center',
        width: 80,
        isReadOnly: true,
      },
      {
        binding: 'eqpId',
        header: String(t('asst.label.설비ID', '설비ID')),
        align: 'center',
        width: 100,
        isReadOnly: true,
        cellTemplate: !resultCfm
          ? ''
          : CellMaker.makeLink({
              text: '${item.eqpId}',
              click: (e, ctx) => {
                onClikDetail(ctx.item);
              },
            }),
      },
      {
        binding: 'eqpNm',
        header: String(t('asst.label.설비명', '설비명')),
        align: 'left',
        minWidth: 200,
        width: '*',
        isReadOnly: true,
      },
      {
        binding: 'cctrCd',
        header: String(t('asst.label.코스트센터', '코스트센터')),
        align: 'center',
        width: 100,
        isReadOnly: true,
        visible: resultCfm ? false : true,
      },
      {
        binding: 'cctrNm',
        header: String(t('asst.label.코스트센터명', '코스트센터명')),
        align: 'left',
        width: 130,
        isReadOnly: true,
        visible: resultCfm ? false : true,
      },
      {
        binding: 'asstNo',
        header: String(t('asst.label.자산번호', '자산번호')),
        align: 'center',
        width: 150,
        isReadOnly: true,
      },
      {
        binding: 'asstNm',
        header: String(t('asst.label.고정자산명', '고정자산명')),
        align: 'left',
        width: 150,
        isReadOnly: true,
      },
      {
        binding: 'prdnProcCdNm',
        header: String(t('asst.label.공정', '공정')),
        align: 'center',
        width: 150,
        isReadOnly: true,
      },
      {
        binding: 'rvwStatCdNm',
        header: String(t('asst.label.상태', '상태')),
        align: 'center',
        width: 150,
        isReadOnly: true,
        visible: resultCfm ? false : true,
      },
      {
        binding: 'progressRate',
        header: String(t('asst.label.검토율', '검토율')),
        align: 'center',
        width: 150,
        isReadOnly: true,
        visible: resultCfm ? false : true,
        cellTemplate: CellMaker.makeLink({
          text: '${item.progressRate}',
          click: (e, ctx) => {
            onClikDetail(ctx.item);
          },
        }),
      },
      {
        binding: 'asstRvwTpCd',
        header: String(t('asst.label.검토유형', '검토유형')),
        align: 'center',
        width: 150,
        cssClass: 'WijmoSelect',
        isReadOnly: true,
        dataMap: new DataMap(code?.asstRvwTpCd || [], 'cmnCd', 'cmnCdNm'),
        visible: resultCfm ? true : false,
      },
      {
        binding: 'rvwRltDivsCd',
        header: String(t('asst.label.검토결과', '검토결과')),
        align: 'center',
        width: 150,
        cssClass: 'WijmoSelect',
        dataMap: mapRvwRltDivsCd,
      },

      {
        binding: 'tgtCopCd',
        header: String(t('asst.label.활용법인', '활용법인')),
        align: 'center',
        width: 150,
        isReadOnly: true,
      },
      {
        // TODO
        binding: '',
        header: String(t('asst.label.지연여부', '지연여부')),
        align: 'center',
        width: 150,
        isReadOnly: true,
        visible: resultCfm ? false : true,
      },
      {
        binding: 'acqrDt',
        header: String(t('asst.label.자산취득일자', '자산취득일자')),
        align: 'center',
        width: 150,
        isReadOnly: true,
      },
      {
        binding: 'mdlNm',
        header: String(t('asst.label.대응모델', '대응모델')),
        align: 'left',
        width: 150,
        isReadOnly: true,
      },
      {
        binding: 'asstAcqrAmt',
        header: String(t('asst.label.취득가액(USD)', '취득가액(USD)')),
        align: 'right',
        width: 150,
        isReadOnly: true,
        cellTemplate: (params) =>
          `${params.value ? Number(params.value || 0).toLocaleString() : ''}`,
      },
      {
        binding: 'deprAmt',
        header: String(t('asst.label.잔존가액(USD)', '잔존가액(USD)')),
        align: 'right',
        width: 150,
        isReadOnly: true,
        cellTemplate: (params) =>
          `${params.value ? Number(params.value || 0).toLocaleString() : ''}`,
      },
      {
        binding: 'asstQty',
        header: String(t('asst.label.수량', '수량')),
        align: 'center',
        width: 80,
        isReadOnly: true,
      },
      {
        binding: 'asstQtyUnitNm',
        header: String(t('asst.label.단위', '단위')),
        align: 'center',
        width: 70,
        isReadOnly: true,
      },
      {
        binding: 'eqpOwnCopCd',
        header: String(t('asst.label.법인', '법인')),
        align: 'center',
        width: 150,
        isReadOnly: true,
      },
      {
        binding: 'dataInsUserNm',
        header: String(t('asst.label.등록자', '등록자')),
        align: 'center',
        width: 100,
        isReadOnly: true,
      },
      {
        binding: 'dataInsDtm',
        header: String(t('asst.label.등록일자', '등록일자')),
        align: 'center',
        width: 120,
        isReadOnly: true,
      },
      {
        binding: 'atchFileCnt',
        header: String(t('asst.label.참고자료', '참고자료')),
        align: 'center',
        width: 150,
        isReadOnly: true,
        cellTemplate: (params) =>
          `<div class="fileDiv"><span><em>${(
            params.value || 0
          ).toLocaleString()}</em></span></div>`,
      },
      {
        binding: 'rmk',
        header: String(t('asst.label.비고', '비고')),
        align: 'center',
        width: 150,
        isReadOnly: resultCfm ? false : true,
      },
      {
        binding: 'atchFileGrId',
        visible: false,
      },
    ];
  }, [code, resultCfm]);

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('asst.label.유휴자산 설비 List', '불용자산 요청 List > 불용자산 설비상세')}</h3>
          <span className="total">
            {t('com.label.총', '총')} <span>{(rowData || []).length.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
          {subTitle && !resultCfm && <div className="info warning">{subTitle}</div>}
        </SubTitleGroup>

        <ControlBtnGroup>
          {!resultCfm && isAsstDeptRole && !isAsstEqpComplete && (
            <Button
              css={IconButton.button}
              // className="docu"
              className="create primaryBtn"
              disableRipple
              onClick={() => {
                setReviewResultCondition({
                  rvwReqId: rowData[0].rvwReqId,
                  asstRvwReqCopCd: rowData[0]?.asstRvwReqCopCd,
                });
                setOpenReviewResultDept(true);
              }}
            >
              {t('asst.button.검토결과입력(부서)', '검토결과입력(부서)')}
            </Button>
          )}

          {/*{ ASST_MANAGER }*/}
          {!resultCfm &&
            isAsstEqpComplete &&
            !isAsstMstComplete &&
            (hasRole('ASST_MANAGER') || hasRole('ADM')) && (
              <Button
                css={IconButton.button}
                // className="docu"
                className="create primaryBtn"
                disableRipple
                onClick={() => {
                  if (rowData.length == 0) {
                    openMessageBar({
                      type: 'error',
                      content: t(
                        'asst.msg.검토요청번호를 선택하세요.',
                        '검토요청번호를 선택하세요.'
                      ),
                    });

                    return;
                  }
                  setReviewResultCondition({
                    rvwReqId: rowData[0].rvwReqId,
                    asstRvwReqCopCd: rowData[0]?.asstRvwReqCopCd,
                  });
                  setOpenReviewResult(true);
                }}
              >
                {t('asst.button.최종결과입력', '최종결과입력')}
              </Button>
            )}

          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={handleExportExcel}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        height={gridHeight}
        excludeFilter={['asstTpCdNm']}
        allowPinning={false}
        initialized={onInitialized}
        beginningEdit={beginningEdit}
      />

      {resultCfm && (
        <GlobalBtnGroup>
          <Button css={IconButton.button} className="save" onClick={() => handleSave()}>
            {t('com.button.저장', '저장')}
          </Button>
        </GlobalBtnGroup>
      )}

      {isOpenReviewResult && (
        <IdlAssetReviewResultCfmPopUp
          open={isOpenReviewResult}
          close={() => setOpenReviewResult(false)}
          initParam={{
            rvwReqId: rowData[0].rvwReqId,
            asstRvwReqCopCd: rowData[0].asstRvwReqCopCd,
          }}
          onRefresh={() => {
            // 설비 목록 재조회
            handleSearch(condition);
            // 요청 목록 재조회
            onRefresh?.();
          }}
        />
      )}

      {isOpenReviewResultDept && (
        <IdlAssetReviewResultPopUp
          open={isOpenReviewResultDept}
          close={() => setOpenReviewResultDept(false)}
          initParam={{
            rvwReqId: rowData[0].rvwReqId,
            isAsstEqpComplete: isAsstEqpComplete,
          }}
          onRefresh={() => {
            // 설비 목록 재조회
            handleSearch(condition);
            // 요청 목록 재조회
            onRefresh?.();
          }}
        />
      )}
    </>
  );
};

export default React.forwardRef(IdleAssetEquipmentGrid);
