import { CommonRequest, Method, ServiceName, CommonResponse } from 'models/common/RestApi';
import {
  FacilitySchedule,
  FacilityScheduleRegistMaster,
  FacilityScheduleCondition,
  FacilityManagerCondition,
  FacilityManager,
} from 'models/pjm/FacilityScheduleRegist';
import { callApi } from 'utils/ApiUtil';

export const getFacilityScheduleMaster = async (condition?: FacilityScheduleCondition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/facility/schedule-master`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };
  const response: CommonResponse<FacilityScheduleRegistMaster> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as FacilityScheduleRegistMaster;
};

export const getFacilityScheduleList = async (condition?: FacilityScheduleCondition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/facility/schedule-list`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };
  const response: CommonResponse<FacilitySchedule[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as FacilitySchedule[];
};

export const getFacilityScheduleUserList = async (condition?: FacilityScheduleCondition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/facility/schedule-user-list`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };
  const response: CommonResponse<FacilitySchedule[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as FacilitySchedule[];
};

/**
 * 공정 기본 정보 목록 조회
 * @param copCd
 */
export const getProcessList = async (copCd: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/facility/process`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ copCd: copCd }),
  };
  const response: CommonResponse<FacilitySchedule[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as FacilitySchedule[];
};

/**
 * 설비별 일정 저장
 * @param detailList
 */
export const saveFacilitySchedule = async (
  type: 'write' | 'review',
  detailList: FacilitySchedule[]
) => {
  const request: CommonRequest = {
    method: Method.PUT,
    url: `/v1/facility/schedule/${type}`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: detailList,
  };

  const response: CommonResponse = await callApi(request);
  return response;
};

/**
 * 담당자 목록 조회 (설비별 일정등록 > 담당자 등록 탭)
 * @param condition
 */
export const findFacilityManager = async (condition?: FacilityManagerCondition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/facility/manager`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      elmPrjId: condition?.elmPrjId || '',
      copCd: condition?.copCd || '',
    }),
  };
  const response: CommonResponse<FacilityManager[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as FacilityManager[];
};

/**
 * 담당자 목록 저장 (설비별 일정등록 > 담당자 등록 탭)
 * @param managers
 */
export const saveFacilityManager = async (managers: FacilityManager[]) => {
  const request: CommonRequest = {
    method: Method.PUT,
    url: `/v1/facility/manager`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: managers,
  };

  const response: CommonResponse<string> = await callApi(request);
  return response;
};

export const saveProjectStatus = async (master: FacilityScheduleRegistMaster) => {
  const request: CommonRequest = {
    method: Method.PUT,
    url: `/v1/facility/master-status`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: master,
  };

  const response: CommonResponse<string> = await callApi(request);
  return response;
};
