import { Employee, EmployeeCondition } from 'models/admin/Employee';
import {
  CommonRequest,
  Method,
  ServiceName,
  CommonResponse,
  DmlResponse,
} from 'models/common/RestApi';
import { SetupMaster, SetupTgtEqpSchedule } from 'models/pjm/SetupScheduleRegistDetail';
import { callApi } from 'utils/ApiUtil';

export const getSetupScheduleRegistList = async (elmPrjId) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/setup/getSetupScheduleRegistList`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ elmPrjId: elmPrjId }),
  };
  const response: CommonResponse<SetupMaster> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as SetupMaster;
};

export const getSetupEqpTgtScheduleList = async (searchParam) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/setup/getSetupEqpTgtScheduleList`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...searchParam }),
  };
  const response: CommonResponse<SetupTgtEqpSchedule[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as SetupTgtEqpSchedule[];
};

export const getElmPrjId = async (condition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/setup/getElmPrjId`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      ...condition,
    }),
  };
  const response: CommonResponse<string> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as string;
};

export const saveScheduleRegistTempData = async (saveData: SetupMaster) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/setup/saveScheduleRegistTempData`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: saveData,
  };
  const response: CommonResponse<string> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as string;
};

/**
 * 계획수립 완료 처리
 * @param saveData
 */
export const confirmPlan = async (saveData: SetupMaster) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/setup/confirmPlan`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: saveData,
  };
  const response: CommonResponse<string> = await callApi(request);

  return response;
};

export const saveScheduleRegistData = async (saveData: SetupMaster) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/setup/saveScheduleRegistData`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: saveData,
  };
  const response: CommonResponse<string> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as string;
};

export const updateAtchFileGrId = async (saveData) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/setup/updateAtchFileGrId`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: saveData,
  };
  const response: CommonResponse = await callApi(request);

  return response.successOrNot === 'Y' ? response.data : null;
};

export const getInpectionHistoryMasterData = async (searchParam) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/setup/getInpectionHistoryMasterData`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...searchParam }),
  };
  const response: CommonResponse<SetupMaster> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as SetupMaster;
};
