/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useEvent from 'react-use-event-hook';
import _ from 'lodash';
import { Button } from '@mui/material';
import { addClass } from '@grapecity/wijmo';
import { CellType } from '@grapecity/wijmo.grid';
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { SubTitleLayout, SubTitleGroup, ControlBtnGroup } from 'components/layouts/ContentLayout';
import {
  SearchBox,
  SearchBoxRow,
  InputBox,
  SearchRows,
  SearchCols,
  SearchButtonWrap,
} from 'components/layouts/SearchBox';
import { IconButton } from 'components/buttons/IconSVG';
import { getCommonCodeNames, getCommonCodeNamesCondition } from 'apis/admin/CommonCode';
import { MultiComboBox } from 'components/selects/ComboBox';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { useMessageBar } from 'hooks/useMessageBar';
import CustomGrid from 'components/grids/CustomGrid';
import { getExcelFileName } from 'utils/ExcelUtil';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Code } from 'models/common/CommonCode';
import { hasRole } from 'utils/SessionUtil';
import SetupScheduleRegistPopup from './popup/SetupScheduleRegistPopup';
import SetupResultRegistPopup from './popup/SetupResultRegistPopup';
import { getEquGroupParameter } from 'apis/pjm/SetupManager';
import { SearchParam, SetupDashboard } from 'models/pjm/SetupDashboard';
import { getSetupDashboardList } from 'apis/pjm/SetupDashboard';
import { SetupDashboardLegendPopup } from './popup/SetupDashboardLegendPopup';
import { ResultRegistCondition } from 'models/pjm/SetupResultRegist';
import SetupScheduleChartPopup from 'pages/pjm/popup/SetupScheduleChartPopup';

interface PageLocationState {
  elmPrjId?: string;
}

interface scheduleRegistCondition {
  elmPrjId?: string;
  mode?: string;
}

const SetupDashboardPage = () => {
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();
  const [searchParams] = useSearchParams();
  const { state } = useLocation();
  const [locationState, setLocationState] = useState<PageLocationState>(useLocation().state);

  const [flexRef, setFlexRef] = useState<any>();

  const [rowData, setRowData] = useState<SetupDashboard[]>([]);

  const [copCode, setCopCode] = useState<Code[]>([]);
  const [prdnPldoCode, setPrdnPldoCode] = useState<Code[]>([]);
  const [prdnProcCode, setPrdnProcCode] = useState<Code[]>([]);
  const [eqclIdCode, setEqclIdCode] = useState<Code[]>([]);
  const [elmPrjStatCode, setElmPrjStatCode] = useState<Code[]>([]);
  const excludefilter = [
    'atMatStat',
    'fatStat',
    'fobStat',
    'eqpCrryStat',
    'istlStat',
    'iocStat',
    'wetRunStat',
    'qcStat',
    'satStat',
    'smplPrdnStat',
    'mspdStat',
  ];

  const initSearchParam = {
    copCds: [],
    prdnPldoCds: [],
    prdnProcCds: [],
    eqclIds: [],
    elmPrjStatCds: ['ONGOING'],
    elmPrjId: '',
    elmPrjNm: '',
  };
  const [searchParam, setSearchParam] = useState<SearchParam>({
    ...initSearchParam,
    elmPrjId: searchParams?.get('elmPrjId') || '',
  });
  const [isOpenScheduleRegistModal, setOpenScheduleRegistModal] = useState<boolean>(false);
  const [isOpenResultRegistModal, setOpenResultRegistModal] = useState<boolean>(false);
  const [isOpenSetupScheduleChartPopup, setOpenSetupScheduleChartPopup] = useState<boolean>(false);
  const [isOpenlegendPopUp, setOpenlegendPopUp] = useState<boolean>(false);
  const condition = {
    emlPrjId: '',
    mode: 'NEW',
  };
  const [resultRegistCondition, setResultRegistCondition] = useState<ResultRegistCondition>({});
  const [scheduleRegtistCondition, setScheduleRegtistCondition] =
    useState<scheduleRegistCondition>(condition);

  const [setupScheduleChartCondition, setSetupScheduleChartCondition] = useState<any>({
    emlPrjId: '',
  });

  const [hasAuth, setHasAuth] = useState<boolean>(false);

  useEffect(() => {
    initCondition();
    if (hasRole('ADM') || hasRole('UT_MANAGER')) {
      setHasAuth(true);
    }
    btnSearch();
  }, []);

  useEffect(() => {
    setSearchParam((prev) => ({
      ...prev,
      elmPrjId: locationState?.elmPrjId || prev?.elmPrjId || searchParams?.get('elmPrjId') || '',
    }));
    if (locationState?.elmPrjId) {
      btnSearch();
    }
  }, [locationState?.elmPrjId, searchParams?.get('elmPrjId')]);

  useEffect(() => {
    if (state?.elmPrjId) {
      setLocationState(state);
    }
  }, [state]);

  const initCondition = async () => {
    const copCode: Code[] = await getCommonCodeNames('ELM_COP_CD');
    const factoryCode: Code[] = await getCommonCodeNames('FACTORY_CODE');
    const elmPrjStatCode: Code[] = await getCommonCodeNames('ELM_PRJ_STAT_CD');
    const prdnProcCode: Code[] = await getCommonCodeNamesCondition({
      optValCtn3: 'Y',
      cmnGrCd: 'PRDN_PROC_CD',
    });
    const eqclCode: Code[] = await getEquGroupParameter();

    const filteredElmPrjStatCode = elmPrjStatCode.filter((item) => item.cmnCd !== 'PLANNING');

    setCopCode(copCode);
    setPrdnPldoCode(
      (factoryCode || []).reduce(
        (acc, cur) => [
          ...acc,
          {
            ...cur,
            cmnCdNm: `${cur.cmnCd} : ${cur.cmnCdNm}`,
          },
        ],
        [] as Code[]
      )
    );
    setPrdnProcCode(prdnProcCode);
    setEqclIdCode(eqclCode);
    setElmPrjStatCode(filteredElmPrjStatCode);

    if (hasRole('ADM') || hasRole('SETUP_MANAGER')) {
      setHasAuth(true);
    }
  };

  const layoutDefinition = useMemo(() => {
    return [
      {
        binding: 'copCd',
        header: String(t('ut.label.법인', '법인')),
        isReadOnly: true,
        width: 90,
        align: 'center',
        cellTemplate: CellMaker.makeLink({
          text: '<span>${item.copCd}</span>',
          click: (e, ctx) => {
            btnGridScheduleRegist(ctx.item);
          },
        }),
      },
      {
        binding: 'prdnProcCd',
        visible: false,
      },
      {
        binding: 'prdnProcCdNm',
        header: String(t('ut.label.공정', '공정')),
        isReadOnly: true,
        width: 90,
        align: 'center',
        cellTemplate: CellMaker.makeLink({
          text: '<span>${item.prdnProcCdNm}</span>',
          click: (e, ctx) => {
            btnGridScheduleRegist(ctx.item);
          },
        }),
      },
      {
        binding: 'prdnProcCd',
        visible: false,
      },
      {
        binding: 'eqclId',
        header: String(t('ut.label.설비군', '설비군')),
        isReadOnly: true,
        width: 90,
        align: 'center',
        cellTemplate: CellMaker.makeLink({
          text: '<span>${item.eqclId}</span>',
          click: (e, ctx) => {
            btnGridScheduleRegist(ctx.item);
          },
        }),
      },
      {
        header: String(t('pjm.label.Line', 'Line')),
        align: 'center',
        columns: [
          {
            binding: 'eltrTpCdNm',
            header: String(t('pjm.label.A/C', 'A/C')),
            width: 80,
            align: 'center',
          },
          {
            binding: 'eqpLnId',
            header: String(t('pjm.label.호기', '호기')),
            width: 80,
            align: 'center',
            cellTemplate: CellMaker.makeLink({
              click: (e, ctx) => {
                btnShowChart(ctx.item);
              },
            }),
          },
        ],
      },
      {
        header: String(t('pjm.label.PO발행', 'PO발행')),
        align: 'center',
        columns: [
          {
            binding: 'poIssuStat',
            header: String(t('pjm.label.계획일정', '계획일정')),
            width: 120,
            align: 'center',
            cellTemplate: (params) => btnResultRegist(params, params.item.poIssuDt),
          },
          {
            binding: 'poIssuStat',
            header: String(t('pjm.label.현황', '현황')),
            width: 120,
            align: 'center',
            cellTemplate: (params) => {
              return `<span>${params.item.poIssuCrs === null ? '' : params.item.poIssuCrs}</span>`;
            },
          },
          {
            binding: 'poIssuDt',
            visible: false,
          },
          {
            binding: 'poIssuCrs',
            visible: false,
          },
        ],
      },
      {
        header: String(t('pjm.label.시운전자재', '시운전자재')),
        align: 'center',
        columns: [
          {
            binding: 'atMatStat',
            header: String(t('pjm.label.계획일정', '계획일정')),
            width: 120,
            align: 'center',
            cellTemplate: (params) => btnResultRegist(params, params.item.atMatDt),
          },
          {
            binding: 'atMatStat',
            header: String(t('pjm.label.현황', '현황')),
            width: 120,
            align: 'center',
            cellTemplate: (params) => {
              return `<span>${params.item.atMatCrs === null ? '' : params.item.atMatCrs}</span>`;
            },
          },
          {
            binding: 'atMatDt',
            visible: false,
          },
          {
            binding: 'atMatCrs',
            visible: false,
          },
        ],
      },
      {
        header: String(t('pjm.label.FAT', 'FAT')),
        align: 'center',
        columns: [
          {
            binding: 'fatStat',
            header: String(t('pjm.label.계획일정', '계획일정')),
            width: 120,
            align: 'center',
            cellTemplate: (params) => btnResultRegist(params, params.item.fatDt),
          },
          {
            binding: 'fatStat',
            header: String(t('pjm.label.현황', '현황')),
            width: 120,
            align: 'center',
            cellTemplate: (params) => {
              return `<span>${params.item.fatCrs === null ? '' : params.item.fatCrs}</span>`;
            },
          },
          {
            binding: 'fatDt',
            visible: false,
          },
          {
            binding: 'fatCrs',
            visible: false,
          },
        ],
      },
      {
        header: String(t('pjm.label.FOB', 'FOB')),
        align: 'center',
        columns: [
          {
            binding: 'fobStat',
            header: String(t('pjm.label.계획일정', '계획일정')),
            width: 120,
            align: 'center',
            cellTemplate: (params) => btnResultRegist(params, params.item.fobDt),
          },
          {
            binding: 'fobStat',
            header: String(t('pjm.label.현황', '현황')),
            width: 120,
            align: 'center',
            cellTemplate: (params) => {
              return `<span>${params.item.fobCrs === null ? '' : params.item.fobCrs}</span>`;
            },
          },
          {
            binding: 'fobDt',
            visible: false,
          },
          {
            binding: 'fobCrs',
            visible: false,
          },
        ],
      },
      {
        header: String(t('pjm.label.설비반입', '설비반입')),
        align: 'center',
        columns: [
          {
            binding: 'eqpCrryStat',
            header: String(t('pjm.label.계획일정', '계획일정')),
            width: 120,
            align: 'center',
            cellTemplate: (params) => btnResultRegist(params, params.item.eqpCrryDt),
          },
          {
            binding: 'eqpCrryStat',
            header: String(t('pjm.label.현황', '현황')),
            width: 120,
            cellTemplate: (params) => {
              return `<span>${
                params.item.eqpCrryCrs === null ? '' : params.item.eqpCrryCrs
              }</span>`;
            },
          },
          {
            binding: 'eqpCrryDt',
            visible: false,
          },
          {
            binding: 'eqpCrryCrs',
            visible: false,
          },
        ],
      },
      {
        header: String(t('pjm.label.설치', '설치')),
        align: 'center',
        columns: [
          {
            binding: 'istlStat',
            header: String(t('pjm.label.계획일정', '계획일정')),
            width: 120,
            align: 'center',
            cellTemplate: (params) => btnResultRegist(params, params.item.istlDt),
          },
          {
            binding: 'istlStat',
            header: String(t('pjm.label.현황', '현황')),
            width: 120,
            align: 'center',
            cellTemplate: (params) => {
              return `<span>${params.item.istlCrs === null ? '' : params.item.istlCrs}</span>`;
            },
          },
          {
            binding: 'istlDt',
            visible: false,
          },
          {
            binding: 'istlCrs',
            visible: false,
          },
        ],
      },
      {
        header: String(t('pjm.label.I/O Check', 'I/O Check')),
        align: 'center',
        columns: [
          {
            binding: 'iocStat',
            header: String(t('pjm.label.계획일정', '계획일정')),
            width: 120,
            align: 'center',
            cellTemplate: (params) => btnResultRegist(params, params.item.iocDt),
          },
          {
            binding: 'iocStat',
            header: String(t('pjm.label.현황', '현황')),
            width: 120,
            align: 'center',
            cellTemplate: (params) => {
              return `<span>${params.item.iocCrs === null ? '' : params.item.iocCrs}</span>`;
            },
          },
          {
            binding: 'iocDt',
            visible: false,
          },
          {
            binding: 'iocCrs',
            visible: false,
          },
        ],
      },
      {
        header: String(t('pjm.label.Wet Run', 'Wet Run')),
        align: 'center',
        columns: [
          {
            binding: 'wetRunStat',
            header: String(t('pjm.label.계획일정', '계획일정')),
            width: 120,
            align: 'center',
            cellTemplate: (params) => btnResultRegist(params, params.item.wetRunDt),
          },
          {
            binding: 'wetRunStat',
            header: String(t('pjm.label.현황', '현황')),
            width: 120,
            align: 'center',
            cellTemplate: (params) => {
              return `<span>${params.item.wetRunCrs === null ? '' : params.item.wetRunCrs}</span>`;
            },
          },
          {
            binding: 'wetRunDt',
            visible: false,
          },
          {
            binding: 'wetRunCrs',
            visible: false,
          },
        ],
      },
      {
        header: String(t('pjm.label.Quality Check', 'Quality Check')),
        align: 'center',
        columns: [
          {
            binding: 'qcStat',
            header: String(t('pjm.label.계획일정', '계획일정')),
            width: 120,
            align: 'center',
            cellTemplate: (params) => btnResultRegist(params, params.item.qcDt),
          },
          {
            binding: 'qcStat',
            header: String(t('pjm.label.현황', '현황')),
            width: 120,
            align: 'center',
            cellTemplate: (params) => {
              return `<span>${params.item.qcCrs === null ? '' : params.item.qcCrs}</span>`;
            },
          },
          {
            binding: 'qcDt',
            visible: false,
          },
          {
            binding: 'qcCrs',
            visible: false,
          },
        ],
      },
      {
        header: String(t('pjm.label.SAT', 'SAT')),
        align: 'center',
        columns: [
          {
            binding: 'satStat',
            header: String(t('pjm.label.계획일정', '계획일정')),
            width: 120,
            align: 'center',
            cellTemplate: (params) => btnResultRegist(params, params.item.satDt),
          },
          {
            binding: 'satStat',
            header: String(t('pjm.label.현황', '현황')),
            width: 120,
            align: 'center',
            cellTemplate: (params) => {
              return `<span>${params.item.satCrs === null ? '' : params.item.satCrs}</span>`;
            },
          },
          {
            binding: 'satDt',
            visible: false,
          },
          {
            binding: 'satCrs',
            visible: false,
          },
        ],
      },
      {
        header: String(t('pjm.label.샘플생산', '샘플생산')),
        align: 'center',
        columns: [
          {
            binding: 'smplPrdnStat',
            header: String(t('pjm.label.계획일정', '계획일정')),
            width: 120,
            align: 'center',
            cellTemplate: (params) => btnResultRegist(params, params.item.smplPrdnDt),
          },
          {
            binding: 'smplPrdnStat',
            header: String(t('pjm.label.현황', '현황')),
            width: 120,
            align: 'center',
            cellTemplate: (params) => {
              return `<span>${
                params.item.smplPrdnCrs === null ? '' : params.item.smplPrdnCrs
              }</span>`;
            },
          },
          {
            binding: 'smplPrdnDt',
            visible: false,
          },
          {
            binding: 'smplPrdnCrs',
            visible: false,
          },
        ],
      },
      {
        header: String(t('pjm.label.양산', '양산')),
        align: 'center',
        columns: [
          {
            binding: 'mspdStat',
            header: String(t('pjm.label.계획일정', '계획일정')),
            width: 120,
            align: 'center',
            cellTemplate: (params) => btnResultRegist(params, params.item.mspdDt),
          },
          {
            binding: 'mspdStat',
            header: String(t('pjm.label.현황', '현황')),
            width: 120,
            align: 'center',
            cellTemplate: (params) => {
              return `<span>${params.item.mspdCrs === null ? '' : params.item.mspdCrs}</span>`;
            },
          },
          {
            binding: 'mspdDt',
            visible: false,
          },
          {
            binding: 'mspdCrs',
            visible: false,
          },
        ],
      },
      {
        binding: 'elmPrjId',
        header: String(t('pjm.label.프로젝트 ID', '프로젝트 ID')),
        isReadOnly: true,
        width: 100,
        align: 'center',
        cellTemplate: CellMaker.makeLink({
          text: '<span>${item.elmPrjId}</span>',
          click: (e, ctx) => {
            btnGridScheduleRegist(ctx.item);
          },
        }),
      },
      {
        binding: 'elmPrjNm',
        header: String(t('pjm.label.프로젝트 명', '프로젝트 명')),
        isReadOnly: true,
        width: 200,
        align: 'left',
      },
      {
        binding: 'elmPrjStatCdNm',
        header: String(t('com.label.상태', '상태')),
        isReadOnly: true,
        width: 100,
        align: 'center',
      },
      {
        binding: 'prdnPldoCdNm',
        header: String(t('pjm.label.Factory', 'Factory')),
        isReadOnly: true,
        width: 180,
        align: 'left',
      },
    ];
  }, [rowData]);

  const onInitialized = (grid) => {
    setFlexRef(grid);

    grid.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;
      // grid.startEditing(true);

      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      const binding = grid.columns[ht.col].binding;
      const item = grid.rows[ht.row].dataItem;

      if (e.target instanceof HTMLAnchorElement) {
        if (e.target.id === 'btnOpen') {
          setResultRegistCondition({
            elmPrjId: item.elmPrjId,
            prdnPldoCd: item.prdnPldoCd,
            planProcId: item.planProcId,
            eqpLnId: item.eqpLnId,
            eltrTpCd: item.eltrTpCd,
            prjSpCd: _.toUpper(_.snakeCase(binding.replace('Stat', ''))),
          });
          setOpenResultRegistModal(true);
        }
      }
    });
  };

  const onItemFormatter = useEvent((panel, row, col, cell) => {
    if (CellType.Cell === panel.cellType) {
      const binding = panel.columns[col].binding;
      const item = panel.rows[row].dataItem;
      if (binding.includes('Dt') || binding.includes('Stat')) {
        switch (item[binding]) {
          case 'CPLT':
            addClass(cell, 'cplt');
            break;
          case 'DLY_CPLT':
            addClass(cell, 'dlyCplt');
            break;
          case 'OBSR':
            addClass(cell, 'obsr');
            break;
          case 'DLY':
            addClass(cell, 'dly');
            break;
        }
      }
    }
  });

  const handleOnChange = (name, value) => {
    setSearchParam({ ...searchParam, [name]: value });
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      btnSearch();
    }
  };

  const btnMasterExcelExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
      formatItem: exportFormat,
    });
    book.sheets[0].name = 'setup';
    book.saveAsync(getExcelFileName(t('pjm.label.Setup_일정_Dashboard', 'Setup_일정_Dashboard')));
  };

  const exportFormat = (args) => {
    const p = args.panel;
    const row = args.row;
    const col = args.col;
    const xlsxCell = args.xlsxCell;
    if (p.columns[col].binding === 'gatingContIds') {
      const cell = args.getFormattedCell();
      xlsxCell.value = cell.textContent;
    }
    if (p.columns[col].binding === 'gatingContTlIds') {
      const cell = args.getFormattedCell();
      xlsxCell.value = cell.textContent;
    }
    if (p.columns[col].binding === 'gatingEqpContData') {
      const cell = args.getFormattedCell();
      xlsxCell.value = '';
    }
  };

  const btnSearch = () => {
    getSetupDashboardList(searchParam).then((result: SetupDashboard[]) => {
      if (result !== null) {
        setRowData(result);
      } else {
        openMessageBar({
          type: 'error',
          content: t(
            'com.msg.요청 정보 조회 중 오류가 발생했습니다.',
            '요청 정보 조회 중 오류가 발생했습니다.'
          ),
        });
      }
    });
  };

  const btnReload = () => {
    setSearchParam(
      (prev) =>
        Object.keys(prev).reduce(
          (acc, cur) => Object.assign(acc, { [cur]: initSearchParam[cur] || '' }),
          {}
        ) as SearchParam
    );
  };

  const btnGridScheduleRegist = (params) => {
    const condition = {
      elmPrjId: params?.elmPrjId,
      mode: 'WRITE',
    };
    setScheduleRegtistCondition(condition);
    setOpenScheduleRegistModal(true);
  };

  const btnScheduleRegist = (params) => {
    const selectedRowNodes = flexRef.selectedRows;
    const isSelected = selectedRowNodes.filter((item) => item.isSelected);
    const selectedData = isSelected.map(({ dataItem }) => dataItem);

    if (isSelected.length > 1) {
      openMessageBar({
        type: 'error',
        content: t('pjm.label.Setup을 하나만 선택해 주세요.', 'Setup을 하나만 선택해 주세요.'),
      });
      return false;
    }

    if (isSelected.length === 1) {
      const elmPrjId = selectedData[0].elmPrjId;
      const condition = {
        elmPrjId: elmPrjId,
        mode: 'WRITE',
      };
      setScheduleRegtistCondition(condition);
      setOpenScheduleRegistModal(true);
    } else if (isSelected.length === 0) {
      const condition = {
        elmPrjId: '',
        mode: 'NEW',
      };
      setScheduleRegtistCondition(condition);
      setOpenScheduleRegistModal(true);
    }
  };

  const btnResultRegist = (params, binding) => {
    if (['OBSR', 'DLY'].includes(params.value)) {
      return `<a href="#" class="wj-cell-maker" tabindex="-1" id="btnOpen">${
        binding === null ? '' : binding
      }</a>`;
    } else {
      return `<span>${binding === null ? '' : binding}</span>`;
    }
  };

  const btnShowChart = (params) => {
    const condition = {
      elmPrjId: params?.elmPrjId,
      prdnPldoCd: params?.prdnPldoCd,
      eltrTpCd: params?.eltrTpCd,
      eqpLnId: params?.eqpLnId,
      planProcId: params?.planProcId,
      prdnProcCdNm: params?.prdnProcCdNm, // 타이틀 노출용
      eqclId: params?.eqclId, // 타이틀 노출용
    };
    setSetupScheduleChartCondition(condition);
    setOpenSetupScheduleChartPopup(true);
  };

  return (
    <>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows className="FourCol">
              <SearchCols>
                <label>{String(t('pjm.label.법인', '법인'))}</label>
                <MultiComboBox
                  id="prdnProcCd"
                  options={copCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.prdnProcCds || []}
                  onChange={(value) => {
                    handleOnChange('copCd', value.join());
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{String(t('pjm.label.Factory', 'Factory'))}</label>
                <MultiComboBox
                  id="prdnPldoCd"
                  options={prdnPldoCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.prdnPldoCds || []}
                  onChange={(value) => {
                    handleOnChange('prdnPldoCd', value.join());
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{String(t('pjm.label.공정', '공정'))}</label>
                <MultiComboBox
                  id="prdnProcCd"
                  options={prdnProcCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.prdnProcCds || []}
                  onChange={(value) => {
                    handleOnChange('prdnProcCd', value.join());
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{String(t('pjm.label.설비군', '설비군'))}</label>
                <MultiComboBox
                  id="eqpGrcd"
                  options={eqclIdCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.eqclIds || []}
                  onChange={(value) => {
                    handleOnChange('eqclId', value.join());
                  }}
                />
              </SearchCols>
            </SearchRows>
            <SearchRows className="FourColOneTwoOne">
              <SearchCols>
                <label>{t('pjm.label.프로젝트 ID', '프로젝트 ID')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="elmPrjId"
                  placeholder={String(
                    t('pjm.label.프로젝트 ID를 입력해 주세요.', '프로젝트 ID를 입력해 주세요.')
                  )}
                  value={searchParam?.elmPrjId}
                  onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                  onKeyDown={onKeyDown}
                />
              </SearchCols>
              <SearchCols className="width3">
                <label>{t('pjm.label.프로젝트 명', '프로젝트 명')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="elmPrjNm"
                  placeholder={String(
                    t('pjm.label.프로젝트 명을 입력해 주세요.', '프로젝트 명을 입력해 주세요.')
                  )}
                  value={searchParam?.elmPrjNm}
                  onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                  onKeyDown={onKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label>{String(t('pjm.label.상태', '상태'))}</label>
                <MultiComboBox
                  id="elmPrjStatCd"
                  options={elmPrjStatCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.elmPrjStatCds || []}
                  onChange={(value) => {
                    handleOnChange('elmPrjStatCd', value.join());
                  }}
                />
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchButtonWrap>
            <Button
              css={IconButton.button}
              className="reload"
              onClick={btnReload}
              disableRipple
            ></Button>
            <Button css={IconButton.button} className="find" onClick={btnSearch} disableRipple>
              {t('com.button.조회', '조회')}
            </Button>
          </SearchButtonWrap>
        </SearchBoxRow>
      </SearchBox>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('pjm.label.Setup 일정 Dashboard', 'Setup 일정 Dashboard')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{rowData?.length ?? 0}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="rule"
            onClick={() => setOpenlegendPopUp(true)}
            disableRipple
          >
            {t('pjm.label.범례', '범례', '범례')}
          </Button>
          {/* <StatusDot>
            <span className="red">{t('pjm.label.지연', '지연')}</span>
            <span className="yellow">{t('pjm.label.지연완료', '지연완료')}</span>
            <span className="green">{t('pjm.label.준수', '준수')}</span>
            <span className="blue">{t('pjm.label.적기완료', '적기완료')}</span>
          </StatusDot> */}
          {hasAuth && (
            <Button
              css={IconButton.button}
              className="regist"
              onClick={btnScheduleRegist}
              disableRipple
            >
              {t('com.button.일정등록', '일정등록')}
            </Button>
          )}
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={btnMasterExcelExport}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        isReadOnly={true}
        initialized={onInitialized}
        itemFormatter={onItemFormatter}
        height={490}
        frozenColumns={7}
        allowPinning={false}
        excludeFilter={excludefilter}
        isHoverCss={false}
      />
      {isOpenScheduleRegistModal && (
        <SetupScheduleRegistPopup
          open={isOpenScheduleRegistModal}
          close={() => setOpenScheduleRegistModal(false)}
          onCallback={() => console.log('')}
          condition={scheduleRegtistCondition}
        />
      )}
      {isOpenResultRegistModal && (
        <SetupResultRegistPopup
          open={isOpenResultRegistModal}
          close={() => setOpenResultRegistModal(false)}
          onRefresh={() => btnSearch()}
          condition={resultRegistCondition}
        />
      )}
      {isOpenlegendPopUp && (
        <SetupDashboardLegendPopup
          open={isOpenlegendPopUp}
          close={() => setOpenlegendPopUp(false)}
        />
      )}
      {isOpenSetupScheduleChartPopup && (
        <SetupScheduleChartPopup
          open={isOpenSetupScheduleChartPopup}
          close={() => setOpenSetupScheduleChartPopup(false)}
          condition={setupScheduleChartCondition}
        />
      )}
    </>
  );
};

export default SetupDashboardPage;
