/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  SearchBox,
  SearchBoxRow,
  InputBox,
  SearchRows,
  SearchCols,
} from 'components/layouts/SearchBox';
import SearchBoxButtons from 'components/buttons/SearchBoxButtons';

import {
  ProcessBarWrap,
  ProcessStepBox,
  StepBox,
  ProcessStep,
  ProcessNum,
  ProcessText,
  SubStep,
  ProgressBar,
} from 'components/process/Process';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'hooks/useMessageBar';
import { tabs } from 'components/layouts/Tab';
import { ComboBox, MultiComboBox } from 'components/selects/ComboBox';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { Code } from 'models/common/CommonCode';
import { UtMatrixRegistSearchCondition, UtMatrixMaster, UtMatrixDetail } from 'models/ut/UtMatrix';
import {
  findWithConditionMaster,
  findProcess,
  findProcessBar,
  submitCheckRegistMatrix,
  submitUtMatix,
  changeStatUtMatix,
} from 'apis/ut/UtMatrixRegist';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import MachineSummaryContent from './review/MachineSummaryContent';
import ElectricSummaryContent from './review/ElectricSummaryContent';
import StandardUseContent from './review/StandardUseContent';
import DetailSummaryContent from './review/DetailSummaryContent';

import CustomGrid from '../../components/grids/CustomGrid';
import { UtMatrixSummaryList } from '../../models/ut/UtMatrixSummary';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { getUtMatrixSummaryList } from 'apis/ut/UtMatrixSummary';

import ApproveRequestModal from '../approves/ApproveRequestModal';
import { ApproveRequestPageType } from '../approves/ApproveRequestDetailCase';
import UtMatrixGraphPopup from './popup/UtMatrixGraphPopup';
import { ManagementMode } from '../../models/common/Common';
import { Employee } from 'models/admin/Employee';
import { findUtMatrixReferrer } from 'apis/ut/UtMatrixReview';

interface PageLocationState {
  openMode?: 'UT_MGR' | 'WRITE' | 'READ';
  bildPlntCd?: string;
  utmId?: string;
  planProcId?: string;
}

const UtMatrixReviewPage = () => {
  const { t } = useTranslation();
  const detailRef = useRef<any>();
  const machineRef = useRef<any>();
  const elecRef = useRef<any>();
  const atulCapaRef = useRef<any>();
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const [locationState, setLocationState] = useState<PageLocationState>(useLocation().state);
  const conditionRef = useRef<UtMatrixRegistSearchCondition>();
  const { openMessageBar } = useMessageBar();
  const { openCommonModal } = useCommonModal();
  const [code, setCode] = useState<any>();
  const [condition, setCondition] = useState<UtMatrixRegistSearchCondition>({
    bildPlntCd: searchParams?.get('bildPlntCd') || '',
    utmId: searchParams?.get('utmId') || '',
    // planProcId: searchParams?.get('planProcId') || '',
    openMode: searchParams?.get('openMode') || '',
    sumBaseCd: 'ALL',
  });
  const [errors, setErrors] = useState<any>({});
  const [isRefresh, setRefresh] = useState<boolean>(false);
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);
  const [masterData, setMasterData] = useState<UtMatrixMaster[]>([]);
  const [processData, setProcessData] = useState<UtMatrixDetail[]>([]);
  const [utmWkProgStatCd, setUtmWkProgStatCd] = useState<Code[]>([]);
  const [bildPlntCode, setBildPlntCode] = useState<Code[]>([]);
  const [utmWkProgInfo, setUtmWkProgInfo] = useState<UtMatrixDetail>();
  const tabMenus = useMemo(() => {
    return [
      { id: 'UTM', name: t('ut.label.UT Matrix', 'UT Matrix') },
      { id: 'MACH', name: t('ut.label.기계요약', '기계요약') },
      { id: 'ELTR', name: t('ut.label.전기요약', '전기요약') },
      { id: 'CAPA', name: t('ut.label.표준사용량 비교', '표준사용량 비교') },
    ];
  }, []);

  const [isOpenGraphModal, setOpenGraphModal] = useState<boolean>(false);
  const [utmData, setUtmData] = useState<UtMatrixSummaryList[]>([]);

  // 검색조건 > UT Matrix 명 (ComboBox 사용을 위해 변환)
  const optionsUtMaster = useMemo(() => {
    return (masterData || []).reduce(
      (acc, cur) => [
        ...acc,
        {
          cmnCd: cur.utmId,
          cmnCdNm: cur.utmNm,
        } as Code,
      ],
      [] as Code[]
    );
  }, [masterData]);

  const selectedMaster = useMemo(() => {
    if (!condition?.utmId) return null;
    const master = (masterData || []).filter((o) => o.utmId === condition?.utmId);
    return master.length > 0 ? master[0] : null;
  }, [condition?.utmId, masterData]);

  useEffect(() => {
    setCondition((prev) => ({
      bildPlntCds:
        locationState?.bildPlntCd || searchParams?.get('bildPlntCd')
          ? [locationState?.bildPlntCd || searchParams?.get('bildPlntCd')]
          : [],
      utmId: locationState?.utmId || searchParams?.get('utmId') || '',
      // planProcId: locationState?.planProcId || searchParams?.get('planProcId') || '',
      openMode: locationState?.openMode || searchParams?.get('openMode') || '',
      sumBaseCd: prev?.sumBaseCd || 'ALL',
    }));
    if (locationState?.utmId || searchParams?.get('utmId')) {
      setRefresh(true);
    }
  }, [locationState?.utmId, locationState?.bildPlntCd]);

  useEffect(() => {
    if (state?.utmId && state?.bildPlntCd) {
      setLocationState(state);
    }
  }, [state]);

  useEffect(() => {
    if (isRefresh && condition.utmId) {
      setRefresh(false);
      handleSearch();
    }
  }, [condition.utmId, isRefresh]);

  useEffect(() => {
    getCommonCodes();
  }, []);

  useEffect(() => {
    if (_.isEmpty(condition?.bildPlntCds)) {
      setMasterData([]);
      return;
    }

    findWithConditionMaster(condition).then((result: UtMatrixMaster[]) => {
      setMasterData(result);
    });
  }, [condition?.bildPlntCds]);

  useEffect(() => {
    setCondition((prev) => ({
      ...prev,
      bildPlntCds: prev.bildPlntCds || [],
      prdnProcCd: '',
      eqclId: '',
    }));
    if (!condition?.utmId) {
      setProcessData([]);
      return;
    }
    searchProcess(condition?.utmId);
  }, [condition?.utmId]);

  const getCommonCodes = async () => {
    const bildPlntCode = await getCommonCodeNames('BILD_PLNT_CD');
    const utmWkProgStatCd: Code[] = await getCommonCodeNames('UTM_WK_PROG_STAT_CD');
    const sumBaseCd: Code[] = await getCommonCodeNames('SUM_BASE_CD');

    setBildPlntCode(bildPlntCode);
    setUtmWkProgStatCd((utmWkProgStatCd || []).filter((o) => o.cmnCd !== 'UTM06'));
    setCode({
      sumBaseCd: sumBaseCd,
    });
  };

  const handleSearch = () => {
    setErrors({
      bildPlntCds: _.isEmpty(condition.bildPlntCds),
      utmId: _.isEmpty(condition.utmId),
      sumBaseCd: _.isEmpty(condition.sumBaseCd),
    });

    if (
      _.isEmpty(condition.bildPlntCds) ||
      _.isEmpty(condition.utmId) ||
      _.isEmpty(condition.sumBaseCd)
    ) {
      return;
    }
    conditionRef.current = {
      ...condition,
      utmWkProgStatCd: utmWkProgInfo?.utmWkProgStatCd,
      utmNo: selectedMaster?.utmNo || '',
    };
    searchUtMatrixReferrer(condition.utmId);
    searchProcessBar(condition.utmId);
    searchSummary(condition);
    searchTabContent();
  };

  useEffect(() => {
    if (conditionRef.current && conditionRef.current?.utmId) {
      searchTabContent();
    }
  }, [currentTabIndex]);

  useEffect(() => {
    if ((processData || []).length) {
      let proc = [] as UtMatrixDetail[];
      if (condition?.prdnProcCd && condition?.eqclId) {
        proc = processData.filter(
          (o) => o.prdnProcCd === condition?.prdnProcCd && o.eqclId === condition?.eqclId
        );
      } else if (condition?.planProcId) {
        proc = processData.filter((o) => o.planProcId === condition?.planProcId);
      }
      if (proc.length) {
        setCondition((prev) => ({
          ...prev,
          planProcId: proc[0].planProcId,
          prdnProcCd: proc[0].prdnProcCd,
          prdnProcCdNm: proc[0].prdnProcCdNm,
          eqclId: proc[0].eqclId,
          eqclNm: proc[0].eqclNm,
          utmWrtProcProgStatCd: proc[0].utmWrtProcProgStatCd,
          utmWrtProcProgStatCdNm: proc[0].utmWrtProcProgStatCdNm,
        }));
      }
    }
  }, [processData, condition?.prdnProcCd, condition?.eqclId, condition?.planProcId]);

  const processSteps = useMemo(() => {
    let currentStepNo = -1;
    return (utmWkProgStatCd || []).reduce((acc, cur, idx) => {
      const isNow = (utmWkProgInfo || {}).utmWkProgStatCd === cur.cmnCd;
      if (isNow) currentStepNo = idx;
      return [
        ...acc,
        {
          ...cur,
          cmnCdDesc: isNow
            ? 'now'
            : Object.keys(utmWkProgInfo || {}).length > 0 && currentStepNo < 0
            ? 'done'
            : '', // or 'disable'
        },
      ];
    }, [] as Code[]);
  }, [utmWkProgStatCd, utmWkProgInfo]);

  const searchUtMatrixReferrer = (utmId) => {
    findUtMatrixReferrer(utmId).then((result) => {
      setReferrerRowData(result);
    });
  };

  const searchProcessBar = (utmId?: string) => {
    if (!utmId) return;
    findProcessBar(utmId).then((result) => {
      setUtmWkProgInfo(result);
      setCondition((prev) => ({
        ...prev,
        utmWkProgStatCd: result?.utmWkProgStatCd,
      }));
      // 자동검색 시점에 상태값이 없기때문에 별도로 추가
      conditionRef.current = {
        ...condition,
        utmWkProgStatCd: result?.utmWkProgStatCd,
        utmNo: selectedMaster?.utmNo || '',
      };
    });
  };

  const searchProcess = (utmId?: string) => {
    return new Promise((resolve) => {
      if (!utmId) {
        resolve(true);
        return;
      }
      findProcess(utmId)
        .then((result: UtMatrixDetail[]) => {
          if (result !== null) {
            setProcessData(result);
          } else {
            openMessageBar({
              type: 'error',
              content: t(
                'ut.label.공정 정보 조회 중 오류가 발생했습니다.',
                '공정 정보 조회 중 오류가 발생했습니다.'
              ),
            });
          }
        })
        .finally(() => resolve(true));
    });
  };

  const searchTabContent = () => {
    if ((tabMenus || []).length) {
      if (tabMenus[currentTabIndex].id === 'UTM') {
        detailRef.current?.searchRegistMatrix(conditionRef.current);
      } else if (tabMenus[currentTabIndex].id === 'MACH') {
        machineRef.current?.searchMachineSummary(conditionRef.current);
      } else if (tabMenus[currentTabIndex].id === 'ELTR') {
        elecRef.current?.searchElectricSummary(conditionRef.current);
      } else if (tabMenus[currentTabIndex].id === 'CAPA') {
        atulCapaRef.current?.searchStandardUse(conditionRef.current);
      }
    }
  };

  const navigate = useNavigate();

  //보류 링크
  const handleRequestLinkHold = async () => {
    if (!conditionRef.current?.utmId) return;
    navigate('/ut/ut-matrix-request', {
      state: { searchParam: { utmId: summaryRowData[0].utmId, mode: 'HOLD' } },
    });
  };
  //재작성 요청 링크
  const handleRequestLinkRewrite = async () => {
    if (!conditionRef.current?.utmId) return;
    navigate('/ut/ut-matrix-request', {
      state: { searchParam: { utmId: summaryRowData[0].utmId, mode: 'REWRITE' } },
    });
    //결재요청1
  };

  // 검증
  const handleReview = async () => {
    if (!conditionRef.current?.utmId) return;
    submitCheckRegistMatrix(conditionRef.current?.utmId, undefined).then((result) => {
      if (result) {
        openCommonModal({
          modalType: 'alert',
          content: result,
          size: 'md',
        });
      } else {
        // submitUtMatrix();
        openMessageBar({
          type: 'confirm',
          content: t('com.msg.검증되었습니다.', '검증되었습니다.'),
        });
      }
    });
  };

  const submitUtMatrix = () => {
    if (!conditionRef.current?.utmId) return;
    submitUtMatix(conditionRef.current).then((res) => {
      if (res?.successOrNot === 'Y') {
        searchProcess(conditionRef.current?.utmId).then(() => handleSearch());
      }
      openMessageBar({
        type: res?.successOrNot === 'Y' ? 'confirm' : 'error',
        content:
          res?.successOrNot === 'Y'
            ? t('com.msg.검증되었습니다.', '검증되었습니다.')
            : t('com.msg.검증 중 오류가 발생했습니다.', '검증 중 오류가 발생했습니다.'),
      });
    });
  };

  // 검토진행
  const handleSubmitReview = async () => {
    if (!conditionRef.current?.utmId) return;

    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.검토진행 하시겠습니까?', '검토진행 하시겠습니까?'),
      yesCallback: () => {
        changeStatUtMatix({ ...conditionRef.current, utmWrtProcProgStatCd: 'UTP04' }).then(
          (res) => {
            if (res?.successOrNot === 'Y') {
              searchProcess(conditionRef.current?.utmId).then(() => handleSearch());
            }
            openMessageBar({
              type: res?.successOrNot === 'Y' ? 'confirm' : 'error',
              content:
                res?.successOrNot === 'Y'
                  ? t('ut.label.검토중 상태로 변경되었습니다.', '검토중 상태로 변경되었습니다.')
                  : t('ut.label.수정 중 오류가 발생했습니다.', '수정 중 오류가 발생했습니다.'),
            });
          }
        );
      },
    });
  };

  //결재 요청
  const handleApproval_temp = () => {
    if (!conditionRef.current?.utmId) return;
    //결재요청

    openCommonModal({
      modalType: 'confirm',
      content: t('ut.label.확정완료 하시겠습니까?', '확정완료 하시겠습니까?'),
      yesCallback: () => {
        changeStatUtMatix({ ...conditionRef.current, utmWrtProcProgStatCd: 'UTP06' }).then(
          (res) => {
            if (res?.successOrNot === 'Y') {
              searchProcess(conditionRef.current?.utmId).then(() => handleSearch());
            }
            openMessageBar({
              type: res?.successOrNot === 'Y' ? 'confirm' : 'error',
              content:
                res?.successOrNot === 'Y'
                  ? t('ut.label.확정완료 상태로 변경되었습니다.', '확정완료 상태로 변경되었습니다.')
                  : t('ut.label.오류가 발생했습니다.', '오류가 발생했습니다.'),
            });
          }
        );
      },
    });
  };

  const [isOpenRequestModal, setOpenRequestModal] = useState<boolean>(false);
  const [requestModalCondition, setRequestModalCondition] = useState<any>();
  /**
   * 결재요청
   */
  const handleApproval = () => {
    setRequestModalCondition({
      pageId: ApproveRequestPageType.UT_REVIEW_REQ,
      mode: ManagementMode.READ,
      requestMasterList: summaryRowData[0],
    });
    setOpenRequestModal(true);
  };

  const [summaryRowData, setSummaryRowData] = useState<UtMatrixSummaryList[]>([]);
  const [referrerRowData, setReferrerRowData] = useState<Employee[]>([]);

  const onInitializedSummary = (grid) => {
    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;
      const ht = grid.hitTest(e);

      if (ht.row < 0 || ht.col < 0) return;
      if (ht.panel.cellType != 1) return;

      if (e.target instanceof HTMLButtonElement) {
        const col = grid.columns[ht.col]; // 선택한 컬럼의 정보
        const item = grid.rows[ht.row].dataItem; // 선택한 row의 정
        switch (e.target.id) {
          case 'btnMoveGraph':
            setOpenGraphModal(true);
            setUtmData(item);
            break;
        }
      }
    });
  };

  const searchSummary = async (condition) => {
    if (!condition) return;

    getUtMatrixSummaryList(condition.utmId).then((result) => {
      setSummaryRowData(result);
    });
  };

  const layoutDefinitionSummary = [
    {
      binding: 'utmId',
      header: String(t('ut.label.UTM ID', 'UTM ID')),
      visible: true,
      isReadOnly: true,
      align: 'center',
    },
    {
      binding: 'bildPlntCdNm',
      header: String(t('ut.label.공장', '공장')),
      width: 100,
      align: 'center',
      isReadOnly: true,
    },
    {
      binding: 'bildPlntCd',
      visible: false,
    },
    {
      binding: 'utmNo',
      header: String(t('ut.label.UT Matrix 번호', 'UT Matrix 번호')),
      width: 150,
      align: 'center',
      isReadOnly: true,
    },
    {
      binding: 'utmNm',
      header: String(t('ut.label.UT Matrix 명', 'UT Matrix 명')),
      width: 180,
      isReadOnly: true,
    },
    {
      binding: 'verNo',
      header: String(t('ut.label.버전', '버전')),
      width: 80,
      align: 'center',
      isReadOnly: true,
    },
    {
      binding: 'lineQty',
      header: String(t('ut.label.Line 수', 'Line 수')),
      width: 110,
      align: 'center',
      isReadOnly: true,
    },
    {
      binding: 'graph',
      header: String(t('ut.label.Graph', 'Graph')),
      width: 80,
      align: 'center',
      isReadOnly: true,
      cssClass: 'WijmoPlay',
      cellTemplate: '<button id="btnMoveGraph"></button>',
    },
    {
      binding: 'elec',
      header: String(t('ut.label.전기[KW]', '전기[kW]')),
      width: 140,
      align: 'right',
      isReadOnly: true,
      cellTemplate: (params) => Number(params.value || 0).toLocaleString(),
    },
    {
      binding: 'cda',
      header: String(t('ut.label.CDA[l/min]', 'CDA[ℓ/min]')),
      width: 140,
      align: 'right',
      isReadOnly: true,
      cellTemplate: (params) => Number(params.value || 0).toLocaleString(),
    },
    {
      binding: 'n2',
      header: String(t('ut.label.N2[l/min]', 'N2[ℓ/min]')),
      width: 140,
      align: 'right',
      isReadOnly: true,
      cellTemplate: (params) => Number(params.value || 0).toLocaleString(),
    },
    {
      binding: 'iw',
      header: String(t('ut.label.IW[l/min]', 'IW[ℓ/min]')),
      width: 140,
      align: 'right',
      isReadOnly: true,
      cellTemplate: (params) => Number(params.value || 0).toLocaleString(),
    },
    {
      binding: 'iw2',
      header: String(t('ut.label.IW2[l/min]', 'FW[ℓ/min]')),
      width: 140,
      align: 'right',
      isReadOnly: true,
      cellTemplate: (params) => Number(params.value || 0).toLocaleString(),
    },
    {
      binding: 'pcw',
      header: String(t('ut.label.PCW[l/min]', 'PCW[ℓ/min]')),
      width: 140,
      align: 'right',
      isReadOnly: true,
      cellTemplate: (params) => Number(params.value || 0).toLocaleString(),
    },
    {
      binding: 'ww',
      header: String(t('ut.label.WW[l/min]', 'WW[ℓ/min]')),
      width: 140,
      align: 'right',
      isReadOnly: true,
      cellTemplate: (params) => Number(params.value || 0).toLocaleString(),
    },
    {
      binding: 'steam',
      header: String(t('ut.label.Steam[Ton/hr]', 'Steam[Ton/hr]')),
      width: 150,
      align: 'right',
      isReadOnly: true,
      cellTemplate: (params) => Number(params.value || 0).toLocaleString(),
    },
    {
      binding: 'ng',
      header: String(t('ut.label.NG[N㎥/hr]', 'NG[N㎥/hr]')),
      width: 150,
      align: 'right',
      isReadOnly: true,
      cellTemplate: (params) => Number(params.value || 0).toLocaleString(),
    },
    {
      binding: 'exRetrun',
      header: String(t('ut.label.Return air[CMH]', 'Return air[CMH]')),
      width: 150,
      align: 'right',
      isReadOnly: true,
      cellTemplate: (params) => Number(params.value || 0).toLocaleString(),
    },
    {
      binding: 'exVent',
      header: String(t('ut.label.Exhaust air[CMH]', 'Exhaust air[CMH]')),
      width: 150,
      align: 'right',
      isReadOnly: true,
      cellTemplate: (params) => Number(params.value || 0).toLocaleString(),
    },
    {
      binding: 'sa',
      header: String(t('ut.label.Supply Air', 'Supply air')),
      width: 150,
      align: 'right',
      isReadOnly: true,
      cellTemplate: (params) => Number(params.value || 0).toLocaleString(),
    },
  ];

  return (
    <>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows>
              <SearchCols className="width25">
                <label>
                  <span className="dot">{t('ut.label.공장', '공장')}</span>
                </label>
                <MultiComboBox
                  id="bildPlntCd"
                  options={bildPlntCode || []}
                  placeholder={String(t('com.label.선택', '선택'))}
                  defaultValue={condition?.bildPlntCds || []}
                  onChange={(values: string[]) => {
                    setCondition((prev) => ({
                      ...prev,
                      bildPlntCds: values,
                    }));
                    setErrors((prev) => ({
                      ...prev,
                      bildPlntCds: false,
                    }));
                    setRefresh(true);
                  }}
                  isError={errors?.bildPlntCds}
                  msgError={String(t('ut.label.공장을 선택해 주세요.', '공장을 선택해 주세요.'))}
                  className="width25"
                />
              </SearchCols>
              <SearchCols className="width25">
                <label>
                  <span className="dot">{t('ut.label.UT Matrix 명', 'UT Matrix 명')}</span>
                </label>
                <ComboBox
                  placeholder={String(t('com.label.선택', '선택'))}
                  options={optionsUtMaster}
                  defaultValue={condition?.utmId}
                  onChange={(value) => {
                    setCondition((prev) => ({
                      bildPlntCds: prev?.bildPlntCds || [],
                      utmId: value,
                      openMode: prev?.openMode || '',
                      sumBaseCd: prev?.sumBaseCd || 'ALL',
                    }));
                    setErrors((prev) => ({
                      ...prev,
                      utmId: false,
                    }));
                    setRefresh(true);
                  }}
                  isError={errors?.utmId}
                  msgError={String(
                    t('ut.label.UT Matrix 명을 선택해 주세요.', 'UT Matrix 명을 선택해 주세요.')
                  )}
                />
              </SearchCols>
              <SearchCols className="width25">
                <label>{t('ut.label.UT Matrix 번호', 'UT Matrix 번호')}</label>
                <CustomInputWithSearch
                  name="utmNo"
                  value={selectedMaster?.utmNo || ''}
                  readOnly={true}
                  isWrap={false}
                />
              </SearchCols>
              <SearchCols className="width25">
                {/*Tab이 기계요약/ 전기요약일 경우*/}
                <label>{t('ut.label.집계기준', '집계기준')}</label>
                <ComboBox
                  options={code?.sumBaseCd || []}
                  defaultValue={condition?.sumBaseCd}
                  isWrap={false}
                  onChange={(value) => {
                    setCondition({
                      ...condition,
                      sumBaseCd: value,
                    });
                  }}
                />
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchBoxButtons setReset={setCondition} onSearch={handleSearch} />
        </SearchBoxRow>
      </SearchBox>

      <ProcessBarWrap className="mt24">
        {(processSteps || []).map((o, i) => (
          <ProcessStepBox key={o.cmnCd} className={o.cmnCdDesc}>
            <StepBox>
              <ProcessStep>
                <ProcessNum>{i + 1}</ProcessNum>
                <ProcessText>{o.cmnCdNm}</ProcessText>
                {o.cmnCd === 'UTM02' && o.cmnCd === utmWkProgInfo?.utmWkProgStatCd ? (
                  <SubStep className="subPer">
                    <li>
                      <strong>{utmWkProgInfo?.procPer || 0}%</strong>
                      <span>{t('ut.label.진행', '진행')}</span>
                      <div>
                        <span
                          style={{
                            width: `${utmWkProgInfo?.procPer || 0}%`,
                          }}
                        ></span>
                      </div>
                    </li>
                    <li>
                      (<em>{utmWkProgInfo.cmplCnt}</em>/{utmWkProgInfo.totalCnt})
                    </li>
                    <li>
                      <span>{t('ut.label.지연', '지연')}</span> {utmWkProgInfo.delayCnt}
                    </li>
                  </SubStep>
                ) : null}
              </ProcessStep>
            </StepBox>
          </ProcessStepBox>
        ))}
        <ProgressBar></ProgressBar>
      </ProcessBarWrap>

      {/* summary grid start */}
      <div>
        <CustomGrid
          layoutDefinition={layoutDefinitionSummary}
          rowData={summaryRowData}
          initialized={onInitializedSummary}
          isFilter={false}
          isSelector={false}
          height={100}
          frozenColumns={1}
          excludePin={[
            'graph',
            'elec',
            'cda',
            'n2',
            'iw',
            'iw2',
            'pcw',
            'ww',
            'steam',
            'ng',
            'exRetrun',
            'exVent',
            'sa',
          ]}
        />
      </div>
      {/* summary grid end */}

      <div css={tabs.wrap} style={{ marginTop: '24px' }}>
        <Tabs
          value={currentTabIndex}
          onChange={(e, newValue) => {
            // 이전 탭이 Ut Matrix인 경우 자동 저장 후 탭변경
            if (currentTabIndex === 0) {
              detailRef.current?.saveRegistMatrix().finally(() => {
                setCurrentTabIndex(newValue);
              });
            } else {
              setCurrentTabIndex(newValue);
            }
          }}
          css={tabs.tab}
        >
          {tabMenus.map((o, idx) => (
            <Tab
              key={o.id}
              label={o.name}
              id={`ut-tab-${idx}`}
              aria-controls={`ut-tabpanel-${idx}`}
            />
          ))}
        </Tabs>
      </div>

      <TabPanel value={currentTabIndex} index={currentTabIndex}>
        {currentTabIndex === 0 ? (
          <DetailSummaryContent
            ref={detailRef}
            condition={conditionRef.current}
            processData={processData}
            onSubmit={handleSubmitReview}
            onModify={handleReview}
            onAppr={handleApproval}
            onLinkHold={handleRequestLinkHold}
            onLinkRewrite={handleRequestLinkRewrite}
          />
        ) : currentTabIndex === 1 ? (
          <MachineSummaryContent
            ref={machineRef}
            condition={conditionRef.current}
            processData={processData}
            onSubmit={handleSubmitReview}
            onModify={handleReview}
            onAppr={handleApproval}
            onLinkHold={handleRequestLinkHold}
            onLinkRewrite={handleRequestLinkRewrite}
          />
        ) : currentTabIndex === 2 ? (
          <ElectricSummaryContent
            ref={elecRef}
            condition={conditionRef.current}
            processData={processData}
            onSubmit={handleSubmitReview}
            onModify={handleReview}
            onAppr={handleApproval}
            onLinkHold={handleRequestLinkHold}
            onLinkRewrite={handleRequestLinkRewrite}
          />
        ) : (
          <StandardUseContent
            ref={atulCapaRef}
            condition={conditionRef.current}
            processData={processData}
            onSubmit={handleSubmitReview}
            onModify={handleReview}
            onAppr={handleApproval}
            onLinkHold={handleRequestLinkHold}
            onLinkRewrite={handleRequestLinkRewrite}
          />
        )}
      </TabPanel>
      {isOpenRequestModal && (
        <ApproveRequestModal
          open={isOpenRequestModal}
          close={() => setOpenRequestModal(false)}
          pageId={ApproveRequestPageType.UT_REVIEW_REQ}
          aprReqId={summaryRowData[0].aprReqId}
          condition={{
            utmId: condition.utmId,
            // requestMaster: summaryRowData[0],
            mode: requestModalCondition?.mode,
            approvalInfr: referrerRowData,
          }}
        />
      )}

      {isOpenGraphModal && (
        <UtMatrixGraphPopup
          open={isOpenGraphModal}
          close={() => setOpenGraphModal(false)}
          condition={utmData}
        />
      )}
    </>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(props: { [x: string]: any; children: any; value: any; index: any }) {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`ut-tabpanel-${index}`}>
      {value === index && <div>{children}</div>}
    </div>
  );
}

export default UtMatrixReviewPage;
