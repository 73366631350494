/** @jsxImportSource @emotion/react */
import React from 'react';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { di, TitleWrap } from 'components/layouts/Dialog';
import { ImgBox } from 'components/layouts/Board';
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  close: () => void;
};

/**
 * @param props
 * @constructor
 */
export const SetupDashboardLegendPopup = ({ open, close }: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} css={di.dialog} fullWidth className="md" maxWidth={false}>
      <TitleWrap>
        <h2>{t('pjm.label.범례', '범례')}</h2>
        <Button onClick={() => close()} disableRipple />
      </TitleWrap>
      <DialogContent>
        <ImgBox>
          <img src="/assets/setupDashboardLegend.png"></img>
        </ImgBox>
      </DialogContent>
    </Dialog>
  );
};
