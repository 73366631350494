/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { IconButton } from 'components/buttons/IconSVG';
import { tb } from 'components/layouts/Table';
import { ControlBtnGroup, SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import CustomDialog from 'components/modals/common/CustomDialog';
import CustomGrid from 'components/grids/CustomGrid';
import { getExcelFileName } from 'utils/ExcelUtil';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { SetupMaster, SetupTgtEqpSchedule } from 'models/pjm/SetupScheduleRegistDetail';
import { getInpectionHistoryMasterData } from 'apis/pjm/SetupScheduleRegist';

type Props = {
  open: boolean;
  close: () => void;
  condition: any;
  onCallback: (result) => void;
};

const FacilityInspectionHistoryPopup = ({ open, close, condition, onCallback }: Props) => {
  const [flexRef, setFlexRef] = useState<any>();
  const { t } = useTranslation();
  const [masterData, setMasterData] = useState<SetupMaster>();
  const [masterParam, setMasterParam] = useState<SetupMaster>();
  const [inspectionHistoryRowData, setInspectionHistoryRowData] = useState<SetupTgtEqpSchedule[]>(
    []
  );

  useEffect(() => {
    if (condition.elmPrjId !== null && condition.elmPrjId !== undefined) {
      setMasterParam(condition);
      getInpectionHistoryList(condition);
    }
  }, [condition]);

  const getInpectionHistoryList = async (condition) => {
    await getInpectionHistoryMasterData(condition).then((result) => {
      setMasterData(result || {});
      setInspectionHistoryRowData(result?.setupTgtEqpSchedule || []);
    });
  };

  const onInitialized = (grid) => {
    setFlexRef(grid);
  };

  const inspectionHistoryLayoutDefinition = [
    {
      header: String(t('pjm.label.관리항목', '관리항목')),
      align: 'center',
      columns: [
        {
          binding: 'crtnYm',
          header: String(t('pjm.label.기준년월', '기준년월')),
          width: 120,
          isReadOnly: true,
          align: 'center',
        },
        {
          binding: 'perdWct',
          header: String(t('pjm.label.기간차수', '기간차수')),
          width: 100,
          isReadOnly: true,
          align: 'center',
        },
        {
          binding: 'startDate',
          header: String(t('pjm.label.시작일자', '시작일자')),
          width: 110,
          isReadOnly: true,
          align: 'center',
        },
        {
          binding: 'endDate',
          header: String(t('pjm.label.종료일자', '종료일자')),
          width: 110,
          isReadOnly: true,
          align: 'center',
        },
      ],
    },
    {
      binding: 'isptYn',
      header: String(t('pjm.label.점검여부', '점검여부')),
      isReadOnly: true,
      align: 'center',
      width: 90,
    },
    {
      binding: 'isptCtn',
      header: String(t('pjm.label.점검내용', '점검내용')),
      isReadOnly: true,
      align: 'left',
      width: '*',
    },
    {
      binding: 'isptUserNm',
      header: String(t('pjm.label.점검자', '점검자')),
      isReadOnly: true,
      align: 'center',
    },
    {
      binding: 'isptDt',
      header: String(t('pjm.label.점검일자', '점검일자')),
      isReadOnly: true,
      align: 'center',
    },
  ];

  const btnInsHistoryExcelExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = 'setup';
    book.saveAsync(getExcelFileName(t('pjm.label.Setup_점검이력_List', 'Setup_점검이력_List')));
  };

  return (
    <CustomDialog
      title={t('pjm.label.Setup 주간 점검이력', '주간 점검이력')}
      size="lg"
      open={open}
      onClose={close}
    >
      <TableContainer css={tb.table} style={{ marginBottom: '24px' }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <span>{t('pjm.label.프로젝트 ID', '프로젝트 ID')}</span>
              </TableCell>
              <TableCell>
                <span>{masterData?.elmPrjId}</span>
                {/* <CustomInputWithSearch name="itemEngNm" value={mpItem?.itemEngNm} /> */}
              </TableCell>
              <TableCell>
                <span>{t('pjm.label.프로젝트 명', '프로젝트 명')}</span>
              </TableCell>
              <TableCell colSpan={5}>
                <span>{masterData?.elmPrjNm}</span>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span>{t('pjm.label.Factory', 'Factory')}</span>
              </TableCell>
              <TableCell>
                <span>{masterData?.prdnPldoCdNm}</span>
              </TableCell>
              <TableCell>
                <span>{t('pjm.label.공정', '공정')}</span>
              </TableCell>
              <TableCell>
                <span>{masterData?.prdnProcCdNm}</span>
              </TableCell>
              <TableCell>
                <span>{t('pjm.label.설비군', '설비군')}</span>
              </TableCell>
              <TableCell>
                <span>{masterData?.eqclId}</span>
              </TableCell>
              <TableCell>
                <span>{t('pjm.label.Line A/C', 'Line A/C')}</span>
              </TableCell>
              <TableCell>
                <span>{masterData?.eltrTpCdNm}</span>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span>{t('pjm.label.Line 호기', 'Line 호기')}</span>
              </TableCell>
              <TableCell>
                <span>{masterData?.eqpLnId}</span>
              </TableCell>
              <TableCell>
                <span>{t('pjm.label.Machine', 'Machine')}</span>
              </TableCell>
              <TableCell>
                <span>{masterData?.eqpId}</span>
              </TableCell>
              <TableCell>
                <span>{t('pjm.label.단계', '단계')}</span>
              </TableCell>
              <TableCell>
                <span>{masterParam?.prjSpCdNm}</span>
              </TableCell>
              <TableCell>
                <span>{t('pjm.label.계획일정', '계획일정')}</span>
              </TableCell>
              <TableCell>
                <span>{masterParam?.planEndDt}</span>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span>{t('pjm.label.기준년월', '기준년월')}</span>
              </TableCell>
              <TableCell>
                <span>{masterParam?.crtnYm}</span>
              </TableCell>
              <TableCell>
                <span>{t('pjm.label.기간차수', '기간차수')}</span>
              </TableCell>
              <TableCell>
                <span>{masterParam?.perdWct}</span>
              </TableCell>
              <TableCell>
                <span>{t('pjm.label.점검일정', '점검일정')}</span>
              </TableCell>
              <TableCell>
                <span>{masterParam?.isptDt}</span>
              </TableCell>
              <TableCell>
                <span>{t('pjm.label.점검여부', '점검여부')}</span>
              </TableCell>
              <TableCell>
                <span>{masterParam?.isptYn}</span>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span>{t('pjm.label.점검자', '점검자')}</span>
              </TableCell>
              <TableCell>
                <span>{masterParam?.isptUserNm}</span>
                {/* <CustomInputWithSearch name="itemEngNm" value={mpItem?.itemEngNm} /> */}
              </TableCell>
              <TableCell>
                <span>{t('pjm.label.점검내용', '점검내용')}</span>
              </TableCell>
              <TableCell colSpan={5}>
                <span>{masterParam?.isptCtn}</span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('pjm.label.점검이력 List', '점검이력 List')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{inspectionHistoryRowData?.length ?? 0}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={btnInsHistoryExcelExport}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={inspectionHistoryLayoutDefinition}
        rowData={inspectionHistoryRowData}
        initialized={onInitialized}
        height={300}
        isSelector={false}
        isReadOnly={true}
        isFilter={false}
        allowPinning={false}
      />
    </CustomDialog>
  );
};

export default FacilityInspectionHistoryPopup;
