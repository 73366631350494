import { CommonRequest, Method, ServiceName, CommonResponse } from 'models/common/RestApi';
import {
  FacilitySchedule,
  FacilityScheduleCondition,
  FacilityScheduleHistory,
} from 'models/pjm/FacilityScheduleRegist';
import { callApi } from 'utils/ApiUtil';

export const findFacilityScheduleChangeMaster = async (condition?: FacilityScheduleCondition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/facility/schedule-change/master`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };
  const response: CommonResponse<FacilitySchedule> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as FacilitySchedule;
};

export const findFacilityScheduleChangeHistory = async (condition?: FacilityScheduleCondition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/facility/schedule-change/history`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };
  const response: CommonResponse<FacilityScheduleHistory[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as FacilityScheduleHistory[];
};

export const saveFacilityScheduleChange = async (history: FacilityScheduleHistory) => {
  const request: CommonRequest = {
    method: Method.PUT,
    url: `/v1/facility/schedule-change`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: history,
  };

  const response: CommonResponse<string> = await callApi(request);
  return response;
};
