/** @jsxImportSource @emotion/react */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Button } from '@mui/material';
import { Checkbox } from '@mui/material';
import { DatePickerWrap } from 'components/inputs/DatePicker';
import { ContainerLayout } from 'components/layouts/ContainerLayout';
import {
  SubTitleLayout,
  SubTitleGroup,
  ControlBtnGroup,
  GlobalBtnGroup,
} from 'components/layouts/ContentLayout';
import {
  SearchBox,
  SearchBoxRow,
  InputBox,
  SearchRows,
  SearchCols,
  SearchButtonWrap,
} from 'components/layouts/SearchBox';
import {
  ProcessBarWrap,
  ProcessStepBox,
  StepBox,
  ProcessStep,
  ProcessNum,
  ProcessText,
  SubStep,
  ProgressBar,
} from 'components/process/Process';
import Datepicker from 'react-tailwindcss-datepicker';
import { IconButton } from 'components/buttons/IconSVG';
import { useTranslation } from 'react-i18next';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { MultiComboBox } from 'components/selects/ComboBox';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import UserModal from 'components/modals/common/UserModal';
import { Employee } from 'models/admin/Employee';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'hooks/useMessageBar';
import CustomGrid from 'components/grids/CustomGrid';
import { SearchParam, UtMatrixDetailList, UtMatrixMasterList } from 'models/ut/UtMatrixList';
import {
  deleteUtMatrixMaster,
  getUtMatrixDetailList,
  getUtMatrixMasterList,
  saveUtMatrixWrtUser,
  updateUtMatrixMasterAtchFileGrId,
} from 'apis/ut/UtMatrixList';
import { getExcelFileName } from 'utils/ExcelUtil';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';
import { CellType, DataMap } from '@grapecity/wijmo.grid';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toggleClass } from '@grapecity/wijmo';
import { Code } from 'models/common/CommonCode';
import moment from 'moment';
import useSessionStore from 'stores/useSessionStore';
import { hasRole } from 'utils/SessionUtil';
import { st } from 'components/inputs/CustomInput';
import { UtMatrixDetail } from 'models/ut/UtMatrix';
import { ApproveRequestPageType } from 'pages/approves/ApproveRequestDetailCase';
import ApproveRequestModal from 'pages/approves/ApproveRequestModal';
import { ManagementMode } from 'models/common/Common';
import { findUtMatrixReferrer } from 'apis/ut/UtMatrixReview';
import { getUtMatrixSummaryList } from 'apis/ut/UtMatrixSummary';
import { UtMatrixSummaryList } from 'models/ut/UtMatrixSummary';
import { GatingContListPopUp } from 'pages/gtng/popup/GatingContListPopUp';
import UserMultiModal from 'components/modals/common/UserMultiModal';
import { CrudCode } from 'models/common/Edit';
import { GridStatusCellTemplate } from 'components/grids/GridStatusCellRenderer';
import useEvent from 'react-use-event-hook';
import UtMatrixHookupTagPopup from './popup/UtMatrixHookupTagPopup';
import FileUploadPopUp from 'pages/common/components/FileUploadPopUp';
import { findProcessBar } from 'apis/ut/UtMatrixRegist';
import { CommonUtil } from '../../utils/CommonUtil';

interface PageLocationState {
  utmNo?: string;
}

const UtMatrixListPage = () => {
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();
  const { openCommonModal } = useCommonModal();
  const navigate = useNavigate();
  const userSession = useSessionStore();
  const [searchParams] = useSearchParams();

  const gridRef = useRef<any>();
  const [flexRef, setFlexRef] = useState<any>();
  const [flexItem, setFlexItem] = useState<any>();
  const [detailFlexItem, setDetailFlexItem] = useState<any>();

  const [masterRowData, setMasterRowData] = useState<UtMatrixMasterList[]>([]);
  const [detailRowData, setDetailRowData] = useState<UtMatrixDetailList[]>([]);

  const [utmWkProgInfo, setUtmWkProgInfo] = useState<UtMatrixDetail>();
  const [atchFileUtmId, setAtchFileUtmId] = useState<string>('');

  const [bildPlntCode, setBildPlntCode] = useState<Code[]>([]);
  const [utmWkProgStatCode, setUtmWkProgStatCode] = useState<Code[]>([]);
  const progressStatBarCode = utmWkProgStatCode.filter((item) => item.cmnCd !== 'UTM06');

  const today = moment().format('YYYY-MM-DD');
  const bfTwoMonth = moment().subtract(2, 'months');
  const startMonth = bfTwoMonth.startOf('month').format('YYYY-MM-DD');

  const [progressNum, setProgressNum] = useState<number>(0);
  const [userNm, setUserNm] = useState<string>('');
  const [userIds, setUserIds] = useState<string>('');
  const initSearchParam = {
    bildPlntCds: [],
    // askPurpCds: [],
    utmWkProgStatCds: [],
    curYn: 'N',
    startDate: startMonth,
    endDate: today,
  };
  const [searchParam, setSearchParam] = useState<SearchParam>(initSearchParam);
  const { state } = useLocation();

  const [locationState, setLocationState] = useState<PageLocationState>(useLocation().state);

  const [mode, setMode] = useState<string>('');
  const [isOpenUserModal, setOpenUserModal] = useState<boolean>(false);
  const [isOpenUserMultiModal, setOpenUserMultiModal] = useState<boolean>(false);
  const [isOpenRequestModal, setOpenRequestModal] = useState<boolean>(false);
  const [isOpenRequestReadModal, setOpenRequestReadModal] = useState<boolean>(false);
  const [isOpenUserReadModal, setOpenUserReadModal] = useState<boolean>(false);
  const [isOpenHookupTaggingModal, setOpenHookupTaggingModal] = useState<boolean>(false);

  const [fileUploadModalCondition, setFileUploadModalCondition] = useState<any>({});
  const [isOpenFileUploadModal, setOpenFileUploadModal] = useState<boolean>(false);

  const [requestModalCondition, setRequestModalCondition] = useState<any>();
  const [referrerRowData, setReferrerRowData] = useState<Employee[]>([]);
  const [summaryRowData, setSummaryRowData] = useState<UtMatrixSummaryList[]>([]);

  const [rowItem, setRowItem] = useState<any>();
  const [hasAuth, setHasAuth] = useState<boolean>(false);

  const [hookupTaggingCondition, setHookupTaggingCondition] = useState<any>();
  const [utLineQty, setUtLineQty] = useState<Code[]>([]);

  useEffect(() => {
    initCondition();
    if (hasRole('ADM') || hasRole('UT_MANAGER')) {
      setHasAuth(true);
    }
    btnSearch();
  }, []);

  useEffect(() => {
    setSearchParam((prev) => ({
      ...prev,
      utmNo: locationState?.utmNo || prev?.utmNo || searchParams?.get('utmNo') || '',
      bildPlntCds: locationState?.utmNo ? [] : prev?.bildPlntCds || [],
      askPurpCds: locationState?.utmNo ? [] : prev?.askPurpCds || [],
      utmWkProgStatCds: locationState?.utmNo ? [] : prev?.utmWkProgStatCds || [],
    }));
    if (locationState?.utmNo) {
      btnSearch();
    }
  }, [locationState?.utmNo]);

  useEffect(() => {
    if (state?.utmNo) {
      setLocationState(state);
    }
  }, [state]);

  const initCondition = async () => {
    const bildPlntCode = await getCommonCodeNames('BILD_PLNT_CD');
    const utmWkProgStatCode = await getCommonCodeNames('UTM_WK_PROG_STAT_CD');
    const utLineQty: Code[] = await getCommonCodeNames('UT_LINE_QTY');
    setBildPlntCode(bildPlntCode);
    setUtmWkProgStatCode(utmWkProgStatCode);
    setUtLineQty(utLineQty); // 라인수량
  };

  const fnSearchUtmNo = (params: any) => {
    const utmLink = params.utmLink;
    const utmId = params.utmId;
    const bildPlntCd = params.bildPlntCd;
    const utmWkProgStatCd = params.utmWkProgStatCd;
    switch (utmWkProgStatCd) {
      case 'UTM01': // 요청전
        navigate('/ut/ut-matrix-request', {
          state: {
            searchParam: {
              utmId: utmId,
              bildPlntCd: bildPlntCd,
              mode: 'DRAFT',
            },
          },
        });
        break;
      case 'UTM02': // 작성중
        navigate('/ut/ut-matrix-request', {
          state: {
            searchParam: {
              utmId: utmId,
              bildPlntCd: bildPlntCd,
              mode: 'UTM02',
            },
          },
        });
        break;
      case 'UTM03': // 작성완료
        navigate('/ut/ut-matrix-request', {
          state: {
            searchParam: {
              utmId: utmId,
              bildPlntCd: bildPlntCd,
              mode: 'READ',
            },
          },
        });
        break;
      case 'UTM04': // 검토중
        navigate('/ut/ut-matrix-request', {
          state: {
            searchParam: {
              utmId: utmId,
              bildPlntCd: bildPlntCd,
              mode: 'HOLD',
            },
          },
        });
        break;
      case 'UTM05': // 확정완료
        navigate('/ut/ut-matrix-request', {
          state: {
            searchParam: {
              utmId: utmId,
              bildPlntCd: bildPlntCd,
              mode: 'READ',
            },
          },
        });
        break;
      case 'UTM06': // 보류
        navigate('/ut/ut-matrix-request', {
          state: {
            searchParam: {
              utmId: utmId,
              bildPlntCd: bildPlntCd,
              mode: 'HOLD',
            },
          },
        });
        break;
    }
  };

  const fnSearchUtmNm = (params: any) => {
    const utmLink = params.utmLink;
    const utmId = params.utmId;
    const bildPlntCd = params.bildPlntCd;
    switch (utmLink) {
      case 'R-DRAFT':
        navigate('/ut/ut-matrix-request', {
          state: {
            searchParam: {
              utmId: utmId,
              bildPlntCd: bildPlntCd,
              mode: 'DRAFT',
            },
          },
        });
        break;
      case 'R-READ':
        navigate('/ut/ut-matrix-request', {
          state: {
            searchParam: {
              utmId: utmId,
              bildPlntCd: bildPlntCd,
              mode: 'READ',
            },
          },
        });
        break;
      case 'W-WRITE':
        navigate('/ut/regist-management/regist', {
          state: { utmId: utmId, bildPlntCd: bildPlntCd, openMode: 'WRITE' },
        });
        break;
      case 'W-READ':
        navigate('/ut/regist-management/regist', {
          state: { utmId: utmId, bildPlntCd: bildPlntCd, openMode: 'READ' },
        });
        break;
      case 'I-UT_MGR':
        navigate('/ut/regist-management/review', {
          state: { utmId: utmId, bildPlntCd: bildPlntCd, mode: 'UT_MGR' },
        });
        break;
      case 'I-READ':
        navigate('/ut/regist-management/review', {
          state: { utmId: utmId, bildPlntCd: bildPlntCd, mode: 'READ' },
        });
        break;
    }
  };

  const fnSearchDtalProc = (params: any) => {
    const utmLink = params.utmLink;
    const utmId = params.utmId;
    switch (utmLink) {
      case 'W-WRITE':
        navigate('/ut/regist-management/regist', {
          state: {
            utmId: utmId,
            planProcId: params.planProcId,
            openMode: 'WRITE',
          },
        });
        break;
      case 'W-READ':
        navigate('/ut/regist-management/regist', {
          state: {
            utmId: utmId,
            planProcId: params.planProcId,
            openMode: 'READ',
          },
        });
        break;
      case 'I-UT_MGR':
        navigate('/ut/regist-management/review', {
          state: { utmId: utmId, bildPlntCd: params.bildPlntCd, mode: 'UT_MGR' },
        });
        break;
    }
  };

  const masterLayoutDefinition = useMemo(
    () => [
      {
        binding: 'utmId',
        visible: false,
      },
      {
        binding: 'bildPlntCdNm',
        header: String(t('ut.label.공장', '공장')),
        isReadOnly: true,
        width: 150,
        align: 'center',
      },
      {
        binding: 'bildPlntCd',
        visible: false,
      },
      {
        binding: 'askPurpCdNm',
        header: String(t('ut.label.목적', '목적')),
        visible: false,
      },
      {
        binding: 'askPurpCd',
        visible: false,
      },
      {
        binding: 'utmNo',
        header: String(t('ut.label.UT Matrix 번호', 'UT Matrix 번호')),
        width: 150,
        isReadOnly: true,
        align: 'center',
        cellTemplate: CellMaker.makeLink({
          text: '${item.utmNo}',
          click: (e, ctx) => {
            fnSearchUtmNo(ctx.item);
          },
        }),
      },
      {
        binding: 'utmNm',
        header: String(t('ut.label.UT Matrix 명', 'UT Matrix 명')),
        width: '*',
        minWidth: 200,
        isReadOnly: true,
        align: 'left',
        cellTemplate: CellMaker.makeLink({
          text: '${item.utmNm}',
          click: (e, ctx) => {
            fnSearchUtmNm(ctx.item);
          },
        }),
      },
      {
        binding: 'utmLink',
        header: String(t('ut.label.UTM Link', 'UTM Link')),
        width: 120,
        visible: false,
        isReadOnly: true,
        align: 'center',
      },
      {
        binding: 'utmWkProgStatCdNm',
        header: String(t('ut.label.상태', '상태')),
        width: 80,
        isReadOnly: true,
        align: 'center',
        cssClass: 'WijmoTag',
        cellTemplate: (item) => {
          if (item.item.utmWkProgStatCd === 'UTM01') {
            return `<span class="yellow">${item.value}</span>`;
          } else if (item.item.utmWkProgStatCd === 'UTM02') {
            return `<span class="blue">${item.value}</span>`;
          } else if (item.item.utmWkProgStatCd === 'UTM03') {
            return `<span class="green">${item.value}</span>`;
          } else if (item.item.utmWkProgStatCd === 'UTM04') {
            return `<span class="purple">${item.value}</span>`;
          } else if (item.item.utmWkProgStatCd === 'UTM05') {
            return `<span class="grey">${item.value}</span>`;
          } else if (item.item.utmWkProgStatCd === 'UTM06') {
            return `<span class="red">${item.value}</span>`;
          }
        },
      },
      {
        binding: 'utmWkProgStatCd',
        visible: false,
      },
      {
        binding: 'verNo',
        header: String(t('com.label.버전', '버전')),
        width: 80,
        isReadOnly: true,
        align: 'center',
      },
      {
        binding: 'smitCloDtm',
        header: String(t('ut.label.작성기한', '작성기한')),
        width: 100,
        isReadOnly: true,
        align: 'center',
      },
      {
        binding: 'lineQty',
        header: String(t('ut.label.Line 수', 'Line 수')),
        width: 95,
        isReadOnly: true,
        align: 'center',
      },
      {
        binding: 'procRatio',
        header: String(t('ut.label.진행공정', '진행공정')),
        width: 140,
        isReadOnly: true,
        cssClass: 'WijmoPlay',
        align: 'center',
        cellTemplate: '<button class="detail" id="btnDetail">${item.procRatio}</button>',
      },
      {
        binding: 'planCpltDtm',
        header: String(t('ut.label.확정기한', '확정기한')),
        width: 100,
        isReadOnly: true,
        align: 'center',
      },
      {
        binding: 'atchFileGrId',
        visible: false,
      },
      {
        binding: 'atchFileCnt',
        width: 80,
        align: 'center',
        header: String(t('com.label.첨부', '첨부')),
        isReadOnly: true,
        cellTemplate: CellMaker.makeLink({
          text: '${item.atchFileCnt}',
          click: (e, ctx) => {
            // handleFileUpload('UTM_REQUEST', ctx);
            setFileUploadModalCondition({
              atchFileGrId: ctx.item?.atchFileGrId,
              atchFileTpCd: 'UTM_REQUEST',
              tableName: 'tb_eelmb_utm_m',
              bizName: 'ut',
            });
            setAtchFileUtmId(ctx.item.utmId);
            setOpenFileUploadModal(true);
          },
        }),
      },
      {
        binding: 'aprRequest',
        header: String(t('ut.label.결재내용', '결재내용')),
        width: 120,
        isReadOnly: true,
        align: 'center',
        cssClass: 'WijmoPopup',
        cellTemplate: (params) => {
          if (params.value == null || params.value == '') {
            return '';
          } else {
            return `${params.value} <button class="aprReq" id="btnAprReq"></button>`;
          }
        },
      },
      {
        binding: 'dataInsUserNm',
        header: String(t('ut.label.요청자', '요청자')),
        width: 100,
        isReadOnly: true,
        align: 'center',
      },
      {
        binding: 'dataInsUserId',
        visible: false,
      },
      {
        binding: 'dataInsDtm',
        header: String(t('ut.label.요청일자', '요청일자')),
        width: 100,
        isReadOnly: true,
        align: 'center',
      },
      // {
      //   binding: 'utmId',
      //   header: String(t('ut.label.UTM ID', 'UTM ID')),
      //   width: 120,
      //   isReadOnly: true,
      //   align: 'center',
      // },
      {
        binding: 'wrtUserId',
        visible: false,
        isReadOnly: true,
        width: 300,
      },
    ],
    [hasAuth]
  );

  const onInitialized = (grid) => {
    setFlexRef(grid);
    gridRef.current = grid;

    if (gridRef.current) {
      const grid = gridRef.current;

      grid.loadedRows.addHandler(() => {
        const items = grid.collectionView ? grid.collectionView.sourceCollection : grid.itemsSource;
        if (!items || !Array.isArray(items)) {
          return;
        }

        items.forEach((item, rowIndex) => {
          grid.columns.forEach((col, colIndex) => {
            const value = grid.getCellData(rowIndex, colIndex, false);

            if (col.binding !== 'utmNm') return;
            const content = CommonUtil.safeHtmlContents(value, true);
            grid.setCellData(rowIndex, colIndex, content, true);
          });
        });

        grid.invalidate();
      });
    }

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;
      const ht = grid.hitTest(e);

      if (ht.row < 0 || ht.col < 0) return;
      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      const item = grid.rows[ht.row].dataItem; // 선택한 row의 정보
      setFlexItem(item);

      if (e.target instanceof HTMLButtonElement) {
        const col = grid.columns[ht.col];
        const item = grid.rows[ht.row].dataItem;
        switch (e.target.id) {
          case 'btnDetail':
            btnSearchDetail(item.utmId);
            setRowItem(item);
            break;
          case 'btnAprReq':
            setRequestModalCondition({
              pageId: ApproveRequestPageType.UT_REVIEW_REQ,
              mode: ManagementMode.READ,
              requestMasterList: item,
            });
            setOpenRequestReadModal(true);
            break;
        }
      }
    });
  };

  const utLineQtyMap = new DataMap(utLineQty, 'cmnCd', 'cmnCdNm');

  const detailLayoutDefinition = [
    {
      binding: 'crudKey',
      header: String(t('com.label.상태', '상태')),
      isReadOnly: true,
      width: 43,
      align: 'center',
      cellTemplate: GridStatusCellTemplate,
    },
    {
      binding: 'utmId',
      header: String(t('ut.label.UTM ID', 'UTM ID')),
      isReadOnly: true,
      width: 150,
      align: 'center',
    },
    {
      binding: 'prdnProcCdNm',
      header: String(t('ut.label.공정', '공정')),
      isReadOnly: true,
      width: 140,
      align: 'center',
    },
    {
      binding: 'prdnProcCd',
      visible: false,
    },
    {
      binding: 'eqclIdNm',
      header: String(t('ut.label.설비군', '설비군')),
      isReadOnly: true,
      width: 150,
      align: 'center',
      cellTemplate: CellMaker.makeLink({
        click: (e, ctx) => {
          fnSearchDtalProc(ctx.item);
        },
      }),
    },
    // {
    //   binding: 'utmLink',
    //   header: String(t('ut.label.UTM Link', 'UTM Link')),
    //   width: 120,
    //   visible: true,
    //   isReadOnly: true,
    //   align: 'center',
    // },
    {
      binding: 'dtalProcCd',
      visible: false,
    },
    // {
    //   binding: 'wrtUserNm',
    //   header: String(t('ut.label.담당자', '담당자')),
    //   width: '*',
    //   isReadOnly: true,
    //   align: 'center',
    //   cssClass: 'WijmoFind',
    //   cellTemplate: '<span>${item.wrtUserNm}</span><button id="btnWrtUserNm" />',
    // },
    {
      header: String(t('ut.label.Hook-up Tag', 'Hook-up Tag')),
      columns: [
        {
          binding: 'tagIssuYn',
          header: String(t('ut.label.태그', '태그')),
          width: 80,
          isReadOnly: true,
          align: 'center',
        },
        {
          binding: 'exctYn',
          header: String(t('ut.label.예외', '예외')),
          width: 80,
          isReadOnly: true,
          align: 'center',
        },
      ],
    },
    {
      header: String(t('ut.label.라인수량', '라인수량')),
      binding: 'eqpPrdnLnQty',
      cssClass: 'WijmoSelect',
      dataMap: utLineQtyMap,
      width: 120,
      align: 'center',
    },
    {
      binding: 'wrtUserNm',
      visible: false,
    },
    {
      binding: 'wrtUserId',
      header: String(t('ut.label.담당자', '담당자')),
      width: '*',
      minWidth: 160,
      isReadOnly: !hasAuth,
      align: 'center',
      cssClass: 'WijmoFind',
      cellTemplate: (item) => {
        const splitWrtUserId = item.item.wrtUserId?.split(',');
        let id = '';
        if (
          (hasAuth === true ||
            splitWrtUserId.includes(userSession.userId) ||
            rowItem.dataInsUserId === userSession.userId) &&
          !['UTP05', 'UTP06'].includes(item.item.utmWrtProcProgStatCd)
        ) {
          id = 'wrtUserId';
        } else if (item.item.crudKey === CrudCode.UPDATE) {
          id = 'wrtUserId';
        } else {
          id = 'readWrtUserId';
        }
        return `<span>${item.item.wrtUserNm}</span><button id=${id} />`;
        // return `<span>${item.item.wrtUserNm}</span><button id=${
        //   (hasAuth === true ||
        //     splitWrtUserId.includes(userSession.userId) ||
        //     rowItem.dataInsUserId === userSession.userId) &&
        //   item.item.utmWrtProcProgStatCd !== 'UTP06'
        //     ? 'wrtUserId'
        //     : 'readWrtUserId'
        // } />`;
      },
    },
    // {
    //   binding: 'wrtUserId',
    //   visible: false,
    // },
    {
      binding: 'utmWrtProcProgStatCdNm',
      header: String(t('ut.label.상태', '상태')),
      width: 100,
      isReadOnly: true,
      align: 'center',
      cssClass: 'WijmoTag',
      cellTemplate: (item) => {
        if (item.item.utmWrtProcProgStatCd === 'UTP01') {
          return `<span class="yellow">${item.value}</span>`;
        } else if (item.item.utmWrtProcProgStatCd === 'UTP02') {
          return `<span class="blue">${item.value}</span>`;
        } else if (item.item.utmWrtProcProgStatCd === 'UTP03') {
          return `<span class="green">${item.value}</span>`;
        } else if (item.item.utmWrtProcProgStatCd === 'UTP04') {
          return `<span class="purple">${item.value}</span>`;
        } else if (item.item.utmWrtProcProgStatCd === 'UTP05') {
          return `<span class="orange">${item.value}</span>`;
        } else if (item.item.utmWrtProcProgStatCd === 'UTP06') {
          return `<span class="grey">${item.value}</span>`;
        } else if (item.item.utmWrtProcProgStatCd === 'UTP07') {
          return `<span class="red">${item.value}</span>`;
        }
      },
    },
    {
      binding: 'utmWrtProcProgStatCd',
      visible: false,
    },
    {
      binding: 'dataUpdDtm',
      header: String(t('ut.label.최종수정일자', '최종수정일자')),
      width: 150,
      isReadOnly: true,
      align: 'center',
    },
  ];

  const onDetailInitialized = (grid) => {
    gridRef.current = grid;
    grid.hostElement.addEventListener('click', (e) => {
      if (e.target instanceof HTMLButtonElement) {
        const len = grid.rows.length;
        if (len == 0) return;
        const ht = grid.hitTest(e);
        if (ht.row < 0 || ht.col < 0) return;
        if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.
        const col = grid.columns[ht.col];
        const item = grid.rows[ht.row].dataItem;
        setDetailFlexItem(item);
        switch (e.target.id) {
          case 'wrtUserId':
            setOpenUserMultiModal(true);
            setUserIds(item.wrtUserId);
            setMode('gridWrtUserId');
            break;
          case 'readWrtUserId':
            setOpenUserReadModal(true);
            setUserIds(item.wrtUserId);
            break;
        }
      }
    });
  };

  const onDetailItemFormatter = useEvent((panel, row, col, cell) => {
    if (CellType.Cell === panel.cellType) {
      const binding = panel.columns[col].binding;
      const item = panel.rows[row].dataItem;
      const splitWrtUserId = item.wrtUserId.split(',');
      const isReadOnly = true;
      if (binding === 'wrtUserId') {
        if (
          (hasAuth === true ||
            splitWrtUserId.includes(userSession.userId) ||
            rowItem.dataInsUserId === userSession.userId) &&
          !['UTP05', 'UTP06'].includes(item.utmWrtProcProgStatCd)
        ) {
          cell.ariaReadOnly = !isReadOnly;
        } else if (item.crudKey === CrudCode.UPDATE) {
          cell.ariaReadOnly = !isReadOnly;
        } else {
          cell.ariaReadOnly = isReadOnly;
        }
      }
      // 라인수량 -> 작성중, ADMIN, 담당자 수정
      if (binding === 'eqpPrdnLnQty') {
        if (
          (hasAuth === true ||
            splitWrtUserId.includes(userSession.userId) ||
            rowItem.dataInsUserId === userSession.userId) &&
          ['UTP01', 'UTP02'].includes(item.utmWrtProcProgStatCd)
        ) {
          cell.ariaReadOnly = !isReadOnly;
        } else {
          cell.ariaReadOnly = isReadOnly;
          toggleClass(cell, 'WijmoSelect', true);
          if (cell.firstElementChild instanceof HTMLButtonElement) {
            cell.firstChild.remove();
          }
        }
      }
    }
  });

  const callbackUserData = (callbackData) => {
    if (mode === 'gridWrtUserId') {
      if (callbackData.userId === '') {
        openMessageBar({
          type: 'warning',
          content: t('com.msg.선택된 사용자가 없습니다.', '선택된 사용자가 없습니다.'),
        });
        return false;
      }
      detailFlexItem['wrtUserId'] = callbackData.userId;
      detailFlexItem['wrtUserNm'] = callbackData.empNm;
      if (
        detailFlexItem['crudKey'] !== CrudCode.CREATE &&
        detailFlexItem['crudKey'] !== CrudCode.DELETE
      ) {
        detailFlexItem['crudKey'] = CrudCode.UPDATE;
      }
      gridRef.current.refresh();
    }
  };

  const callbackFileUpload = (atchFileGrId, cnt) => {
    if (cnt === 1) {
      updateUtMasterAtchFileGrId(atchFileGrId);
    } else {
      flexItem['atchFileCnt'] = cnt;
      gridRef.current.refresh();
    }
    setOpenFileUploadModal(false);
  };

  const beginningEdit = useEvent((grid, e) => {
    const col = grid.columns[e.col];
    const binding = col.binding;
    const item = grid.rows[e.row].dataItem;
    const splitWrtUserId = item.wrtUserId.split(',');
    const isReadOnly = true;

    // readonly 설정
    if (['atchFileCtn', 'wrtUserId'].includes(binding)) {
      e.cancel = true;
    }
    if (binding === 'eqpPrdnLnQty') {
      if (!['UTP01', 'UTP02'].includes(item.utmWrtProcProgStatCd)) {
        e.cancel = true;
      }
    }
  });

  const handleOnChange = (name, value) => {
    setSearchParam({ ...searchParam, [name]: value });
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      btnSearch();
    }
  };

  const btnMasterExcelExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = 'Ut Matrix List';
    book.saveAsync(getExcelFileName(t('ut.label.UT Matrix List', 'UT Matrix List')));
  };

  const btnDetailExcelExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = 'UT Matrix 공정 List';
    book.saveAsync(getExcelFileName(t('ut.label.UT Matrix 공정 List', 'UT Matrix 공정 List')));
  };

  const btnSearch = () => {
    getUtMatrixMasterList(searchParam).then((result: UtMatrixMasterList[]) => {
      if (result !== null) {
        setMasterRowData(result);
        if (result.length > 0) {
          btnSearchDetail(result[0].utmId);
          setRowItem(result[0]);
        } else {
          setDetailRowData([]);
          setRowItem([]);
        }
      } else {
        openMessageBar({
          type: 'error',
          content: t(
            'com.msg.요청 정보 조회 중 오류가 발생했습니다.',
            '요청 정보 조회 중 오류가 발생했습니다.'
          ),
        });
      }
    });
  };

  const searchUtMatrixReferrer = (utmId) => {
    findUtMatrixReferrer(utmId).then((result) => {
      setReferrerRowData(result);
    });
  };

  const btnSearchDetail = (utmId) => {
    getUtMatrixDetailList(utmId).then((result: UtMatrixDetailList[]) => {
      if (result !== null) {
        setDetailRowData(result);
      } else {
        openMessageBar({
          type: 'error',
          content: t(
            'com.msg.요청 정보 조회 중 오류가 발생했습니다.',
            '요청 정보 조회 중 오류가 발생했습니다.'
          ),
        });
      }
    });
    findProcessBar(utmId).then((result) => {
      setUtmWkProgInfo(result);
    });
  };

  const btnReload = () => {
    setUserNm('');
    setSearchParam(
      (prev) =>
        Object.keys(prev).reduce(
          (acc, cur) => Object.assign(acc, { [cur]: initSearchParam[cur] || '' }),
          {}
        ) as SearchParam
    );
  };

  const btnShowResult = () => {
    const selectedRowNodes = flexRef.selectedRows;
    const isSelected = selectedRowNodes.filter((item) => item.isSelected);
    const selectedData = isSelected.map(({ dataItem }) => dataItem);
    if (isSelected.length < 1) {
      openMessageBar({
        type: 'error',
        content: t('ut.label.Ut Matrix를 선택해 주세요.', 'Ut Matrix를 선택해 주세요.'),
      });
      return false;
    }

    const valid = selectedData
      .map((item) => {
        if (!['UTM03', 'UTM04', 'UTM05'].includes(item.utmWkProgStatCd)) {
          openMessageBar({
            type: 'error',
            content: t(
              'ut.label.결과비교를 할 수 없는 상태입니다.',
              '결과비교를 할 수 없는 상태입니다.'
            ),
          });
          return false;
        }
      })
      .filter((element) => element !== undefined);

    if (valid.length) return false;

    navigate('/ut/ut-matrix-summary', { state: { utmData: selectedData } });
  };

  const getAprReqInfo = (item) => {
    if (item.length > 0) {
      findUtMatrixReferrer(item[0].utmId).then((result) => {
        setReferrerRowData(result);
      });

      getUtMatrixSummaryList(item[0].utmId).then((result) => {
        setSummaryRowData(result);
      });
    }
  };

  const btnAprRequest = () => {
    const selectedRowNodes = flexRef.selectedRows;
    const isSelected = selectedRowNodes.filter((item) => item.isSelected);
    const selectedData = isSelected.map(({ dataItem }) => dataItem);
    if (isSelected.length < 1) {
      openMessageBar({
        type: 'error',
        content: t('ut.label.Ut Matrix를 선택해 주세요.', 'Ut Matrix를 선택해 주세요.'),
      });
      return false;
    }

    if (isSelected.length > 1) {
      openMessageBar({
        type: 'error',
        content: t(
          'ut.label.Ut Matrix를 하나만 선택해 주세요.',
          'Ut Matrix를 하나만 선택해 주세요.'
        ),
      });
      return false;
    }

    const aprRequest = selectedData[0].aprRequest;
    if (aprRequest) {
      openMessageBar({
        type: 'error',
        content: t('ut.label.결재 진행중입니다.', '결재 진행중입니다.'),
      });
      return false;
    }

    const utmWkProgStatCd = selectedData[0].utmWkProgStatCd;
    if (utmWkProgStatCd !== 'UTM04') {
      openMessageBar({
        type: 'error',
        content: t(
          'ut.label.결재요청을 할 수 없는 상태입니다.',
          '결재요청을 할 수 없는 상태입니다.'
        ),
      });
      return false;
    }

    setRequestModalCondition({
      pageId: ApproveRequestPageType.UT_REVIEW_REQ,
      mode: ManagementMode.READ,
      requestMasterList: selectedData[0],
    });
    setOpenRequestModal(true);
  };

  const btnHold = () => {
    const selectedRowNodes = flexRef.selectedRows;
    const isSelected = selectedRowNodes.filter((item) => item.isSelected);
    const selectedData = isSelected.map(({ dataItem }) => dataItem);
    if (isSelected.length < 1) {
      openMessageBar({
        type: 'error',
        content: t('ut.label.Ut Matrix를 선택해 주세요.', 'Ut Matrix를 선택해 주세요.'),
      });
      return false;
    }

    if (isSelected.length > 1) {
      openMessageBar({
        type: 'error',
        content: t(
          'ut.label.Ut Matrix를 하나만 선택해 주세요.',
          'Ut Matrix를 하나만 선택해 주세요.'
        ),
      });
      return false;
    }

    const valid = selectedData
      .map((item) => {
        if (!['UTM02', 'UTM03', 'UTM04'].includes(item.utmWkProgStatCd)) {
          openMessageBar({
            type: 'error',
            content: t('ut.label.보류 할 수 없는 상태입니다.', '보류 할 수 없는 상태입니다.'),
          });
          return false;
        }
      })
      .filter((element) => element !== undefined);

    if (valid.length) return false;

    const aprRequest = selectedData[0].aprRequest;
    if (aprRequest) {
      openMessageBar({
        type: 'error',
        content: t(
          'ut.label.결재 진행중인 상태에서는 보류를 할 수 없습니다.',
          '결재 진행중인 상태에서는 보류를 할 수 없습니다.'
        ),
      });
      return false;
    }

    const searchParam = {
      utmId: selectedData[0].utmId,
      mode: 'HOLD',
    };
    navigate('/ut/ut-matrix-request', { state: { searchParam: searchParam } });
  };

  const btnNew = () => {
    const selectedRowNodes = flexRef.selectedRows;
    const isSelected = selectedRowNodes.filter((item) => item.isSelected);
    if (isSelected.length > 1) {
      openMessageBar({
        type: 'error',
        content: t(
          'ut.label.Ut Matrix를 하나만 선택해 주세요.',
          'Ut Matrix를 하나만 선택해 주세요.'
        ),
      });
      return false;
    }

    if (isSelected.length < 1) {
      const searchParam = {
        mode: 'NEW',
      };
      navigate('/ut/ut-matrix-request', { state: { searchParam: searchParam } });
    } else {
      const selectedData = isSelected.map(({ dataItem }) => dataItem);
      const utmWkProgStatCd = selectedData[0].utmWkProgStatCd;
      if (utmWkProgStatCd === 'UTM01') {
        openMessageBar({
          type: 'error',
          content: t('ut.label.신규 진행할 수 없는 상태입니다.', '신규 진행할 수 없는 상태입니다.'),
        });
        return false;
      } else {
        const searchParam = {
          utmData: selectedData[0],
          utmId: selectedData[0].utmId,
          mode: 'COPY_NEW',
        };
        navigate('/ut/ut-matrix-request', { state: { searchParam: searchParam } });
      }
    }
    // } else {
    //   const selectedData = isSelected.map(({ dataItem }) => dataItem);
    //   const askPurpCd = selectedData[0].askPurpCd;
    //   const utmWkProgStatCd = selectedData[0].utmWkProgStatCd;
    //   if (askPurpCd === 'DESIGN') {
    //     if (utmWkProgStatCd === 'UTM01') {
    //       openMessageBar({
    //         type: 'error',
    //         content: t(
    //           'ut.label.신규 진행할 수 없는 상태입니다.',
    //           '신규 진행할 수 없는 상태입니다.'
    //         ),
    //       });
    //       return false;
    //     } else {
    //       const searchParam = {
    //         utmData: selectedData[0],
    //         utmId: selectedData[0].utmId,
    //         mode: 'COPY_NEW',
    //       };
    //       // navigate('/ut/regist-management/regist', { state: { searchParam: searchParam } });
    //       navigate('/ut/ut-matrix-request', { state: { searchParam: searchParam } });
    //     }
    //   }
    //   if (askPurpCd === 'INVEST') {
    //     if (utmWkProgStatCd === 'UTM01') {
    //       openMessageBar({
    //         type: 'error',
    //         content: t(
    //           'ut.label.신규 진행할 수 없는 상태입니다.',
    //           '신규 진행할 수 없는 상태입니다.'
    //         ),
    //       });
    //       return false;
    //     } else if (utmWkProgStatCd === 'UTM05') {
    //       const searchParam = {
    //         utmId: selectedData[0].utmId,
    //         mode: 'DESIGN_NEW',
    //       };
    //       // navigate('/ut/regist-management/regist', { state: { searchParam: searchParam } });
    //       navigate('/ut/ut-matrix-request', { state: { searchParam: searchParam } });
    //     } else {
    //       openCommonModal({
    //         modalType: 'confirm',
    //         content: t(
    //           'com.label.투자용 UT Matrix가 확정완료가 아닙니다. 설계용 UT Matrix를 작성 요청 하시겠습니까?',
    //           '투자용 UT Matrix가 확정완료가 아닙니다. 설계용 UT Matrix를 작성 요청 하시겠습니까?'
    //         ),
    //         yesCallback: () => {
    //           const searchParam = {
    //             utmData: selectedData[0],
    //             utmId: selectedData[0].utmId,
    //             mode: 'DESIGN_NEW',
    //           };
    //           // navigate('/ut/regist-management/regist', { state: { searchParam: searchParam } });
    //           navigate('/ut/ut-matrix-request', { state: { searchParam: searchParam } });
    //         },
    //       });
    //     }
    //   }
    // }
  };

  const btnReWriteRequest = () => {
    const selectedRowNodes = flexRef.selectedRows;
    const isSelected = selectedRowNodes.filter((item) => item.isSelected);
    const selectedData = isSelected.map(({ dataItem }) => dataItem);
    if (isSelected.length < 1) {
      openMessageBar({
        type: 'error',
        content: t('ut.label.Ut Matrix를 선택해 주세요.', 'Ut Matrix를 선택해 주세요.'),
      });
      return false;
    }

    if (isSelected.length > 1) {
      openMessageBar({
        type: 'error',
        content: t(
          'ut.label.Ut Matrix를 하나만 선택해 주세요.',
          'Ut Matrix를 하나만 선택해 주세요.'
        ),
      });
      return false;
    }

    const utmWkProgStatCd = selectedData[0].utmWkProgStatCd;
    const aprRequest = selectedData[0].aprRequest;
    const curYn = selectedData[0].curYn;

    //결재 진행중인 상태면 재요청 불가능
    if (aprRequest) {
      openMessageBar({
        type: 'error',
        content: t(
          'ut.label.결재 진행중인 상태에서는 재작성요청을 할 수 없습니다.',
          '결재 진행중인 상태에서는 재작성요청을 할 수 없습니다.'
        ),
      });
      return false;
    }
    //현재상태가 N이면 재요청 불가능
    if (curYn === 'N') {
      openMessageBar({
        type: 'error',
        content: t(
          'ut.label.재작성요청을 할 수 없는 상태입니다.',
          '재작성요청을 할 수 없는 상태입니다.'
        ),
      });
      return false;
    }
    //권한이 ADM, UT_MANAGER은 Ut Matrix 상태(작성완료, 검토중, 확정완료) 재요청 가능
    if (hasAuth) {
      if (utmWkProgStatCd === 'UTM03' || utmWkProgStatCd === 'UTM04') {
        const searchParam = {
          mode: 'REWRITE',
          utmId: selectedData[0].utmId,
        };
        // navigate('/ut/regist-management/regist', { state: { searchParam: searchParam } });
        navigate('/ut/ut-matrix-request', { state: { searchParam: searchParam } });
      } else if (utmWkProgStatCd === 'UTM05') {
        const searchParam = {
          mode: 'VERSION_UP',
          utmId: selectedData[0].utmId,
        };
        navigate('/ut/ut-matrix-request', { state: { searchParam: searchParam } });
      } else {
        openMessageBar({
          type: 'error',
          content: t(
            'ut.label.재작성요청을 할 수 없는 상태입니다.',
            '재작성요청을 할 수 없는 상태입니다.'
          ),
        });
        return false;
      }
    } else if (!hasAuth) {
      //권한이 CMN은 Ut Matrix 상태(확정완료) 재요청 가능
      const wrtUserIds = selectedData[0].wrtUserId.split(',');
      if (utmWkProgStatCd === 'UTM05') {
        if (wrtUserIds.includes(userSession.userId)) {
          const searchParam = {
            mode: 'VERSION_UP',
            utmId: selectedData[0].utmId,
          };
          navigate('/ut/ut-matrix-request', { state: { searchParam: searchParam } });
        } else {
          openMessageBar({
            type: 'error',
            content: t(
              'ut.label.재작성요청을 할 수 없는 상태입니다.',
              '재작성요청을 할 수 없는 상태입니다.'
            ),
          });
          return false;
        }
      } else {
        openMessageBar({
          type: 'error',
          content: t(
            'ut.label.재작성요청을 할 수 없는 상태입니다.',
            '재작성요청을 할 수 없는 상태입니다.'
          ),
        });
        return false;
      }
    }
  };

  const btnDelete = () => {
    const selectedRowNodes = flexRef.selectedRows;
    const isSelected = selectedRowNodes.filter((item) => item.isSelected);
    if (isSelected.length < 1) {
      openMessageBar({
        type: 'error',
        content: t('ut.label.Ut Matrix를 선택해 주세요.', 'Ut Matrix를 선택해 주세요.'),
      });
      return;
    }

    const valid = isSelected
      .map((item) => {
        if (!['UTM01', 'UTM06'].includes(item.dataItem.utmWkProgStatCd)) {
          openMessageBar({
            type: 'error',
            content: t('ut.label.삭제할 수 없는 상태입니다.', '삭제할 수 없는 상태입니다.'),
          });
          return false;
        }
        if (item.dataItem.dataInsUserId !== userSession.userId) {
          openMessageBar({
            type: 'error',
            content: t('ut.label.삭제 권한이 없습니다.', '삭제 권한이 없습니다.'),
          });
          return false;
        }
      })
      .filter((element) => element !== undefined);

    if (valid.length) return false;

    const deleteData = isSelected.map(({ dataItem }) => dataItem);
    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.삭제하시겠습니까?', '삭제하시겠습니까?'),
      yesCallback: async () => {
        deleteUtMatrixMaster(deleteData).then((result) => {
          if (result) {
            openMessageBar({
              type: 'confirm',
              content: t('com.label.삭제되었습니다.', '삭제되었습니다.'),
            });
            btnSearch();
          } else {
            openMessageBar({
              type: 'error',
              content: t('com.label.삭제 중 오류가 발생했습니다.', '삭제 중 오류가 발생했습니다.'),
            });
          }
        });
      },
    });
  };

  useEffect(() => {
    for (let i = 0; i < progressStatBarCode.length; i++) {
      if (progressStatBarCode[i].cmnCd === rowItem?.utmWkProgStatCd) {
        setProgressNum(i);
      }
    }
  }, [rowItem]);

  const btnSave = () => {
    const validStatusU = detailRowData.filter((element) => element.crudKey == CrudCode.UPDATE);
    if (validStatusU.length < 1) {
      openMessageBar({
        type: 'error',
        content: t('com.label.수정된 항목이 없습니다.', '수정된 항목이 없습니다.'),
      });
      return false;
    }

    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.저장하시겠습니까?', '저장하시겠습니까?'),
      yesCallback: () => {
        saveUtMatrixWrtUser(validStatusU).then((result) => {
          if (!result) {
            openMessageBar({
              type: 'error',
              content: t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
            });
            return false;
          } else {
            openMessageBar({
              type: 'confirm',
              content: t('com.label.저장되었습니다.', '저장되었습니다.'),
            });
            btnSearch();
          }
        });
      },
      noCallback: () => {
        return false;
      },
    });
  };

  const updateUtMasterAtchFileGrId = async (atchFileGrId) => {
    const params = {
      utmId: atchFileUtmId,
      atchFileGrId: atchFileGrId,
    };
    await updateUtMatrixMasterAtchFileGrId(params).then((result) => {
      btnSearch();
    });
  };

  const btnHookupOpen = () => {
    setHookupTaggingCondition({
      utmId: rowItem.utmId,
      planProcId: '',
      utmSeq: '',
      tagDivsCd: '',
    });
    setOpenHookupTaggingModal(true);
  };

  return (
    <ContainerLayout>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows className="FourCol">
              <SearchCols>
                <label>{String(t('ut.label.공장', '공장'))}</label>
                <MultiComboBox
                  id="bildPlntCd"
                  options={bildPlntCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.bildPlntCds || []}
                  onChange={(value) => {
                    handleOnChange('bildPlntCd', value.join());
                  }}
                />
              </SearchCols>
              {/* ELM Phase3 삭제 */}
              {/* <SearchCols>
                <label>{String(t('ut.label.목적', '목적'))}</label>
                <MultiComboBox
                  id="askPurpCd"
                  options={askPurpCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.askPurpCds || []}
                  onChange={(value) => {
                    handleOnChange('askPurpCd', value.join());
                  }}
                />
              </SearchCols> */}
              <SearchCols>
                <label>{String(t('ut.label.상태', '상태'))}</label>
                <MultiComboBox
                  id="utmWkProgStatCd"
                  options={utmWkProgStatCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.utmWkProgStatCds}
                  onChange={(value) => {
                    handleOnChange('utmWkProgStatCd', value.join());
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('com.label.담당자', '담당자')}</label>
                <CustomInputWithSearch
                  name="userId"
                  className="find"
                  value={userNm || ''}
                  defaultValue={userNm || ''}
                  placeholder={String(
                    t('ut.label.담당자를 선택해 주세요.', '담당자를 선택해 주세요.')
                  )}
                  onChange={(e) => {
                    setUserNm(e.target.value);
                    setSearchParam({
                      ...searchParam,
                      dataInsUserId: '',
                      dataInsUserNm: e.target.value,
                    });
                  }}
                  onSearchClick={() => {
                    setOpenUserModal(true);
                  }}
                  onKeyDown={onKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('ut.label.요청일자', '요청일자')}</label>
                <DatePickerWrap className="range">
                  <Datepicker
                    inputClassName="datepickerInput"
                    value={{
                      startDate: searchParam?.startDate || null,
                      endDate: searchParam?.endDate || null,
                    }}
                    onChange={(value) => {
                      setSearchParam({
                        ...searchParam,
                        startDate: String(value?.startDate || ''), // newValue.startDate.toString(),
                        endDate: String(value?.endDate || ''), //newValue.endDate.toString(),
                      });
                    }}
                    i18n={'ko'}
                    useRange={true}
                    placeholder="YYYY.MM.DD - YYYY.MM.DD"
                    displayFormat={'YYYY.MM.DD'}
                  ></Datepicker>
                </DatePickerWrap>
              </SearchCols>
            </SearchRows>
            <SearchRows className="FourCol">
              <SearchCols>
                <label>{t('ut.label.UT Matrix 번호', 'UT Matrix 번호')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="utmNo"
                  placeholder={String(
                    t('ut.label.UT Matrix 번호를 입력해 주세요.', 'UT Matrix 번호를 입력해 주세요.')
                  )}
                  value={searchParam?.utmNo}
                  onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                  onKeyDown={onKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('ut.label.UT Matrix 명', 'UT Matrix 명')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="utmNm"
                  placeholder={String(
                    t('ut.label.UT Matrix 명을 입력해 주세요.', 'UT Matrix 명을 입력해 주세요.')
                  )}
                  value={searchParam?.utmNm}
                  onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                  onKeyDown={onKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <div style={{ display: 'inline-flex', width: '200px', paddingTop: '5px' }}>
                  <label style={{ paddingTop: '0px' }}>
                    {t('ut.label.이전버전보기', '이전버전보기')}
                  </label>
                  <Checkbox
                    id="showPrevVersion"
                    name="showPrevVersion"
                    css={st.checkbox}
                    className="custom"
                    checked={searchParam?.curYn === 'Y'}
                    onClick={(e) => {
                      setSearchParam({
                        ...searchParam,
                        curYn: searchParam?.curYn === 'Y' ? 'N' : 'Y',
                      });
                    }}
                    disableRipple
                  />
                </div>
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchButtonWrap>
            <Button
              css={IconButton.button}
              className="reload"
              onClick={btnReload}
              disableRipple
            ></Button>
            <Button css={IconButton.button} className="find" onClick={btnSearch} disableRipple>
              {t('com.button.조회', '조회')}
            </Button>
          </SearchButtonWrap>
        </SearchBoxRow>
      </SearchBox>
      <ProcessBarWrap className="mt24">
        {(progressStatBarCode || []).map((item, index) => (
          <ProcessStepBox
            key={item.cmnCd}
            className={
              item.cmnCd === rowItem?.utmWkProgStatCd ? 'now' : index < progressNum ? 'done' : ''
            }
          >
            <StepBox>
              <ProcessStep>
                <ProcessNum>{index + 1}</ProcessNum>
                <ProcessText>{item.cmnCdDesc}</ProcessText>
                {item.cmnCd === 'UTM02' && item.cmnCd === utmWkProgInfo?.utmWkProgStatCd ? (
                  <SubStep className="subPer">
                    <li>
                      <strong>{utmWkProgInfo?.procPer || 0}%</strong>
                      <span>{t('ut.label.진행', '진행')}</span>
                      <div>
                        <span
                          style={{
                            width: `${utmWkProgInfo?.procPer || 0}%`,
                          }}
                        ></span>
                      </div>
                    </li>
                    <li>
                      (<em>{utmWkProgInfo?.cmplCnt}</em>/{utmWkProgInfo?.totalCnt})
                    </li>
                    <li>
                      <span>{t('ut.label.지연', '지연')}</span> {utmWkProgInfo?.delayCnt}
                    </li>
                  </SubStep>
                ) : (
                  ''
                )}
              </ProcessStep>
            </StepBox>
          </ProcessStepBox>
        ))}
        <ProgressBar></ProgressBar>
      </ProcessBarWrap>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('ut.label.UT Matrix List', 'UT Matrix List')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{(masterRowData || []).length.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button css={IconButton.button} className="request" onClick={btnShowResult} disableRipple>
            {t('com.button.결과보기', '결과보기')}
          </Button>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={btnMasterExcelExport}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={masterLayoutDefinition}
        rowData={masterRowData}
        allowPinning={false}
        height={400}
        initialized={onInitialized}
        onChangeCheckedItem={getAprReqInfo}
      />
      <GlobalBtnGroup>
        {hasAuth && (
          <Button css={IconButton.button} className="create" onClick={btnNew} disableRipple>
            {t('com.button.신규', '신규')}
          </Button>
        )}
        {hasAuth && (
          <Button
            css={IconButton.button}
            className="refresh"
            onClick={btnReWriteRequest}
            disableRipple
          >
            {t('com.button.재작성요청', '재작성요청')}
          </Button>
        )}
        {hasAuth && (
          <Button css={IconButton.button} className="info" onClick={btnHold} disableRipple>
            {t('com.button.보류', '보류')}
          </Button>
        )}
        {hasAuth && (
          <Button css={IconButton.button} className="delete" onClick={btnDelete} disableRipple>
            {t('com.button.삭제', '삭제')}
          </Button>
        )}
        {hasAuth && (
          <Button css={IconButton.button} className="request" onClick={btnAprRequest} disableRipple>
            {t('com.button.결재요청', '결재요청')}
          </Button>
        )}
      </GlobalBtnGroup>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('ut.label.UT Matrix 공정 List', 'UT Matrix 공정 List')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{(detailRowData || []).length.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
          {rowItem?.bildPlntCdNm && rowItem?.utmNo && (
            <div className="info warning">
              {rowItem?.bildPlntCdNm} / {rowItem?.utmNo}
            </div>
          )}
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button css={IconButton.button} className="select" onClick={btnHookupOpen} disableRipple>
            {t('com.button.Hookup Tagging', 'Hookup Tagging')}
          </Button>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={btnDetailExcelExport}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={detailLayoutDefinition}
        rowData={detailRowData}
        initialized={onDetailInitialized}
        beginningEdit={beginningEdit}
        isSelector={false}
        allowPinning={false}
        height={200}
        itemFormatter={onDetailItemFormatter}
      />
      <GlobalBtnGroup>
        {hasAuth && (
          <Button css={IconButton.button} className="save" onClick={btnSave}>
            {t('com.button.저장', '저장')}
          </Button>
        )}
      </GlobalBtnGroup>
      {isOpenUserModal && (
        <UserModal
          open={isOpenUserModal}
          close={() => setOpenUserModal(false)}
          title={t('com.label.담당자 조회', '담당자 조회')}
          singleSelect={true}
          onCallback={(result) => {
            const users = (result || []) as Employee;
            setSearchParam({ ...searchParam, dataInsUserId: users.userId });
            setUserNm(users.empNm || '');
          }}
          condition={{ empNm: userNm }}
        />
      )}
      {isOpenRequestModal && (
        <ApproveRequestModal
          open={isOpenRequestModal}
          close={() => setOpenRequestModal(false)}
          pageId={ApproveRequestPageType.UT_REVIEW_REQ}
          aprReqId={summaryRowData[0].aprReqId}
          condition={{
            utmId: requestModalCondition.requestMasterList.utmId,
            // requestMaster: summaryRowData[0],
            mode: requestModalCondition.requestMasterList.mode,
            approvalInfr: referrerRowData,
          }}
        />
      )}
      {isOpenRequestReadModal && (
        <ApproveRequestModal
          open={isOpenRequestReadModal}
          close={() => setOpenRequestReadModal(false)}
          pageId={requestModalCondition?.pageId}
          aprReqId={requestModalCondition.requestMasterList.aprRequest}
        />
      )}
      {isOpenUserMultiModal && (
        <UserMultiModal
          open={isOpenUserMultiModal}
          close={() => setOpenUserMultiModal(false)}
          title={t('com.label.담당자 조회', '담당자 조회')}
          mode={mode}
          defaultUserId={userIds}
          singleSelect={false}
          onCallback={(callbackData: any) => {
            callbackUserData(callbackData);
          }}
        />
      )}
      {isOpenUserReadModal && (
        <GatingContListPopUp
          open={isOpenUserReadModal}
          close={() => setOpenUserReadModal(false)}
          title={t('com.label.담당자', '담당자')}
          initParam={userIds}
        />
      )}
      {isOpenHookupTaggingModal && (
        <UtMatrixHookupTagPopup
          open={isOpenHookupTaggingModal}
          condition={hookupTaggingCondition}
          close={() => setOpenHookupTaggingModal(false)}
        />
      )}
      {isOpenFileUploadModal && (
        <FileUploadPopUp
          open={isOpenFileUploadModal}
          close={() => setOpenFileUploadModal(false)}
          singleSelect={false}
          downloadOnly={hasAuth ? false : true}
          initParam={{
            atchFileGrId: fileUploadModalCondition.atchFileGrId,
            atchFileTpCd: fileUploadModalCondition.atchFileTpCd,
            optValCtn1: fileUploadModalCondition.tableName,
            bizName: fileUploadModalCondition.bizName,
          }}
          onCallback={(atchFileGrId, cnt) => callbackFileUpload(atchFileGrId, cnt)}
        />
      )}
    </ContainerLayout>
  );
};

export default UtMatrixListPage;
