/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useImperativeHandle, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalBtnGroup, SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import CustomGrid from 'components/grids/CustomGrid';
import { useCommonModal } from 'hooks/useCommonModal';
import { IdleAssetReview } from 'models/asst/IdleAssetReview';
import { findAssetReview } from 'apis/asst/IdleAssetReview';
import { GatingContListPopUp } from '../../gtng/popup/GatingContListPopUp';
import { CellType, DataMap } from '@grapecity/wijmo.grid';
import { toggleClass, removeClass } from '@grapecity/wijmo';
import { Button } from '@mui/material';
import { IconButton } from 'components/buttons/IconSVG';
import { saveIdleAssetReviewResultCfm } from 'apis/asst/IdleAssetReviewResult';
import { useMessageBar } from 'hooks/useMessageBar';
import { commonYNcodes } from 'models/common/Common';
import { CrudCode } from 'models/common/Edit';
import _ from 'lodash';
import useEvent from 'react-use-event-hook';
import { IdleAssetDetail } from 'models/asst/IdleAsset';

interface Prop {
  condition?: any;
  resultCfm?: boolean;
  isAsstMstComplete?: boolean;
  onRefresh?: () => void;
}

const IdleAssetReviewGrid = (props: Prop, ref) => {
  const { t } = useTranslation();
  const { openCommonModal } = useCommonModal();
  const gridRef = useRef<any>();
  const [condition, setCondition] = useState<IdleAssetDetail>();
  const [rowData, setRowData] = useState<IdleAssetReview[]>([]);
  const [isOpenUserReadModal, setOpenUserReadModal] = useState<boolean>(false);
  const [userIds, setUserIds] = useState<string>('');
  const [code, setCode] = useState<any>();
  const isDone = useMemo(() => !!condition?.rvwRltDivsCd, [condition]);

  useEffect(() => {
    getCommonCodes();
  }, []);

  const getCommonCodes = async () => {
    const useYn = [
      { cmnCd: 'Y', cmnCdNm: String(t('com.label.Y', 'Y')) },
      { cmnCd: 'N', cmnCdNm: String(t('com.label.N', 'N')) },
    ];

    setCode({
      useYn: useYn,
    });
  };

  useImperativeHandle(ref, () => ({
    reset: () => {
      setCondition(undefined);
      setRowData([]);
    },
    search: (params) => {
      setCondition(params);
      handleSearch(params);
    },
    refresh: () => {
      if (condition) {
        handleSearch(condition);
      }
    },
  }));

  const handleSearch = (params) => {
    findAssetReview(params.rvwReqId, params.asstTpCd, params.eqpId).then((result) => {
      setRowData(
        (result || []).reduce(
          (acc, cur) => [
            ...acc,
            {
              ...cur,
              cfmYn: cur.cfmYn || (cur.rvwRltDivsCd === 'IMPB' ? 'N' : ''), // 활용불가인 경우 검토결과 N 설정
            },
          ],
          [] as IdleAssetReview[]
        )
      );
    });
  };

  const onItemFormatter = useEvent((panel, row, col, cell) => {
    if (CellType.Cell === panel.cellType) {
      const binding = panel.columns[col].binding;
      const item = panel.rows[row].dataItem;
      const readonly = item.rvwRltDivsCd === 'IMPB' || !props.resultCfm || isDone;
      if ('cfmYn' == binding) {
        cell.ariaReadOnly = readonly;
        toggleClass(cell, 'WijmoSelect', !readonly);
        if (readonly && cell.firstElementChild instanceof HTMLButtonElement) {
          cell.firstChild.remove();
        }
      }
    }
  });

  const beginningEdit = useEvent((s, e) => {
    const item = s.rows[e.row].dataItem;
    const col = s.columns[e.col];
    const readonly = item.rvwRltDivsCd === 'IMPB' || !props.resultCfm || isDone;
    if ('cfmYn' === col.binding) {
      e.cancel = readonly;
    }
  });

  const onInitialized = (grid) => {
    gridRef.current = grid;
    grid.itemFormatter = onItemFormatter;

    grid.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);

      if (ht.row < 0 || ht.col < 0) return;

      if (ht.panel === grid.cells) {
        const item = grid.rows[ht.row].dataItem;
        const binding = grid.columns[ht.col].binding;
        if ('wrtUserNms' === binding) {
          const ids = (item.wrtUserIds || '').replaceAll(' ', '');
          if (ids) {
            setUserIds(ids);
            setOpenUserReadModal(true);
          }
        }
      }
    });
  };

  const { openMessageBar } = useMessageBar();

  const validate = () => {
    const cfmYnCnt = (rowData || []).filter((o) => o.cfmYn === 'Y');

    const valid = (rowData || [])
      .filter((o) => o.crudKey !== CrudCode.DELETE)
      .map((o, index) => {
        if (_.isEmpty(o.cfmYn)) {
          return `${index + 1} : ${t(
            'asst.msg.활용부서를 선택해주세요.',
            `활용부서를 선택해주세요.`
          )}\n`;
        }
        if (cfmYnCnt.length > 1) {
          return `${index + 1} : ${t(
            'asst.msg.활용부서는 하나만 선택해주세요.',
            `활용부서는 하나만 선택해주세요.`
          )}\n`;
        }
      })
      .filter((element) => element !== undefined);

    if (valid.length) {
      openMessageBar({ type: 'error', content: valid.toString() });
      return false;
    }
    return true;
  };

  const handleSave = () => {
    // if (!validate()) return;

    const cfmYnCnt = (rowData || []).filter((o) => o.cfmYn === 'Y');

    if (cfmYnCnt.length == 0) {
      openMessageBar({
        type: 'error',
        content: t('asst.msg.활용부서를 선택해주세요.', '활용부서를 선택해주세요.'),
      });
      return;
    }
    if (cfmYnCnt.length > 1) {
      openMessageBar({
        type: 'error',
        content: t('asst.msg.활용부서는 하나만 선택해주세요.', '활용부서는 하나만 선택해주세요.'),
      });
      return;
    }

    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.저장하시겠습니까?', '저장하시겠습니까?'),
      yesCallback: () => {
        rowData.map((o, index) => {
          if (_.isEmpty(o.cfmYn)) {
            o.cfmYn = 'N';
          }
        });

        // 변경된 데이터
        const params = (rowData || []).filter((o) => !!o.crudKey);

        saveIdleAssetReviewResultCfm(params)
          .then((response) => {
            openMessageBar({
              type: 'confirm',
              content: t('com.msg.저장되었습니다.', '저장되었습니다.'),
            });
            setCondition((prev) => ({
              ...prev,
              rvwRltDivsCd: 'TEMP', // 저장 후 재조회 전까지 화면의 편집모드를 막기 위해 임시로 값 추가
            }));
            // 재조회 호출
            props?.onRefresh?.();
          })
          .catch((err) =>
            openMessageBar({
              type: 'error',
              content: t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
            })
          );
      },
    });
  };

  const layoutDefinition = [
    {
      binding: 'no',
      header: String(t('com.label.NO', 'NO')),
      align: 'center',
      width: 40,
      isReadOnly: true,
      cellTemplate: (grid) => grid.row._idx + 1,
    },
    {
      binding: 'tgtCopCd',
      header: String(t('asst.label.법인', '법인')),
      align: 'center',
      width: 100,
      isReadOnly: true,
    },
    {
      binding: 'crgeDeptNm',
      header: String(t('asst.label.부서', '부서')),
      align: 'left',
      width: 200,
      isReadOnly: true,
    },
    {
      binding: 'wrtUserNms',
      header: String(t('asst.label.담당자', '담당자')),
      align: 'left',
      width: 120,
      cssClass: 'WijmoFind',
      isReadOnly: true,
      cellTemplate: (params) => `
        <span>${params.value || ''}</span>
        <Button />  
      `,
    },
    {
      header: String(t('asst.label.상태', '상태')),
      align: 'center',
      width: 100,
      isReadOnly: true,
      cellTemplate: (params) => `
            ${
              params.item.rvwRltDivsCd
                ? t('asst.label.검토완료', '검토완료')
                : t('asst.label.검토중', '검토중')
            }
          `,
    },
    {
      binding: 'rvwRltDivsCd',
      header: String(t('asst.label.검토결과', '검토결과')),
      align: 'center',
      width: 100,
      isReadOnly: true,
      cssClass: 'WijmoTag',
      cellTemplate: (item) => {
        if ('PSBL' === item.value) {
          return `<span class="green">${t('asst.label.활용가능', '활용가능')}</span>`;
        } else if ('IMPB' === item.value) {
          return `<span class="red">${t('asst.label.활용불가', '활용불가')}</span>`;
        }
      },
    },
    {
      binding: 'rvwRltCtn',
      header: String(t('asst.label.Comment', 'Comment')),
      align: 'left',
      width: 150,
      isReadOnly: true,
    },
    {
      binding: 'rvwUserNm',
      header: String(t('asst.label.검토자', '검토자')),
      align: 'center',
      width: 100,
      isReadOnly: true,
    },
    {
      binding: 'rvwDt',
      header: String(t('asst.label.검토일자', '검토일자')),
      align: 'center',
      width: 120,
      isReadOnly: true,
    },
    {
      binding: 'cfmYn',
      header: String(t('asst.label.법인지원팀 확정', '법인지원팀 확정')),
      align: 'center',
      width: 120,
      cssClass: 'WijmoSelect',
      dataMap: new DataMap(commonYNcodes || [], 'cmnCd', 'cmnCdNm'),
    },
    {
      binding: 'cfmOpinCtn',
      header: String(t('asst.label.법인지원팀 검토결과', '법인지원팀 검토결과')),
      align: 'left',
      width: '*',
      isReadOnly: props.resultCfm ? false : true,
    },
  ];

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>
            {t(
              'asst.label.법인별 검토 현황',
              '불용자산 요청 List > 불용자산 설비상세 > 법인별 검토 현황'
            )}
          </h3>
          <span className="total">
            {t('com.label.총', '총')} <span>{(rowData || []).length.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        height={230}
        isSelector={false}
        isReadOnly={isDone}
        allowPinning={false}
        initialized={onInitialized}
        beginningEdit={beginningEdit}
      />
      {props.resultCfm && !props.isAsstMstComplete && (
        <GlobalBtnGroup>
          <Button css={IconButton.button} className="save" onClick={() => handleSave()}>
            {t('com.button.저장', '저장')}
          </Button>
        </GlobalBtnGroup>
      )}

      {isOpenUserReadModal && (
        <GatingContListPopUp
          open={isOpenUserReadModal}
          close={() => setOpenUserReadModal(false)}
          title={t('com.label.담당자', '담당자')}
          initParam={userIds}
        />
      )}
    </>
  );
};

export default React.forwardRef(IdleAssetReviewGrid);
