import { Menu } from 'models/admin/Menu';

export type LangType = 'ko' | 'en' | 'pl' | 'zhC' | 'zhT' | 'zh' | 'id';

export interface Session {
  userId?: string;
  empNm?: string;
  langCd?: LanguageCode;
  roleCodes?: string[];
  menus?: Menu[];
  gptTmzCd?: string;
  tmzFrmtCd?: string;
  userCopCd?: string;
  userDeptCd?: string;
  empNo?: string;
  empEngNm?: string;
  empCngNm?: string;
  deptCd?: string;
  deptNm?: string;
  deptEngNm?: string;
  deptCngNm?: string;
  copCd?: string;
  elmCopCd?: string;
  jtiCd?: string;
  jtiNm?: string;
  jtiEngNm?: string;
  jtiCngNm?: string;
  jpsCd?: string;
  jpsNm?: string;
  jpsEngNm?: string;
  jpsCngNm?: string;
  upprEmpNo?: string;
  upprUserId?: string;
  onduRegnCd?: string;
  onduRegnNm?: string;
  ctryCd?: string;
  teamYn?: string;
  tldYn?: string;
  leasTeamYn?: string;
  leasTldYn?: string;
  gleasTeamYn?: string;
  gleasTldYn?: string;
  mgrDeptCd?: string;
}

export enum LanguageCode {
  ko = 'ko',
  en = 'en',
  pl = 'pl',
  zhC = 'zhC',
  zhT = 'zhT',
  zh = 'zh',
  id = 'id',
}

export enum GridNoRowsTemplateData {
  ko = '<div class="agNodata"><span>조회 가능한 데이터가 없습니다.</span></div>',
  en = '<div class="agNodata"><span>There is no data available to view.</span></div>',
  pl = '<div class="agNodata"><span>Brak danych do wyświetlenia.</span></div>',
  zhC = '<div class="agNodata"><span>没有可查看的数据。</span></div>',
  zhT = '<div class="agNodata"><span>没有可供查看的数据。</span></div>',
  zh = '<div class="agNodata"><span>没有可查看的数据。</span></div>',
  id = '<div class="agNodata"><span>There is no data available to view.</span></div>',
}
