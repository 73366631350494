import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { SetupMaster, SetupTgtEqpResult } from 'models/pjm/SetupResultRegist';
import { callApi } from 'utils/ApiUtil';

export const getSetupResultRegistMaster = async (elmPrjId) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/setup/getSetupResultRegistMaster`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ elmPrjId: elmPrjId }),
  };
  const response: CommonResponse<SetupMaster> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as SetupMaster;
};

export const getSetupEqpTgtResultList = async (condition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/setup/getSetupEqpTgtResultList`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };
  const response: CommonResponse<SetupTgtEqpResult[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as SetupTgtEqpResult[];
};

/**
 * 설비별 일정 저장
 * @param detailList
 */
export const saveEqpTgtResultList = async (resultList: SetupTgtEqpResult[]) => {
  const request: CommonRequest = {
    method: Method.PUT,
    url: `/v1/setup/saveEqpTgtResultList`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: resultList,
  };
  const response: CommonResponse = await callApi(request);
  return response;
};
