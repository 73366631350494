import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';
import {
  IdleAssetCondition,
  IdleAssetMaster,
  IdleAssetDetail,
  IdleAssetRequestDetail,
  IdleAssetSummary,
} from 'models/asst/IdleAsset';

/**
 * 불용자산 요청 목록 조회
 * @param condition
 */
export const findIdleAssetMaster = async (condition?: IdleAssetCondition) => {
  const params = {
    ...condition,
    copCds: '',
  };
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/asst/master`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...params }),
  };

  const response: CommonResponse<IdleAssetMaster[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as IdleAssetMaster[];
};

/**
 * 불용자산 설비 목록 조회
 * @param rvwReqId
 * @param copCd
 */
export const findIdleAssetEquipments = async (rvwReqId: string, copCd: string) => {
  const params = {
    rvwReqId: rvwReqId,
    copCd: copCd,
  };
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/asst/${rvwReqId}/equipments`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...params }),
  };

  const response: CommonResponse<IdleAssetDetail[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as IdleAssetDetail[];
};

/**
 * 검토요청ID 생성
 */
export const generateRvwReqId = async () => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/asst/review-request-id`,
    serviceName: ServiceName.ELM_BE,
  };

  const response: CommonResponse<string> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as string;
};

/**
 * 불용자산 요청 설비 목록 조회
 * @param rvwReqId
 * @param aprReqId
 */
export const findIdleAssetRequestEquipments = async (rvwReqId: string, aprReqId: string) => {
  const params = {
    rvwReqId: rvwReqId,
    aprReqId: aprReqId,
  };
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/asst/${rvwReqId}/equipments`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...params }),
  };

  const response: CommonResponse<IdleAssetRequestDetail[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as IdleAssetRequestDetail[];
};

export const findIdleAssetSummary = async (
  type: 'request' | 'status',
  condition?: IdleAssetCondition
) => {
  const params = {
    ...condition,
    copCds: '',
  };
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/asst/summary/${type}`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...params }),
  };

  const response: CommonResponse<IdleAssetSummary[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as IdleAssetSummary[];
};
