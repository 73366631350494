/** @jsxImportSource @emotion/react */
import React, { useState, useImperativeHandle, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FlexChart, FlexChartLegend, FlexChartSeries } from '@grapecity/wijmo.react.chart';
import { FlexChartAnimation } from '@grapecity/wijmo.react.chart.animation';
import { IdleAssetSummary } from 'models/asst/IdleAsset';
import { findIdleAssetSummary } from 'apis/asst/IdleAsset';
import { Code } from 'models/common/CommonCode';
import { getCommonCodeNames } from 'apis/admin/CommonCode';

const IdleAssetStatusChart = (props, ref) => {
  const { t } = useTranslation();
  const [rowData, setRowData] = useState<IdleAssetSummary[]>([]);
  const [code, setCode] = useState<any>();
  const defaultRowData = useMemo(
    () =>
      (code?.elmCopCd || []).reduce(
        (acc, cur) => [
          ...acc,
          {
            copCd: cur.cmnCd,
            eqpCount: 0,
            asstAcqrAmt: 0,
            deprAmt: 0,
            puseCount: 0,
            dpslCount: 0,
            dcdCount: 0,
          } as IdleAssetSummary,
        ],
        []
      ),
    [code?.elmCopCd]
  );

  useImperativeHandle(ref, () => ({
    search: (params) => {
      findIdleAssetSummary('request', params).then((result) => {
        setRowData(result);
      });
    },
  }));

  useEffect(() => {
    getCommonCodes();
  }, []);

  const getCommonCodes = async () => {
    const elmCopCd: Code[] = await getCommonCodeNames('ELM_COP_CD'); // 법인코드

    setCode({
      elmCopCd: elmCopCd,
    });
  };

  const palette = [
    'rgb(180, 181, 243, 0.99)',
    'rgb(218, 240, 136, 0.99)',
    'rgb(252, 169, 191, 0.99)',
    'rgb(173, 206, 255, 0.99)',
  ];

  return (
    <>
      <FlexChart
        header={String(t('asst.label.유휴자산 요청 현황', '불용자산 요청 현황'))}
        bindingX={'copCd'}
        itemsSource={(rowData || []).length > 0 ? rowData : defaultRowData}
        palette={palette}
        style={{
          'margin-top': '12px',
        }}
      >
        <FlexChartLegend position="Bottom" />
        <FlexChartSeries binding="puseCount" name={String(t('asst.label.활용', '활용'))} />
        <FlexChartSeries binding="dpslCount" name={String(t('asst.label.매각', '매각'))} />
        <FlexChartSeries binding="dcdCount" name={String(t('asst.label.폐기', '폐기'))} />
        <FlexChartAnimation />
      </FlexChart>
    </>
  );
};

export default React.forwardRef(IdleAssetStatusChart);
