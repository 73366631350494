import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';
import { Equipment, EquipmentMasterCondition } from 'models/common/popup/EquipmentMaster';

/**설비Master 목록 조회 */
export const findEquipmentMaster = async (condition: EquipmentMasterCondition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/common/equipment`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };

  const response: CommonResponse<Equipment[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as Equipment[];
};
