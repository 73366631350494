import { CommonRequest, Method, ServiceName, CommonResponse } from 'models/common/RestApi';
import { FacilitySetup } from 'models/pjm/FacilitySetupList';
import { SearchParam } from 'models/pjm/SetupManager';
import { callApi } from 'utils/ApiUtil';

export const getFacilitySetupList = async (condition: SearchParam) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/facility/setup-list`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      ...condition,
    }),
  };
  const response: CommonResponse<FacilitySetup[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as FacilitySetup[];
};
