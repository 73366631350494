/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { tb } from 'components/layouts/Table';
import { ControlBtnGroup, SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import {
  ExcelDownloadRequest,
  ExcelRequestError,
  ExcelRequestErrorCondition,
  ExcelValidResult,
} from 'models/common/Excel';
import { getExcelFileName } from 'utils/ExcelUtil';
import {
  findExcelValidDetail,
  findExcelValidList,
  downloadExcelValidList,
} from 'apis/common/Excel';
import { IconButton } from 'components/buttons/IconSVG';
import CustomGrid from 'components/grids/CustomGrid';
import CustomDialog from 'components/modals/common/CustomDialog';

type Props = {
  open: boolean;
  close: () => void;
  condition: {
    valReqId: string;
  };
};

enum ValidRequestType {
  XLS = 'Excel Upload',
  UI = '화면단에서 대용량 검증', //t('com.label.화면단에서 대용량 검증', '화면단에서 대용량 검증'),
}

const ExcelValidModal = ({ open, close, condition }: Props) => {
  const { t } = useTranslation();
  const [validResult, setValidResult] = useState<ExcelValidResult>({});
  const [rowData, setRowData] = useState<ExcelRequestError[]>([]);
  const [excelValidCondition, setExcelValidCondition] = useState<ExcelRequestErrorCondition>({
    valReqId: condition?.valReqId,
  });
  const [totalCount, setTotalCount] = useState<number>(0);

  useEffect(() => {
    if (condition?.valReqId) {
      findExcelValidDetail(excelValidCondition).then((response) => setValidResult(response));
      handleSearch(excelValidCondition);
    }
  }, []);

  const layoutDefinition = [
    {
      binding: 'excelSheet',
      header: String(t('com.label.Excel Sheet 명', 'Excel Sheet 명')),
      align: 'center',
      width: 160,
    },
    {
      binding: 'excelNo',
      header: String(t('com.label.Excel No', 'Excel No')),
      align: 'center',
      width: 130,
    },
    {
      binding: 'errorItem',
      header: String(t('com.label.Error Item', 'Error Item')),
      align: 'left',
      minWidth: 300,
      width: '*',
    },
    {
      binding: 'errorDesc',
      header: String(t('com.label.오류 내용', '오류 내용')),
      align: 'left',
      width: 250,
    },
    {
      binding: 'errorResolution',
      header: String(t('com.label.오류 가이드', '오류 가이드')),
      align: 'left',
      width: 400,
    },
  ];

  const handleSearch = async (condition: ExcelRequestErrorCondition) => {
    const response = await findExcelValidList({
      ...condition,
    });
    if (response) {
      setRowData(response.list || []);
      setTotalCount(response.totalCount || 0);
    }
  };

  const handleDownloadExcel = () => {
    const excelData: ExcelDownloadRequest<ExcelRequestErrorCondition> = {
      fileName: getExcelFileName(t('com.label.Data 검증 오류 조회', 'Data 검증 오류 조회')),
      sheetName: t('com.label.검증오류 List', '검증오류 List'),
      header: [
        String(t('com.label.요청 항목', '요청 항목')),
        String(t('com.label.요청번호', '요청번호')),
        String(t('com.label.Excel 명', 'Excel 명')),
        String(t('com.label.Excel Sheet 명', 'Excel Sheet 명')),
        String(t('com.label.Excel No', 'Excel No')),
        String(t('com.label.Error Item', 'Error Item')),
        String(t('com.label.오류 내용', '오류 내용')),
        String(t('com.label.오류 가이드', '오류 가이드')),
      ],
      searchCondition: { ...excelValidCondition, pageSize: '' },
    };

    downloadExcelValidList(excelData);
  };

  return (
    <CustomDialog
      open={open}
      title={t('com.label.Data 검증 오류 조회', 'Data 검증 오류 조회')}
      size={'xl'}
      onClose={close}
    >
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('com.label.검증 유형', '검증 유형')}</h3>
        </SubTitleGroup>
      </SubTitleLayout>
      <TableContainer css={tb.tableRow}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className="firstCol">
                {t('com.label.검증 요청 ID', '검증 요청 ID')}
              </TableCell>
              <TableCell>{validResult?.valReqId}</TableCell>
              <TableCell className="secondCol">{t('com.label.검증 유형', '검증 유형')}</TableCell>
              <TableCell>{ValidRequestType[validResult?.valTypeNm || '']}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="firstCol">{t('com.label.요청 유형', '요청 유형')}</TableCell>
              <TableCell>{validResult?.reqTypeNm}</TableCell>
              <TableCell className="secondCol">{t('com.label.오류 개수', '오류 개수')}</TableCell>
              <TableCell>{(validResult?.errorCnt || 0).toLocaleString()}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('com.label.검증오류 List', '검증오류 List')}</h3>
          <span className="total">
            {t('com.label.총', '총')} <span>{totalCount.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={handleDownloadExcel}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
          <Button
            css={IconButton.button}
            className="refresh"
            onClick={() => handleSearch(excelValidCondition)}
            disableRipple
          >
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        height={500}
        isSelector={false}
        isReadOnly={true}
      />
    </CustomDialog>
  );
};

export default ExcelValidModal;
