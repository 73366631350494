/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useImperativeHandle, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useEvent from 'react-use-event-hook';
import { addClass } from '@grapecity/wijmo';
import { CellType } from '@grapecity/wijmo.grid';
import { SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import CustomGrid from 'components/grids/CustomGrid';
import { IdleAssetSummary } from 'models/asst/IdleAsset';
import { Code } from 'models/common/CommonCode';
import { findIdleAssetSummary } from 'apis/asst/IdleAsset';
import { getCommonCodeNames } from 'apis/admin/CommonCode';

const IdleAssetPuseStatusGrid = (props, ref) => {
  const { t } = useTranslation();
  const gridRef = useRef<any>();
  const [rowData, setRowData] = useState<IdleAssetSummary[]>([]);
  const [code, setCode] = useState<any>();
  const defaultRowData = useMemo(
    () =>
      (code?.elmCopCd || []).reduce(
        (acc, cur) => [
          ...acc,
          {
            copCd: cur.cmnCd,
            eqpCount: 0,
            asstAcqrAmt: 0,
            deprAmt: 0,
            puseCount: 0,
            dpslCount: 0,
            dcdCount: 0,
          } as IdleAssetSummary,
        ],
        [
          {
            copCd: 'ALL',
            eqpCount: 0,
            asstAcqrAmt: 0,
            deprAmt: 0,
            puseCount: 0,
            dpslCount: 0,
            dcdCount: 0,
          },
        ]
      ),
    [code?.elmCopCd]
  );

  useImperativeHandle(ref, () => ({
    search: (params) => {
      findIdleAssetSummary('status', params).then((result) => {
        setRowData(result);
      });
    },
  }));

  useEffect(() => {
    getCommonCodes();
  }, []);

  const getCommonCodes = async () => {
    const elmCopCd: Code[] = await getCommonCodeNames('ELM_COP_CD'); // 법인코드

    setCode({
      elmCopCd: elmCopCd,
    });
  };

  const onItemFormatter = useEvent((panel, row, col, cell) => {
    if (CellType.RowHeader === panel.cellType) {
      addClass(cell, 'transposed-header');
    }
  });

  const onInitialized = (grid) => {
    gridRef.current = grid;
    grid.itemFormatter = onItemFormatter;
  };

  const layoutDefinition = [
    {
      binding: 'copCd',
      header: String(t('asst.label.구분', '구분')),
      align: 'center',
      cellTemplate: (params) =>
        `${params.value === 'ALL' ? t('com.labe.전체', '전체') : params.value}`,
    },
    {
      binding: 'eqpCount',
      header: String(t('asst.label.자산 대수', '자산 대수')),
      align: 'center',
    },
    {
      binding: 'deprAmt',
      header: String(t('asst.label.잔존가액(USD)', '잔존가액(USD)')),
      align: 'right',
      cellTemplate: (params) => `${t('com.label.$', '$')}${(params.value || 0).toLocaleString()}`,
    },
  ];

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('asst.label.유휴자산 활용 현황', '불용자산 활용 현황')}</h3>
        </SubTitleGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={(rowData || []).length > 0 ? rowData : defaultRowData}
        height={110}
        isTransposed={true}
        isReadOnly={true}
        isSelector={false}
        isFilter={false}
        allowPinning={false}
        initialized={onInitialized}
        loadedRows={(grid, e) => {
          grid.columns.defaultSize = 124.46;
        }}
      />
    </>
  );
};

export default React.forwardRef(IdleAssetPuseStatusGrid);
