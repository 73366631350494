/** @jsxImportSource @emotion/react */
import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useEvent from 'react-use-event-hook';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import PropTypes from 'prop-types';
import { Button, Tab, Tabs } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { IconButton } from 'components/buttons/IconSVG';
import { tb } from 'components/layouts/Table';
import { tabs } from 'components/layouts/Tab';
import { useMessageBar } from 'hooks/useMessageBar';
import { SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import CustomDialog from 'components/modals/common/CustomDialog';
import { getExcelFileName } from 'utils/ExcelUtil';
import ScheduleRegistGrid from '../regist/ScheduleRegistGrid';
import ManagerRegistGrid from '../regist/ManagerRegistGrid';
import { FacilitySchedule, FacilityScheduleRegistMaster } from 'models/pjm/FacilityScheduleRegist';
import {
  getFacilityScheduleMaster,
  saveFacilitySchedule,
  saveProjectStatus,
} from 'apis/pjm/FacilityScheduleRegist';
import { useLoading } from 'components/process/Loading';

type Props = {
  open: boolean;
  close: () => void;
  condition: any;
  onRefresh: () => void;
};

const FacilityScheduleRegistPopup = ({ open, close, condition, onRefresh }: Props) => {
  const [flexRef, setFlexRef] = useState<any>();
  const schdTabRef = useRef<any>();
  const mgrTabRef = useRef<any>();

  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();
  const { openLoading } = useLoading();

  const [isSave, setSave] = useState<boolean>(false);
  const [facilityScheduleRegistMaster, SetFacilityScheduleRegistMaster] =
    useState<FacilityScheduleRegistMaster>();
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);
  const tabMenus = useMemo(() => {
    return [
      { id: 'SCHD', name: t('pjm.label.일정등록', '일정등록') },
      { id: 'MGR', name: t('pjm.label.담당자등록', '담당자등록') },
    ];
  }, []);

  useEffect(() => {
    const setDetailData = async () => {
      const masterData = await getFacilityScheduleMaster(condition);
      SetFacilityScheduleRegistMaster(masterData);
      setSave(false);
    };
    setDetailData();
  }, [condition.elmPrjId]);

  const handleSave = useEvent(() => {
    const tabId = tabMenus[currentTabIndex].id;
    if ('MGR' === tabId) {
      return mgrTabRef.current?.saveManager();
    }
    return new Promise((resolve) => {
      const detailList = [] as FacilitySchedule[];
      const scheduleRow = schdTabRef.current?.getScheduleRowData() || ([] as FacilitySchedule[]);
      scheduleRow.forEach((o) => {
        if (o.crudKey) {
          detailList.push({
            ...o,
            copCd: facilityScheduleRegistMaster?.copCd,
            elmPrjId: facilityScheduleRegistMaster?.elmPrjId,
          });
        }
      });

      // 변경된 데이터가 없는 경우
      if ((detailList || []).length < 1) {
        openMessageBar({
          type: 'warning',
          content: t('com.label.변경된 내용이 없습니다.', '변경된 내용이 없습니다.'),
        });
        return false;
      }
      /*
      [25.02.10] 설비별 일정등록에서는 임시저장 동작만 하기때문에 따로 필수항목 체크 안함
      // 필수항목 체크
      let message = '';
      detailList.some((item) => {
        if (!(item.prdnProcCd || '').length) {
          message = t('pjm.label.공정 항목은 필수 항목입니다.', '공정 항목은 필수 항목입니다.');
          return true;
        }

        if (!(item.eqclId || '').length) {
          message = t('pjm.label.설비군 항목은 필수 항목입니다.', '설비군 항목은 필수 항목입니다.');
          return true;
        }

        if (!(item.eqpLnId || '').length) {
          message = t('pjm.label.호기 항목은 필수 항목입니다.', '호기 항목은 필수 항목입니다.');
          return true;
        }

        if (!(item.eqpId || '').length) {
          message = t(
            'pjm.label.Machine 항목은 필수 항목입니다.',
            'Machine 항목은 필수 항목입니다.'
          );
          return true;
        }

        if (!(item.eqpId || '').length) {
          message = t(
            'pjm.label.Machine 항목은 필수 항목입니다.',
            'Machine 항목은 필수 항목입니다.'
          );
          return true;
        }

        if (!item.poIssuDt) {
          message = t('pjm.label.PO발행 항목은 필수 항목입니다.', 'PO발행 항목은 필수 항목입니다.');
          return true;
        }

        if (!item.atMatDt) {
          message = t(
            'pjm.label.시운전자재 항목은 필수 항목입니다.',
            '시운전자재 항목은 필수 항목입니다.'
          );
          return true;
        }

        if (!item.fatDt) {
          message = t('pjm.label.FAT 항목은 필수 항목입니다.', 'FAT 항목은 필수 항목입니다.');
          return true;
        }

        if (!item.fobDt) {
          message = t('pjm.label.FOB 항목은 필수 항목입니다.', 'FOB 항목은 필수 항목입니다.');
          return true;
        }

        if (!item.eqpCrryDt) {
          message = t(
            'pjm.label.설비반입 항목은 필수 항목입니다.',
            '설비반입 항목은 필수 항목입니다.'
          );
          return true;
        }

        if (!item.istlDt) {
          message = t('pjm.label.설치 항목은 필수 항목입니다.', '설치 항목은 필수 항목입니다.');
          return true;
        }

        if (!item.iocDt) {
          message = t(
            'pjm.label.I/O Check 항목은 필수 항목입니다.',
            'I/O Check 항목은 필수 항목입니다.'
          );
          return true;
        }

        if (!item.wetRunDt) {
          message = t(
            'pjm.label.Wet Run 항목은 필수 항목입니다.',
            'Wet Run 항목은 필수 항목입니다.'
          );
          return true;
        }

        if (!item.qcDt) {
          message = t(
            'pjm.label.Quality Check 항목은 필수 항목입니다.',
            'Quality Check 항목은 필수 항목입니다.'
          );
          return true;
        }

        if (!item.satDt) {
          message = t('pjm.label.SAT 항목은 필수 항목입니다.', 'SAT 항목은 필수 항목입니다.');
          return true;
        }

        if (!item.smplPrdnDt) {
          message = t(
            'pjm.label.샘플생산 항목은 필수 항목입니다.',
            '샘플생산 항목은 필수 항목입니다.'
          );
          return true;
        }

        if (!item.mspdDt) {
          message = t('pjm.label.양산 항목은 필수 항목입니다.', '양산 항목은 필수 항목입니다.');
          return true;
        }
      });

      if (message) {
        openMessageBar({
          type: 'error',
          content: message,
        });
        return false;
      }
      */

      openLoading(true);
      saveFacilitySchedule('write', detailList)
        .then((res) => {
          openMessageBar({
            type: res?.successOrNot === 'Y' ? 'confirm' : 'error',
            content:
              res?.successOrNot === 'Y'
                ? t('com.msg.저장되었습니다.', '저장되었습니다.')
                : t('com.msg.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
          });

          if (res?.successOrNot === 'Y') {
            schdTabRef.current?.refreshScheduleRowData();
          }
        })
        .finally(() => {
          openLoading(false);
          resolve(true);
        });
    });
  });
  const handleRequest = async () => {
    handleSave().then(async (res) => {
      // 상태 변경 (일정 수립중 -> 일정 등록)
      if (facilityScheduleRegistMaster?.elmPrjStatCd === 'PLANNING') {
        const requestMaster = { ...facilityScheduleRegistMaster, elmPrjStatCd: 'ONGOING' };
        saveProjectStatus(requestMaster).then(async (res) => {
          const masterData = await getFacilityScheduleMaster(condition);
          SetFacilityScheduleRegistMaster(masterData || ({} as FacilityScheduleRegistMaster));
        });
      }
    });
  };

  const dialogButtons = useMemo(() => {
    const buttons = [] as ReactElement[];
    // 상태가 [일정등록] 인 경우는 임시저장 버튼 표시 X
    // if (facilityScheduleRegistMaster?.elmPrjStatCd === 'ONGOING') {
    // 저장 버튼은 상태변경 하지않고 저장기능만 수행(임시저장 버튼 삭제) : 20250210
    buttons.push(
      <Button
        key={'confirm'}
        css={IconButton.button}
        className="confirm"
        onClick={() => handleSave().then(() => onRefresh())}
        disableRipple
      >
        {t('com.button.저장', '저장')}
      </Button>
    );
    return buttons;
  }, [facilityScheduleRegistMaster]);

  const btnExcelExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = 'setup';
    book.saveAsync(getExcelFileName(t('pjm.label.Setup_대상설비_List', 'Setup_대상설비_List')));
  };

  return (
    <CustomDialog
      title={t('com.label.Setup 일정등록(상세)', 'Setup 일정등록(상세)')}
      size="xl"
      open={open}
      onClose={close}
      onCancel={close}
      buttons={dialogButtons}
    >
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('pjm.label.Setup Master', 'Setup Master')}</h3>
        </SubTitleGroup>
      </SubTitleLayout>
      <TableContainer css={tb.table} style={{ marginBottom: '24px' }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <span>{t('pjm.label.법인', '법인')}</span>
              </TableCell>
              <TableCell>{facilityScheduleRegistMaster?.copCd}</TableCell>
              <TableCell>
                <span>{t('pjm.label.구분', '구분')}</span>
              </TableCell>
              <TableCell>{facilityScheduleRegistMaster?.elmPrjTpCdNm}</TableCell>
              <TableCell>
                <span>{t('pjm.label.프로젝트 ID', '프로젝트 ID')}</span>
              </TableCell>
              <TableCell>{facilityScheduleRegistMaster?.elmPrjId}</TableCell>
              <TableCell>
                <span>{t('pjm.label.상태', '상태')}</span>
              </TableCell>
              <TableCell>{facilityScheduleRegistMaster?.elmPrjStatCdNm}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span>{t('pjm.label.프로젝트 명', '프로젝트 명')}</span>
              </TableCell>
              <TableCell colSpan={5}>{facilityScheduleRegistMaster?.elmPrjNm}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <div css={tabs.wrap} style={{ marginTop: '24px' }}>
        <Tabs
          value={currentTabIndex}
          onChange={(e, newValue) => setCurrentTabIndex(newValue)}
          css={tabs.tab}
        >
          {tabMenus.map((o, idx) => (
            <Tab
              key={o.id}
              label={o.name}
              id={`ut-tab-${idx}`}
              aria-controls={`ut-tabpanel-${idx}`}
            />
          ))}
        </Tabs>
      </div>
      <TabPanel value={currentTabIndex} index={currentTabIndex}>
        {currentTabIndex === 0 ? (
          <ScheduleRegistGrid
            ref={schdTabRef}
            masterData={facilityScheduleRegistMaster}
            condition={facilityScheduleRegistMaster}
          />
        ) : currentTabIndex === 1 ? (
          <ManagerRegistGrid ref={mgrTabRef} masterData={facilityScheduleRegistMaster} />
        ) : (
          ''
        )}
      </TabPanel>
    </CustomDialog>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(props: { [x: string]: any; children: any; value: any; index: any }) {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`pjm-tabpanel-${index}`}>
      {value === index && <div>{children}</div>}
    </div>
  );
}

export default FacilityScheduleRegistPopup;
