/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useEffect, useMemo, useCallback, memo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {
  deleteRoleDepartment,
  getRoleDepartment,
  insertRoleDepartment,
} from 'apis/admin/RoleDepartment';
import { Department } from 'models/admin/Department';
import { CommonYN } from 'models/common/Common';
import { useCommonModal } from 'hooks/useCommonModal';
import { CrudCode } from 'models/common/Edit';
import { useTranslation } from 'react-i18next';
import {
  ControlBtnGroup,
  GlobalBtnGroup,
  SubTitleGroup,
  SubTitleLayout,
} from 'components/layouts/ContentLayout';
import { Button } from '@mui/material';
import { IconButton } from 'components/buttons/IconSVG';
import DepartmentType1Modal from '../../../components/modals/common/DepartmentType1Modal';
import { GridStatusCellTemplate } from '../../../components/grids/GridStatusCellRenderer';
import CustomGrid from 'components/grids/CustomGrid';
import { DataMap, CellType } from '@grapecity/wijmo.grid';
import { useEvent } from 'react-use-event-hook';
import { useMessageBar } from 'hooks/useMessageBar';
import { getExcelFileName } from 'utils/ExcelUtil';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';

interface SearchParamData {
  roleCd: string;
  editable: boolean;
  height?: number;
  width?: number;
}

const DepartmentManageTable = (props: SearchParamData) => {
  const newRow: Department = {
    deptCd: '',
    copCd: '',
    deptNm: '',
    deptEngNm: '',
    deptCngNm: '',
    temLdrUserId: '',
    upprDeptCd: '',
    useYn: CommonYN.Y,
    crudKey: CrudCode.CREATE,
    isDeleted: false,
    isUpdated: false,
  };

  const gridRef = useRef<AgGridReact<Department>>(null);
  const [rowData, setRowData] = useState<Department[]>([]);
  const [flexRef, setFlexRef] = useState<any>();
  const [rowIdx, setRowIdx] = useState<string>('');
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();
  const { t } = useTranslation();
  const [colWidthMap, setColWidthMap] = useState<any>({
    deptCd: 200,
    copCd: 250,
    deptNm: '*',
  });

  const layoutDefinition = () => {
    return [
      {
        binding: 'no',
        header: String(t('com.label.NO', 'NO')),
        width: 40,
        isReadOnly: true,
        align: 'center',
        cellTemplate: (grid) => grid.row._idx + 1,
      },
      {
        binding: 'crudKey',
        header: String(t('com.label.상태', '상태')),
        width: 50,
        align: 'center',
        isReadOnly: true,
        cellTemplate: GridStatusCellTemplate,
        visible: props.editable,
      },
      {
        binding: 'deptCd',
        header: String(t('admin.grid.부서코드', '부서코드')),
        width: colWidthMap.deptCd,
        isReadOnly: true,
        // editable: (params: EditableCallbackParams) => {
        //   return params.node!.data.crudKey === CrudCode.CREATE;
        // },
        isRequired: false,
      },
      {
        binding: 'copCd',
        header: String(t('admin.grid.법인코드', '법인코드')),
        width: colWidthMap.copCd,
        isReadOnly: true,
        // editable: (params: EditableCallbackParams) => {
        //   return params.node!.data.crudKey === CrudCode.CREATE;
        // },
        isRequired: false,
      },
      {
        binding: 'deptNm',
        header: String(t('admin.grid.부서명', '부서명')),
        width: colWidthMap.deptNm,
        isReadOnly: true,
        isRequired: false,
      },
    ];
  };

  const state = {
    itemsSource: rowData,
    layoutDefinition: layoutDefinition(),
  };

  const onInitialized = (grid) => {
    setFlexRef(grid);
    grid.resizingColumn.addHandler((s, e) => {
      const cell = s.columnHeaders.getCellElement(0, e.col);
      const col = e.panel.columns[e.col];
      colWidthMap[col.binding] = col.width;
      setColWidthMap(colWidthMap);
    });
  };

  const beginningEdit = useEvent((s, e) => {
    const item = s.rows[e.row].dataItem;
    const col = s.columns[e.col];
    if (item.crudKey !== CrudCode.CREATE) {
      if ('roleCd' === col.binding || 'roleNm' === col.binding || 'roleDesc' === col.binding) {
        e.cancel = true;
      }
    }
  });

  const [open, setOpen] = useState(false);

  const onGridReady = (e) => {
    e.api.sizeColumnsToFit();
  };

  const fnSearchRoleDepartment = function (params: string) {
    getRoleDepartment(params)
      .then((result: Department[]) => {
        result &&
          result.map((row) => {
            row.crudKey = CrudCode.READ;
          });
        setRowData(result);
      })
      .then(() => {
        gridRef.current?.api.sizeColumnsToFit();
      });
  };

  const btnExcelExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = 'departments';
    book.saveAsync(getExcelFileName(t('admin.label.역할별부서목록', '역할별부서목록')));
  };

  const fnAddRow = useCallback(() => {
    if (props.roleCd == '') {
      openMessageBar({
        type: 'error',
        content: t('admin.msg.역할을 선택해주세요.', '역할을 선택해주세요.'),
      });
      return;
    }
    setRowIdx('0');
    setOpen(true);
    setRowData([{ crudKey: CrudCode.CREATE, deptCd: '' } as Department, ...rowData]);
  }, [rowData]);

  // const fnDelRow = () => {
  //   const selectedRowNodes = gridRef.current!.api.getSelectedNodes();

  //   const selectedIds = selectedRowNodes
  //     .map((rowNode) => {
  //       return parseInt(rowNode.id!);
  //     })
  //     .reverse();

  //   selectedIds.forEach((item) => {
  //     if (rowData[item].crudKey === CrudCode.CREATE) {
  //       delete rowData[item];
  //     } else {
  //       rowData[item].crudKey = CrudCode.DELETE;
  //     }
  //   });
  //   const filteredData = rowData.filter((element) => element !== undefined);

  //   setRowData(filteredData);
  // };

  const fnDelRow = useCallback(() => {
    if (props.roleCd == '') {
      openMessageBar({
        type: 'error',
        content: t('admin.msg.역할을 선택해주세요.', '역할을 선택해주세요.'),
      });
      return;
    }
    const selectedRowNodes = flexRef.selectedRows;
    // const selectedRowNodes = gridRef.current!.api.getSelectedNodes();
    const isSelected = selectedRowNodes.filter((item) => item.isSelected);
    if (isSelected.length < 1) {
      openMessageBar({
        type: 'warning',
        content: t(
          'com.label.삭제할 항목을 1건 이상 선택해 주세요.',
          '삭제할 항목을 1건 이상 선택해 주세요.'
        ),
      });
      return;
    }

    const selectedIds = selectedRowNodes
      .map((rowNode) => {
        return parseInt(rowNode._idx!);
      })
      .reverse();

    selectedIds.forEach((item) => {
      rowData[item].crudKey = CrudCode.DELETE;
    });

    const filteredData = rowData.filter((element) => element !== undefined);
    setRowData(filteredData);
  }, [rowData]);

  const fnSave = async () => {
    const saveDeptCds = rowData
      .map((rowNode) => {
        if (rowNode.crudKey === CrudCode.CREATE || rowNode.crudKey === CrudCode.UPDATE) {
          return rowNode.deptCd;
        }
      })
      .filter((element) => element) as string[];

    const deleteDeptCds = rowData
      .map((rowNode) => {
        if (rowNode.crudKey === CrudCode.DELETE) {
          return rowNode.deptCd;
        }
      })
      .filter((element) => element) as string[];

    if (saveDeptCds.length === 0 && deleteDeptCds.length === 0) {
      openMessageBar({
        type: 'error',
        content: t('com.msg.수정된 항목이 없습니다.', '수정된 항목이 없습니다.'),
      });
      return;
    }

    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.저장하시겠습니까?', '저장하시겠습니까?'),
      yesCallback: async () => {
        let result: any = null;
        if (saveDeptCds.length > 0) result = await insertRoleDepartment(props.roleCd, saveDeptCds);
        if (deleteDeptCds.length > 0)
          result = await deleteRoleDepartment(props.roleCd, deleteDeptCds);
        if (result != null) {
          openMessageBar({
            type: 'confirm',
            content: t('com.label.저장되었습니다.', '저장되었습니다.'),
          });
          fnSearchRoleDepartment(props.roleCd);
        } else {
          openMessageBar({
            type: 'error',
            content: t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
          });
        }
      },
      noCallback: () => {
        return false;
      },
    });
  };

  const handleSaveDepartment = (department: Department) => {
    if (parseInt(rowIdx) < 0) return;
    const newDepartments: Department[] = [...rowData];
    if (newDepartments[rowIdx].crudKey !== CrudCode.CREATE) {
      newDepartments.push({ ...newDepartments[rowIdx], crudKey: CrudCode.DELETE, isUpdated: true });
      newDepartments[rowIdx] = { ...department, crudKey: CrudCode.UPDATE } as Department;
    } else {
      newDepartments[rowIdx] = { ...department, crudKey: CrudCode.CREATE } as Department;
    }
    setRowData([
      ...newDepartments.filter((item) => item.crudKey !== CrudCode.DELETE),
      ...newDepartments.filter((item) => item.crudKey === CrudCode.DELETE),
    ]);
  };

  // Example load data from server
  useEffect(() => {
    !props.roleCd || fnSearchRoleDepartment(props.roleCd);
  }, [props]);

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('admin.label.부서', '부서')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{rowData.length}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>

        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={btnExcelExport}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
          {props.editable && (
            <>
              <Button css={IconButton.button} className="plus" disableRipple onClick={fnAddRow}>
                {t('com.button.행추가', '행추가')}
              </Button>
              <Button css={IconButton.button} className="minus" disableRipple onClick={fnDelRow}>
                {t('com.button.행삭제', '행삭제')}
              </Button>
            </>
          )}
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={state.layoutDefinition}
        rowData={state.itemsSource}
        height={props.height ?? 500}
        isSelector={props.editable}
        excludeFilter={['no']}
        initialized={onInitialized}
        beginningEdit={beginningEdit}
      />
      {/* <ContentGrid className="ag-theme-alpine" style={{ height: `${props.height ?? 500}px` }}>
        <AgGridReact<Department>
          overlayNoRowsTemplate="No rows to show"
          ref={gridRef}
          rowData={rowData.filter((data) => !data.isUpdated)}
          columnDefs={columnDefs}
          suppressRowClickSelection={true}
          defaultColDef={defaultColDef}
          rowSelection="multiple"
          onGridReady={onGridReady}
        />
      </ContentGrid> */}
      {props.editable && (
        <GlobalBtnGroup>
          <Button css={IconButton.button} className="save" disableRipple onClick={fnSave}>
            {t('com.button.저장', '저장')}
          </Button>
        </GlobalBtnGroup>
      )}
      <>
        <DepartmentType1Modal
          open={open}
          initParam={{
            copCd: '',
          }}
          close={() => setOpen(false)}
          save={handleSaveDepartment}
        />
      </>
    </>
  );
};

export default memo(DepartmentManageTable);
