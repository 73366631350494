/** @jsxImportSource @emotion/react */
import React, {
  useEffect,
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import useEvent from 'react-use-event-hook';
import isEqual from 'lodash/isEqual';
import { Button } from '@mui/material';
import { addClass, toggleClass } from '@grapecity/wijmo';
import { DataMap, CellType } from '@grapecity/wijmo.grid';
import { IconButton } from 'components/buttons/IconSVG';
import {
  InputBox,
  SearchBox,
  SearchBoxRow,
  SearchCols,
  SearchRows,
} from 'components/layouts/SearchBox';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import CustomDialog from 'components/modals/common/CustomDialog';
import CustomGrid from 'components/grids/CustomGrid';
import SearchBoxButtons from 'components/buttons/SearchBoxButtons';
import {
  SubTitleGroup,
  SubTitleLayout,
  HalfContetntLayout,
  ControlBtnGroup,
} from 'components/layouts/ContentLayout';
import { MultiComboBox } from 'components/selects/ComboBox';
import { Code } from 'models/common/CommonCode';
import {
  EquipmentMasterCondition,
  Equipment,
  EquipmentLevel,
  CustomEquipment,
} from 'models/common/popup/EquipmentMaster';
import { CrudCode } from 'models/common/Edit';
import { getCommonCodeNames, getCommonCodeNamesCondition } from 'apis/admin/CommonCode';
import { findEquipmentMaster } from 'apis/common/popup/EquipmentMaster';
import { GridStatusCellTemplate } from 'components/grids/GridStatusCellRenderer';
import { useMessageBar } from 'hooks/useMessageBar';
import _ from 'lodash';

interface Props {
  open: boolean;
  close: () => void;
  title: string;
  condition?: {
    copCds?: string[]; // 법인코드
    equipmentId?: string; // 설비ID
    equipmentName?: string; // 설비명
    mainEqpNm?: string; // 최상위설비명
  };
  isFixedMainLevel?: boolean; // 설비레벨 Main 고정여부 (default: false)
  defaultEquipment?: CustomEquipment | CustomEquipment[]; // 기존 선택된 설비정보 목록
  editable?: boolean; // 편집가능 여부 (default : false)
  singleSelect?: boolean; // 단일선택 여부 (default: true)
  isCopCdFix?: boolean; // 법인코드 고정 여부
  onCallback: (result: CustomEquipment | CustomEquipment[]) => void;
}

const EquipmentPopUp = ({
  open,
  close,
  title,
  isFixedMainLevel = false,
  singleSelect = true,
  editable = false,
  onCallback,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const searchRef = useRef<any>();
  const selectRef = useRef<any>();
  const [code, setCode] = useState<any>();
  const [condition, setCondition] = useState<EquipmentMasterCondition>(
    Object.assign(
      {
        copCds: [],
        equipmentId: '',
        equipmentName: '',
        mainEquipmentName: '',
        equipmentLevel: isFixedMainLevel ? EquipmentLevel.MAIN : '',
      },
      props?.condition || {}
    )
  );

  useEffect(() => {
    getCommonCodes();
  }, []);

  const getCommonCodes = async () => {
    const elmCopCd: Code[] = await getCommonCodeNames('ELM_COP_CD'); // 법인코드

    setCode({
      elmCopCd: elmCopCd,
    });
  };

  const handleConditionChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    setCondition({ ...condition, [e.target.name]: e.target.value });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      searchRef.current?.handleSearch(condition);
    }
  };

  const handleSearch = (condition: EquipmentMasterCondition) => {
    searchRef.current?.handleSearch(condition);
  };

  const handleConfirm = () => {
    if (singleSelect) {
      const items = searchRef.current?.getRowData();
      onCallback && onCallback((items || []).length ? items[0] : null);
    } else {
      if (!selectRef.current?.validate()) {
        return;
      }
      const items = selectRef.current?.getRowData();
      onCallback && onCallback(items);
    }
    close();
  };

  /**
   * Quick Select 이벤트 처리
   * @param item
   */
  const handleQuickSelect = (item: Equipment) => {
    onCallback && onCallback(item);
    close();
  };

  const dialogButtons = [
    <Button
      key={'confirm'}
      css={IconButton.button}
      className="confirm"
      onClick={handleConfirm}
      disableRipple
    >
      {t('com.button.선택', '선택')}
    </Button>,
  ];

  return (
    <CustomDialog
      title={title}
      open={open}
      onClose={close}
      onCancel={close}
      buttons={dialogButtons}
    >
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows className="popup">
              <SearchCols>
                <label>{t('asst.label.법인', '법인')}</label>
                <MultiComboBox
                  id="copCd"
                  placeholder={String(t('asst.msg.법인', '법인'))}
                  options={code?.elmCopCd || []}
                  defaultValue={condition?.copCds || []}
                  readOnly={props.isCopCdFix ? true : false}
                  onChange={(value) => {
                    setCondition((prev) => ({
                      ...prev,
                      copCd: (value || []).join(),
                    }));
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('asst.label.설비ID', '설비ID')}</label>
                <CustomInputWithSearch
                  name="equipmentId"
                  placeholder={String(
                    t('asst.label.설비ID를 입력해 주세요.', '설비ID를 입력해 주세요.')
                  )}
                  value={condition?.equipmentId}
                  onChange={handleConditionChange}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('asst.label.설비명', '설비명')}</label>
                <CustomInputWithSearch
                  name="equipmentName"
                  placeholder={String(
                    t('asst.label.설비명을 입력해 주세요.', '설비명을 입력해 주세요.')
                  )}
                  value={condition?.equipmentName}
                  onChange={handleConditionChange}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
              {!isFixedMainLevel && (
                <SearchCols>
                  <label>{t('asst.label.최상위설비', '최상위설비')}</label>
                  <CustomInputWithSearch
                    name="mainEquipmentName"
                    placeholder={String(
                      t('asst.label.최상위설비를 입력해 주세요.', '최상위설비를 입력해 주세요.')
                    )}
                    value={condition?.mainEquipmentName}
                    onChange={handleConditionChange}
                    onKeyDown={handleKeyDown}
                  />
                </SearchCols>
              )}
            </SearchRows>
          </InputBox>
          <SearchBoxButtons setReset={setCondition} onSearch={() => handleSearch(condition)} />
        </SearchBoxRow>
      </SearchBox>
      {singleSelect ? (
        <SearchGrid
          ref={searchRef}
          singleSelect={singleSelect}
          defaultCondition={props.condition}
          onQuickSelect={handleQuickSelect}
        />
      ) : (
        <>
          <HalfContetntLayout style={{ marginTop: '24px' }}>
            <div>
              <SearchGrid
                ref={searchRef}
                singleSelect={singleSelect}
                defaultCondition={props.condition}
                isCopfix={props.isCopCdFix}
                addRow={(items) => selectRef.current?.addRowData(items)}
              />
            </div>
            <div>
              <SelectedGrid
                ref={selectRef}
                singleSelect={singleSelect}
                editable={editable}
                isCopfix={props.isCopCdFix}
                defaultCondition={condition}
                defaultEquipment={props?.defaultEquipment}
              />
            </div>
          </HalfContetntLayout>
        </>
      )}
    </CustomDialog>
  );
};

interface ContentProps {
  singleSelect: boolean;
  editable?: boolean;
  isCopfix?: boolean;
  defaultCondition?: EquipmentMasterCondition;
  defaultEquipment?: CustomEquipment | CustomEquipment[];
  onQuickSelect?: (item: Equipment) => void;
  addRow?: (items: Equipment[]) => void;
}

const SearchGrid = forwardRef(
  ({ singleSelect, addRow, onQuickSelect, defaultCondition }: ContentProps, ref) => {
    const gridRef = useRef<any>();
    const prevCondition = useRef<any>();
    const { t } = useTranslation();
    const [rowData, setRowData] = useState<Equipment[]>([]);
    const [checkedItems, setCheckedItems] = useState<Equipment[]>([]);

    useImperativeHandle(ref, () => ({
      handleSearch: (condition) => {
        handleSearch(condition);
      },
      getRowData: () => {
        return checkedItems;
      },
    }));

    useEffect(() => {
      if (!isEqual(prevCondition.current, defaultCondition)) {
        prevCondition.current = defaultCondition;
        handleSearch(defaultCondition);
      }
    }, [defaultCondition]);

    const handleSearch = (condition) => {
      findEquipmentMaster(condition).then((result) => {
        setRowData(result || []);
      });
    };

    const onInitialized = (grid) => {
      gridRef.current = grid;

      grid.hostElement.addEventListener('click', (e) => {
        if (grid.rows.length == 0) return;

        const ht = grid.hitTest(e);
        if (ht.row < 0 || ht.col < 0) return;

        const binding = grid.columns[ht.col].binding;
        const item = grid.rows[ht.row].dataItem;

        if (ht.panel === grid.cells) {
          // Quick Select
          if ('btnSelect' === binding) {
            onQuickSelect && onQuickSelect(item);
          }
        }
      });

      grid.hostElement.addEventListener('dblclick', (e) => {
        if (grid.rows.length == 0) return;

        const ht = grid.hitTest(e);
        if (ht.row < 0 || ht.col < 0) return;

        const item = grid.rows[ht.row].dataItem;

        if (item) {
          onQuickSelect && onQuickSelect(item);
        }
      });
    };

    const handleAddRow = () => {
      addRow && addRow(checkedItems);
    };

    const layoutDefinition = [
      {
        binding: 'btnSelect',
        header: ' ',
        cssClass: 'WijmoRelay',
        cellTemplate: '<Button />',
        width: 39,
        visible: singleSelect,
      },
      {
        binding: 'no',
        header: String(t('com.label.NO', 'NO')),
        width: 40,
        cellTemplate: (grid) => grid.row._idx + 1,
        visible: singleSelect,
      },
      {
        header: String(t('asst.label.법인', '법인')),
        binding: 'copCd',
        align: 'center',
        width: 100,
      },
      {
        binding: 'equipmentId',
        header: String(t('asst.label.설비ID', '설비ID')),
        align: 'left',
        width: 120,
      },
      {
        binding: 'equipmentName',
        header: String(t('asst.label.설비명', '설비명')),
        align: 'left',
        width: '*',
      },
    ];

    return (
      <>
        <SubTitleLayout>
          <SubTitleGroup>
            <h3>{t('asst.label.설비', '설비')}</h3>
            <span className="total">
              {t('com.label.총', '총')} <span>{(rowData || []).length.toLocaleString()}</span>
              {t('com.label.건', '건')}
            </span>
          </SubTitleGroup>
          {!singleSelect && (
            <ControlBtnGroup>
              <Button
                css={IconButton.button}
                className="addRow"
                onClick={handleAddRow}
                disableRipple
              >
                {t('com.label.추가', '추가')}
              </Button>
            </ControlBtnGroup>
          )}
        </SubTitleLayout>
        <CustomGrid
          layoutDefinition={layoutDefinition}
          rowData={rowData}
          height={500}
          isReadOnly={true}
          isFilter={false}
          autoCheck={true}
          rowSelection={singleSelect ? 'single' : 'multiple'}
          allowPinning={false}
          align="center"
          initialized={onInitialized}
          onChangeCheckedItem={(items) => setCheckedItems(items)}
        />
      </>
    );
  }
);

const SelectedGrid = forwardRef(
  ({ singleSelect, editable, isCopfix, defaultCondition, defaultEquipment }: ContentProps, ref) => {
    const getDefaultEquipment = () => {
      if (singleSelect) {
        return defaultEquipment ? [defaultEquipment as CustomEquipment] : ([] as CustomEquipment[]);
      }
      return (defaultEquipment || []) as CustomEquipment[];
    };

    const gridRef = useRef<any>();
    const { t } = useTranslation();
    const { openMessageBar } = useMessageBar();
    const [rowData, setRowData] = useState<CustomEquipment[]>(getDefaultEquipment());
    const [checkedItems, setCheckedItems] = useState<CustomEquipment[]>([]);
    const [code, setCode] = useState<any>({});
    const defaultCopCd = useMemo(
      () => ((defaultCondition?.copCds || []).length > 0 ? defaultCondition?.copCds[0] : ''),
      [defaultCondition?.copCds]
    );

    useImperativeHandle(ref, () => ({
      addRowData: (items: Equipment[]) => {
        addRowData(items);
      },
      getRowData: () => {
        return rowData;
      },
      validate: () => {
        if (!editable) {
          return true;
        }
        const valid = (rowData || [])
          .filter((o) => o.crudKey !== CrudCode.DELETE)
          .map((o, index) => {
            if (_.isEmpty(o.copCd)) {
              return (
                `${index + 1} : ` +
                t('asst.label.법인을 선택해 주세요.', `법인을 선택해 주세요.`) +
                '\n'
              );
            }
            if (_.isEmpty(o.equipmentId)) {
              return (
                `${index + 1} : ` +
                t('asst.label.설비ID를 입력해 주세요.', `설비ID를 입력해 주세요.`) +
                '\n'
              );
            }
            if (_.isEmpty(o.equipmentName)) {
              return (
                `${index + 1} : ` +
                t('asst.label.설비명을 입력해 주세요.', `설비명을 입력해 주세요.`) +
                '\n'
              );
            }
          })
          .filter((element) => element !== undefined);

        if (valid.length) {
          openMessageBar({ type: 'error', content: valid.toString() });
          return false;
        }
        return true;
      },
    }));

    useEffect(() => {
      getCommonCodes();
    }, []);

    const getCommonCodes = async () => {
      const elmCopCd: Code[] = await getCommonCodeNames('ELM_COP_CD'); // 법인코드
      const factoryCode: Code[] = await getCommonCodeNames('FACTORY_CODE'); // 공장(동)
      const prdnProcCd = await getCommonCodeNamesCondition({
        optValCtn5: 'Y',
        cmnGrCd: 'PRDN_PROC_CD',
      }); // 공정

      setCode({
        elmCopCd: elmCopCd,
        factoryCode: factoryCode,
        prdnProcCd: prdnProcCd,
      });
    };

    const addRowData = (items: Equipment[]) => {
      setRowData((prev) => {
        const newRows = (items || []).reduce((acc, cur) => {
          acc.push({
            ...cur,
            crudKey: CrudCode.CREATE,
            eqpIdExstYn: 'Y', // 설비ID 조회에서 추가한 경우
          });
          return acc;
        }, [] as CustomEquipment[]);
        return [...prev, ...newRows].reduce((acc, cur) => {
          if (
            cur.eqpIdExstYn === 'N' ||
            acc.findIndex(({ equipmentId }) => equipmentId === cur.equipmentId) === -1
          ) {
            acc.push(cur);
          }
          return acc;
        }, [] as CustomEquipment[]);
      });
    };

    const onItemFormatter = useEvent((panel, row, col, cell) => {
      if (CellType.ColumnHeader === panel.cellType) {
        const binding = panel.columns[col].binding;
        // 필수항목
        if (['copCd', 'equipmentId', 'equipmentName'].includes(binding)) {
          addClass(cell, 'dot');
        }
      }
      if (CellType.Cell === panel.cellType) {
        const binding = panel.columns[col].binding;
        const item = panel.rows[row].dataItem;

        const readonlyCopCd = item.eqpIdExstYn === 'Y' || isCopfix;

        if ('copCd'.includes(binding)) {
          cell.ariaReadOnly = readonlyCopCd;
          if ('copCd'.includes(binding)) {
            toggleClass(cell, 'WijmoSelect', !readonlyCopCd);
            if (readonlyCopCd && cell.firstElementChild instanceof HTMLButtonElement) {
              cell.firstChild.remove();
            }
          }
        }
        const readonly = item.eqpIdExstYn === 'Y';
        if (['equipmentId', 'equipmentName', 'factoryCode', 'processCode'].includes(binding)) {
          cell.ariaReadOnly = readonly;
          if (['factoryCode', 'processCode'].includes(binding)) {
            toggleClass(cell, 'WijmoSelect', !readonly);
            if (readonly && cell.firstElementChild instanceof HTMLButtonElement) {
              cell.firstChild.remove();
            }
          }
        }
      }
    });

    const onBeginningEdit = (grid, e) => {
      const binding = grid.columns[e.col].binding;
      const item = grid.rows[e.row].dataItem;
      const readonly = item.eqpIdExstYn === 'Y';
      const readonlyCopCd = item.eqpIdExstYn === 'Y' || isCopfix;
      if (
        ['copCd', 'equipmentId', 'equipmentName', 'factoryCode', 'processCode'].includes(binding) &&
        readonly
      ) {
        e.cancel = true;
      }
      if ('copCd'.includes(binding) && readonlyCopCd) {
        e.cancel = true;
      }
    };

    const onCellEditEnding = (grid, e) => {
      const data = grid.rows[e.row].dataItem;
      const binding = grid.columns[e.col].binding;

      // if ('copCd' === binding) {
      //   if (newVal !== data.copCd) {
      //     // 법인코드 변경 시 공장코드 초기화
      //     data.factoryCode = '';
      //   }
      // }
      //
      if ('equipmentId' === binding) {
        const newVal = grid.activeEditor?.value;
        // 설비ID 중복 확인
        if (
          grid.rows.filter(
            (o, idx) => idx !== e.row && newVal != null && o.dataItem.equipmentId === newVal
          ).length
        ) {
          openMessageBar({
            type: 'error',
            content: t('asst.label.이미 추가한 설비ID 입니다.', '이미 추가한 설비ID 입니다.'),
          });
          e.cancel = true;
          return;
        }
        if (newVal == null || newVal == '') return;
        // 이미 존재하는 설비ID인 경우 기존 설비 데이터로 덮어쓰기
        findEquipmentMaster({ equipmentIdAsst: newVal, copCd: data.copCd }).then((result) => {
          if ((result || []).length) {
            openMessageBar({
              type: 'warning',
              content: t(
                'asst.label.존재하는 설비ID이므로 설비정보를 변경하였습니다.',
                '존재하는 설비ID이므로 설비정보를 변경하였습니다.'
              ),
            });
            const eqp = result[0];
            data.copCd = eqp.copCd;
            data.equipmentId = eqp.equipmentId;
            data.equipmentName = eqp.equipmentName;
            data.factoryCode = eqp.factoryCode;
            data.processCode = eqp.processCode;
            data.eqpIdExstYn = 'Y'; // 설비ID존재여부 변경
            gridRef.current?.refresh();
          }
        });
      }
    };

    const onInitialized = (grid) => {
      gridRef.current = grid;
      grid.itemFormatter = onItemFormatter;
    };

    const handleAddRow = () => {
      const newRow = {
        crudKey: CrudCode.CREATE,
        copCd: defaultCopCd,
        eqpIdExstYn: 'N',
      } as Equipment;
      const rows = [newRow, ...rowData];
      setRowData(rows);
    };

    const handleDelRow = () => {
      setRowData((prev) => (prev || []).filter((o) => !(checkedItems || []).includes(o)));
      setCheckedItems([]);
    };

    const layoutDefinition = useMemo(() => {
      const mapFactoryList = new DataMap(code?.factoryCode || [], 'cmnCd', 'cmnCdNm');
      mapFactoryList.getDisplayValues = (item) => {
        return (code?.factoryCode || [])
          .filter((o) => o.optValCtn1 === item.copCd)
          .filter((o) => !!o.cmnCdNm)
          .map((o) => o.cmnCdNm || '');
      };

      return [
        {
          binding: 'no',
          header: String(t('com.label.NO', 'NO')),
          width: 40,
          isReadOnly: true,
          cellTemplate: (grid) => grid.row._idx + 1,
        },
        {
          header: String(t('com.label.상태', '상태')),
          binding: 'crudKey',
          width: 50,
          isReadOnly: true,
          align: 'center',
          cellTemplate: GridStatusCellTemplate,
          visible: editable,
        },
        {
          header: String(t('asst.label.법인', '법인')),
          binding: 'copCd',
          align: 'center',
          width: 100,
          dataMap: new DataMap(code?.elmCopCd || [], 'cmnCd', 'cmnCdNm'),
        },
        {
          binding: 'equipmentId',
          header: String(t('asst.label.설비ID', '설비ID')),
          align: 'left',
          width: 120,
        },
        {
          binding: 'equipmentName',
          header: String(t('asst.label.설비명', '설비명')),
          align: 'left',
          width: 150,
        },
        {
          binding: 'factoryCode',
          header: String(t('asst.label.공장(동)', '공장(동)')),
          align: 'left',
          width: 150,
          dataMap: mapFactoryList,
        },
        {
          binding: 'processCode',
          header: String(t('asst.label.공정', '공정')),
          align: 'left',
          width: 150,
          dataMap: new DataMap(code?.prdnProcCd || [], 'cmnCd', 'cmnCdNm'),
        },
        {
          binding: 'eqpIdExstYn',
          visible: false,
        },
      ];
    }, [code]);

    return (
      <>
        <SubTitleLayout>
          <SubTitleGroup>
            <h3></h3>
          </SubTitleGroup>
          <ControlBtnGroup>
            {editable && (
              <Button
                css={IconButton.button}
                className="addRow"
                onClick={() => handleAddRow()}
                disableRipple
              >
                {t('com.button.행추가', '행추가')}
              </Button>
            )}
            <Button
              css={IconButton.button}
              className="delRow"
              onClick={() => handleDelRow()}
              disableRipple
            >
              {editable ? t('com.button.행삭제', '행삭제') : t('com.button.삭제', '삭제')}
            </Button>
          </ControlBtnGroup>
        </SubTitleLayout>
        <CustomGrid
          layoutDefinition={layoutDefinition}
          rowData={rowData}
          height={500}
          isReadOnly={!editable}
          isFilter={false}
          autoCheck={!editable}
          rowSelection={'multiple'}
          allowPinning={false}
          allowSorting={false}
          align="center"
          initialized={onInitialized}
          beginningEdit={onBeginningEdit}
          cellEditEnding={onCellEditEnding}
          onChangeCheckedItem={(items) => setCheckedItems(items)}
        />
      </>
    );
  }
);

SearchGrid.displayName = 'SearchGrid';
SelectedGrid.displayName = 'SelectedGrid';

export default EquipmentPopUp;
