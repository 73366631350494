import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';
import { IdleAssetDetail } from 'models/asst/IdleAsset';
import { IdleAssetReviewResult } from '../../models/asst/IdleAssetReviewResult';
import { IdleAssetReview } from '../../models/asst/IdleAssetReview';

export const findIdleAssetReviewResult = async (rvwReqId: string) => {
  const params = {
    rvwReqId: rvwReqId,
  };
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/asst/review-result`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...params }),
  };

  const response: CommonResponse<IdleAssetReviewResult[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as IdleAssetReviewResult[];
};

export const saveIdleAssetReviewResult = async (idleAssetDetail?: IdleAssetDetail[]) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/asst/review-result-dept`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: idleAssetDetail,
  };

  const response: CommonResponse<IdleAssetDetail[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as IdleAssetDetail[];
};

export const saveIdleAssetReviewResultCfm = async (idleAssetReviewResult?: IdleAssetReview[]) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/asst/review-result-confirm`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: idleAssetReviewResult,
  };

  const response: CommonResponse<IdleAssetReview[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as IdleAssetReview[];
};

export const saveIdleAssetReviewResultEqp = async (
  idleAssetReviewResult?: IdleAssetReviewResult[]
) => {
  console.log(idleAssetReviewResult);
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/asst/review-result-eqp`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: idleAssetReviewResult,
  };

  const response: CommonResponse<IdleAssetReviewResult[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as IdleAssetReviewResult[];
};
