/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { SelectionMode } from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { CellType, DataMap } from '@grapecity/wijmo.grid';
import {
  InputBox,
  SearchBox,
  SearchBoxRow,
  SearchButtonWrap,
  SearchCols,
  SearchRows,
} from '../../components/layouts/SearchBox';
import { ComboBox, MultiComboBox } from '../../components/selects/ComboBox';
import CustomInputWithSearch from '../../components/inputs/CustomInputWithSearch';
import SearchBoxButtons from '../../components/buttons/SearchBoxButtons';
import { useTranslation } from 'react-i18next';
import { SearchParam, SetupManager } from 'models/pjm/SetupManager';
import { Code } from '../../models/common/CommonCode';
import { getCommonCodeNames, getCommonCodeNamesCondition } from '../../apis/admin/CommonCode';
import {
  ControlBtnGroup,
  GlobalBtnGroup,
  SubTitleGroup,
  SubTitleLayout,
} from '../../components/layouts/ContentLayout';
import { Button } from '@mui/material';
import { IconButton } from '../../components/buttons/IconSVG';
import CustomGrid from '../../components/grids/CustomGrid';
import { CrudCode } from '../../models/common/Edit';
import { useMessageBar } from '../../hooks/useMessageBar';
import { getExcelFileName } from '../../utils/ExcelUtil';
import { CommonUtil } from '../../utils/CommonUtil';
import { GridStatusCellTemplate } from '../../components/grids/GridStatusCellRenderer';
import useEvent from 'react-use-event-hook';
import { hasRole } from 'utils/SessionUtil';
import _ from 'lodash';
import { toggleClass } from '@grapecity/wijmo';
import {
  getEquGroupParameter,
  getSetupManagerList,
  saveSetupManagerList,
} from 'apis/pjm/SetupManager';
import { useCommonModal } from 'hooks/useCommonModal';
import UserMultiModal from 'components/modals/common/UserMultiModal';
import { GatingContListPopUp } from 'pages/gtng/popup/GatingContListPopUp';

const SetupMgrManagementPage = () => {
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();
  const { openCommonModal } = useCommonModal();

  const [copCode, setCopCode] = useState<Code[]>([]);
  const [prdnProcCode, setPrdnProcCode] = useState<Code[]>([]);
  const [eqclIdCode, setEqclIdCode] = useState<Code[]>([]);

  const [errors, setErrors] = useState<any>({});

  const [hasAuth, setHasAuth] = useState<boolean>(false);

  const initSearchParam = {
    copCds: [],
    prdnProcCds: [],
    eqclIds: [],
  };

  const [searchParam, setSearchParam] = useState<SearchParam>(initSearchParam);

  const gridRef = useRef<any>();
  const [hitTest, setHitTest] = useState<any>();
  const [flexRef, setFlexRef] = useState<any>();
  const [flexItem, setFlexItem] = useState<any>();

  const [rowData, setRowData] = useState<SetupManager[]>([]);
  const [delData, setDelData] = useState<SetupManager[]>([]);

  const [isOpenUserMultiModal, setOpenUserMultiModal] = useState<boolean>(false);
  const [isOpenUserReadModal, setOpenUserReadModal] = useState<boolean>(false);

  const [userIds, setUserIds] = useState<string>('');
  const [wrtUserNm, setWrtUserNm] = useState<string>('');
  const [mode, setMode] = useState<string>('');
  const [modeNm, setModeNm] = useState<string>('');

  useEffect(() => {
    initCondition();
  }, []);

  const initCondition = async () => {
    const copCode: Code[] = await getCommonCodeNames('ELM_COP_CD'); // 법인코드
    const prdnProcCode = await getCommonCodeNamesCondition({
      optValCtn3: 'Y',
      cmnGrCd: 'PRDN_PROC_CD',
    });
    const eqclCode = await getEquGroupParameter();
    setCopCode(copCode);
    setPrdnProcCode(prdnProcCode);
    setEqclIdCode(eqclCode);

    if (hasRole('ADM') || hasRole('SETUP_MANAGER')) {
      setHasAuth(true);
    }
  };

  const isReadOnly = useMemo(() => {
    return hasAuth;
  }, [hasAuth]);

  const btnSearch = () => {
    getSetupManagerList(searchParam).then((result: SetupManager[]) => {
      if (result !== null) {
        setRowData(result);
      } else {
        openMessageBar({
          type: 'error',
          content: t(
            'com.msg.요청 정보 조회 중 오류가 발생했습니다.',
            '요청 정보 조회 중 오류가 발생했습니다.'
          ),
        });
      }
    });
  };

  const btnSave = () => {
    const validStatusCorU = rowData.filter(
      (element) => element.crudKey == CrudCode.CREATE || element.crudKey == CrudCode.UPDATE
    );
    const valid = validStatusCorU
      .map((rowNode, index) => {
        if (rowNode.crudKey != CrudCode.DELETE) {
          if (rowNode.copCd == null || rowNode.copCd == '')
            return `${t('pjm.label.법인을 선택해 주세요.', '법인을 선택해 주세요.')}`;
          if (rowNode.prdnProcCd == null || rowNode.prdnProcCd == '')
            return `${t('pjm.label.공정을 선택해 주세요.', '공정을 선택해 주세요.')}`;
          if (rowNode.eqclId == null || rowNode.eqclId == '')
            return `${t('pjm.label.설비군을 선택해 주세요.', '설비군을 선택해 주세요.')}`;
        }
      })
      .filter((element) => element !== undefined);
    if (valid.length) {
      const content = valid[0]?.toString();
      openMessageBar({ type: 'error', content: content || '' });
      return false;
    }

    // 중복 체크
    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.저장하시겠습니까?', '저장하시겠습니까?'),
      yesCallback: () => {
        const saveRows = rowData
          .map((rowNode) => {
            return rowNode.crudKey ? rowNode : null;
          })
          .filter((element) => element !== null) as SetupManager[];

        const saveRowsData = [...saveRows, ...delData];

        saveSetupManagerList(saveRowsData).then((result) => {
          if (!result) {
            openMessageBar({
              type: 'error',
              content: t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
            });
            return false;
          } else if (result.insertedRows === -1) {
            openMessageBar({
              type: 'error',
              content: t(
                'com.label.법인, 공정, 설비군은 중복 등록할 수 없습니다.',
                '법인, 공정, 설비군은 중복 등록할 수 없습니다.'
              ),
            });
            return false;
          } else {
            openMessageBar({
              type: 'confirm',
              content: t('com.label.저장되었습니다.', '저장되었습니다.'),
            });
            setDelData([]);
            btnSearch();
          }
        });
      },
      noCallback: () => {
        return false;
      },
    });
  };

  const btnAddRow = () => {
    const newRow = {
      crudKey: CrudCode.CREATE,
      useYn: 'Y',
    } as SetupManager;
    setRowData([newRow, ...rowData]);
  };

  const btnDelRow = () => {
    const selectedRowNodes = flexRef.selectedRows;

    const isSelected = selectedRowNodes.filter((item) => item.isSelected);
    const selectedData = isSelected.map(({ dataItem }) => dataItem);

    if (isSelected.length < 1) {
      openMessageBar({
        type: 'error',
        content: t('pjm.label.삭제할 행을 선택해 주세요.', '삭제할 행을 선택해 주세요.'),
      });
      return false;
    } else {
      const selectedIds = selectedRowNodes
        .map((rowNode) => {
          return parseInt(rowNode._idx!);
        })
        .reverse();

      selectedIds.forEach((idx) => {
        if (rowData[idx].crudKey === CrudCode.CREATE) {
          delete rowData[idx];
        } else {
          rowData[idx].crudKey = CrudCode.DELETE;
        }
      });

      const filteredData = rowData.filter((element) => element !== undefined);
      setRowData(filteredData);
    }
  };

  const btnCopyRow = () => {
    const selectedRowNodes = flexRef.selectedRows;

    const isSelected = selectedRowNodes.filter((item) => item.isSelected);

    if (isSelected.length < 1) {
      openMessageBar({
        type: 'error',
        content: t('pjm.label.복사할 행을 선택해 주세요.', '복사할 행을 선택해 주세요.'),
      });
      return false;
    } else {
      const selectNo = 0; //단일 선택 복사 시 0번째
      const copiedData = isSelected[selectNo].dataItem;
      const newRow = {
        crudKey: CrudCode.CREATE,
        useYn: copiedData?.useYn,
        prdnProcCd: copiedData?.prdnProcCd,
        prdnProcCdNm: copiedData?.prdnProcCdNm,
        copCd: copiedData?.copCd,
        copCdNm: copiedData?.copCdNm,
        poIssu: copiedData?.poIssu,
        poIssuNm: copiedData?.poIssuNm,
        atMat: copiedData?.atMat,
        atMatNm: copiedData?.atMatNm,
        fat: copiedData?.fat,
        fatNm: copiedData?.fatNm,
        fob: copiedData?.fob,
        fobNm: copiedData?.fobNm,
        eqpCrry: copiedData?.eqpCrry,
        eqpCrryNm: copiedData?.eqpCrryNm,
        istl: copiedData?.istl,
        istlNm: copiedData?.istlNm,
        ioc: copiedData?.ioc,
        iocNm: copiedData?.iocNm,
        wetRun: copiedData?.wetRun,
        wetRunNm: copiedData?.wetRunNm,
        qc: copiedData?.qc,
        qcNm: copiedData?.qcNm,
        sat: copiedData?.sat,
        satNm: copiedData?.satNm,
        smplPrdn: copiedData?.smplPrdn,
        smplPrdnNm: copiedData?.smplPrdnNm,
        mspd: copiedData?.mspd,
        mspdNm: copiedData?.mspdNm,
      } as SetupManager;
      setRowData([newRow, ...rowData]);
    }
  };

  const handleOnChange = (name: string, value: string) => {
    setSearchParam({
      ...searchParam,
      [name]: value,
    });
    setErrors({ ...errors, [name]: false });
  };

  const onInitialized = (grid) => {
    setFlexRef(grid);
    gridRef.current = grid;

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;
      const ht = grid.hitTest(e);
      grid.startEditing(true);

      if (ht.row < 0 || ht.col < 0) return;
      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      const item = grid.rows[ht.row].dataItem; // 선택한 row의 정보

      if (e.target instanceof HTMLButtonElement) {
        const col = grid.columns[ht.col]; // 선택한 컬럼의 정보
        const item = grid.rows[ht.row].dataItem; // 선택한 row의 정
        setFlexItem(item);
        setHitTest(ht);
        const userIds = item[e.target.id];
        switch (e.target.id) {
          case e.target.id:
            setOpenUserMultiModal(true);
            setUserIds(userIds);
            setMode(e.target.id);
            setModeNm(e.target.id + 'Nm');
            break;
          case 'readOpenModal':
            setOpenUserReadModal(true);
            break;
        }
      }
    });
  };

  const layoutDefinition = useMemo(() => {
    const copCodeMap = new DataMap(copCode, 'cmnCd', 'cmnCdNm');
    const prdnProcCodeMap = new DataMap(prdnProcCode, 'cmnCd', 'cmnCdNm');
    const eqclIdCodeMap = new DataMap(eqclIdCode, 'cmnCd', 'cmnCd');
    return [
      {
        binding: 'crudKey',
        header: String(t('com.label.상태', '상태')),
        isReadOnly: true,
        width: 50,
        align: 'center',
        cellTemplate: GridStatusCellTemplate,
      },
      {
        binding: 'copCd',
        header: String(t('pjm.label.법인', '법인')),
        width: 100,
        align: 'center',
        cssClass: 'WijmoSelect',
        dataMap: copCodeMap,
        cellTemplate: (item) => {
          if (item.item.crudKey === CrudCode.CREATE) {
            if (copCodeMap.getDisplayValue(item.value) == undefined) {
              return `<span>${item.text}</span>`;
            } else {
              return `<span>${copCodeMap.getDisplayValue(item.text)}</span>`;
            }
          } else {
            return `<span>${item.item.copCdNm}</span>`;
          }
        },
      },
      {
        binding: 'prdnProcCd',
        header: String(t('pjm.label.공정', '공정')),
        width: 100,
        align: 'center',
        cssClass: 'WijmoSelect',
        dataMap: prdnProcCodeMap,
        cellTemplate: (item) => {
          if (item.item.crudKey === CrudCode.CREATE) {
            if (prdnProcCodeMap.getDisplayValue(item.value) == undefined) {
              return `<span>${item.text}</span>`;
            } else {
              return `<span>${prdnProcCodeMap.getDisplayValue(item.text)}</span>`;
            }
          } else {
            return `<span>${item.item.prdnProcCdNm}</span>`;
          }
        },
      },
      {
        binding: 'eqclId',
        header: String(t('pjm.label.설비군', '설비군')),
        width: 90,
        align: 'center',
        cssClass: 'WijmoSelect',
        dataMap: eqclIdCodeMap,
        cellTemplate: (item) => {
          if (item.item.crudKey !== null) {
            return `<span>${
              item.item.eqclId === undefined ? '' : eqclIdCodeMap.getDisplayValue(item.item.eqclId)
            }</span>`;
          } else {
            return `<span>${eqclIdCodeMap.getDisplayValue(item.item.eqclId)}</span>`;
          }
        },
      },
      {
        binding: 'poIssu',
        header: String(t('pjm.label.PO발행', 'PO발행')),
        width: 130,
        align: 'left',
        isReadOnly: !hasAuth,
        cssClass: 'WijmoFind',
        cellTemplate: (o) =>
          setupMgrId(o, o.item.crudKey, o.item.poIssu, o.item.poIssuNm, 'poIssu'),
      },
      {
        binding: 'poIssuNm',
        visible: false,
      },
      {
        binding: 'atMat',
        header: String(t('pjm.label.시운전자재', '시운전자재')),
        width: 130,
        align: 'left',
        isReadOnly: !hasAuth,
        cssClass: 'WijmoFind',
        cellTemplate: (o) => setupMgrId(o, o.item.crudKey, o.item.atMat, o.item.atMatNm, 'atMat'),
      },
      {
        binding: 'atMatNm',
        visible: false,
      },
      {
        binding: 'fat',
        header: String(t('pjm.label.FAT', 'FAT')),
        width: 130,
        align: 'left',
        isReadOnly: !hasAuth,
        cssClass: 'WijmoFind',
        cellTemplate: (o) => setupMgrId(o, o.item.crudKey, o.item.fat, o.item.fatNm, 'fat'),
      },
      {
        binding: 'fatNm',
        visible: false,
      },
      {
        binding: 'fob',
        header: String(t('pjm.label.FOB', 'FOB')),
        width: 130,
        align: 'left',
        isReadOnly: !hasAuth,
        cssClass: 'WijmoFind',
        cellTemplate: (o) => setupMgrId(o, o.item.crudKey, o.item.fob, o.item.fobNm, 'fob'),
      },
      {
        binding: 'fobNm',
        visible: false,
      },
      {
        binding: 'eqpCrry',
        header: String(t('pjm.label.설비반입', '설비반입')),
        width: 130,
        align: 'left',
        isReadOnly: !hasAuth,
        cssClass: 'WijmoFind',
        cellTemplate: (o) =>
          setupMgrId(o, o.item.crudKey, o.item.eqpCrry, o.item.eqpCrryNm, 'eqpCrry'),
      },
      {
        binding: 'eqpCrryNm',
        visible: false,
      },
      {
        binding: 'istl',
        header: String(t('pjm.label.설치', '설치')),
        width: 130,
        align: 'left',
        isReadOnly: !hasAuth,
        cssClass: 'WijmoFind',
        cellTemplate: (o) => setupMgrId(o, o.item.crudKey, o.item.istl, o.item.istlNm, 'istl'),
      },
      {
        binding: 'istlNm',
        visible: false,
      },
      {
        binding: 'ioc',
        header: String(t('pjm.label.I/O Check', 'I/O Check')),
        width: 130,
        align: 'left',
        isReadOnly: !hasAuth,
        cssClass: 'WijmoFind',
        cellTemplate: (o) => setupMgrId(o, o.item.crudKey, o.item.ioc, o.item.iocNm, 'ioc'),
      },
      {
        binding: 'iocNm',
        visible: false,
      },
      {
        binding: 'wetRun',
        header: String(t('pjm.label.Wet Run', 'Wet Run')),
        width: 130,
        align: 'left',
        isReadOnly: !hasAuth,
        cssClass: 'WijmoFind',
        cellTemplate: (o) =>
          setupMgrId(o, o.item.crudKey, o.item.wetRun, o.item.wetRunNm, 'wetRun'),
      },
      {
        binding: 'wetRunNm',
        visible: false,
      },
      {
        binding: 'qc',
        header: String(t('pjm.label.Quality Check', 'Quality Check')),
        width: 130,
        align: 'left',
        isReadOnly: !hasAuth,
        cssClass: 'WijmoFind',
        cellTemplate: (o) => setupMgrId(o, o.item.crudKey, o.item.qc, o.item.qcNm, 'qc'),
      },
      {
        binding: 'qcNm',
        visible: false,
      },
      {
        binding: 'sat',
        header: String(t('pjm.label.SAT', 'SAT')),
        width: 130,
        align: 'left',
        isReadOnly: !hasAuth,
        cssClass: 'WijmoFind',
        cellTemplate: (o) => setupMgrId(o, o.item.crudKey, o.item.sat, o.item.satNm, 'sat'),
      },
      {
        binding: 'satNm',
        visible: false,
      },
      {
        binding: 'smplPrdn',
        header: String(t('pjm.label.샘플생산', '샘플생산')),
        width: 130,
        align: 'left',
        isReadOnly: !hasAuth,
        cssClass: 'WijmoFind',
        cellTemplate: (o) =>
          setupMgrId(o, o.item.crudKey, o.item.smplPrdn, o.item.smplPrdnNm, 'smplPrdn'),
      },
      {
        binding: 'smplPrdnNm',
        visible: false,
      },
      {
        binding: 'mspd',
        header: String(t('pjm.label.양산', '양산')),
        width: 130,
        align: 'left',
        isReadOnly: !hasAuth,
        cssClass: 'WijmoFind',
        cellTemplate: (o) => setupMgrId(o, o.item.crudKey, o.item.mspd, o.item.mspdNm, 'mspd'),
      },
      {
        binding: 'mspdNm',
        visible: false,
      },
    ];
  }, [rowData]);

  const setupMgrId = (item, crudKey, mgrId, mgrNm, mgrBinding) => {
    if (crudKey == CrudCode.CREATE) {
      if (mgrId === null || mgrId === undefined) {
        return `<span></span><button id="${
          hasAuth === true ? `${mgrBinding}` : 'readOpenModal'
        }" />`;
      } else {
        return `<span>${mgrNm}</span><button id="${
          hasAuth === true ? `${mgrBinding}` : 'readOpenModal'
        }" />`;
      }
    } else {
      if (mgrId === null || mgrId === undefined) {
        return `<span></span><button id="${
          hasAuth === true ? `${mgrBinding}` : 'readOpenModal'
        }" />`;
      } else {
        return `<span>${mgrNm}</span><button id="${
          hasAuth === true ? `${mgrBinding}` : 'readOpenModal'
        }" />`;
      }
    }
  };

  const onItemFormatter = useEvent((panel, row, col, cell) => {
    if (CellType.Cell === panel.cellType) {
      const binding = panel.columns[col].binding;
      const item = panel.rows[row].dataItem;

      if (item.crudKey !== CrudCode.CREATE) {
        if (['copCd', 'prdnProcCd', 'eqclId'].includes(binding)) {
          cell.ariaReadOnly = true;
          toggleClass(cell, 'WijmoSelect', !isReadOnly);
          if (isReadOnly && cell.firstElementChild instanceof HTMLButtonElement) {
            cell.firstChild.remove();
          }
        }
      }
    }
  });

  const beginningEdit = useEvent((grid, e) => {
    const col = grid.columns[e.col];
    const binding = col.binding;
    const item = grid.rows[e.row].dataItem;

    // readonly 설정
    if (
      [
        'ivesCnsn',
        'poIssu',
        'atMat',
        'fat',
        'fob',
        'eqpCrry',
        'istl',
        'ioc',
        'wetRun',
        'qc',
        'sat',
        'smplPrdn',
        'mspd',
      ].includes(binding)
    ) {
      e.cancel = true;
    }

    if (item.crudKey !== CrudCode.CREATE) {
      if (['copCd', 'prdnProcCd', 'eqclId'].includes(binding)) {
        e.cancel = true;
      }
    }

    const selectedEqclId: Code[] = [];
    if (binding === 'prdnProcCd' && item.crudKey === CrudCode.CREATE) {
      item.eqclId = '';
    }
    // 그리드 공정->설비군 하이라키구조
    // if (binding === 'eqclId' && item.crudKey === CrudCode.CREATE) {
    //   const selectedCode = dtalProcListCode.filter((code) => code.optValCtn1 === item.prdnProcCd);
    //   const result: Code[] = [];
    //   for (let i = 0; i < selectedCode.length; i++) {
    //     for (let j = 0; j < eqclIdCode.length; j++) {
    //       if (selectedCode[i].optValCtn2 === eqclIdCode[j].cmnCd) {
    //         result.push({ cmnCd: selectedCode[i].optValCtn2, cmnCdNm: eqclIdCode[j].cmnCdNm });
    //       }
    //     }
    //   }
    //   const deleteDuplication = result.filter((code, index) => {
    //     return (
    //       result.findIndex((scdCode) => {
    //         return code.cmnCd === scdCode.cmnCd;
    //       }) === index
    //     );
    //   });
    //   col.dataMap = new DataMap(deleteDuplication, 'cmnCd', 'cmnCdNm');
    //   selectedEqclId.concat(deleteDuplication);
    // }
  });

  const btnReload = () => {
    setSearchParam(
      (prev) =>
        Object.keys(prev).reduce(
          (acc, cur) => Object.assign(acc, { [cur]: initSearchParam[cur] || '' }),
          {}
        ) as SearchParam
    );
  };

  const callbackUserData = (callbackData) => {
    flexItem[mode] = callbackData.userId;
    flexItem[modeNm] = callbackData.empNm;
    if (flexItem['crudKey'] !== CrudCode.CREATE && flexItem['crudKey'] !== CrudCode.DELETE) {
      flexItem['crudKey'] = CrudCode.UPDATE;
    }
    gridRef.current.refresh();
  };

  return (
    <>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows>
              <SearchCols>
                <label>{String(t('pjm.label.법인', '법인'))}</label>
                <MultiComboBox
                  id="prdnProcCd"
                  options={copCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.prdnProcCds || []}
                  onChange={(value) => {
                    handleOnChange('copCd', value.join());
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{String(t('pjm.label.공정', '공정'))}</label>
                <MultiComboBox
                  id="prdnProcCd"
                  options={prdnProcCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.prdnProcCds || []}
                  onChange={(value) => {
                    handleOnChange('prdnProcCd', value.join());
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{String(t('pjm.label.설비군', '설비군'))}</label>
                <MultiComboBox
                  id="eqpGrcd"
                  options={eqclIdCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.eqclIds || []}
                  onChange={(value) => {
                    handleOnChange('eqclId', value.join());
                  }}
                />
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchButtonWrap>
            <Button
              css={IconButton.button}
              className="reload"
              onClick={btnReload}
              disableRipple
            ></Button>
            <Button css={IconButton.button} className="find" onClick={btnSearch} disableRipple>
              {t('com.button.조회', '조회')}
            </Button>
          </SearchButtonWrap>
        </SearchBoxRow>
      </SearchBox>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('pjm.label.공정별 담당자 List', '공정별 담당자 List')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{rowData?.length ?? 0}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          {hasAuth && (
            <Button css={IconButton.button} className="addRow" onClick={btnAddRow} disableRipple>
              {t('com.button.행추가', '행추가')}
            </Button>
          )}
          {hasAuth && (
            <Button css={IconButton.button} className="delRow" onClick={btnDelRow} disableRipple>
              {t('com.button.행삭제', '행삭제')}
            </Button>
          )}
          {hasAuth && (
            <Button css={IconButton.button} className="copyRow" onClick={btnCopyRow} disableRipple>
              {t('com.button.행복사', '행복사')}
            </Button>
          )}
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={() => {
              CommonUtil.exportWijmoExcelGrid(
                flexRef,
                t('pjm.label.공정별 담당자', '공정별 담당자'),
                t('pjm.label.공정별 담당자', '공정별 담당자')
              );
            }}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
          <Button css={IconButton.button} className="refresh" onClick={btnSearch} disableRipple>
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        initialized={onInitialized}
        itemFormatter={onItemFormatter}
        beginningEdit={beginningEdit}
        height={520}
        frozenColumns={4}
        autoCheck={true}
        isFilter={false}
        allowPinning={false}
      />
      <GlobalBtnGroup>
        {hasAuth && (
          <Button css={IconButton.button} className="save" onClick={btnSave}>
            {t('com.button.저장', '저장')}
          </Button>
        )}
      </GlobalBtnGroup>
      {isOpenUserMultiModal && (
        <UserMultiModal
          open={isOpenUserMultiModal}
          close={() => setOpenUserMultiModal(false)}
          title={t('com.label.담당자 조회', '담당자 조회')}
          mode={mode}
          defaultUserId={userIds}
          singleSelect={false}
          onCallback={(callbackData: any) => {
            if (callbackData.userId === '') {
              openMessageBar({
                type: 'warning',
                content: t('com.msg.선택된 사용자가 없습니다.', '선택된 사용자가 없습니다.'),
              });
              return false;
            }
            callbackUserData(callbackData);
          }}
        />
      )}
      {isOpenUserReadModal && (
        <GatingContListPopUp
          open={isOpenUserReadModal}
          close={() => setOpenUserReadModal(false)}
          title={t('com.label.담당자', '담당자')}
          initParam={userIds}
        />
      )}
    </>
  );
};
export default SetupMgrManagementPage;
