/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { DialogContent, Button, Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { di, TitleWrap, PopupBtnGroup } from 'components/layouts/Dialog';
import {
  SearchBox,
  SearchRows,
  SearchCols,
  SearchBoxRow,
  InputBox,
  SearchButtonWrap,
} from 'components/layouts/SearchBox';
import { ContentGrid } from 'components/layouts/ContentGrid';
import { SubTitleLayout, SubTitleGroup } from 'components/layouts/ContentLayout';
import { UseToggle } from 'components/inputs/CustomInput';
import { IconButton } from 'components/buttons/IconSVG';
import { useTranslation } from 'react-i18next';
import { Partner, PartnerCondition } from 'models/common/Partner';

import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import useSessionStore from 'stores/useSessionStore';
// Wijmo library
import { flexGridTooltip } from 'models/ip/Public';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { DataMap, SelectionMode } from '@grapecity/wijmo.grid';
import { FlexGridFilter } from '@grapecity/wijmo.grid.filter';
import { ExcpPartner, ExcpPartnerCondition } from 'models/admin/ExcpPartner';
import { getExcpList } from 'apis/admin/ExcpPartner';

type Props = {
  open: boolean;
  close: () => void;
  condition?: {
    ptnrId?: string;
    ptnrNm?: string;
  };
  onCallback: (item: Partner | Partner[]) => void;
  singleSelect?: boolean;
};
const PartnerModal = ({ open, close, condition, onCallback, singleSelect = true }: Props) => {
  const { t } = useTranslation();
  const { gridNoRowsTemplate } = useSessionStore();
  const [rowData, setRowData] = useState<Partner[]>([]);
  const [flexRef, setflexRef] = useState<any>();
  const [hitTest, setHitTest] = useState<any>();
  const [flexItem, setflexItem] = useState<any>();
  const [fieldId, setFieldId] = useState<any>();
  const [partnerCondition, setPartnerCondition] = useState<ExcpPartner>({
    excpId: condition?.ptnrId || '',
    excpNm: condition?.ptnrNm || '',
    rmk: '',
    useYn: 'Y',
    rpsnNm: '',
  });
  const [colWidthMap, setColWidthMap] = useState<any>({
    qselect: 120,
    excpId: 200,
    excpNm: '*',
    rpsnNm: '*',
    useYn: 100,
  });

  useEffect(() => {
    if (!_.isEmpty(condition?.ptnrId) || !_.isEmpty(condition?.ptnrNm)) {
      handleSearch(partnerCondition);
    }
  }, []);

  const handleClose = () => {
    close();
  };

  const handleSearch = async (condition: ExcpPartnerCondition) => {
    const response = await getExcpList({ ...condition } as ExcpPartnerCondition);
    if (response) {
      const rowData = response || [];
      setRowData(rowData);
    }
  };

  const handleConfirm = () => {
    const rows = flexRef.selectedRows.length > 0 ? [flexRef.selectedRows[0].dataItem] : [];
    onCallback(singleSelect && rows.length > 0 ? rows[0] : rows);
    close();
  };

  const handleButtonClick = (params) => {
    // data에 현재 행의 데이터가 포함되어 있음123345
    onCallback(!_.isNull(params.data) ? params.data : null);
    close();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch(partnerCondition);
    }
  };

  const handleResetCondition = () => {
    setPartnerCondition(
      (prev) =>
        Object.keys(prev).reduce(
          (acc, cur) => Object.assign(acc, { [cur]: cur === 'useYn' ? 'Y' : '' }),
          {}
        ) as ExcpPartner
    );
  };

  const handleConditionChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    setPartnerCondition({ ...partnerCondition, [e.target.name]: e.target.value });
  };

  const LayoutDefinition = () => {
    return [
      // {
      //   width: 37,
      //   checkboxSelection: true,
      //   headerCheckboxSelection: true,
      // },
      {
        header: 'Quick Select',
        width: colWidthMap.qselect,
        cellTemplate: (params) => `<button id='qSelect'/>`,
        isReadOnly: true,
        align: 'center',
        cssClass: 'WijmoRelay',
      },
      {
        binding: 'excpId',
        header: String(t('com.label.협력사 ID', '협력사 ID')),
        width: colWidthMap.excpId,
        isReadOnly: true,
        align: 'center',
      },
      {
        binding: 'excpNm',
        header: String(t('com.label.협력사명', '협력사명')),
        isReadOnly: true,
        width: colWidthMap.excpNm,
        // flex: 1,
        align: 'center',
      },
      {
        binding: 'rpsnNm',
        header: String(t('com.label.대표자명', '대표자명')),
        isReadOnly: true,
        width: colWidthMap.rpsnNm,
        align: 'center',
      },
      {
        binding: 'useYn',
        header: String(t('com.label.사용여부', '사용여부')),
        isReadOnly: true,
        width: colWidthMap.useYn,
        align: 'center',
      },
    ];
  };

  const state = {
    itemsSource: rowData,
    layoutDefinition: LayoutDefinition(),
  };

  const onInitialized = (grid) => {
    new Selector(grid);
    new FlexGridFilter(grid);
    setflexRef(grid);

    //셀렉트 방식
    grid.selectionMode = SelectionMode.Row;

    //columnGroups속성 사용할 경우 -> Column 너비 수동 지정
    grid.resizingColumn.addHandler((s, e) => {
      const cell = s.columnHeaders.getCellElement(0, e.col);
      const col = e.panel.columns[e.col];
      colWidthMap[col.binding] = col.width;
      setColWidthMap(colWidthMap);
    });

    grid.hostElement.addEventListener('dblclick', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;

      const ht = grid.hitTest(e);
      setHitTest(ht);

      if (ht.row < 0 || ht.col < 0) return;

      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      // row 선택시 체크박스 체크
      for (let i = 0; i < len; i++) grid.rows[i].isSelected = false;
      grid.rows[ht.row].isSelected = true;

      const col = grid.columns[ht.col];
      const item = grid.rows[ht.row].dataItem;
      handleButtonClick({ data: item });
    });

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;

      const ht = grid.hitTest(e);
      setHitTest(ht);

      if (ht.row < 0 || ht.col < 0) return;

      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      // row 선택시 체크박스 체크
      for (let i = 0; i < len; i++) grid.rows[i].isSelected = false;
      grid.rows[ht.row].isSelected = true;

      const col = grid.columns[ht.col];
      const item = grid.rows[ht.row].dataItem;

      setflexItem(item);
      setFieldId(col.binding);

      switch (e.target.id) {
        case 'qSelect':
          handleButtonClick({ data: item });
          break;
      }

      grid.refresh();
    });
  };

  return (
    <Dialog open={true} onClose={handleClose} css={di.dialog} fullWidth maxWidth="lg">
      <TitleWrap>
        <h2>{t('com.label.협력사 조회', '협력사 조회')}</h2>
        <Button onClick={handleClose} disableRipple></Button>
      </TitleWrap>
      <DialogContent>
        <SearchBox>
          <SearchBoxRow>
            <InputBox>
              <SearchRows>
                <SearchCols>
                  <label>{t('com.label.협력사 ID', '협력사 ID')}</label>
                  <CustomInputWithSearch
                    name="excpId"
                    placeholder={String(
                      t('com.label.협력사 ID를 입력해 주세요.', '협력사 ID를 입력해 주세요.')
                    )}
                    value={partnerCondition.excpId}
                    onChange={handleConditionChange}
                    onKeyDown={handleKeyDown}
                  />
                </SearchCols>
                <SearchCols>
                  <label>{t('com.label.협력업체명', '협력업체명')}</label>
                  <CustomInputWithSearch
                    name="excpNm"
                    placeholder={String(
                      t('com.label.협력업체명을 입력해 주세요.', '협력업체명을 입력해 주세요.')
                    )}
                    value={partnerCondition.excpNm}
                    onChange={handleConditionChange}
                    onKeyDown={handleKeyDown}
                  />
                </SearchCols>
                <SearchCols>
                  <label>{t('com.label.대표자명', '대표자명')}</label>
                  <CustomInputWithSearch
                    name="rpsnNm"
                    placeholder={String(
                      t('com.label.대표자명을 입력해주세요.', '대표자명을 입력해주세요.')
                    )}
                    value={partnerCondition.rpsnNm}
                    onChange={handleConditionChange}
                    onKeyDown={handleKeyDown}
                  />
                </SearchCols>
              </SearchRows>
              <SearchRows>
                <SearchCols>
                  <label>{t('com.label.사용여부', '사용여부')}</label>
                  <UseToggle className="switch">
                    <input
                      type="checkbox"
                      name="useYn"
                      value={partnerCondition.useYn}
                      checked={partnerCondition.useYn === 'Y'}
                      onChange={() => {
                        setPartnerCondition({
                          ...partnerCondition,
                          useYn: partnerCondition.useYn === 'Y' ? 'N' : 'Y',
                        });
                      }}
                    />
                    <span className="slider"></span>
                    <span
                      className="labels"
                      data-on={t('com.label.사용', '사용')}
                      data-off={t('com.label.미사용', '미사용')}
                    ></span>
                  </UseToggle>
                </SearchCols>
              </SearchRows>
            </InputBox>
            <SearchButtonWrap>
              <Button
                css={IconButton.button}
                className="reload"
                onClick={handleResetCondition}
                disableRipple
              ></Button>
              <Button
                css={IconButton.button}
                className="find"
                onClick={() => handleSearch(partnerCondition)}
                disableRipple
              >
                {t('com.button.조회', '조회')}
              </Button>
            </SearchButtonWrap>
          </SearchBoxRow>
        </SearchBox>
        <SubTitleLayout>
          <SubTitleGroup>
            <h3>{t('com.label.사용자', '사용자')}</h3>
            <span className="total">
              {t('com.label.총', '총')} <span>{rowData.length}</span>
              {t('com.label.건', '건')}
            </span>
            {/* <PaginationSize
              defaultPageSize={pageSize}
              onChange={(value) => {
                setPageSize(value);
                paginationRef.current?.handlePageSizeChange(value);
              }}
            /> */}
          </SubTitleGroup>
          {/*<ControlBtnGroup>*/}
          {/*  <Button css={IconButton.button} className="Exceldown" disableRipple>*/}
          {/*    다운로드*/}
          {/*  </Button>*/}
          {/*  <Button css={IconButton.button} className="refresh" disableRipple>*/}
          {/*    새로고침*/}
          {/*  </Button>*/}
          {/*</ControlBtnGroup>*/}
        </SubTitleLayout>
        <ContentGrid
          className={rowData.length < 1 ? 'noData' : ''}
          style={{ height: '380px', marginBottom: rowData.length < 1 ? 0 : '32px' }}
        >
          <FlexGrid
            columnGroups={state.layoutDefinition}
            itemsSource={state.itemsSource}
            deferResizing={false}
            //selectionMode={'ListBox'}
            //headersVisibility="Column"
            //showSelectedHeaders="All"
            //showMarquee={false}
            //showAlternatingRows={false}
            //alternatingRowStep={0}
            // allowPinning="ColumnRange" // 열 고정 방식
            //allowMerging="ColumnHeaders"
            // autoGenerateColumns={false}
            selectionMode={'Row'}
            stickyHeaders={true}
            //isReadOnly={true}
            // itemsSourceChanging={onCellValueChanged}
            // formatItem={flexGridTooltip}
            // selectionChanged={onFlexGridSelectionChanged}
            initialized={onInitialized}
            style={{ height: (rowData || []).length < 1 ? '' : '380px' }}
          />
          <div className="noData" style={{ height: (rowData || []).length < 1 ? '380px' : '' }}>
            <span>
              {t('com.label.조회 가능한 데이터가 없습니다.', '조회 가능한 데이터가 없습니다.')}
            </span>
          </div>
          {/* <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            rowHeight={32}
            rowSelection={singleSelect ? 'single' : 'multiple'}
            //suppressRowClickSelection={true}
            onCellDoubleClicked={handleConfirm}
            overlayNoRowsTemplate={gridNoRowsTemplate}
            onGridReady={(params: GridReadyEvent) => {
              params.api.sizeColumnsToFit();
            }}
          ></AgGridReact> */}
        </ContentGrid>
      </DialogContent>
      <PopupBtnGroup>
        <Button className="cancel" onClick={handleClose} disableRipple>
          {t('com.button.취소', '취소')}
        </Button>
        <Button css={IconButton.button} className="confirm" onClick={handleConfirm} disableRipple>
          {t('com.button.선택', '선택')}
        </Button>
      </PopupBtnGroup>
    </Dialog>
  );
};
export default PartnerModal;
