/** @jsxImportSource @emotion/react */
import React, { useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import CustomGrid from 'components/grids/CustomGrid';

import {
  UtMatrixStandardEquipment,
  UtMatrixStandardEquipmentCondition,
} from 'models/ut/UtMatrixStandardEquipment';
import { findUtMatrixStandardEquipmentList } from 'apis/ut/UtMatrixLibrary';

interface Props {
  onGridRef: (ref: any) => void;
  condition?: UtMatrixStandardEquipmentCondition;
  onClickDetail: (item: UtMatrixStandardEquipment) => void;
  onClickApprove: (aprReqId: string) => void;
}

const UtMatrixLibraryMasterGrid = (
  { onGridRef, condition, onClickDetail, onClickApprove }: Props,
  ref
) => {
  const gridRef = useRef<any>();
  const { t } = useTranslation();

  const [rowData, setRowData] = useState<UtMatrixStandardEquipment[]>([]);

  useImperativeHandle(ref, () => ({
    search: async (condition) => {
      await handleSearch(condition);
    },
    getSelectedRow: () => getSelectedRow(),
  }));

  const getSelectedRow = () => {
    return gridRef.current.rows.filter((r) => r.isSelected).map((o) => o.dataItem);
  };

  const handleSearch = async (condition) => {
    const response = await findUtMatrixStandardEquipmentList(condition);
    const rowData = response || [];
    setRowData(rowData);
    // [24.12.12] 조회 시 첫번째 행 표준설비코드(좌측그리드)의 표준라이브러리(우측그리드) 목록 조회
    if (rowData.length) {
      const firstRow = [{ ...rowData[0] }] || [];
      onClickDetail(firstRow as UtMatrixStandardEquipment);
      setTimeout(() => {
        if (gridRef.current.rows.length) {
          gridRef.current.rows[0].isSelected = true;
          gridRef.current.refresh();
        }
      }, 100);
    }
  };

  const layoutDefinition = [
    {
      binding: 'stndEqpId',
      header: String(t('ut.label.표준설비코드', '표준설비코드')),
      align: 'center',
      width: 120,
    },
    {
      binding: 'prdnProcCdNm',
      header: String(t('ut.label.공정', '공정')),
      align: 'center',
      width: 100,
    },
    {
      binding: 'eqclIdNm',
      header: String(t('ut.label.설비군', '설비군')),
      align: 'center',
      width: 100,
    },
    {
      binding: 'dtalProcCdNm',
      header: String(t('ut.label.세부공정', '세부공정')),
      align: 'left',
      width: 100,
    },
    // {
    //   header: String(t('ut.label.상세', '상세')),
    //   binding: 'detailNo',
    //   width: 70,
    //   isReadOnly: true,
    //   cssClass: 'WijmoPlay',
    //   cellTemplate: (params) => `<Button />`,
    // },
    {
      header: String(t('ut.label.Main', 'Main')),
      binding: 'eqpMainNm',
      width: 100,
      align: 'center',
    },
    {
      binding: String(t('eqpMchNm')),
      header: String(t('ut.label.Machine', 'Machine')),
      align: 'left',
      width: 100,
    },
    {
      binding: 'aprReqId',
      header: String(t('ut.label.요청번호', '요청번호')),
      width: 120,
      align: 'left',
      cssClass: 'WijmoPopup',
      cellTemplate: (params) => {
        if (params.value == null || params.value == '') {
          return '';
        } else {
          return `${params.value} <Button />`;
        }
      },
    },
    {
      binding: 'libCnt',
      header: String(t('ut.label.Library 수', 'Library 수')),
      width: 100,
      align: 'center',
    },
  ];

  const onInitialized = (grid) => {
    onGridRef && onGridRef(grid);
    gridRef.current = grid;
    grid.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;

      const binding = grid.columns[ht.col].binding;
      const item = grid.rows[ht.row].dataItem;

      if (ht.panel === grid.cells) {
        // 상세보기
        // if ('detailNo' === binding) {
        //   onClickDetail(item);
        // }
        // 결재 요청 상세보기
        if ('aprReqId' === binding) {
          if (item.aprReqId) {
            onClickApprove(item.aprReqId);
          }
        }
      }
    });
  };

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('ut.label.표준설비코드 List', '표준설비코드 List')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{(rowData || []).length.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData || []}
        isReadOnly={true}
        height={510}
        autoCheck={true}
        allowPinning={false}
        excludeFilter={['detailNo']}
        rowSelection={'multiple'}
        initialized={onInitialized}
        onChangeCheckedItem={(items) => {
          onClickDetail(getSelectedRow());
        }}
      />
    </>
  );
};

export default React.forwardRef(UtMatrixLibraryMasterGrid);
