/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Button, Select } from '@mui/material';
import { Checkbox } from '@mui/material';
import { SelectionMode } from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { DatePickerWrap } from 'components/inputs/DatePicker';
import { ContainerLayout } from 'components/layouts/ContainerLayout';
import { toggleClass } from '@grapecity/wijmo';
import {
  ContentLayout,
  SubTitleLayout,
  SubTitleGroup,
  ControlBtnGroup,
  GlobalBtnGroup,
} from 'components/layouts/ContentLayout';
import {
  SearchBox,
  SearchBoxRow,
  InputBox,
  SearchRows,
  SearchCols,
  SearchButtonWrap,
} from 'components/layouts/SearchBox';
import Datepicker from 'react-tailwindcss-datepicker';
import { IconButton } from 'components/buttons/IconSVG';
import { useTranslation } from 'react-i18next';
import { getCommonCodeNames, getCommonCodeNamesCondition } from 'apis/admin/CommonCode';
import { ComboBox, MultiComboBox } from 'components/selects/ComboBox';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'hooks/useMessageBar';
import CustomGrid from 'components/grids/CustomGrid';
import { getExcelFileName } from 'utils/ExcelUtil';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';
import { useNavigate } from 'react-router-dom';
import { Code } from 'models/common/CommonCode';
import { CellType, DataMap } from '@grapecity/wijmo.grid';
import useSessionStore from 'stores/useSessionStore';
import { hasRole } from 'utils/SessionUtil';
import { CrudCode } from 'models/common/Edit';
import useEvent from 'react-use-event-hook';

import _ from 'lodash';
import { SearchParam, UtMatrixStandardCode } from 'models/ut/UtMatrixStandardCode';
import {
  checkDuplicationStandardCode,
  getUtMatrixStandardCodeList,
  saveUtMatrixStandardCodeList,
} from 'apis/ut/UtMatrixStandartdCode';
import { GridStatusCellTemplate } from 'components/grids/GridStatusCellRenderer';
import { SuccessOrNot } from '../../models/common/RestApi';

const UtMatrixStandardCodePage = () => {
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();
  const { openCommonModal } = useCommonModal();
  const navigate = useNavigate();

  const gridRef = useRef<any>();
  const [hitTest, setHitTest] = useState<any>();
  const [flexRef, setFlexRef] = useState<any>();
  const [flexItem, setFlexItem] = useState<any>();

  const [rowData, setRowData] = useState<UtMatrixStandardCode[]>([]);

  const [getCommPrdnProcCode, setCommPrdnProcCode] = useState<Code[]>([]); // 공정 공정코드
  const [getCommEqclIdCodeList, setCommEqclIdCodeList] = useState<Code[]>([]); // 설비군 공통코드
  const [getCommDtalProcCodeList, setCommDtalProcCodeList] = useState<Code[]>([]); // 세부공정 공통코드

  const [eqclIdCode, setEqclIdCode] = useState<Code[]>([]); // 설비군 조회조건
  const [dtalProcCode, setDtalProcCode] = useState<Code[]>([]); // 세부공정 조회조건

  const [dtalProcListCode, setDtalProcListCode] = useState<Code[]>([]); // 공정-설비군-세부공정 공통코드(하이라키)

  const [changeCode, setChangeCode] = useState<string>('');

  const initSearchParam = {
    prdnProcCds: [],
    eqclIds: [],
    dtalProcCds: [],
    eqpMchId: '',
  };

  const [searchParam, setSearchParam] = useState<SearchParam>(initSearchParam);

  const [hasAuth, setHasAuth] = useState<boolean>(false);

  const [delData, setDelData] = useState<UtMatrixStandardCode[]>([]);

  const isReadOnly = useMemo(() => {
    return hasAuth;
  }, [hasAuth]);

  useEffect(() => {
    initCondition();
    if (hasRole('ADM') || hasRole('UT_MANAGER')) {
      setHasAuth(true);
    }
  }, []);

  const initCondition = async () => {
    const prdnProcCd = await getCommonCodeNamesCondition({
      optValCtn1: 'Y',
      cmnGrCd: 'PRDN_PROC_CD',
    });
    const eqclIdCd = await getCommonCodeNamesCondition({
      optValCtn1: 'Y',
      cmnGrCd: 'EQCL_ID',
    });
    const dtalProcCd = await getCommonCodeNames('DTAL_PROC_CD');

    setCommPrdnProcCode(prdnProcCd);
    setCommEqclIdCodeList(eqclIdCd);
    setCommDtalProcCodeList(dtalProcCd);

    const dtalProclist = await getCommonCodeNames('DTAL_PROC_LIST'); //공정-설비군-세부공정 하이라키 구조 공통코드
    setDtalProcListCode(dtalProclist); //공정-설비군-세부공정 하이라키 구조 공통코드
  };

  useEffect(() => {
    if (changeCode === 'prdnProcCd') {
      searchParam.eqclIds = [];
      searchParam.dtalProcCds = [];
      const selectedPrdnProcCd = searchParam.prdnProcCd?.split(',') || [];

      const findedEqclIdList: Code[] = [];
      if (selectedPrdnProcCd?.length > 0) {
        for (let i = 0; i < selectedPrdnProcCd.length; i++) {
          const selectedCode: Code[] = dtalProcListCode.filter(
            (code) => code.optValCtn1 === selectedPrdnProcCd[i]
          );
          findedEqclIdList.push(...selectedCode);
        }
      }

      const makeEqclIdCode: Code[] = [];
      for (let i = 0; i < findedEqclIdList.length; i++) {
        for (let j = 0; j < getCommEqclIdCodeList.length; j++) {
          if (findedEqclIdList[i].optValCtn2 === getCommEqclIdCodeList[j].cmnCd) {
            makeEqclIdCode.push({
              cmnCd: findedEqclIdList[i].optValCtn2,
              cmnCdNm: getCommEqclIdCodeList[j].cmnCdNm,
            });
          }
        }
      }

      const deleteDuplication = makeEqclIdCode.filter((code, index) => {
        return (
          makeEqclIdCode.findIndex((scdCode) => {
            return code.cmnCd === scdCode.cmnCd;
          }) === index
        );
      });

      setEqclIdCode(deleteDuplication);
    }

    if (changeCode === 'eqclId') {
      searchParam.dtalProcCds = [];
      const selectedEqclId = searchParam.eqclId?.split(',') || [];

      const findedDtalProcCdList: Code[] = [];
      if (selectedEqclId?.length > 0) {
        for (let i = 0; i < selectedEqclId.length; i++) {
          const selectedCode: Code[] = dtalProcListCode.filter(
            (code) => code.optValCtn2 === selectedEqclId[i]
          );
          findedDtalProcCdList.push(...selectedCode);
        }
      }

      const makeDtalProcCode: Code[] = [];
      for (let i = 0; i < findedDtalProcCdList.length; i++) {
        for (let j = 0; j < getCommDtalProcCodeList.length; j++) {
          if (findedDtalProcCdList[i].optValCtn3 === getCommDtalProcCodeList[j].cmnCd) {
            makeDtalProcCode.push({
              cmnCd: findedDtalProcCdList[i].optValCtn3,
              cmnCdNm: getCommDtalProcCodeList[j].cmnCdNm,
            });
          }
        }
      }

      const deleteDuplication = makeDtalProcCode.filter((code, index) => {
        return (
          makeDtalProcCode.findIndex((scdCode) => {
            return code.cmnCd === scdCode.cmnCd;
          }) === index
        );
      });

      setDtalProcCode(deleteDuplication);
    }
  }, [searchParam]);

  const layoutDefinition = useMemo(() => {
    const prdnProcCodeMap = new DataMap(getCommPrdnProcCode, 'cmnCd', 'cmnCdNm');
    const eqpClIdCodeMap = new DataMap(getCommEqclIdCodeList, 'cmnCd', 'cmnCdNm');
    const dtalProcCodeMap = new DataMap(getCommDtalProcCodeList, 'cmnCd', 'cmnCd');
    const dtalProcCodeNmMap = new DataMap(getCommDtalProcCodeList, 'cmnCd', 'cmnCdNm');
    return [
      {
        binding: 'no',
        header: String(t('com.label.NO', 'NO')),
        width: 40,
        isReadOnly: true,
        align: 'center',
        cellTemplate: (grid) => grid.row._idx + 1,
      },
      {
        binding: 'crudKey',
        header: String(t('com.label.상태', '상태')),
        width: 50,
        isReadOnly: true,
        cellTemplate: GridStatusCellTemplate,
      },
      {
        binding: 'prdnProcCd',
        header: String(t('ut.label.공정', '공정')),
        width: 120,
        isReadOnly: !hasAuth,
        cssClass: 'WijmoSelect',
        dataMap: prdnProcCodeMap,
        cellTemplate: (item) => {
          if (item.item.crudKey !== null) {
            if (prdnProcCodeMap.getDisplayValue(item.value) == undefined) {
              return `<span>${item.text}</span>`;
            } else {
              return `<span>${prdnProcCodeMap.getDisplayValue(item.text)}</span>`;
            }
          } else {
            return `<span>${item.item.prdnProcCdNm}</span>`;
          }
        },
      },
      {
        binding: 'prdnProcCdNm',
        isReadOnly: true,
        visible: false,
      },
      {
        binding: 'eqclId',
        header: String(t('ut.label.설비군명', '설비군명')),
        width: 150,
        isReadOnly: !hasAuth,
        cssClass: 'WijmoSelect',
        dataMap: eqpClIdCodeMap,
        cellTemplate: (item) => {
          if (item.item.crudKey !== null) {
            return `<span>${
              item.item.eqclId === undefined ? '' : eqpClIdCodeMap.getDisplayValue(item.item.eqclId)
            }</span>`;
          } else {
            return `<span>${eqpClIdCodeMap.getDisplayValue(item.item.eqclId)}</span>`;
          }
        },
      },
      {
        binding: 'eqclIdNm',
        header: String(t('ut.label.설비군명', '설비군명')),
        visible: false,
      },
      {
        binding: 'dtalProcCd',
        header: String(t('ut.label.세부공정코드', '세부공정코드')),
        width: 150,
        isReadOnly: !hasAuth,
        cssClass: 'WijmoSelect',
        dataMap: dtalProcCodeMap,
        cellTemplate: (item) => {
          if (item.item.crudKey !== null) {
            return `<span>${item.item.dtalProcCd === undefined ? '' : item.item.dtalProcCd}</span>`;
          } else {
            return `<span>${item.value}</span>`;
          }
        },
      },
      {
        binding: 'dtalProcCdNm',
        header: String(t('ut.label.세부공정명', '세부공정명')),
        width: 150,
        isReadOnly: true,
        cellTemplate: (item) => {
          if (item.item.crudKey !== null) {
            return `<span>${
              item.item.dtalProcCd === undefined
                ? ''
                : dtalProcCodeNmMap.getDisplayValue(item.item.dtalProcCd)
            }</span>`;
          } else {
            return `<span>${item.value}</span>`;
          }
        },
      },
      {
        binding: 'eqpMainId',
        header: String(t('ut.label.Main ID', 'Main ID')),
        width: 120,
        isReadOnly: !hasAuth,
        // cssClass: 'WijmoSelect',
        // dataMap: electrodeCodeMap,
        // cellTemplate: (item) => {
        //   if (item.item.crudKey === CrudCode.CREATE) {
        //     return `<span>${item.item.eqpMainId === undefined ? '' : item.item.eqpMainId}</span>`;
        //   }
        // },
      },
      {
        binding: 'eqpMainNm',
        header: String(t('ut.label.Main 명', 'Main 명')),
        width: 180,
        isReadOnly: !hasAuth,
        // cellTemplate: (item) => {
        //   if (item.item.crudKey === CrudCode.CREATE) {
        //     return `<span>${
        //       item.item.eqpMainId === undefined
        //         ? ''
        //         : electrodeCodeNmMap.getDisplayValue(item.item.eqpMainId)
        //     }</span>`;
        //   }
        // },
      },
      {
        binding: 'eqpMchId',
        header: String(t('ut.label.Machine ID', 'Machine ID')),
        width: 120,
        isReadOnly: !hasAuth,
      },
      {
        binding: 'eqpMchNm',
        header: String(t('ut.label.Machine 명', 'Machine 명')),
        width: 180,
        isReadOnly: !hasAuth,
      },
      {
        binding: 'stndEqpId',
        header: String(t('ut.label.표준설비코드', '표준설비코드')),
        width: 150,
        align: 'center',
        isReadOnly: true,
        cellTemplate: (item) => {
          if (item.item.crudKey === CrudCode.CREATE) {
            if (item.item.dtalProcCd && item.item.eqpMainId && item.item.eqpMchId) {
              return `<span>${(item.item.stndEqpId =
                item.item.dtalProcCd + '-' + item.item.eqpMainId + '-' + item.item.eqpMchId)}
              </span>`;
            }
          } else {
            return `<span>${item.value}</span>`;
          }
        },
      },
      {
        binding: 'libraryQty',
        header: String(t('ut.label.Library 수', 'Library 수')),
        width: 120,
        isReadOnly: true,
        align: 'center',
        cellTemplate: CellMaker.makeLink({
          click: (e, ctx) => {
            fnSearchLibrary(ctx.item);
          },
        }),
      },
      {
        binding: 'dataInsUserNm',
        header: String(t('ut.label.등록자', '등록자')),
        width: 100,
        isReadOnly: true,
        align: 'center',
      },
      {
        binding: 'dataInsDtm',
        header: String(t('ut.label.등록일자', '등록일자')),
        width: 120,
        isReadOnly: true,
        align: 'center',
      },
      {
        binding: 'dataUpdUserNm',
        header: String(t('ut.label.수정자', '수정자')),
        width: 100,
        isReadOnly: true,
        align: 'center',
      },
      {
        binding: 'dataUpdDtm',
        header: String(t('ut.label.수정일자', '수정일자')),
        width: 120,
        isReadOnly: true,
        align: 'center',
      },
    ];
  }, [rowData]);

  const onInitialized = (grid) => {
    new Selector(grid);
    setFlexRef(grid);
    gridRef.current = grid;

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;
      const ht = grid.hitTest(e);
      grid.startEditing(true);

      if (ht.row < 0 || ht.col < 0) return;
      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      const item = grid.rows[ht.row].dataItem; // 선택한 row의 정보

      if (e.target instanceof HTMLButtonElement) {
        const col = grid.columns[ht.col]; // 선택한 컬럼의 정보
        const item = grid.rows[ht.row].dataItem; // 선택한 row의 정
        setFlexItem(item);
        setHitTest(ht);
        switch (e.target.id) {
          case 'gridWrtUserId':
            break;
          case 'gridReadWrtUserId':
            break;
        }
      }
    });
  };

  const onItemFormatter = useEvent((panel, row, col, cell) => {
    if (CellType.Cell === panel.cellType) {
      const binding = panel.columns[col].binding;
      const item = panel.rows[row].dataItem;

      if (item.crudKey !== CrudCode.CREATE) {
        if (['prdnProcCd', 'eqclId', 'dtalProcCd', 'eqpMainId', 'eqpMchId'].includes(binding)) {
          cell.ariaReadOnly = true;
          toggleClass(cell, 'WijmoSelect', !isReadOnly);
          if (isReadOnly && cell.firstElementChild instanceof HTMLButtonElement) {
            cell.firstChild.remove();
          }
        }
      }
    }
  });

  const beginningEdit = useEvent((grid, e) => {
    const col = grid.columns[e.col];
    const binding = col.binding;
    const item = grid.rows[e.row].dataItem;

    if (item.crudKey !== CrudCode.CREATE) {
      if (['prdnProcCd', 'eqclId', 'dtalProcCd', 'eqpMainId', 'eqpMchId'].includes(binding)) {
        e.cancel = true;
      }
    }

    const selectedEqclId: Code[] = [];
    if (binding === 'prdnProcCd' && item.crudKey === CrudCode.CREATE) {
      item.eqclId = '';
      item.dtalProcCd = '';
    }
    if (binding === 'eqclId' && item.crudKey === CrudCode.CREATE) {
      item.dtalProcCd = '';
      const selectedCode = dtalProcListCode.filter((code) => code.optValCtn1 === item.prdnProcCd);
      const result: Code[] = [];
      for (let i = 0; i < selectedCode.length; i++) {
        for (let j = 0; j < getCommEqclIdCodeList.length; j++) {
          if (selectedCode[i].optValCtn2 === getCommEqclIdCodeList[j].cmnCd) {
            result.push({
              cmnCd: selectedCode[i].optValCtn2,
              cmnCdNm: getCommEqclIdCodeList[j].cmnCdNm,
            });
          }
        }
      }
      const deleteDuplication = result.filter((code, index) => {
        return (
          result.findIndex((scdCode) => {
            return code.cmnCd === scdCode.cmnCd;
          }) === index
        );
      });
      col.dataMap = new DataMap(deleteDuplication, 'cmnCd', 'cmnCdNm');
      selectedEqclId.concat(deleteDuplication);
    }

    if (binding === 'dtalProcCd') {
      const selectedPrdnCode = dtalProcListCode.filter(
        (code) => code.optValCtn1 === item.prdnProcCd
      );
      const selectedEqclIdCode = selectedPrdnCode.filter((code) => code.optValCtn2 === item.eqclId);
      const result: Code[] = [];
      for (let i = 0; i < selectedEqclIdCode.length; i++) {
        for (let j = 0; j < getCommDtalProcCodeList.length; j++) {
          if (selectedEqclIdCode[i].optValCtn3 === getCommDtalProcCodeList[j].cmnCd) {
            result.push({
              cmnCd: selectedEqclIdCode[i].optValCtn3,
              cmnCdNm: getCommDtalProcCodeList[j].cmnCdNm,
            });
          }
        }
      }
      col.dataMap = new DataMap(result, 'cmnCd', 'cmnCd');
    }
  });

  const editEnded = useEvent((grid, e) => {
    if (grid.rows == 0) return;
    const col = grid.columns[e.col];
    const binding = col.binding;
    const item = grid.rows[e.row].dataItem;
    if (binding === 'dtalProcCd') {
      if (!_.isEmpty(item.dtalProcCd)) {
        const dtalSelectedCode = getCommDtalProcCodeList.filter(
          (dtalCode) => dtalCode.cmnCd === item.dtalProcCd
        );
        item.dtalProcCdNm = dtalSelectedCode[0].cmnCdNm;
      }
    }
    if (binding === 'prdnProcCd' || binding === 'eqpMainId' || binding === 'eqpMchId') {
      if (!_.isEmpty(item.dtalProcCd) && !_.isEmpty(item.eqpMainId) && !_.isEmpty(item.eqpMchId)) {
        item.stndEqpId = item.dtalProcCd + '-' + item.eqpMainId + '-' + item.eqpMchId;
      }
    }
  });

  const handleOnChange = (name, value) => {
    setSearchParam({ ...searchParam, [name]: value });
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      btnSearch();
    }
  };

  const btnExcelExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = 'Ut Matrix 대상 기준정보';
    book.saveAsync(
      getExcelFileName(t('ut.label.UT Matrix 대상 기준정보', 'UT Matrix 대상 기준정보'))
    );
  };

  const btnSearch = () => {
    getUtMatrixStandardCodeList(searchParam).then((result: UtMatrixStandardCode[]) => {
      if (result !== null) {
        setRowData(result);
      } else {
        openMessageBar({
          type: 'error',
          content: t(
            'com.msg.요청 정보 조회 중 오류가 발생했습니다.',
            '요청 정보 조회 중 오류가 발생했습니다.'
          ),
        });
      }
    });
  };

  const btnReload = () => {
    setSearchParam(
      (prev) =>
        Object.keys(prev).reduce(
          (acc, cur) => Object.assign(acc, { [cur]: initSearchParam[cur] || '' }),
          {}
        ) as SearchParam
    );
    setEqclIdCode([]);
    setDtalProcCode([]);
  };

  const btnAddRow = () => {
    const newRow = {
      crudKey: CrudCode.CREATE,
      useYn: 'Y',
    } as UtMatrixStandardCode;
    setRowData([newRow, ...rowData]);
  };

  const btnDelRow = () => {
    if (flexRef === null) {
      openMessageBar({
        type: 'error',
        content: t('ut.label.기준정보를 선택해 주세요.', '기준정보를 선택해 주세요.'),
      });
      return false;
    }

    const selectedRowNodes = flexRef.selectedRows;

    const isSelected = selectedRowNodes.filter((item) => item.isSelected);
    const selectedData = isSelected.map(({ dataItem }) => dataItem);
    console.log('selectedData', selectedData);
    const valid = selectedData
      .map((item) => {
        if (item.libraryQty !== null && item.crudKey !== CrudCode.CREATE) {
          openMessageBar({
            type: 'error',
            content:
              item.stndEqpId +
              ': ' +
              t(
                'ut.label.사용중인 기준정보 입니다. 삭제할 수 없습니다.',
                '사용중인 기준정보 입니다. 삭제할 수 없습니다.'
              ),
          });
          return false;
        }
      })
      .filter((element) => element !== undefined);

    if (valid.length) return false;

    if (isSelected.length < 1) {
      openMessageBar({
        type: 'error',
        content: t('ut.label.기준정보를 선택해 주세요.', '기준정보를 선택해 주세요.'),
      });
      return false;
    } else {
      const selectedIds = selectedRowNodes
        .map((rowNode) => {
          return parseInt(rowNode._idx!);
        })
        .reverse();

      selectedIds.forEach((idx) => {
        if (rowData[idx].crudKey === CrudCode.CREATE) {
          delete rowData[idx];
        } else {
          rowData[idx].crudKey = CrudCode.DELETE;
        }
      });

      const filteredData = rowData.filter((element) => element !== undefined);
      setRowData(filteredData);
    }

    // if (isSelected.length < 1) {
    //   openMessageBar({
    //     type: 'error',
    //     content: t('ut.label.기준정보를 선택해 주세요.', '기준정보를 선택해 주세요.'),
    //   });
    //   return false;
    // } else {
    //   openCommonModal({
    //     modalType: 'confirm',
    //     content: t('com.label.삭제하시겠습니까?', '삭제하시겠습니까?'),
    //     yesCallback: () => {
    //       deleteUtMatrixStandardCodeList(selectedData).then((result) => {
    //         if (!result) {
    //           openMessageBar({
    //             type: 'error',
    //             content: t(
    //               'com.label.삭제 중 오류가 발생했습니다.',
    //               '삭제 중 오류가 발생했습니다.'
    //             ),
    //           });
    //           return false;
    //         } else {
    //           openMessageBar({
    //             type: 'confirm',
    //             content: t('com.label.삭제되었습니다.', '삭제되었습니다.'),
    //           });
    //           setDelData([]);
    //           btnSearch();
    //         }
    //       });
    //     },
    //     noCallback: () => {
    //       return false;
    //     },
    //   });
    // }
  };

  const btnCopyRow = () => {
    if (flexRef == null) {
      openMessageBar({
        type: 'error',
        content: t('ut.label.기준정보를 선택해 주세요.', '기준정보를 선택해 주세요.'),
      });
      return false;
    }

    const selectedRowNodes = flexRef.selectedRows;

    const isSelected = selectedRowNodes.filter((item) => item.isSelected);

    if (isSelected.length < 1) {
      openMessageBar({
        type: 'error',
        content: t('ut.label.기준정보를 선택해 주세요.', '기준정보를 선택해 주세요.'),
      });
      return false;
    } else {
      const selectNo = 0; //단일 선택 복사 시 0번째
      const copiedData = isSelected[selectNo].dataItem;
      const newRow = {
        crudKey: CrudCode.CREATE,
        prdnProcCd: copiedData?.prdnProcCd,
        eqclId: copiedData?.eqclId,
        dtalProcCd: copiedData?.dtalProcCd,
        dtalProcCdNm: copiedData?.dtalProcCdNm,
        eqpMainId: copiedData?.eqpMainId,
        eqpMainNm: copiedData?.eqpMainNm,
        useYn: copiedData?.useYn,
      } as UtMatrixStandardCode;
      setRowData([newRow, ...rowData]);
    }
  };

  const btnSave = () => {
    const validStatusCorU = rowData.filter(
      (element) => element.crudKey == CrudCode.CREATE || element.crudKey == CrudCode.UPDATE
    );
    const valid = validStatusCorU
      .map((rowNode, index) => {
        if (rowNode.crudKey != CrudCode.DELETE) {
          if (rowNode.prdnProcCd == null || rowNode.prdnProcCd == '')
            return `${t('ut.label.공정을 선택해 주세요.', '공정을 선택해 주세요.')}`;
          if (rowNode.eqclId == null || rowNode.eqclId == '')
            return `${t('ut.label.설비군명을 선택해 주세요.', '설비군명을 선택해 주세요.')}`;
          if (rowNode.dtalProcCd == null || rowNode.dtalProcCd == '')
            return `${t(
              'ut.label.세부공정코드를 선택해 주세요.',
              '세부공정코드를 선택해 주세요.'
            )}`;
          if (rowNode.dtalProcCdNm == null || rowNode.dtalProcCdNm == '')
            return `${t('ut.label.세부공정명을 입력해 주세요.', '세부공정명을 입력해 주세요.')}`;
          if (rowNode.eqpMainId == null || rowNode.eqpMainId == '')
            return `${t('ut.label.Main ID를 입력해 주세요.', 'Main ID를 입력해 주세요.')}`;
          if (rowNode.eqpMainNm == null || rowNode.eqpMainNm == '')
            return `${t('ut.label.Main 명을 입력해 주세요.', 'Main 명을 입력해 주세요.')}`;
          if (rowNode.eqpMchId == null || rowNode.eqpMchId == '')
            return `${t('ut.label.Machine ID를 입력해 주세요.', 'Machine ID를 입력해 주세요.')}`;
          if (rowNode.eqpMchNm == null || rowNode.eqpMchNm == '')
            return `${t('ut.label.Machine 명을 입력해 주세요.', 'Machine 명을 입력해 주세요.')}`;
          if (rowNode.stndEqpId == null || rowNode.stndEqpId == '')
            return `${t('ut.label.표준설비코드가 없습니다.', '표준설비코드가 없습니다.')}`;
          // if (rowNode.crudKey !== CrudCode.CREATE)
          //   return `${t(
          //     'ut.label.사용중인 기준정보 입니다. 삭제할 수 없습니다.',
          //     '사용중인 기준정보 입니다. 삭제할 수 없습니다.'
          //   )}`;
        }
      })
      .filter((element) => element !== undefined);
    if (valid.length) {
      const content = valid[0]?.toString();
      openMessageBar({ type: 'error', content: content || '' });
      return false;
    }

    // 중복 체크
    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.저장하시겠습니까?', '저장하시겠습니까?'),
      yesCallback: () => {
        const saveRows = rowData
          .map((rowNode) => {
            return rowNode.crudKey ? rowNode : null;
          })
          .filter((element) => element !== null) as UtMatrixStandardCode[];

        const saveRowsData = [...saveRows, ...delData];
        checkDuplicationStandardCode(saveRowsData).then((result) => {
          if (result < 0) {
            openMessageBar({
              type: 'error',
              content: t(
                'com.label.기 등록된 표준설비코드가 존재합니다.',
                '기 등록된 표준설비코드가 존재합니다.'
              ),
            });
            return false;
          } else {
            saveUtMatrixStandardCodeList(saveRowsData).then((result) => {
              if (!result || result.successOrNot === SuccessOrNot.N) {
                openMessageBar({
                  type: 'error',
                  content:
                    result.data ||
                    t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
                });
                return false;
              } else {
                openMessageBar({
                  type: 'confirm',
                  content: t('com.label.저장되었습니다.', '저장되었습니다.'),
                });
                setDelData([]);
                btnSearch();
              }
            });
          }
        });
      },
      noCallback: () => {
        return false;
      },
    });
  };

  const fnSearchLibrary = (params: any) => {
    const searchParam = {
      prdnProcCds: params.prdnProcCd,
      eqclIds: params.eqclId,
      dtalProcCds: params.dtalProcCd,
      eqpMchId: params.eqpMchId,
      stndEqpId: params.stndEqpId,
    };
    navigate('/ut/info-management/library', { state: searchParam });
  };

  return (
    <ContainerLayout>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows className="FourCol">
              <SearchCols>
                <label>{String(t('ut.label.공정', '공정'))}</label>
                <MultiComboBox
                  id="prdnProcCd"
                  options={getCommPrdnProcCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.prdnProcCds || []}
                  onChange={(value) => {
                    handleOnChange('prdnProcCd', value.join());
                    setChangeCode('prdnProcCd');
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{String(t('ut.label.설비군', '설비군'))}</label>
                <MultiComboBox
                  id="eqpGrcd"
                  options={eqclIdCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.eqclIds || []}
                  onChange={(value) => {
                    handleOnChange('eqclId', value.join());
                    setChangeCode('eqclId');
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{String(t('ut.label.세부공정', '세부공정'))}</label>
                <MultiComboBox
                  id="dtalProcCode"
                  options={dtalProcCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.dtalProcCds || []}
                  onChange={(value) => {
                    handleOnChange('dtalProcCd', value.join());
                    setChangeCode('dtalProcCd');
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('com.label.Machine', 'Machine')}</label>
                <CustomInputWithSearch
                  name="eqpMchNm"
                  value={searchParam.eqpMchNm || ''}
                  defaultValue={searchParam.eqpMchNm || ''}
                  placeholder={String(
                    t('com.label.Machine을 입력해 주세요.', 'Machine을 입력해 주세요.')
                  )}
                  onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                  onKeyDown={onKeyDown}
                />
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchButtonWrap>
            <Button
              css={IconButton.button}
              className="reload"
              onClick={btnReload}
              disableRipple
            ></Button>
            <Button css={IconButton.button} className="find" onClick={btnSearch} disableRipple>
              {t('com.button.조회', '조회')}
            </Button>
          </SearchButtonWrap>
        </SearchBoxRow>
      </SearchBox>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('ut.label.UT Matrix 대상 기준정보', 'UT Matrix 대상 기준정보')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{rowData?.length ?? 0}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          {hasAuth && (
            <Button css={IconButton.button} className="addRow" onClick={btnAddRow} disableRipple>
              {t('com.button.행추가', '행추가')}
            </Button>
          )}
          {hasAuth && (
            <Button css={IconButton.button} className="delRow" onClick={btnDelRow} disableRipple>
              {t('com.button.행삭제', '행삭제')}
            </Button>
          )}
          {hasAuth && (
            <Button css={IconButton.button} className="copyRow" onClick={btnCopyRow} disableRipple>
              {t('com.button.행복사', '행복사')}
            </Button>
          )}
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={btnExcelExport}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
          <Button css={IconButton.button} className="refresh" onClick={btnSearch} disableRipple>
            {t('com.button.새로고침', '새로고침')}
          </Button>
          {/* <Button
            css={IconButton.button}
            className="refresh"
            onClick={() => setOpenPopup(true)}
            disableRipple
          >
            {t('com.button.표준설비코드팝업', '표준설비코드팝업')}
          </Button>
          <Button
            css={IconButton.button}
            className="refresh"
            onClick={() => setOpenPopup2(true)}
            disableRipple
          >
            {t('com.button.작성내역팝업', '작성내역팝업')}
          </Button> */}
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        initialized={onInitialized}
        height={520}
        beginningEdit={beginningEdit}
        // cellEditEnded={editEnded}
        cellEditEnded={editEnded}
        itemFormatter={onItemFormatter}
        excludeFilter={[]}
        excludePin={[
          'prdnProcCd',
          'eqclId',
          'dtalProcCd',
          'dtalProcCdNm',
          'eqpMainId',
          'eqpMainNm',
          'libraryQty',
          'dataInsUserId',
          'dataInsDtm',
          'dataUpdUserId',
          'dataUpdDtm',
        ]}
        autoCheck={true}
        selectionMode={SelectionMode.Row}
      />
      <GlobalBtnGroup>
        {hasAuth && (
          <Button css={IconButton.button} className="save" onClick={btnSave}>
            {t('com.button.저장', '저장')}
          </Button>
        )}
      </GlobalBtnGroup>
      {/* {openPopup && (
        <StandardEquipmentPopup
          open={openPopup}
          close={() => setOpenPopup(false)}
          condition={{
            prdnProcCd: 'E',
          }}
          onCallback={(callbackData) => console.log('callbackData', callbackData)}
          singleSelect={false}
        />
      )}
      {openPopup2 && (
        <UtMatrixRegistHistoryPoupup
          open={openPopup2}
          close={() => setOpenPopup2(false)}
          condition={{
            prdnProcCd: 'E',
          }}
          onCallback={(callbackData) => console.log('callbackData', callbackData)}
          singleSelect={false}
        />
      )} */}
    </ContainerLayout>
  );
};

export default UtMatrixStandardCodePage;
