/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import {
  CommonBG,
  StatusErrorColor,
  StatusConfirmColor,
  StatusWarningMinorColor,
  StatusDoneColor,
  CommonText,
  CommonBorder,
  LegendStatusBG,
  ControlFilldBG,
  GrayColor,
  LightBlueColor,
} from 'ui/theme/Color';
import { ButtonDesign, ButtonSize, ButtonStyle } from 'components/buttons/ButtonStyle';
import { SVGIcon } from 'components/buttons/IconSVG';

export const ContentLayout = styled.div`
  width: 100%;
  min-width: calc(100% - 230px);
  /* height:calc(100vh - 52px);   */
  background-color: ${CommonBG.Elevation};
  height: calc(100vh - 60px) !important;
  padding-top: 32px;
  position: relative;
  overflow: hidden;
`;

export const HalfContetntLayout = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  & > div {
    width: calc((100% - 24px) / 2) !important;
    & > div:first-child {
      margin-top: 0px;
    }
  }
`;

export const ThirdContetntLayout = styled.div`
  display: flex;
  align-items: flex-start;
  & > div {
    width: calc((100% - 48px) / 3) !important;
    label {
      width: 50px !important;
      & + div {
        width: calc(100% - 50px);
      }
    }
    & + div {
      margin-left: 24px;
    }
    & > div:first-child {
      margin-top: 0px;
    }
  }
`;

export const SubTitleLayout = styled.div`
  height: 28px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 4px;
  margin-top: 12px;
  &.right {
    justify-content: flex-end;
  }
`;

export const Divider = styled.div`
  height: 49px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  & + div {
    margin-top: 0px !important;
  }
  &:before {
    content: '';
    clear: both;
    display: inline-block;
    width: 100%;
    height: 1px;
    position: absolute;
    top: 24px;
    left: 0;
    background: ${CommonBorder.Basic};
  }
  button {
    width: 24px;
    min-width: 24px;
    height: 24px;
    padding: 0px;
    border-radius: 2px;
    z-index: 99;
    ${ButtonStyle.containedNormal};
    &:before {
      content: '';
      clear: both;
      display: inline-block;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-mask: ${SVGIcon.IcoFillChevron};
      mask-image: ${SVGIcon.IcoFillChevron};
    }
    & + button {
      margin-left: 4px;
    }
    &.right {
      &:before {
        transform: translate(-50%, -50%) rotate(180deg);
      }
    }
  }
`;

export const SubTitleGroup = styled.div`
  height: 28px;
  display: inline-flex;
  align-items: center;
  h3 {
    font-size: 16px;
    font-weight: bold;
    color: ${CommonText.Basic};
    font-family: 'Spoqa Han Sans Neo';
  }
  .total {
    display: inline-block;
    font-size: 13px;
    line-height: 1.5;
    margin-left: 8px;
    color: ${CommonText.Basic};
    span {
      color: ${CommonText.Primary};
      padding-left: 2px;
    }
  }
  .pageNum {
    margin-left: 8px;
    display: inline-flex;
    align-items: center;
    span {
      display: inline-block;
      margin-left: 4px;
      font-size: 13px;
    }
  }
  .info {
    margin-left: 8px;
    padding-left: 22px;
    font-size: 12px;
    font-weight: 400;
    position: relative;
    &:before {
      content: '';
      clear: both;
      display: inline-block;
      width: 18px;
      height: 18px;
      position: absolute;
      top: 0;
      left: 0;
    }
    &.default {
      color: ${CommonText.Lighter};
      &:before {
        background: ${CommonText.Lighter};
        -webkit-mask: ${SVGIcon.IcoInfo};
        mask-image: ${SVGIcon.IcoInfo};
      }
    }
    &.error {
      color: ${CommonText.Error};
      &:before {
        background: ${CommonText.Error};
        -webkit-mask: ${SVGIcon.IcoInfoError};
        mask-image: ${SVGIcon.IcoInfoError};
      }
    }
    &.warning {
      color: ${LegendStatusBG.WarningMajorNormal};
      &:before {
        background: ${LegendStatusBG.WarningMajorNormal};
        -webkit-mask: ${SVGIcon.IcoInfo};
        mask-image: ${SVGIcon.IcoInfo};
      }
    }
    &.comfirm {
      color: ${CommonText.Confirmed};
      &:before {
        background: ${CommonText.Confirmed};
        -webkit-mask: ${SVGIcon.IcoInfoComfirm};
        mask-image: ${SVGIcon.IcoInfoComfirm};
      }
    }
    &.primary {
      font-weight: 700;
      color: ${CommonText.Primary};
      &:before {
        background: ${CommonText.Primary};
        -webkit-mask: ${SVGIcon.IcoInfo};
        mask-image: ${SVGIcon.IcoInfo};
      }
    }
  }
`;

export const ControlBtnGroup = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: right;
  label {
    margin-right: 4px;
    font-size: 12px;
  }
  div {
    padding-left: 2px;
    height: 28px;
    & + button {
      margin-left: 4px;
    }

    input {
      height: 100%;
    }
    button {
      &:hover {
        background: #5b5c5b;
      }

      &:before {
        left: 8px;
      }

      &.buttonFind {
        &:before {
          background: ${CommonText.Basic} !important;
        }
      }
    }
    ul {
      top: 31px;
    }
  }
  &.board {
    align-items: center;
  }
  button {
    ${ButtonSize.sm}
    ${ButtonStyle.outlineNormal}
      ${ButtonDesign.IconLfSM}
  }
  button.normal {
    ${ButtonSize.sm}
    ${ButtonStyle.outlineNormal}
    ${ButtonDesign.labelSM}
  }
  button + button {
    margin-left: 4px;
  }
  .MuiFormGroup-root {
    height: 28px;
    display: inline-block;
    flex-direction: initial;
    flex-wrap: initial;
    .MuiFormControlLabel-root {
      height: 28px;
      margin: 0px !important;
      width: 81px;
      display: inline-block;
      position: relative;
      .MuiSwitch-root {
        width: 32px;
        height: 20px;
        position: absolute;
        left: 49px;
        top: 4px;
        padding: 0px !important;
        .MuiSwitch-switchBase {
          padding: 0px !important;
          &.Mui-checked {
            transform: translateX(14px);
            .MuiSwitch-thumb {
              border: 2px solid ${CommonBG.Secondary};
            }
            & + .MuiSwitch-track {
              background: ${CommonBG.Secondary};
              &:before {
                content: '';
                clear: both;
                display: inline-block;
                width: 9px;
                height: 6px;
                position: absolute;
                top: 6px;
                left: 4px;
                background-image: url("data:image/svg+xml,%3Csvg width='9' height='6' viewBox='0 0 9 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.44896 0.206475C8.73939 0.486031 8.73934 0.942722 8.44884 1.22221L3.69742 5.79364C3.55918 5.92664 3.3733 6.00004 3.18103 6C2.98876 5.99996 2.80291 5.92649 2.66473 5.79343L0.884435 4.07914C0.594058 3.79953 0.594208 3.34284 0.884753 3.06341C1.17083 2.78827 1.63143 2.7884 1.91735 3.06371L3.1814 4.2809L7.41637 0.206361C7.70239 -0.0688305 8.163 -0.0687815 8.44896 0.206475Z' fill='white'/%3E%3C/svg%3E%0A");
                background-size: 9px 6px;
                z-index: 5;
              }
            }
          }
          .MuiSwitch-thumb {
            width: 18px;
            height: 18px;
            background: ${CommonBG.Basic};
            border: 2px solid ${CommonBorder.Stronger};
            box-shadow: none !important;
          }
        }
        .MuiSwitch-track {
          opacity: 1 !important;
          width: 32px;
          height: 12px;
          border-radius: 6px;
          background: ${CommonBG.Stonger};
          margin-top: 3px;
        }
      }
      .MuiTypography-root {
        position: absolute;
        left: 0;
        top: 4px;
        font-family: 'Malgun Gothic', 'Segoe UI', 'MS YaHei', Sans-Serif;
        font-size: 13px;
        font-weight: 400;
        color: ${CommonText.Basic};
      }
    }
  }
`;

export const BoardBtnGroup = styled.div`
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: right;
  button.button {
    min-width: 0px;
    & + button {
      margin-left: 4px;
    }
    border: 1px solid red;
    ${ButtonSize.md}
    ${ButtonStyle.outlineNormal}
      &.normal {
      ${ButtonDesign.labelMD}
    }
    &.icon {
      ${ButtonDesign.IconLfMD}
    }
  }
`;

export const LanguageSelect = styled.div`
  height: 32px;
  border: 1px solid ${CommonBorder.Strong};
  background: ${CommonBG.Basic};
  color: ${CommonText.Light};
  border-radius: 2px;
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-right: 16px;
  .arrow {
    display: inline-block;
    width: 24px;
    height: 30px;
    position: relative;
    &:before {
      content: '';
      clear: both;
      display: inline-block;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 8px;
      left: 4px;
      background: ${CommonText.Light};
      -webkit-mask: ${SVGIcon.IcoLineSmallArr};
      mask-image: ${SVGIcon.IcoLineSmallArr};
    }
  }
  button.translation {
    width: 44px;
    height: 26px;
    font-size: 13px;
    font-weight: bold;
    color: ${CommonText.Light};
    padding: 0px !important;
    border-width: 0px 0px 0px 1px;
    border-color: ${CommonBorder.Light};
    border-style: solid;
    border-radius: 0px;
    position: relative;
    &:focus,
    &:active {
      outline: none !important;
      border: none !important;
      background: none !important;
      border-left: 1px solid ${CommonBorder.Light} !important;
    }
    &:hover {
      background: none !important;
      border: none !important;
      border-left: 1px solid ${CommonBorder.Light} !important;
    }
    &:before {
      display: none;
    }
  }
`;

export const BoardSerch = styled.div`
  margin-left: 8px;
  height: 28px;
  border: 1px solid ${CommonBorder.Strong};
  background: ${CommonBG.Basic};
  color: ${CommonText.Light};
  border-radius: 2px;
  position: relative;
  display: inline-flex;
  align-items: center;
  .boardSearch {
    width: 160px;
    height: 26px;
    line-height: 1.5;
    font-size: 13px;
    font-family: 'Malgun Gothic', 'Segoe UI', 'MS YaHei', Sans-Serif;
    border: solid 1px ${CommonBorder.Strong};
    border-left: none !important;
    border-right: 1px solid ${CommonBorder.Light};
    background-color: ${CommonBG.Basic};
    font-size: 13px;
    color: ${CommonText.Basic};
    vertical-align: middle;
    &:focus,
    &:active {
      outline: none !important;
    }
  }
  .bordFind {
    width: 26px;
    height: 26px;
    padding: 0px !important;
    border-radius: 0px;
    position: relative;
    border: none !important;
    background: ${GrayColor.White} !important;
    &:focus,
    &:active {
      outline: none !important;
      border: none !important;
      background: ${GrayColor.White} !important;
    }
    &:hover {
      background: ${GrayColor.White} !important;
      border: none !important;
    }
    &:before {
      content: '';
      clear: both;
      display: inline-block;
      position: absolute;
      width: 16px;
      height: 16px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: ${CommonText.Light};
      -webkit-mask: ${SVGIcon.IcoFind};
      mask-image: ${SVGIcon.IcoFind};
      mask-size: 16px 16px;
      mask-repeat: no-repeat;
    }
  }
`;

export const StatusDot = styled.div`
  display: inline-flex;
  align-items: flex-end;
  height: 22px !important;
  span {
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
    color: ${CommonText.Light};
    position: relative;
    padding-left: 16px;
    &:before {
      content: '';
      clear: both;
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 6px;
      position: absolute;
      top: 3px;
      left: 0px;
    }
    & + span {
      margin-left: 8px;
    }
    &.red {
      &:before {
        background: ${StatusErrorColor.StatusError500};
      }
    }
    &.blue {
      &:before {
        background: ${LightBlueColor.LightBlue700};
      }
    }
    &.green {
      &:before {
        background: ${StatusConfirmColor.StatusConfirm500};
      }
    }
    &.yellow {
      &:before {
        background: ${StatusWarningMinorColor.StatusWarningMinor500};
      }
    }
    &.done {
      &:before {
        background: ${StatusDoneColor.StatusDone500};
      }
    }
    &.delayDone {
      &:before {
        background: ${StatusDoneColor.StatusDone700};
      }
    }
    &.plan {
      &:before {
        border: 1px solid ${StatusDoneColor.StatusDone700};
        background: ${CommonBG.Elevation};
      }
    }
  }
`;

export const GlobalBtnGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-top: 4px;
  &.top {
    margin-top: 0px;
    margin-bottom: 4px;
  }
  &.between {
    justify-content: space-between;
  }
  button {
    ${ButtonSize.lg}
    ${ButtonStyle.outlineNormal}
      ${ButtonDesign.IconLfLG}
    &.save,
    &.confirm, 
    &.ok {
      ${ButtonSize.lg}
      ${ButtonStyle.containedPrimary}
    }
    &.lightBtn {
      ${ButtonStyle.outlineNormal}
      &:hover {
        &:before {
          background: ${ControlFilldBG.InverseHover};
        }
      }
    }
    &.primaryBtn {
      ${ButtonStyle.containedPrimary}
      &:hover {
        &:before {
          background: ${CommonBG.Basic};
        }
      }
    }
  }
  button + button {
    margin-left: 4px;
  }
`;

export const CheckBoxDiv = styled.div`
  display: inline-flex;
  align-items: center;
  height: 28px;
  margin-right: 4px;
  label {
    font-size: 13px;
    font-weight: 400;
    color: ${CommonText.Basic};
    margin-left: 4px;
    margin-bottom: 0px !important;
  }
`;

export const Chart2Div = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > div {
    width: calc((100% - 80px) / 2) !important;
  }
`;

export const cs = {
  root: css`
    overflow-y: auto;
    height: calc(100vh - 60px);
    padding: 28px 32px 70px;
    ::-webkit-scrollbar {
      width: 6px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: rgba(60, 62, 61, 0.4);
      width: 6px;
      border-radius: 3px;
    }
  `,
  persnal: css`
    margin-top: 10px;
    background: ${CommonBG.Deep};
    padding: 12px;
    h4 {
      font-size: 15px;
      font-weight: 700;
    }
    span {
      font-size: 12px;
      color: ${CommonText.Basic};
      display: inline-block;
      position: relative;
      padding-left: 24px;
      margin-bottom: 12px;
      &:before {
        content: '';
        display: inline-block;
        clear: both;
        position: absolute;
        left: 8px;
        top: 7px;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background: ${CommonText.Lighter};
      }
    }
  `,
};

export const AccodianWrap = styled.div`
  border-top: 1px solid ${CommonBorder.Basic};
  border-bottom: 1px solid ${CommonBorder.Basic};
  &.marginTop {
    margin-top: 16px;
  }
  .MuiAccordion-root {
    border-radius: 0px !important;
    transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    &.firstAcc {
      &.Mui-expanded {
        margin: 0 0 24px 0 !important;
        border-top: none !important;
      }
    }
    &.Mui-expanded {
      margin: 0 0 24px 0 !important;
      border-top: 1px solid ${CommonBorder.Basic};
      &:first-child {
        border-top: none;
      }
    }
  }
  .MuiAccordionSummary-root {
    height: 54px;
    position: relative;
    font-size: 16px;
    font-weight: 400;
    color: ${CommonText.Light};
    padding-left: 32px;
    transition: min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    &.Mui-expanded {
      min-height: 54px;
      font-weight: 700;
      color: ${CommonText.Secondary};
      &:before {
        transform: translateY(-50%) rotate(180deg);
        transition: all 0.5s ease-in-out;
      }
    }
    &:before {
      content: '';
      clear: both;
      display: inline-block;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 50%;
      left: 8px;
      transform: translateY(-50%);
      transition: all 0.2s ease-in-out;
      background: ${CommonText.Light};
      -webkit-mask: ${SVGIcon.IcoLineChevron};
      mask-image: ${SVGIcon.IcoLineChevron};
    }
  }
  .MuiCollapse-root {
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  .MuiCollapse-wrapper {
    background: ${CommonBG.Deep};
    padding: 0px 10px 0px 10px;
  }
  .accBtn {
    ${ButtonSize.sm}
    ${ButtonDesign.IconLfSM}
    ${ButtonStyle.outlineNormal}
    position:absolute;
    right: 8px;
    top: 13px;
    z-index: 99;
    &:before {
      -webkit-mask: ${SVGIcon.IconUserplus};
      mask-image: ${SVGIcon.IconUserplus};
    }
  }
  .MuiAccordionSummary-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
