import { PaginationRequest } from 'models/common/Pagination';
import { Crud } from '../Edit';

export enum EquipmentLevel {
  MAIN = 'M',
  MACHINE = 'C',
  UNIT = 'U',
  ASSEMBLY = 'A',
}

export interface EquipmentMasterCondition extends PaginationRequest {
  equipmentId?: string; // 설비ID
  equipmentIdAsst?: string; // 설비ID
  equipmentName?: string; // 설비명
  equipmentGroup?: string; // 설비군
  equipmentGroupName?: string; // 설비군명
  equipmentLevel?: string; // 설비 레벨
  processCode?: string; // 공정(코드)
  processName?: string; // 공정명
  factoryCode?: string; // 공장(코드)
  copCd?: string; // 법인코드
  copCds?: any; // 법인코드목록
  useYn?: string; // 사용여부
  mainEquipmentName?: string; // 메인설비명
}

/**
 * 설비기본정보
 */
export interface Equipment extends Crud {
  copCd?: string; // 법인코드
  siteCd?: string; // 사이트코드
  equipmentId?: string; // 설비ID
  equipmentName?: string; // 설비명
  unitEquipmentName?: string; // 단위설비명
  equipmentDesc?: string; // 설비설명
  equipmentLevel?: string; // 설비레벨구분코드
  ancestorEquipmentId?: string; // 최상위설비ID
  upperEquipmentId?: string; // 상위설비ID
  equipmentGroup?: string; // 설비군
  equipmentCategoryType?: string; // 설비유형코드
  lineId?: string; // 라인ID
  lineDesc?: string; // 라인명
  electrodeType?: string; // 전극유형코드
  processCode?: string; // 공정ID
  processName?: string;
  factoryCode?: string; // 플랜트코드
  factoryName?: string;
  floorName?: string; // 층명
  owningDeptName?: string; // 주관부서명
  createdBy?: string; // 등록자명
  creationDate?: string; // 등록일자
  lastUpdateBy?: string; // 수정자명
  lastUpdateDate?: string; // 수정일자
  standardNo?: string; // 표준설비구성ID
  standardStructureNo?: string; // 표준설비구성도구조ID
  useYn?: string; // 사용여부
  dataInsUserId?: string; // 데이터입력사용자ID
  dataInsUserNm?: string; // 데이터입력사용자명
  dataInsUserIp?: string;
  dataInsDtm?: string; // 데이터입력일시
  dataUpdUserId?: string; // 데이터수정사용자ID
  dataUpdUserNm?: string; // 데이터수정사용자명
  dataUpdUserIp?: string;
  dataUpdDtm?: string; // 데이터수정일시
  eqpIdExstYn?: string; // 설비ID존재여부 (직접입력한 설비인 경우 N)
}

export interface CustomEquipment extends Equipment {
  eqpIdExstYn?: string; // 설비ID존재여부 (직접입력한 설비인 경우 N)
  cctrCd?: string; // 코스트센터코드
  cctrNm?: string; // 코스트센터명
  asstNo?: string; // 자산번호
  asstNm?: string; // 자산명
  acqrDt?: string; // 취득일자
  mdlNm?: string; // 모델명
  asstAcqrAmt?: string; // 자산취득금액
  deprAmt?: string; // 감가상각금액
  asstQty?: string; // 자산수량
  asstQtyUnitNm?: string; // 자산수량단위명
  rmk?: string; // 비고
  atchFileGrId?: string; // 첨부파일그룹ID
  atchFileCnt?: number; // 첨부파일수
  asstTpCd?: string; // 자산유형코드
}
