/** @jsxImportSource @emotion/react */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import Datepicker, { DateType, DateRangeType, DateValueType } from 'react-tailwindcss-datepicker';
import { DatePickerWrap } from 'components/inputs/DatePicker';
import useSessionStore from 'stores/useSessionStore';

interface Props {
  isAlwaysOepn?: boolean; // 항상 열어야할경우
  isRange?: boolean; // 기간선택여부 (true: 기간선택(default), false: 날짜선택)
  isError?: boolean; // 에러여부
  msgError?: string; // 에러메세지
  value?: DateType | DateRangeType; // 기본값
  startFrom?: Date | null; // 달력 시작날짜
  // Datepicker 기본 속성
  minDate?: Date; // 최소날짜
  maxDate?: Date; // 최대날짜
  disabledDates?: DateRangeType[]; // 사용할 수 없는 날짜
  readOnly?: boolean;
  disabled?: boolean;
  onChange?: (newValue: DateType | DateRangeType) => void; // 기간인 경우 DateRangeType, 날짜인 경우 DateType으로 반환
}

const CustomDatepicker = ({
  isAlwaysOepn = false,
  isRange = true,
  isError = false,
  msgError,
  minDate,
  maxDate,
  value = null,
  startFrom,
  disabledDates,
  readOnly = false,
  disabled = false,
  onChange,
}: Props) => {
  const wrapRef = useRef<HTMLDivElement>(null);
  const { langCd } = useSessionStore();
  const [currentValue, setCurrentValue] = useState(convertValue(value));
  const displayFormat = useMemo(() => {
    // TODO 사용자 세션에 날짜포맷 정보로 변경 필요 (사용자 설정)
    return 'YYYY.MM.DD';
  }, [langCd]);
  const placeholder = useMemo(() => {
    return isRange ? `${displayFormat} - ${displayFormat}` : displayFormat;
  }, [isRange, displayFormat]);

  useEffect(() => {
    setCurrentValue(convertValue(value));
  }, [value]);

  useEffect(() => {
    wrapRef.current?.addEventListener('input', handleDatepickerInput);
    return () => {
      wrapRef.current?.removeEventListener('input', handleDatepickerInput);
    };
  }, []);

  useEffect(() => {
    if (isAlwaysOepn) {
      setTimeout(() => {
        const inputElement = wrapRef.current?.querySelector('input');
        if (inputElement) {
          inputElement.focus();
          const clickEvent = new MouseEvent('click', { bubbles: true });
          inputElement.dispatchEvent(clickEvent);
          inputElement.hidden = true;

          const nextElement = inputElement.nextElementSibling;

          if (nextElement && nextElement.tagName === 'BUTTON') {
            (nextElement as HTMLElement).style.display = 'none';
          }
        }
      }, 100);
    }
  }, [isAlwaysOepn]);

  const handleDatepickerInput = (e) => {
    if (e.target instanceof HTMLInputElement) {
      if ((e as InputEvent).inputType === 'deleteContentBackward') {
        // datepicker input에서 키보드로 값을 다 지운 경우 값 초기화
        if (!e.target.value) {
          handleChange(convertValue(null));
        }
      }
    }
  };

  function convertValue(newValue) {
    if (newValue === null) {
      return {
        startDate: null,
        endDate: null,
      };
    }
    if (typeof newValue === 'string' || newValue instanceof Date) {
      return {
        startDate: newValue,
        endDate: newValue,
      } as DateValueType;
    }
    return newValue as DateValueType;
  }

  const handleChange = (newValue: DateValueType) => {
    const convertDateFormat = (v) => {
      if (v) {
        // datepicker 기본 날짜포맷(YYYY-MM-DD)에서 사용자포맷으로 변환
        return dayjs(v).format(displayFormat);
      }
      return v;
    };

    setCurrentValue(newValue);

    const v = {
      startDate: null,
      endDate: null,
    };
    if (newValue) {
      Object.assign(v, {
        startDate: convertDateFormat((newValue || {}).startDate),
        endDate: convertDateFormat((newValue || {}).endDate),
      });
    }
    // 기간인 경우 DateRangeType, 날짜인 경우 DateType으로 반환
    onChange && onChange(isRange ? v : ((v || {}).startDate as DateType));
  };

  return (
    <DatePickerWrap
      ref={wrapRef}
      className={`${isRange ? 'range' : 'single'} ${isError ? 'error' : ''}`}
    >
      <Datepicker
        inputClassName="datepickerInput"
        useRange={isRange}
        placeholder={placeholder}
        displayFormat={displayFormat}
        value={currentValue}
        asSingle={!isRange}
        separator={'-'}
        onChange={handleChange}
        i18n={langCd}
        readOnly={readOnly}
        disabled={disabled}
        minDate={minDate}
        maxDate={maxDate}
        disabledDates={disabledDates}
        startFrom={startFrom}
      />
      {isError && msgError && <span className="message">{msgError}</span>}
    </DatePickerWrap>
  );
};

export default CustomDatepicker;
