/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useEvent from 'react-use-event-hook';
import dayjs from 'dayjs';
import { Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { addClass, removeClass } from '@grapecity/wijmo';
import { CellType } from '@grapecity/wijmo.grid';
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { IconButton } from 'components/buttons/IconSVG';
import { tb } from 'components/layouts/Table';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { useMessageBar } from 'hooks/useMessageBar';
import { ControlBtnGroup, SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import CustomDialog from 'components/modals/common/CustomDialog';
import { Code } from 'models/common/CommonCode';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import CustomGrid from 'components/grids/CustomGrid';
import { getExcelFileName } from 'utils/ExcelUtil';
import {
  ResultRegistCondition,
  SetupMaster,
  SetupTgtEqpResult,
} from 'models/pjm/SetupResultRegist';
import {
  saveEqpTgtResultList,
  getSetupResultRegistMaster,
  getSetupEqpTgtResultList,
} from 'apis/pjm/SetupResultRegist';
import { CrudCode } from 'models/common/Edit';
import useSessionStore from 'stores/useSessionStore';
import { hasRole } from 'utils/SessionUtil';
import WJCellTextarea from 'components/inputs/WJCellTextarea';
import { GridStatusCellTemplate } from 'components/grids/GridStatusCellRenderer';
import { GatingContListPopUp } from '../../gtng/popup/GatingContListPopUp';
import FileUploadPopUp from '../../common/components/FileUploadPopUp';
import { updateAtchFileGrId } from 'apis/pjm/SetupScheduleRegist';
import FacilityInspectionHistoryPopup from './FacilityInspectionHistoryPopup';

type Props = {
  open: boolean;
  close: () => void;
  condition: ResultRegistCondition;
  onRefresh: () => void;
};

const SetupResultRegistPopup = ({ open, close, condition, onRefresh }: Props) => {
  const gridRef = useRef<any>();
  const [hitTest, setHitTest] = useState<any>();

  const userSession = useSessionStore();
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();

  const [historyCondition, setHistoryCondition] = useState<any>({});
  const [isOpenFacilityInspectionHistoryModal, setOpenFacilityInspectionHistoryModal] =
    useState<boolean>(false);

  const [perdWctCode, setPerdWctCode] = useState<Code[]>([]);
  const [masterData, setMasterData] = useState<SetupMaster>();
  const [rowData, setRowData] = useState<SetupTgtEqpResult[]>([]);
  const [isOpenUserReadModal, setOpenUserReadModal] = useState<boolean>(false);
  const [userIds, setUserIds] = useState<string>('');
  const [isOpenCellTextarea, setOpenCellTextarea] = useState<boolean>(false);
  const [fileUploadModalCondition, setFileUploadModalCondition] = useState<any>({});
  const [isOpenFileUploadModal, setOpenFileUploadModal] = useState<boolean>(false);
  const [isSave, setSave] = useState<boolean>(false);
  const hasAuth = useMemo(() => hasRole('ADM') || hasRole('SETUP_MANAGER'), []);
  const hasAuthSpManager = useMemo(
    () => (rowData || []).filter((o) => o.authYn === 'Y').length > 0,
    [rowData]
  ); // 담당자인 경우

  useEffect(() => {
    getCommonCodes();
  }, []);

  useEffect(() => {
    if (condition.elmPrjId) {
      // master 정보 조회
      getSetupResultRegistMaster(condition.elmPrjId).then((result) => {
        setMasterData(result);
      });
      handleSearch();
      setSave(false);
    }
  }, [condition.elmPrjId]);

  const handleSearch = () => {
    searchEqpTgtResultList(condition);
  };

  const getCommonCodes = async () => {
    const perdWctCodes: Code[] = await getCommonCodeNames('PERD_WCT_CD'); // 일정주차

    const today = dayjs().format('YYYYMMDD');
    const filteredPerdWct = perdWctCodes.filter(
      (item) =>
        (dayjs(today).isAfter(dayjs(item.optValCtn3)) ||
          dayjs(today).isSame(dayjs(item.optValCtn3))) &&
        (dayjs(today).isBefore(dayjs(item.optValCtn4)) ||
          dayjs(today).isSame(dayjs(item.optValCtn4)))
    );
    setPerdWctCode(filteredPerdWct);
  };

  const handleExportExcel = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(gridRef.current, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = '실적입력';
    book.saveAsync(getExcelFileName(t('pjm.label.실적입력', '실적입력')));
  };

  const layoutDefinition = useMemo(() => {
    return [
      {
        binding: 'crudKey',
        header: String(t('com.label.상태', '상태')),
        isReadOnly: true,
        width: 43,
        align: 'center',
        cellTemplate: GridStatusCellTemplate,
      },
      {
        binding: 'prdnPldoCdNm',
        header: String(t('pjm.label.Factory', 'Factory')),
        width: 150,
        align: 'left',
        isReadOnly: true,
      },
      {
        binding: 'prdnProcCdNm',
        header: String(t('pjm.label.공정', '공정')),
        width: 90,
        align: 'center',
        isReadOnly: true,
      },
      {
        binding: 'eqclId',
        header: String(t('pjm.label.설비군', '설비군')),
        width: 90,
        align: 'center',
        isReadOnly: true,
      },
      {
        header: String(t('pjm.label.Line', 'Line')),
        align: 'center',
        columns: [
          {
            binding: 'eltrTpCdNm',
            header: String(t('pjm.label.A/C', 'A/C')),
            width: 80,
            isReadOnly: true,
          },
          {
            binding: 'eqpLnId',
            header: String(t('pjm.label.호기', '호기')),
            width: 80,
            align: 'center',
            isReadOnly: true,
          },
        ],
      },
      {
        binding: 'eqpId',
        header: String(t('pjm.label.Machine', 'Machine')),
        width: 90,
        align: 'left',
        isReadOnly: true,
      },
      {
        binding: 'prjSpCdNm',
        header: String(t('pjm.label.일정단계', '일정단계')),
        width: 120,
        align: 'center',
        isReadOnly: true,
      },
      {
        binding: 'spMgrNms',
        header: String(t('pjm.label.담당자', '담당자')),
        cssClass: 'WijmoFind',
        align: 'left',
        isReadOnly: true,
        cellTemplate: (params) => `
          <span>${params.value || ''}</span>
          <Button /> 
        `,
      },
      {
        header: String(t('pjm.label.관리항목', '관리항목')),
        align: 'center',
        columns: [
          {
            binding: 'planEndDt',
            header: String(t('pjm.label.계획일정', '계획일정')),
            width: 100,
            isReadOnly: true,
            align: 'center',
          },
          {
            binding: 'isptDt',
            header: String(t('pjm.label.점검일정', '점검일정')),
            width: 100,
            isReadOnly: true,
            align: 'center',
            cellTemplate: CellMaker.makeLink({
              click: (e, ctx) => {
                const condition = {
                  elmPrjId: ctx.item.elmPrjId,
                  tgtEqpId: ctx.item.tgtEqpId,
                  prjSpCd: ctx.item.prjSpCd,
                  prjSpCdNm: ctx.item.prjSpCdNm,
                  planEndDt: ctx.item.planEndDt,
                  crtnYm: perdWctCode[0].optValCtn1,
                  perdWct: perdWctCode[0].optValCtn2,
                  isptDt: ctx.item.isptDt,
                  isptYn: ctx.item.isptYn,
                  isptUserId: ctx.item.isptUserId,
                  isptUserNm: ctx.item.isptUserNm,
                  isptCtn: ctx.item.isptCtn,
                };
                setHistoryCondition(condition);
                setOpenFacilityInspectionHistoryModal(true);
              },
            }),
          },
          {
            binding: 'crtnYm',
            header: String(t('pjm.label.기준년월', '기준년월')),
            width: 80,
            isReadOnly: true,
            align: 'center',
          },
          {
            binding: 'perdWct',
            header: String(t('pjm.label.기간차수', '기간차수')),
            width: 80,
            align: 'center',
            isReadOnly: true,
          },
          {
            binding: 'isptYn',
            header: String(t('pjm.label.점검여부', '점검여부')),
            width: 80,
            align: 'center',
            isReadOnly: true,
            cssClass: 'WijmoCheck',
            cellTemplate: (params) => checkYn(params, 'isptYn'),
          },
          {
            binding: 'isptUserNm',
            visible: false,
          },
          {
            binding: 'dlyDdn',
            header: String(t('pjm.label.지연일수', '지연일수')),
            width: 80,
            isReadOnly: true,
            align: 'center',
            cellTemplate: (params) => {
              if (params.item.cpltYn !== 'Y' && (params.value || 0) > 0) {
                return `D+${Number(params.value || 0).toLocaleString()}`;
              }
            },
          },
          {
            binding: 'cpltYn',
            header: String(t('pjm.label.완료여부', '완료여부')),
            width: 80,
            isReadOnly: true,
            align: 'center',
            cssClass: 'WijmoCheck',
            cellTemplate: (params) => checkYn(params, 'cpltYn'),
          },
          {
            binding: 'shipNo',
            header: String(t('pjm.label.선적번호', '선적번호')),
            width: 100,
            align: 'left',
          },
        ],
      },
      {
        binding: 'isptCtn',
        header: String(t('pjm.label.점검내용', '점검내용')),
        width: 100,
        align: 'left',
      },
      {
        binding: 'isuTitNm',
        header: String(t('pjm.label.Issue', 'Issue')),
        width: 100,
        align: 'left',
        isReadOnly: true,
      },
      {
        binding: 'atchFileCnt',
        header: String(t('pjm.label.첨부파일', '첨부파일')),
        isReadOnly: true,
        align: 'center',
        width: 80,
        cellTemplate: (params) =>
          `<div class="fileDiv"><span><em>${(
            params.value || 0
          ).toLocaleString()}</em></span></div>`,
      },
      {
        binding: 'dataUpdUserNm',
        header: String(t('pjm.label.최종수정자', '최종수정자')),
        width: 120,
        align: 'center',
        isReadOnly: true,
      },
      {
        binding: 'dataUpdDtm',
        header: String(t('pjm.label.최종수정일', '최종수정일')),
        width: 150,
        align: 'center',
        isReadOnly: true,
      },
      {
        binding: 'atchFileGrId',
        visible: false,
      },
      {
        binding: 'spMgrIds',
        visible: false,
      },
      {
        binding: 'tgtEqpId',
        visible: false,
      },
      {
        binding: 'elmPrjId',
        visible: false,
      },
      {
        binding: 'prjSpCd',
        visible: false,
      },
    ];
  }, [perdWctCode]);

  const checkYn = useEvent((params, binding) => {
    const checked = params.value === 'Y' ? 'checked' : '';
    const disabled = checkDisabled(params.item) ? 'disabled' : '';
    return `<div class="checkbox">
            <input type="checkbox" id="${binding}_${params.item.tgtEqpId}" ${checked} ${disabled}/>  
            <label />
          </div>
    `;
  });

  const checkDisabled = (item) => {
    const mspd =
      item.crudKey !== CrudCode.UPDATE && item.prjSpCd === 'MSPD' && item.cpltYn === 'Y'
        ? false
        : true;
    const disabled =
      (hasAuth || item.authYn === 'Y') && item.curSpYn === 'Y' && mspd ? '' : 'disabled';
    return !((hasAuth || item.authYn === 'Y') && item.curSpYn === 'Y' && mspd);
  };

  const handleFileUpload = async (item, row, col) => {
    setFileUploadModalCondition({
      atchFileGrId: item.atchFileGrId,
      atchFileTpCd: 'SETUP',
      tableName: 'tb_eelmb_prj_tgt_sp_ispt_m',
      bizName: 'prj',
      downloadOnly: !((hasAuth || item.authYn === 'Y') && item.curSpYn === 'Y'),
      target: {
        item: item,
        row: row,
        col: col,
      },
    });
    setOpenFileUploadModal(true);
  };

  const onItemFormatter = useEvent((panel, row, col, cell) => {
    if (CellType.Cell === panel.cellType) {
      const binding = panel.columns[col].binding;
      const item = panel.rows[row].dataItem;
      const editable = (hasAuth || item.authYn === 'Y') && item.curSpYn === 'Y';
      if (['isptCtn', 'isuTitNm'].includes(binding)) {
        cell.ariaReadOnly = !editable;
      }
      if ('shipNo' === binding) {
        cell.ariaReadOnly = !(editable && item.prjSpCd === 'FOB');
      }
      if ('atchFileCnt' === binding) {
        const downloadOnly = !((hasAuth || item.authYn === 'Y') && item.curSpYn === 'Y');
        if (downloadOnly && Number(item.atchFileCnt || 0) < 1) {
          removeClass(cell.firstChild, 'fileDiv');
        }
      }
    }
    if (CellType.ColumnHeader === panel.cellType) {
      const binding = panel.columns[col].binding;
      // 필수항목
      if (['isptCtn'].includes(binding)) {
        addClass(cell, 'dot');
      }
    }
  });

  const onBeginningEdit = useEvent((grid, e) => {
    const binding = grid.columns[e.col].binding;
    const item = grid.rows[e.row].dataItem;
    const editable = (hasAuth || item.authYn === 'Y') && item.curSpYn === 'Y';
    if (['isptCtn', 'isuTitNm'].includes(binding)) {
      e.cancel = !editable;
    }
    if ('shipNo' === binding) {
      e.cancel = !(editable && item.prjSpCd === 'FOB');
    }
  });

  const onInitialized = useEvent((grid) => {
    gridRef.current = grid;
    grid.itemFormatter = onItemFormatter;

    grid.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);

      if (ht.row < 0 || ht.col < 0) return;

      const item = grid.rows[ht.row].dataItem;
      if (ht.panel === grid.cells) {
        const binding = grid.columns[ht.col].binding;
        if ('isuTitNm' === binding) {
          if (hasAuth || item.authYn === 'Y') {
            setHitTest(ht);
            setOpenCellTextarea(true);
          }
        }
        if ('spMgrNms' === binding) {
          setOpenUserReadModal(true);
          setUserIds(item.spMgrIds);
        }
        if (['isptYn', 'cpltYn'].includes(binding)) {
          if (!checkDisabled(item)) {
            item[binding] = e.target.checked ? 'Y' : 'N';
            item.crudKey = CrudCode.UPDATE;
            gridRef.current?.refresh();
          }
        }
        if ('atchFileCnt' === binding) {
          if (
            ((hasAuth || item.authYn === 'Y') && item.curSpYn === 'Y') ||
            Number(item.atchFileCnt || 0) > 0
          ) {
            handleFileUpload(item, ht.row, ht.col);
          }
        }
      }
    });
  });

  const searchEqpTgtResultList = (condition) => {
    getSetupEqpTgtResultList(condition).then((result: SetupTgtEqpResult[]) => {
      if (result !== null) {
        setRowData(result);
      } else {
        openMessageBar({
          type: 'error',
          content: t(
            'com.msg.요청 정보 조회 중 오류가 발생했습니다.',
            '요청 정보 조회 중 오류가 발생했습니다.'
          ),
        });
      }
    });
  };

  const handleSave = () => {
    const resultList = rowData.filter((element) => element.crudKey == CrudCode.UPDATE);

    if (resultList.length < 1) {
      openMessageBar({
        type: 'error',
        content: `${t('pjm.label.수정내역이 없습니다.', '수정내역이 없습니다.')}`,
      });
      return;
    }

    const valid = resultList
      .map((rowNode, index) => {
        if (rowNode.isptYn === 'Y' && (rowNode.isptCtn === null || rowNode.isptCtn === ''))
          return `${t(
            'pjm.label.점검을 완료하려면 점검내용을 입력해 주세요.',
            '점검을 완료하려면 점검내용을 입력해 주세요.'
          )}`;
        if (
          (rowNode.isptYn === 'N' || rowNode.isptYn === null || rowNode.isptYn === '') &&
          rowNode.cpltYn === 'Y'
        )
          return `${t(
            'pjm.label.일정단계를 완료하려면 점검완료를 먼저 진행해 주세요.',
            '일정단계를 완료하려면 점검완료를 먼저 진행해 주세요.'
          )}`;
        if (
          (rowNode.isptYn === 'N' || rowNode.isptYn === null) &&
          (rowNode.isptCtn !== null || rowNode.isptCtn !== '')
        )
          return `${t('pjm.label.점검여부를 확인해 주세요', '점검여부를 확인해 주세요')}`;
        // if (rowNode.crudKey !== CrudCode.CREATE)
        //   return `${t(
        //     'ut.label.사용중인 기준정보 입니다. 삭제할 수 없습니다.',
        //     '사용중인 기준정보 입니다. 삭제할 수 없습니다.'
        //   )}`;
      })
      .filter((element) => element !== undefined);
    if (valid.length) {
      const content = valid[0]?.toString();
      openMessageBar({ type: 'error', content: content || '' });
      return;
    }

    saveEqpTgtResultList(resultList).then((res) => {
      openMessageBar({
        type: res?.successOrNot === 'Y' ? 'confirm' : 'error',
        content:
          res?.successOrNot === 'Y'
            ? t('com.msg.저장되었습니다.', '저장되었습니다.')
            : t('com.msg.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
      });
      if (res?.successOrNot === 'Y') {
        handleSearch();
        setSave(true);
      }
    });
  };

  const handleClose = useEvent(() => {
    if (isSave) {
      onRefresh();
    }
    close();
  });

  const dialogButtons = [
    <Button
      key={'confirm'}
      css={IconButton.button}
      className="confirm"
      onClick={handleSave}
      disableRipple
    >
      {t('com.button.저장', '저장')}
    </Button>,
  ];

  return (
    <CustomDialog
      title={t('com.label.실적입력', '실적입력')}
      size="xl"
      open={open}
      onClose={handleClose}
      onCancel={hasAuth || hasAuthSpManager ? handleClose : undefined}
      buttons={hasAuth || hasAuthSpManager ? dialogButtons : undefined}
    >
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('pjm.label.Setup Master', 'Setup Master')}</h3>
        </SubTitleGroup>
      </SubTitleLayout>
      <TableContainer css={tb.table} style={{ marginBottom: '24px' }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <span>{t('pjm.label.법인', '법인')}</span>
              </TableCell>
              <TableCell>{masterData?.copCd}</TableCell>
              <TableCell>
                <span>{t('pjm.label.구분', '구분')}</span>
              </TableCell>
              <TableCell>{masterData?.elmPrjTpCdNm}</TableCell>
              <TableCell>
                <span>{t('pjm.label.프로젝트 ID', '프로젝트 ID')}</span>
              </TableCell>
              <TableCell>{masterData?.elmPrjId}</TableCell>
              <TableCell>{t('pjm.label.상태', '상태')}</TableCell>
              <TableCell>{masterData?.elmPrjStatCdNm}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span>{t('pjm.label.프로젝트 명', '프로젝트 명')}</span>
              </TableCell>
              <TableCell colSpan={5}>{masterData?.elmPrjNm}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('pjm.label.실적입력', '실적입력')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{rowData?.length ?? 0}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          {perdWctCode[0]?.cmnCdNm && (
            <CustomInputWithSearch
              name="perdWctCode"
              value={perdWctCode[0]?.cmnCdNm || ''}
              style={{
                width: '145px',
              }}
              readOnly={true}
            />
          )}
          <SubTitleGroup>
            {perdWctCode[0]?.optValCtn3 && perdWctCode[0]?.optValCtn4 && (
              <span className="info primary">
                {`${dayjs(perdWctCode[0]?.optValCtn3 || '').format('MM월DD일')} ~ ${dayjs(
                  perdWctCode[0]?.optValCtn4 || ''
                ).format('MM월DD일')}`}
              </span>
            )}
          </SubTitleGroup>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={handleExportExcel}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
          <Button
            css={IconButton.button}
            className="refresh"
            onClick={() => handleSearch()}
            disableRipple
          >
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        height={400}
        frozenColumns={7}
        isSelector={false}
        allowPinning={false}
        excludeFilter={[
          'prdnPldoCdNm',
          'prdnProcCdNm',
          'eqclId',
          'eltrTpCdNm',
          'eqpLnId',
          'prjSpCdNm',
          'planEndDt',
          'crtnYm',
          'perdWct',
          'isptYn',
          'dlyDdn',
          'cpltYn',
          'shipNo',
          'isptCtn',
          'isuTitNm',
          'atchFileCnt',
          'dataUpdUserNm',
          'dataUpdDtm',
        ]}
        initialized={onInitialized}
        beginningEdit={onBeginningEdit}
      />
      {isOpenCellTextarea && (
        <WJCellTextarea
          grid={gridRef.current}
          hitTest={hitTest}
          close={() => setOpenCellTextarea(false)}
        />
      )}
      {isOpenUserReadModal && (
        <GatingContListPopUp
          open={isOpenUserReadModal}
          close={() => setOpenUserReadModal(false)}
          title={t('com.label.담당자', '담당자')}
          initParam={userIds}
        />
      )}
      {isOpenFacilityInspectionHistoryModal && (
        <FacilityInspectionHistoryPopup
          open={isOpenFacilityInspectionHistoryModal}
          close={() => setOpenFacilityInspectionHistoryModal(false)}
          onCallback={() => console.log('')}
          condition={historyCondition}
        />
      )}
      {isOpenFileUploadModal && (
        <FileUploadPopUp
          open={isOpenFileUploadModal}
          close={() => setOpenFileUploadModal(false)}
          singleSelect={false}
          downloadOnly={fileUploadModalCondition.downloadOnly}
          initParam={{
            atchFileGrId: fileUploadModalCondition.atchFileGrId,
            atchFileTpCd: fileUploadModalCondition.atchFileTpCd,
            optValCtn1: fileUploadModalCondition.tableName,
            bizName: fileUploadModalCondition.bizName,
          }}
          onCallback={(atchFileGrId, fileCount) => {
            const target = fileUploadModalCondition.target;
            if (target) {
              if (!fileUploadModalCondition.atchFileGrId && atchFileGrId && fileCount > 0) {
                // 신규 등록인 경우 첨부파일ID 업데이트
                const item = gridRef.current?.rows[target.row].dataItem;
                const params = {
                  tgtEqpId: item.tgtEqpId,
                  prjSpCd: item.prjSpCd,
                  crtnYm: item.crtnYm,
                  perdWct: item.perdWct,
                  atchFileGrId: atchFileGrId,
                };
                updateAtchFileGrId(params).then(() => {
                  item.atchFileGrId = atchFileGrId;
                });
              }
              gridRef.current?.setCellData(target.row, target.col, fileCount);
              gridRef.current?.startEditing(false, target.row, target.col);
              gridRef.current?.finishEditing();
            }
            setOpenFileUploadModal(false);
          }}
        />
      )}
    </CustomDialog>
  );
};

export default SetupResultRegistPopup;
