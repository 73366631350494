/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';
import { ControlBtnGroup, SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import { IconButton } from 'components/buttons/IconSVG';
import CustomGrid from 'components/grids/CustomGrid';
import { getExcelFileName } from 'utils/ExcelUtil';
import { ApproveRequestPageType } from 'pages/approves/ApproveRequestDetailCase';
import ApproveRequestModal from '../../approves/ApproveRequestModal';
import { IdleAssetMaster } from 'models/asst/IdleAsset';
import { findIdleAssetMaster, generateRvwReqId } from 'apis/asst/IdleAsset';
import { useMessageBar } from 'hooks/useMessageBar';
import useEvent from 'react-use-event-hook';
import _ from 'lodash';

interface Prop {
  onClikDetail: (item: IdleAssetMaster) => void;
}

const IdleAssetRequestGrid = ({ onClikDetail }: Prop, ref) => {
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();
  const gridRef = useRef<any>();
  const [rowData, setRowData] = useState<IdleAssetMaster[]>([]);
  const [isOpenRequestModal, setOpenRequestModal] = useState<boolean>(false);
  const [requestModalCondition, setRequestModalCondition] = useState<any>();

  useImperativeHandle(ref, () => ({
    search: (params) => {
      findIdleAssetMaster(params).then((result) => {
        setRowData(result);
        if (!_.isNull(result[0])) {
          onClikDetail(result[0]);
        }
      });
    },
  }));

  const handleExportExcel = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(gridRef.current, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = t('asst.label.유휴자산 요청 List', '불용자산 요청 List');
    book.saveAsync(getExcelFileName(t('asst.label.유휴자산 요청 List', '불용자산 요청 List')));
  };

  const onInitialized = (grid) => {
    gridRef.current = grid;
    // grid.itemFormatter = onItemFormatter;
  };

  const layoutDefinition = [
    {
      binding: 'no',
      header: String(t('com.label.NO', 'NO')),
      align: 'center',
      width: 40,
      isReadOnly: true,
      cellTemplate: (grid) => grid.row._idx + 1,
    },
    {
      binding: 'asstRvwReqCopCd',
      header: String(t('asst.label.법인', '법인')),
      align: 'center',
      width: 100,
    },
    {
      binding: 'rvwReqId',
      header: String(t('asst.label.요청번호', '요청번호')),
      align: 'center',
      width: 100,
      cellTemplate: CellMaker.makeLink({
        text: '${item.rvwReqId}',
        click: (e, ctx) => {
          onClikDetail(ctx.item);
        },
      }),
    },
    {
      binding: 'rvwReqTitNm',
      header: String(t('asst.label.요청제목', '요청제목')),
      align: 'left',
      minWidth: 200,
      width: '*',
      cellTemplate: CellMaker.makeLink({
        text: '${item.rvwReqTitNm}',
        click: (e, ctx) => {
          onClikDetail(ctx.item);
        },
      }),
    },
    {
      binding: 'eqpCount',
      header: String(t('asst.label.설비대수', '설비대수')),
      align: 'center',
      width: 100,
    },
    {
      binding: 'rvwStatCdNm',
      header: String(t('asst.label.상태', '상태')),
      align: 'center',
      width: 100,
    },
    {
      binding: 'progressRate',
      header: String(t('asst.label.검토율', '검토율')),
      align: 'center',
      width: 150,
    },
    {
      binding: 'rvwCpltDt',
      header: String(t('asst.label.검토완료일자', '검토완료일자')),
      align: 'center',
      width: 150,
    },
    {
      binding: 'rvwReqCtn',
      header: String(t('asst.label.요청내용', '요청내용')),
      align: 'left',
      width: 150,
    },
    {
      binding: 'dataInsUserNm',
      header: String(t('asst.label.요청자', '요청자')),
      align: 'center',
      width: 100,
      isReadOnly: true,
    },
    {
      binding: 'dataInsDtm',
      header: String(t('asst.label.요청일자', '요청일자')),
      align: 'center',
      width: 120,
      isReadOnly: true,
    },
  ];

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('asst.label.유휴자산 요청 List', '불용자산 요청 List')}</h3>
          <span className="total">
            {t('com.label.총', '총')} <span>{(rowData || []).length.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="create primaryBtn"
            disableRipple
            onClick={() => {
              generateRvwReqId().then((result) => {
                if (!result) {
                  openMessageBar({
                    type: 'error',
                    content: t(
                      'asst.label.검토요청번호 생성에 실패했습니다.<br/>새로고침 후 다시 시도해 주세요.',
                      '검토요청번호 생성에 실패했습니다.<br/>새로고침 후 다시 시도해 주세요.'
                    ),
                  });
                  return;
                }
                setRequestModalCondition({
                  pageId: ApproveRequestPageType.IDLE_ASST_REQ,
                  rvwReqId: result,
                });
                setOpenRequestModal(true);
              });
            }}
          >
            {t('com.button.신규등록', '신규등록')}
          </Button>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={handleExportExcel}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        height={250}
        isReadOnly={true}
        isSelector={false}
        allowPinning={false}
        initialized={onInitialized}
      />

      {isOpenRequestModal && (
        <ApproveRequestModal
          open={isOpenRequestModal}
          close={() => setOpenRequestModal(false)}
          pageId={requestModalCondition?.pageId}
          aprReqId={requestModalCondition?.aprReqId}
          condition={{
            rvwReqId: requestModalCondition?.rvwReqId,
          }}
        />
      )}
    </>
  );
};

export default React.forwardRef(IdleAssetRequestGrid);
