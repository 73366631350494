/** @jsxImportSource @emotion/react */
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { camelCase } from 'lodash';
import { Button } from '@mui/material';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { CellType, DataMap } from '@grapecity/wijmo.grid';
import { ControlBtnGroup, SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import { IconButton } from 'components/buttons/IconSVG';
import CustomGrid from 'components/grids/CustomGrid';
import {
  FacilityManager,
  FacilityManagerCondition,
  FacilityScheduleRegistMaster,
} from 'models/pjm/FacilityScheduleRegist';
import { getExcelFileName } from 'utils/ExcelUtil';
import { useMessageBar } from 'hooks/useMessageBar';
import { ComboBox } from 'components/selects/ComboBox';
import { findFacilityManager, saveFacilityManager } from 'apis/pjm/FacilityScheduleRegist';
import { getCommonCodeNames, getCommonCodeNamesCondition } from 'apis/admin/CommonCode';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { Code } from 'models/common/CommonCode';
import UserModal from 'components/modals/common/UserModal';
import { Employee } from 'models/admin/Employee';
import { CrudCode } from 'models/common/Edit';
import { GridStatusCellTemplate } from 'components/grids/GridStatusCellRenderer';
import { useLoading } from 'components/process/Loading';

const ManagerRegistGrid = (props: any, ref) => {
  const {
    onSubmit,
    condition = {} as FacilityManagerCondition,
    masterData = {} as FacilityScheduleRegistMaster,
  } = props;

  const gridRef = useRef<any>();
  const { openMessageBar } = useMessageBar();
  const { openLoading } = useLoading();
  const { t } = useTranslation();
  const [rowData, setRowData] = useState<FacilityManager[]>([]);
  const [checkedItems, setCheckedItems] = useState<FacilityManager[]>([]);
  const [code, setCode] = useState<any>({});
  const [allMgrType, setAllMgrType] = useState<string>('');
  const [allMgrNm, setAllMgrNm] = useState<string>();
  const [allMgrId, setAllMgrId] = useState<string>();
  const [isOpenUserModal, setOpenUserModal] = useState<any>(false);
  const [isOpenAllUserModal, setOpenAllUserModal] = useState<any>(false);
  const [userModalCondition, setUserModalCondition] = useState<any>({});

  useImperativeHandle(ref, () => ({
    getRowData: () => {
      return rowData;
    },
    saveManager: () => {
      return new Promise((resolve) => {
        handleSave().finally(() => {
          resolve(true);
        });
      });
    },
    refresh: () => {
      handleSearch(masterData);
    },
  }));

  useEffect(() => {
    getCommonCodes();
  }, []);

  useEffect(() => {
    if (masterData?.copCd && masterData?.elmPrjId) {
      handleSearch(masterData);
    }
  }, [masterData?.copCd, masterData?.elmPrjId]);

  const getCommonCodes = async () => {
    const elmCopCd: Code[] = await getCommonCodeNames('ELM_COP_CD'); // 법인코드
    const factoryCode: Code[] = await getCommonCodeNames('FACTORY_CODE');
    const prjSpCd: Code[] = await getCommonCodeNamesCondition({
      optValCtn1: 'Y',
      cmnGrCd: 'PRJ_SP_CD',
    }); // 프로젝트 단계
    const prdnProcCd = await getCommonCodeNamesCondition({
      cmnGrCd: 'PRDN_PROC_CD',
    }); // 공정

    setCode({
      elmCopCd: elmCopCd,
      prjSpCd: prjSpCd,
      factoryCode: factoryCode,
      prdnProcCd: prdnProcCd,
    });
  };

  const handleSearch = (condition) => {
    findFacilityManager(condition).then((result) => {
      setRowData(result);
      setCheckedItems([]);
    });
  };

  const handleSave = () => {
    return new Promise((resolve) => {
      // 변경된 데이터가 없는 경우
      if (!(rowData || []).filter((o) => o.crudKey).length) {
        openMessageBar({
          type: 'warning',
          content: t('com.label.변경된 내용이 없습니다.', '변경된 내용이 없습니다.'),
        });
        resolve(true);
        return;
      }

      openLoading(true);
      saveFacilityManager(rowData)
        .then((res) => {
          openMessageBar({
            type: res.successOrNot === 'Y' ? 'confirm' : 'error',
            content:
              res.successOrNot === 'Y'
                ? t('com.label.저장되었습니다.', '저장되었습니다.')
                : t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
          });
          if (res?.successOrNot === 'Y') {
            handleSearch(masterData);
          }
        })
        .finally(() => {
          openLoading(false);
          resolve(true);
        });
    });
  };

  const layoutDefinition = [
    {
      binding: 'crudKey',
      header: String(t('com.label.상태', '상태')),
      width: 43,
      cellTemplate: GridStatusCellTemplate,
    },
    {
      binding: 'prdnProcCd',
      header: String(t('pjm.label.공정', '공정')),
      align: 'center',
      width: 100,
      dataMap: new DataMap(code?.prdnProcCd || [], 'cmnCd', 'cmnCdNm'),
    },
    {
      binding: 'eqclId',
      header: String(t('pjm.label.설비군', '설비군')),
      isReadOnly: true,
      align: 'center',
      width: 100,
    },
    {
      binding: 'poIssuWrtUserNms',
      header: String(t('pjm.label.PO발행', 'PO발행')),
      cssClass: 'WijmoFind',
      align: 'left',
      isReadOnly: true,
      isRequired: false,
      cellTemplate: (params) => `
        <span>${params.value || ''}</span>
        <Button /> 
      `,
    },
    {
      binding: 'atMatWrtUserNms',
      header: String(t('pjm.label.시운전자재', '시운전자재')),
      cssClass: 'WijmoFind',
      align: 'left',
      isReadOnly: true,
      isRequired: false,
      cellTemplate: (params) => `
        <span>${params.value || ''}</span>
        <Button /> 
      `,
    },
    {
      binding: 'fatWrtUserNms',
      header: String(t('pjm.label.FAT', 'FAT')),
      cssClass: 'WijmoFind',
      align: 'left',
      isReadOnly: true,
      isRequired: false,
      cellTemplate: (params) => `
        <span>${params.value || ''}</span>
        <Button /> 
      `,
    },
    {
      binding: 'fobWrtUserNms',
      header: String(t('pjm.label.FOB', 'FOB')),
      cssClass: 'WijmoFind',
      align: 'left',
      isReadOnly: true,
      isRequired: false,
      cellTemplate: (params) => `
        <span>${params.value || ''}</span>
        <Button /> 
      `,
    },
    {
      binding: 'eqpCrryWrtUserNms',
      header: String(t('pjm.label.설비반입', '설비반입')),
      cssClass: 'WijmoFind',
      align: 'left',
      isReadOnly: true,
      isRequired: false,
      cellTemplate: (params) => `
        <span>${params.value || ''}</span>
        <Button /> 
      `,
    },
    {
      binding: 'istlWrtUserNms',
      header: String(t('pjm.label.설치', '설치')),
      cssClass: 'WijmoFind',
      align: 'left',
      isReadOnly: true,
      isRequired: false,
      cellTemplate: (params) => `
        <span>${params.value || ''}</span>
        <Button /> 
      `,
    },
    {
      binding: 'iocWrtUserNms',
      header: String(t('pjm.label.I/O Check', 'I/O Check')),
      cssClass: 'WijmoFind',
      align: 'left',
      isReadOnly: true,
      isRequired: false,
      cellTemplate: (params) => `
        <span>${params.value || ''}</span>
        <Button /> 
      `,
    },
    {
      binding: 'wetRunWrtUserNms',
      header: String(t('pjm.label.Wet Run', 'Wet Run')),
      cssClass: 'WijmoFind',
      align: 'left',
      isReadOnly: true,
      isRequired: false,
      cellTemplate: (params) => `
        <span>${params.value || ''}</span>
        <Button /> 
      `,
    },
    {
      binding: 'qcWrtUserNms',
      header: String(t('pjm.label.Quality check', 'Quality check')),
      cssClass: 'WijmoFind',
      align: 'left',
      isReadOnly: true,
      isRequired: false,
      cellTemplate: (params) => `
        <span>${params.value || ''}</span>
        <Button /> 
      `,
    },
    {
      binding: 'satWrtUserNms',
      header: String(t('pjm.label.SAT', 'SAT')),
      cssClass: 'WijmoFind',
      align: 'left',
      isReadOnly: true,
      isRequired: false,
      cellTemplate: (params) => `
        <span>${params.value || ''}</span>
        <Button /> 
      `,
    },
    {
      binding: 'smplPrdnWrtUserNms',
      header: String(t('pjm.label.샘플생산', '샘플생산')),
      cssClass: 'WijmoFind',
      align: 'left',
      isReadOnly: true,
      isRequired: false,
      cellTemplate: (params) => `
        <span>${params.value || ''}</span>
        <Button /> 
      `,
    },
    {
      binding: 'mspdWrtUserNms',
      header: String(t('pjm.label.양산', '양산')),
      cssClass: 'WijmoFind',
      align: 'left',
      isReadOnly: true,
      isRequired: false,
      cellTemplate: (params) => `
        <span>${params.value || ''}</span>
        <Button /> 
      `,
    },
    {
      binding: 'planProcId',
      visible: false,
    },
    {
      binding: 'poIssuWrtUserIds',
      visible: false,
    },
    {
      binding: 'atMatWrtUserIds',
      visible: false,
    },
    {
      binding: 'fatWrtUserIds',
      visible: false,
    },
    {
      binding: 'fobWrtUserIds',
      visible: false,
    },
    {
      binding: 'eqpCrryWrtUserIds',
      visible: false,
    },
    {
      binding: 'istlWrtUserIds',
      visible: false,
    },
    {
      binding: 'iocWrtUserIds',
      visible: false,
    },
    {
      binding: 'wetRunWrtUserIds',
      visible: false,
    },
    {
      binding: 'qcWrtUserIds',
      visible: false,
    },
    {
      binding: 'satWrtUserIds',
      visible: false,
    },
    {
      binding: 'smplPrdnWrtUserIds',
      visible: false,
    },
    {
      binding: 'mspdWrtUserIds',
      visible: false,
    },
  ];

  const onInitialized = (grid) => {
    gridRef.current = grid;
    grid.itemFormatter = (panel, row, col, cell) => {
      if (CellType.Cell === panel.cellType) {
        const binding = panel.columns[col].binding;
        const item = panel.rows[row].dataItem;
        // 담당자 editable css 적용
        if ((binding || '').endsWith('WrtUserNms')) {
          cell.ariaReadOnly = false;
        }
      }
    };

    grid.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);

      if (ht.row < 0 || ht.col < 0) return;

      if (ht.panel === grid.cells) {
        const item = grid.rows[ht.row].dataItem;
        const binding = grid.columns[ht.col].binding;

        if ((binding || '').endsWith('WrtUserNms')) {
          const id = binding.replace('WrtUserNms', 'WrtUserIds');
          setUserModalCondition({
            wrtUserIds: item[id],
            target: {
              binding: binding,
              row: ht.row,
              col: ht.col,
            },
          });
          setOpenUserModal(true);
        }
      }
    });
  };

  const handleSetAllUser = () => {
    if ((checkedItems || []).length < 1) {
      openMessageBar({
        type: 'error',
        content: t('pjm.label.일괄적용할 항목을 선택해주세요.', '일괄적용할 항목을 선택해주세요.'),
      });
      return;
    } else if (!allMgrType) {
      openMessageBar({
        type: 'error',
        content: String(t('pjm.label.단계를 선택해 주세요.', '단계를 선택해 주세요.')),
      });
      return;
    } else if (!allMgrId) {
      openMessageBar({
        type: 'error',
        content: t('pjm.label.담당자를 선택해 주세요.', '담당자를 선택해 주세요.'),
      });
      return;
    }

    const id = `${camelCase(allMgrType)}WrtUserIds`;
    const nm = `${camelCase(allMgrType)}WrtUserNms`;
    (checkedItems || []).forEach((o) => {
      o[id] = allMgrId;
      o[nm] = allMgrNm;
      o.crudKey = CrudCode.UPDATE;
    });

    gridRef.current?.collectionView.refresh();
  };

  const btnExcelExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(gridRef.current, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = '담당자 등록';
    book.saveAsync(getExcelFileName(t('pjm.label.담당자등록', '담당자등록')));
  };

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('pjm.label.담당자등록', '담당자등록')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{(rowData || []).length.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
          {condition?.utmNo && <div className="info warning">{condition?.utmNo}</div>}
        </SubTitleGroup>
        <ControlBtnGroup>
          <SubTitleGroup>
            <span className="total">{t('pjm.label.단계', '단계')}&nbsp;</span>
          </SubTitleGroup>
          <ComboBox
            placeholder={String(t('com.label.선택', '선택'))}
            options={code?.prjSpCd || []}
            defaultValue={allMgrType}
            style={{
              width: '120px',
            }}
            onChange={(value) => setAllMgrType(value)}
          />
          <CustomInputWithSearch
            name="allContDataNm"
            className="find"
            placeholder={String(t('com.label.담당자를 선택해 주세요.', '담당자를 선택해 주세요.'))}
            value={allMgrNm}
            readOnly={true}
            onSearchClick={() => {
              setOpenAllUserModal(true);
            }}
          />
          <Button
            css={IconButton.button}
            className="setting"
            onClick={handleSetAllUser}
            disableRipple
          >
            {t('com.button.일괄적용', '일괄적용')}
          </Button>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={btnExcelExport}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
          <Button
            css={IconButton.button}
            className="refresh"
            onClick={() => handleSearch(masterData)}
            disableRipple
          >
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        isReadOnly={true}
        frozenColumns={3}
        isFilter={false}
        allowPinning={false}
        initialized={onInitialized}
        height={400}
        onChangeCheckedItem={(items) => setCheckedItems(items)}
      />

      {/*담당자 일괄적용*/}
      {isOpenAllUserModal && (
        <UserModal
          open={isOpenAllUserModal}
          close={() => setOpenAllUserModal(false)}
          title={t('pjm.label.담당자 조회', '담당자 조회')}
          defaultUserId={allMgrId}
          singleSelect={false}
          onCallback={(result) => {
            const users = (result || []) as Employee[];
            setAllMgrNm(users.map((o) => o.empNm).join(', '));
            setAllMgrId(users.map((o) => o.userId).join(', '));
          }}
        />
      )}

      {/*담당자 적용*/}
      {isOpenUserModal && (
        <UserModal
          open={isOpenUserModal}
          close={() => setOpenUserModal(false)}
          title={t('pjm.label.담당자 조회', '담당자 조회')}
          defaultUserId={userModalCondition?.wrtUserIds}
          singleSelect={false}
          onCallback={(result) => {
            const target = userModalCondition.target;
            if (target && target?.row > -1) {
              const users = (result || []) as Employee[];
              const item = gridRef.current?.rows[target.row].dataItem;
              const id = (target?.binding || '').replace('WrtUserNms', 'WrtUserIds');
              item[target.binding] = users.map((o) => o.empNm).join(', ');
              item[id] = users.map((o) => o.userId).join(', ');
              item.crudKey = CrudCode.UPDATE;
              gridRef.current?.collectionView.refresh();
            }
          }}
        />
      )}
    </>
  );
};

export default React.forwardRef(ManagerRegistGrid);
