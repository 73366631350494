/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DateRangeType } from 'react-tailwindcss-datepicker';
import dayjs from 'dayjs';
import {
  SearchBox,
  SearchBoxRow,
  InputBox,
  SearchRows,
  SearchCols,
} from 'components/layouts/SearchBox';
import CustomDatepicker from 'components/inputs/CustomDatepicker';
import SearchBoxButtons from 'components/buttons/SearchBoxButtons';
import CustomChip from 'components/selects/CustomChip';
import { getCommonCodeNamesCondition } from 'apis/admin/CommonCode';
import { Code } from 'models/common/CommonCode';
import { IdleAssetCondition, RvwStatCd } from 'models/asst/IdleAsset';
import IdleAssetStatusChart from './status/IdleAssetStatusChart';
import IdleAssetRequestGrid from './status/IdleAssetRequestResultGrid';
import IdleAssetStatusGrid from './status/IdleAssetPuseStatusGrid';

const IdlAssetStatusPage = () => {
  const { t } = useTranslation();
  const defaultCondition = {
    rvwStatCd: RvwStatCd.COMPLETE,
    startDate: dayjs().add(-2, 'year').format('YYYY.MM.DD'),
    endDate: dayjs().format('YYYY.MM.DD'),
  };
  const chartRef = useRef<any>();
  const requestRef = useRef<any>();
  const statusRef = useRef<any>();
  const [condition, setCondition] = useState<IdleAssetCondition>(defaultCondition);
  const [code, setCode] = useState<any>();

  useEffect(() => {
    getCommonCodes();
    handleSearch();
  }, []);

  const getCommonCodes = async () => {
    const rvwStatCd: Code[] = await getCommonCodeNamesCondition({
      optValCtn1: 'Y',
      cmnGrCd: 'RVW_STAT_CD',
    }); // 검토상태

    setCode({
      rvwStatCd: [{ cmnCd: '', cmnCdNm: String(t('com.label.전체', '전체')) }, ...rvwStatCd],
    });
  };

  const handleSearch = () => {
    chartRef.current?.search(condition);
    requestRef.current?.search(condition);
    statusRef.current?.search(condition);
  };

  const handleConditionChange = (name, value) => {
    setCondition({ ...condition, [name]: value });
  };

  return (
    <>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows>
              <SearchCols>
                <label>
                  <span>{String(t('asst.label.요청일자', '요청일자'))}</span>
                </label>
                <CustomDatepicker
                  isRange={true} // 기간선택인 경우 생략가능
                  value={{
                    startDate: condition?.startDate || '',
                    endDate: condition?.endDate || '',
                  }}
                  onChange={(newValue) => {
                    const value = newValue as DateRangeType;
                    setCondition({
                      ...condition,
                      startDate: (value?.startDate as string) || '',
                      endDate: (value?.endDate as string) || '',
                    });
                  }}
                />
              </SearchCols>
              <SearchCols className="width25">
                <label>
                  <span>{String(t('asst.label.검토상태', '검토상태'))}</span>
                </label>
                <CustomChip
                  options={code?.rvwStatCd || []}
                  defaultValue={condition?.rvwStatCd}
                  onChange={(value) => handleConditionChange('rvwStatCd', value)}
                />
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchBoxButtons
            defaultCondition={defaultCondition}
            setReset={setCondition}
            onSearch={() => handleSearch()}
          />
        </SearchBoxRow>
      </SearchBox>

      <IdleAssetStatusChart ref={chartRef} />

      <IdleAssetRequestGrid ref={requestRef} />

      <IdleAssetStatusGrid ref={statusRef} />
    </>
  );
};

export default IdlAssetStatusPage;
