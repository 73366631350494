/** @jsxImportSource @emotion/react */
import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import useEvent from 'react-use-event-hook';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Button } from '@mui/material';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { addClass, DataType, toggleClass } from '@grapecity/wijmo';
import { CellType, DataMap } from '@grapecity/wijmo.grid';
import {
  ControlBtnGroup,
  GlobalBtnGroup,
  SubTitleGroup,
  SubTitleLayout,
} from 'components/layouts/ContentLayout';
import { IconButton } from 'components/buttons/IconSVG';
import CustomGrid from 'components/grids/CustomGrid';
import {
  findProcessCode,
  findUtMatrix,
  findVoltageCode,
  saveUtMatrix,
} from 'apis/ut/UtMatrixRegist';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import ExcelUploadButton from 'components/buttons/ExcelUploadButton';
import { UtMatrixDetail, UtMatrixRegistSearchCondition } from 'models/ut/UtMatrix';
import { Code } from 'models/common/CommonCode';
import { commonYNcodes, FileTypeName, ManagementMode } from 'models/common/Common';
import {
  downloadExcelUtMatrixTemplatesPost,
  uploadExcelUtMatrixTemplates,
} from 'apis/common/Excel';
import {
  calculateBrkeCapa,
  calculateCspSumCapa,
  calculateElpwSumCapa,
  calculateOd1EcNvl,
  getInsTpCd,
  PWFT_NVL_DEFAULT,
} from 'utils/UtMatrixUtil';
import { useMessageBar } from 'hooks/useMessageBar';
import { useLoading } from 'components/process/Loading';
import { GridStatusCellTemplate } from 'components/grids/GridStatusCellRenderer';
import { CrudCode } from 'models/common/Edit';
import ExcelValidModal from 'components/modals/common/ExcelValidModal';
import { downloadFileByType } from 'apis/file/File';
import { hasRole } from 'utils/SessionUtil';
import { getExcelFileName } from 'utils/ExcelUtil';
import UtMatrixDetailProcessPopup from '../popup/UtMatrixDetailProcessPopup';
import { copyUtMatrixDetailProcess } from 'apis/ut/UtMatrixDetailProcess';
import { UtMatrixDetailList } from 'models/ut/UtMatrixList';
import { SuccessOrNot } from 'models/common/RestApi';
import StandardEquipmentPopup from '../popup/StandardEquipmentPopup';
import StandardLibraryPopup from '../popup/StandardLibraryPopup';
import UtMatrixRegistHistoryPoupup from '../popup/UtMatrixRegistHistoryPoupup';
import { UtMatrixLibrary } from 'models/ut/UtMatrixLibrary';
import UtMatrixHookupTagPopup from '../popup/UtMatrixHookupTagPopup';
import { CommonUtil } from 'utils/CommonUtil';

const UtMatrixContent = (props: any, ref) => {
  const {
    onSubmit,
    condition = {} as UtMatrixRegistSearchCondition,
    processData = [] as UtMatrixDetail[],
  } = props;
  const gridRef = useRef<any>();
  const { t } = useTranslation();
  const { openLoading } = useLoading();
  const { openMessageBar } = useMessageBar();
  const [rowData, setRowData] = useState<UtMatrixDetail[]>([]);
  const [code, setCode] = useState<any>();

  const [isUtMatrixRegistHistoryModalOpen, setUtMatrixRegistHistoryModalOpen] =
    useState<boolean>(false);
  const [utMatrixRegistHistoryCondition, setUtMatrixRegistHistoryCondition] = useState<any>();
  const [isStandardEquipmentModalOpen, setStandardEquipmentModalOpen] = useState<boolean>(false);
  const [standardEquipmentCondition, setStandardEquipmentCondition] = useState<any>({
    singleSelect: true,
  });
  const [isStandardLibraryModalOpen, setStandardLibraryModalOpen] = useState<boolean>(false);
  const [standardLibraryCondition, setStandardLibraryCondition] = useState<any>();
  const [isUtMatrixDetailProcessModalOpen, setUtMatrixDetailProcessModalOpen] =
    useState<boolean>(false);
  const [isExcelValidModalOpen, setExcelValidModalOpen] = useState<boolean>(false);
  const [excelValidCondition, setExcelValidCondition] = useState<string | null>(null);
  const [isOpenHookupTagPopup, setOpenHookupTagPopup] = useState<boolean>(false);
  const [hookupTagCondition, setHookupTagCondition] = useState<any>();
  const isUtWriter = useMemo(() => {
    const p = processData.filter(
      (o) => o.prdnProcCd === condition?.prdnProcCd && o.eqclId === condition?.eqclId
    );
    return (p.length > 0 && p[0].utmWriteYn === 'Y') || hasRole('ADM');
  }, [processData, condition?.prdnProcCd, condition?.eqclId]);
  const isWrite = useMemo(() => {
    const p = processData.filter(
      (o) => o.prdnProcCd === condition?.prdnProcCd && o.eqclId === condition?.eqclId
    );
    return p.length > 0 && p[0].utmWrtProcProgStatCd === 'UTP02';
  }, [processData, condition?.prdnProcCd, condition?.eqclId]);

  const isWriteComplete = useMemo(() => {
    const p = processData.filter(
      (o) => o.prdnProcCd === condition?.prdnProcCd && o.eqclId === condition?.eqclId
    );
    return p.length > 0 && p[0].utmWrtProcProgStatCd === 'UTP03';
  }, [processData, condition?.prdnProcCd, condition?.eqclId]);

  useImperativeHandle(ref, () => ({
    searchRegistMatrix: (params) => {
      searchRegistMatrix(params);
    },
    saveRegistMatrix: (showMsg = true) => {
      return new Promise((resolve) => {
        handleSave(showMsg).finally(() => {
          resolve(true);
        });
      });
    },
  }));

  useEffect(() => {
    getCommonCodesForGrid();
  }, []);

  useEffect(() => {
    if (condition?.prdnProcCd && condition?.eqclId) {
      findProcessCode(condition?.prdnProcCd, condition?.eqclId).then((result) =>
        setCode((prev) => ({ ...prev, dtalProcCd: result || [] }))
      );
    }
  }, [condition?.prdnProcCd, condition?.eqclId]);

  useEffect(() => {
    if (condition?.bildPlntCd) {
      findVoltageCode(condition?.bildPlntCd).then((result) =>
        setCode((prev) => ({ ...prev, utVltgList: result || [] }))
      );
    }
  }, [condition?.bildPlntCd]);

  const getCommonCodesForGrid = async () => {
    const areaCd: Code[] = await getCommonCodeNames('AREA_CD');
    const bldgFloCd: Code[] = await getCommonCodeNames('BLDG_FLO_CD');
    const elpwPhasCd: Code[] = await getCommonCodeNames('ELPW_PHAS_CD');
    const ctwTpCd: Code[] = await getCommonCodeNames('CTW_TP_CD');
    const utFrqList: Code[] = await getCommonCodeNames('UT_FRQ_LIST');
    const elpwEqpCnctTpCd: Code[] = await getCommonCodeNames('ELPW_EQP_CNCT_TP_CD');
    const devcCnctTpCd: Code[] = await getCommonCodeNames('DEVC_CNCT_TP_CD');
    const exhaCllrTpCd: Code[] = await getCommonCodeNames('EXHA_CLLR_TP_CD');
    const utBrkeCapaList: Code[] = await getCommonCodeNames('UT_BRKE_CAPA_LIST');
    const bildPlntCd: Code[] = await getCommonCodeNames('BILD_PLNT_CD');
    const insTpCd: Code[] = await getCommonCodeNames('INS_TP_CD');
    // const suarCllrTpCd: Code[] = await getCommonCodeNames('SUAR_CLLR_TP_CD');

    setCode((prev) => ({
      ...(prev || {}),
      areaCd: areaCd,
      bldgFloCd: bldgFloCd,
      elpwPhasCd: elpwPhasCd,
      ctwTpCd: ctwTpCd,
      utFrqList: utFrqList,
      elpwEqpCnctTpCd: elpwEqpCnctTpCd,
      devcCnctTpCd: devcCnctTpCd,
      exhaCllrTpCd: exhaCllrTpCd,
      utBrkeCapaList: utBrkeCapaList,
      bildPlntCd: bildPlntCd,
      // suarCllrTpCd: suarCllrTpCd,
      insTpCd: insTpCd,
    }));
  };

  const searchRegistMatrix = (params) => {
    findUtMatrix('write', params).then((result) => {
      const p = (processData || []).filter(
        (o) => o.prdnProcCd === params?.prdnProcCd && o.eqclId === params?.eqclId
      );
      let isWt = false,
        isUtWt = false;
      if (p.length > 0) {
        isWt = p[0].utmWrtProcProgStatCd === 'UTP02';
        isUtWt = p[0].utmWriteYn === 'Y' || hasRole('ADM');
      }
      let rows = result || [];
      // 편집가능한 상태면서 데이터가 없는 경우 행추가
      if (rows.length < 1 && isWt && isUtWt) {
        const newRow = {
          crudKey: CrudCode.CREATE,
          utmId: params.utmId,
          planProcId: params.planProcId,
          prdnProcCd: params.prdnProcCd,
          prdnProcCdNm: params.prdnProcCdNm,
          eqclId: params.eqclId,
          eqclNm: params.eqclNm,
          exlUpldUseYn: 'N',
          plcClctYn: 'N',
          insTpCd: getInsTpCd(null, null),
        } as UtMatrixDetail;
        rows = [newRow];
      }
      setRowData(rows);
    });
  };

  const layoutDefinition = useMemo(() => {
    // 공장(국가) 기준 Voltage 목록
    const mapUtVltgList = new DataMap(code?.utVltgList || [], 'cmnCd', 'cmnCdNm');
    mapUtVltgList.getDisplayValues = (item) => {
      return (code?.utVltgList || [])
        .reduce((acc, cur) => {
          if (acc.findIndex((o) => o.cmnCd === cur.cmnCd) < 0) {
            acc.push(cur);
          }
          return acc;
        }, [])
        .filter((o) => !!o.cmnCdNm)
        .map((o) => o.cmnCdNm || '');
    };

    const mapPhaseList = new DataMap(code?.elpwPhasCd || [], 'cmnCd', 'cmnCdNm');
    mapPhaseList.getDisplayValues = (item) => {
      // 선택한 Voltage 값에 따라 Phase 콤보박스 필터링
      const targets = (code?.utVltgList || [])
        .filter((o) => o.cmnCd === item.vltgNvl)
        .map((o) => o.optValCtn3 || '');
      return (code?.elpwPhasCd || [])
        .filter((o) => !!o.cmnCdNm && (targets || []).includes(o.cmnCd))
        .reduce((acc, cur) => {
          if (acc.findIndex((o) => o.cmnCd === cur.cmnCd) < 0) {
            acc.push(cur);
          }
          return acc;
        }, [])
        .map((o) => o.cmnCdNm || '');
    };

    const mapWireList = new DataMap(code?.ctwTpCd || [], 'cmnCd', 'cmnCdNm');
    mapWireList.getDisplayValues = (item) => {
      const targets = (code?.utVltgList || [])
        .filter((o) => o.cmnCd === item.vltgNvl && o.optValCtn3 === item.elpwPhasCd)
        .map((o) => o.optValCtn4 || '');
      return (code?.ctwTpCd || [])
        .filter((o) => !!o.cmnCdNm && (targets || []).includes(o.cmnCd))
        .reduce((acc, cur) => {
          if (acc.findIndex((o) => o.cmnCd === cur.cmnCd) < 0) {
            acc.push(cur);
          }
          return acc;
        }, [])
        .map((o) => o.cmnCdNm || '');
    };

    return [
      {
        binding: 'utmSeq',
        header: String(t('com.label.NO', 'NO')),
        width: 40,
        isReadOnly: true,
        align: 'center',
        // cellTemplate: (grid) => grid.row._idx + 1,
      },
      {
        header: String(t('com.label.상태', '상태')),
        binding: 'crudKey',
        width: 40,
        isReadOnly: true,
        align: 'center',
        cellTemplate: GridStatusCellTemplate,
      },
      {
        binding: 'prdnProcCdNm',
        header: String(t('ut.label.공정', '공정')),
        width: 100,
        isReadOnly: true,
        align: 'center',
      },
      {
        binding: 'bizAreaNm',
        header: String(t('ut.label.AREA', 'AREA')),
        width: 100,
        isRequired: false,
        cssClass: 'WijmoSelect',
        dataMap: new DataMap(code?.areaCd || [], 'cmnCd', 'cmnCdNm'),
      },
      {
        binding: 'eqclNm',
        header: String(t('ut.label.설비군', '설비군')),
        width: 100,
        isReadOnly: true,
        visible: false,
      },
      {
        binding: 'stndEqpId',
        header: String(t('ut.label.표준설비코드', '표준설비코드')),
        width: 140,
        isReadOnly: true,
        cssClass: 'WijmoFind',
        cellTemplate: (params) => `
            <span>${params.value || ''}</span> 
            ${!isWrite || !isUtWriter ? '' : '<Button id="stndEqpId" />'} 
        `,
      },
      {
        binding: 'dtalProcCd',
        header: String(t('ut.label.세부공정', '세부공정')),
        width: 110,
        isRequired: false,
        visible: false,
        cssClass: 'WijmoSelect',
        dataMap: new DataMap(code?.dtalProcCd || [], 'cmnCd', 'cmnCdNm'),
      },
      {
        header: String(t('ut.label.Facility Location', 'Facility Location')),
        collapseTo: 'bldgFloCd',
        isCollapsed: true,
        columns: [
          {
            binding: 'bldgFloCd',
            header: String(t('ut.label.Floor', 'Floor')),
            width: 120,
            isRequired: false,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.bldgFloCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'istlLocNm',
            header: String(t('ut.label.Room', 'Room')),
            width: 100,
            isRequired: false,
          },
        ],
      },
      {
        binding: 'prdnLnNm',
        header: String(t('ut.label.Line', 'Line')),
        width: 100,
        isRequired: false,
      },
      {
        binding: 'prdnPrlLnNm',
        header: String(t('ut.label.Sub Line', 'Sub Line')),
        width: 120,
        isRequired: false,
      },
      {
        binding: 'eqpMainNm',
        header: String(t('ut.label.Main', 'Main')),
        width: 100,
        isRequired: false,
      },
      {
        binding: 'eqpMchNm',
        header: String(t('ut.label.Machine', 'Machine')),
        width: 120,
        isRequired: false,
      },
      {
        binding: 'eqpUntNm',
        header: String(t('ut.label.Unit', 'Unit')),
        width: 100,
        isRequired: false,
      },
      {
        header: String(t('ut.label.Hookup Tagging', 'Hookup Tagging')),
        collapseTo: 'machEqpNm',
        isCollapsed: condition?.tagIssuYn !== 'Y',
        columns: [
          {
            // 미입력 (검토/결과 보기 화면에서는 Hookup ID 생성 자동 표시)
            binding: 'machEqpNm',
            header: String(t('ut.label.Mech', 'Mech')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoFind',
            cellTemplate: (params) => `
                <span>${params.value || ''}</span> 
                ${params.value && params.item.tagIssuYn === 'Y' ? '<Button id="machEqpNm"/>' : ''} 
            `,
          },
          {
            // 미입력 (검토/결과 보기 화면에서는 Hookup ID 생성 자동 표시)
            binding: 'elpwEqpNm',
            header: String(t('ut.label.Elec', 'Elec')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoFind',
            cellTemplate: (params) => `
                <span>${params.value || ''}</span>
                ${params.value && params.item.tagIssuYn === 'Y' ? '<Button id="elpwEqpNm" />' : ''}
            `,
          },
        ],
      },
      {
        header: String(t('ut.label.설비 Qty', '설비 Qty')),
        columns: [
          {
            binding: 'machEqpQty',
            header: String(t('ut.label.Mech', 'Mech')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'elpwEqpQty',
            header: String(t('ut.label.Elec', 'Elec')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
        ],
      },
      {
        header: String(t('ut.label.Library', 'Library')),
        columns: [
          {
            binding: 'utmLibId',
            header: String(t('ut.label.ID', 'ID')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoFind',
            cellTemplate: (params) => `
                <span>${params.value || ''}</span> 
                ${!isWrite || !isUtWriter ? '' : '<Button id="utmLibId" />'} 
            `,
          },
          {
            binding: 'utmLibVerNo',
            header: String(t('ut.label.Version', 'Version')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoFind',
            cellTemplate: (params) => `
                <span>${params.value || ''}</span> 
                ${!isWrite || !isUtWriter ? '' : '<Button id="utmLibVerNo" />'} 
            `,
          },
        ],
      },
      {
        // 시스템 자동 (표준설비코드 Library 선택에 따라 자동으로 설정)
        // 엑셀에서 업로드한 경우는 작성구분을 사용자가 입력한 값을 지정하고 작성구분에 따라 제출 시 표준설비코드, LibraryID의 선택여부를 interlock 한다.
        binding: 'insTpCd',
        header: String(t('ut.label.작성구분', '작성구분')),
        width: 100,
        align: 'center',
        isReadOnly: true,
        cssClass: 'WijmoSelect',
        dataMap: new DataMap(code?.insTpCd || [], 'cmnCd', 'cmnCdNm'),
      },
      {
        header: String(t('ut.label.Electricity', 'Electricity')),
        collapseTo: 'vltgNvl',
        columns: [
          /*
          // [24.11.19] 미사용 삭제
          {
            binding: 'elpwEqpGrNm',
            header: String(t('ut.label.Group 명', 'Group 명')),
            width: 100,
          },
          */
          {
            header: String(t('ut.label.Voltage', 'Voltage')),
            columns: [
              {
                // 표준라이브러리 선택 시 자동입력 or 사용자 선택
                binding: 'vltgNvl', // 전기필수 (ELTR_EQP_QTY 값이 1 이상일때 필수)
                header: '[V]',
                // header: String(t('ut.label.Voltage[V]', 'Voltage[V]')),
                width: 75,
                isRequired: false,
                cssClass: 'WijmoSelect',
                dataMap: mapUtVltgList,
              },
            ],
          },
          {
            header: String(t('ut.label.Phase', 'Phase')),
            columns: [
              {
                // 표준라이브러리 선택 시 자동입력 or 사용자 선택
                binding: 'elpwPhasCd', // 전기필수
                // header: String(t('ut.label.Phase[Φ]', 'Phase')),
                header: '[Φ]',
                width: 75,
                isRequired: false,
                cssClass: 'WijmoSelect',
                dataMap: mapPhaseList,
              },
            ],
          },
          {
            header: String(t('ut.label.Wires', 'Wires')),
            columns: [
              {
                // 표준라이브러리 선택 시 자동입력 or 사용자 선택
                binding: 'ctwTpCd',
                // header: String(t('ut.label.Wires[EA]', 'Wires')),
                header: '[EA]',
                width: 80,
                isRequired: false,
                cssClass: 'WijmoSelect',
                dataMap: mapWireList,
              },
            ],
          },
          {
            // UT Matrix (UT Matrix No)별로 동일해야 함
            binding: 'frqNvl', // 전기필수
            header: String(t('ut.label.Frequency[Hz]', 'Frequency[Hz]')),
            width: 100,
            isReadOnly: true,
            isRequired: false,
            visible: false,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.utFrqList || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            // [25.03.10] 0.95로 Default 입력
            binding: 'pwftNvl', // 전기필수
            header: String(t('ut.label.Power Factor-', 'Power Factor-')),
            width: 100,
            align: 'right',
            dataType: DataType.String,
            visible: false,
          },
          {
            header: String(t('ut.label.Current', 'Current')),
            columns: [
              {
                binding: 'od2EcNvl',
                // header: String(t('ut.label.Current(입력값)[A]', 'Current(입력값)[A]')),
                header: String(t('ut.label.입력값 [A]', '입력값 [A]')),
                width: 97,
                isRequired: false,
                dataType: DataType.Number,
              },
              {
                // [Current(계산값)] = "Phase ="3P“  이면   ＂Capacity[kW]＂*1000/SQRT(3)/＂Voltage[V]＂/＂Power factor＂
                //                                아니면  , "Capacity[kW]"*1000/"Voltage[V]"/"Power factor"
                binding: 'od1EcNvl', // 수식 시스템 계산
                // header: String(t('ut.label.Current(계산값)[A]', 'Current(계산값)[A]')),
                header: String(t('ut.label.계산값 [A]', '계산값 [A]')),
                width: 97,
                align: 'right',
                isReadOnly: true,
                cellTemplate: (params) =>
                  Number(params.item.elpwEqpQty || 0) < 1
                    ? ''
                    : params.item.od1EcNvl
                    ? `${params.item.od1EcNvl}`
                    : calculateOd1EcNvl(
                        params.item.elpwEqpQty,
                        params.item.elpwPhasCd,
                        params.item.elpwCapa,
                        params.item.vltgNvl,
                        params.item.pwftNvl
                      ),
              },
            ],
          },
          {
            header: String(t('ut.label.동시가동율', '동시가동율')),
            columns: [
              {
                binding: 'smtmOprt',
                // header: String(t('ut.label.동시가동율[%]', '동시가동율[%]')),
                header: '[%]',
                width: 75,
                isRequired: false,
                dataType: DataType.Number,
              },
            ],
          },
          {
            header: String(t('ut.label.Breaker', 'Breaker')),
            columns: [
              {
                /**
                 * 동시가동율 추가에 따른 MCCB 값 자동입력 요건 변경 (*변동가능)
                 * (as-is) Max(계산값, 입력값) x 1.25배 보다 큰 가장 근접한 값
                 * (to-be) Max(계산값, 입력값) x 동시가동율 보다 큰 가장 근접한 값
                 *
                 * 조건부 계산
                 * 1. 사용자 입력일때 입력 값 사용
                 * 2. 입력이 아닐때 자동 계산
                 *
                 * Current (계산값)[A] 과 Current(입력값)[A]  두 값 중 더 큰값을 기준으로 1.25배한 값보다 큰 가장 근접한 값 ( 대상 List : UT_BRKE_CAPA_LIST)을 자동 입력
                 * 참고 :
                 * pkg_elm_xls_upload.upload_ut_matrix_list의 tb_eelmb_utm_d.brke_capa  계산로직 참고
                 */
                binding: 'brkeCapa',
                // header: String(t('ut.label.Breaker(입력값)', 'Breaker(입력값)')),
                header: String(t('ut.label.입력값 [A]', '입력값 [A]')),
                width: 97,
                isRequired: false,
                align: 'right',
                cellTemplate: (params) =>
                  Number(params.item.elpwEqpQty || 0) < 1
                    ? ''
                    : params.item.brkeCapa
                    ? `${params.item.brkeCapa}`
                    : calculateBrkeCapa(
                        code?.utBrkeCapaList || [],
                        params.item.od1EcNvl
                          ? params.item.od1EcNvl
                          : calculateOd1EcNvl(
                              params.item.elpwEqpQty,
                              params.item.elpwPhasCd,
                              params.item.elpwCapa,
                              params.item.vltgNvl,
                              params.item.pwftNvl
                            ),
                        params.item.od2EcNvl,
                        params.item.smtmOprt
                      ),
              },
              {
                binding: 'optValCtn1', // TODO Breaker 계산값 (저장은 패키지에서 처리하는지 확인필요)
                // header: String(t('ut.label.Breaker(계산값)', 'Breaker(계산값)')),
                header: String(t('ut.label.계산값 [A]', '계산값 [A]')),
                width: 97,
                isReadOnly: true,
                isRequired: false,
                align: 'right',
                cellTemplate: (params) =>
                  Number(params.item.elpwEqpQty || 0) < 1
                    ? ''
                    : calculateBrkeCapa(
                        code?.utBrkeCapaList || [],
                        params.item.od1EcNvl
                          ? params.item.od1EcNvl
                          : calculateOd1EcNvl(
                              params.item.elpwEqpQty,
                              params.item.elpwPhasCd,
                              params.item.elpwCapa,
                              params.item.vltgNvl,
                              params.item.pwftNvl
                            ),
                        params.item.od2EcNvl,
                        params.item.smtmOprt
                      ),
              },
            ],
          },
          {
            binding: 'plcClctYn', // 전기필수
            header: String(t('ut.label.PLC접지유무-', 'PLC접지유무-')),
            width: 100,
            visible: false,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(commonYNcodes, 'cmnCd', 'cmnCdNm'),
          },
          {
            header: String(t('ut.label.Connection', 'Connection')),
            columns: [
              {
                // Voltage[V] = 120 이면 “OUTLET” 자동 아니면 사용자 선택
                binding: 'elpwEqpCnctTpCd', // 전기필수
                // header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
                header: '[Type]',
                width: 80,
                isRequired: false,
                cssClass: 'WijmoSelect',
                dataMap: new DataMap(code?.elpwEqpCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
              },
            ],
          },
          {
            header: String(t('ut.label.Capacity', 'Capacity')),
            columns: [
              {
                // 표준라이브러리 선택 시 자동입력 or 사용자 입력
                binding: 'elpwCapa', // 전기필수
                // header: String(t('ut.label.Capacity[kW]', 'Capacity[kW]')),
                header: '[kW]',
                width: 83,
                isRequired: false,
                dataType: DataType.Number,
              },
              {
                binding: 'elpwSumCapa', // 수식계산 (= 설비 Qty Elec * Capacity[kW])
                // header: String(t('ut.label.Capacity Sum', 'Capacity Sum')),
                header: 'Sum',
                width: 83,
                isReadOnly: true,
                align: 'right',
                cellTemplate: (params) =>
                  calculateElpwSumCapa(params.item?.elpwEqpQty, params.item?.elpwCapa),
              },
            ],
          },
          {
            header: String(t('ut.label.표준사용량', '표준사용량')),
            columns: [
              {
                binding: 'elpwStndUseQty',
                // header: String(t('ut.label.표준사용량', '표준사용량')),
                header: '[kW]',
                width: 82,
                isRequired: false,
                dataType: DataType.Number,
              },
            ],
          },
          {
            header: String(t('ut.label.Demand Factor', 'Demand Factor')),
            columns: [
              {
                binding: 'dmdElpwTarfNvl', // 검토화면에서 UT Manager 권한인 경우 입력
                // header: String(t('ut.label.Demand Factor[%]', 'Demand Factor[%]')),
                header: '[%]',
                width: 96,
                dataType: DataType.Number,
                isReadOnly: true,
              },
            ],
          },
          {
            header: String(t('ut.label.Capacity_Sum', 'Capacity_Sum')),
            columns: [
              {
                binding: 'dmdElpwCapa', // 검토화면에서 자동계산 (= Capacity Sum * Demand Factor[%])
                // header: String(
                //   t('ut.label.Capacity_Sum(수용율 적용)[kW]', 'Capacity_Sum(수용율 적용)[kW]')
                // ),
                header: String(t('ut.label.(수용율 적용) [kW]', '(수용율 적용) [kW]')),
                width: 160,
                isReadOnly: true,
              },
            ],
          },
          {
            header: String(t('ut.label.Heat Capacity', 'Heat Capacity')),
            columns: [
              {
                binding: 'elpwHeatCapa', // 전기필수
                // header: String(t('ut.label.Heat Capacity[kW]', 'Heat Capacity[kW]')),
                header: '[kW]',
                width: 88,
                isRequired: false,
                dataType: DataType.Number,
              },
            ],
          },
          // 개선요청사항(김중열) 57번 - 13. Static Condenser Capa. 해당 열 삭제
          // {
          //   binding: 'cdsCapa', // 검토화면에서 UT Manager 권한인 경우 입력
          //   header: String(
          //     t(
          //       'ut.label.Static Condenser Capa. (p.f. = 0.92)[KVAR]',
          //       'Static Condenser Capa. (p.f. = 0.92)[KVAR]'
          //     )
          //   ),
          //   width: 100,
          //   dataType: DataType.Number,
          //   isReadOnly: true,
          // },
        ],
      },
      {
        header: String(t('ut.label.CDA', 'CDA')),
        collapseTo: 'cdaOtsdSizeValCtn',
        columns: [
          {
            header: String(t('ut.label.Size', 'Size')),
            columns: [
              {
                binding: 'cdaOtsdSizeValCtn',
                // header: String(t('ut.label.Size O.D[Φ]', 'Size O.D[Φ]')),
                header: 'O.D[Φ]',
                width: 75,
                isRequired: false,
              },
              {
                binding: 'cdaInsdSizeValCtn',
                // header: String(t('ut.label.Size I.D[Φ]', 'Size I.D[Φ]')),
                header: 'I.D[Φ]',
                width: 75,
                isRequired: false,
              },
            ],
          },
          {
            header: String(t('ut.label.Connection', 'Connection')),
            columns: [
              {
                binding: 'cdaDevcCnctTpCd',
                // header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
                header: '[Type]',
                width: 90,
                isRequired: false,
                cssClass: 'WijmoSelect',
                dataMap: new DataMap(code?.devcCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
              },
            ],
          },
          {
            header: String(t('ut.label.Pressure', 'Pressure')),
            columns: [
              {
                binding: 'cdaPresValCtn', // [24.11.11] 사용자선택 -> 사용자입력 변경 (데이터 검증 제외)
                // header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
                header: '[Bar]',
                width: 80,
                isRequired: false,
              },
            ],
          },
          {
            header: String(t("ut.label.Q'ty", "Q'ty")),
            columns: [
              {
                binding: 'cdaPntCnt',
                // header: String(t("ut.label.Q'ty[Point]", "Q'ty[Point]")),
                header: '[Point]',
                width: 92,
                isRequired: false,
                dataType: DataType.Number,
              },
            ],
          },
          {
            header: String(t('ut.label.Consumption', 'Consumption')),
            columns: [
              {
                binding: 'cdaCspCapa', // 기계 조건부 필수 대상값
                // header: String(t('ut.label.Consumption[ℓ/min]', 'Consumption[ℓ/min]')),
                header: '[ℓ/min]',
                width: 95,
                isRequired: false,
                dataType: DataType.Number,
              },
              {
                binding: 'cdaCspSumCapa', // 수식계산 (= Qty * CDA Consumption[ℓ/min])
                // header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
                header: 'Sum',
                width: 95,
                isReadOnly: true,
                align: 'right',
                cellTemplate: (params) =>
                  calculateCspSumCapa(params.item?.cdaPntCnt, params.item?.cdaCspCapa),
              },
            ],
          },
          {
            header: String(t('ut.label.표준사용량', '표준사용량')),
            columns: [
              {
                binding: 'cdaStndUseQty',
                // header: String(t('ut.label.표준사용량', '표준사용량')),
                header: '[ℓ/min]',
                width: 95,
                isRequired: false,
                dataType: DataType.Number,
              },
            ],
          },
        ],
      },
      {
        header: String(t('ut.label.N2', 'N2')),
        collapseTo: 'nitrOtsdDmtrValCtn',
        columns: [
          {
            header: 'Size',
            columns: [
              {
                binding: 'nitrOtsdDmtrValCtn',
                // header: String(t('ut.label.Size O.D[Φ]', 'Size O.D[Φ]')),
                header: 'O.D[Φ]',
                width: 75,
                isRequired: false,
              },
              {
                binding: 'nitrInsdDmtrValCtn',
                // header: String(t('ut.label.Size I.D[Φ]', 'Size I.D[Φ]')),
                header: 'I.D[Φ]',
                width: 75,
                isRequired: false,
              },
            ],
          },
          {
            header: String(t('ut.label.Connection', 'Connection')),
            columns: [
              {
                binding: 'nitrDevcCnctTpCd',
                // header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
                header: '[Type]',
                width: 90,
                isRequired: false,
                cssClass: 'WijmoSelect',
                dataMap: new DataMap(code?.devcCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
              },
            ],
          },
          {
            header: String(t('ut.label.Pressure', 'Pressure')),
            columns: [
              {
                binding: 'nitrPresValCtn', // [24.11.11] 사용자선택 -> 사용자입력 변경 (데이터 검증 제외)
                // header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
                header: '[Bar]',
                width: 80,
                isRequired: false,
              },
            ],
          },
          {
            header: String(t("ut.label.Q'ty", "Q'ty")),
            columns: [
              {
                binding: 'nitrPntCnt',
                // header: String(t("ut.label.Q'ty[Point]", "Q'ty[Point]")),
                header: '[Point]',
                width: 92,
                isRequired: false,
                dataType: DataType.Number,
              },
            ],
          },
          {
            header: String(t('ut.label.AVG Consumption', 'AVG Consumption')),
            columns: [
              {
                binding: 'nitrCspCapa',
                // header: String(t('ut.label.AVG Consumption[ℓ/min]', 'AVG Consumption[ℓ/min]')),
                header: '[ℓ/min]',
                width: 95,
                isRequired: false,
                dataType: DataType.Number,
              },
              {
                binding: 'nitrCspSumCapa',
                // header: String(t('ut.label.AVG Consumption Sum', 'AVG Consumption Sum')),
                header: 'Sum',
                width: 95,
                isRequired: false,
              },
            ],
          },
          {
            header: String(t('ut.label.Peak Consumption', 'Peak Consumption')),
            columns: [
              {
                binding: 'nitrPeakCspCapa', // 기계 조건부 필수 대상값
                // header: String(t('ut.label.Peak Consumption[ℓ/min]', 'Peak Consumption[ℓ/min]')),
                header: '[ℓ/min]',
                width: 95,
                isRequired: false,
                dataType: DataType.Number,
              },
              {
                binding: 'nitrPeakCspSumCapa', // 수식 (= Qty * N2 Consumption[ℓ/min])
                // header: String(t('ut.label.Peak Consumption Sum', 'Peak Consumption Sum')),
                header: 'Sum',
                width: 95,
                isReadOnly: true,
                align: 'right',
                cellTemplate: (params) =>
                  calculateCspSumCapa(params.item.nitrPntCnt, params.item.nitrPeakCspCapa),
              },
            ],
          },
          {
            header: String(t('ut.label.표준사용량', '표준사용량')),
            columns: [
              {
                binding: 'nitrStndUseQty',
                // header: String(t('ut.label.표준사용량', '표준사용량')),
                header: '[ℓ/min]',
                width: 95,
                isRequired: false,
                dataType: DataType.Number,
              },
            ],
          },
        ],
      },
      {
        header: String(t('ut.label.IW', 'IW')),
        collapseTo: 'iwOtsdDmtrValCtn',
        columns: [
          {
            header: String(t('ut.label.Size', 'Size')),
            columns: [
              {
                binding: 'iwOtsdDmtrValCtn',
                // header: String(t('ut.label.Size O.D[Φ]', 'Size O.D[Φ]')),
                header: 'O.D[Φ]',
                width: 75,
                isRequired: false,
              },
              {
                binding: 'iwInsdDmtrValCtn',
                // header: String(t('ut.label.Size I.D[Φ]', 'Size I.D[Φ]')),
                header: 'I.D[Φ]',
                width: 75,
                isRequired: false,
              },
            ],
          },
          {
            header: String(t('ut.label.Connection', 'Connection')),
            columns: [
              {
                binding: 'iwDevcCnctTpCd',
                // header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
                header: '[Type]',
                width: 90,
                isRequired: false,
                cssClass: 'WijmoSelect',
                dataMap: new DataMap(code?.devcCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
              },
            ],
          },
          {
            header: String(t('ut.label.Tempurature', 'Tempurature')),
            columns: [
              {
                binding: 'iwTmprValCtn', // [24.11.11] 숫자 -> 문자 변경 (데이터 검증 제외)
                // header: String(t('ut.label.Tempurature[℃]', 'Tempurature[℃]')),
                header: '[℃]',
                width: 85,
                isRequired: false,
              },
            ],
          },
          {
            header: String(t('ut.label.Pressure', 'Pressure')),
            columns: [
              {
                binding: 'iwPresValCtn', // [24.11.11] 숫자 -> 문자 변경 (데이터 검증 제외)
                // header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
                header: '[Bar]',
                width: 80,
                isRequired: false,
              },
            ],
          },
          {
            header: String(t("ut.label.Q'ty", "Q'ty")),
            columns: [
              {
                binding: 'iwPntCnt',
                // header: String(t("ut.label.Q'ty[Point]", "Q'ty[Point]")),
                header: '[Point]',
                width: 92,
                isRequired: false,
                dataType: DataType.Number,
              },
            ],
          },
          {
            header: String(t('ut.label.Drain', 'Drain')),
            columns: [
              {
                binding: 'iwDrnCapa',
                // header: String(t('ut.label.Drain[ℓ/min]', 'Drain[ℓ/min]')),
                header: '[ℓ/min]',
                width: 95,
                isRequired: false,
                dataType: DataType.Number,
              },
              {
                binding: 'iwDrnSumCapa',
                // header: String(t('ut.label.Drain Sum', 'Drain Sum')),
                header: 'Sum',
                width: 95,
                isRequired: false,
                dataType: DataType.Number,
              },
            ],
          },
          {
            header: String(t('ut.label.Consumption', 'Consumption')),
            columns: [
              {
                binding: 'iwCspCapa', // 기계 조건부 필수 대상값
                // header: String(t('ut.label.Consumption[ℓ/min]', 'Consumption[ℓ/min]')),
                header: '[ℓ/min]',
                width: 95,
                isRequired: false,
                dataType: DataType.Number,
              },
              {
                binding: 'iwCspSumCapa', // 수식 (= Qty * IW Consumption[ℓ/min])
                // header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
                header: 'Sum',
                width: 95,
                isReadOnly: true,
                align: 'right',
                cellTemplate: (params) =>
                  calculateCspSumCapa(params.item.iwPntCnt, params.item.iwCspCapa),
              },
            ],
          },
          {
            header: String(t('ut.label.표준사용량', '표준사용량')),
            columns: [
              {
                binding: 'iwStndUseQty',
                // header: String(t('ut.label.표준사용량', '표준사용량')),
                header: '[ℓ/min]',
                width: 95,
                isRequired: false,
                dataType: DataType.Number,
              },
            ],
          },
        ],
      },
      {
        header: String(t('ut.label.IW for Emergency fire', 'FW')),
        collapseTo: 'frwtOtsdDmtrValCtn',
        columns: [
          {
            header: String(t('ut.label.Size', 'Size')),
            columns: [
              {
                binding: 'frwtOtsdDmtrValCtn',
                // header: String(t('ut.label.Size O.D[Φ]', 'Size O.D[Φ]')),
                header: 'O.D[Φ]',
                width: 75,
                isRequired: false,
              },
              {
                binding: 'frwtInsdDmtrValCtn',
                // header: String(t('ut.label.Size I.D[Φ]', 'Size I.D[Φ]')),
                header: 'I.D[Φ]',
                width: 75,
                isRequired: false,
              },
            ],
          },
          {
            header: String(t('ut.label.Connection', 'Connection')),
            columns: [
              {
                binding: 'frwtDevcCnctTpCd',
                // header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
                header: '[Type]',
                width: 90,
                isRequired: false,
                cssClass: 'WijmoSelect',
                dataMap: new DataMap(code?.devcCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
              },
            ],
          },
          {
            header: String(t('ut.label.Tempurature', 'Tempurature')),
            columns: [
              {
                binding: 'frwtTmprValCtn', // [24.11.11] 숫자 -> 문자 변경 (데이터 검증 제외)
                // header: String(t('ut.label.Tempurature[℃]', 'Tempurature[℃]')),
                header: '[℃]',
                width: 83,
                isRequired: false,
              },
            ],
          },
          {
            header: String(t('ut.label.Pressure', 'Pressure')),
            columns: [
              {
                binding: 'frwtPresValCtn', // [24.11.11] 숫자 -> 문자 변경 (데이터 검증 제외)
                // header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
                header: '[Bar]',
                width: 80,
                isRequired: false,
              },
            ],
          },
          {
            header: String(t("ut.label.Q'ty", "Q'ty")),
            columns: [
              {
                binding: 'frwtPntCnt',
                // header: String(t("ut.label.Q'ty[Point]", "Q'ty[Point]")),
                header: '[Point]',
                width: 92,
                isRequired: false,
                dataType: DataType.Number,
              },
            ],
          },
          {
            header: String(t('ut.label.Drain', 'Drain')),
            columns: [
              {
                binding: 'frwtDrnCapa',
                // header: String(t('ut.label.Drain[ℓ/min]', 'Drain[ℓ/min]')),
                header: '[ℓ/min]',
                width: 95,
                isRequired: false,
                dataType: DataType.Number,
              },
              {
                binding: 'frwtDrnSumCapa',
                // header: String(t('ut.label.Drain Sum', 'Drain Sum')),
                header: 'Sum',
                width: 95,
                isRequired: false,
                dataType: DataType.Number,
              },
            ],
          },
          {
            header: String(t('ut.label.Consumption', 'Consumption')),
            columns: [
              {
                binding: 'frwtCspCapa', // 기계 조건부 필수 대상값
                // header: String(t('ut.label.Consumption[ℓ/min]', 'Consumption[ℓ/min]')),
                header: '[ℓ/min]',
                width: 95,
                isRequired: false,
                dataType: DataType.Number,
              },
              {
                binding: 'frwtCspSumCapa', // 수식 (= Qty * IW2 Consumption[ℓ/min])
                // header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
                header: 'Sum',
                width: 95,
                isReadOnly: true,
                align: 'right',
                cellTemplate: (params) =>
                  calculateCspSumCapa(params.item.frwtPntCnt, params.item.frwtCspCapa),
              },
            ],
          },
          {
            header: String(t('ut.label.표준사용량', '표준사용량')),
            columns: [
              {
                binding: 'frwtStndUseQty',
                // header: String(t('ut.label.표준사용량', '표준사용량')),
                header: '[ℓ/min]',
                width: 95,
                isRequired: false,
                dataType: DataType.Number,
              },
            ],
          },
        ],
      },
      {
        header: String(t('ut.label.PCW', 'PCW')),
        collapseTo: 'coolOtsdSizeValCtn',
        columns: [
          {
            header: String(t('ut.label.Size', 'Size')),
            columns: [
              {
                binding: 'coolOtsdSizeValCtn',
                // header: String(t('ut.label.Size O.D[Φ]', 'Size O.D[Φ]')),
                header: 'O.D[Φ]',
                width: 75,
                isRequired: false,
              },
              {
                binding: 'coolInsdSizeValCtn',
                // header: String(t('ut.label.Size I.D[Φ]', 'Size I.D[Φ]')),
                header: 'I.D[Φ]',
                width: 75,
                isRequired: false,
              },
            ],
          },
          {
            header: String(t('ut.label.Connection', 'Connection')),
            columns: [
              {
                binding: 'coolDevcCnctTpCd',
                // header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
                header: '[Type]',
                width: 90,
                isRequired: false,
                cssClass: 'WijmoSelect',
                dataMap: new DataMap(code?.devcCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
              },
            ],
          },
          {
            header: String(t('ut.label.Tempurature', 'Tempurature')),
            columns: [
              {
                binding: 'coolTmprValCtn', // [24.11.11] 숫자 -> 문자 변경 (데이터 검증 제외)
                // header: String(t('ut.label.Tempurature[℃]', 'Tempurature[℃]')),
                header: '[℃]',
                width: 85,
                isRequired: false,
              },
            ],
          },
          {
            header: String(t('ut.label.Pressure', 'Pressure')),
            columns: [
              {
                binding: 'coolPresValCtn', // [24.11.11] 숫자 -> 문자 변경 (데이터 검증 제외)
                // header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
                header: '[Bar]',
                width: 80,
                isRequired: false,
              },
            ],
          },
          {
            header: String(t("ut.label.Q'ty", "Q'ty")),
            columns: [
              {
                binding: 'coolPntCnt',
                // header: String(t("ut.label.Q'ty[Point]", "Q'ty[Point]")),
                header: '[Point]',
                width: 92,
                isRequired: false,
                dataType: DataType.Number,
              },
            ],
          },
          {
            header: String(t('ut.label.Consumption', 'Consumption')),
            columns: [
              {
                binding: 'coolCspCapa', // 기계 조건부 필수 대상값
                // header: String(t('ut.label.Consumption[ℓ/min]', 'Consumption[ℓ/min]')),
                header: '[ℓ/min]',
                width: 95,
                isRequired: false,
                dataType: DataType.Number,
              },
              {
                binding: 'coolCspSumCapa', // 수식 (= Qty * PCW Consumption[ℓ/min])
                // header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
                header: 'Sum',
                width: 95,
                isReadOnly: true,
                align: 'right',
                cellTemplate: (params) =>
                  calculateCspSumCapa(params.item.coolPntCnt, params.item.coolCspCapa),
              },
            ],
          },
          {
            header: String(t('ut.label.표준사용량', '표준사용량')),
            columns: [
              {
                binding: 'coolStndUseQty',
                // header: String(t('ut.label.표준사용량', '표준사용량')),
                header: '[ℓ/min]',
                width: 95,
                isRequired: false,
                dataType: DataType.Number,
              },
            ],
          },
        ],
      },
      {
        header: String(t('ut.label.WW', 'WW')),
        collapseTo: 'wwOtsdDmtrValCtn',
        columns: [
          {
            header: String(t('ut.label.Size', 'Size')),
            columns: [
              {
                binding: 'wwOtsdDmtrValCtn',
                // header: String(t('ut.label.Size O.D[Φ]', 'Size O.D[Φ]')),
                header: 'O.D[Φ]',
                width: 75,
                isRequired: false,
              },
              {
                binding: 'wwInsdDmtrValCtn',
                // header: String(t('ut.label.Size I.D[Φ]', 'Size I.D[Φ]')),
                header: 'I.D[Φ]',
                width: 75,
                isRequired: false,
              },
            ],
          },
          {
            header: String(t('ut.label.Connection', 'Connection')),
            columns: [
              {
                binding: 'wwDevcCnctTpCd',
                // header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
                header: '[Type]',
                width: 90,
                isRequired: false,
                cssClass: 'WijmoSelect',
                dataMap: new DataMap(code?.devcCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
              },
            ],
          },
          {
            header: String(t('ut.label.Tempurature', 'Tempurature')),
            columns: [
              {
                binding: 'wwTmprValCtn', // [24.11.11] 숫자 -> 문자 변경 (데이터 검증 제외)
                // header: String(t('ut.label.Tempurature[℃]', 'Tempurature[℃]')),
                header: '[℃]',
                width: 85,
                isRequired: false,
              },
            ],
          },
          {
            header: String(t('ut.label.Pressure', 'Pressure')),
            columns: [
              {
                binding: 'wwPresValCtn', // [24.11.11] 숫자 -> 문자 변경 (데이터 검증 제외)
                // header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
                header: '[Bar]',
                width: 80,
                isRequired: false,
              },
            ],
          },
          {
            header: String(t("ut.label.Q'ty", "Q'ty")),
            columns: [
              {
                binding: 'wwPntCnt',
                // header: String(t("ut.label.Q'ty[Point]", "Q'ty[Point]")),
                header: '[Point]',
                width: 92,
                isRequired: false,
                dataType: DataType.Number,
              },
            ],
          },
          {
            header: String(t('ut.label.Consumption', 'Consumption')),
            columns: [
              {
                binding: 'wwCspCapa', // 기계 조건부 필수 대상값
                // header: String(t('ut.label.Consumption[ℓ/min]', 'Consumption[ℓ/min]')),
                header: '[ℓ/min]',
                width: 95,
                isRequired: false,
                dataType: DataType.Number,
              },
              {
                binding: 'wwCspSumCapa', // 수식 (= Qty * WW Consumption[ℓ/min])
                // header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
                header: 'Sum',
                width: 95,
                isReadOnly: true,
                align: 'right',
                cellTemplate: (params) =>
                  calculateCspSumCapa(params.item.wwPntCnt, params.item.wwCspCapa),
              },
            ],
          },
          {
            header: String(t('ut.label.표준사용량', '표준사용량')),
            columns: [
              {
                binding: 'wwStndUseQty',
                // header: String(t('ut.label.표준사용량', '표준사용량')),
                header: '[ℓ/min]',
                width: 95,
                isRequired: false,
                dataType: DataType.Number,
              },
            ],
          },
        ],
      },
      {
        header: String(t('ut.label.Steam', 'Steam')),
        collapseTo: 'stemOtsdDmtrValCtn',
        columns: [
          {
            header: String(t('ut.label.Size', 'Size')),
            columns: [
              {
                binding: 'stemOtsdDmtrValCtn',
                // header: String(t('ut.label.Size O.D[Φ]', 'Size O.D[Φ]')),
                header: 'O.D[Φ]',
                width: 75,
                isRequired: false,
              },
              {
                binding: 'stemInsdDmtrValCtn',
                // header: String(t('ut.label.Size I.D[Φ]', 'Size I.D[Φ]')),
                header: 'I.D[Φ]',
                width: 75,
                isRequired: false,
              },
            ],
          },
          {
            header: String(t('ut.label.Connection', 'Connection')),
            columns: [
              {
                binding: 'stemDevcCnctTpCd',
                // header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
                header: '[Type]',
                width: 90,
                isRequired: false,
                cssClass: 'WijmoSelect',
                dataMap: new DataMap(code?.devcCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
              },
            ],
          },
          {
            header: String(t('ut.label.Condensator connection', 'Condensator connection')),
            columns: [
              {
                binding: 'stemCwtSize',
                // header: String(
                //   t('ut.label.Condenstaor connection size[Φ]', 'Condenstaor connection size[Φ]')
                // ),
                header: 'size [Φ]',
                width: 96,
                isRequired: false,
                dataType: DataType.Number,
              },
              {
                binding: 'stemCwtDevcCnctTpCd',
                // header: String(
                //   t('ut.label.Condensator connection[Type]', 'Condensator connection[Type]')
                // ),
                header: '[Type]',
                width: 96,
                isRequired: false,
                cssClass: 'WijmoSelect',
                dataMap: new DataMap(code?.devcCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
              },
            ],
          },
          {
            header: String(t('ut.label.Pressure', 'Pressure')),
            columns: [
              {
                binding: 'stemPresValCtn', // [24.11.11] 숫자 -> 문자 변경 (데이터 검증 제외)
                // header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
                header: '[Bar]',
                width: 80,
                isRequired: false,
              },
            ],
          },
          {
            header: String(t("ut.label.Q'ty", "Q'ty")),
            columns: [
              {
                binding: 'stemPntCnt',
                //header: String(t("ut.label.Q'ty[Point]", "Q'ty[Point]")),
                header: '[Point]',
                width: 100,
                isRequired: false,
                dataType: DataType.Number,
              },
            ],
          },
          {
            header: String(t('ut.label.Consumption', 'Consumption')),
            columns: [
              {
                binding: 'stemCspCapa', // 기계 조건부 필수 대상값
                // header: String(t('ut.label.Consumption[t/hr]', 'Consumption[t/hr]')),
                header: '[t/hr]',
                width: 85,
                isRequired: false,
                dataType: DataType.Number,
              },
              {
                binding: 'stemCspSumCapa', // 수식 (= Qty * Steam Consumption[ℓ/min])
                // header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
                header: 'Sum',
                width: 85,
                isReadOnly: true,
                align: 'right',
                cellTemplate: (params) =>
                  calculateCspSumCapa(params.item.stemPntCnt, params.item.stemCspCapa),
              },
            ],
          },
          {
            header: String(t('ut.label.표준사용량', '표준사용량')),
            columns: [
              {
                binding: 'stemStndUseQty',
                // header: String(t('ut.label.표준사용량', '표준사용량')),
                header: '[t/hr]',
                width: 85,
                isRequired: false,
                dataType: DataType.Number,
              },
            ],
          },
        ],
      },
      {
        header: String(t('ut.label.NG', 'NG')),
        collapseTo: 'lngOtsdDmtrValCtn',
        columns: [
          {
            header: String(t('ut.label.Size', 'Size')),
            columns: [
              {
                binding: 'lngOtsdDmtrValCtn',
                // header: String(t('ut.label.Size O.D[Φ]', 'Size O.D[Φ]')),
                header: 'O.D[Φ]',
                width: 75,
                isRequired: false,
              },
              {
                binding: 'lngInsdDmtrValCtn',
                // header: String(t('ut.label.Size I.D[Φ]', 'Size I.D[Φ]')),
                header: 'I.D[Φ]',
                width: 75,
                isRequired: false,
              },
            ],
          },
          {
            header: String(t('ut.label.Connection', 'Connection')),
            columns: [
              {
                binding: 'lngDevcCnctTpCd',
                // header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
                header: '[Type]',
                width: 90,
                isRequired: false,
                cssClass: 'WijmoSelect',
                dataMap: new DataMap(code?.devcCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
              },
            ],
          },
          {
            header: String(t('ut.label.Pressure', 'Pressure')),
            columns: [
              {
                binding: 'lngPresValCtn', // [24.11.11] 숫자 -> 문자 변경 (데이터 검증 제외)
                // header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
                header: '[Bar]',
                width: 80,
                isRequired: false,
              },
            ],
          },
          {
            header: String(t("ut.label.Q'ty", "Q'ty")),
            columns: [
              {
                binding: 'lngPntCnt',
                // header: String(t("ut.label.Q'ty[Point]", "Q'ty[Point]")),
                header: '[Point]',
                width: 92,
                isRequired: false,
              },
            ],
          },
          {
            header: String(t('ut.label.Consumption', 'Consumption')),
            columns: [
              {
                binding: 'lngCspCapa', // 기계 조건부 필수 대상값
                // header: String(t('ut.label.Consumption[ℓ/min]', 'Consumption[ℓ/min]')),
                header: '[Nm3/hr]',
                width: 110,
                isRequired: false,
                dataType: DataType.Number,
              },
              {
                binding: 'lngCspSumCapa', // 수식 (= Qty * NG Consumption[ℓ/min])
                // header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
                header: 'Sum',
                width: 90,
                isReadOnly: true,
                align: 'right',
                cellTemplate: (params) =>
                  calculateCspSumCapa(params.item.lngPntCnt, params.item.lngCspCapa),
              },
            ],
          },
          {
            header: String(t('ut.label.표준사용량', '표준사용량')),
            columns: [
              {
                binding: 'lngStndUseQty',
                // header: String(t('ut.label.표준사용량', '표준사용량')),
                header: '[Nm3/hr]',
                width: 108,
                isRequired: false,
                dataType: DataType.Number,
              },
            ],
          },
        ],
      },
      {
        header: String(t('ut.label.Exhaust air [Return(to Air Handling Unit)]', 'Return Air')),
        collapseTo: 'exhaOtsdDmtrValCtn',
        columns: [
          {
            header: String(t('ut.label.Size', 'Size')),
            columns: [
              {
                binding: 'exhaOtsdDmtrValCtn',
                // header: String(t('ut.label.Size O.D[Φ]', 'Size O.D[Φ]')),
                header: 'O.D[Φ]',
                width: 75,
                isRequired: false,
              },
              {
                binding: 'insdExhaInsdDmtrValCtn',
                // header: String(t('ut.label.Size I.D[Φ]', 'Size I.D[Φ]')),
                header: 'I.D[Φ]',
                width: 75,
                isRequired: false,
              },
            ],
          },
          {
            header: String(t('ut.label.Connection', 'Connection')),
            columns: [
              {
                binding: 'insdExhaCnctTpCd',
                // header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
                header: '[Type]',
                width: 85,
                isRequired: false,
                cssClass: 'WijmoSelect',
                dataMap: new DataMap(code?.devcCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
              },
            ],
          },
          {
            header: String(t('ut.label.Collector Type', 'Collector Type')),
            columns: [
              {
                binding: 'insdExhaCllrTpCd',
                // header: String(t('ut.label.Collector Type[Type]', 'Collector Type[Type]')),
                header: '[Type]',
                width: 93,
                isRequired: false,
                cssClass: 'WijmoSelect',
                dataMap: new DataMap(code?.exhaCllrTpCd || [], 'cmnCd', 'cmnCdNm'),
              },
            ],
          },
          {
            header: String(t('ut.label.Tempurature', 'Tempurature')),
            columns: [
              {
                binding: 'insdExhaTmprValCtn', // [24.11.11] 숫자 -> 문자 변경 (데이터 검증 제외)
                // header: String(t('ut.label.Tempurature[℃]', 'Tempurature[℃]')),
                header: '[℃]',
                width: 80,
                isRequired: false,
              },
            ],
          },
          {
            header: String(t("ut.label.Q'ty", "Q'ty")),
            columns: [
              {
                binding: 'insdExhaPntCnt',
                // header: String(t("ut.label.Q'ty[Point]", "Q'ty[Point]")),
                header: '[Point]',
                width: 92,
                isRequired: false,
                dataType: DataType.Number,
              },
            ],
          },
          {
            header: String(t('ut.label.Consumption', 'Consumption')),
            columns: [
              {
                binding: 'insdExhaCspCapa', // 기계 조건부 필수 대상값
                // header: String(t('ut.label.Consumption[CHM]', 'Consumption[CHM]')),
                header: '[CHM]',
                width: 95,
                isRequired: false,
                dataType: DataType.Number,
              },
              {
                binding: 'insdExhaCspSumCapa', // 수식 (= Qty * Exhaust air Return Consumption[ℓ/min])
                // header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
                header: 'Sum',
                width: 95,
                isReadOnly: true,
                align: 'right',
                cellTemplate: (params) =>
                  calculateCspSumCapa(params.item.insdExhaPntCnt, params.item.insdExhaCspCapa),
              },
            ],
          },
          {
            header: String(t('ut.label.표준사용량', '표준사용량')),
            columns: [
              {
                binding: 'insdExhaStndUseQty',
                // header: String(t('ut.label.표준사용량', '표준사용량')),
                header: '[CHM]',
                width: 95,
                isRequired: false,
                dataType: DataType.Number,
              },
            ],
          },
        ],
      },
      {
        header: String(t('ut.label.Exhaust air [Ventilation(to Outside)]', 'Exhaust air')),
        collapseTo: 'otsdExhaOtsdDmtrValCtn',
        columns: [
          {
            header: String(t('ut.label.Size', 'Size')),
            columns: [
              {
                binding: 'otsdExhaOtsdDmtrValCtn',
                // header: String(t('ut.label.Size O.D[Φ]', 'Size O.D[Φ]')),
                header: 'O.D[Φ]',
                width: 75,
                isRequired: false,
              },
              {
                binding: 'otsdExhaInsdDmtrValCtn',
                // header: String(t('ut.label.Size I.D[Φ]', 'Size I.D[Φ]')),
                header: 'I.D[Φ]',
                width: 75,
                isRequired: false,
              },
            ],
          },
          {
            header: String(t('ut.label.Connection', 'Connection')),
            columns: [
              {
                binding: 'otsdExhaCnctTpCd',
                // header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
                header: '[Type]',
                width: 90,
                isRequired: false,
                cssClass: 'WijmoSelect',
                dataMap: new DataMap(code?.devcCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
              },
            ],
          },
          {
            header: String(t("ut.label.Q'ty", "Q'ty")),
            columns: [
              {
                binding: 'otsdExhaPntCnt',
                // header: String(t("ut.label.Q'ty[Point]", "Q'ty[Point]")),
                header: '[Point]',
                width: 92,
                isRequired: false,
                dataType: DataType.Number,
              },
            ],
          },
          {
            header: String(t('ut.label.Consumption', 'Consumption')),
            columns: [
              {
                binding: 'otsdExhaCspCapa', // 기계 조건부 필수 대상값
                // header: String(t('ut.label.Consumption[CHM]', 'Consumption[CHM]')),
                header: '[CHM]',
                width: 95,
                isRequired: false,
                dataType: DataType.Number,
              },
              {
                binding: 'otsdExhaCspSumCapa', // 수식 (= Qty * Exhaust air [Ventilation Consumption[ℓ/min])
                // header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
                header: 'Sum',
                width: 95,
                isReadOnly: true,
                align: 'right',
                cellTemplate: (params) =>
                  calculateCspSumCapa(params.item.otsdExhaPntCnt, params.item.otsdExhaCspCapa),
              },
            ],
          },
          {
            header: String(t('ut.label.표준사용량', '표준사용량')),
            columns: [
              {
                binding: 'otsdExhaStndUseQty',
                // header: String(t('ut.label.표준사용량', '표준사용량')),
                header: '[CHM]',
                width: 95,
                isRequired: false,
                dataType: DataType.Number,
              },
            ],
          },
        ],
      },
      {
        header: String(t('ut.label.Supply Air', 'Supply air')),
        collapseTo: 'suarOtsdDmtrValCtn',
        columns: [
          {
            header: String(t('ut.label.Size', 'Size')),
            columns: [
              {
                binding: 'suarOtsdDmtrValCtn',
                // header: String(t('ut.label.Size O.D[Φ]', 'Size O.D[Φ]')),
                header: 'O.D[Φ]',
                width: 75,
                isRequired: false,
              },
              {
                binding: 'suarInsdDmtrValCtn',
                // header: String(t('ut.label.Size I.D[Φ]', 'Size I.D[Φ]')),
                header: 'I.D[Φ]',
                width: 75,
                isRequired: false,
              },
            ],
          },
          {
            header: String(t('ut.label.Connection', 'Connection')),
            columns: [
              {
                binding: 'suarDevcCnctTpCd',
                // header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
                header: '[Type]',
                width: 90,
                isRequired: false,
                cssClass: 'WijmoSelect',
                dataMap: new DataMap(code?.devcCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
              },
            ],
          },
          /*
          [24.12.02] suarDevcCnctTpCd과 중복항목이므로 삭제
          {
            binding: 'suarCllrTpCd',
            header: String(t('ut.label.Connection Type[Duct/Pipe]', 'Connection Type[Duct/Pipe]')),
            width: 100,
            isRequired: false,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.suarCllrTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          */
          {
            header: String(t("ut.label.Q'ty", "Q'ty")),
            columns: [
              {
                binding: 'suarPntCnt',
                // header: String(t("ut.label.Q'ty[Point]", "Q'ty[Point]")),
                header: '[Point]',
                width: 92,
                isRequired: false,
                dataType: DataType.Number,
              },
            ],
          },
          {
            header: String(t('ut.label.Consumption', 'Consumption')),
            columns: [
              {
                binding: 'suarCspCapa', // 기계 조건부 필수 대상값
                // header: String(t('ut.label.Consumption[CHM]', 'Consumption[CHM]')),
                header: '[CHM]',
                width: 95,
                isRequired: false,
                dataType: DataType.Number,
              },
              {
                binding: 'suarCspSumCapa', // 수식 (= Qty * Supply air Consumption[ℓ/min])
                // header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
                header: 'Sum',
                width: 95,
                isReadOnly: true,
                align: 'right',
                cellTemplate: (params) =>
                  calculateCspSumCapa(params.item.suarPntCnt, params.item.suarCspCapa),
              },
            ],
          },
          {
            header: String(t('ut.label.표준사용량', '표준사용량')),
            columns: [
              {
                binding: 'suarStndUseQty',
                // header: String(t('ut.label.표준사용량', '표준사용량')),
                header: '[CHM]',
                width: 95,
                isRequired: false,
                dataType: DataType.Number,
              },
            ],
          },
        ],
      },
      {
        // header: String(t('ut.label.EQP Spec(Dimension_mm)', 'Dimension[mm]/Weight[kg]')),
        header: String(t('ut.label.Equipment Specification', 'Equipment Specification')),
        collapseTo: 'eqpDimWthLen',
        isCollapsed: true,
        columns: [
          {
            header: String(t('ut.label.Dimension[mm]', 'Dimension[mm]')),
            columns: [
              {
                binding: 'eqpDimWthLen',
                header: String(t('ut.label.Width', 'Width')),
                width: 190,
                isRequired: false,
                dataType: DataType.Number,
              },
              {
                binding: 'eqpDimDpthNvl',
                header: String(t('ut.label.Depth', 'Depth')),
                width: 100,
                isRequired: false,
                dataType: DataType.Number,
              },
              {
                binding: 'eqpDimHigtNvl',
                header: String(t('ut.label.Height', 'Height')),
                width: 100,
                isRequired: false,
                dataType: DataType.Number,
              },
            ],
          },
          {
            header: String(t('ut.label.Weight[kg]', 'Weight[kg]')),
            columns: [
              {
                binding: 'eqpWgt',
                // header: String(t('ut.label.EQP Spec(Weight_kg)', 'Weight')),
                header: String(t('ut.label.전체중량', '전체중량')),
                width: 110,
                isRequired: false,
                dataType: DataType.Number,
              },
            ],
          },
        ],
      },
      {
        binding: 'rmk',
        header: String(t('ut.label.Remark', 'Remark')),
        width: 100,
        isRequired: false,
      },
      {
        binding: 'dataInsUserNm',
        header: String(t('com.label.작성자', '작성자')),
        width: 100,
        align: 'center',
        isReadOnly: true,
      },
      {
        binding: 'dataInsDtm',
        header: String(t('com.label.작성일자', '작성일자')),
        width: 120,
        align: 'center',
        isReadOnly: true,
      },
      {
        binding: 'dataUpdUserNm',
        header: String(t('com.label.수정자', '수정자')),
        width: 100,
        align: 'center',
        isReadOnly: true,
      },
      {
        binding: 'dataUpdDtm',
        header: String(t('com.label.수정일자', '수정일자')),
        width: 120,
        align: 'center',
        isReadOnly: true,
      },
      {
        binding: 'utmId',
        visible: false,
      },
      {
        binding: 'planProcId',
        visible: false,
      },
      {
        binding: 'utmSeq',
        visible: false,
      },
      {
        binding: 'eqclId',
        visible: false,
      },
      {
        binding: 'eqpMainId',
        visible: false,
      },
      {
        binding: 'eqpMchId',
        visible: false,
      },
      {
        binding: 'exlUpldUseYn',
        visible: false,
      },
    ];
  }, [code, condition]);

  const onInitialized = (grid) => {
    gridRef.current = grid;
    grid.itemFormatter = onItemFormatter;

    if (gridRef.current) {
      const grid = gridRef.current;

      grid.loadedRows.addHandler(() => {
        const items = grid.collectionView ? grid.collectionView.sourceCollection : grid.itemsSource;
        if (!items || !Array.isArray(items)) {
          return;
        }

        items.forEach((item, rowIndex) => {
          grid.columns.forEach((col, colIndex) => {
            // readonly인경우 처리 x
            if (col.isReadOnly) return;

            const value = grid.getCellData(rowIndex, colIndex, false);
            const content = CommonUtil.safeHtmlContents(value, false);

            grid.setCellData(rowIndex, colIndex, content, true);
          });
        });

        grid.invalidate();
      });
    }

    grid.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);

      if (ht.panel === grid.cells) {
        const item = grid.rows[ht.row].dataItem;
        const binding = grid.columns[ht.col].binding;

        if (e.target instanceof HTMLButtonElement) {
          const id = e.target.id;
          // 표준설비코드 클릭
          if ('stndEqpId' === id) {
            setStandardEquipmentCondition({
              singleSelect: true,
              prdnProcCd: condition.prdnProcCd,
              eqclId: condition.eqclId,
              selectedIds: item.stndEqpId ? [item.stndEqpId] : undefined,
              target: {
                row: ht.row,
                col: ht.col,
              },
            });
            setStandardEquipmentModalOpen(true);
          }
          // 표준라이브러리 클릭
          if (['utmLibId', 'utmLibVerNo'].includes(id)) {
            if (!item.stndEqpId) {
              openMessageBar({
                type: 'error',
                content: t(
                  'ut.label.먼저 표준설비코드를 선택해 주세요.',
                  '먼저 표준설비코드를 선택해 주세요.'
                ),
              });
              return;
            }
            setStandardLibraryCondition({
              stndEqpId: item.stndEqpId,
              target: {
                row: ht.row,
                col: ht.col,
              },
            });
            setStandardLibraryModalOpen(true);
          }
          if ('machEqpNm' === id) {
            if (item.machEqpNm && item.tagIssuYn === 'Y') {
              if (item.utmId && item.planProcId && item.utmSeq) {
                setHookupTagCondition({
                  utmId: item.utmId,
                  planProcId: item.planProcId,
                  utmSeq: item.utmSeq,
                  tagDivsCd: 'MECH',
                });
                setOpenHookupTagPopup(true);
              }
            }
          }
          if ('elpwEqpNm' === id) {
            if (item.elpwEqpNm && item.tagIssuYn === 'Y') {
              if (item.utmId && item.planProcId && item.utmSeq) {
                setHookupTagCondition({
                  utmId: item.utmId,
                  planProcId: item.planProcId,
                  utmSeq: item.utmSeq,
                  tagDivsCd: 'ELEC',
                });
                setOpenHookupTagPopup(true);
              }
            }
          }
        }
      }
    });
  };

  const downloadExcel = async (templateType) => {
    openLoading(true);
    downloadExcelUtMatrixTemplatesPost(templateType, condition).finally(() => openLoading(false));
  };

  const onCellEditEnding = useEvent((grid, e) => {
    const newVal = grid.activeEditor?.value;
    const data = grid.rows[e.row].dataItem;
    const binding = grid.columns[e.col].binding;

    // 전압값수치
    if ('vltgNvl' === binding) {
      const utVltg = (code?.utVltgList || []).filter((o) => o.cmnCd === newVal);
      if (utVltg.length) {
        // 전기위상코드 선택 (ref. 전압값수치)
        const elpwPhasCd = (code?.elpwPhasCd || []).filter(
          (o) => o.cmnCd === utVltg[0]?.optValCtn3
        );
        data.elpwPhasCd = elpwPhasCd.length ? elpwPhasCd[0].cmnCd : '';
        // 단선유형코드 선택 (ref. 전기위상코드)
        if (elpwPhasCd.length) {
          const ctwTpCd = (code?.ctwTpCd || []).filter(
            (o) => o.cmnCd === elpwPhasCd[0]?.optValCtn1
          );
          data.ctwTpCd = ctwTpCd.length ? ctwTpCd[0].cmnCd : '';
        }
        // 전기연결유형코드 선택 (ref. 전압값수치)
        const elpwEqpCnctTpCd = (code?.elpwEqpCnctTpCd || []).filter(
          (o) => o.optValCtn1 === utVltg[0].cmnCd
        );
        data.elpwEqpCnctTpCd = elpwEqpCnctTpCd.length ? elpwEqpCnctTpCd[0].cmnCd : '';
      } else {
        data.elpwPhasCd = '';
        data.ctwTpCd = '';
        data.elpwEqpCnctTpCd = '';
      }
    } else if ('elpwPhasCd' === binding) {
      const elpwPhasCd = (code?.elpwPhasCd || []).filter((o) => o.cmnCd === newVal);
      // 단선유형코드 선택 (ref. 전기위상코드)
      if (elpwPhasCd.length) {
        const ctwTpCd = (code?.ctwTpCd || []).filter((o) => o.cmnCd === elpwPhasCd[0]?.optValCtn1);
        data.ctwTpCd = ctwTpCd.length ? ctwTpCd[0].cmnCd : '';
      } else {
        data.ctwTpCd = '';
      }
    } else if ('pwftNvl' === binding) {
      const regex = /^[0-9.-]*$/;
      if (newVal && !regex.test(newVal)) {
        e.cancel = true;
      }
    } else if ('elpwEqpQty' === binding) {
      const elpwEqpQty = Number(newVal);
      if (!_.isNaN(elpwEqpQty) && elpwEqpQty > 0) {
        data.pwftNvl = data.pwftNvl
          ? String(data.pwftNvl).replace(/[^0-9.-]/g, '')
          : PWFT_NVL_DEFAULT;

        const bildPlntCd = (code?.bildPlntCd || []).filter(
          (o) => o.cmnCd === condition?.bildPlntCd
        );
        // [24.12.11] 건설플랜코드의 주파수 값으로 고정 (수정불가)
        data.frqNvl = bildPlntCd.length > 0 ? bildPlntCd[0].optValCtn3 : '';
      } else {
        data.pwftNvl = '';
        data.frqNvl = '';
      }
    }
  });

  const onItemFormatter = useEvent((panel, row, col, cell) => {
    if (CellType.ColumnHeader === panel.cellType) {
      const binding = panel.columns[col].binding;
      // 필수항목
      if (['dtalProcCd', 'prdnLnNm', 'eqpMchNm', 'machEqpQty', 'elpwEqpQty'].includes(binding)) {
        addClass(cell, 'dot');
      }
    } else if (CellType.Cell === panel.cellType) {
      const binding = panel.columns[col].binding;
      const item = panel.rows[row].dataItem;
      // 표준설비코드 선택 시 지정 값 수정불가 (세부공정, Main, Machine)
      if (['dtalProcCd', 'eqpMainNm', 'eqpMchNm'].includes(binding)) {
        const readonly = !!item?.stndEqpId;
        cell.ariaReadOnly = readonly;
        if ('dtalProcCd' === binding) {
          toggleClass(cell, 'WijmoSelect', !readonly);
          if (readonly && cell.firstElementChild instanceof HTMLButtonElement) {
            cell.firstChild.remove();
          }
        }
      }
      // 표준설비코드, 표준라이브러리 editable css 적용
      if (['stndEqpId', 'utmLibId', 'utmLibVerNo'].includes(binding)) {
        const readonly = !isWrite || !isUtWriter;
        cell.ariaReadOnly = readonly;
        toggleClass(cell, 'WijmoFind', !readonly);
      }
      if (
        [
          'vltgNvl',
          'elpwPhasCd',
          'ctwTpCd',
          'pwftNvl',
          'od2EcNvl',
          'smtmOprt',
          'brkeCapa',
          'plcClctYn',
          'elpwEqpCnctTpCd',
          'elpwCapa',
          'elpwHeatCapa',
          'elpwStndUseQty',
        ].includes(binding)
      ) {
        // Electricity 하위 항목 readonly 설정
        // 전력설비수량(elpwEqpQty)이 0인 경우 readonly
        // 표준라이브러리 선택 시 지정 값 수정불가 (Voltage, Phase, Wires, Capacity[kW], Current[입력값], Breaker)
        const readonly =
          Number(item.elpwEqpQty || 0) < 1 ||
          !isWrite ||
          !isUtWriter ||
          (['vltgNvl', 'elpwPhasCd', 'ctwTpCd', 'elpwCapa', 'od2EcNvl', 'brkeCapa'].includes(
            binding
          ) &&
            !!item.utmLibId);
        cell.ariaReadOnly = readonly;
        if (
          ['vltgNvl', 'elpwPhasCd', 'ctwTpCd', 'plcClctYn', 'elpwEqpCnctTpCd'].includes(binding)
        ) {
          toggleClass(cell, 'WijmoSelect', !readonly);
          if (readonly && cell.firstElementChild instanceof HTMLButtonElement) {
            cell.firstChild.remove();
          }
        }
      }
      // 기계 하위 항목 readonly 설정 (machEqpQty)
      if (
        [
          'cdaOtsdSizeValCtn',
          'cdaInsdSizeValCtn',
          'cdaDevcCnctTpCd',
          'cdaPntCnt',
          'cdaPresValCtn',
          'cdaCspCapa',
          'cdaStndUseQty',
          'nitrOtsdDmtrValCtn',
          'nitrInsdDmtrValCtn',
          'nitrDevcCnctTpCd',
          'nitrPresValCtn',
          'nitrPntCnt',
          'nitrCspCapa',
          'nitrCspSumCapa',
          'nitrPeakCspCapa',
          'nitrStndUseQty',
          'iwOtsdDmtrValCtn',
          'iwInsdDmtrValCtn',
          'iwDevcCnctTpCd',
          'iwTmprValCtn',
          'iwPresValCtn',
          'iwPntCnt',
          'iwDrnCapa',
          'iwDrnSumCapa',
          'iwCspCapa',
          'iwStndUseQty',
          'frwtOtsdDmtrValCtn',
          'frwtInsdDmtrValCtn',
          'frwtDevcCnctTpCd',
          'frwtTmprValCtn',
          'frwtPresValCtn',
          'frwtPntCnt',
          'frwtDrnCapa',
          'frwtDrnSumCapa',
          'frwtCspCapa',
          'frwtStndUseQty',
          'coolOtsdSizeValCtn',
          'coolInsdSizeValCtn',
          'coolDevcCnctTpCd',
          'coolTmprValCtn',
          'coolPresValCtn',
          'coolPntCnt',
          'coolCspCapa',
          'coolStndUseQty',
          'wwOtsdDmtrValCtn',
          'wwInsdDmtrValCtn',
          'wwDevcCnctTpCd',
          'wwTmprValCtn',
          'wwPresValCtn',
          'wwPntCnt',
          'wwCspCapa',
          'wwStndUseQty',
          'stemOtsdDmtrValCtn',
          'stemInsdDmtrValCtn',
          'stemDevcCnctTpCd',
          'stemCwtSize',
          'stemCwtDevcCnctTpCd',
          'stemPresValCtn',
          'stemPntCnt',
          'stemCspCapa',
          'stemStndUseQty',
          'lngOtsdDmtrValCtn',
          'lngInsdDmtrValCtn',
          'lngDevcCnctTpCd',
          'lngPresValCtn',
          'lngPntCnt',
          'lngCspCapa',
          'lngStndUseQty',
          'exhaOtsdDmtrValCtn',
          'insdExhaInsdDmtrValCtn',
          'insdExhaCnctTpCd',
          'insdExhaCllrTpCd',
          'insdExhaTmprValCtn',
          'insdExhaPntCnt',
          'insdExhaCspCapa',
          'insdExhaStndUseQty',
          'otsdExhaOtsdDmtrValCtn',
          'otsdExhaInsdDmtrValCtn',
          'otsdExhaCnctTpCd',
          'otsdExhaPntCnt',
          'otsdExhaCspCapa',
          'otsdExhaStndUseQty',
          'suarOtsdDmtrValCtn',
          'suarInsdDmtrValCtn',
          'suarDevcCnctTpCd',
          'suarCllrTpCd',
          'suarPntCnt',
          'suarCspCapa',
          'suarStndUseQty',
        ].includes(binding)
      ) {
        // 기계설비수량(machEqpQty)이 0인 경우 readonly
        // 표준라이브러리 선택 시 지정 값 수정불가
        const readonly =
          Number(item.machEqpQty || 0) < 1 ||
          !isWrite ||
          !isUtWriter ||
          ([
            'cdaCspCapa',
            'nitrPeakCspCapa',
            'iwCspCapa',
            'frwtCspCapa',
            'coolCspCapa',
            'wwCspCapa',
            'stemCspCapa',
            'lngCspCapa',
            'insdExhaCspCapa',
            'otsdExhaCspCapa',
            'suarCspCapa',
          ].includes(binding) &&
            !!item.utmLibId);
        cell.ariaReadOnly = readonly;
        if (
          [
            'cdaDevcCnctTpCd',
            'nitrDevcCnctTpCd',
            'iwDevcCnctTpCd',
            'frwtDevcCnctTpCd',
            'coolDevcCnctTpCd',
            'wwDevcCnctTpCd',
            'stemDevcCnctTpCd',
            'stemCwtDevcCnctTpCd',
            'lngDevcCnctTpCd',
            'insdExhaCnctTpCd',
            'insdExhaCllrTpCd',
            'otsdExhaCnctTpCd',
            'otsdExhaPntCnt',
            'suarDevcCnctTpCd',
            'suarCllrTpCd',
          ].includes(binding)
        ) {
          toggleClass(cell, 'WijmoSelect', !readonly);
          if (readonly && cell.firstElementChild instanceof HTMLButtonElement) {
            cell.firstChild.remove();
          }
        }
      }
    }
  });

  const handleAddRowByStandardEquipment = () => {
    setStandardEquipmentCondition({
      prdnProcCd: condition.prdnProcCd,
      eqclId: condition.eqclId,
      singleSelect: false,
    });
    setStandardEquipmentModalOpen(true);
  };

  const handleAddRow = () => {
    if (!condition.utmId || !condition.planProcId || !condition.prdnProcCd || !condition.eqclId) {
      openMessageBar({
        type: 'error',
        content: t(
          'ut.label.조회 후 행추가 버튼을 눌러주세요.',
          '조회 후 행추가 버튼을 눌러주세요.'
        ),
      });
      return;
    }

    const newRow = {
      crudKey: CrudCode.CREATE,
      utmId: condition.utmId,
      planProcId: condition.planProcId,
      prdnProcCd: condition.prdnProcCd,
      prdnProcCdNm: condition.prdnProcCdNm,
      eqclId: condition.eqclId,
      eqclNm: condition.eqclNm,
      exlUpldUseYn: 'N',
      plcClctYn: 'N',
      insTpCd: getInsTpCd(null, null),
    } as UtMatrixDetail;
    const rows = [newRow, ...rowData];
    setRowData(rows);
  };

  const handleCopyRow = () => {
    const selectedRows = gridRef.current.rows.filter((r) => r.isSelected);
    if ((selectedRows || []).length < 1) {
      openMessageBar({
        type: 'error',
        content: t('com.label.복사할 행을 선택해 주세요.', '복사할 행을 선택해 주세요.'),
      });
      return;
    }

    const newRows = [] as UtMatrixDetail[];
    selectedRows.forEach((o) => {
      const item = o.dataItem;
      newRows.push({
        ...item,
        crudKey: CrudCode.CREATE,
        exlUpldUseYn: 'N',
        utmSeq: '',
        dataInsUserNm: '',
        dataInsDtm: '',
        dataUpdUserNm: '',
        dataUpdDtm: '',
      });
    });
    setRowData([...newRows, ...rowData]);
  };

  const handleDelRow = () => {
    const selectedRows = gridRef.current.rows.filter((r) => r.isSelected);
    if (selectedRows.length < 1) return;

    selectedRows.forEach((row) => {
      const item = row.dataItem;
      if (item.crudKey === CrudCode.CREATE) {
        gridRef.current.collectionView.remove(item);
      } else {
        item.crudKey = CrudCode.DELETE;
      }
    });
    gridRef.current.refresh();
  };

  const handleSave = (showMsg = true) => {
    return new Promise((resolve) => {
      if (!(rowData || []).filter((o) => o.crudKey).length) {
        /*
        // [24.08.19] 탭 이동 시 변경사항 없는 경우 자동저장 안내문구 미노출
        if (showMsg) {
          openMessageBar({
            type: 'warning',
            content: t('ut.label.변경사항이 없습니다.', '변경사항이 없습니다.'),
          });
        }
        */
        resolve(true);
        return;
      }

      saveUtMatrix('write', rowData)
        .then((res) => {
          if (showMsg) {
            openMessageBar({
              type: res?.successOrNot === 'Y' ? 'confirm' : 'error',
              content:
                res?.successOrNot === 'Y'
                  ? t('com.msg.저장되었습니다.', '저장되었습니다.')
                  : t('com.msg.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
            });
          }
          if (res?.successOrNot === 'Y') {
            searchRegistMatrix(condition);
          }
        })
        .finally(() => resolve(true));
    });
  };

  const handleSubmit = () => {
    /*
    // [24.09.02] 인터락/알림 안내문구 통일을 위해 패키지에서 검증
    // 빈도값수치 – UT Matrix (UT Matrix No)별로 동일
    const rows = (rowData || [])
      .filter((o) => !_.isNaN(Number(o.elpwEqpQty)) && Number(o.elpwEqpQty) > 0)
      .map((o) => o.frqNvl);
    if (new Set(rows).size > 1) {
      openMessageBar({
        type: 'error',
        content: t('ut.label.Frequency 재확인 바랍니다.', 'Frequency 재확인 바랍니다.'),
      });
      return;
    }
    */
    /*
    // [24.08.14] 인터락/알림 안내문구 통일을 위해 패키지에서 검증
    const brkeRows = (rowData || []).filter((o) => {
      if (!_.isNaN(Number(o.elpwEqpQty)) && Number(o.elpwEqpQty) > 0) {
        const od1EcNvl1 =
          o.od1EcNvl ||
          calculateOd1EcNvl(
            Number(o.elpwEqpQty || 0),
            o.elpwPhasCd,
            Number(o.elpwCapa || 0),
            Number(o.vltgNvl || 0),
            o.pwftNvl
          );
        const brkeCapa =
          o.brkeCapa || calculateBrkeCapa(code?.utBrkeCapaList || [], od1EcNvl1, o.od2EcNvl);
        const target = Math.max(Number(od1EcNvl1 || 0), Number(o.od2EcNvl || 0)) * 1.25;
        // Breaker보다 Current x 1.25이 큰 경우 팝업으로 알람제공 또는 제출 불가
        return Number(brkeCapa || 0) < target;
      }
      return false;
    });

    if (brkeRows.length) {
      openMessageBar({
        type: 'error',
        content: t('ut.label.Breaker 재확인 바랍니다.', 'Breaker 재확인 바랍니다.'),
      });
      return;
    }
    */
    props?.onSubmit?.();
  };

  const handleModify = () => {
    props?.onModify?.();
  };

  const handleDownloadExcel = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(gridRef.current, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = t('ut.label.UT Matrix', 'UT Matrix');
    book.saveAsync(getExcelFileName(t('ut.label.UT Matrix 작성', 'UT Matrix 작성')));
  };

  const bindLibrary = (row, library = {} as UtMatrixLibrary) => {
    const item = gridRef.current?.rows[row].dataItem;
    item.utmLibId = library?.utmLibId;
    item.utmLibVerNo = library?.utmLibVerNo;
    // 엑셀 업로드를 통해 추가된 행의 경우 작성구분 값 자동판단하지 않음
    if (item.exlUpldUseYn === 'N') {
      item.insTpCd = getInsTpCd(item.stndEqpId, library?.utmLibId);
    }
    // 전기
    item.vltgNvl = library?.vltgNvl;
    item.elpwPhasCd = library?.elpwPhasCd;
    item.ctwTpCd = library?.ctwTpCd;
    item.elpwCapa = library?.elpwCapa;
    item.od2EcNvl = library?.od2EcNvl;
    item.brkeCapa = library?.brkeCapa;
    // 기계
    item.cdaCspCapa = library?.cdaCspCapa;
    item.nitrPeakCspCapa = library?.nitrPeakCspCapa;
    item.iwCspCapa = library?.iwCspCapa;
    item.frwtCspCapa = library?.frwtCspCapa;
    item.coolCspCapa = library?.coolCspCapa;
    item.wwCspCapa = library?.wwCspCapa;
    item.stemCspCapa = library?.stemCspCapa;
    item.lngCspCapa = library?.lngCspCapa;
    item.insdExhaCspCapa = library?.insdExhaCspCapa;
    item.otsdExhaCspCapa = library?.otsdExhaCspCapa;
    item.suarCspCapa = library?.suarCspCapa;
    // item.prjNm = lib.prjNm;
    //
    if (item.crudKey !== CrudCode.CREATE) {
      item.crudKey = CrudCode.UPDATE;
    }
    gridRef.current?.collectionView.refresh();
  };

  const excludePin = useMemo(() => {
    const convertFlat = (acc, cur) => {
      if ('columns' in cur && Array.isArray(cur?.columns)) {
        return cur.columns.reduce(convertFlat, acc);
      }
      return ![
        'prdnProcCdNm',
        'bizAreaNm',
        'eqclNm',
        'stndEqpId',
        'dtalProcCd',
        'bldgFloCd',
        'istlLocNm',
        'prdnLnNm',
        'prdnPrlLnNm',
        'eqpMainNm',
        'eqpMchNm',
        'eqpUntNm',
        'machEqpNm',
        'elpwEqpNm',
        'machEqpQty',
        'elpwEqpQty',
      ].includes(cur?.binding)
        ? [...acc, cur?.binding]
        : acc;
    };

    return (layoutDefinition || []).reduce(convertFlat, []);
  }, [layoutDefinition]);

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('ut.label.UT Matrix', 'UT Matrix')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{(rowData || []).length.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
          {condition?.utmNo && condition?.prdnProcCdNm && condition?.eqclNm && (
            <div className="info warning">
              {condition?.utmNo} / {condition?.prdnProcCdNm} / {condition?.eqclNm}
            </div>
          )}
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="info"
            onClick={() => downloadFileByType(FileTypeName.GUIDE_UT_REGIST)}
          >
            {t('ut.label.작성가이드', '작성가이드')}
          </Button>
          <Button
            css={IconButton.button}
            className="info"
            onClick={() => {
              const items = gridRef.current.rows.filter((r) => r.isSelected).map((o) => o.dataItem);
              if (items.length > 1) {
                openMessageBar({
                  type: 'error',
                  content: t(
                    'ut.label.검색할 대상을 1건만 선택해 주세요.',
                    '검색할 대상을 1건만 선택해 주세요.'
                  ),
                });
                return;
              }
              setUtMatrixRegistHistoryCondition({
                stndEqpId: items.length < 1 ? '' : items[0].stndEqpId,
              });
              setUtMatrixRegistHistoryModalOpen(true);
            }}
          >
            {t('ut.label.설비별 UT Matrix', '설비별 UT Matrix')}
          </Button>
          {isUtWriter && (
            <>
              {condition?.utmId && condition?.planProcId && (
                <>
                  {isWrite && (
                    <>
                      {/*
                      [24.11.11] 참조하기 기능 삭제
                      <Button
                        css={IconButton.button}
                        className="info"
                        onClick={() => {
                          if ((rowData || []).length) {
                            openCommonModal({
                              modalType: 'confirm',
                              content: t(
                                'ut.label.저장된 데이터가 있으면 기존 데이터는 삭제됩니다. 진행하시겠습니까?',
                                '저장된 데이터가 있으면 기존 데이터는 삭제됩니다. 진행하시겠습니까?'
                              ),
                              yesCallback: () => {
                                setUtMatrixDetailProcessModalOpen(true);
                              },
                            });
                          } else {
                            setUtMatrixDetailProcessModalOpen(true);
                          }
                        }}
                      >
                        {t('ut.label.참조하기', '참조하기')}
                      </Button>
                      */}
                      <ExcelUploadButton
                        msgConfirm={
                          t(
                            'ut.label.저장된 데이터가 있으면 기존 데이터는 삭제됩니다.\n 새로 Upload 하시겠습니까?',
                            '저장된 데이터가 있으면 기존 데이터는 삭제됩니다.\n 새로 Upload 하시겠습니까?'
                          ) || ''
                        }
                        onCallback={(file) => {
                          openLoading(true);
                          uploadExcelUtMatrixTemplates(
                            file,
                            'TPL_UT_REGIST',
                            'NORMAL',
                            'tb_eelmb_utm_d',
                            condition?.utmId,
                            condition?.planProcId
                          )
                            .then((response) => {
                              openLoading(false);
                              if (response?.data.x_result == 'OK') {
                                openMessageBar({
                                  type: 'confirm',
                                  content: t(
                                    'ut.label.검증이 완료되었습니다.',
                                    '검증이 완료되었습니다.'
                                  ),
                                });
                                searchRegistMatrix(condition);
                              } else {
                                setExcelValidCondition(response?.data.p_xls_upload_id);
                                setExcelValidModalOpen(true);
                              }
                            })
                            .finally(() => openLoading(false));
                        }}
                      />
                      <Button
                        css={IconButton.button}
                        className="addRow"
                        onClick={handleAddRowByStandardEquipment}
                      >
                        {t('ut.label.표준설비코드', '표준설비코드')}
                      </Button>
                      <Button css={IconButton.button} className="addRow" onClick={handleAddRow}>
                        {t('com.button.행추가', '행추가')}
                      </Button>
                      <Button
                        css={IconButton.button}
                        className="copyRow"
                        onClick={handleCopyRow}
                        disableRipple
                      >
                        {t('com.button.행복사', '행복사')}
                      </Button>
                      <Button css={IconButton.button} className="delRow" onClick={handleDelRow}>
                        {t('com.button.행삭제', '행삭제')}
                      </Button>
                    </>
                  )}
                </>
              )}
            </>
          )}
          {condition?.utmId && (
            <>
              <Button
                css={IconButton.button}
                className="Exceldown"
                onClick={() => downloadExcel(FileTypeName.TPL_UT_REGIST)}
              >
                {t('ut.label.양식 다운로드', '양식 다운로드')}
              </Button>
            </>
          )}
          <Button css={IconButton.button} className="Exceldown" onClick={handleDownloadExcel}>
            {t('com.button.다운로드', '다운로드')}
          </Button>
          <Button
            css={IconButton.button}
            className="refresh"
            onClick={() => searchRegistMatrix(condition)}
          >
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        height={388}
        isReadOnly={!isWrite || !isUtWriter}
        frozenColumns={13}
        excludeFilter={['utmSeq', 'prdnProcCdNm', 'eqclNm']}
        excludePin={excludePin}
        initialized={onInitialized}
        cellEditEnding={onCellEditEnding}
        beginningEdit={(grid, e) => {
          const binding = grid.columns[e.col].binding;
          const item = grid.rows[e.row].dataItem;
          // 표준설비코드 값에 따라 readonly 설정
          if (item?.stndEqpId && ['dtalProcCd', 'eqpMainNm', 'eqpMchNm'].includes(binding)) {
            // 표준설비코드 선택 시 지정 값 수정불가 (세부공정, Main, Machine)
            e.cancel = true;
          }

          // Electricity 하위 항목 readonly 설정
          if (
            [
              'vltgNvl',
              'elpwPhasCd',
              'ctwTpCd',
              'pwftNvl',
              'od2EcNvl',
              'brkeCapa',
              'plcClctYn',
              'elpwEqpCnctTpCd',
              'elpwCapa',
              'elpwHeatCapa',
              'elpwStndUseQty',
            ].includes(binding)
          ) {
            // 전력설비수량(elpwEqpQty)이 0인 경우 readonly
            // 표준라이브러리 선택 시 지정 값 수정불가 (Voltage, Phase, Wires, Capacity[kW], Current[입력값], Breaker)
            if (
              Number(item.elpwEqpQty || 0) < 1 ||
              (['vltgNvl', 'elpwPhasCd', 'ctwTpCd', 'elpwCapa', 'od2EcNvl', 'brkeCapa'].includes(
                binding
              ) &&
                !!item.utmLibId)
            ) {
              e.cancel = true;
            }
          }
          // 기계 하위 항목 readonly 설정 (machEqpQty)
          if (
            [
              'cdaOtsdSizeValCtn',
              'cdaInsdSizeValCtn',
              'cdaDevcCnctTpCd',
              'cdaPntCnt',
              'cdaPresValCtn',
              'cdaCspCapa',
              'cdaStndUseQty',
              'nitrOtsdDmtrValCtn',
              'nitrInsdDmtrValCtn',
              'nitrDevcCnctTpCd',
              'nitrPresValCtn',
              'nitrPntCnt',
              'nitrCspCapa',
              'nitrCspSumCapa',
              'nitrPeakCspCapa',
              'nitrStndUseQty',
              'iwOtsdDmtrValCtn',
              'iwInsdDmtrValCtn',
              'iwDevcCnctTpCd',
              'iwTmprValCtn',
              'iwPresValCtn',
              'iwPntCnt',
              'iwDrnCapa',
              'iwDrnSumCapa',
              'iwCspCapa',
              'iwStndUseQty',
              'frwtOtsdDmtrValCtn',
              'frwtInsdDmtrValCtn',
              'frwtDevcCnctTpCd',
              'frwtTmprValCtn',
              'frwtPresValCtn',
              'frwtPntCnt',
              'frwtDrnCapa',
              'frwtDrnSumCapa',
              'frwtCspCapa',
              'frwtStndUseQty',
              'coolOtsdSizeValCtn',
              'coolInsdSizeValCtn',
              'coolDevcCnctTpCd',
              'coolTmprValCtn',
              'coolPresValCtn',
              'coolPntCnt',
              'coolCspCapa',
              'coolStndUseQty',
              'wwOtsdDmtrValCtn',
              'wwInsdDmtrValCtn',
              'wwDevcCnctTpCd',
              'wwTmprValCtn',
              'wwPresValCtn',
              'wwPntCnt',
              'wwCspCapa',
              'wwStndUseQty',
              'stemOtsdDmtrValCtn',
              'stemInsdDmtrValCtn',
              'stemDevcCnctTpCd',
              'stemCwtSize',
              'stemCwtDevcCnctTpCd',
              'stemPresValCtn',
              'stemPntCnt',
              'stemCspCapa',
              'stemStndUseQty',
              'lngOtsdDmtrValCtn',
              'lngInsdDmtrValCtn',
              'lngDevcCnctTpCd',
              'lngPresValCtn',
              'lngPntCnt',
              'lngCspCapa',
              'lngStndUseQty',
              'exhaOtsdDmtrValCtn',
              'insdExhaInsdDmtrValCtn',
              'insdExhaCnctTpCd',
              'insdExhaCllrTpCd',
              'insdExhaTmprValCtn',
              'insdExhaPntCnt',
              'insdExhaCspCapa',
              'insdExhaStndUseQty',
              'otsdExhaOtsdDmtrValCtn',
              'otsdExhaInsdDmtrValCtn',
              'otsdExhaCnctTpCd',
              'otsdExhaCspCapa',
              'otsdExhaStndUseQty',
              'suarOtsdDmtrValCtn',
              'suarInsdDmtrValCtn',
              'suarDevcCnctTpCd',
              'suarCllrTpCd',
              'suarPntCnt',
              'suarCspCapa',
              'suarStndUseQty',
            ].includes(binding)
          ) {
            // 기계설비수량(machEqpQty)이 0인 경우 readonly
            // 표준라이브러리 선택 시 지정 값 수정불가
            if (
              Number(item.machEqpQty || 0) < 1 ||
              ([
                'cdaCspCapa',
                'nitrPeakCspCapa',
                'iwCspCapa',
                'frwtCspCapa',
                'coolCspCapa',
                'wwCspCapa',
                'stemCspCapa',
                'lngCspCapa',
                'insdExhaCspCapa',
                'otsdExhaCspCapa',
                'suarCspCapa',
              ].includes(binding) &&
                !!item.utmLibId)
            )
              e.cancel = true;
          }
        }}
      />
      {isExcelValidModalOpen && (
        <ExcelValidModal
          open={isExcelValidModalOpen}
          close={() => {
            setExcelValidCondition(null);
            setExcelValidModalOpen(false);
          }}
          condition={{
            valReqId: excelValidCondition || '',
          }}
        />
      )}
      {isUtMatrixDetailProcessModalOpen && (
        <UtMatrixDetailProcessPopup
          open={isUtMatrixDetailProcessModalOpen}
          close={() => setUtMatrixDetailProcessModalOpen(false)}
          condition={condition}
          onCallback={(checkedItems: UtMatrixDetailList[]) => {
            copyUtMatrixDetailProcess(
              checkedItems[0]?.utmId,
              checkedItems[0]?.planProcId,
              condition?.utmId,
              condition?.planProcId
            ).then((response) => {
              if (response.successOrNot === SuccessOrNot.Y) {
                openMessageBar({
                  type: 'confirm',
                  content: t('ut.label.복사되었습니다.', '복사되었습니다.'),
                });
                searchRegistMatrix(condition);
              } else {
                openMessageBar({
                  type: 'error',
                  content:
                    (response.data as string) ||
                    t('ut.label.복사 중 오류가 발생했습니다.', '복사 중 오류가 발생했습니다.'),
                });
              }
            });
          }}
        />
      )}
      {isUtMatrixRegistHistoryModalOpen && (
        <UtMatrixRegistHistoryPoupup
          open={isUtMatrixRegistHistoryModalOpen}
          close={() => setUtMatrixRegistHistoryModalOpen(false)}
          condition={{
            prdnProcCd: condition?.prdnProcCd,
            eqclId: condition?.eqclId,
            stndEqpId: utMatrixRegistHistoryCondition?.stndEqpId,
          }}
        />
      )}

      {isStandardEquipmentModalOpen && (
        <StandardEquipmentPopup
          open={isStandardEquipmentModalOpen}
          close={() => setStandardEquipmentModalOpen(false)}
          singleSelect={standardEquipmentCondition?.singleSelect}
          condition={{
            prdnProcCd: condition?.prdnProcCd,
            eqclId: condition?.eqclId,
          }}
          selectedIds={standardEquipmentCondition?.selectedIds}
          onCallback={(result) => {
            if (
              !condition.utmId ||
              !condition.planProcId ||
              !condition.prdnProcCd ||
              !condition.eqclId
            ) {
              openMessageBar({
                type: 'error',
                content: t(
                  'ut.label.조회 후 표준설비코드 버튼을 눌러주세요.',
                  '조회 후 표준설비코드 버튼을 눌러주세요.'
                ),
              });
              return;
            }
            // 그리드 행 내 단일 선택
            if (standardEquipmentCondition?.singleSelect) {
              const target = standardEquipmentCondition?.target;
              if (target && target?.row > -1) {
                const item = gridRef.current?.rows[target.row].dataItem;
                item.stndEqpId = result?.stndEqpId;
                item.eqpMainId = result?.eqpMainId;
                item.eqpMainNm = result?.eqpMainNm;
                item.eqpMchId = result?.eqpMchId;
                item.eqpMchNm = result?.eqpMchNm;
                item.dtalProcCd = result?.dtalProcCd;
                // [25.01.21] 기존 라이브러리가 입력되어 있는 경우에만 라이브러리 관련 데이터 초기화
                if (item.utmLibId) {
                  // 표준설비 변경된 경우 라이브러리 초기화
                  bindLibrary(target.row);
                }
                // 엑셀 업로드를 통해 추가된 행의 경우 작성구분 값 자동판단하지 않음
                if (item.exlUpldUseYn !== 'Y') {
                  item.insTpCd = getInsTpCd(item.stndEqpId, item?.utmLibId);
                }
                if (item.crudKey !== CrudCode.CREATE) {
                  item.crudKey = CrudCode.UPDATE;
                }
                gridRef.current?.collectionView.refresh();

                /*
                // 엑셀 업로드를 통해 추가된 행의 경우 작성구분 값 자동판단하지 않음
                if (item.exlUpldUseYn === 'N') {
                  item.insTpCd = getInsTpCd(result?.stndEqpId, item?.utmLibId);
                }
                if (item.crudKey !== CrudCode.CREATE) {
                  item.crudKey = CrudCode.UPDATE;
                }
                gridRef.current?.collectionView.refresh();
                */
              }
              return;
            }
            // 그리드 상단 표준설비코드 버튼을 통해 다건 선택
            const newRows = (result || []).reduce((acc, cur) => {
              acc.push({
                crudKey: CrudCode.CREATE,
                utmId: condition?.utmId,
                planProcId: condition?.planProcId,
                prdnProcCd: condition?.prdnProcCd,
                prdnProcCdNm: condition?.prdnProcCdNm,
                eqclId: condition.eqclId,
                eqclNm: condition.eqclNm,
                exlUpldUseYn: 'N',
                plcClctYn: 'N',
                dtalProcCd: cur?.dtalProcCd,
                stndEqpId: cur?.stndEqpId,
                eqpMainId: cur.eqpMainId,
                eqpMainNm: cur.eqpMainNm,
                eqpMchId: cur.eqpMchId,
                eqpMchNm: cur.eqpMchNm,
                insTpCd: getInsTpCd(cur.stndEqpId, null), // 행추가 시 신규상태 (표준설비코드 O, 표준라이브러리 X)
              });
              return acc;
            }, [] as UtMatrixDetail[]);
            const rows = [...newRows, ...rowData];
            setRowData(rows);
          }}
        />
      )}
      {isStandardLibraryModalOpen && (
        <StandardLibraryPopup
          open={isStandardLibraryModalOpen}
          condition={{
            stndEqpId: standardLibraryCondition?.stndEqpId,
            bildPlntCd: condition?.bildPlntCd,
          }}
          close={() => setStandardLibraryModalOpen(false)}
          onCallback={(result) => {
            const target = standardLibraryCondition?.target;
            if (target && target?.row > -1) {
              const lib = (result || {}) as UtMatrixLibrary;
              bindLibrary(target.row, lib);
            }
          }}
        />
      )}

      {isOpenHookupTagPopup && (
        <UtMatrixHookupTagPopup
          open={isOpenHookupTagPopup}
          close={() => setOpenHookupTagPopup(false)}
          condition={hookupTagCondition}
        />
      )}

      <GlobalBtnGroup>
        {isWrite && isUtWriter && (
          <>
            <Button css={IconButton.button} className="icoNone" onClick={() => handleSave()}>
              {t('com.button.임시저장', '임시저장')}
            </Button>
            <Button css={IconButton.button} className="save" onClick={handleSubmit}>
              {t('ut.label.제출', '제출')}
            </Button>
          </>
        )}
        {isWriteComplete && isUtWriter && (
          <Button css={IconButton.button} className="save" onClick={handleModify}>
            {t('com.button.수정', '수정')}
          </Button>
        )}
      </GlobalBtnGroup>
    </>
  );
};

export default React.forwardRef(UtMatrixContent);
