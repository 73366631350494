/* eslint-disable prettier/prettier */
/** @jsxImportSource @emotion/react */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import Datepicker, { DateValueType } from 'react-tailwindcss-datepicker';
import { Button, css } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import {
  CellClickedEvent,
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
} from 'ag-grid-community';
import { SnackbarOrigin } from '@mui/material/Snackbar';
import { SubLayout } from 'components/layouts/SubLayout';
import { IconButton } from 'components/buttons/IconSVG';
import { SelectBox, Selected, AllSelectWrap, list } from 'components/selects/CustomSelect';
import {
  SearchBox,
  SearchRows,
  SearchCols,
  SearchBoxRow,
  InputBox,
  SearchButtonWrap,
} from 'components/layouts/SearchBox';
import { ContentGrid } from 'components/layouts/ContentGrid';
import {
  ContentLayout,
  SubTitleLayout,
  SubTitleGroup,
  ControlBtnGroup,
  GlobalBtnGroup,
  cs,
} from 'components/layouts/ContentLayout';
import { HeaderSection } from 'components/layouts/PageHeaderLayout';
import { ContainerLayout } from 'components/layouts/ContainerLayout';
import { CustomInputText, CustomInputWrap, AllInputWrap } from 'components/inputs/CustomInput';
// import { Pagination } from 'apps/sub/components/Pagination';
import {
  TabButtonGroup,
  TabControlButton,
  ScrollingWrap,
  TabCloseButton01,
} from 'components/layouts/MDI';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { DatePickerWrap } from 'components/inputs/DatePicker';
// import NewSensorSolutionReqPage from './popup/NewSensorSolutionReqPopup';
import { Pagination, PaginationRef } from 'components/layouts/Pagination';
import {
  findClickStatusContent,
  generateSensorRequestAtchFileGrId,
} from 'apis/sensor/NewSensorSolutionReq';
import { NewSensorSolutionReq } from 'models/sensor/NewSensorSolutionReq';
import _ from 'lodash';
import {
  findSensorSolReqProgressStatusList,
  getNewSensorSolReqStatusListDownload,
} from 'apis/sensor/NewSensorSolutionReqStatus';
import { ElmEmpPopup } from 'pages/common/ElmEmpPopup';
import { PaginationSize } from 'components/layouts/PaginationSize';
import { TooltipComponent, TooltipValueGetter } from 'components/grids/Tooltip';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { Code } from 'models/common/CommonCode';
import useSessionStore from 'stores/useSessionStore';
import { getExcelFileName } from 'utils/ExcelUtil';
import { ExcelDownloadRequest } from 'models/common/Excel';
import { CrudCode } from 'models/common/Edit';
import FileUploadPopUp from 'pages/common/components/FileUploadPopUp';
import NewSensorSolutionReqPopup from './popup/NewSensorSolutionReqPopup';
import { ComboBox } from 'components/selects/ComboBox';
import { useMessageBar } from 'hooks/useMessageBar';
import { FileuploadBtn } from 'components/buttons/CustomButton';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { useLoading } from 'components/process/Loading';
import { useTranslation } from 'react-i18next';
import { InputDate } from '@grapecity/wijmo.input';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { flexGridTooltip } from 'models/ip/Public';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { FlexGridFilter } from '@grapecity/wijmo.grid.filter';
import { SelectionMode } from '@grapecity/wijmo.grid';
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';

interface PageLocationState {
  slunId?: string;
}

interface LocationState {
  upprMnuUrl?: string;
}

interface newSensorRequestPageLocationState {
  slunId?: string;
  misnNm?: string; // slunStatCd
  useYn?: string;
  condition?: any;
}

interface DisplayCondition {
  dataInsUserIdNm?: string; // 등록자명
}

const NewSensorSolutionReqStatusPage = () => {
  const { t } = useTranslation();
  const { gridNoRowsTemplate } = useSessionStore();
  const { openMessageBar } = useMessageBar();

  const navigate = useNavigate();
  // const copyNavigate = useNavigate(); // 복사
  // const newSensorRequestPageLocation = useNavigate(); // 신규센서요청이동

  const locationState: newSensorRequestPageLocationState = useLocation().state;

  const URLParams = new URLSearchParams(location.search);
  // const slunId = URLParams.get('slunId');
  // const isShowMenu = URLParams.get('showMenu') === 'N' ? false : true;
  // const locationState: LocationState = useLocation().state;
  // const [isLocationState, setLocationState] = useState<LocationState>({
  //   upprMnuUrl: locationState?.upprMnuUrl,
  // });

  const gridRef = useRef<AgGridReact<any>>(null);
  // const detailRef = useRef<any>(null);
  const paginationRef = useRef<PaginationRef>(null);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [newSensorSolutionReqStatusData, setNewSensorSolutionReqStatusData] =
    useState<NewSensorSolutionReq>({});
  const [openNewSensorSolutionReqPopup, setOpenNewSensorSolutionReqPopup] =
    useState<boolean>(false);
  const [newRequestModalCondition, setNewRequestModalCondition] = useState<any>();
  const [isOpenElmEmpPopup, setOpenElmEmpPopup] = useState<boolean>(false);
  const [userData, setUserData] = useState<any>('');
  const [rowData, setRowData] = useState<NewSensorSolutionReq[]>([]);
  const [displayCondition, setDisplayCondition] = useState<DisplayCondition>({});

  const [isOpenFileUploadModal, setOpenFileUploadModal] = useState<boolean>(false);
  const [fileUploadModalCondition, setFileUploadModalCondition] = useState<any>({});

  const [flexRef, setflexRef] = useState<any>();
  const [hitTest, setHitTest] = useState<any>();
  const [flexItem, setflexItem] = useState<any>();
  const [fieldId, setFieldId] = useState<any>();
  const [isOpenElmEmp, setOpenElmEmp] = useState<boolean>(false);
  const [isOpenCellTextarea, setOpenCellTextarea] = useState<boolean>(false);

  useEffect(() => {
    getCommonCode();
  }, []);
  const [isOpen, setOpen] = useState<any>(false);
  const [snsrStatCd, setSnsrStatCd] = useState<Code[]>([]);

  useEffect(() => {
    if (userData != '' && userData != null) {
      const userInfo = userData.userInfo[0] || {};
      setNewSensorSolutionReqStatusData({
        ...newSensorSolutionReqStatusData,
        dataInsUserId: userInfo.userId,
        dataInsUserNm: userInfo.empNm,
      });

      setDisplayCondition({
        ...displayCondition,
        dataInsUserIdNm: userInfo.empNm,
      });
    }
  }, [userData]);

  const getCommonCode = async () => {
    const snsrStatCd: Code[] = await getCommonCodeNames('SNSR_SOL_STAT_CD');
    setSnsrStatCd(snsrStatCd);
  };

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    if (e.target.name == 'dataInsUserNm') {
      setNewSensorSolutionReqStatusData({
        ...newSensorSolutionReqStatusData,
        [e.target.name]: e.target.value,
        dataInsUserId: e.target.value,
      });
    } else {
      setNewSensorSolutionReqStatusData({
        ...newSensorSolutionReqStatusData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    setNewSensorSolutionReqStatusData({
      ...newSensorSolutionReqStatusData,
      slunStatCd: e.target.value,
    });
  };

  const handleDisplayConditionChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const name = e.target.name;
    setDisplayCondition({
      ...displayCondition,
      [name]: e.target.value,
    });

    setNewSensorSolutionReqStatusData({
      ...newSensorSolutionReqStatusData,
      [name.replace('Nm', '')]: '',
    });
  };

  const searchBtn = async (condition: NewSensorSolutionReq, isFirst = false) => {
    const response = await findSensorSolReqProgressStatusList({
      ...condition,
    });
    if (response) {
      const rowData = response.data?.list || [];
      setRowData(rowData);
      setNewRequestModalCondition(rowData);
      setTotalCount(response.data?.totalCount || 0);
    }
  };

  const handleOpenRequest = (params) => {
    navigate('/sensor/newSensorSolutionReqPage', {
      state: { slunId: params.slunId, isCopy: false },
    });
  };

  // const handleOpenRequest = (data) => {
  //   navigate('/sensor/newSensorSolutionReqPage', {
  //     state: { slunId: data.slunId, isCopy: false },
  //   },
  // )};

  const handleOpenNewSensorRequest = () => {
    setNewSensorSolutionReqStatusData({});
    navigate('/sensor/newSensorSolutionReqPage', {
      state: { slunId: '', isCopy: false },
    });
  };

  let selector: any;
  const handleOpenCopyNewSensorRequest = (fieldId) => {
    // const rows = flexRef.selectedRows; // selectedRows;
    const rows = flexRef.selectedItems;

    if (!rows.length) {
      openMessageBar({
        type: 'error',
        content: t('com.label.복사할 행을 선택해 주세요.', '복사할 행을 선택해 주세요.'),
      });
      return;
    }
    navigate('/sensor/newSensorSolutionReqPage', {
      state: { slunId: rows[0].slunId, isCopy: true },
      // state: { slunId: grid.rows[ht.row]._data.slunId, isCopy: true, },
    });
    // )};
  };

  const handleResetCondition = () => {
    setNewSensorSolutionReqStatusData(
      (prev) =>
        Object.keys(prev).reduce(
          (acc, cur) => Object.assign(acc, { [cur]: cur === 'useYn' ? 'Y' : '' }),
          {}
        ) as NewSensorSolutionReq
    );

    setDisplayCondition(
      (prev) =>
        Object.keys(prev).reduce(
          (acc, cur) => Object.assign(acc, { [cur]: '' }),
          {}
        ) as DisplayCondition
    );
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      searchBtn(newSensorSolutionReqStatusData, true);
    }
  };

  const handleDownloadExcel = () => {
    const excelData: ExcelDownloadRequest<NewSensorSolutionReq> = {
      fileName: getExcelFileName(t('snsr.label.신규센서요청현황조회', '신규센서요청현황조회')), // + '.xlsx',
      sheetName: t('snsr.label.신규센서조회요청현황', '신규센서조회요청현황'),
      header: [
        String(t('snsr.label.솔루션 요청번호', '솔루션 요청번호')),
        String(t('snsr.label.과제명', '과제명')),
        String(t('snsr.label.공정위치', '공정위치')),
        String(t('snsr.label.문제 및 현상', '문제 및 현상')),
        String(t('snsr.label.요청목적', '요청목적')),
        String(t('snsr.label.납기', '납기')),
        String(t('com.label.진행상태', '진행상태')), // 진행상태 이름으로 변경해야함
        String(t('snsr.grid.처리내역', '처리내역')),
        String(t('com.label.요청자', '요청자')), // 요청자명으로 변경필요
        String(t('snsr.grid.측정 대상 및 필요 스펙', '측정 대상 및 필요 스펙')),
        String(t('snsr.grid.현재기준', '현재기준')),
        String(t('snsr.grid.목표기준', '목표기준')),
        String(t('snsr.label.기대효과', '기대효과')),
        String(t('snsr.label.개선방향', '개선방향')),
        // String(t('com.label.첨부', '첨부')),
        String(t('snsr.grid.비고', '비고')),
        String(t('com.label.등록자', '등록자')),
        String(t('com.label.등록일', '등록일자')),
        String(t('com.label.수정자', '수정자')),
        String(t('com.label.수정일', '수정일자')),
      ],
      searchCondition: { ...newSensorSolutionReqStatusData },
    };
    getNewSensorSolReqStatusListDownload(excelData);
  };

  const handleFileUpload = async (params) => {
    let atchFileGrId = params.data.atchFileGrId;
    if (_.isEmpty(atchFileGrId)) {
      atchFileGrId = await generateSensorRequestAtchFileGrId(params.data.slunId || '');
      if (atchFileGrId) {
        setNewSensorSolutionReqStatusData({
          ...newSensorSolutionReqStatusData,
          atchFileGrId: atchFileGrId,
        });
      } else {
        openMessageBar({
          type: 'error',
          content:
            t(
              'com.label.첨부파일그룹ID 생성에 실패했습니다.',
              '첨부파일그룹ID 생성에 실패했습니다.'
            ) +
            <br /> +
            t('com.label.새로고침 후 다시 시도해 주세요.', '새로고침 후 다시 시도해 주세요.'),
        });
        return;
      }
    }
    setFileUploadModalCondition({
      atchFileGrId: atchFileGrId,
      atchFileTpCd: 'NORMAL',
      optValCtn1: 'TB_ELM_SNSR_REQ_N',
      bizName: 'snsr',
    });
    setOpenFileUploadModal(true);
  };

  const valueFormatter = (params: any) => {
    if (params.colDef.field == 'rqrUserId') {
      return params.node.data.rqrUserNm;
    }
    if (params.colDef.field == 'dataInsUserId') {
      return params.node.data.dataInsUserNm;
    }
    if (params.colDef.field == 'dataUpdUserId') {
      return params.node.data.dataUpdUserNm;
    }
    if (params.colDef.field == 'slunStatCd') {
      return params.node.data.slunStatus;
    }
  };

  // const defaultColumnDefs: ColDef = {
  //   cellStyle: { textAlign: 'center' },
  //   resizable: true,
  //   tooltipComponent: TooltipComponent,
  //   tooltipValueGetter: TooltipValueGetter,
  // };

  // const onFlexGridSelectionChanged = (s, e) => {
  //   if (s.rows == 0) return;
  //   const row = s.rows[e.row].dataItem;
  // };

  const onFlexGridSelectionChanged = (s, e) => {
    if (s.rows == 0) {
      return;
    }
    // const row = s.rows[e.row]._data;
    // if (row?.slunId) {
    // //   row.fatDate = {
    // //     startDate: row.fatPlanStartDate?.substr(0, 10),
    // //     endDate: row.fatPlanEndDate?.substr(0, 10),
    // //   };
    // //   row.satDate = {
    // //     startDate: row.satPlanStartDate?.substr(0, 10),
    // //     endDate: row.satPlanEndDate?.substr(0, 10),
    // //   };
    // //   row.rmk = '';
    // }
    // // setRowSingle((rowSingle) => {
    // //   rowSingle = row;
    // //   return rowSingle;
    // // });
    // // setDisabled(row && row.ipCheckStatus === 'PLAN' ? false : true);
    // setRowData
    s.refresh();
    //s.rows[e.row].isSelected = true;

    // const value = s.getCellData(e.row, 0);
    // const checkbox = s.rowHeaders.getCellElement(e.row, 0);

    // //setState((state) => ({ ...state, selectedItems: s.rows.filter((r) => r.isSelected) }));
  };

  const onInitialized = (grid) => {
    new Selector(grid);
    new FlexGridFilter(grid);
    setflexRef(grid);

    //셀렉트 방식
    grid.selectionMode = SelectionMode.ListBox;

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;

      const ht = grid.hitTest(e);
      setHitTest(ht);
      // console.log(ht.row, ht.com);
      if (ht.row < 0 || ht.col < 0) return;

      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      // row 선택시 체크박스 체크
      for (let i = 0; i < len; i++) grid.rows[i].isSelected = false;
      grid.rows[ht.row].isSelected = true;

      const col = grid.columns[ht.col];
      const item = grid.rows[ht.row].dataItem;

      setflexItem(item);
      setFieldId(col.binding);
      //console.log(col, col.binding, grid.rows[ht.row]);

      // if (e.target instanceof HTMLButtonElement) {
      //   switch (e.target.id) {
      //     case 'btnEmp':
      //       setOpenElmEmp(true);
      //       break;
      //   }
      // }

      // switch (ht.panel.columns[ht.col].binding) {
      //   case 'H': {
      //     setOpenCellTextarea(true);
      //     break;
      //   }
      // }
    });

    // grid.hostElement.addEventListener('dblclick', (e) => {
    //   const len = grid.rows.length;
    //   if (len == 0) return;

    //   const ht = grid.hitTest(e);
    //   if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.
    // });
  };

  const LayoutDefinition = () => {
    return [
      {
        binding: 'slunId',
        header: String(t('snsr.label.솔루션 요청번호', '솔루션 요청번호')),
        align: 'center',
        allowMerging: true,
        width: 160,
        cellTemplate: CellMaker.makeLink({
          click: (e, ctx) => {
            navigate('/sensor/newSensorSolutionReqPage', {
              state: { slunId: ctx.item.slunId, isCopy: false },
            });
          },
        }),
      },
      {
        binding: 'misnNm',
        header: String(t('snsr.label.과제명', '과제명')),
        align: 'left',
        allowMerging: true,
        width: 230,
      },
      {
        binding: 'procLocCtn',
        header: String(t('snsr.label.공정위치', '공정위치')),
        align: 'left',
        allowMerging: true,
        width: 260,
      },
      {
        binding: 'prbPnmnCtn',
        header: String(t('snsr.label.문제 및 현상', '문제 및 현상')),
        align: 'left',
        allowMerging: true,
        width: 300,
      },
      {
        binding: 'reqPurpCtn',
        header: String(t('snsr.label.요청목적', '요청목적')),
        align: 'left',
        allowMerging: true,
        width: 300,
      },
      {
        binding: 'dueDtm',
        header: String(t('snsr.label.납기', '납기')),
        align: 'center',
        allowMerging: true,
        width: 100,
      },
      {
        binding: 'slunStatus', //'slunStatCd',
        header: String(t('com.label.진행상태', '진행상태')),
        align: 'left',
        allowMerging: true,
        width: 100,
      },
      {
        binding: 'psgCtn',
        header: String(t('snsr.grid.처리내역', '처리내역')),
        align: 'left',
        allowMerging: true,
        width: 120,
      },
      {
        binding: 'rqrUserNm',
        header: String(t('com.label.요청자', '요청자')),
        align: 'center',
        allowMerging: true,
        width: 140,
      },
      {
        binding: 'snsrSpecCtn',
        header: String(t('snsr.grid.측정 대상 및 필요 스펙', '측정 대상 및 필요 스펙')),
        align: 'left',
        allowMerging: true,
        width: 300,
      },
      {
        binding: 'curCrtnCtn',
        header: String(t('snsr.grid.현재기준', '현재기준')),
        align: 'left',
        allowMerging: true,
        width: 280,
      },
      {
        binding: 'golCrtnCtn',
        header: String(t('snsr.grid.목표기준', '목표기준')),
        align: 'left',
        allowMerging: true,
        width: 280,
      },
      {
        binding: 'epcEftCtn',
        align: 'left',
        header: String(t('snsr.label.기대효과', '기대효과')),
        allowMerging: true,
        width: 280,
      },
      {
        binding: 'iprvMesrCtn',
        header: String(t('snsr.label.개선방향', '개선방향')),
        align: 'left',
        allowMerging: true,
        width: 250,
      },
      {
        binding: 'atchFileCount', // atchFileCount
        header: String(t('com.label.첨부', '첨부')),
        align: 'center',
        allowMerging: true,
        width: 85,
        cellTemplate: CellMaker.makeLink({
          text: '${item.atchFileCount}',
          click: (e, ctx) => {
            setFileUploadModalCondition({
              atchFileGrId: ctx.item.atchFileGrId, //node.data.atchFileGrId,
              atchFileTpCd: 'NORMAL',
              optValCtn1: 'TB_ELM_SNSR_M', // 관련 테이블 명 (확인용)
              bizName: 'snsr', // 파일저장경로 중 업무 폴더명으로 사용 (없는 경우 etc)
              target: ctx,
            });
            setOpenFileUploadModal(true);
          },
        }),
      },
      {
        binding: 'rmk',
        header: String(t('snsr.grid.비고', '비고')),
        align: 'left',
        allowMerging: true,
        width: 140,
      },
      {
        binding: 'dataInsUserNm',
        header: String(t('com.label.등록자', '등록자')),
        align: 'center',
        allowMerging: true,
        width: 110,
      },
      {
        binding: 'dataInsDtm',
        header: String(t('com.label.등록일', '등록일')),
        align: 'center',
        allowMerging: true,
        width: 160,
      },
      {
        binding: 'dataUpdUserNm',
        header: String(t('com.label.수정자', '수정자')),
        align: 'center',
        allowMerging: true,
        width: 100,
      },
      {
        binding: 'dataUpdDtm',
        header: String(t('com.label.수정일', '수정일')),
        align: 'center',
        allowMerging: true,
        width: 150,
        editor: new InputDate(document.createElement('div')),
      },
    ];
  };

  const state = {
    itemsSource: rowData,
    layoutDefinition: LayoutDefinition(),
  };

  return (
    <>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows>
              <SearchCols>
                <label>{t('snsr.label.과제명', '과제명')}</label>
                <CustomInputWithSearch
                  name="misnNm"
                  placeholder={String(
                    t('snsr.msg.과제명을 입력해 주세요.', '과제명을 입력해 주세요.')
                  )}
                  value={newSensorSolutionReqStatusData?.misnNm}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('com.label.진행상태', '진행상태')}</label>
                <ComboBox
                  placeholder={String(t('com.label.전체', '전체'))}
                  options={snsrStatCd}
                  defaultValue={newSensorSolutionReqStatusData?.slunStatCd}
                  onChange={(value) =>
                    setNewSensorSolutionReqStatusData({
                      ...newSensorSolutionReqStatusData,
                      slunStatCd: value,
                    })
                  }
                />
              </SearchCols>
              <SearchCols>
                <label>{t('snsr.label.공정위치', '공정위치')}</label>
                <CustomInputWithSearch
                  name="procLocCtn"
                  placeholder={String(
                    t('snsr.msg.공정위치를 입력해 주세요.', '공정위치를 입력해 주세요.')
                  )}
                  value={newSensorSolutionReqStatusData?.procLocCtn}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
            </SearchRows>
            <SearchRows>
              <SearchCols>
                <label>{t('snsr.label.문제 및 현상', '문제 및 현상')}</label>
                <CustomInputWithSearch
                  name="prbPnmnCtn"
                  placeholder={String(
                    t('snsr.msg.문제 및 현상을 입력해 주세요.', '문제 및 현상을 입력해 주세요.')
                  )}
                  value={newSensorSolutionReqStatusData?.prbPnmnCtn}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('com.label.등록자', '등록자')}</label>
                <CustomInputWithSearch
                  className="find"
                  name="dataInsUserNm"
                  placeholder={String(
                    t('com.label.등록자를 선택해 주세요.', '등록자를 선택해 주세요.')
                  )}
                  value={newSensorSolutionReqStatusData?.dataInsUserNm}
                  onChange={handleChange}
                  onSearchClick={() => setOpenElmEmpPopup(true)}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('com.label.등록일', '등록일')}</label>
                <DatePickerWrap className="range">
                  <Datepicker
                    inputClassName="datepickerInput"
                    value={{
                      startDate: newSensorSolutionReqStatusData?.startDate || null,
                      endDate: newSensorSolutionReqStatusData?.endDate || null,
                    }}
                    onChange={(value) => {
                      setNewSensorSolutionReqStatusData({
                        ...newSensorSolutionReqStatusData,
                        startDate: String(value?.startDate || ''), // newValue.startDate.toString(),
                        endDate: String(value?.endDate || ''), //newValue.endDate.toString(),
                      });
                    }}
                    i18n={'ko'}
                    useRange={true}
                    placeholder="YYYY.MM.DD - YYYY.MM.DD"
                    displayFormat={'YYYY.MM.DD'}
                  ></Datepicker>
                </DatePickerWrap>
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchButtonWrap>
            <Button
              css={IconButton.button}
              className="reload"
              onClick={handleResetCondition}
              disableRipple
            ></Button>
            <Button
              css={IconButton.button}
              className="find"
              onClick={() => searchBtn(newSensorSolutionReqStatusData, true)}
            >
              {t('com.button.조회', '조회')}
            </Button>
          </SearchButtonWrap>
        </SearchBoxRow>
      </SearchBox>
      <ContainerLayout>
        <SubTitleLayout>
          <SubTitleGroup>
            <h3>{t('com.label.솔루션 요청 list', '솔루션 요청 list')}</h3>
            <span className="total">
              {t('com.label.총', '총')} <span>{totalCount.toLocaleString()}</span>
              {t('com.label.건', '건')}
            </span>
            {/* <PaginationSize
                  defaultPageSize={pageSize}
                  onChange={(value) => {
                    setPageSize(value);
                    paginationRef.current?.handlePageSizeChange(value);
                  }}
                /> */}
          </SubTitleGroup>
          <ControlBtnGroup>
            <Button
              css={IconButton.button}
              className="request"
              onClick={() => handleOpenNewSensorRequest()}
            >
              {t('com.button.신규센서솔루션 요청', '신규센서솔루션 요청')}
            </Button>
            <Button
              css={IconButton.button}
              className="copyRow"
              onClick={(e) => handleOpenCopyNewSensorRequest(e)}
            >
              {t('com.button.복사', '복사')}
            </Button>
            <Button css={IconButton.button} className="Exceldown" onClick={handleDownloadExcel}>
              {t('com.button.다운로드', '다운로드')}
            </Button>
            <Button
              css={IconButton.button}
              className="refresh"
              onClick={() => searchBtn(newSensorSolutionReqStatusData, true)}
            >
              {t('com.button.새로고침', '새로고침')}
            </Button>
          </ControlBtnGroup>
        </SubTitleLayout>

        <ContentGrid
          className={`ag-theme-alpine ${(rowData || []).length < 1 ? 'noData' : ''}`}
          // style={{ height: '500px' }}
        >
          <FlexGrid
            columnGroups={state.layoutDefinition}
            itemsSource={state.itemsSource}
            //headersVisibility="Column"
            //showSelectedHeaders="All"
            allowPinning="ColumnRange"
            // frozenColumns={1}
            showMarquee={true}
            selectionMode={'Row'}
            //alternatingRowStep={0}
            //allowMerging="ColumnHeaders"
            autoGenerateColumns={false}
            stickyHeaders={true}
            isReadOnly={true}
            formatItem={flexGridTooltip}
            // selectionChanged={onFlexGridSelectionChanged}
            initialized={onInitialized}
            // overlayNoRowsTemplate={gridNoRowsTemplate}
            style={{ height: (rowData || []).length < 1 ? '' : '500px' }}
          />
          <div className="noData" style={{ height: (rowData || []).length < 1 ? '500px' : '' }}>
            <span>
              {t('com.label.조회 가능한 데이터가 없습니다.', '조회 가능한 데이터가 없습니다.')}
            </span>
          </div>
          {/* <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            rowHeight={32}
            overlayNoRowsTemplate={gridNoRowsTemplate}
            suppressRowTransform={true}
            defaultColDef={defaultColumnDefs}
            tooltipShowDelay={0}
            tooltipHideDelay={2000}
            rowSelection="single"
          ></AgGridReact> */}
          {isOpenElmEmpPopup && (
            <ElmEmpPopup
              userData={userData}
              setUserData={setUserData}
              setPopup={setOpenElmEmpPopup}
              close={() => setOpenElmEmpPopup(false)}
              initParam={newSensorSolutionReqStatusData.dataInsUserNm}
            />
          )}
          {isOpenFileUploadModal && (
            <FileUploadPopUp
              open={isOpenFileUploadModal}
              close={() => setOpenFileUploadModal(false)}
              singleSelect={false}
              downloadOnly={fileUploadModalCondition.downloadOnly}
              initParam={{
                atchFileGrId: fileUploadModalCondition.atchFileGrId,
                atchFileTpCd: fileUploadModalCondition.atchFileTpCd,
                optValCtn1: fileUploadModalCondition.tableName,
                bizName: fileUploadModalCondition.bizName,
              }}
              onCallback={(atchFileGrId, fileCount) => {
                if (fileUploadModalCondition.target) {
                  fileUploadModalCondition.target.node?.setDataValue(
                    fileUploadModalCondition.target.column?.getColId(),
                    fileCount
                  );
                }
                setOpenFileUploadModal(false);
              }}
            />
          )}
        </ContentGrid>
      </ContainerLayout>
    </>
  );
};

export default NewSensorSolutionReqStatusPage;
