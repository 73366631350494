/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { IconButton } from 'components/buttons/IconSVG';
import { tb } from 'components/layouts/Table';
import { useMessageBar } from 'hooks/useMessageBar';
import { ControlBtnGroup, SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import CustomDialog from 'components/modals/common/CustomDialog';
import CustomGrid from 'components/grids/CustomGrid';
import { getExcelFileName } from 'utils/ExcelUtil';
import CustomDatepicker from 'components/inputs/CustomDatepicker';
import CustomTextarea from 'components/inputs/CustomTextarea';
import { FacilitySchedule, FacilityScheduleHistory } from 'models/pjm/FacilityScheduleRegist';
import {
  findFacilityScheduleChangeMaster,
  findFacilityScheduleChangeHistory,
  saveFacilityScheduleChange,
} from 'apis/pjm/FacilityScheduleChange';
import useEvent from 'react-use-event-hook';
import { CommonUtil } from 'utils/CommonUtil';
import { hasRole } from 'utils/SessionUtil';

type Props = {
  open: boolean;
  close: () => void;
  condition: {
    elmPrjId: string; // 프로젝트ID
    tgtEqpId: string; // 대상설비ID
    prjSpCd: string; // 프로젝트단계코드
  };
  onRefresh: () => void;
};

const FacilityScheduleChangePopup = ({ open, close, condition, onRefresh }: Props) => {
  const gridRef = useRef<any>();
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();
  const [masterData, setMasterData] = useState<FacilitySchedule>();
  const [rowData, setRowData] = useState<FacilityScheduleHistory[]>([]);
  const [newPlanEndDt, setNewPlanEndDt] = useState<string>('');
  const [chgRsnCtn, setChgRsnCtn] = useState<string>('');
  const [isSave, setSave] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const isReadOnly = useMemo(() => {
    return masterData?.cpltYn === 'Y' && (hasRole('SETUP_MANAGER') || hasRole('ADM'));
  }, [masterData]);

  useEffect(() => {
    handleSearch(condition);
    setSave(false);
  }, [condition]);

  useEffect(() => {
    if (newPlanEndDt) {
      setErrors({
        ...errors,
        newPlanEndDt: false,
      });
    }
  }, [newPlanEndDt]);

  useEffect(() => {
    if (chgRsnCtn) {
      setErrors({
        ...errors,
        chgRsnCtn: false,
      });
    }
  }, [chgRsnCtn]);

  const onInitialized = (grid) => {
    gridRef.current = grid;
  };

  const handleSave = useEvent(() => {
    const err = {};
    if ((newPlanEndDt || '').length < 1) {
      Object.assign(err, { newPlanEndDt: true });
    }
    if ((chgRsnCtn || '').length < 1) {
      Object.assign(err, { chgRsnCtn: true });
    }
    setErrors(err);

    if (Object.keys(err).filter((k) => err[k]).length) {
      return;
    }

    if (newPlanEndDt === masterData?.planEndDt) {
      openMessageBar({
        type: 'error',
        content: t('pjm.label.현재 일정과 동일합니다.', '현재 일정과 동일합니다.'),
      });
      return;
    }

    const params = {
      elmPrjId: condition.elmPrjId,
      tgtEqpId: condition.tgtEqpId,
      prjSpCd: condition.prjSpCd,
      newPlanEndDt: newPlanEndDt,
      chgRsnCtn: chgRsnCtn,
    } as FacilityScheduleHistory;
    saveFacilityScheduleChange(params).then((res) => {
      openMessageBar({
        type: res.successOrNot === 'Y' ? 'confirm' : 'error',
        content:
          res.successOrNot === 'Y'
            ? t('com.label.저장되었습니다.', '저장되었습니다.')
            : t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
      });
      if (res?.successOrNot === 'Y') {
        handleSearch(condition);
        setSave(true);
      }
    });
  });

  const handleSearch = (params) => {
    if (params?.elmPrjId && params?.tgtEqpId && params?.prjSpCd) {
      findFacilityScheduleChangeMaster(params).then((result) => {
        setMasterData(result);
        setNewPlanEndDt(result?.planEndDt || '');
      });
      findFacilityScheduleChangeHistory(params).then((result) => setRowData(result));
    }
  };

  const handleExportExcel = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(gridRef.current, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = t('pjm.label.일정변경이력', '일정변경이력');
    book.saveAsync(getExcelFileName(t('pjm.label.일정변경이력', '일정변경이력')));
  };

  const handleClose = () => {
    if (isSave) {
      onRefresh();
    }
    close();
  };

  const layoutDefinition = [
    {
      binding: 'planEndDt',
      header: String(t('pjm.label.계획 일정', '계획 일정')),
      align: 'center',
      width: 150,
    },
    {
      binding: 'newPlanEndDt',
      header: String(t('pjm.label.변경 일정', '변경 일정')),
      align: 'center',
      width: 150,
    },
    {
      binding: 'chgRsnCtn',
      header: String(t('pjm.label.변경 사유', '변경 사유')),
      align: 'left',
      minWidth: 200,
      width: '*',
      cellTemplate: (params) => CommonUtil.unescapeHtml(params.value),
    },
    {
      binding: 'dataUpdUserNm',
      header: String(t('pjm.label.수정자', '수정자')),
      align: 'center',
      width: 150,
    },
    {
      binding: 'dataUpdDtm',
      header: String(t('pjm.label.수정일자', '수정일자')),
      align: 'center',
      width: 150,
    },
  ];

  const dialogButtons = useMemo(() => {
    return [
      <Button
        key={'confirm'}
        css={IconButton.button}
        className="confirm"
        onClick={handleSave}
        disableRipple
      >
        {t('com.button.저장', '저장')}
      </Button>,
    ];
  }, []);

  return (
    <CustomDialog
      title={t('com.label.일정변경 관리', '일정변경 관리')}
      size="lg"
      open={open}
      onClose={handleClose}
      onCancel={isReadOnly ? undefined : handleClose}
      buttons={isReadOnly ? undefined : dialogButtons}
    >
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('pjm.label.일정 변경', '일정 변경')}</h3>
        </SubTitleGroup>
      </SubTitleLayout>
      <TableContainer css={tb.table} className="colFix9">
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <span>{t('pjm.label.프로젝트 ID', '프로젝트 ID')}</span>
              </TableCell>
              <TableCell>
                <span>{masterData?.elmPrjId}</span>
              </TableCell>
              <TableCell>
                <span>{t('pjm.label.프로젝트 명', '프로젝트 명')}</span>
              </TableCell>
              <TableCell colSpan={5}>
                <span>{masterData?.elmPrjNm}</span>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span>{t('pjm.label.Factory', 'Factory')}</span>
              </TableCell>
              <TableCell>
                <span>{masterData?.prdnPldoCdNm}</span>
              </TableCell>
              <TableCell>
                <span>{t('pjm.label.공정', '공정')}</span>
              </TableCell>
              <TableCell>
                <span>{masterData?.prdnProcCdNm}</span>
              </TableCell>
              <TableCell>
                <span>{t('pjm.label.설비군', '설비군')}</span>
              </TableCell>
              <TableCell>
                <span>{masterData?.eqclId}</span>
              </TableCell>
              <TableCell>
                <span>{t('pjm.label.Line A/C', 'Line A/C')}</span>
              </TableCell>
              <TableCell>
                <span>{masterData?.eltrTpCdNm}</span>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span>{t('pjm.label.Line 호기', 'Line 호기')}</span>
              </TableCell>
              <TableCell>
                <span>{masterData?.eqpLnId}</span>
              </TableCell>
              <TableCell>
                <span>{t('pjm.label.Machine', 'Machine')}</span>
              </TableCell>
              <TableCell>
                <span>{masterData?.eqpId}</span>
              </TableCell>
              <TableCell>
                <span>{t('pjm.label.단계', '단계')}</span>
              </TableCell>
              <TableCell>
                <span>{masterData?.prjSpCdNm}</span>
              </TableCell>
              <TableCell>
                <span>{t('pjm.label.완료여부', '완료여부')}</span>
              </TableCell>
              <TableCell>
                <span>{masterData?.cpltYn}</span>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span>{t('pjm.label.계획 일정', '계획 일정')}</span>
              </TableCell>
              <TableCell>
                <span>{masterData?.planEndDt}</span>
              </TableCell>
              <TableCell rowSpan={2} style={{ borderBottom: 'none' }}>
                <span>{t('pjm.label.변경 사유', '변경 사유')}</span>
              </TableCell>
              <TableCell colSpan={5} rowSpan={2} style={{ borderBottom: 'none' }}>
                <CustomTextarea
                  name="chgRsnCtn"
                  placeholder={String(
                    t('pjm.msg.변경사유를 입력해 주세요.', '변경사유를 입력해 주세요.')
                  )}
                  maxLength={1000}
                  value={chgRsnCtn}
                  onChange={(e: any) => {
                    setChgRsnCtn(e?.target.value);
                  }}
                  readOnly={isReadOnly}
                  isError={errors?.chgRsnCtn}
                  msgError={String(
                    t('pjm.msg.변경사유를 입력해 주세요.', '변경사유를 입력해 주세요.')
                  )}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span>{t('pjm.label.변경 일정', '변경 일정')}</span>
              </TableCell>
              <TableCell>
                <CustomDatepicker
                  isRange={false}
                  value={newPlanEndDt}
                  maxDate={
                    masterData?.nextPlanDt ? dayjs(masterData?.nextPlanDt).toDate() : undefined
                  }
                  minDate={
                    masterData?.prevPlanDt ? dayjs(masterData?.prevPlanDt).toDate() : undefined
                  }
                  onChange={(newValue) => {
                    setNewPlanEndDt(newValue as string);
                  }}
                  isError={errors?.newPlanEndDt}
                  msgError={String(
                    t('pjm.label.변경 일정을 입력해 주세요.', '변경 일정을 입력해 주세요.')
                  )}
                  readOnly={isReadOnly}
                  disabled={isReadOnly}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('pjm.label.일정변경이력', '일정변경이력')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{(rowData || []).length.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={() => handleExportExcel()}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
          <Button
            css={IconButton.button}
            className="refresh"
            onClick={() => handleSearch(condition)}
            disableRipple
          >
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        height={350}
        isReadOnly={true}
        isFilter={false}
        isSelector={false}
        allowPinning={false}
        initialized={onInitialized}
      />
    </CustomDialog>
  );
};

export default FacilityScheduleChangePopup;
