/** @jsxImportSource @emotion/react */
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox } from '@mui/material';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { IconButton } from 'components/buttons/IconSVG';
import {
  InputBox,
  SearchBox,
  SearchBoxRow,
  SearchButtonWrap,
  SearchCols,
  SearchRows,
} from 'components/layouts/SearchBox';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import CustomDialog from 'components/modals/common/CustomDialog';
import CustomGrid from 'components/grids/CustomGrid';
import SearchBoxButtons from 'components/buttons/SearchBoxButtons';
import { ControlBtnGroup, SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import { ComboBox, MultiComboBox } from 'components/selects/ComboBox';
import { getCommonCodeNames, getCommonCodeNamesCondition } from 'apis/admin/CommonCode';
import { getExcelFileName } from 'utils/ExcelUtil';
import { UtMatrixStandardCode } from 'models/ut/UtMatrixStandardCode';
import { Code } from 'models/common/CommonCode';
import { getUtMatrixStandardCodeList } from 'apis/ut/UtMatrixStandartdCode';
import { useMessageBar } from 'hooks/useMessageBar';
import { SearchParam, UtMatrixRegistHistory } from 'models/ut/UtMatrixRegistHistory';
import { st } from 'components/inputs/CustomInput';
import { getUtMatrixRegistHistoryList } from 'apis/ut/UtMatrixRegistHistory';
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

interface Props {
  open: boolean;
  close: () => void;
  condition?: {
    prdnProcCd?: string;
    eqclId?: string;
    dtalProcCd?: string;
    eqpMchId?: string;
    stndEqpId?: string;
  };
}

const UtMatrixRegistHistoryPoupup = ({ open, close, condition }: Props) => {
  const gridRef = useRef<any>();
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();
  const navigate = useNavigate();
  const [rowData, setRowData] = useState<UtMatrixRegistHistory[]>([]);
  const [prdnProcCode, setPrdnProcCode] = useState<Code[]>([]);
  const [eqclIdCode, setEqclIdCode] = useState<Code[]>([]);
  const [dtalProcCode, setDtalProcCode] = useState<Code[]>([]);

  const initSearchParam = {
    prdnProcCds: [],
    eqclIds: [],
    dtalProcCds: [],
    eqpMchId: '',
    stndEqpId: '',
  };
  const [searchParam, setSearchParam] = useState<SearchParam>(initSearchParam);
  const [searchState, setSearchState] = useState<any>();

  useEffect(() => {
    getCommonCodes();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(condition)) {
      setSearchParam({
        prdnProcCds: [condition?.prdnProcCd] || [],
        eqclIds: [condition?.eqclId] || [],
        stndEqpId: condition?.stndEqpId || '',
        dtalProcCds: [] || '',
        eqpMchId: '',
      });
      if (condition?.prdnProcCd && condition?.eqclId) {
        setSearchState(true);
      }
    }
  }, [condition?.prdnProcCd, condition?.eqclId]);

  useEffect(() => {
    if (searchState) {
      handleSearch();
      setSearchState(false);
    }
  }, [searchState]);

  const getCommonCodes = async () => {
    const prdnProcCd = await getCommonCodeNamesCondition({
      optValCtn1: 'Y',
      cmnGrCd: 'PRDN_PROC_CD',
    });
    const eqclIdCd = await getCommonCodeNamesCondition({
      optValCtn1: 'Y',
      cmnGrCd: 'EQCL_ID',
    });
    const dtalProcCd = await getCommonCodeNames('DTAL_PROC_CD');
    setPrdnProcCode(prdnProcCd);
    setEqclIdCode(eqclIdCd);
    setDtalProcCode(dtalProcCd);
  };

  const handleOnChange = (name, value) => {
    setSearchParam({ ...searchParam, [name]: value });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleExportExcel = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(gridRef.current, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = t('ut.label.표준설비코드 List', '표준설비코드 List');
    book.saveAsync(getExcelFileName(t('ut.label.표준설비코드 List', '표준설비코드 List')));
  };

  /**
   * 조회 이벤트 처리
   */
  const handleSearch = async () => {
    getUtMatrixRegistHistoryList(searchParam).then((result: UtMatrixRegistHistory[]) => {
      if (result !== null) {
        setRowData(result);
      } else {
        openMessageBar({
          type: 'error',
          content: t(
            'com.msg.요청 정보 조회 중 오류가 발생했습니다.',
            '요청 정보 조회 중 오류가 발생했습니다.'
          ),
        });
      }
    });
  };

  const layoutDefinition = useMemo(() => {
    return [
      {
        binding: 'no',
        header: String(t('com.label.NO', 'NO')),
        width: 40,
        align: 'center',
        cellTemplate: (grid) => grid.row._idx + 1,
      },
      {
        binding: 'prdnProcCdNm',
        header: String(t('ut.label.공정', '공정')),
        width: 100,
      },
      {
        binding: 'eqclIdNm',
        header: String(t('ut.label.설비군', '설비군')),
        width: 120,
      },
      {
        binding: 'dtalProcCdNm',
        header: String(t('ut.label.세부공정', '세부공정')),
        width: 120,
      },
      {
        binding: 'eqpMainNm',
        header: String(t('ut.label.Main', 'Main')),
        width: 120,
      },
      {
        binding: 'eqpMchNm',
        header: String(t('ut.label.Machine', 'Machine')),
        width: 120,
      },
      {
        binding: 'stndEqpId',
        header: String(t('ut.label.표준설비코드', '표준설비코드')),
        width: 130,
        align: 'center',
      },
      {
        binding: 'bildPlntCdNm',
        header: String(t('ut.label.공장', '공장')),
        width: 100,
        align: 'center',
      },
      {
        binding: 'utmId',
        header: String(t('ut.label.UT Matrix 번호', 'UT Matrix 번호')),
        width: 130,
        visible: false,
      },
      {
        binding: 'utmNo',
        header: String(t('ut.label.UT Matrix 번호', 'UT Matrix 번호')),
        width: 130,
        cellTemplate: CellMaker.makeLink({
          text: '${item.utmNo}',
          click: (e, ctx) => {
            fnSearchUtmNm(ctx.item);
          },
        }),
      },
      {
        binding: 'utmNm',
        header: String(t('ut.label.UT Matrix 명', 'UT Matrix 명')),
        width: 250,
        align: 'left',
      },
      {
        binding: 'verNo',
        header: String(t('ut.label.버전', '버전')),
        width: 80,
        align: 'center',
      },
      {
        binding: 'prdnLnNm',
        header: String(t('ut.label.Line', 'Line')),
        width: 80,
        align: 'center',
      },
      {
        binding: 'prdnPrlLnNm',
        header: String(t('ut.label.Sub Line', 'Sub Line')),
        width: 80,
        align: 'center',
      },
      {
        header: String(t('ut.label.수량', '수량')),
        columns: [
          {
            binding: 'machEqpQty',
            header: String(t('ut.label.기계', '기계')),
            width: 80,
          },
          {
            binding: 'elpwEqpQty',
            header: String(t('ut.label.전기', '전기')),
            width: 80,
          },
        ],
      },
      {
        binding: 'insTpCdNm',
        header: String(t('ut.label.작성구분', '작성구분')),
        width: 120,
        align: 'center',
      },
      {
        binding: 'utmLibId',
        header: String(t('ut.label.Library ID', 'Library ID')),
        width: 150,
        align: 'center',
      },
      {
        binding: 'utmLibVerNo',
        header: String(t('ut.label.Library Version', 'Library Version')),
        width: 100,
        align: 'center',
      },
      {
        binding: 'vltgNvl',
        header: String(t('ut.label.Voltage', 'Voltage')),
        width: 100,
        align: 'center',
      },
      {
        binding: 'elpwPhasCd',
        header: String(t('ut.label.Phase', 'Phase')),
        width: 100,
        align: 'center',
      },
      {
        binding: 'ctwTpCd',
        header: String(t('ut.label.Wire', 'Wire')),
        width: 100,
        align: 'center',
      },
      {
        binding: 'od1EcNvl',
        header: String(t('ut.label.Current(입력값)', 'Current(입력값)')),
        width: 100,
        align: 'center',
      },
      {
        binding: 'brkeCapa',
        header: String(t('ut.label.Breaker(MCCB)', 'Breaker(MCCB)')),
        width: 100,
        align: 'center',
      },
      {
        binding: 'elpwCapa',
        header: String(t('ut.label.Capacity', 'Capacity')),
        width: 100,
        align: 'center',
      },
      {
        binding: 'cdaCspCapa',
        header: String(t('ut.label.CDA', 'CDA')),
        width: 100,
        align: 'center',
      },
      {
        binding: 'nitrPeakCspCapa',
        header: String(t('ut.label.N2', 'N2')),
        width: 100,
        align: 'center',
      },
      {
        binding: 'iwCspCapa',
        header: String(t('ut.label.IW', 'IW')),
        width: 100,
        align: 'center',
      },
      {
        binding: 'frwtCspCapa',
        header: String(t('ut.label.FW', 'FW')),
        width: 100,
        align: 'center',
      },
      {
        binding: 'coolCspCapa',
        header: String(t('ut.label.RCW', 'RCW')),
        width: 100,
        align: 'center',
      },
      {
        binding: 'wwCspCapa',
        header: String(t('ut.label.WW', 'WW')),
        width: 100,
        align: 'center',
      },
      {
        binding: 'stemCspCapa',
        header: String(t('ut.label.Steam', 'Steam')),
        width: 100,
        align: 'center',
      },
      {
        binding: 'lngCspCapa',
        header: String(t('ut.label.NG(', 'NG(')),
        width: 100,
        align: 'center',
      },
      {
        binding: 'insdCspCapa',
        header: String(t('ut.label.Retrun Air', 'Retrun Air')),
        width: 100,
        align: 'center',
      },
      {
        binding: 'otsdCspCapa',
        header: String(t('ut.label.Exhaust Air', 'Exhaust Air')),
        width: 100,
        align: 'center',
      },
      {
        binding: 'suarCspCapa',
        header: String(t('ut.label.Supply Air', 'Supply air')),
        width: 100,
        align: 'center',
      },
      {
        binding: 'dataInsUserNm',
        header: String(t('ut.label.작성자', '작성자')),
        width: 100,
        align: 'center',
      },
      {
        binding: 'dataInsDtm',
        header: String(t('ut.label.작성일자', '작성일자')),
        width: 100,
        align: 'center',
      },
    ];
  }, [rowData]);

  const onInitialized = (grid) => {
    gridRef.current = grid;
  };

  const fnSearchUtmNm = (params: any) => {
    navigate('/ut/regist-management/review', {
      state: {
        utmId: params.utmId,
        bildPlntCd: params.bildPlntCd,
      },
    });
    close();
  };

  const btnReload = () => {
    setSearchParam({
      prdnProcCds: [],
      eqclIds: [],
      dtalProcCds: [],
      eqpMchId: '',
    });
  };

  return (
    <CustomDialog
      title={t('ut.label.표준설비별 UT Matrix', '표준설비별 UT Matrix')}
      open={open}
      onClose={close}
      size={'xl'}
    >
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows>
              <SearchCols>
                <label>{String(t('ut.label.공정', '공정'))}</label>
                <MultiComboBox
                  id="prdnProcCd"
                  options={prdnProcCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.prdnProcCds || []}
                  onChange={(value) => {
                    handleOnChange('prdnProcCd', value.join());
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{String(t('ut.label.설비군', '설비군'))}</label>
                <MultiComboBox
                  id="eqclId"
                  options={eqclIdCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.eqclIds || []}
                  onChange={(value) => {
                    handleOnChange('eqclId', value.join());
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{String(t('ut.label.세부공정', '세부공정'))}</label>
                <MultiComboBox
                  id="dtalProcCode"
                  options={dtalProcCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.dtalProcCds || []}
                  onChange={(value) => {
                    handleOnChange('dtalProcCd', value.join());
                  }}
                />
              </SearchCols>
            </SearchRows>
            <SearchRows>
              <SearchCols>
                <label>{t('com.label.Machine', 'Machine')}</label>
                <CustomInputWithSearch
                  name="eqpMchId"
                  value={searchParam.eqpMchId || ''}
                  defaultValue={searchParam.eqpMchId || ''}
                  placeholder={String(
                    t('com.label.Machine을 입력해 주세요.', 'Machine을 입력해 주세요.')
                  )}
                  onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('ut.label.표준설비코드', '표준설비코드')}</label>
                <CustomInputWithSearch
                  name="stndEqpId"
                  value={searchParam.stndEqpId || ''}
                  defaultValue={searchParam.stndEqpId || ''}
                  placeholder={String(
                    t('com.label.표준설비코드를 입력해 주세요.', '표준설비코드를 입력해 주세요.')
                  )}
                  onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <div style={{ display: 'inline-flex', width: '200px', paddingTop: '5px' }}>
                  <label style={{ paddingTop: '0px' }}>
                    {t('ut.label.이전버전보기', '이전버전보기')}
                  </label>
                  <Checkbox
                    id="showPrevVersion"
                    name="showPrevVersion"
                    css={st.checkbox}
                    className="custom"
                    checked={searchParam?.curYn === 'Y'}
                    onClick={(e) => {
                      setSearchParam({
                        ...searchParam,
                        curYn: searchParam?.curYn === 'Y' ? 'N' : 'Y',
                      });
                    }}
                    disableRipple
                  />
                </div>
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchButtonWrap>
            <Button
              css={IconButton.button}
              className="reload"
              onClick={btnReload}
              disableRipple
            ></Button>
            <Button css={IconButton.button} className="find" onClick={handleSearch} disableRipple>
              {t('com.button.조회', '조회')}
            </Button>
          </SearchButtonWrap>
        </SearchBoxRow>
      </SearchBox>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('ut.label.표준설비별 UT Matrix List', '표준설비별 UT Matrix List')}</h3>
          <span className="total">
            {t('com.label.총', '총')} <span>{(rowData || []).length.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={handleExportExcel}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
          <Button
            css={IconButton.button}
            className="refresh"
            onClick={() => handleSearch()}
            disableRipple
          >
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        height={450}
        isReadOnly={true}
        isFilter={false}
        isSelector={false}
        allowPinning={false}
        align="center"
        initialized={onInitialized}
      />
    </CustomDialog>
  );
};

export default UtMatrixRegistHistoryPoupup;
