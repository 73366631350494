/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useImperativeHandle, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useEvent from 'react-use-event-hook';
import { Button } from '@mui/material';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { addClass } from '@grapecity/wijmo';
import { CellType } from '@grapecity/wijmo.grid';
import {
  SubTitleLayout,
  SubTitleGroup,
  ControlBtnGroup,
  GlobalBtnGroup,
} from 'components/layouts/ContentLayout';
import { IconButton } from 'components/buttons/IconSVG';
import CustomGrid from 'components/grids/CustomGrid';
import { findStandardUse } from 'apis/ut/UtMatrixRegist';
import { UtMatrixDetail, UtMatrixRegistSearchCondition } from 'models/ut/UtMatrix';
import { hasRole } from 'utils/SessionUtil';
import { getExcelFileName } from 'utils/ExcelUtil';

const StandardUseContent = (props: any, ref) => {
  const {
    onSubmit,
    condition = {} as UtMatrixRegistSearchCondition,
    processData = [] as UtMatrixDetail[],
  } = props;
  const gridRef = useRef<any>();
  const { t } = useTranslation();
  const [rowData, setRowData] = useState<UtMatrixDetail[]>([]);
  const [flexRef, setFlexRef] = useState<any>();
  const [hasAuth, setHasAuth] = useState<boolean>(false);

  const isAprText = useMemo(() => {
    const p = processData.filter((o) => o.utmWrtProcProgStatCd === 'UTP05');
    return condition.utmWkProgStatCd === 'UTM04' && p.length > 0 ? '결재정보' : '결재요청';
  }, [processData, condition.utmWkProgStatCd]);

  //검토중
  const isUtReview = useMemo(() => {
    return condition.utmWkProgStatCd === 'UTM04' && condition.openMode !== 'appr' && hasAuth;
  }, [condition.utmWkProgStatCd, hasAuth]);

  // 작성완료 : <검토진행> <재작성요청> <보류> <목록> <다운로드>
  const isUtComplete = useMemo(() => {
    const p = processData.filter((o) => o.utmWrtProcProgStatCd === 'UTP03');
    return condition.utmWkProgStatCd === 'UTM03' && hasAuth;
  }, [processData, condition.utmWkProgStatCd, hasAuth]);

  // 작성중 : <검증> <임시저장> <새로고침>
  const isUtWriter = useMemo(() => {
    const p = processData.filter((o) => o.utmWrtProcProgStatCd === 'UTP02');
    return condition.utmWkProgStatCd === 'UTM02' && hasAuth; //작성중
  }, [processData, condition.utmWkProgStatCd, hasAuth]);

  useImperativeHandle(ref, () => ({
    searchStandardUse: (condition) => {
      searchStandardUse(condition);
    },
  }));

  useEffect(() => {
    if (hasRole('ADM') || hasRole('UT_MANAGER')) {
      setHasAuth(true);
    }
  }, []);

  const searchStandardUse = (condition) => {
    findStandardUse(condition).then((result) => {
      setRowData(result);
    });
  };

  const btnExcelExportGrid = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = 'Ut Matrix 검토 기계 요약';
    book.saveAsync(
      getExcelFileName(t('ut.label.Ut Matrix 검토 전기 요약', 'Ut Matrix 검토 전기 요약'))
    );
  };

  const layoutDefinition = [
    {
      binding: 'no',
      header: String(t('com.label.NO', 'NO')),
      width: 40,
      align: 'center',
      cellTemplate: (grid) => grid.row._idx + 1,
    },
    {
      binding: 'prdnProcCdNm',
      header: String(t('ut.label.공정', '공정')),
      width: 100,
    },
    {
      binding: 'qty',
      header: String(t('ut.label.라인/설비 수량', '라인/설비 수량')),
      width: 150,
      align: 'center',
      cellTemplate: (params) =>
        `${(params.item.eqpPrdnLnQty || 0).toLocaleString()} / ${(
          (params.item.machEqpQty || 0) + (params.item.elpwEqpQty || 0)
        ).toLocaleString()}`,
    },
    {
      header: String(t('ut.label.Electrocity[KW]', 'Electrocity[kW]')),
      collapseTo: 'od2EcNvl',
      columns: [
        {
          binding: 'od2EcNvl',
          header: String(t('ut.label.사용량', '사용량')),
          width: 100,
        },
        {
          binding: 'elpwStndUseQty',
          header: String(t('ut.label.표준사용량', '표준사용량')),
          width: 100,
        },
        {
          // 수용률 = 사용량 / 실사용량 (계산용 필드로 DB에 값저장하지 않음)
          binding: 'elecDmdRate',
          header: String(t('ut.label.수용율', '수용율')),
          width: 100,
        },
      ],
    },
    {
      header: String(t('ut.label.CDA[l/min]', 'CDA[ℓ/min]')),
      collapseTo: 'cdaCspSumCapa',
      columns: [
        {
          binding: 'cdaCspSumCapa',
          header: String(t('ut.label.사용량', '사용량')),
          width: 100,
        },
        {
          binding: 'cdaStndUseQty',
          header: String(t('ut.label.표준사용량', '표준사용량')),
          width: 100,
        },
        {
          binding: 'cdaDmdRate',
          header: String(t('ut.label.수용율', '수용율')),
          width: 100,
        },
      ],
    },
    {
      header: String(t('ut.label.N2[l/min]', 'N2[ℓ/min]')),
      collapseTo: 'nitrPeakCspSumCapa',
      columns: [
        {
          binding: 'nitrPeakCspSumCapa',
          header: String(t('ut.label.사용량', '사용량')),
          width: 100,
        },
        {
          binding: 'nitrStndUseQty',
          header: String(t('ut.label.표준사용량', '표준사용량')),
          width: 100,
        },
        {
          binding: 'nitrDmdRate',
          header: String(t('ut.label.수용율', '수용율')),
          width: 100,
        },
      ],
    },
    {
      header: String(t('ut.label.IW[l/min]', 'IW[ℓ/min]')),
      collapseTo: 'iwCspSumCapa',
      columns: [
        {
          binding: 'iwCspSumCapa',
          header: String(t('ut.label.사용량', '사용량')),
          width: 100,
        },
        {
          binding: 'iwStndUseQty',
          header: String(t('ut.label.표준사용량', '표준사용량')),
          width: 100,
        },
        {
          binding: 'iwDmdRate',
          header: String(t('ut.label.수용율', '수용율')),
          width: 100,
        },
      ],
    },
    {
      header: String(t('ut.label.IW2[l/min]', 'FW[ℓ/min]')),
      collapseTo: 'frwtCspSumCapa',
      columns: [
        {
          binding: 'frwtCspSumCapa',
          header: String(t('ut.label.사용량', '사용량')),
          width: 100,
        },
        {
          binding: 'frwtStndUseQty',
          header: String(t('ut.label.표준사용량', '표준사용량')),
          width: 100,
        },
        {
          binding: 'frwtDmdRate',
          header: String(t('ut.label.수용율', '수용율')),
          width: 100,
        },
      ],
    },
    {
      header: String(t('ut.label.PCW[l/min]', 'PCW[ℓ/min]')),
      collapseTo: 'coolCspSumCapa',
      columns: [
        {
          binding: 'coolCspSumCapa',
          header: String(t('ut.label.사용량', '사용량')),
          width: 100,
        },
        {
          binding: 'coolStndUseQty',
          header: String(t('ut.label.표준사용량', '표준사용량')),
          width: 100,
        },
        {
          binding: 'coolDmdRate',
          header: String(t('ut.label.수용율', '수용율')),
          width: 100,
        },
      ],
    },
    {
      header: String(t('ut.label.WW[l/min]', 'WW[ℓ/min]')),
      collapseTo: 'wwCspSumCapa',
      columns: [
        {
          binding: 'wwCspSumCapa',
          header: String(t('ut.label.사용량', '사용량')),
          width: 100,
        },
        {
          binding: 'wwStndUseQty',
          header: String(t('ut.label.표준사용량', '표준사용량')),
          width: 100,
        },
        {
          binding: 'wwDmdRate',
          header: String(t('ut.label.수용율', '수용율')),
          width: 100,
        },
      ],
    },
    {
      header: String(t('ut.label.Steam[Ton/hr]', 'Steam[Ton/hr]')),
      collapseTo: 'stemCspSumCapa',
      columns: [
        {
          binding: 'stemCspSumCapa',
          header: String(t('ut.label.사용량', '사용량')),
          width: 100,
        },
        {
          binding: 'stemStndUseQty',
          header: String(t('ut.label.표준사용량', '표준사용량')),
          width: 100,
        },
        {
          binding: 'steamDmdRate',
          header: String(t('ut.label.수용율', '수용율')),
          width: 100,
        },
      ],
    },
    {
      header: String(t('ut.label.NG[N㎥/hr]', 'NG[N㎥/hr]')),
      collapseTo: 'lngCspSumCapa',
      columns: [
        {
          binding: 'lngCspSumCapa',
          header: String(t('ut.label.사용량', '사용량')),
          width: 100,
        },
        {
          binding: 'lngStndUseQty',
          header: String(t('ut.label.표준사용량', '표준사용량')),
          width: 100,
        },
        {
          binding: 'lngDmdRate',
          header: String(t('ut.label.수용율', '수용율')),
          width: 100,
        },
      ],
    },
    {
      header: String(t('ut.label.Return air[CMH]', 'Return air[CMH]')),
      collapseTo: 'insdExhaCspSumCapa',
      columns: [
        {
          binding: 'insdExhaCspSumCapa',
          header: String(t('ut.label.사용량', '사용량')),
          width: 100,
        },
        {
          binding: 'insdExhaStndUseQty',
          header: String(t('ut.label.표준사용량', '표준사용량')),
          width: 100,
        },
        {
          binding: 'insdExhaDmdRate',
          header: String(t('ut.label.수용율', '수용율')),
          width: 100,
        },
      ],
    },
    {
      header: String(t('ut.label.Exhaust air[CMH]', 'Exhaust air[CMH]')),
      collapseTo: 'otsdExhaCspSumCapa',
      columns: [
        {
          binding: 'otsdExhaCspSumCapa',
          header: String(t('ut.label.사용량', '사용량')),
          width: 100,
        },
        {
          binding: 'otsdExhaStndUseQty',
          header: String(t('ut.label.표준사용량', '표준사용량')),
          width: 100,
        },
        {
          binding: 'otsdExhaDmdRate',
          header: String(t('ut.label.수용율', '수용율')),
          width: 100,
        },
      ],
    },
    {
      header: String(t('ut.label.Supply Air', 'Supply air')),
      collapseTo: 'suarCspSumCapa',
      columns: [
        {
          binding: 'suarCspSumCapa',
          header: String(t('ut.label.사용량', '사용량')),
          width: 100,
        },
        {
          binding: 'suarStndUseQty',
          header: String(t('ut.label.표준사용량', '표준사용량')),
          width: 100,
        },
        {
          binding: 'suarDmdRate',
          header: String(t('ut.label.수용율', '수용율')),
          width: 100,
        },
      ],
    },
  ];

  //검토진행
  const handleSubmitReview = () => {
    props?.onSubmit?.();
  };

  //검증
  const handleModify = () => {
    props?.onModify?.();
  };
  //보류 링크
  const handleRequestLinkHold = () => {
    props?.onLinkHold?.();
  };
  //재작성 링크
  const handleRequestLinkRewrite = () => {
    props?.onLinkRewrite?.();
  };

  const handleApproval = () => {
    props?.onAppr?.();
  };

  const onItemFormatter = useEvent((panel, row, col, cell) => {
    if (CellType.Cell === panel.cellType) {
      const binding = panel.columns[col].binding;
      const item = panel.rows[row].dataItem;
      if (binding) {
        if (item.prdnProcCdNm === 'Sub Sum') {
          addClass(cell, 'rowTotal');
        } else if (item.prdnProcCdNm === 'Total Sum') {
          addClass(cell, 'sumTotal');
        }
      }
    }
  });

  const onInitialized = (grid) => {
    grid.itemFormatter = onItemFormatter;
  };

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('ut.label.표준사용량 비교', '표준사용량 비교')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{(rowData || []).length.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
          {condition?.utmNo && <div className="info warning">{condition?.utmNo}</div>}
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={btnExcelExportGrid}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
          <Button
            css={IconButton.button}
            className="refresh"
            onClick={() => searchStandardUse(condition)}
          >
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        height={388}
        isReadOnly={true}
        isSelector={false}
        frozenColumns={3}
        allowPinning={false}
        excludePin={['utmSeq']}
        excludeFilter={['utmSeq', 'qty']}
        initialized={onInitialized}
      />
      <GlobalBtnGroup>
        {/* 작성중 : <검증> <임시저장> <새로고침> UTM02  */}
        {/* 작성완료 : <검토진행> <재작성요청> <보류> <목록> <다운로드>  UTM05 */}
        {/* 검토중 : <결재요청> <목록> <다운로드>  UTM04 */}
        {/* 그외 (결재중) : <목록> <다운로드>  */}

        {isUtWriter && (
          <>
            <Button css={IconButton.button} className="draft" onClick={handleModify}>
              {t('com.button.검증', '검증')}
            </Button>
            {/*<Button css={IconButton.button} className="draft" onClick={() => handleSave()}>*/}
            {/*  임시저장*/}
            {/*</Button>*/}
          </>
        )}
        {isUtComplete && (
          <>
            <Button css={IconButton.button} className="draft" onClick={handleRequestLinkRewrite}>
              {t('com.button.재작성요청', '재작성요청')}
            </Button>
            <Button css={IconButton.button} className="draft" onClick={handleRequestLinkHold}>
              {t('com.button.보류', '보류')}
            </Button>
            <Button css={IconButton.button} className="draft" onClick={handleSubmitReview}>
              {t('com.button.검토진행', '검토진행')}
            </Button>
            <Button css={IconButton.button} className="draft" onClick={handleModify}>
              {t('com.button.검증', '검증')}
            </Button>
            {/*<Button css={IconButton.button} className="draft" onClick={() => handleSave()}>*/}
            {/*  임시저장*/}
            {/*</Button>*/}
          </>
        )}
        {isUtReview && (
          <>
            <Button css={IconButton.button} className="draft" onClick={handleRequestLinkRewrite}>
              {t('com.button.재작성요청', '재작성요청')}
            </Button>
            <Button css={IconButton.button} className="draft" onClick={handleRequestLinkHold}>
              {t('com.button.보류', '보류')}
            </Button>
            <Button css={IconButton.button} className="draft" onClick={handleModify}>
              {t('com.button.검증', '검증')}
            </Button>
            {/*<Button css={IconButton.button} className="draft" onClick={() => handleSave()}>*/}
            {/*  임시저장*/}
            {/*</Button>*/}
            <Button css={IconButton.button} className="save" onClick={handleApproval}>
              {isAprText}
            </Button>
          </>
        )}
      </GlobalBtnGroup>
    </>
  );
};

export default React.forwardRef(StandardUseContent);
