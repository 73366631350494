/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Button } from '@mui/material';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { ContainerLayout } from 'components/layouts/ContainerLayout';
import { SubTitleLayout, SubTitleGroup, ControlBtnGroup } from 'components/layouts/ContentLayout';
import {
  SearchBox,
  SearchBoxRow,
  InputBox,
  SearchRows,
  SearchCols,
} from 'components/layouts/SearchBox';
import { IconButton } from 'components/buttons/IconSVG';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { ComboBox, MultiComboBox } from 'components/selects/ComboBox';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { useMessageBar } from 'hooks/useMessageBar';
import CustomGrid from 'components/grids/CustomGrid';
import { getExcelFileName } from 'utils/ExcelUtil';
import { Code } from 'models/common/CommonCode';
import { SearchParam, UtMatrixHookupTag } from 'models/ut/UtMatrixHookupTag';
import { getUtMatrixHookupTagList } from 'apis/ut/UtMatrixHookupTag';
import { findWithConditionMaster } from 'apis/ut/UtMatrixRegist';
import { UtMatrixMaster } from 'models/ut/UtMatrix';
import SearchBoxButtons from 'components/buttons/SearchBoxButtons';

const UtMatrixHookupTagPage = () => {
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();
  const gridRef = useRef<any>();
  const [rowData, setRowData] = useState<UtMatrixHookupTag[]>([]);
  const [masterData, setMasterData] = useState<UtMatrixMaster[]>([]);
  const [processData, setProcessData] = useState<UtMatrixHookupTag[]>([]);
  const [bildPlntCode, setBildPlntCode] = useState<Code[]>([]);

  const initSearchParam = {
    utmId: '',
    utmNo: '',
    utmNm: '',
    bildPlntCds: [],
    prdnProcCds: [],
    eqclIds: [],
    dtalProcCds: [],
    eqpMchId: '',
  };
  const [searchParam, setSearchParam] = useState<SearchParam>(initSearchParam);

  // 검색조건 > UT Matrix 명 (ComboBox 사용을 위해 변환)
  const optionsUtMaster = useMemo(() => {
    return (masterData || []).reduce(
      (acc, cur) => [
        ...acc,
        {
          cmnCd: cur.utmId,
          cmnCdNm: cur.utmNm,
        } as Code,
      ],
      [] as Code[]
    );
  }, [masterData]);

  const selectedMaster = useMemo(() => {
    if (!searchParam?.utmId) return null;
    const master = (masterData || []).filter((o) => o.utmId === searchParam?.utmId);
    return master.length > 0 ? master[0] : null;
  }, [searchParam?.utmId, masterData]);

  // 검색조건 > 공정 (ComboBox 사용을 위해 변환)
  const optionsProcess = useMemo(() => {
    return (processData || []).reduce((acc, cur) => {
      if (acc.findIndex(({ cmnCd }) => cmnCd === cur.prdnProcCd) < 0) {
        acc.push({
          cmnCd: cur.prdnProcCd,
          cmnCdNm: cur.prdnProcCdNm,
        });
      }
      return acc;
    }, [] as Code[]);
  }, [processData]);

  // 검색조건 > 설비군 (ComboBox 사용을 위해 변환)
  const optionsEqclId = useMemo(() => {
    if ((searchParam?.prdnProcCds || []).length < 1) return [];
    return (processData || []).reduce((acc, cur) => {
      if ((searchParam?.prdnProcCds || []).includes(cur.prdnProcCd)) {
        if (acc.findIndex(({ cmnCd }) => cmnCd === cur.eqclId) < 0) {
          acc.push({
            cmnCd: cur.eqclId,
            cmnCdNm: cur.eqclIdNm,
          });
        }
      }
      return acc;
    }, [] as Code[]);
  }, [processData, searchParam?.prdnProcCds]);

  // 검색조건 > 세부공정 (ComboBox 사용을 위해 변환)
  const optionDtlProcess = useMemo(() => {
    if ((searchParam?.prdnProcCds || []).length < 1) return [];
    return (processData || []).reduce((acc, cur) => {
      if (
        (searchParam?.prdnProcCds || []).includes(cur.prdnProcCd) &&
        (searchParam?.eqclIds || []).includes(cur.eqclId)
      ) {
        if (acc.findIndex(({ cmnCd }) => cmnCd === cur.dtalProcCd) < 0) {
          acc.push({
            cmnCd: cur.dtalProcCd,
            cmnCdNm: cur.dtalProcCdNm,
          });
        }
      }
      return acc;
    }, [] as Code[]);
  }, [processData, searchParam?.prdnProcCds, searchParam?.eqclIds]);

  useEffect(() => {
    initCondition();
  }, []);

  const initCondition = async () => {
    const bildPlntCode = await getCommonCodeNames('BILD_PLNT_CD');
    setBildPlntCode(bildPlntCode);
  };

  useEffect(() => {
    setSearchParam((prev) => ({
      ...prev,
      prdnProcCds: [],
      eqclIds: [],
      dtalProcCds: [],
    }));
    if (!searchParam?.utmId) {
      setProcessData([]);
      return;
    }
    getUtMatrixHookupTagList({ utmId: searchParam?.utmId }).then((result: UtMatrixHookupTag[]) => {
      if (result !== null) {
        setProcessData(result);
      }
    });
  }, [searchParam?.utmId]);

  useEffect(() => {
    if (_.isEmpty(searchParam?.bildPlntCds)) {
      setMasterData([]);
      return;
    }

    findWithConditionMaster(searchParam).then((result: UtMatrixMaster[]) => {
      setMasterData(result);
    });
  }, [searchParam?.bildPlntCds]);

  const layoutDefinition = useMemo(() => {
    return [
      {
        binding: 'no',
        header: String(t('com.label.NO', 'NO')),
        width: 40,
        align: 'center',
        cellTemplate: (grid) => grid.row._idx + 1,
      },
      {
        binding: 'utmNo',
        header: String(t('ut.label.UT Matrix 번호', 'UT Matrix 번호')),
        align: 'center',
        width: 140,
      },
      {
        binding: 'utmNm',
        header: String(t('ut.label.UT Matrix 명', 'UT Matrix 명')),
        width: 200,
      },
      {
        header: String(t('ut.label.Hookup Tagging', 'Hookup Tagging')),
        columns: [
          {
            binding: 'tagDivsCdNm',
            header: String(t('ut.label.기계/전기', '기계/전기')),
            width: 120,
            align: 'center',
          },
          {
            binding: 'tagId',
            header: String(t('ut.label.TAG ID', 'TAG ID')),
            width: 250,
          },
        ],
      },
      {
        binding: 'prdnProcCdNm',
        header: String(t('ut.label.공정', '공정')),
        width: 120,
        align: 'center',
      },
      {
        binding: 'eqclIdNm',
        header: String(t('ut.label.설비군', '설비군')),
        width: 120,
        align: 'center',
      },
      {
        binding: 'dtalProcCdNm',
        header: String(t('ut.label.세부공정', '세부공정')),
        width: 120,
        align: 'center',
      },
      {
        binding: 'eqpMainNm',
        header: String(t('ut.label.Main', 'Main')),
        width: 110,
      },
      {
        binding: 'eqpMchNm',
        header: String(t('ut.label.Machine', 'Machine')),
        width: 110,
      },
      {
        binding: 'stndEqpId',
        header: String(t('ut.label.표준설비코드', '표준설비코드')),
        width: 150,
        align: 'left',
      },
      {
        binding: 'bizAreaNm',
        header: String(t('ut.label.Area', 'Area')),
        width: 80,
        align: 'center',
      },
      {
        binding: 'bldgFloCdNm',
        header: String(t('ut.label.Floor', 'Floor')),
        width: 80,
        align: 'center',
      },
      {
        binding: 'prdnLnNm',
        header: String(t('ut.label.Line', 'Line')),
        width: 80,
        align: 'right',
      },
      {
        binding: 'prdnPrlLnNm',
        header: String(t('ut.label.Sub Line', 'Sub Line')),
        width: 90,
        align: 'right',
      },
      {
        header: String(t('ut.label.수량', '수량')),
        columns: [
          {
            binding: 'machEqpQty',
            header: String(t('ut.label.기계', '기계')),
            width: 80,
            align: 'right',
          },
          {
            binding: 'elpwEqpQty',
            header: String(t('ut.label.전기', '전기')),
            width: 80,
            align: 'right',
          },
        ],
      },
      {
        binding: 'vltgNvl',
        header: String(t('ut.label.Voltage', 'Voltage')),
        width: 100,
        align: 'center',
      },
      {
        binding: 'elpwPhasCd',
        header: String(t('ut.label.Phase', 'Phase')),
        width: 100,
        align: 'center',
      },
      {
        binding: 'ctwTpCd',
        header: String(t('ut.label.Wire', 'Wire')),
        width: 100,
        align: 'center',
      },
      {
        binding: 'od1EcNvl',
        header: String(t('ut.label.Current(입력값)', 'Current(입력값)')),
        width: 140,
        align: 'right',
        cellTemplate: (params) => `${params.value ? Number(params.value).toLocaleString() : ''}`,
      },
      {
        binding: 'brkeCapa',
        header: String(t('ut.label.Breaker(MCCB)', 'Breaker(MCCB)')),
        width: 140,
        align: 'right',
        cellTemplate: (params) => `${params.value ? Number(params.value).toLocaleString() : ''}`,
      },
      {
        binding: 'elpwCapa',
        header: String(t('ut.label.Electricity Capacity', 'Electricity Capacity')),
        width: 150,
        align: 'right',
        cellTemplate: (params) => `${params.value ? Number(params.value).toLocaleString() : ''}`,
      },
      {
        binding: 'cdaDevcCnctTpCdNm',
        header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
        width: 140,
        align: 'center',
      },
      {
        binding: 'cdaPntCnt',
        header: String(t('ut.label.CDA(Qty)', 'CDA(Qty)')),
        width: 100,
        align: 'right',
        cellTemplate: (params) => `${params.value ? Number(params.value).toLocaleString() : ''}`,
      },
      {
        binding: 'nitrPntCnt',
        header: String(t('ut.label.N2(Qty)', 'N2(Qty)')),
        width: 100,
        align: 'right',
        cellTemplate: (params) => `${params.value ? Number(params.value).toLocaleString() : ''}`,
      },
      {
        binding: 'iwPntCnt',
        header: String(t('ut.label.IW(Qty)', 'IW(Qty)')),
        width: 100,
        align: 'right',
        cellTemplate: (params) => `${params.value ? Number(params.value).toLocaleString() : ''}`,
      },
      {
        binding: 'frwtPntCnt',
        header: String(t('ut.label.FW(Qty)', 'FW(Qty)')),
        width: 100,
        align: 'right',
        cellTemplate: (params) => `${params.value ? Number(params.value).toLocaleString() : ''}`,
      },
      {
        binding: 'coolPntCnt',
        header: String(t('ut.label.RCW(Qty)', 'RCW(Qty)')),
        width: 100,
        align: 'right',
        cellTemplate: (params) => `${params.value ? Number(params.value).toLocaleString() : ''}`,
      },
      {
        binding: 'wwPntCnt',
        header: String(t('ut.label.WW(Qty)', 'WW(Qty)')),
        width: 100,
        align: 'right',
        cellTemplate: (params) => `${params.value ? Number(params.value).toLocaleString() : ''}`,
      },
      {
        binding: 'stemPntCnt',
        header: String(t('ut.label.Steam(Qty)', 'Steam(Qty)')),
        width: 100,
        align: 'right',
        cellTemplate: (params) => `${params.value ? Number(params.value).toLocaleString() : ''}`,
      },
      {
        binding: 'lngPntCnt',
        header: String(t('ut.label.NG(Qty)', 'NG(Qty)')),
        width: 100,
        align: 'right',
        cellTemplate: (params) => `${params.value ? Number(params.value).toLocaleString() : ''}`,
      },
      {
        binding: 'insdExhaPntCnt',
        header: String(t('ut.label.Retrun Air(Qty)', 'Retrun Air(Qty)')),
        width: 120,
        align: 'right',
        cellTemplate: (params) => `${params.value ? Number(params.value).toLocaleString() : ''}`,
      },
      {
        binding: 'otsdExhaPntCnt',
        header: String(t('ut.label.Exhaust Air(Qty)', 'Exhaust Air(Qty)')),
        width: 120,
        align: 'right',
        cellTemplate: (params) => `${params.value ? Number(params.value).toLocaleString() : ''}`,
      },
      {
        binding: 'suarPntCnt',
        header: String(t('ut.label.Supply Air(Qty)', 'Supply air(Qty)')),
        width: 120,
        align: 'right',
        cellTemplate: (params) => `${params.value ? Number(params.value).toLocaleString() : ''}`,
      },
      {
        binding: 'dataInsUserNm',
        header: String(t('ut.label.등록자', '등록자')),
        width: 100,
        align: 'center',
      },
      {
        binding: 'dataInsDtm',
        header: String(t('ut.label.등록일', '등록일')),
        width: 100,
        align: 'center',
      },
    ];
  }, [rowData]);

  const onInitialized = (grid) => {
    gridRef.current = grid;
  };

  const handleOnChange = (name, value) => {
    setSearchParam({ ...searchParam, [name]: value });
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      btnSearch();
    }
  };

  const btnExcelExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(gridRef.current, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = 'Hookup tagging list';
    book.saveAsync(getExcelFileName(t('ut.label.Hookup Tagging List', 'Hookup Tagging List')));
  };

  const btnSearch = () => {
    getUtMatrixHookupTagList(searchParam).then((result: UtMatrixHookupTag[]) => {
      if (result !== null) {
        setRowData(result);
      } else {
        openMessageBar({
          type: 'error',
          content: t(
            'com.msg.요청 정보 조회 중 오류가 발생했습니다.',
            '요청 정보 조회 중 오류가 발생했습니다.'
          ),
        });
      }
    });
  };

  return (
    <ContainerLayout>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows className="FourColThree">
              <SearchCols>
                <label>
                  <span>{t('ut.label.공장', '공장')}</span>
                </label>
                <MultiComboBox
                  id="bildPlntCd"
                  options={bildPlntCode || []}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.bildPlntCds || []}
                  onChange={(values: string[]) => {
                    const isChanged = !(
                      !!selectedMaster?.bildPlntCd &&
                      (values || []).includes(selectedMaster?.bildPlntCd)
                    );
                    setSearchParam((prev) => {
                      return {
                        ...prev,
                        bildPlntCds: values,
                        utmId: isChanged ? '' : prev?.utmId,
                        prdnProcCds: isChanged ? [] : prev?.prdnProcCds,
                        eqclIds: isChanged ? [] : prev?.eqclIds,
                        dtalProcCds: isChanged ? [] : prev?.dtalProcCds,
                      };
                    });
                  }}
                  className="width25"
                />
              </SearchCols>
              <SearchCols>
                <label>
                  <span>{t('ut.label.UT Matrix 명', 'UT Matrix 명')}</span>
                </label>
                <ComboBox
                  placeholder={String(t('com.label.선택', '선택'))}
                  options={optionsUtMaster}
                  defaultValue={searchParam?.utmId}
                  onChange={(value) => {
                    setSearchParam((prev) => ({
                      ...prev,
                      utmId: value,
                      prdnProcCds: [],
                      eqclIds: [],
                      dtalProcCds: [],
                    }));
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('ut.label.UT Matrix 번호', 'UT Matrix 번호')}</label>
                <CustomInputWithSearch
                  name="utmNo"
                  value={selectedMaster?.utmNo || ''}
                  readOnly={true}
                  isWrap={false}
                />
              </SearchCols>
            </SearchRows>
            <SearchRows className="FourCol">
              <SearchCols>
                <label>{String(t('ut.label.공정', '공정'))}</label>
                <MultiComboBox
                  id="prdnProcCd"
                  options={optionsProcess}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.prdnProcCds || []}
                  onChange={(values) => {
                    setSearchParam((prev) => {
                      return {
                        ...prev,
                        prdnProcCds: values,
                        eqclIds: [],
                        dtalProcCds: [],
                      };
                    });
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{String(t('ut.label.설비군', '설비군'))}</label>
                <MultiComboBox
                  id="eqpGrcd"
                  options={optionsEqclId}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.eqclIds || []}
                  onChange={(values) => {
                    setSearchParam((prev) => ({
                      ...prev,
                      eqclIds: values,
                      dtalProcCds: [],
                    }));
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{String(t('ut.label.세부공정', '세부공정'))}</label>
                <MultiComboBox
                  id="dtalProcCode"
                  options={optionDtlProcess}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.dtalProcCds || []}
                  onChange={(values) => {
                    setSearchParam((prev) => ({
                      ...prev,
                      dtalProcCds: values,
                    }));
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('com.label.Machine', 'Machine')}</label>
                <CustomInputWithSearch
                  name="eqpMchNm"
                  value={searchParam.eqpMchNm || ''}
                  defaultValue={searchParam.eqpMchNm || ''}
                  placeholder={String(
                    t('com.label.Machine을 입력해 주세요.', 'Machine을 입력해 주세요.')
                  )}
                  onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                  onKeyDown={onKeyDown}
                />
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchBoxButtons
            defaultCondition={initSearchParam}
            setReset={setSearchParam}
            onSearch={btnSearch}
          />
        </SearchBoxRow>
      </SearchBox>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('ut.label.Hookup Tagging List', 'Hookup Tagging List')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{rowData?.length ?? 0}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={btnExcelExport}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
          <Button css={IconButton.button} className="refresh" onClick={btnSearch} disableRipple>
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        initialized={onInitialized}
        height={490}
        isSelector={false}
        isReadOnly={true}
        excludePin={[
          'cdaPntCnt',
          'nitrPntCnt',
          'iwPntCnt',
          'frwtPntCnt',
          'coolPntCnt',
          'wwPntCnt',
          'stemPntCnt',
          'lngPntCnt',
          'insdExhaPntCnt',
          'otsdExhaPntCnt',
          'suarPntCnt',
          'dataInsUserNm',
          'dataInsDtm',
        ]}
      />
    </ContainerLayout>
  );
};

export default UtMatrixHookupTagPage;
