/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { IconButton } from './IconSVG';
import { SearchButtonWrap } from '../layouts/SearchBox';

interface Props {
  defaultCondition?: object; // 리셋 초기값 (입력하지 않은 경우 빈 값으로 초기화) (cf. { useYn: 'Y' }
  setReset?: React.Dispatch<React.SetStateAction<any>>; // 리셋 초기값을 설정할 setter (setter 전달 시에만 초기화, 없는 경우 onReset을 통해 따로 초기화 진행)
  onReset?: () => void; // 리셋 버튼 클릭 이벤트
  onSearch: () => void; // 검색 버튼 클릭 이벤트
  onFolding?: (isOpen: boolean) => void; // 접기 버튼 클릭 이벤트
}

const SearchBoxButtons = ({
  defaultCondition = {},
  setReset,
  onReset,
  onSearch,
  onFolding,
}: Props) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);

  const handleReset = () => {
    // setter 전달한 경우 값 초기화 처리
    if (setReset) {
      // defaultCondition 전달한 경우 해당 값으로 초기화 (or 빈 값)
      setReset((prev) =>
        Object.keys(Object.assign(prev || {}, defaultCondition)).reduce(
          (acc, cur) => Object.assign(acc, { [cur]: defaultCondition[cur] || '' }),
          {}
        )
      );
    }

    onReset && onReset();
  };

  const handleFolding = () => {
    setOpen(!isOpen);
    onFolding && onFolding(!isOpen);
  };

  return (
    <SearchButtonWrap>
      {(setReset || onReset) && (
        <Button css={IconButton.button} className="reload" onClick={handleReset} disableRipple />
      )}
      <Button css={IconButton.button} className="find" onClick={onSearch} disableRipple>
        {t('com.button.조회', '조회')}
      </Button>
      {onFolding && (
        <Button
          css={IconButton.button}
          className={`chevron ${isOpen ? 'active' : ''}`}
          onClick={handleFolding}
          hidden={true}
          disableRipple
        />
      )}
    </SearchButtonWrap>
  );
};

export default SearchBoxButtons;
