/** @jsxImportSource @emotion/react */
import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCommonModal } from 'hooks/useCommonModal';
import { tb } from 'components/layouts/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import { SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import CustomGrid from 'components/grids/CustomGrid';
import {
  findIdleAssetReviewResult,
  saveIdleAssetReviewResult,
} from 'apis/asst/IdleAssetReviewResult';
import _ from 'lodash';
import { useMessageBar } from 'hooks/useMessageBar';
import { CrudCode } from 'models/common/Edit';
import { Code } from 'models/common/CommonCode';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { DataMap, CellType } from '@grapecity/wijmo.grid';
import useEvent from 'react-use-event-hook';
import { GridStatusCellTemplate } from 'components/grids/GridStatusCellRenderer';
import { IdleAssetReviewResult } from 'models/asst/IdleAssetReviewResult';
import CustomDialog from '../../../components/modals/common/CustomDialog';
import { IconButton } from '../../../components/buttons/IconSVG';
import { addClass } from '@grapecity/wijmo';

type Props = {
  open: boolean;
  close: () => void;
  initParam?: {
    rvwReqId?: string; //검토요청ID
    isAsstEqpComplete?: boolean;
  };
  onRefresh: () => void;
};

export const IdlAssetReviewResultPopUp = ({ open, close, initParam, onRefresh }: Props) => {
  const { t } = useTranslation();
  const gridRef = useRef<any>();
  const { openMessageBar } = useMessageBar();
  const [rowData, setRowData] = useState<IdleAssetReviewResult>();
  const [rowDataResult, setRowDataResult] = useState<IdleAssetReviewResult[]>([]);
  const [code, setCode] = useState<any>();
  const { openCommonModal } = useCommonModal();
  const [isAsstEqpComplete, setAsstEqpComplete] = useState<boolean>(false);

  const getCommonCodes = async () => {
    const rvwRltDivsCd: Code[] = await getCommonCodeNames('RVW_RLT_DIVS_CD'); // 검토결과
    const rvwStatCd: Code[] = await getCommonCodeNames('RVW_STAT_CD'); // 검토상태(부서)
    const asstTpCd: Code[] = await getCommonCodeNames('ASST_TP_CD'); // 분류

    setCode({
      rvwRltDivsCd: rvwRltDivsCd,
      rvwStatCd: rvwStatCd,
      asstTpCd: asstTpCd,
    });
  };

  /**
   * props.initParam 변경 시 실행1
   */
  useEffect(() => {
    getCommonCodes();
    getEquipmentInfo(initParam);
  }, []);

  const getEquipmentInfo = async (initParam) => {
    if (initParam.rvwReqId) {
      findIdleAssetReviewResult(initParam.rvwReqId).then((result) => {
        setAsstEqpComplete(false);
        if (result.filter((o) => o.rvwStatCd == 'RVW').length == 0) setAsstEqpComplete(true);
        if (initParam.isAsstEqpComplete) setAsstEqpComplete(true);

        setRowData(result[0]);
        setRowDataResult(result);
      });
    }
  };

  const onInitialized = (grid) => {
    gridRef.current = grid;
    grid.itemFormatter = onItemFormatter;
  };

  const onItemFormatter = useEvent((panel, row, col, cell) => {
    const column = panel.columns[col];
    const binding = column.binding || '';
    if (CellType.ColumnHeader === panel.cellType) {
      const binding = panel.columns[col].binding;
      // 필수항목
      if (['rvwRltDivsCd'].includes(binding)) {
        addClass(cell, 'dot');
      }
    }
  });

  const validate = () => {
    const valid = (rowDataResult || [])
      .filter((o) => o.crudKey !== CrudCode.DELETE)
      .map((o, index) => {
        if (_.isEmpty(o.rvwRltDivsCd)) {
          return `${index + 1} : ${t(
            'asst.msg.검토 결과를 선택해 주세요.',
            `검토 결과를 선택해 주세요.`
          )}\n`;
        }
      })
      .filter((element) => element !== undefined);

    if (valid.length) {
      openMessageBar({ type: 'error', content: valid.toString() });
      return false;
    }
    return true;
  };

  const handleSave = () => {
    if (!validate()) return;

    openCommonModal({
      modalType: 'confirm',
      content: t(
        'com.label.검토 완료 하시겠습니까? 검토완료 후 수정은 불가 합니다.',
        '검토 완료 하시겠습니까? 검토완료 후 수정은 불가 합니다.'
      ),
      yesCallback: () => {
        // 변경된 데이터
        const params = (rowDataResult || []).filter((o) => !!o.crudKey);
        saveIdleAssetReviewResult(params)
          .then((response) => {
            openMessageBar({
              type: 'confirm',
              content: t('com.msg.검토 완료 되었습니다.', '검토 완료 되었습니다.'),
            });
            getEquipmentInfo(initParam);

            // 팝업 닫기 전 재조회 호출
            onRefresh();
            close();
          })
          .catch((err) =>
            openMessageBar({
              type: 'error',
              content: t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
            })
          );
      },
    });
  };

  const layoutDefinition = [
    // {
    //   binding: 'no',
    //   header: String(t('com.label.NO', 'NO')),
    //   align: 'center',
    //   width: 40,
    //   cellTemplate: (grid) => grid.row._idx + 1,
    // },
    {
      header: String(t('com.label.상태', '상태')),
      binding: 'crudKey',
      width: 43,
      align: 'center',
      cellTemplate: GridStatusCellTemplate,
      visible: false,
      isReadOnly: true,
    },
    {
      binding: 'asstTpCd',
      header: String(t('asst.label.분류', '분류')),
      align: 'center',
      width: 60,
      dataMap: new DataMap(code?.asstTpCd || [], 'cmnCd', 'cmnCdNm'),
      isReadOnly: true,
    },
    {
      binding: 'eqpId',
      header: String(t('asst.label.설비ID', '설비ID')),
      align: 'center',
      width: 80,
      isReadOnly: true,
    },
    {
      binding: 'eqpNm',
      header: String(t('asst.label.설비명', '설비명')),
      align: 'left',
      width: 130,
      isReadOnly: true,
    },
    {
      binding: 'asstNo',
      header: String(t('asst.label.자산번호', '자산번호')),
      align: 'center',
      width: 80,
      isReadOnly: true,
    },
    {
      binding: 'asstNm',
      header: String(t('asst.label.고정자산명', '고정자산명')),
      align: 'center',
      width: 100,
      isReadOnly: true,
    },
    {
      binding: 'acqrDt',
      header: String(t('asst.label.자산취득일자', '자산취득일자')),
      align: 'center',
      width: 100,
      isReadOnly: true,
    },
    {
      binding: 'asstAcqrAmt',
      header: String(t('asst.label.취득가액(USD)', '취득가액(USD)')),
      align: 'right',
      width: 100,
      cellTemplate: (params) => `${params.value ? Number(params.value || 0).toLocaleString() : ''}`,
      isReadOnly: true,
    },
    {
      binding: 'deprAmt',
      header: String(t('asst.label.잔존가액(USD)', '잔존가액(USD)')),
      align: 'right',
      width: 100,
      cellTemplate: (params) => `${params.value ? Number(params.value || 0).toLocaleString() : ''}`,
      isReadOnly: true,
    },
    {
      binding: 'atchFileCnt',
      header: String(t('asst.label.참고자료', '참고자료')),
      align: 'center',
      width: 80,
      isReadOnly: true,
      cellTemplate: (params) =>
        `<div class="fileDiv"><span><em>${(params.value || 0).toLocaleString()}</em></span></div>`,
    },
    {
      binding: 'atchFileGrId',
      visible: false,
    },
    {
      binding: 'tgtCopCd',
      header: String(t('asst.label.검토법인', '검토법인')),
      align: 'center',
      width: 80,
      isReadOnly: true,
    },
    {
      binding: 'crgeDeptNm',
      header: `${t('asst.label.검토부서', '검토부서')}`,
      align: 'center',
      width: 100,
      isReadOnly: true,
    },
    {
      binding: 'rvwStatCd',
      header: `${t('asst.label.상태', '상태')}`,
      align: 'center',
      width: 80,
      cssClass: 'WijmoSelect',
      dataMap: new DataMap(code?.rvwStatCd || [], 'cmnCd', 'cmnCdNm'),
      isReadOnly: true,
    },
    {
      binding: 'rvwRltDivsCd',
      header: String(t('asst.label.검토결과', '검토결과')),
      align: 'center',
      width: 100,
      cssClass: 'WijmoSelect',
      dataMap: new DataMap(code?.rvwRltDivsCd || [], 'cmnCd', 'cmnCdNm'),
    },

    {
      binding: 'rvwRltCtn',
      header: `${t('asst.label.Comment', 'Comment')}`,
      align: 'center',
      width: '*',
      maxLength: 400,
    },
    {
      binding: 'rvwUserNm',
      header: String(t('asst.label.검토자', '검토자')),
      align: 'center',
      width: 60,
      isReadOnly: true,
    },
    {
      binding: 'rvwDt',
      header: String(t('asst.label.검토일', '검토일')),
      align: 'center',
      width: 100,
      isReadOnly: true,
    },
  ];

  const dialogButtons = [
    <Button
      key={'select'}
      css={IconButton.button}
      className="select"
      onClick={() => handleSave()}
      disabled={initParam?.isAsstEqpComplete || isAsstEqpComplete ? true : false}
      disableRipple
    >
      {t('com.button.검토완료', '검토완료')}
    </Button>,
  ];

  return (
    <CustomDialog
      open={open}
      onClose={close}
      onCancel={close}
      size="xl"
      title={t('asst.label.최종결과입력', '최종결과입력')}
      buttons={dialogButtons}
    >
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('asst.label.유휴자산정보', '불용자산정보')}</h3>
        </SubTitleGroup>
      </SubTitleLayout>
      <TableContainer css={tb.table}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>{t('asst.label.요청번호', '요청번호')}</TableCell>
              <TableCell className="col31">{rowData?.rvwReqId}</TableCell>
              <TableCell>{t('asst.label.요청법인', '요청법인')}</TableCell>
              <TableCell className="col31">{rowData?.asstRvwReqCopCd}</TableCell>
              <TableCell>{t('asst.label.요청자', '요청자')}</TableCell>
              <TableCell className="col31">{rowData?.reqInsUserNm}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('asst.label.요청제목', '요청제목')}</TableCell>
              <TableCell className="col31" colSpan={3}>
                {rowData?.rvwReqTitNm}
              </TableCell>
              <TableCell>{t('asst.label.검토완료일자', '검토완료일자')}</TableCell>
              <TableCell className="col31">{rowData?.rvwCpltDt}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('asst.label.결과입력', '결과입력')}</h3>
        </SubTitleGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowDataResult}
        height={250}
        initialized={onInitialized}
        isFilter={false}
        allowPinning={false}
        isSelector={false}
      />
    </CustomDialog>
  );
};
